/**
 * Deliver Media Tab Styles
 */
export const DeliverMediaStyles = {
  CardContent: { padding: 0, "&:last-child": { paddingBottom: 0 } },
  Box1: {
    display: "flex",
    position: "relative",
    flexDirection: "row",
    alignItems: "start",
    margin: "1rem",
    "> button, > label": { marginLeft: "1rem" }
  },
  DataGrid: {
    border: 0,
    "& .--table-colhead-default-fill": {
      backgroundColor: "#F5F5F5",
      textTransform: "uppercase"
    }
  },
  VisuallyHiddenInput: {
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1
  },
  Requested: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.15px"
  },
  RequestedBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 1rem 0.75rem 1rem"
  },
  checkBoxStyles: {
    marginRight: "0px !important",
    width: "18px !important",
    height: "18px !important"
  }
};
