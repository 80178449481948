import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MediaListModalStyles } from "./MediaListModalStyles";

import ErrorIcon from "assets/images/warning-2.svg";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import {
  ERROR_TYPES,
  errorMsgs,
  mdyDateFormat,
  VALUE_N,
  VALUE_Y
} from "utils/constants";
import {
  actionTypes,
  aTag,
  bitZero,
  csvFileHeaderStarts,
  dateFromatForFileName,
  extensionCsv,
  extensionTxt,
  fileFormatOptions,
  fileType,
  globalAttributTypeId,
  hour,
  systemId,
  textFileHeaderStarts
} from "utils/constants/open-media-processing/Research";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getGlobalAttributeValue,
  getResponseData
} from "utils/helpers";
import { openMediaResearchStyles } from "./openMediaResearchStyles";

const CreateFileModal = ({
  open,
  onClose,
  includeColumnHeaders,
  excludeTransitAndDeliverDestroyed,
  setIncludeColumnHeaders,
  setExcludeTransitAndDeliverDestroyed,
  selectedCustomer,
  dispatch,
  setMessageSuccess,
  handleError,
  fieldsData,
  localeByBranch,
  selectedSatus,
  resultCount
}) => {
  const { t } = useTranslation();
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState(fileFormatOptions[0]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confrimMessage, setConfirmMessage] = useState("");
  const districtId = getAuthenticatedUserBranch();

  const handleCancel = () => {
    onClose();
    setExcludeTransitAndDeliverDestroyed(true);
    setIncludeColumnHeaders(false);
  };

  const confirmBeforeCreate = async () => {
    if (
      parseInt(resultCount.actualCount) > parseInt(resultCount.maxDisplayCount)
    ) {
      const msg = await getMessageFromCode(errorMsgs.errorCode25367);
      let message = msg[0]?.descr;
      setConfirmMessage(message);
      setConfirmModalOpen(true);
    } else {
      handleOk();
    }
  };

  const handleOk = async () => {
    setConfirmModalOpen(false);
    dispatch({ type: actionTypes.loader, payload: true });
    try {
      const params = await getParams();
      const reqBody = JSON.stringify({
        main_district_id: districtId,
        ...params
      });

      const data = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.research.createinventorytextfile,
        3
      );

      let error = data.data[0] && data.data[0][0]?.error;
      if (error) {
        handleError(error);
        handleCancel();
        return;
      }
      const branchDateTime = await getBranchDateTime();
      const customerNumber = selectedCustomer.number;
      const fileExtension =
        selectedFormat?.value === fileFormatOptions[0].value
          ? extensionTxt
          : extensionCsv;
      const fileName = `${customerNumber.trim()}-${branchDateTime}${fileExtension}`;
      let dataToCreateFile = data?.data[1] || [];

      let headerIndex = dataToCreateFile?.findIndex(
        (item) =>
          item?.line_out?.startsWith(textFileHeaderStarts) ||
          item?.line_out?.startsWith(csvFileHeaderStarts)
      );

      let headerItem = "";
      if (headerIndex > 0 && dataToCreateFile.length > headerIndex) {
        headerItem = dataToCreateFile.splice(headerIndex, 1)[0];
      }

      let sortedData = dataToCreateFile?.sort((a, b) => {
        let itemA = extractThirdItem(a.line_out)?.toLowerCase();
        let itemB = extractThirdItem(b.line_out)?.toLowerCase();
        return itemA?.localeCompare(itemB);
      });
      if (headerItem) {
        sortedData.unshift(headerItem);
      }
      handleDownloadCSV(sortedData, fileName);
      const msg = await getMessageFromCode(errorMsgs.errorCode26017);
      let successMessage = msg[0]?.descr;
      setMessageSuccess(successMessage);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      dispatch({ type: actionTypes.loader, payload: false });
    }
  };

  function extractThirdItem(lineOut) {
    let items = lineOut.split(",");
    return items[2].replace(/^(="|")|("$)/g, "");
  }

  const handleDownloadCSV = (data, fileName) => {
    let content = "";
    let blob;

    content = data.map((item) => item.line_out).join("");

    if (selectedFormat?.value === fileFormatOptions[1].value) {
      blob = new Blob([content], { type: fileType.csv });
    } else if (selectedFormat?.value === fileFormatOptions[0].value) {
      blob = new Blob([content], { type: fileType.txt });
    }

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement(aTag);
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    handleCancel();
  };

  const getBranchDateTime = async () => {
    let offset = await getLocalDateTimeForTimeZone();
    dayjs.locale(localeByBranch);
    return dayjs.utc().add(offset, hour).format(dateFromatForFileName);
  };

  const getLocalDateTimeForTimeZone = async () => {
    try {
      const reqBody = JSON.stringify({
        main_district_id: districtId
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing?.getLocalDateTimeForTimeZone,
        1
      );
      const obj = dataSets?.data;
      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        const timeZoneOffset = obj[0][0]?.time_zone_offset;
        return timeZoneOffset;
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const getParams = async () => {
    const globalAttributeValue = await getGlobalAttributeValue(
      districtId,
      systemId,
      globalAttributTypeId
    );

    return {
      customer_id: selectedCustomer.value,
      show_om_in_discrepancy_only_flag_bit: bitZero,
      file_format_type: selectedFormat?.value?.toString(),
      logical_vault_id: fieldsData?.selectedLv?.value,
      drp_code: fieldsData?.selectedDRP?.value,
      media_exchange_status_id: fieldsData?.selectedSatus?.value,
      return_date: dayjs(fieldsData?.returnDate).isValid()
        ? dayjs(fieldsData?.returnDate).format(mdyDateFormat)
        : "",
      open_media_volser: fieldsData?.mediaNumber,
      show_dup_only_flag_bit: bitZero,
      start_slot_number: fieldsData?.startingSlot,
      descr: fieldsData?.description,
      start_volser_number: fieldsData?.mediaStartingNumber,
      end_slot_number: fieldsData?.endingSlot,
      is_volser_list_flag: VALUE_N,
      volser_list: "",
      include_in_transit_not_at_im_flag: excludeTransitAndDeliverDestroyed
        ? VALUE_Y
        : VALUE_N,
      include_column_headings_flag: includeColumnHeaders ? VALUE_Y : VALUE_N,
      called_from_secure_sync_flag: "",
      include_cmi_column_flag: globalAttributeValue
    };
  };

  const onChangeFormat = (val) => {
    if (val?.value === fileFormatOptions[1].value) {
      setIncludeColumnHeaders(false);
    }
    setSelectedFormat(val);
  };

  return (
    <>
      <CommonModal
        open={open}
        onClose={onClose}
        title={t("openMediaResearch.createFile")}
        body={
          <>
            {throwError && (
              <Alert
                severity="warning"
                style={MediaListModalStyles.alertPadding}
                icon={
                  <img
                    style={MediaListModalStyles.alertSize}
                    src={ErrorIcon}
                    alt="error"
                  />
                }
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                      setThrowErrorMessage(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  componentsProps={{
                    clearIndicator: ArrowDown
                  }}
                  popupIcon={<ArrowDown />}
                  disableClearable={true}
                  options={fileFormatOptions}
                  value={selectedFormat}
                  onChange={(event, newValue) => onChangeFormat(newValue)}
                  id="logical-vault"
                  name="logical-vault"
                  size="medium"
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("openMediaResearch.fileFormat")}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack alignItems="center" justifyContent="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          setIncludeColumnHeaders(e.target.checked)
                        }
                        color="primary"
                        checked={includeColumnHeaders}
                        disabled={
                          selectedFormat?.value === fileFormatOptions[1].value
                        }
                      />
                    }
                    label={t("openMediaResearch.includeColumnHeaders")}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} py={2}>
                <FormControlLabel
                  sx={openMediaResearchStyles.checkboxWrapper}
                  pl={2}
                  control={
                    <Checkbox
                      color="primary"
                      checked={
                        excludeTransitAndDeliverDestroyed &&
                        !selectedSatus?.value
                      }
                      disabled={!!selectedSatus?.value}
                      onChange={(e) =>
                        setExcludeTransitAndDeliverDestroyed(e.target.checked)
                      }
                    />
                  }
                  label={
                    <Typography pt={1}>
                      {t(
                        "openMediaResearch.excludeTransitDeliveredDestroyedMedia"
                      )}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button
              id="CancelBtn-modal"
              variant="outlined"
              onClick={handleCancel}
              sx={MediaListModalStyles.buttonSX}
            >
              {t("common.cancel")}
            </Button>

            <Button
              id="saveBtn-modal"
              variant="contained"
              sx={MediaListModalStyles.buttonSX}
              onClick={confirmBeforeCreate}
            >
              {t("common.ok")}
            </Button>
          </>
        }
      />
      <ConfirmPopup
        modalPopupOpen={confirmModalOpen}
        handleCancel={() => setConfirmModalOpen(false)}
        showCancel={true}
        showNo={false}
        showOk={true}
        handleYes={handleOk}
        message={confrimMessage}
      />
    </>
  );
};

export default CreateFileModal;
