import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  TableContainer,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomers,
  getCustomersQueryData,
  getDistricts,
  getMessageFromCode,
  getSystemId
} from "services/api/query";
import { selectAuth } from "store/slices";
import {
  setDefaultBranchValues,
  setIsRefreshRequired,
  setVendorResponseList
} from "store/slices/customer-preferences";
import spacing from "styles/spacing";
import {
  dayMonthYearTimeFormat,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  downloadOptionPdf,
  ERROR_TYPES,
  errorMsgs,
  N_CHECKER,
  NO,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  YES
} from "utils/constants";
import {
  clear,
  employeeAccess,
  interBranch,
  mediaCare,
  operationsTransportationVendors,
  parnentId,
  payloadHash,
  payloadPersentageTwentyThree,
  personnelAccess,
  preferencesTypeIds,
  requirmentCode,
  resourceFileDesecriptionKeys,
  resourceFilekeys,
  roleAccessMappings,
  systemId
} from "utils/constants/customer-information/CustomerPreference";
import {
  dateTimeFormatByDateMonthrYear,
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData,
  getSortedData,
  getUserName
} from "utils/helpers";
import { PreferencesStyles } from "../PreferencesStyles";

import { CollapsibleTable } from "./PrefenceTabTable";
import {
  customerPreferencesReport,
  getAllBranchs,
  getPreferenceTabValues,
  getVaultsStatusTabData,
  updateValutStatusReports
} from "./Services";

import BranchField from "components/core/branch-field/BranchField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import {
  PAYINVOICE,
  PREFERENCES_ADD_ONCUTOFF_DEADLINE,
  PREFERENCES_DONT_CALL_DESC,
  PREFERENCES_EMAIL_NOTIFICATION,
  PREFERENCES_HOLIDAY_SERVICE,
  PREFERENCES_MINIMUM_EMPTY_TRANSPORTS_REQUIRED_AT_CUSTOMER_SITE,
  PREFERENCES_PLACE_HOLD_ON_MEDIA_DESTRUCTION_REQUESTS,
  PREFERENCES_SCAN_ON_SITE,
  PREFERENCES_SERVICE_INDICATOR
} from "routing/Paths";
import { useGetPersonnelAuthQuery } from "services/api";
import { CF_URLS } from "services/api/endpoints";
import PreferencesLayout from "./PreferencesLayout";
import {
  createXmlPyload,
  tableOptions,
  vaultStatusReportColumns
} from "./TableColumns";
import { a11yProps, CustomTabPanel, PreferenceTabs } from "./Tabs";
const { default: MUIDataTable } = require("mui-datatables");

const CustomerPreferences = () => {
  // intial States
  const { currentBranch, auth, timeZoneOffset, localeByBranch } =
    useSelector(selectAuth);
  const [canChangeTab, setCanChangeTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [thirdPartyData, setThirdPartyData] = useState(null);
  const dispatch = useDispatch();
  const {
    defaultAuthBranchValues,
    isAuthenticated,
    isRefreshRequired,
    authFormValues
  } = useSelector((state) => state.customerPreferences);
  const preferenceTableResultSetOneData =
    defaultAuthBranchValues.preferenceTableResultSetOne || {};
  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: defaultAuthBranchValues?.personnel_id
  });
  const [reportCUrl, setReportCFUrl] = useState(VALUE_EMPTY_STRING);
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);
  const [data, setData] = useState({
    preferenceTableData: [],
    vaultStatusTableData: [],
    branchsDropdownData: [],
    customers: [],
    settingsTime: [],
    allBranches: [],
    allAuthenticatedCustomers: [],
    authenticaticatedBranch: [],
    selectedCustomer: "",
    customer_id: "",
    selectedBranch: "",
    customerName: "",
    systemId: "",
    successMessage: "",
    throwErrorMessage: "",
    throwError: false,
    successModal: false,
    findButtonDisable: true,
    isEmailReportCheckBox: true,
    downloadModal: false
  });
  const employee_id = auth?.user?.id;
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  const navigate = useNavigate();

  // get the All Branchs
  const getBranchsData = async () => {
    setIsLoading(true);
    try {
      let res = await getAllBranchs(mainDistrictId, employee_id);
      if (res) {
        setData((prev) => ({
          ...prev,
          branchsDropdownData: res?.data
        }));
      }
      setIsLoading(false);
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getBranchsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainDistrictId]);

  // custom Sort
  const customSort = (a, b) => {
    const descA = String(a.name).toLowerCase();
    const descB = String(b.name).toLowerCase();

    return descA.localeCompare(descB, undefined, { numeric: true });
  };

  // table Logic
  const buildHierarchy = (data) => {
    // debugger;
    const idToItem = {};
    const result = [];
    data.sort(customSort);
    data.forEach((item) => {
      idToItem[item?.preference_type_id] = { ...item, children: [] };
    });

    data.forEach((item) => {
      const currentItem = idToItem[item?.preference_type_id];
      if (item?.parent_id) {
        const parentItem = idToItem[item?.parent_id];
        parentItem?.children.push(currentItem);
      } else {
        result.push(currentItem);
      }
    });

    return result;
  };

  useEffect(() => {
    if (isRefreshRequired) {
      getPreferenceGridData();
      dispatch(setIsRefreshRequired(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // fetching  preference tab table  data
  const getPreferenceGridData = async () => {
    try {
      dispatch(setDefaultBranchValues({ loading: true }));
      let res = await getPreferenceTabValues(
        mainDistrictId,
        defaultAuthBranchValues.customer?.value
      );
      let transportationVendorResponse = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.value
        }),
        CF_URLS.customerPreferences.getTransportationVendor,
        1
      );
      if (transportationVendorResponse?.data[0]) {
        dispatch(
          setVendorResponseList(
            transportationVendorResponse.data[0].sort((a, b) =>
              a.transportation_vendor_id.localeCompare(
                b.transportation_vendor_id
              )
            )
          )
        );
      }
      const operationalMode = await getSystemId(systemId);
      const employeeRequirmentCode =
        operationalMode[0]?.employee_requirement_code;
      const personalRequirementCode =
        operationalMode[0]?.personnel_requirement_code;

      if (res) {
        let filterData = (
          resData,
          employeeRequirmentCode,
          personalRequirementCode
        ) => {
          const isEmployeeRequirementCodeR =
            employeeRequirmentCode === requirmentCode;
          const isPersonnalRequirementCodeR =
            personalRequirementCode === requirmentCode;

          // Define service indicator values to exclude
          const serviceIndicatorParentId = parnentId;
          const excludedPreferenceValues = [interBranch, mediaCare]; // Interbranch, MediaCare
          return resData?.filter((item) => {
            const shouldRemoveForEmployee =
              isEmployeeRequirementCodeR &&
              item.employee_access === employeeAccess;
            const shouldRemoveForPersonnel =
              isPersonnalRequirementCodeR &&
              item.personnel_access === personnelAccess;
            //record should be remove basedon the access Code

            if (shouldRemoveForEmployee || shouldRemoveForPersonnel) {
              return false;
            }
            const isServiceIndicatorRecord =
              item?.parent_id === serviceIndicatorParentId;
            const shouldExcludeServiceIndicatorRecord =
              isServiceIndicatorRecord &&
              excludedPreferenceValues.includes(item.preference_value);
            const hasInvalidPreferenceValue =
              (item.preference_value === null ||
                item.preference_value === "") &&
              item.parent_id !== "";
            return (
              !shouldExcludeServiceIndicatorRecord && !hasInvalidPreferenceValue
            );
          });
        };

        const findData = filterData(
          res?.data[2],
          employeeRequirmentCode,
          personalRequirementCode
        );
        // Sort filteredData by discription (ascending)
        let filteredData = findData?.sort((a, b) => {
          const descA = String(a.description).toLowerCase();
          const descB = String(b.description).toLowerCase();

          return descA.localeCompare(descB, undefined, { numeric: true });
        });
        await import(`dayjs/locale/${localeByBranch}.js`);
        let updatedData = filteredData?.map((record) => {
          // Find role access based on preference_type_id
          const roleAccessMapping = roleAccessMappings.find((mapping) =>
            Array.isArray(mapping.preference_type_id)
              ? mapping.preference_type_id.includes(record.preference_type_id)
              : mapping.preference_type_id === record.preference_type_id
          );

          // Default to empty array if no matching role access found
          const roleAccess = roleAccessMapping
            ? roleAccessMapping.editRoleAccess
            : [];

          const dateFormate = dateTimeFormatByDateMonthrYear(
            record?.change_history_datetime,
            localeByBranch
          );

          return {
            settings: getFormatedSetting(
              record?.preference_value,
              record?.reference_list_description,
              transportationVendorResponse?.data[0]
            ),
            authorizedBy: ` ${record.personnel_last_name} ${record.personnel_first_name}`,
            authorizedDate: dateFormate,
            imEmployee: `${record.employee_last_name} ${record.employee_first_name} `,
            name: getResoursesDescription(
              record.description,
              record.preference_type_id
            ),
            preference_type_id: record.preference_type_id,
            parent_id: record.parent_id,
            preference_value: record.preference_value,
            roleAccess: roleAccess
          };
        });

        const payloadData = filteredData.map((item) => {
          const authorizedByName =
            `${item.personnel_last_name} ${item.personnel_first_name}`.trim();
          const imEmployee =
            `${item.employee_last_name} ${item.employee_first_name} `.trim();
          return {
            ...(getFormatedSetting(
              item?.preference_value,
              item?.reference_list_description,
              transportationVendorResponse?.data[0]
            ) && {
              st: getFormatedSetting(
                item?.preference_value,
                item?.reference_list_description,
                transportationVendorResponse?.data[0]
              )
            }),
            ...(authorizedByName && { aby: authorizedByName }),
            ...(item?.change_history_datetime && {
              adt: item?.change_history_datetime
            }),
            ...(imEmployee && { ie: imEmployee }),
            ...(item.preference_type_id && { ptid: item.preference_type_id }),
            ...(item.parent_id && { pid: item.parent_id }),
            ...(payloadDescription(
              item.description,
              item.preference_type_id
            ) && {
              na: payloadDescription(item.description, item.preference_type_id)
            })
          };
        });

        let finaldata = buildHierarchy(updatedData);

        const updatedStoreData = {
          preferenceTableResultSetOne: res?.data[1][0],
          prefenceFilteredTableData: finaldata,
          setTablePayloadData: payloadData
        };

        dispatch(setDefaultBranchValues(updatedStoreData));

        setData((prev) => ({
          ...prev,

          systemId: operationalMode[0]?.system_id
        }));
      }
      dispatch(setDefaultBranchValues({ loading: false }));
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      dispatch(setDefaultBranchValues({ loading: false }));
    }
  };

  const getFormatedSetting = (
    preferenceValue,
    referenceListDescription,
    vendorResponseList = []
  ) => {
    if ([N_CHECKER, Y_CHECKER].includes(preferenceValue)) {
      return preferenceValue === N_CHECKER ? NO : YES;
    } else if (preferenceValue === VALUE_EMPTY_STRING) {
      return NO;
    } else if (operationsTransportationVendors === referenceListDescription) {
      let data = vendorResponseList?.find(
        (item) => item?.transportation_vendor_id === preferenceValue
      );
      setThirdPartyData(data);
      return data?.name;
    } else {
      if (referenceListDescription) {
        let key = `${referenceListDescription}_${preferenceValue}`.replace(
          /\./g,
          "_"
        );

        return resourceFilekeys[key];
      }

      return preferenceValue;
    }
  };
  const sanitizeDescription = (text) => {
    return text.replace(payloadHash, payloadPersentageTwentyThree); // Remove non-alphanumeric characters except whitespace
  };
  const payloadDescription = (description, preferenceId) => {
    let key = `${t("preferences.decriptionKey")}_${preferenceId}`;
    let resourecDescription = resourceFileDesecriptionKeys[key] || description;
    return sanitizeDescription(resourecDescription);
  };
  const getResoursesDescription = (description, preferenceId) => {
    let key = `${t("preferences.decriptionKey")}_${preferenceId}`;
    return resourceFileDesecriptionKeys[key] || description;
  };

  //fetching  vault Status tab table  Data
  const getVaultsStatusGridData = async () => {
    try {
      dispatch(setDefaultBranchValues({ loading: true }));

      let res = await getVaultsStatusTabData(
        mainDistrictId,
        defaultAuthBranchValues.customer?.value
      );
      if (res) {
        let updatedData = res?.data[0].map((record) => ({
          personnelName: `${record?.last_name} ${record?.first_name}`,
          businessEmail: record?.business_email_addr,
          sendReport:
            record?.vault_status_report_recipient_flag === N_CHECKER
              ? N_CHECKER
              : Y_CHECKER,
          discrepancyContact:
            record?.discrepancy_contact_flag === N_CHECKER ? NO : YES,
          personnel_id: record?.personnel_id,
          last_mod_date: record?.last_mod_date
        }));

        // Sort updatedData by personnelName (ascending)
        updatedData.sort((a, b) => {
          // Case-insensitive comparison
          const nameA = a.personnelName.toLowerCase();
          const nameB = b.personnelName.toLowerCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

        setData((prev) => ({ ...prev, vaultStatusTableData: updatedData }));
      }

      dispatch(setDefaultBranchValues({ loading: false }));
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      dispatch(setDefaultBranchValues({ loading: false }));
    }
  };
  // find handler for data binding
  const handleFindClick = async () => {
    if (mainDistrictId) {
      await getPreferenceGridData();
      const updatedData = {
        isDisablePrint: false
      };

      dispatch(setDefaultBranchValues(updatedData));
    }
  };

  let xmlPayload = createXmlPyload(defaultAuthBranchValues, data);

  // save functionality SP Integration
  const saveValultStatusData = async () => {
    try {
      setIsLoading(true);
      await updateValutStatusReports(
        mainDistrictId,
        defaultAuthBranchValues.customer?.value,
        preferenceTableResultSetOneData?.vault_status_email,
        preferenceTableResultSetOneData?.email_only_if_exceptions_flag,
        preferenceTableResultSetOneData.timestamp,
        xmlPayload
      );

      let errorMsg = await getMessageFromCode(errorMsgs.errorCode64095);
      let replacedMessage = errorMsg[0]?.descr.replace(
        "|",
        t("preferences.vaultStausReportText")
      );
      await import(`dayjs/locale/${localeByBranch}.js`);
      const formattedDate = dayjs
        .utc()
        .add(timeZoneOffset, "hour")
        .locale(localeByBranch)
        .format(dayMonthYearTimeFormat);
      let repaceWithData = replacedMessage.replace("|", formattedDate);

      setData((prev) => ({
        ...prev,
        successMessage: repaceWithData || findErrorMessage(ERROR_TYPES.ISSUE),
        successModal: true
      }));

      setIsLoading(false);
    } catch (e) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };
  const handleSave = async () => {
    await saveValultStatusData();
  };

  //  selected the cutomers  handler
  const handleCustomerChange = async (e, newValue, reason) => {
    if (reason === clear) {
      setData((prev) => ({
        ...prev,
        selectedCustomer: VALUE_EMPTY_STRING,
        findButtonDisable: true,
        vaultStatusTableData: []
      }));
      setCanChangeTab(1);
      const updatedData = {
        prefenceFilteredTableData: [],
        preferenceTableResultSetOne: {},
        customer_id: VALUE_EMPTY_STRING,
        customerName: VALUE_EMPTY_STRING,
        findButtonDisable: true,
        isDisablePrint: true
      };

      dispatch(setDefaultBranchValues(updatedData));
      return;
    }
    newValue = { label: newValue.label, value: newValue.value.trim() };
    if (newValue) {
      let splitedValue = newValue?.label?.split("-")[0];
      setCanChangeTab(1);
      setData((prev) => ({
        ...prev,
        selectedCustomer: newValue,
        customer_id: newValue?.value,
        customerName: splitedValue,
        vaultStatusTableData: [],
        findButtonDisable: false
      }));

      const updatedData = {
        customer: newValue,
        customerName: splitedValue,
        findButtonDisable: false,
        isDisablePrint: true,
        prefenceFilteredTableData: [],
        preferenceTableResultSetOne: {}
      };
      dispatch(setDefaultBranchValues(updatedData));
    }
  };

  // clearing all States
  const handleClear = () => {
    setData((prev) => ({
      ...prev,

      vaultTableData: [],
      vaultStatusTableData: [],
      selectedCustomer: "",
      findButtonDisable: true,
      throwErrorMessage: "",
      throwError: false
    }));
    let initialAuthValues = {
      customer: ""
    };
    let intailSelectedValues = {
      customer: "",
      prefenceFilteredTableData: [],
      findButtonDisable: true,
      isDisablePrint: true
    };
    dispatch(setDefaultBranchValues(initialAuthValues));
    dispatch(setDefaultBranchValues(intailSelectedValues));
    dispatch(setVendorResponseList([]));
    setCanChangeTab(1);
  };

  //  tabs props
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
  };
  //  tabs  switching handleler
  const handleChange = async (event, newValue, allowChangeTab) => {
    setCanChangeTab(newValue);
    if (newValue === 0) {
      await getVaultsStatusGridData();
    } else {
      await getPreferenceGridData();

      setData((prev) => ({
        ...prev,
        throwErrorMessage: "",
        throwError: false
      }));
    }
  };
  //email check box changes
  const handleEmailChekboxChange = (e) => {
    let isChecked = e.target.checked;

    let updatedData = {
      preferenceTableResultSetOne: {
        ...preferenceTableResultSetOneData,
        email_only_if_exceptions_flag: isChecked ? Y_CHECKER : N_CHECKER
      }
    };
    dispatch(setDefaultBranchValues(updatedData));
  };

  const handleEmailTextBoxChange = (e) => {
    let value = e.target.value;

    let updatedData = {
      preferenceTableResultSetOne: {
        ...preferenceTableResultSetOneData,
        vault_status_email: value
      }
    };
    dispatch(setDefaultBranchValues(updatedData));
  };
  // vault state report table handlechange
  const handleTableCheckboxChange = (e, rowIndex) => {
    const isChecked = e.target.checked;
    setData((prev) => {
      const updatedData = [...prev.vaultStatusTableData];
      updatedData[rowIndex].sendReport = isChecked ? Y_CHECKER : N_CHECKER; // Update boolean value

      return { ...prev, vaultStatusTableData: updatedData };
    });
  };

  // navigate to subpages
  const navigateToSubScreen = (path, rowdata) => {
    let customer_id = defaultAuthBranchValues?.customer?.value;
    let splitedCustomerName = data?.selectedCustomer?.label?.split("-")[0];
    navigate(path, {
      state: {
        customer_id,
        splitedCustomerName,
        data,
        mainDistrictId,
        employee_id,
        rowdata,
        thirdPartyData
      }
    });
  };

  const handlerediectpage = (data) => {
    let preferenceTypeID = data.preference_type_id;
    switch (preferenceTypeID) {
      case preferencesTypeIds.scanOnSite:
        navigateToSubScreen(PREFERENCES_SCAN_ON_SITE);
        break;
      case preferencesTypeIds.placeHoldOnMediaDestructionRequests:
        navigateToSubScreen(
          PREFERENCES_PLACE_HOLD_ON_MEDIA_DESTRUCTION_REQUESTS
        );
        break;
      case preferencesTypeIds.holidayService:
        navigateToSubScreen(PREFERENCES_HOLIDAY_SERVICE, data);
        break;
      case preferencesTypeIds.emailNotification:
        navigateToSubScreen(PREFERENCES_EMAIL_NOTIFICATION);
        break;
      case preferencesTypeIds.dontCallResolveDesc:
        navigateToSubScreen(PREFERENCES_DONT_CALL_DESC);
        break;
      case preferencesTypeIds.addCutOffDeadlineRequest:
        navigateToSubScreen(PREFERENCES_ADD_ONCUTOFF_DEADLINE, data);
        break;
      case preferencesTypeIds.serviceIndicator:
        navigateToSubScreen(PREFERENCES_SERVICE_INDICATOR);
        break;
      case preferencesTypeIds.payInvoice:
        navigateToSubScreen(PAYINVOICE);
        break;
      case preferencesTypeIds.minimumEmptyTransports:
        navigateToSubScreen(
          PREFERENCES_MINIMUM_EMPTY_TRANSPORTS_REQUIRED_AT_CUSTOMER_SITE,
          data
        );
        break;
      default:
        break;
    }
  };
  //  get all branchs
  const getAllBarnchesFromFireStore = async () => {
    setIsLoading(true);
    try {
      const branches = await getDistricts();
      let bran = branches?.map((b) => {
        return {
          ...b,
          label: b?.name,
          value: b?.district_id
        };
      });

      const branchData = getSortedData(bran, "name", "asc");

      branchData?.unshift({
        label: t("common.all"),
        value: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      });
      setData((prev) => ({
        ...prev,
        allBranches: branchData
      }));
      setIsLoading(false);
    } catch (error) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllBarnchesFromFireStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleBranchChange = async (e) => {
    let value = e.target.value;
    await getCustomersBasedOnTheSelectedBranch(value);
    setData((prev) => ({
      ...prev,
      selectedBranch: value,
      selectedCustomer: ""
    }));
    const updatedData = {
      customer: "",
      selectedBranch: value,
      prefenceFilteredTableData: [],
      findButtonDisable: true
    };
    dispatch(setDefaultBranchValues(updatedData));
  };

  useEffect(() => {
    if (defaultAuthBranchValues?.branch || currentBranch?.id) {
      let branchName = defaultAuthBranchValues?.branch || currentBranch?.id;

      setData((prev) => ({
        ...prev,
        selectedBranch: branchName
      }));
      getCustomersBasedOnTheSelectedBranch(
        defaultAuthBranchValues?.branch || currentBranch?.id
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAuthBranchValues?.branch, currentBranch?.label]);
  useEffect(() => {
    setData((prev) => ({
      ...prev,
      selectedCustomer: defaultAuthBranchValues?.customer
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAuthBranchValues?.customer]);
  useEffect(() => {
    setData((prev) => ({
      ...prev,
      findButtonDisable: defaultAuthBranchValues?.findButtonDisable
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAuthBranchValues?.findButtonDisable]);

  // if authenticated get the allocated customers
  const getAllocatedCustomers = async () => {
    setIsLoading((prev) => true);
    const jsonString = personnelAuth[0]?.customer_list;
    const distinctCustomers = jsonString && JSON.parse(jsonString);
    // Extract distinct customer values
    const distinctCustomerIds = distinctCustomers?.reduce((acc, obj) => {
      if (!acc.includes(obj.customer_Id)) {
        acc.push(`${obj.customer_Id}`);
      }
      return acc;
    }, []);

    let customerList = await getCustomers(distinctCustomerIds);
    const custData = getSortedData(customerList, "number", "asc");
    let modCux = custData?.map((cus) => {
      return {
        label: `${cus?.number} - ${cus?.name}`,
        value: cus?.id
      };
    });

    if (modCux?.length > 1) {
      modCux.unshift({
        label: "",
        value: "",
        customer_id: "",
        number: ""
      });
    }
    setData((prev) => ({ ...prev, allAuthenticatedCustomers: modCux }));
    setIsLoading((prev) => false);
  };
  useEffect(() => {
    if (isAuthenticated) {
      getAllocatedCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // get the customers based on the Brach
  const getCustomersBasedOnTheSelectedBranch = async (branch) => {
    setIsLoading(true);
    try {
      const custList = await getCustomersQueryData(branch);
      let modCux = custList?.map((cus) => {
        return {
          ...cus,
          label: `${cus?.number} - ${cus?.name}`,
          value: cus?.id
        };
      });

      setData((prev) => ({
        ...prev,
        customers: modCux
      }));
      setIsLoading(false);
    } catch (error) {
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
      setIsLoading(false);
    }
  };
  const removeDuplicateBranchs = (arr) => {
    const uniqBranchs = new Set();
    let filteredBranchs = arr.filter((item) => {
      if (uniqBranchs.has(item?.label)) {
        return false;
      } else {
        uniqBranchs.add(item.label);
        return true;
      }
    });
    filteredBranchs.sort((a, b) => a.label.localeCompare(b.label));
    return filteredBranchs;
  };
  const getInitialPersonnelDetails = async (id, type) => {
    setIsLoading(true);
    try {
      let reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        personnel_id: id,
        customer_facing: N_CHECKER
      };

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.personnelDetail?.personnelinformationandpassedpersonnelid}`,
        4
      );

      let customerAssociation = data?.data[1];

      let cuxList = customerAssociation?.map((c) => {
        return {
          ...c,
          customer: c?.customer_number,
          name: c?.customer_name,
          branch: c?.district_name,
          customerName: `${c?.customer_number} ${c?.customer_name}`,
          label: c?.district_name,
          value: c?.district_id
        };
      });
      let uniqBranchs = removeDuplicateBranchs(cuxList);
      if (uniqBranchs?.length > 1) {
        uniqBranchs?.unshift({
          label: t("common.all"),
          value: DISTRICT_ID_GET_ALL_LOCATIONS,
          district_id: DISTRICT_ID_GET_ALL_LOCATIONS
        });
      }
      dispatch(setDefaultBranchValues({ authenticatateBranch: uniqBranchs }));
      setData((prev) => ({
        ...prev,
        authenticaticatedBranch: uniqBranchs
      }));

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setData((prev) => ({
        ...prev,
        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    }
  };
  useEffect(() => {
    if (authFormValues?.personnel?.value) {
      getInitialPersonnelDetails(authFormValues?.personnel?.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authFormValues?.personnel?.value]);
  // downloan handler
  const handleReplortDownloader = async () => {
    try {
      setIsLoading(true);
      const payload = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        customer_id: defaultAuthBranchValues?.customer?.value,
        preferences: defaultAuthBranchValues?.setTablePayloadData,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      };
      let res = await customerPreferencesReport(payload);

      if (!res.success) {
        setData((prev) => ({
          ...prev,
          throwErrorMessage: res.error,
          throwError: true
        }));
      }
      setIsLoading(false);
    } catch (e) {
      setData((prev) => ({
        ...prev,

        throwErrorMessage: findErrorMessage(ERROR_TYPES.ISSUE),
        throwError: true
      }));
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <PreferencesLayout>
      {(isLoading || defaultAuthBranchValues?.loading) && (
        <FullCircularLoader
          loading={isLoading || defaultAuthBranchValues?.loading}
        />
      )}
      {data.downloadModal && (
        <DownloadPopup
          title={t("common.downloadReport")}
          throwError={data?.throwError}
          throwErrorMessage={data?.throwErrorMessage}
          showPrintModal={data.downloadModal}
          setThrowError={() => {
            setData((prev) => ({ ...prev, throwError: false }));
          }}
          onCancleClick={() => {
            setData((prev) => ({ ...prev, downloadModal: false }));
            setReportCFUrl(VALUE_EMPTY_STRING);
          }}
          onDownloadClick={handleReplortDownloader}
          setSelectedValue={setSelectedValue}
          cfUrl={reportCUrl}
        />
      )}

      {data?.successModal && (
        <SuccessPopup
          message={data.successMessage}
          close={() => {
            setData((prev) => ({ ...prev, successModal: false }));
          }}
        />
      )}
      {data?.throwError && (
        <Collapse in={data?.throwError}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setData((prev) => ({ ...prev, throwError: false }));
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {data?.throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      <>
        <Box sx={isAuthenticated ? PreferencesStyles.margingTop : ""}>
          <Grid container spacing={2}>
            <Grid item md={6} lg={3} xs={12}>
              <FormControl fullWidth variant="outlined">
                <BranchField
                  options={
                    defaultAuthBranchValues?.authenticatateBranch.length > 1
                      ? defaultAuthBranchValues?.authenticatateBranch
                      : data?.allBranches
                  }
                  onChange={(e) => handleBranchChange(e)}
                  value={data?.selectedBranch}
                  disabled={
                    isAuthenticated
                      ? defaultAuthBranchValues?.authenticatateBranch.length > 1
                        ? false
                        : true
                      : false
                  }
                />
              </FormControl>
            </Grid>
            <Grid item md={6} lg={3} xs={12}>
              <Autocomplete
                disablePortal
                id="customerSelectLabel"
                options={
                  isAuthenticated
                    ? data.allAuthenticatedCustomers
                    : data?.customers
                }
                onChange={handleCustomerChange}
                onInputChange={(event, newInputValue, reason) => {
                  if (!isAuthenticated) {
                    setData((prev) => ({
                      ...prev,
                      throwErrorMessage: "",
                      throwError: false
                    }));
                  }
                }}
                value={data?.selectedCustomer}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("personnelValidation.customer")}
                  />
                )}
              />
            </Grid>
          </Grid>
          <br />
          <Stack direction="row" gap={spacing.gap}>
            <Button variant="outlined" onClick={handleClear}>
              {t("common.clear")}
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={
                defaultAuthBranchValues?.findButtonDisable
                  ? defaultAuthBranchValues?.findButtonDisable
                  : data?.findButtonDisable
              }
              onClick={handleFindClick}
            >
              {t("common.find")}
            </Button>
          </Stack>
          <br /> <br />
          <Box>
            <Box>
              <Tabs
                value={canChangeTab}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {PreferenceTabs.map((obj, index) => {
                  return (
                    <Tab
                      label={obj.label}
                      disabled={
                        defaultAuthBranchValues?.prefenceFilteredTableData
                          .length <= 0
                          ? index === 0
                          : false
                      }
                      key={obj.label}
                      {...a11yProps(index)}
                    />
                  );
                })}
              </Tabs>
            </Box>
            <CustomTabPanel value={canChangeTab} index={0}>
              <Paper>
                <Box sx={PreferencesStyles.dFlex}>
                  <Box sx={PreferencesStyles.marginLeftAuto}>
                    <Button
                      sx={PreferencesStyles.buttonMargin}
                      variant="outlined"
                      type="submit"
                      onClick={() => {
                        setData((prev) => ({ ...prev, downloadModal: true }));
                        setReportCFUrl(
                          CF_URLS.printReports.customerInformation
                            .customerPreferencesReport
                        );
                      }}
                    >
                      {t("common.print")}
                    </Button>
                  </Box>
                </Box>
                <br />
                <TableContainer>
                  <MUIDataTable
                    columns={vaultStatusReportColumns(
                      handleTableCheckboxChange
                    )}
                    options={tableOptions(data?.vaultStatusTableData)}
                    data={data?.vaultStatusTableData}
                  />
                </TableContainer>
              </Paper>
              <br />
              <Grid container spacing={2}>
                <Grid item md={6} lg={8} xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <>
                      <TextField
                        type="text"
                        value={
                          preferenceTableResultSetOneData.vault_status_email
                        }
                        onChange={handleEmailTextBoxChange}
                        disabled={false}
                        placeholder={t("preferences.feiledText")}
                      />
                    </>
                  </FormControl>
                  <Typography sx={PreferencesStyles.font13}>
                    {t("preferences.discription")}
                  </Typography>
                  <br />
                </Grid>

                <Grid item md={6} lg={4} xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            preferenceTableResultSetOneData.email_only_if_exceptions_flag ===
                            Y_CHECKER
                              ? true
                              : false
                          }
                          onChange={handleEmailChekboxChange}
                          inputProps={{ "aria-label": "controlled" }}
                          disabled={false}
                        />
                      }
                      label={t("preferences.emailReport")}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <br />
              <Typography sx={PreferencesStyles.borderBottom}></Typography>
              <br />
              <Button variant="contained" type="submit" onClick={handleSave}>
                {t("common.save")}
              </Button>
            </CustomTabPanel>
            <CustomTabPanel value={canChangeTab} index={1}>
              <Paper>
                <Box sx={PreferencesStyles.dFlex}>
                  <Box sx={PreferencesStyles.marginLeftAuto}>
                    <Button
                      sx={PreferencesStyles.buttonMargin}
                      variant="outlined"
                      type="submit"
                      disabled={defaultAuthBranchValues?.isDisablePrint}
                      onClick={() => {
                        setData((prev) => ({ ...prev, downloadModal: true }));
                        setReportCFUrl(
                          CF_URLS.printReports.customerInformation
                            .customerPreferencesReport
                        );
                      }}
                    >
                      {t("common.print")}
                    </Button>
                  </Box>
                </Box>
                <br />
                <CollapsibleTable
                  data={defaultAuthBranchValues?.prefenceFilteredTableData}
                  handlerediectpage={handlerediectpage}
                />
              </Paper>
            </CustomTabPanel>
          </Box>
        </Box>
      </>
    </PreferencesLayout>
  );
};

export default CustomerPreferences;
