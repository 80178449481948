import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import {
  downloadOptionPdf,
  ERROR_TYPES,
  VALUE_EMPTY_STRING
} from "utils/constants";
import { findErrorMessage, getUserName, printReport } from "utils/helpers";

export default function DownloadReportModal({
  showPrintModal,
  setShowPrintModal,
  throwError,
  setThrowError,
  throwErrorMessage,
  customerMoveEventId,
  destinationDistrictId,
  setThrowErrorMessage,
  setIsLoading
}) {
  const { auth } = useSelector(selectAuth);
  const { currentBranch } = useSelector(selectAuth);
  const [reportCUrl, setReportCFUrl] = useState(VALUE_EMPTY_STRING);
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);
  const userName = getUserName(auth?.user);
  const { t } = useTranslation();

  useEffect(() => {
    if (showPrintModal) {
      setReportCFUrl(CF_URLS.printReports.customerMove.reconcilliationReport);
    }
  }, [showPrintModal]);

  const handleCancel = () => {
    setThrowError(false);
    setShowPrintModal((prev) => !prev);
    setReportCFUrl(VALUE_EMPTY_STRING);
  };
  const handleDownloadReport = async () => {
    const requestBody = {
      main_district_id: currentBranch?.district_id,
      customer_move_event_id: customerMoveEventId,
      user_name: userName,
      report_format: selectedValue?.type?.toUpperCase(),
      dst_district_id: destinationDistrictId
    };

    try {
      setIsLoading(true);
      const reqBody = JSON.stringify(requestBody);

      const reportResponse = await printReport(
        reqBody,
        CF_URLS.printReports.customerMove.reconcilliationReport
      );
      if (!reportResponse.success) {
        setThrowErrorMessage((prevState) => reportResponse.error);
        setThrowError((prevState) => true);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage((prevState) => findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError((prevState) => true);
    } finally {
      setIsLoading((prevState) => false);
    }
    return;
  };
  return (
    <DownloadPopup
      title={t("customerMoveDetail.downloadReport")}
      showPrintModal={showPrintModal}
      onCancleClick={handleCancel}
      onDownloadClick={handleDownloadReport}
      setThrowError={setThrowError}
      throwError={throwError}
      throwErrorMessage={throwErrorMessage}
      setSelectedValue={setSelectedValue}
      cfUrl={reportCUrl}
    />
  );
}
