import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CircularLoaderFullPage } from "components/core";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import {
  ERROR_TYPES,
  N_CHECKER,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  downloadOptionPdf
} from "utils/constants";
import { allValue } from "utils/constants/container-processing/Research";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getAuthenticatedUserName,
  printReport
} from "utils/helpers";

const PrintReportModal = (props) => {
  const { t } = useTranslation();
  const { currentBranch } = useSelector(selectAuth);
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf); // selected download type
  const [pageLoading, setPageLoading] = useState(false); // page loading state
  const [throwError, setThrowError] = useState(false); // error message show/hide state
  const [throwErrorMessage, setThrowErrorMessage] = useState(""); // error message state

  // download click event handler
  const handleDownloadOnClick = async () => {
    try {
      setPageLoading((prevState) => true);
      setThrowError((prevState) => false);

      let containerList = props.formValues?.containerNumbers;
      if (props.containerNumbers.length > 0) {
        containerList = props.containerNumbers
          .map((cont) => cont.container_number)
          .toString()
          .replaceAll(",", "|");
      }

      let mediaIdentifiers = props.formValues?.customerMediaIds;
      if (props.customerMediaIDs.length > 0) {
        mediaIdentifiers = props.customerMediaIDs
          .map((media) => media.customer_media)
          .toString()
          .replaceAll(",", "|");
      }

      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        district_id: getAuthenticatedUserBranch(),
        customer_id: String(props.formValues?.customerId),
        media_type_id:
          String(props.formValues?.mediaType) === allValue
            ? VALUE_EMPTY_STRING
            : String(props.formValues?.mediaType),
        run_id: String(props.formValues?.runCode),
        container_list: containerList,
        customer_media_descr_list: mediaIdentifiers,
        exclude_transports_flag: props.formValues?.excludeTransports
          ? Y_CHECKER
          : N_CHECKER,
        status_id: String(props.formValues?.exchangeStatus),
        maxresults_flag: VALUE_EMPTY_STRING,
        district_name: currentBranch?.name,
        user_name: getAuthenticatedUserName(),
        report_format: selectedValue?.type?.toUpperCase()
      });

      const reportResponse = await printReport(
        reqBody,
        props.includeCMI
          ? `${CF_URLS.printReports.containerProcessing.research.containerSummaryWithCMI}`
          : `${CF_URLS.printReports.containerProcessing.research.containerSummary}`
      );
      if (!reportResponse.success) {
        setThrowErrorMessage((prevState) => reportResponse.error);
        setThrowError((prevState) => true);
      } else {
        // report print success
      }
    } catch (e) {
      setThrowErrorMessage(
        (prevState) => e.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError((prevState) => true);
    } finally {
      setPageLoading((prevState) => false);
    }
  };

  return (
    <>
      {/* Full page loader */}
      <CircularLoaderFullPage
        id="research-transaction-detail-full-page-loader"
        loading={pageLoading}
      />

      <DownloadPopup
        title={t("common.downloadReport")}
        showPrintModal={props.open}
        cfUrl={props.reportCFUrl}
        onCancleClick={props.onClose}
        onDownloadClick={handleDownloadOnClick}
        setSelectedValue={setSelectedValue}
        setThrowError={setThrowError}
        throwError={throwError}
        throwErrorMessage={throwErrorMessage}
      />
    </>
  );
};

PrintReportModal.propTypes = {
  open: PropTypes.bool.isRequired, // show/hide modal state
  includeCMI: PropTypes.bool.isRequired, // include Customer Media Identifier in report
  formValues: PropTypes.object, // form values
  containerNumbers: PropTypes.array, // container number list
  customerMediaIds: PropTypes.array, // customer media identifier list
  onClose: PropTypes.func // handle modal close event
};

export default PrintReportModal;
