import { Y_YES } from "components/features/request-module/media-destruction-request-vault-itemized/Constants";
import { getDocs, query, where } from "firebase/firestore";
import { t } from "i18next";
import {
  alertType,
  api_results_collection_resultset0,
  appsettings_collection,
  audit_sample_method,
  audit_status_collection,
  audit_type_collection,
  billing_classification_collection,
  billing_error_action_collection,
  branches,
  capacity_billing_suffix_collection,
  commission_type_collection,
  country,
  country_attribute_format_collection,
  customers_collection,
  day_of_week_collection,
  dropdown_list,
  email_group_address,
  employee,
  employee_collection,
  exchange_status_collection,
  file_section_status,
  global_attribute,
  global_attribute_collection,
  holidays_collection,
  holidays_years_collection,
  job_title,
  locale_collection,
  lock_types_collection,
  lv_media_type,
  media_program_type,
  media_type_collection,
  message_by_code_collection,
  module_settings_collection,
  operational_center,
  operational_mode_collection,
  personnel_cust_pers_collection,
  program_security,
  reasons_collection,
  root_cause_type,
  security_groups,
  service_frequency_collection,
  service_indicator_type,
  service_result_source_complete_collection,
  setting_type,
  state_province,
  suggestion_type,
  vendor_transport,
  week_of_month_collection
} from "services/firebase";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  DOUBLE_EQUALS,
  DROPDOWN_LIST_TYPE_ID,
  FIREBASE_COLLECTION_NAMES,
  FIREBASE_FIELD_NAMES,
  IN,
  Y_CHECKER,
  dropdownListValue,
  moduleSettingsEnvs,
  service_source_id_2
} from "utils/constants";
import { openMediaAuditDetailConst } from "utils/constants/open-media-processing/OpenMediaAuditConstants";
import {
  extractEndpointFromURL,
  getAuthenticatedUserBranch
} from "utils/helpers";

const queryData = async (q) => {
  let result = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    result.push({ id: doc?.id, ...doc?.data() });
  });
  return result;
};

const queryDataForCustomName = async (q, collectionName) => {
  let result = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.id === collectionName) {
      result.push(...(doc.data()?.CUSTOM_NAME || []));
    }
  });
  return result;
};

export const getAppSetting = async () => {
  let result = [];
  const q = query(appsettings_collection);
  const querySnapshot = await getDocs(q);
  querySnapshot?.forEach((doc) => {
    result.push({ id: doc?.id, ...doc?.data() });
  });

  return result[2]?.printer_templates[0];
};

const queryDataForSenderMailList = async (q, collectionName) => {
  let result = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.id === collectionName) {
      result.push(...(doc.data()?.SENDER_MAIL_LIST || []));
    }
  });
  return result;
};

const queryDataForDoNotReplyConfiguration = async (q, collectionName) => {
  let result = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.id === collectionName) {
      const { CUSTOM_NAME, REPLY_TO_MAIL } =
        doc.data()?.doNotReplyConfiguration || {};
      result.push({ emailAddress: REPLY_TO_MAIL, name: CUSTOM_NAME });
    }
  });
  return result;
};

export const getBillingErrorQueryData = async () => {
  const q = query(billing_error_action_collection);
  return await queryData(q);
};

//get capacity query data
export const getCapacityQueryData = async () => {
  const q = query(capacity_billing_suffix_collection);
  return await queryData(q);
};

//get message query data
export const getMessageFromCode = async (code) => {
  const q = query(
    message_by_code_collection,
    where(FIREBASE_FIELD_NAMES.MESSAGE_ID, DOUBLE_EQUALS, code)
  );

  return await queryData(q);
};

//get employees query data
export const getEmployeeDetails = async () => {
  const q = query(
    employee_collection,
    where(FIREBASE_FIELD_NAMES.ACTIVE_FLAG, DOUBLE_EQUALS, Y_CHECKER)
  );
  return await queryData(q);
};

export const getClassificationQueryData = async () => {
  const q = query(billing_classification_collection);
  return await queryData(q);
};

export const getCommissionTypeQueryData = async () => {
  const q = query(commission_type_collection);
  return await queryData(q);
};

//get security groups
export const getSecurityGroups = async (districtId) => {
  const q = query(
    security_groups,
    where(FIREBASE_FIELD_NAMES.DISTRICT_ID, DOUBLE_EQUALS, districtId)
  );
  return await queryData(q);
};

// get customers
export const getCustomersQueryData = async (districtId) => {
  let q;
  if (districtId === DISTRICT_ID_GET_ALL_LOCATIONS) {
    q = query(customers_collection);
  } else {
    q = query(
      customers_collection,
      where(FIREBASE_FIELD_NAMES.DISTRICT_ID, DOUBLE_EQUALS, districtId)
    );
  }

  return await queryData(q);
};

// get reasons
export const getReasonsQueryData = async () => {
  let q = query(
    reasons_collection,
    where(
      FIREBASE_FIELD_NAMES.DROPDOWN_LIST_TYPE_ID,
      DOUBLE_EQUALS,
      DROPDOWN_LIST_TYPE_ID
    )
  );

  return await queryData(q);
};

//

//get groupRights according to security group id

export const getGroupRightsDetails = async (id, districtId) => {
  const q = query(
    program_security,
    where(FIREBASE_FIELD_NAMES.SECURITY_GROUP_ID, DOUBLE_EQUALS, id),
    where(FIREBASE_FIELD_NAMES.DISTRICT_ID, DOUBLE_EQUALS, districtId),
    where(FIREBASE_FIELD_NAMES.IS_SECURE_BASE, DOUBLE_EQUALS, "1")
  );
  return await queryData(q);
};

// get the module settings
export const getModuleSettings = async () => {
  const currentEnv = process.env.REACT_APP_CF_ENV;
  let environment = "";

  if (currentEnv?.includes(moduleSettingsEnvs.dev.name)) {
    environment = moduleSettingsEnvs.dev.environment;
  } else if (currentEnv?.includes(moduleSettingsEnvs.qa.name)) {
    environment = moduleSettingsEnvs.qa.environment;
  } else {
    environment = moduleSettingsEnvs.prod.environment;
  }

  const q = query(
    module_settings_collection,
    where(FIREBASE_FIELD_NAMES.ENVIRONMENT, DOUBLE_EQUALS, environment),
    where(
      FIREBASE_FIELD_NAMES.MODULE_ID,
      DOUBLE_EQUALS,
      "SecureBase.GeneralSettings"
    )
  );
  return await queryData(q);
};

// get all locations by user branch
export const getAllLocationsByBranch = async (docId) => {
  const q = query(
    api_results_collection_resultset0(docId),
    where(
      FIREBASE_FIELD_NAMES.DISTRICT_ID,
      DOUBLE_EQUALS,
      getAuthenticatedUserBranch()
    )
  );

  return await queryData(q);
};

//get groupRights according to security group id
export const getEmployeeSearchDetails = async () => {
  const q = query(employee);
  return await queryData(q);
};

//get districts
export const getDistricts = async () => {
  const q = query(branches);
  return await queryData(q);
};

//get job titles
export const getJobTitles = async (districtId) => {
  const q = query(
    job_title,
    where(FIREBASE_FIELD_NAMES.DISTRICT_ID, DOUBLE_EQUALS, districtId)
  );
  return await queryData(q);
};

//get operational centers
export const getOperationalCenters = async (districtId) => {
  const q = query(
    operational_center,
    where(FIREBASE_FIELD_NAMES.DISTRICT_ID, DOUBLE_EQUALS, districtId)
  );
  return await queryData(q);
};

// get countries
export const getCountries = async () => {
  const q = query(country);
  return await queryData(q);
};

//get employee by id
export const getEmployeeDetailsById = async (id) => {
  const q = query(
    employee_collection,
    where(FIREBASE_FIELD_NAMES.EMPLOYEE_ID, DOUBLE_EQUALS, id)
  );
  return await queryData(q);
};
//get systemID
export const getSystemId = async (systemId) => {
  const q = query(
    operational_mode_collection,
    where(FIREBASE_FIELD_NAMES.SYSTEM_ID, DOUBLE_EQUALS, systemId)
  );
  return await queryData(q);
};

//get employee by email
export const getEmployeeDetailsByEmail = async (email, fieldName) => {
  const q = query(employee_collection, where(fieldName, DOUBLE_EQUALS, email));
  return await queryData(q);
};

//get locale by id
export const getLocaleById = async (id, fieldName) => {
  const q = query(locale_collection, where(fieldName, DOUBLE_EQUALS, id));
  return await queryData(q);
};

// get countries
export const getPersonnelCustomer = async (fieldName, id) => {
  const q = query(
    personnel_cust_pers_collection,
    where(fieldName, DOUBLE_EQUALS, id)
  );
  const res = await queryData(q);
  return res;
};

export const getCustomers = async (branchArray) => {
  let allocatedBranches = [];
  if (branchArray?.length > 0) {
    const chunkSize = 30;
    const chunks = [];
    for (let i = 0; i < branchArray?.length; i += chunkSize) {
      chunks.push(branchArray?.slice(i, i + chunkSize));
    }

    for (const chunk of chunks) {
      const q = query(
        customers_collection,
        where(FIREBASE_FIELD_NAMES.CUSTOMER_ID, IN, chunk)
      );
      const querySnapshotOfBranches = await getDocs(q);
      querySnapshotOfBranches.forEach((doc) => {
        allocatedBranches.push({
          id: doc.id,
          ...doc.data()
        });
      });
    }
  }

  return allocatedBranches;
};

export const getTransportationVendorData = async (req) => {
  let q = query(vendor_transport);

  // Construct the query based on the presence of parameters
  if (req) {
    if (req.name !== undefined && req.name !== t("common.all")) {
      q = query(q, where(FIREBASE_FIELD_NAMES.NAME, DOUBLE_EQUALS, req.name));
    }
    if (req.city !== undefined && req.city !== t("common.all")) {
      q = query(q, where(FIREBASE_FIELD_NAMES.CITY, DOUBLE_EQUALS, req.city));
    }
    if (
      req.state_province_id !== undefined &&
      req.state_province_id !== t("common.all")
    ) {
      q = query(
        q,
        where(
          FIREBASE_FIELD_NAMES.STATE_PROVINCE_ID,
          DOUBLE_EQUALS,
          req.state_province_id
        )
      );
    }
  }

  return await queryData(q);
};

export const getStateProvinceData = async () => {
  const q = query(state_province);
  return await queryData(q);
};

export const getCustomName = async () => {
  const q = query(appsettings_collection);
  return await queryDataForCustomName(
    q,
    FIREBASE_COLLECTION_NAMES?.EMAIL_CONFIGS
  );
};

export const getFromEmail = async () => {
  const q = query(appsettings_collection);
  return await queryDataForSenderMailList(
    q,
    FIREBASE_COLLECTION_NAMES?.EMAIL_CONFIGS
  );
};

export const getDoNotReplyConfiguration = async () => {
  const q = query(appsettings_collection);
  return await queryDataForDoNotReplyConfiguration(
    q,
    FIREBASE_COLLECTION_NAMES?.EMAIL_CONFIGS
  );
};
export const getTimeOutTime = async () => {
  let time;
  const q = query(appsettings_collection);
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.id === FIREBASE_COLLECTION_NAMES?.TIMEOUT_IN_MIN_SB) {
      time = doc.data()?.timeInMin;
    }
  });
  return time;
};

export const getENVDetails = async () => {
  let envs;
  const q = query(appsettings_collection);
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.id === FIREBASE_COLLECTION_NAMES?.CIAM) {
      envs = doc.data()?.envMap;
    }
  });
  return envs;
};

export const getProgramSecurityList = async (
  uniqueRoleIds,
  district_id,
  security_id
) => {
  const chunkSize = 30; // in can handle only 30 values per time
  const chunks = [];
  for (let i = 0; i < uniqueRoleIds.length; i += chunkSize) {
    chunks.push(uniqueRoleIds.slice(i, i + chunkSize));
  }

  let allocatedBranches = [];
  for (const chunk of chunks) {
    const q = query(
      program_security,
      where(FIREBASE_FIELD_NAMES.SECURITY_GROUP_ID, IN, chunk),
      where(FIREBASE_FIELD_NAMES.PROGRAM_ID, DOUBLE_EQUALS, security_id),
      where(FIREBASE_FIELD_NAMES.DISTRICT_ID, DOUBLE_EQUALS, district_id)
    );
    const querySnapshotOfBranches = await getDocs(q);
    querySnapshotOfBranches.forEach((doc) => {
      allocatedBranches.push({
        id: doc?.id,
        ...doc?.data(),
        label: doc?.data().name,
        value: doc?.id
      });
    });
  }
  return allocatedBranches;
};

export const getInventoryCompareDifference = async (typeId) => {
  const q = query(
    global_attribute,
    where(FIREBASE_FIELD_NAMES.GLOBAL_ATTRIBUTE_ID, DOUBLE_EQUALS, typeId)
  );
  return await queryData(q);
};

export const getFormatsBasedOnCountry = async (countryId, attributeId) => {
  const q = query(
    country_attribute_format_collection,
    where(FIREBASE_FIELD_NAMES.COUNTRY_ID, DOUBLE_EQUALS, countryId),
    where(
      FIREBASE_FIELD_NAMES.COUNTRY_ATTRIBUTE_TYPE_ID,
      DOUBLE_EQUALS,
      attributeId
    )
  );
  return await queryData(q);
};

export const getAudiType = async (code) => {
  const q = query(
    audit_type_collection,
    where(FIREBASE_FIELD_NAMES.GROUP_CODE, DOUBLE_EQUALS, code)
  );
  return await queryData(q);
};

export const getAuditStatusData = async () => {
  const q = query(audit_status_collection);
  return await queryData(q);
};

export const getRequestorTypeData = async (typeId) => {
  const q = query(
    dropdown_list,
    where(FIREBASE_FIELD_NAMES.DROPDOWN_LIST_TYPE_ID, DOUBLE_EQUALS, typeId)
  );
  return await queryData(q);
};

export const getRequestType = async () => {
  const q = query(
    dropdown_list,
    where(
      FIREBASE_FIELD_NAMES.DROPDOWN_LIST_TYPE_ID,
      DOUBLE_EQUALS,
      openMediaAuditDetailConst.dropdownListTypeId
    )
  );
  return await queryData(q);
};

export const getAudiTypeMedia = async () => {
  const q = query(audit_type_collection);
  return await queryData(q);
};

export const getMediaType = async () => {
  const q = query(media_type_collection);
  return await queryData(q);
};

export const getAuditSampleMethod = async () => {
  const q = query(
    audit_sample_method,
    where(FIREBASE_FIELD_NAMES.ACTIVE_FLAG, DOUBLE_EQUALS, Y_YES)
  );
  return await queryData(q);
};

export const getNotOnHoldActiveCustomers = async () => {
  const q = query(
    customers_collection,
    // filter by current district
    where(
      FIREBASE_FIELD_NAMES.DISTRICT_ID,
      DOUBLE_EQUALS,
      getAuthenticatedUserBranch()
    ),
    // filter by active users
    where(FIREBASE_FIELD_NAMES.ACTIVE_FLAG, DOUBLE_EQUALS, Y_CHECKER),
    // filter by on-hold users
    where(FIREBASE_FIELD_NAMES.ON_HOLD, DOUBLE_EQUALS, String(false))
  );
  return await queryData(q);
};

export const getExchangeStatus = async () => {
  const q = query(
    exchange_status_collection,
    where(FIREBASE_FIELD_NAMES.EXCHANGE_STATUS_ID, "in", [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "15",
      "11"
    ])
  );
  return await queryData(q);
};

export const getServiceResult = async () => {
  const q = query(
    service_result_source_complete_collection,
    where(
      FIREBASE_FIELD_NAMES?.SERVICE_SOURCE_ID,
      DOUBLE_EQUALS,
      service_source_id_2
    )
  );
  const srData = await queryData(q);

  const filteredData = srData
    ?.filter((s) => s?.service_result_descr?.trim() !== "")
    ?.map((service) => {
      return {
        ...service,
        label: service?.service_result_descr,
        value: service?.service_source_id
      };
    });
  return filteredData;
};

export const getLockTypesData = async () => {
  const q = query(
    lock_types_collection,
    where(FIREBASE_FIELD_NAMES?.ACTIVE_FLAG, DOUBLE_EQUALS, Y_CHECKER)
  );
  const data = await queryData(q);
  const mappedData = data?.map((i) => {
    return { ...i, label: i?.lock_type_code, value: i?.lock_type_code };
  });
  return mappedData;
};

export const getLocalCountryByLocalID = async (localID) => {
  const q = query(
    locale_collection,
    where(FIREBASE_FIELD_NAMES?.LOCALE_ID, DOUBLE_EQUALS, localID)
  );
  return await queryData(q);
};

export const getStateProvinceDataByCountry = async (localCountry) => {
  const q = query(
    state_province,
    where(FIREBASE_FIELD_NAMES?.COUNTRY_ID, DOUBLE_EQUALS, localCountry)
  );
  return await queryData(q);
};

export const getExchangeStatusById = async (statusId) => {
  const q = query(
    exchange_status_collection,
    where(
      FIREBASE_FIELD_NAMES.EXCHANGE_STATUS_ID,
      DOUBLE_EQUALS,
      String(statusId)
    )
  );
  return await queryData(q);
};

export const getServiceFrequencyCollection = async () => {
  const q = query(service_frequency_collection);
  return await queryData(q);
};

export const getDayOfWeekCollection = async () => {
  const q = query(day_of_week_collection);
  return await queryData(q);
};

export const getWeekOfMonthCollection = async () => {
  const q = query(week_of_month_collection);
  return await queryData(q);
};

export const getCustomerByID = async (customer_id) => {
  const q = query(
    customers_collection,
    where(FIREBASE_FIELD_NAMES.CUSTOMER_ID, DOUBLE_EQUALS, customer_id)
  );
  return await queryData(q);
};

export const getHolidayServiceTypes = async () => {
  const q = query(
    reasons_collection,
    where(FIREBASE_FIELD_NAMES.ACTIVE_FLAG, DOUBLE_EQUALS, Y_CHECKER),
    where(
      FIREBASE_FIELD_NAMES.DROPDOWN_LIST_TYPE_ID,
      DOUBLE_EQUALS,
      dropdownListValue
    )
  );
  return await queryData(q);
};

export const getServiceIndicatorType = async () => {
  const q = query(
    service_indicator_type,
    where(FIREBASE_FIELD_NAMES.ACTIVE_FLAG, DOUBLE_EQUALS, Y_YES)
  );
  return await queryData(q);
};
export const getLocalCountries = async () => {
  const q = query(locale_collection);
  return await queryData(q);
};

export const getYearsForHolidays = async (branch) => {
  const q = query(
    holidays_years_collection,
    where(FIREBASE_FIELD_NAMES.DISTRICT_ID, DOUBLE_EQUALS, branch)
  );
  return await queryData(q);
};

export const getHolidaysForBranchAndYear = async (branch, year) => {
  const q = query(
    holidays_collection,
    where(FIREBASE_FIELD_NAMES.DISTRICT_ID, DOUBLE_EQUALS, branch),
    where(FIREBASE_FIELD_NAMES.YEAR, DOUBLE_EQUALS, `${year}`)
  );
  return await queryData(q);
};

//get global attribute
export const getFileSectionStatus = async (id) => {
  const q = query(
    file_section_status,
    where(FIREBASE_FIELD_NAMES.FILE_SECTION_STATUS_ID, DOUBLE_EQUALS, id)
  );
  return await queryData(q);
};

export const getSuggestionType = async (id) => {
  const q = query(
    suggestion_type,
    where(FIREBASE_FIELD_NAMES.SUGGESTION_TYPE_ID, DOUBLE_EQUALS, id)
  );
  return await queryData(q);
};
// get alert types
export const getAlertType = async () => {
  const q = query(alertType);
  return await queryData(q);
};
export const getFeedbackRecipient = async (id) => {
  const q = query(
    email_group_address,
    where(FIREBASE_FIELD_NAMES.EMAIL_GROUP_CODE_DISTRICT_ID, DOUBLE_EQUALS, id)
  );
  return await queryData(q);
};
export const getMediaProgramType = async (id) => {
  const q = query(
    media_program_type,
    where(FIREBASE_FIELD_NAMES.MEDIA_PROGRAM_TYPE_ID, DOUBLE_EQUALS, id)
  );
  return await queryData(q);
};

export const getSettingsContentReport = async (id) => {
  const q = query(
    setting_type,
    where(FIREBASE_FIELD_NAMES.SETTING_TYPE_ID, DOUBLE_EQUALS, id)
  );
  return await queryData(q);
};
export const getFillRootCauseCombo = async () => {
  const q = query(
    root_cause_type,
    where(FIREBASE_FIELD_NAMES.ACTIVE_FLAG, DOUBLE_EQUALS, Y_CHECKER)
  );
  return await queryData(q);
};

export const getGlobalAttributeCollection = async (id, system_id) => {
  const q = query(
    global_attribute_collection,
    where(FIREBASE_FIELD_NAMES.GLOBAL_ATTRIBUTE_TYPE_ID, "==", id),
    where(FIREBASE_FIELD_NAMES.SYSTEM_ID, "==", system_id)
  );
  return await queryData(q);
};

export const getMediaTypeList = async (custId, vaultID) => {
  const q = query(
    lv_media_type,
    where(FIREBASE_FIELD_NAMES.CUSTOMER_ID, "==", custId),
    where(FIREBASE_FIELD_NAMES.LOGICAL_VAULT_ID, "==", vaultID)
  );
  return await queryData(q);
};

export const getGlobalAttribute = async (id, system_id) => {
  const q = query(
    global_attribute_collection,
    where(FIREBASE_FIELD_NAMES.GLOBAL_ATTRIBUTE_ID, DOUBLE_EQUALS, id),
    where(FIREBASE_FIELD_NAMES.SYSTEM_ID, DOUBLE_EQUALS, system_id)
  );
  return await queryData(q);
};

export const getReportFormatAppSetting = async (url) => {
  if (!url) {
    return null;
  }
  let endpoint = extractEndpointFromURL(url);
  let result = [];
  const q = query(appsettings_collection);
  const querySnapshot = await getDocs(q);
  querySnapshot?.forEach((doc) => {
    result.push({ id: doc?.id, ...doc?.data() });
  });
  let reportConfigs = result.find(
    (item) => item.id === FIREBASE_FIELD_NAMES.REPORT_CONFIGURATION
  );
  if (endpoint && Object.keys(reportConfigs[endpoint]).length) {
    return reportConfigs[endpoint];
  } else {
    return null;
  }
};
