import dayjs from "dayjs";

export const allValue = "all";

export const researchBy = {
  customer: "byCustomer",
  run: "byRun"
};

export const containerNumberMaxRecords = 571;
export const customerMediaIdMaxRecords = 190;

// exchange status of container
export const exchangeStatus = {
  tobeIssued: 0,
  atImosdp: 1,
  pendingDistribution: 2,
  generatedPickList: 3,
  verifiedPicking: 4,
  staging: 5,
  staged: 6,
  generatedRunList: 7,
  runPrep: 8,
  preparedForRun: 9,
  delivered: 10,
  pickedUp: 11,
  arrivedAtImosdp: 12,
  generatedDistribution: 13,
  verifiedDistribution: 14,
  deleted: 15
};

// export file format options
export const fileFormatOptions = [
  { value: 1, label: "Inventory file - Standard (Text)", ext: ".txt" },
  { value: 2, label: "Inventory file - Excel (CSV)", ext: ".csv" }
];

// file types
export const fileTypes = {
  csv: "text/csv",
  txt: "text/plain"
};

export const maximumReturnDate = dayjs("2079/05/31");
export const runRulesSystemFunctionId = "21";
export const initialRangeOfTransactionDetail = "1";
export const updateContainerSystemFunctionId = "21";
export const containerLabelPrintersFilterKey = "C";
export const getCARDateRange = "30/12/1899";
export const dateFormatScheduledServices = "MM-DD-YYYY";
export const transactionDetailRowsPerPage = 120;
export const carMaxRowCount = 101;
export const createFileDateFormat = "DD-MMM-YYYY hhmmss";
export const textFileHeaderStarts = "Cust";
export const csvFileHeaderStarts = `"Cust`;
export const anchorTagName = "a";
export const containerInventoryDateFormat = "YYYY-MM-DD";
export const maxRowNumbersCount = 1000;
export const carStatusClosed = "Closed";
