/* 
Header Styles 
*/
const APP_BAR = {
  backgroundColor: "#fff",
  borderBottom: "1px solid rgba(30, 65, 122, 0.50)",
  boxShadow: "none",
  borderRadius: "0"
};

const HEADER_CONTAINER = {
  display: "flex",
  justifyContent: "space-between",
  maxWidth: "none !important",
  paddingLeft: "70px !important",
  paddingRight: "70px !important"
};

const BOX = {
  display: "flex",
  padding: "10px 0px",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: "0"
};

const BOX_BRANCH = {
  display: "flex",
  minWidth: "200px",
  flexDirection: "column",
  alignItems: "flex-start"
};

const BOX_PROFILE = {
  display: "flex",
  padding: "12px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
};

const BOX_HELP = {
  ...BOX_PROFILE,
  cursor: "pointer"
};

const MENU_LIST = {
  position: "absolute",
  top: "100%",
  left: 0,
  backgroundColor: "#FFF",
  zIndex: 2,
  borderRadius: "5px",
  borderBottom: "1px solid rgba(30, 65, 122, 0.50)",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  listStyleType: "none",
  margin: 0,
  paddingInlineStart: "0"
};

const SUB_MENU_LIST = {
  position: "absolute",
  top: 0,
  left: "100%",
  backgroundColor: "white",
  zIndex: 2,
  borderRadius: "5px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  listStyleType: "none",
  margin: 0,
  paddingInlineStart: "0",
  whiteSpace: "nowrap"
};

const NAV_BUTTON = {
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "26px",
  letterSpacing: "0.46px",
  textTransform: "none"
};

const NAV_LINK = {
  textDecoration: "none",
  fontFamily: "sans-serif",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  color: "black"
};

const TOOLTIP_MAIN_DIV = {
  padding: "10px"
};

const NAME_MAIN_DIV = {
  fontSize: "16px"
};

const EMAIL_DIV = {
  fontSize: "12px"
};

const AUTO_COMPONENT = {
  minWidth: "200px",
  paddingTop: "20px",
  margin: "0 10px"
};
const LIST_STYLE = { paddingTop: 0, paddingBottom: 0 };
const HEADER_STYLES = {
  APP_BAR,
  HEADER_CONTAINER,
  BOX,
  BOX_BRANCH,
  BOX_HELP,
  BOX_PROFILE,
  MENU_LIST,
  SUB_MENU_LIST,
  NAV_BUTTON,
  NAV_LINK,
  TOOLTIP_MAIN_DIV,
  NAME_MAIN_DIV,
  EMAIL_DIV,
  AUTO_COMPONENT,
  LIST_STYLE
};

export default HEADER_STYLES;
