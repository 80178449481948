import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  ROLE_ACCESS_CODES,
  Y_CHECKER,
  messageCode
} from "utils/constants";

import { Button, Grid, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as ViewIcon } from "assets/images/RemoveRedEyeFilled.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ADMIN_MODULE_BASE_PATH, EMPLOYEE_DETAIL } from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import { commonStyles } from "styles/commonStyles";
import spacing from "styles/spacing";
import { findErrorMessage, getResponseData } from "utils/helpers";
import { EmployeeSearchStyles } from "./EmployeeSearchStyles";

const EmployeeSearchTable = ({
  searchResults,
  isResetGrid,
  setIsVisiblePrint,
  jobTitle,
  setWarningAlertPopup,
  setWarningMessage,
  setLoading,
  setThrowErrorMessage,
  setThrowError
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roleAccess, auth } = useSelector(selectAuth);

  //get employe detail view right
  const employeeDetailView = async (loggedInEmployeeId, selectedEmployeeId) => {
    try {
      setLoading(true);
      let reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        employee_id: loggedInEmployeeId
      });

      let data = await getResponseData(
        reqBody,
        `${CF_URLS.employeeDetail.getLinkedEmpidList}`,
        1
      );
      let [results] = data?.data?.[0] || {};

      const employeeLinked = results?.linked_employee_list.includes(
        String(selectedEmployeeId)
      );

      return employeeLinked;
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  };

  //navigate on edit click
  const onNavigateEmployeeClick = async (value) => {
    //check login user have access to edit the employee detail
    const viewAcees = await employeeDetailView(
      auth?.user?.employee_id,
      value?.rowData[0]
    );
    const roleRights = roleAccess.includes(ROLE_ACCESS_CODES.CODE_12);
    if (roleRights && viewAcees) {
      navigate(
        `${ADMIN_MODULE_BASE_PATH}/${EMPLOYEE_DETAIL}/${Number(
          value?.rowData[0]
        )}`
      );
    } else {
      const errorMsg = await getMessageFromCode(
        String(messageCode.doNotHaveSecurityAccess)
      );
      if (errorMsg) {
        window.scrollTo({ top: 0 });
        setWarningAlertPopup(true);
        setWarningMessage(errorMsg[0]?.descr);
      }
    }
  };

  //new employee button click
  const onNavigateNewEmployeeClick = () => {
    navigate(`${ADMIN_MODULE_BASE_PATH}/${EMPLOYEE_DETAIL}`);
  };

  //print button click
  const handlePrintClick = () => {
    setIsVisiblePrint(true);
  };

  //Table columns
  const columns = [
    {
      name: "employee_id",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Button onClick={() => onNavigateEmployeeClick(tableMeta)}>
            <SvgIcon component={ViewIcon} />
          </Button>
        )
      }
    },
    {
      name: "employee_name",
      label: t("employeeSearch.nameCol"),
      options: {
        customBodyRender: (value, tableMeta) => {
          const boldRow = tableMeta.rowData[3] === Y_CHECKER;
          return (
            <Typography
              sx={boldRow ? EmployeeSearchStyles.boldRow : null}
            >{value}</Typography>
          );
        }
      }
    },
    {
      name: "title",
      label: t("employeeSearch.jobTitleCol"),
      options: {
        customBodyRender: (value, tableMeta) => {
          const boldRow = tableMeta.rowData[3] === Y_CHECKER;
          return (
            <Typography sx={boldRow ? EmployeeSearchStyles.boldRow : null}>
              {value}
            </Typography>
          );
        }
      }
    },
    {
      name: "emergency_contact_flag",
      options: {
        display: false
      }
    }
  ];

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    page: 0,
    customToolbar: searchResults.length
      ? () => (
          <>
            <Grid
              item
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="end"
              gap={spacing.verticalMargin20}
            >
              <Button
                variant="outlined"
                size="medium"
                type="button"
                onClick={handlePrintClick}
              >
                {t("common.print")}
              </Button>
              <Button
                variant="outlined"
                size="medium"
                type="button"
                onClick={onNavigateNewEmployeeClick}
              >
                {t("employeeSearch.newEmployee")}
              </Button>
            </Grid>
          </>
        )
      : null,
    textLabels: {
      body: {
        noMatch:
          !isResetGrid && searchResults.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    }
  };
  return (
    <>
      <MUIDataTable
        columns={columns}
        options={options}
        data={searchResults}
        title={
          !isResetGrid && (
            <Typography style={commonStyles.tableRecordsFoundTitleStyle}>
              {searchResults?.length} {t("common.recordsFound")}
            </Typography>
          )
        }
      />
    </>
  );
};

export default EmployeeSearchTable;
