import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useCallbackPrompt } from "hooks";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH,
  SERVICE_INFORMATION
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import {
  getCountries,
  getCustomersQueryData,
  getLocalCountries,
  getLockTypesData,
  getMessageFromCode,
  getStateProvinceDataByCountry
} from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  N_CHECKER,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  accountClosure,
  accountOnHold,
  active_vaults,
  dateFormat,
  downloadOptionPdf,
  errorMsgs,
  exchange_location_type_code,
  minDateServiceInfo,
  no_of_records,
  page_number,
  systemTypeId,
  system_id,
  validationErrorId
} from "utils/constants";
import {
  customerFilter,
  findErrorMessage,
  getAuthenticatedUserBranch,
  getDateFromUTCFormat,
  getResponseData,
  getSortedData,
  getUserName,
  printReport
} from "utils/helpers";
import CustomerServiceInformationAccountClosureModal from "./CustomerServiceInformationAccountClosureModal";
import CustomerServiceInformationModal from "./CustomerServiceInformationModal";
import { CustomerServiceInformationStyles } from "./CustomerServiceInformationStyle";
import CustomerServiceInformationUnsavedChangesModal from "./CustomerServiceInformationUnsavedChangesModal";

const CustomerServiceInformation = () => {
  const { id, navigationData, dataNavigation } = useParams();

  const districtId = getAuthenticatedUserBranch();
  const { currentBranch, auth, localeByBranch } = useSelector(selectAuth);

  const [showPrintReportModal, setShowPrintReportModal] = useState(false);
  const [
    accountChangeConfirmationModalShow,
    setAccountChangeConfirmationModalShow
  ] = useState(false);
  const [accountChangeConfirmationMsg, setAccountChangeConfirmationMsg] =
    useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorAlertMsg, setShowErrorAlertMsg] = useState("");
  const [allLocalCountries, setAllLocalCountries] = useState([]);
  const [allCatalogItems, setAllCatalogItems] = useState([]);
  const [successInsertMessage, setSuccessInsertMessage] = useState("");
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [buttonStatus, setButtonStatus] = useState("");
  const [allLockTypes, setAllLockTypes] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [allStateProvinces, setAllStateProvinces] = useState([]);
  const [allManagedContainerData, setAllManagedContainerData] = useState([]);
  const [allActiveVaults, setAllActiveVaults] = useState([]);
  const [allLongTermVaults, setAllLongTermVaults] = useState([]);
  const [vaultModalType, setVaultModalType] = useState("");
  const [selectedVault, setSelectedVault] = useState(null);
  const [selectedVaultsList, setSelectedVaultsList] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vaultsErrorShow, setVaultsErrorShow] = useState(false);

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [branchLocalId, setBranchLocalId] = useState(null);

  const [
    requiringServiceInformationChecked,
    setRequiringServiceInformationChecked
  ] = useState(false);

  const [reportCUrl, setReportCFUrl] = useState(VALUE_EMPTY_STRING);
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);

  const minDate = dayjs(minDateServiceInfo);

  const maxDate = dayjs().endOf("month").add(1, "month");

  const programSecurityListArray = auth?.user?.program_list
    .split(",")
    .map(Number);

  // fetch and store common district settings
  const fetchCommonDistrictSettings = async () => {
    const requestBody = JSON.stringify({
      main_district_id: districtId
    });
    const response = await getResponseData(
      requestBody,
      `${CF_URLS.containerProcessing.getCommonDistrictSettings}`,
      1
    );
    setBranchLocalId(response?.data[0][0]?.district_locale_id);
  };

  const closeModal = () => {
    setSelectedVault((prev) => null);
    setSelectedVaultsList((prev) => []);
    setVaultModalType("");
    setIsModalOpen(false);
    setVaultsErrorShow(false);
  };

  const handleOpenModal = (type) => {
    const selectedVaults =
      type === active_vaults
        ? allActiveVaults?.filter(
            (v) => v?.Physical_Vault_Selected_Flag === Y_CHECKER
          )
        : allLongTermVaults?.filter(
            (v) => v?.Physical_Vault_Selected_Flag === Y_CHECKER
          );
    setSelectedVaultsList(selectedVaults);
    setVaultModalType(type);
    setIsModalOpen(true);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomersList, setSelectedCustomersList] = useState([]);

  const [formValues, setFormValues] = useState({
    lockType: null,
    address: "",
    building: "",
    floor: "",
    city: "",
    stateProvice: null,
    postalCode: "",
    countryPhoneCode: null,
    dataCenterPhone: "",
    extention: "",
    firstChargableTrip: null,
    transCatalogItem: null,
    managedContainerHandling: null,

    drivingDirections: "",
    serviceNotes: "",
    onSiteDirections: "",
    authorizationNotes: ""
  });

  let initialValues = {
    customer: null,
    salesPerson: "",
    accountOwner: null,
    terminationDate: "",
    lockType: null,
    address: "",
    building: "",
    floor: "",
    city: "",
    stateProvice: null,
    postalCode: "",
    countryPhoneCode: null,
    dataCenterPhone: "",
    extention: "",
    firstChargableTrip: "",
    transCatalogItem: null,
    managedContainerHandling: null,
    customerTimeStamp: "",
    locationTimeStamp: "",
    localeId: "",

    drivingDirections: "",
    serviceNotes: "",
    onSiteDirections: "",
    authorizationNotes: "",

    localCountry: null,
    stateProvinceId: "",
    handlingCatalogItemCode: "",
    managedContainerHandlingCatalogItemCode: null
  };
  const [showField, setShowField] = useState(false);

  const customerServiceInformationForm = useFormik({
    initialValues: initialValues
  });

  const haveUnsavedChanges = useMemo(() => {
    const areOtherValuesChanged =
      formValues?.address !== customerServiceInformationForm?.values?.address ||
      formValues?.building !==
        customerServiceInformationForm?.values?.building ||
      formValues?.floor !== customerServiceInformationForm?.values?.floor ||
      formValues?.city !== customerServiceInformationForm?.values?.city ||
      formValues?.postalCode !==
        customerServiceInformationForm?.values?.postalCode ||
      formValues?.dataCenterPhone !==
        customerServiceInformationForm?.values?.dataCenterPhone ||
      formValues?.extention !==
        customerServiceInformationForm?.values?.extention ||
      formValues?.drivingDirections !==
        customerServiceInformationForm?.values?.drivingDirections ||
      formValues?.serviceNotes !==
        customerServiceInformationForm?.values?.serviceNotes ||
      formValues?.onSiteDirections !==
        customerServiceInformationForm?.values?.onSiteDirections ||
      formValues?.authorizationNotes !==
        customerServiceInformationForm?.values?.authorizationNotes ||
      formValues?.stateProvice !==
        customerServiceInformationForm?.values?.stateProvice?.state_province_id;

    let lockTypeMatch = false;
    if (formValues?.lockType) {
      if (
        formValues?.lockType !==
        customerServiceInformationForm?.values?.lockType?.lock_type_code?.trim()
      ) {
        lockTypeMatch = true;
      }
    } else if (!formValues?.lockType) {
      if (customerServiceInformationForm?.values?.lockType) {
        lockTypeMatch = true;
      }
    }

    let tranceCatelogItemMatch = false;
    if (formValues?.lockType) {
      if (
        formValues?.transCatalogItem !==
        customerServiceInformationForm?.values?.transCatalogItem?.catalog_item_code?.trim()
      ) {
        tranceCatelogItemMatch = true;
      }
    } else if (!formValues?.transCatalogItem) {
      if (customerServiceInformationForm?.values?.transCatalogItem) {
        tranceCatelogItemMatch = true;
      }
    }

    const isInitialCustomerEmpty =
      !customerServiceInformationForm?.values?.customer;

    if (
      !isInitialCustomerEmpty &&
      (lockTypeMatch || tranceCatelogItemMatch || areOtherValuesChanged)
    ) {
      return true;
    } else {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
  }, [customerServiceInformationForm?.values]); // page have unsaved changes or not state

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(haveUnsavedChanges); // browser back and forward blocker hook

  // handleCustomer event
  const handleCustomerChange = async (e, value) => {
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    // Check if the customer value has changed
    const isCustomerChanged =
      value?.value !== customerServiceInformationForm?.values?.customer?.value;

    // Check if any other values have changed
    const areOtherValuesChanged =
      formValues?.address !== customerServiceInformationForm?.values?.address ||
      formValues?.building !==
        customerServiceInformationForm?.values?.building ||
      formValues?.floor !== customerServiceInformationForm?.values?.floor ||
      formValues?.city !== customerServiceInformationForm?.values?.city ||
      formValues?.postalCode !==
        customerServiceInformationForm?.values?.postalCode ||
      formValues?.dataCenterPhone !==
        customerServiceInformationForm?.values?.dataCenterPhone ||
      formValues?.extention !==
        customerServiceInformationForm?.values?.extention ||
      formValues?.drivingDirections !==
        customerServiceInformationForm?.values?.drivingDirections ||
      formValues?.serviceNotes !==
        customerServiceInformationForm?.values?.serviceNotes ||
      formValues?.onSiteDirections !==
        customerServiceInformationForm?.values?.onSiteDirections ||
      formValues?.authorizationNotes !==
        customerServiceInformationForm?.values?.authorizationNotes ||
      formValues?.stateProvice !==
        customerServiceInformationForm?.values?.stateProvice?.state_province_id;

    let lockTypeMatch = false;
    if (formValues?.lockType) {
      if (
        formValues?.lockType !==
        customerServiceInformationForm?.values?.lockType?.lock_type_code?.trim()
      ) {
        lockTypeMatch = true;
      }
    } else if (!formValues?.lockType) {
      if (customerServiceInformationForm?.values?.lockType) {
        lockTypeMatch = true;
      }
    }

    let tranceCatelogItemMatch = false;
    if (formValues?.lockType) {
      if (
        formValues?.transCatalogItem !==
        customerServiceInformationForm?.values?.transCatalogItem?.catalog_item_code?.trim()
      ) {
        tranceCatelogItemMatch = true;
      }
    } else if (!formValues?.transCatalogItem) {
      if (customerServiceInformationForm?.values?.transCatalogItem) {
        tranceCatelogItemMatch = true;
      }
    }

    // Check if no customer is selected initially
    const isInitialCustomerEmpty =
      !customerServiceInformationForm?.values?.customer;

    // Update the customer value if only the customer has changed and no other values have changed
    if (
      isCustomerChanged &&
      !areOtherValuesChanged &&
      !lockTypeMatch &&
      !tranceCatelogItemMatch
    ) {
      customerServiceInformationForm.setFieldValue("customer", value);
      setIsLoading((prev) => false);
      return;
    }

    // If both customer and other values have changed, show the unsaved changes modal
    if (
      isCustomerChanged &&
      (lockTypeMatch || tranceCatelogItemMatch || areOtherValuesChanged)
    ) {
      if (!isInitialCustomerEmpty) {
        setShowUnsavedChangesModal(true);
        setIsLoading((prev) => false);
        return;
      }
      // If initial customer was empty, just update the customer value
      customerServiceInformationForm.setFieldValue("customer", value);
      setIsLoading((prev) => false);
      return;
    }

    // If no significant changes, just update the customer value
    customerServiceInformationForm.setFieldValue("customer", value);
    setIsLoading((prev) => false);
  };

  // Load customers
  const getCustomers = async () => {
    setIsLoading(true);
    try {
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);
      //Filter customers
      result = customerFilter(result);
      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id.trim(),
          number: number.trim(),
          districtIid: districtId
        }));
        setCustomerList((prev) => data);
      } else {
        setCustomerList([]);
      }
      setIsLoading(false);
    } catch (e) {
      setCustomerList([]);
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const getSelectedCustomers = async () => {
    setIsLoading(true);
    try {
      const districtId = getAuthenticatedUserBranch();

      const reqBody = {
        main_district_id: districtId
      };

      const customerSearchResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerServiceInformation?.getActivecustnumtodo}`,
        1
      );

      // Extract the numbers from the first array and trim whitespace
      const numbersToMatch = customerSearchResponse?.data[0]?.map((item) =>
        item?.number.trim()
      );

      // Filter the second array for matches
      const matchingObjects = customerList?.filter((item) =>
        numbersToMatch?.includes(item?.number)
      );

      matchingObjects?.sort((a, b) => a?.number?.localeCompare(b?.number));

      setSelectedCustomersList(matchingObjects);
      setIsLoading(false);
    } catch (error) {
      setCustomerList([]);
      setIsLoading(false);
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const getVisibilityForManagedContainerHandlingCatalogItem = async () => {
    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        system_id: system_id
      };

      const settingsResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerServiceInformation?.getSettingValues}`,
        2
      );

      const settingsError = settingsResponse?.data[1]; // get error code

      if (settingsError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          settingsError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const settingType = settingsResponse?.data[0]?.find(
        (s) => s?.setting_type_id === systemTypeId
      );

      if (settingType?.value === Y_CHECKER) {
        setShowField(true);
      } else {
        setShowField(false);
      }
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  // handle Save Event
  const handleOnSave = async (exitPage = false) => {
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setIsLoading(true);
    const validations = await saveValidationSchema();

    if (validations?.length > 0) {
      const newValue = t("customerServiceInformation.errorHeading");
      validations.unshift(newValue);
      setShowErrorAlert(true);
      setShowErrorAlertMsg(validations);
      setIsLoading(false);
      return;
    }

    try {
      const localForSekectedBranch = allLocalCountries?.find(
        (c) =>
          c?.country_id ===
          customerServiceInformationForm?.values?.countryPhoneCode?.country_id
      );

      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        customer_id: customerServiceInformationForm?.values?.customer?.value,
        exchange_location_type_code: exchange_location_type_code,
        address: customerServiceInformationForm?.values?.address,
        city_name: customerServiceInformationForm?.values?.city,
        state_province_id:
          customerServiceInformationForm?.values?.stateProvice
            ?.state_province_id,
        zip: customerServiceInformationForm?.values?.postalCode,
        data_center_phone:
          customerServiceInformationForm?.values?.dataCenterPhone,
        data_center_extension:
          customerServiceInformationForm?.values?.extention,
        building_number: customerServiceInformationForm?.values?.building,
        floor_number: customerServiceInformationForm?.values?.floor,
        default_lock_type_code: customerServiceInformationForm?.values?.lockType
          ? customerServiceInformationForm?.values?.lockType?.lock_type_code
          : "",
        initiate_service_date: dayjs(
          customerServiceInformationForm?.values?.firstChargableTrip
        ).format(dateFormat.universalFormat),
        service_comment: customerServiceInformationForm?.values?.serviceNotes,
        authorization_comment:
          customerServiceInformationForm?.values?.authorizationNotes,
        driving_directions:
          customerServiceInformationForm?.values?.drivingDirections,
        on_site_directions:
          customerServiceInformationForm?.values?.onSiteDirections,
        service_end_date: "",
        cust_ts1: customerServiceInformationForm?.values?.customerTimeStamp,
        cust_loc_ts1: customerServiceInformationForm?.values?.locationTimeStamp,
        locale_id: localForSekectedBranch?.locale_id,
        trans_catalog_item:
          customerServiceInformationForm?.values?.transCatalogItem
            ?.catalog_item_code,
        mc_handle_catalog_item:
          customerServiceInformationForm?.values
            ?.managedContainerHandlingCatalogItemCode?.catalog_item_code,
        last_mod_user: auth?.user?.login
      };

      const saveResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerServiceInformation?.saveCustomerDetails}`,
        2
      );

      const saveError = saveResponse?.data[1]; // get error code
      // if there is an error showing the error and exit from the process
      if (saveError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(saveError[0]?.error);
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const saveValidationError = saveResponse?.data[0]; // get error code
      // if there is an error showing the error and exit from the process
      if (saveValidationError[0]?.Validation_Error_Id !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode63688
        );
        let homePhoneRequiredError = errMsg?.errorMsg;

        homePhoneRequiredError = homePhoneRequiredError?.replace(
          "|",
          saveValidationError[0]?.Validation_Error_Id === validationErrorId
            ? t("customerServiceInformation.dataCenterPhone")
            : t("customerServiceInformation.postalCodeWithoutStar")
        );

        homePhoneRequiredError = homePhoneRequiredError?.replace(
          "|",
          saveValidationError[0]?.Format_Example
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(homePhoneRequiredError);
        setShowUnsavedChangesModal(false);
        setIsLoading((prev) => false);
        return;
      }

      if (exitPage) {
        // exit page after saving
        confirmNavigation();
        setIsLoading((prev) => false);
        return;
      }

      setShowUnsavedChangesModal(false);
      setIsLoading((prev) => false);
      getCustomerDetails();
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  // save required field validation schema
  const saveValidationSchema = async () => {
    let errorFields = [];

    // address
    if (customerServiceInformationForm?.values?.address?.trim() === "") {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode20354
      );
      let lastNameRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("customerServiceInformation.addressWithoutStar")
      );
      errorFields.push(lastNameRequiredError);
    }

    // city
    if (customerServiceInformationForm?.values?.city?.trim() === "") {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode20354
      );
      let lastNameRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("customerServiceInformation.cityWithoutStar")
      );
      errorFields.push(lastNameRequiredError);
    }

    // stateProvice
    if (!customerServiceInformationForm?.values?.stateProvice) {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode20354
      );
      let lastNameRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("customerServiceInformation.provinceWithoutStar")
      );
      errorFields.push(lastNameRequiredError);
    }

    // postalCode
    if (customerServiceInformationForm?.values?.postalCode?.trim() === "") {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode20354
      );
      let lastNameRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("customerServiceInformation.postalCodeWithoutStar")
      );
      errorFields.push(lastNameRequiredError);
    }

    // countryPhoneCode
    if (!customerServiceInformationForm?.values?.countryPhoneCode) {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode20354
      );
      let lastNameRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("customerServiceInformation.country")
      );
      errorFields.push(lastNameRequiredError);
    }

    // firstChargableTrip
    if (!customerServiceInformationForm?.values?.firstChargableTrip) {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode20354
      );
      let lastNameRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("customerServiceInformation.firstChargeableTrip")
      );
      errorFields.push(lastNameRequiredError);
    }

    // transCatalogItem
    if (!customerServiceInformationForm?.values?.transCatalogItem) {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode20354
      );
      let lastNameRequiredError = errMsg?.errorMsg.replace(
        /\|/g,
        t("customerServiceInformation.transCatalogItem")
      );
      errorFields.push(lastNameRequiredError);
    }

    return errorFields;
  };

  // print button click
  const onPrintButtonCLick = () => {
    setShowPrintReportModal(true);
    setReportCFUrl(CF_URLS.printReports.customerInformation.serviceInformation);
  };

  // cancel button click in download modal
  const onCancelClick = () => {
    setShowPrintReportModal(false);
    setReportCFUrl(VALUE_EMPTY_STRING);
  };

  // download button click in download modal
  const onDownloadClick = async () => {
    setIsLoading(true);
    try {
      const region = process.env.REACT_APP_CF_REGION;
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.value,
        customer_id: customerServiceInformationForm?.values?.customer?.value,
        exchange_location_type_code: exchange_location_type_code,
        service_frequency_flag: N_CHECKER,
        country: customerServiceInformationForm?.values?.countryPhoneCode?.name,
        account_owner: customerServiceInformationForm?.values?.accountOwner
          ? customerServiceInformationForm?.values?.accountOwner?.employee_name
          : "",
        active: "",
        long_term: "",
        customer: customerServiceInformationForm?.values?.customer?.number,
        region_name: region,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });

      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.customerInformation.serviceInformation}`
      );

      if (!reportResponse.success) {
        setShowErrorAlert(true);
        setShowErrorAlertMsg(reportResponse.error);
        setIsLoading(false);
      }
    } catch (e) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onAccountClosureButtonClick = async () => {
    const infoMsg = await getTheFirestoreErrorMessage(errorMsgs?.infoCode80466);
    setAccountChangeConfirmationMsg(infoMsg?.errorMsg);
    setAccountChangeConfirmationModalShow(true);
    setButtonStatus(accountClosure);
  };

  const onNoClick = () => {
    setAccountChangeConfirmationModalShow(false);
  };

  const getLocalCountryList = async () => {
    try {
      const localCountries = await getLocalCountries();
      setAllLocalCountries(localCountries);
    } catch (error) {
      setAllLocalCountries([]);
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const getCustomerDetails = async () => {
    setIsLoading((prev) => true);
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        customer_id: customerServiceInformationForm?.values?.customer?.value,
        exchange_location_type_code: exchange_location_type_code,
        service_frequency_flag: N_CHECKER,
        page_number: page_number,
        no_of_records: no_of_records,
        last_mod_user: ""
      };

      const customerResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerServiceInformation?.getCustomerAndLocationInfo}`,
        5
      );

      const customerError = customerResponse?.data[4]; // get error code

      // if there is an error showing the error and exit from the process
      if (customerError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          customerError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const activeVaults = customerResponse?.data[2]?.map((v) => {
        return {
          ...v,
          label: v?.Physical_Vault_Number,
          value: v?.Physical_Vault_Id,
          isChanged: false
        };
      });
      // Sort the array by Physical_Vault_Number
      activeVaults.sort((a, b) => {
        return a.Physical_Vault_Number.localeCompare(
          b.Physical_Vault_Number,
          undefined,
          { numeric: true }
        );
      });
      setAllActiveVaults(activeVaults);

      const longTermVaults = customerResponse?.data[3]?.map((v) => {
        return {
          ...v,
          label: v?.Physical_Vault_Number,
          value: v?.Physical_Vault_Id,
          isChanged: false
        };
      });
      // Sort the array by Physical_Vault_Number
      longTermVaults.sort((a, b) => {
        return a.Physical_Vault_Number.localeCompare(
          b.Physical_Vault_Number,
          undefined,
          { numeric: true }
        );
      });
      setAllLongTermVaults(longTermVaults);

      customerServiceInformationForm.setFieldValue(
        "customerTimeStamp",
        customerResponse?.data[0][0]?.timestamp
      );
      customerServiceInformationForm.setFieldValue(
        "locationTimeStamp",
        customerResponse?.data[0][0]?.location_timestamp
      );
      customerServiceInformationForm.setFieldValue(
        "salesPerson",
        customerResponse?.data[0][0]?.salesperson_employee_id
      );
      customerServiceInformationForm.setFieldValue(
        "terminationDate",
        customerResponse?.data[0][0]?.termination_date
      );
      customerServiceInformationForm.setFieldValue(
        "address",
        customerResponse?.data[0][0]?.address
      );
      customerServiceInformationForm.setFieldValue(
        "building",
        customerResponse?.data[0][0]?.building_number
      );
      customerServiceInformationForm.setFieldValue(
        "postalCode",
        customerResponse?.data[0][0]?.zip
      );
      customerServiceInformationForm.setFieldValue(
        "dataCenterPhone",
        customerResponse?.data[0][0]?.data_center_phone
      );
      customerServiceInformationForm.setFieldValue(
        "extention",
        customerResponse?.data[0][0]?.data_center_extension
      );
      customerServiceInformationForm.setFieldValue(
        "firstChargableTrip",
        customerResponse?.data[0][0]?.initiate_service_date
      );
      customerServiceInformationForm.setFieldValue(
        "city",
        customerResponse?.data[0][0]?.city_name
      );
      customerServiceInformationForm.setFieldValue(
        "floor",
        customerResponse?.data[0][0]?.floor_number
      );
      customerServiceInformationForm.setFieldValue(
        "drivingDirections",
        customerResponse?.data[0][0]?.driving_directions
      );
      customerServiceInformationForm.setFieldValue(
        "serviceNotes",
        customerResponse?.data[0][0]?.service_comment
      );
      customerServiceInformationForm.setFieldValue(
        "onSiteDirections",
        customerResponse?.data[0][0]?.on_site_directions
      );
      customerServiceInformationForm.setFieldValue(
        "authorizationNotes",
        customerResponse?.data[0][0]?.authorization_comment
      );
      customerServiceInformationForm.setFieldValue(
        "handlingCatalogItemCode",
        customerResponse?.data[0][0]?.mc_handling_catalog_item_code
      );

      // all external calls
      const responses = await Promise.allSettled([
        getAccountOwner(customerResponse?.data[0][0]?.salesperson_employee_id),
        getCatalogItems(),
        getLockTypes(),
        getCountryData(),
        getManagedContainerData(),
        getManagedContainerHandlingCatelogItems()
      ]);

      customerServiceInformationForm.setFieldValue(
        "accountOwner",
        responses[0]?.value
      );

      setAllCatalogItems(responses[1]?.value);
      const difTransCatalogItem = responses[1]?.value?.find(
        (c) =>
          c?.catalog_item_code ===
          customerResponse?.data[0][0]?.trans_catalog_item_code
      );
      customerServiceInformationForm.setFieldValue(
        "transCatalogItem",
        difTransCatalogItem ? difTransCatalogItem : null
      );

      setAllLockTypes(responses[2]?.value);
      const difLocType = responses[2]?.value?.find(
        (l) => l?.id === customerResponse?.data[0][0]?.default_lock_type_code
      );
      customerServiceInformationForm.setFieldValue(
        "lockType",
        difLocType ? difLocType : null
      );

      customerServiceInformationForm.setFieldValue(
        "localeId",
        customerResponse?.data[0][0]?.locale_id === VALUE_EMPTY_STRING ||
          customerResponse?.data[0][0]?.locale_id === null
          ? branchLocalId
          : customerResponse?.data[0][0]?.locale_id
      );

      customerServiceInformationForm.setFieldValue(
        "stateProvinceId",
        customerResponse?.data[0][0]?.state_province_id
      );

      setAllCountries(responses[3]?.value);

      setFormValues((prev) => ({
        ...prev,
        lockType: customerResponse?.data[0][0]?.default_lock_type_code
          ? customerResponse?.data[0][0]?.default_lock_type_code
          : null,
        address: customerResponse?.data[0][0]?.address,
        building: customerResponse?.data[0][0]?.building_number,
        floor: customerResponse?.data[0][0]?.floor_number,
        city: customerResponse?.data[0][0]?.city_name,
        stateProvice: customerResponse?.data[0][0]?.state_province_id,
        postalCode: customerResponse?.data[0][0]?.zip,
        countryPhoneCode: customerResponse?.data[0][0]?.locale_id,
        dataCenterPhone: customerResponse?.data[0][0]?.data_center_phone,
        extention: customerResponse?.data[0][0]?.data_center_extension,
        firstChargableTrip: customerResponse?.data[0][0]?.initiate_service_date,
        transCatalogItem: customerResponse?.data[0][0]?.trans_catalog_item_code
          ? customerResponse?.data[0][0]?.trans_catalog_item_code
          : null,
        managedContainerHandling:
          customerResponse?.data[0][0]?.mc_handling_catalog_item_code,

        drivingDirections: customerResponse?.data[0][0]?.driving_directions,
        serviceNotes: customerResponse?.data[0][0]?.service_comment,
        onSiteDirections: customerResponse?.data[0][0]?.on_site_directions,
        authorizationNotes: customerResponse?.data[0][0]?.authorization_comment
      }));
      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading((prev) => false);
    }
  };

  const getUpdateVaultDetails = async () => {
    setIsLoading((prev) => true);
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        customer_id: customerServiceInformationForm?.values?.customer?.value,
        exchange_location_type_code: exchange_location_type_code,
        service_frequency_flag: N_CHECKER,
        page_number: page_number,
        no_of_records: no_of_records,
        last_mod_user: ""
      };

      const customerResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerServiceInformation?.getCustomerAndLocationInfo}`,
        5
      );

      const customerError = customerResponse?.data[4]; // get error code

      // if there is an error showing the error and exit from the process
      if (customerError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          customerError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const activeVaults = customerResponse?.data[2]?.map((v) => {
        return {
          ...v,
          label: v?.Physical_Vault_Number,
          value: v?.Physical_Vault_Id,
          isChanged: false
        };
      });
      // Sort the array by Physical_Vault_Number
      activeVaults.sort((a, b) => {
        return a.Physical_Vault_Number.localeCompare(
          b.Physical_Vault_Number,
          undefined,
          { numeric: true }
        );
      });
      setAllActiveVaults(activeVaults);

      const longTermVaults = customerResponse?.data[3]?.map((v) => {
        return {
          ...v,
          label: v?.Physical_Vault_Number,
          value: v?.Physical_Vault_Id,
          isChanged: false
        };
      });
      // Sort the array by Physical_Vault_Number
      longTermVaults.sort((a, b) => {
        return a.Physical_Vault_Number.localeCompare(
          b.Physical_Vault_Number,
          undefined,
          { numeric: true }
        );
      });
      setAllLongTermVaults(longTermVaults);
      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading((prev) => false);
    }
  };

  // allocated account owner
  const getAccountOwner = async (salesperson_employee_id) => {
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        employee_id: salesperson_employee_id
      };

      const employeeResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerServiceInformation?.returnEmployeeData}`,
        2
      );

      const employeeError = employeeResponse?.data[1]; // get error code

      // if there is an error showing the error and exit from the process
      if (employeeError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          employeeError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      return employeeResponse?.data[0][0];
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  // catalog items for customer
  const getCatalogItems = async () => {
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        customer_id: customerServiceInformationForm?.values?.customer?.value
      };

      const catalogResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerServiceInformation?.getActiveCatelogItemCode}`,
        2
      );

      const catalogError = catalogResponse?.data[1]; // get error code

      // if there is an error showing the error and exit from the process
      if (catalogError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          catalogError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const mappedCatalog = catalogResponse?.data[0]?.map((c) => {
        return {
          ...c,
          label: c?.catalog_item_code,
          value: c?.catalog_item_code
        };
      });

      return mappedCatalog;
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const getLockTypes = async () => {
    try {
      const locks = await getLockTypesData();
      return locks;
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const getCountryData = async () => {
    const countryResult = await getCountries();
    const countryData = getSortedData(countryResult, "name", "asc");
    const countryResponse = countryData.map((res) => ({
      ...res,
      value: `${res.country_id}`,
      label: `${res.name} - ${res.phone_country_code}`,
      id: res.country_id
    }));

    return countryResponse;
  };

  const getStateProvinceData = async (localCountry) => {
    const stateProvinceResult =
      await getStateProvinceDataByCountry(localCountry);
    const states = stateProvinceResult?.map((s) => {
      return {
        ...s,
        label: s?.name,
        value: s?.state_province_id
      };
    });
    // Sorting function
    states.sort((a, b) => {
      if (a.country_id !== b.country_id) return a.country_id - b.country_id;
      return a.postal_abbreviation.localeCompare(b.postal_abbreviation);
    });

    setAllStateProvinces(states);
  };

  const getManagedContainerData = async () => {
    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        customer_id: customerServiceInformationForm?.values?.customer?.value
      };

      const managedContainerResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerServiceInformation?.getActiveCatelogItemCode}`,
        1
      );

      const managedContainerError = managedContainerResponse?.data[0]; // get error code

      // if there is an error showing the error and exit from the process
      if (managedContainerError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          managedContainerError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      return [];
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const getManagedContainerHandlingCatelogItems = async () => {
    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        customer_id: customerServiceInformationForm?.values?.customer?.value
      };

      const managedContainerResponse = await getResponseData(
        reqBody,
        `${CF_URLS.customerServiceInformation?.activeManageContainerCode}`,
        2
      );

      const managedContainerError = managedContainerResponse?.data[1]; // get error code

      if (managedContainerError[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          managedContainerError[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const mappedData = managedContainerResponse?.data[0]?.map((d) => {
        return {
          ...d,
          label: d?.catalog_item_code,
          value: d?.catalog_item_code
        };
      });

      setAllManagedContainerData(mappedData);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const onAccountOnHoldClick = async () => {
    const infoMsg = await getTheFirestoreErrorMessage(errorMsgs?.infoCode80472);
    setAccountChangeConfirmationMsg(infoMsg?.errorMsg);
    setAccountChangeConfirmationModalShow(true);
    setButtonStatus(accountOnHold);
  };

  const onOkClick = async () => {
    if (buttonStatus === accountOnHold) {
      setIsLoading(true);
      try {
        const reqBody = {
          main_district_id: currentBranch?.value,
          customer_id: customerServiceInformationForm?.values?.customer?.value,
          last_mod_user: auth?.user?.login
        };

        const onHoldResponse = await getResponseData(
          reqBody,
          `${CF_URLS.customerServiceInformation?.onHoldCustomer}`,
          2
        );

        const onHoldError = onHoldResponse?.data[0]; // get error code

        // if there is an error showing the error and exit from the process
        if (onHoldError[0]?.error !== errorMsgs?.successCode80473) {
          const errMsg = await getTheFirestoreErrorMessage(
            onHoldError[0]?.error
          );
          setShowErrorAlert(true);
          setShowErrorAlertMsg(errMsg?.errorMsg);
          setAccountChangeConfirmationModalShow(false);
          setAccountChangeConfirmationMsg("");
          setIsLoading((prev) => false);
          return;
        }

        const successMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.successCode80473
        );
        setSuccessInsertMessage(successMsg?.errorMsg);
        setAccountChangeConfirmationModalShow(false);
        setAccountChangeConfirmationMsg("");
        handleCustomerChange("", null);
        setIsLoading((prev) => false);
        setMessageSuccess(true);
      } catch (error) {
        setShowErrorAlert(true);
        setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
        setIsLoading(false);
      }
    } else if (buttonStatus === accountClosure) {
      setIsLoading(true);
      try {
        const reqBody = {
          main_district_id: currentBranch?.value,
          customer_id: customerServiceInformationForm?.values?.customer?.value,
          last_mod_user: auth?.user?.login
        };

        const inactiveResponse = await getResponseData(
          reqBody,
          `${CF_URLS.customerServiceInformation?.inactiveCustomer}`,
          2
        );

        const inactiveError = inactiveResponse?.data[0]; // get error code

        // if there is an error showing the error and exit from the process
        if (inactiveError[0]?.error !== errorMsgs?.successCode80467) {
          const errMsg = await getTheFirestoreErrorMessage(
            inactiveError[0]?.error
          );
          setShowErrorAlert(true);
          setShowErrorAlertMsg(errMsg?.errorMsg);
          setAccountChangeConfirmationModalShow(false);
          setAccountChangeConfirmationMsg("");
          setIsLoading((prev) => false);
          return;
        }

        const successMsg = await getTheFirestoreErrorMessage(
          errorMsgs?.successCode80467
        );
        setSuccessInsertMessage(successMsg?.errorMsg);
        setAccountChangeConfirmationModalShow(false);
        setAccountChangeConfirmationMsg("");
        handleCustomerChange("", null);
        setMessageSuccess(true);
      } catch (error) {
        setShowErrorAlert(true);
        setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
        setIsLoading(false);
      }
    }
  };

  const onCancelButtonClick = async () => {
    handleCustomerChange("", null);
  };

  const onAddButtonClickInVaultModal = (selectedVault, vaultModalType) => {
    setSelectedVaultsList((prev) => [
      ...prev,
      {
        ...selectedVault,
        isChanged: true
      }
    ]);
    if (vaultModalType === active_vaults) {
      let allActive = allActiveVaults?.map((item) => {
        const { Physical_Vault_Id, Physical_Vault_Number, value, label } = item;
        if (
          Physical_Vault_Id === selectedVault?.Physical_Vault_Id &&
          Physical_Vault_Number === selectedVault?.Physical_Vault_Number &&
          value === selectedVault?.value &&
          label === selectedVault?.label
        ) {
          return {
            ...item,
            isChanged: true
          };
        }
        return item;
      });
      setAllActiveVaults(allActive);
    } else {
      let allActive = allLongTermVaults?.map((item) => {
        const { Physical_Vault_Id, Physical_Vault_Number, value, label } = item;
        if (
          Physical_Vault_Id === selectedVault?.Physical_Vault_Id &&
          Physical_Vault_Number === selectedVault?.Physical_Vault_Number &&
          value === selectedVault?.value &&
          label === selectedVault?.label
        ) {
          return {
            ...item,
            isChanged: true
          };
        }
        return item;
      });
      setAllLongTermVaults(allActive);
    }
    setSelectedVault(null);
  };

  const onVaultSelect = (item) => {
    setVaultsErrorShow(false);
    setSelectedVault(item?.target?.value);
  };

  const onVaultRemove = (id, vaultModalType) => {
    const updatedVaults = selectedVaultsList?.filter(
      (_, index) => index !== id
    );
    let temItem = selectedVaultsList?.find((_, index) => index === id);
    if (vaultModalType === active_vaults) {
      let allActive = allActiveVaults?.map((item) => {
        const { Physical_Vault_Id, Physical_Vault_Number, value, label } = item;
        if (
          Physical_Vault_Id === temItem?.Physical_Vault_Id &&
          Physical_Vault_Number === temItem?.Physical_Vault_Number &&
          value === temItem?.value &&
          label === temItem?.label
        ) {
          return {
            ...item,
            isChanged: false
          };
        }
        return item;
      });
      setAllActiveVaults(allActive);
    } else {
      let allActive = allLongTermVaults?.map((item) => {
        const { Physical_Vault_Id, Physical_Vault_Number, value, label } = item;
        if (
          Physical_Vault_Id === selectedVault?.Physical_Vault_Id &&
          Physical_Vault_Number === selectedVault?.Physical_Vault_Number &&
          value === selectedVault?.value &&
          label === selectedVault?.label
        ) {
          return {
            ...item,
            isChanged: false
          };
        }
        return item;
      });
      setAllLongTermVaults(allActive);
    }
    setSelectedVaultsList((prev) => updatedVaults);
  };

  // vaults add implementation
  const onSaveClickVaults = async () => {
    setIsLoading(true);
    try {
      const newlyAdded = selectedVaultsList?.filter((n) => n?.isChanged);

      // Extract Physical_Vault_Selected_Flag "Y" items from the first array
      const selectedFromArray1 =
        vaultModalType === active_vaults
          ? allActiveVaults?.filter(
              (item) => item.Physical_Vault_Selected_Flag === Y_CHECKER
            )
          : allLongTermVaults?.filter(
              (item) => item.Physical_Vault_Selected_Flag === Y_CHECKER
            );

      // Extract IDs from the second array
      const idsInArray2 = new Set(
        selectedVaultsList.map((item) => item.Physical_Vault_Id)
      );

      // Find items in selectedFromArray1 that are missing from array2
      const missingFromArray2 = selectedFromArray1.filter(
        (item) => !idsInArray2.has(item.Physical_Vault_Id)
      );

      if (newlyAdded?.length + missingFromArray2?.length === 0) {
        setVaultsErrorShow(true);
        setIsLoading(false);
        return;
      }

      if (newlyAdded?.length > 0) {
        for (let index = 0; index < newlyAdded.length; index++) {
          const element = newlyAdded[index];

          const reqBody = {
            main_district_id: currentBranch?.value,
            customer_id:
              customerServiceInformationForm?.values?.customer?.value,
            long_term_flag:
              vaultModalType === active_vaults ? N_CHECKER : Y_CHECKER,
            physical_vault_id: element?.Physical_Vault_Id
          };

          const vaultInsertResponse = await getResponseData(
            reqBody,
            `${CF_URLS.customerServiceInformation?.customerphysicalvaulttebleinsert}`,
            3
          );

          const vaultInsertError = vaultInsertResponse?.data[0]; // get error code

          // if there is an error showing the error and exit from the process
          if (vaultInsertError[0]?.error !== errorMsgs?.noError) {
            const errMsg = await getTheFirestoreErrorMessage(
              vaultInsertError[0]?.error
            );
            setShowErrorAlert(true);
            setShowErrorAlertMsg(errMsg?.errorMsg);
            setIsLoading((prev) => false);
            return;
          }
        }
      }

      if (missingFromArray2?.length > 0) {
        for (let index = 0; index < missingFromArray2.length; index++) {
          const element = missingFromArray2[index];

          const reqBody = {
            main_district_id: currentBranch?.value,
            customer_id:
              customerServiceInformationForm?.values?.customer?.value,
            long_term_flag:
              vaultModalType === active_vaults ? N_CHECKER : Y_CHECKER,
            physical_vault_id: element?.Physical_Vault_Id
          };

          const vaultInsertResponse = await getResponseData(
            reqBody,
            `${CF_URLS.customerServiceInformation?.customerphysicalvaulttebledelete}`,
            3
          );

          const vaultInsertError = vaultInsertResponse?.data[0]; // get error code

          // if there is an error showing the error and exit from the process
          if (vaultInsertError[0]?.error !== errorMsgs?.noError) {
            const errMsg = await getTheFirestoreErrorMessage(
              vaultInsertError[0]?.error
            );
            setShowErrorAlert(true);
            setShowErrorAlertMsg(errMsg?.errorMsg);
            setIsLoading((prev) => false);
            return;
          }
        }
      }

      closeModal();
      getUpdateVaultDetails();
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const onSelectFieldChange = (item, fieldName) => {
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    if (fieldName === "countryPhoneCode") {
      customerServiceInformationForm.setFieldValue("stateProvice", null);
    }
    customerServiceInformationForm.setFieldValue(
      [fieldName],
      item?.target?.value
    );
  };

  // handle input event
  const handleChange = (name, value) => {
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    customerServiceInformationForm.setFieldValue([name], value);
  };

  const onCancelClickInUnsavedChanges = () => {
    setShowUnsavedChangesModal(false);
  };

  const onNoClickInUnsavedChanges = () => {
    customerServiceInformationForm.setFieldValue("customer", null);
    setShowUnsavedChangesModal(false);
  };

  // handle confirmation popup "onCancel" event handler
  const handleConfirmationOnCancel = () => cancelNavigation();

  // handle confirmation popup "onNo" event handler
  const handleConfirmationOnNo = () => confirmNavigation();

  // handle confirmation popup "onYes" event handler
  const handleConfirmationOnYes = async () => {
    await handleOnSave(true);
  };

  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };

  useEffect(() => {
    Promise.allSettled([
      getCustomers(),
      getVisibilityForManagedContainerHandlingCatalogItem()
    ]);
    fetchCommonDistrictSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customerList?.length) {
      getSelectedCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerList]);

  useEffect(() => {
    getLocalCountryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customerServiceInformationForm?.values?.customer) {
      getCustomerDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerServiceInformationForm?.values?.customer]);

  useEffect(() => {
    if (
      allLocalCountries?.length &&
      customerServiceInformationForm?.values?.localeId
    ) {
      const country = allLocalCountries?.find(
        (c) => c?.locale_id === customerServiceInformationForm?.values?.localeId
      );
      customerServiceInformationForm.setFieldValue("localCountry", country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLocalCountries, customerServiceInformationForm?.values?.localeId]);

  useEffect(() => {
    if (
      allCountries?.length &&
      customerServiceInformationForm?.values?.localCountry
    ) {
      const country = allCountries?.find(
        (c) =>
          c?.country_id ===
          customerServiceInformationForm?.values?.localCountry?.country_id
      );
      customerServiceInformationForm.setFieldValue("countryPhoneCode", country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerServiceInformationForm?.values?.localCountry, allCountries]);

  useEffect(() => {
    if (customerServiceInformationForm?.values?.countryPhoneCode) {
      getStateProvinceData(
        customerServiceInformationForm?.values?.countryPhoneCode?.country_id
      );
    }
  }, [customerServiceInformationForm?.values?.countryPhoneCode]);

  useEffect(() => {
    if (allStateProvinces?.length) {
      const state = allStateProvinces?.find(
        (c) =>
          c?.state_province_id ===
          customerServiceInformationForm?.values?.stateProvinceId
      );
      customerServiceInformationForm.setFieldValue(
        "stateProvice",
        state ? state : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allStateProvinces,
    customerServiceInformationForm?.values?.stateProvinceId
  ]);

  useEffect(() => {
    if (
      allManagedContainerData?.length &&
      customerServiceInformationForm?.values?.handlingCatalogItemCode
    ) {
      const diff = allManagedContainerData?.find(
        (c) =>
          c?.catalog_item_code ===
          customerServiceInformationForm?.values?.handlingCatalogItemCode
      );
      customerServiceInformationForm.setFieldValue(
        "managedContainerHandlingCatalogItemCode",
        diff ? diff : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerServiceInformationForm?.values?.handlingCatalogItemCode,
    allManagedContainerData
  ]);

  useEffect(() => {
    if (id && customerList?.length) {
      const cust = customerList?.find((c) => c?.value === id);
      customerServiceInformationForm.setFieldValue("customer", cust);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, customerList]);

  useEffect(() => {
    if (navigationData && dataNavigation) {
      setRequiringServiceInformationChecked(true);
      window.history.replaceState(
        null,
        "",
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SERVICE_INFORMATION}`
      );
    }
  }, [navigationData, dataNavigation]);

  const getDateObjectFromUTCFormat = (date) => {
    return getDateFromUTCFormat(date, { asObject: true });
  };

  const dayjsValue = customerServiceInformationForm?.values?.firstChargableTrip
    ? getDateObjectFromUTCFormat(
        customerServiceInformationForm?.values?.firstChargableTrip
      )
    : null;

  // button enable dissable logics
  const isSaveButtonShow = () => {
    let shouldShowSaveButton = true;

    if (!customerServiceInformationForm?.values?.customer) {
      return false;
    }

    // Check if all form values match
    const allValuesMatch =
      formValues?.address === customerServiceInformationForm?.values?.address &&
      formValues?.building ===
        customerServiceInformationForm?.values?.building &&
      formValues?.floor === customerServiceInformationForm?.values?.floor &&
      formValues?.city === customerServiceInformationForm?.values?.city &&
      formValues?.firstChargableTrip ===
        customerServiceInformationForm?.values?.firstChargableTrip &&
      formValues?.postalCode ===
        customerServiceInformationForm?.values?.postalCode &&
      formValues?.dataCenterPhone ===
        customerServiceInformationForm?.values?.dataCenterPhone &&
      formValues?.extention ===
        customerServiceInformationForm?.values?.extention &&
      formValues?.drivingDirections ===
        customerServiceInformationForm?.values?.drivingDirections &&
      formValues?.serviceNotes ===
        customerServiceInformationForm?.values?.serviceNotes &&
      formValues?.onSiteDirections ===
        customerServiceInformationForm?.values?.onSiteDirections &&
      formValues?.authorizationNotes ===
        customerServiceInformationForm?.values?.authorizationNotes &&
      formValues?.stateProvice ===
        customerServiceInformationForm?.values?.stateProvice
          ?.state_province_id &&
      formValues?.transCatalogItem ===
        customerServiceInformationForm?.values?.transCatalogItem
          ?.catalog_item_code;

    let lockTypeMatch = true;
    if (formValues?.lockType) {
      if (
        formValues?.lockType ===
        customerServiceInformationForm?.values?.lockType?.lock_type_code?.trim()
      ) {
        lockTypeMatch = true;
      } else {
        lockTypeMatch = false;
      }
    } else if (!formValues?.lockType) {
      if (customerServiceInformationForm?.values?.lockType) {
        lockTypeMatch = false;
      }
    }

    let tranceCatelogItemMatch = true;
    if (formValues?.lockType) {
      if (
        formValues?.transCatalogItem ===
        customerServiceInformationForm?.values?.transCatalogItem?.catalog_item_code?.trim()
      ) {
        tranceCatelogItemMatch = true;
      } else {
        tranceCatelogItemMatch = false;
      }
    } else if (!formValues?.transCatalogItem) {
      if (customerServiceInformationForm?.values?.transCatalogItem) {
        tranceCatelogItemMatch = false;
      }
    }

    // Return false if all values match
    if (lockTypeMatch && tranceCatelogItemMatch && allValuesMatch) {
      shouldShowSaveButton = false;
    }

    return shouldShowSaveButton;
  };

  const isPrintButtonShow = () => {
    if (!customerServiceInformationForm?.values?.customer) {
      return true;
    }

    const areOtherValuesChanged =
      formValues?.address !== customerServiceInformationForm?.values?.address ||
      formValues?.building !==
        customerServiceInformationForm?.values?.building ||
      formValues?.floor !== customerServiceInformationForm?.values?.floor ||
      formValues?.city !== customerServiceInformationForm?.values?.city ||
      formValues?.postalCode !==
        customerServiceInformationForm?.values?.postalCode ||
      formValues?.dataCenterPhone !==
        customerServiceInformationForm?.values?.dataCenterPhone ||
      formValues?.extention !==
        customerServiceInformationForm?.values?.extention ||
      formValues?.drivingDirections !==
        customerServiceInformationForm?.values?.drivingDirections ||
      formValues?.serviceNotes !==
        customerServiceInformationForm?.values?.serviceNotes ||
      formValues?.onSiteDirections !==
        customerServiceInformationForm?.values?.onSiteDirections ||
      formValues?.authorizationNotes !==
        customerServiceInformationForm?.values?.authorizationNotes ||
      formValues?.stateProvice !==
        customerServiceInformationForm?.values?.stateProvice?.state_province_id;

    let lockTypeMatch = false;
    if (formValues?.lockType) {
      if (
        formValues?.lockType !==
        customerServiceInformationForm?.values?.lockType?.lock_type_code?.trim()
      ) {
        lockTypeMatch = true;
      }
    } else if (!formValues?.lockType) {
      if (customerServiceInformationForm?.values?.lockType) {
        lockTypeMatch = true;
      }
    }

    let tranceCatelogItemMatch = false;
    if (formValues?.lockType) {
      if (
        formValues?.transCatalogItem !==
        customerServiceInformationForm?.values?.transCatalogItem?.catalog_item_code?.trim()
      ) {
        tranceCatelogItemMatch = true;
      }
    } else if (!formValues?.transCatalogItem) {
      if (customerServiceInformationForm?.values?.transCatalogItem) {
        tranceCatelogItemMatch = true;
      }
    }

    if (tranceCatelogItemMatch || lockTypeMatch || areOtherValuesChanged) {
      return true;
    }

    return false;
  };

  const isContainerSlottingShow = () => {
    if (!programSecurityListArray?.includes(205)) {
      return true;
    }

    if (allActiveVaults?.length === 0) {
      return true;
    }

    if (allLongTermVaults?.length === 0) {
      return true;
    }

    return false;
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      {showErrorAlert && (
        <Alert
          severity="error"
          icon={<img src={ErrorIcon} alt="error" width="20" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setShowErrorAlert(false);
                setShowErrorAlertMsg("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          style={CustomerServiceInformationStyles?.margin}
        >
          {Array.isArray(showErrorAlertMsg)
            ? showErrorAlertMsg.map((message, index) => (
                <>
                  {index === 0 ? <b>{message}</b> : `${index}) ${message}`}
                  <br />
                </>
              ))
            : showErrorAlertMsg}
        </Alert>
      )}

      {/*Success message popup*/}
      {messageSuccess && (
        <SuccessPopup
          message={successInsertMessage}
          close={setMessageSuccess}
        />
      )}

      <Grid container spacing={2} alignItems="">
        <Grid item md={6} lg={3} sx={2}>
          <CustomersField
            options={
              requiringServiceInformationChecked
                ? selectedCustomersList
                : customerList
            }
            value={customerServiceInformationForm?.values?.customer}
            handleOnChange={handleCustomerChange}
            form={customerServiceInformationForm}
            label={t("personnelValidation.customer")}
            required
          />
        </Grid>

        <Grid item md={6} lg={6} sx={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={requiringServiceInformationChecked}
                  onChange={(e) => {
                    setRequiringServiceInformationChecked(e.target.checked);
                    handleCustomerChange("", null);
                  }}
                  disabled={!selectedCustomersList?.length}
                />
              }
              label={t("dashboard.requiringServiceInformation")}
            />
          </FormGroup>
        </Grid>

        <Grid item md={6} lg={3} sx={2}></Grid>
        {/* <Grid item md={6} lg={3} sx={2}></Grid> */}

        {customerServiceInformationForm?.values?.customer && (
          <>
            {/* accountOwner */}
            <Grid item xs={3} sm={3}>
              <TextField
                id="account_owner "
                name="account_owner "
                label={t("customerServiceInformation.accountOwner")}
                fullWidth
                variant="standard"
                InputProps={{
                  disableUnderline: true
                }}
                value={
                  customerServiceInformationForm?.values?.accountOwner
                    ?.employee_name
                }
                InputLabelProps={
                  CustomerServiceInformationStyles.inputLabelProps
                }
              />
            </Grid>

            {/* termination_date */}
            <Grid item xs={3} sm={3}>
              <TextField
                id="termination_date"
                name="termination_date"
                label={t("customerServiceInformation.terminationDate")}
                fullWidth
                variant="standard"
                InputProps={{
                  disableUnderline: true
                }}
                value={customerServiceInformationForm?.values?.terminationDate}
                InputLabelProps={
                  CustomerServiceInformationStyles.inputLabelProps
                }
                // disabled
              />
            </Grid>

            <Grid item md={6} lg={3} sx={2}>
              <FormControl fullWidth>
                <InputLabel
                  id="branch-selectLabel"
                  shrink={customerServiceInformationForm?.values?.lockType}
                >
                  {t("customerServiceInformation.lockType")}
                </InputLabel>
                <Select
                  labelId="billCycleSelectLabel"
                  id="bill-cycle-select"
                  value={customerServiceInformationForm?.values?.lockType}
                  label={t("customerServiceInformation.lockType")}
                  onChange={(e) => onSelectFieldChange(e, "lockType")}
                  notched={customerServiceInformationForm?.values?.lockType}
                >
                  {allLockTypes?.length > 0 &&
                    allLockTypes?.map((b) => {
                      return (
                        <MenuItem value={b} key={b?.label}>
                          {b?.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            {/* address */}
            <Grid item md={12} lg={6} sx={4}>
              <TextField
                label={t("customerServiceInformation.address")}
                id="address"
                fullWidth
                multiline
                rows={3}
                maxRows={4}
                value={customerServiceInformationForm?.values?.address}
                onChange={(event, newValue) =>
                  handleChange("address", event.target.value)
                }
                InputLabelProps={{
                  shrink: customerServiceInformationForm?.values?.address
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 100 // 100 length
                  }
                }}
              />
            </Grid>

            {/* building */}
            <Grid item md={4} lg={2} sx={1}>
              <TextField
                label={t("customerServiceInformation.building")}
                id="building"
                fullWidth
                value={customerServiceInformationForm?.values?.building}
                InputLabelProps={{
                  shrink: customerServiceInformationForm?.values?.building
                }}
                onChange={(event, newValue) =>
                  handleChange("building", event.target.value)
                }
                InputProps={{
                  inputProps: {
                    maxLength: 4
                  }
                }}
              />
            </Grid>

            {/* floor */}
            <Grid item md={2} lg={1} sx={1}>
              <TextField
                label={t("customerServiceInformation.floor")}
                id="floor"
                fullWidth
                value={customerServiceInformationForm?.values?.floor}
                InputLabelProps={{
                  shrink: customerServiceInformationForm?.values?.floor
                }}
                onChange={(event, newValue) =>
                  handleChange("floor", event.target.value)
                }
                InputProps={{
                  inputProps: {
                    maxLength: 3
                  }
                }}
              />
            </Grid>

            {/* city */}
            <Grid item md={6} lg={3} sx={2}>
              <TextField
                label={t("customerServiceInformation.city")}
                id="city"
                fullWidth
                value={customerServiceInformationForm?.values?.city}
                InputLabelProps={{
                  shrink: customerServiceInformationForm?.values?.city
                }}
                onChange={(event, newValue) =>
                  handleChange("city", event.target.value)
                }
              />
            </Grid>

            {/* stateprovince */}
            <Grid item md={6} lg={3} sx={2}>
              <FormControl fullWidth>
                <InputLabel
                  id="branch-selectLabel"
                  shrink={customerServiceInformationForm?.values?.stateProvice}
                >
                  {t("customerServiceInformation.province")}
                </InputLabel>
                <Select
                  labelId="billCycleSelectLabel"
                  id="bill-cycle-select"
                  label={t("customerServiceInformation.province")}
                  value={customerServiceInformationForm?.values?.stateProvice}
                  notched={customerServiceInformationForm?.values?.stateProvice}
                  onChange={(e) => onSelectFieldChange(e, "stateProvice")}
                >
                  {allStateProvinces?.length > 0 &&
                    allStateProvinces?.map((b) => {
                      return (
                        <MenuItem value={b} key={b?.label}>
                          {b?.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            {/* postalCode */}
            <Grid item md={6} lg={3} sx={2}>
              <TextField
                label={t("customerServiceInformation.postalCode")}
                id="postal_code"
                fullWidth
                value={customerServiceInformationForm?.values?.postalCode}
                InputLabelProps={{
                  shrink: customerServiceInformationForm?.values?.postalCode
                }}
                onChange={(event, newValue) =>
                  handleChange("postalCode", event.target.value)
                }
                InputProps={{
                  inputProps: {
                    maxLength: 9
                  }
                }}
              />
            </Grid>

            {/* countryPhoneCode */}
            <Grid item md={6} lg={3} sx={2}>
              <FormControl fullWidth>
                <InputLabel
                  id="branch-selectLabel"
                  shrink={
                    customerServiceInformationForm?.values?.countryPhoneCode
                  }
                >
                  {t("customerServiceInformation.country")}
                </InputLabel>
                <Select
                  labelId="billCycleSelectLabel"
                  id="bill-cycle-select"
                  label={t("customerServiceInformation.country")}
                  value={
                    customerServiceInformationForm?.values?.countryPhoneCode
                  }
                  notched={
                    customerServiceInformationForm?.values?.countryPhoneCode
                  }
                  onChange={(e) => onSelectFieldChange(e, "countryPhoneCode")}
                >
                  {allCountries?.length > 0 &&
                    allCountries?.map((b) => {
                      return (
                        <MenuItem value={b} key={b?.label}>
                          {b?.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            {/* dataCenterPhone */}
            <Grid item md={4} lg={2} sx={1}>
              <TextField
                label={t("customerServiceInformation.dataCenterPhone")}
                id="data_center_phone"
                fullWidth
                value={customerServiceInformationForm?.values?.dataCenterPhone}
                InputLabelProps={{
                  shrink:
                    customerServiceInformationForm?.values?.dataCenterPhone
                }}
                onChange={(event, newValue) =>
                  handleChange("dataCenterPhone", event.target.value)
                }
              />
            </Grid>

            {/* extension */}
            <Grid item md={2} lg={1} sx={1}>
              <TextField
                label={t("customerServiceInformation.extension")}
                id="extension"
                fullWidth
                value={customerServiceInformationForm?.values?.extention}
                InputLabelProps={{
                  shrink: customerServiceInformationForm?.values?.extention
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 4
                  }
                }}
                onChange={(event) => {
                  const newValue = event.target.value.replace(/[^0-9]/g, "");
                  handleChange("extention", newValue);
                }}
              />
            </Grid>

            {/* firstChargeableTrip */}
            <Grid item md={6} lg={3} sx={2}>
              <FormControl fullWidth>
                <DatePickerField
                  id="serviceDateId"
                  name="serviceDate"
                  label={t("customerServiceInformation.firstChargeableTrip")}
                  locale={localeByBranch}
                  value={dayjsValue}
                  minDate={minDate}
                  maxDate={maxDate}
                  onChange={(value) => {
                    customerServiceInformationForm.setFieldValue(
                      "firstChargableTrip",
                      value
                    );
                    setShowErrorAlert(false);
                    setShowErrorAlertMsg("");
                  }}
                />
              </FormControl>
            </Grid>

            {/* transCatalogItem */}
            <Grid item md={6} lg={3} sx={2}>
              <FormControl fullWidth>
                <InputLabel
                  id="branch-selectLabel"
                  shrink={
                    customerServiceInformationForm?.values?.transCatalogItem
                  }
                >
                  {t("customerServiceInformation.transCatalogItem")}
                </InputLabel>
                <Select
                  labelId="billCycleSelectLabel"
                  id="bill-cycle-select"
                  label={t("customerServiceInformation.transCatalogItem")}
                  value={
                    customerServiceInformationForm?.values?.transCatalogItem
                  }
                  notched={
                    customerServiceInformationForm?.values?.transCatalogItem
                  }
                  onChange={(e) => onSelectFieldChange(e, "transCatalogItem")}
                >
                  {allCatalogItems?.length > 0 &&
                    allCatalogItems?.map((b) => {
                      return (
                        <MenuItem value={b} key={b?.label}>
                          {b?.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            {/* managedContainerHandling */}
            {showField && (
              <Grid item md={6} lg={3} sx={2}>
                <FormControl fullWidth>
                  <InputLabel
                    id="branch-selectLabel"
                    shrink={
                      customerServiceInformationForm?.values
                        ?.managedContainerHandlingCatalogItemCode
                    }
                  >
                    {t("customerServiceInformation.managedContainerHandling")}
                  </InputLabel>
                  <Select
                    labelId="billCycleSelectLabel"
                    id="bill-cycle-select"
                    label={t(
                      "customerServiceInformation.managedContainerHandling"
                    )}
                    value={
                      customerServiceInformationForm?.values
                        ?.managedContainerHandlingCatalogItemCode
                    }
                    notched={
                      customerServiceInformationForm?.values
                        ?.managedContainerHandlingCatalogItemCode
                    }
                    onChange={(e) =>
                      onSelectFieldChange(
                        e,
                        "managedContainerHandlingCatalogItemCode"
                      )
                    }
                  >
                    {allManagedContainerData?.length > 0 &&
                      allManagedContainerData?.map((b) => {
                        return (
                          <MenuItem value={b} key={b?.label}>
                            {b?.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item md={24} lg={12} sx={8}>
              <Typography id="customerServiceInformation" variant="h5">
                {t("customerServiceInformation.subTitle")}
              </Typography>
            </Grid>

            {/* active */}
            <Grid item md={6} lg={3} sx={2}>
              <TextField
                label={t("customerServiceInformation.activeVaults")}
                id="activeVaults"
                fullWidth
                value={allActiveVaults
                  ?.filter(
                    (vault) => vault.Physical_Vault_Selected_Flag === Y_CHECKER
                  )
                  ?.map((vault) => vault.Physical_Vault_Number)
                  ?.join(", ")}
                onChange={(event, newValue) =>
                  handleChange(active_vaults, event.target.value)
                }
                InputLabelProps={{
                  shrink: allActiveVaults
                    ?.filter(
                      (vault) =>
                        vault.Physical_Vault_Selected_Flag === Y_CHECKER
                    )
                    ?.map((vault) => vault.Physical_Vault_Number)
                    ?.join(", ")
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => handleOpenModal(active_vaults)}
                      sx={CustomerServiceInformationStyles.activeVault}
                      disabled={isContainerSlottingShow()}
                    >
                      <AddCircleOutlineIcon
                        sx={CustomerServiceInformationStyles.vaultAddIcon}
                      />
                    </IconButton>
                  )
                }}
                disabled={isContainerSlottingShow()}
              />
            </Grid>

            {/* long term */}
            <Grid item md={6} lg={3} sx={2}>
              <TextField
                label={t("customerServiceInformation.longTerm")}
                id="longTerm"
                fullWidth
                value={allLongTermVaults
                  ?.filter(
                    (vault) => vault.Physical_Vault_Selected_Flag === Y_CHECKER
                  )
                  ?.map((vault) => vault.Physical_Vault_Number)
                  ?.join(", ")}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => handleOpenModal("longTermVaults")}
                      sx={CustomerServiceInformationStyles.activeVault}
                      disabled={isContainerSlottingShow()}
                    >
                      <AddCircleOutlineIcon
                        sx={CustomerServiceInformationStyles.vaultAddIcon}
                      />
                    </IconButton>
                  )
                }}
                onChange={(event, newValue) =>
                  handleChange("longTerm", event.target.value)
                }
                InputLabelProps={{
                  shrink: allLongTermVaults
                    ?.filter(
                      (vault) =>
                        vault.Physical_Vault_Selected_Flag === Y_CHECKER
                    )
                    ?.map((vault) => vault.Physical_Vault_Number)
                    ?.join(", ")
                }}
                disabled={isContainerSlottingShow()}
              />
            </Grid>

            <Grid item md={12} lg={6} sx={4}></Grid>

            <Grid item md={12} lg={6} sx={4}>
              <TextField
                label={t("customerServiceInformation.drivingDirections")}
                id="driving_directions"
                fullWidth
                multiline
                rows={3}
                maxRows={4}
                value={
                  customerServiceInformationForm?.values?.drivingDirections
                }
                onChange={(event, newValue) =>
                  handleChange("drivingDirections", event.target.value)
                }
                InputLabelProps={{
                  shrink:
                    customerServiceInformationForm?.values?.drivingDirections
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 2096
                  }
                }}
              />
            </Grid>

            <Grid item md={12} lg={6} sx={4}>
              <TextField
                label={t("customerServiceInformation.serviceNotes")}
                id="service_notes"
                fullWidth
                multiline
                rows={3}
                maxRows={4}
                value={customerServiceInformationForm?.values?.serviceNotes}
                onChange={(event, newValue) =>
                  handleChange("serviceNotes", event.target.value)
                }
                InputLabelProps={{
                  shrink: customerServiceInformationForm?.values?.serviceNotes
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 2048
                  }
                }}
              />
            </Grid>

            <Grid item md={12} lg={6} sx={4}>
              <TextField
                label={t("customerServiceInformation.onSiteDirections")}
                id="on_site_directions"
                fullWidth
                multiline
                rows={3}
                maxRows={4}
                value={customerServiceInformationForm?.values?.onSiteDirections}
                onChange={(event, newValue) =>
                  handleChange("onSiteDirections", event.target.value)
                }
                InputLabelProps={{
                  shrink:
                    customerServiceInformationForm?.values?.onSiteDirections
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 255
                  }
                }}
              />
            </Grid>

            <Grid item md={12} lg={6} sx={4}>
              <TextField
                label={t("customerServiceInformation.authorizationNotes")}
                id="address"
                fullWidth
                multiline
                rows={3}
                maxRows={4}
                value={
                  customerServiceInformationForm?.values?.authorizationNotes
                }
                onChange={(event, newValue) =>
                  handleChange("authorizationNotes", event.target.value)
                }
                InputLabelProps={{
                  shrink:
                    customerServiceInformationForm?.values?.authorizationNotes
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 2048
                  }
                }}
              />
            </Grid>
          </>
        )}

        <Grid item md={24} lg={12} sx={8}>
          <hr />
        </Grid>

        <Grid item md={24} lg={12} sx={8}>
          <Stack direction="row" gap={spacing.gap}>
            <Button
              id="cancelBtn"
              variant="outlined"
              style={CustomerServiceInformationStyles.buttonsRow.cancel}
              onClick={(e) => onCancelButtonClick(e)}
              disabled={!customerServiceInformationForm?.values?.customer}
            >
              {t("common.cancel")}
            </Button>

            <Button
              id="account"
              variant="outlined"
              style={CustomerServiceInformationStyles.buttonsRow.account}
              onClick={() => onAccountOnHoldClick()}
              disabled={!customerServiceInformationForm?.values?.customer}
            >
              {t("customerServiceInformation.accountOnHold")}
            </Button>

            <Button
              id="closure"
              variant="outlined"
              style={CustomerServiceInformationStyles.buttonsRow.closure}
              onClick={() => onAccountClosureButtonClick()}
              disabled={!customerServiceInformationForm?.values?.customer}
            >
              {t("customerServiceInformation.accountClosure")}
            </Button>

            <Button
              id="print"
              variant="outlined"
              style={CustomerServiceInformationStyles.buttonsRow.print}
              onClick={() => onPrintButtonCLick()}
              disabled={isPrintButtonShow()}
            >
              {t("common.print")}
            </Button>

            <Button
              id="saveBtn"
              variant="contained"
              style={CustomerServiceInformationStyles.buttonsRow.save}
              type="submit"
              onClick={() => handleOnSave()}
              disabled={!isSaveButtonShow()}
            >
              {t("common.save")}
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <CustomerServiceInformationModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        selectedCustomer={
          customerServiceInformationForm?.values?.customer?.number
        }
        vaultData={
          vaultModalType === active_vaults ? allActiveVaults : allLongTermVaults
        }
        vaultModalType={vaultModalType}
        onAddButtonClickInVaultModal={onAddButtonClickInVaultModal}
        onVaultSelect={onVaultSelect}
        selectedVaultsList={selectedVaultsList}
        onVaultRemove={onVaultRemove}
        onSaveClickVaults={onSaveClickVaults}
        vaultsErrorShow={vaultsErrorShow}
        setVaultsErrorShow={setVaultsErrorShow}
        selectedVault={selectedVault}
      />

      {/* Report download */}
      <DownloadPopup
        title={t("customerServiceInformation.reportTitle")}
        showPrintModal={showPrintReportModal}
        onCancleClick={onCancelClick}
        onDownloadClick={onDownloadClick}
        cfUrl={reportCUrl}
        setSelectedValue={setSelectedValue}
      />

      <CustomerServiceInformationAccountClosureModal
        accountChangeConfirmationModalShow={accountChangeConfirmationModalShow}
        onNoClick={onNoClick}
        accountChangeConfirmationMsg={accountChangeConfirmationMsg}
        onOkClick={onOkClick}
      />

      <CustomerServiceInformationUnsavedChangesModal
        showUnsavedChangesModal={showUnsavedChangesModal}
        onCancelClickInUnsavedChanges={onCancelClickInUnsavedChanges}
        handleOnSave={handleOnSave}
        onNoClickInUnsavedChanges={onNoClickInUnsavedChanges}
      />

      {/* Save Changes Confirmation Modal */}
      {showPrompt && (
        <ConfirmPopup
          modalPopupOpen={showPrompt}
          handleYes={handleConfirmationOnYes}
          handleNo={handleConfirmationOnNo}
          showNo={true}
          handleCancel={handleConfirmationOnCancel}
          showCancel={true}
        />
      )}
    </>
  );
};

export default CustomerServiceInformation;
