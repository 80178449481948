/* eslint-disable no-unused-vars */
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, SelectField } from "components/core";
import ModalPopup from "components/core/modal-components/ModalPopup";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import { SearchTableStyles } from "components/features/request-module/media-request-search/search-table/SearchTable.Styles";
import withLoading from "components/hoc/withLoading";
import { PageTitle } from "components/shared";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import { commonStyles } from "styles/commonStyles";
import spacing from "styles/spacing";
import {
  BOOLEAN_STRING_VALUES,
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  corporateAccessLevelDistrictId,
  falseValue,
  initialSearchCount,
  numberConstants,
  trueValue
} from "utils/constants";
import { findErrorMessage, formatNumberByLocale } from "utils/helpers";
import { ReactComponent as CheckFilled } from "../../../assets/images/CheckFilled.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/EditIcon.svg";
import CapacityBillingGenerationDetailModal from "./CapacityBillingGenerationDetailModal";
import DownloadReportModal from "./CapacityBillingGenerationPrintModal";
import { GenerationSearch } from "./CapacityBillingGenerationSearch.style";
import { capacityBillingGenerationSchema } from "./schema";

const CapacityBillingGenerationSearch = () => {
  const { t } = useTranslation();
  const { currentBranch, localeByBranch } = useSelector(selectAuth);
  const selectedBranchId = currentBranch?.district_id;
  const initialState = {
    generation: 0
  };
  //initial state of capacity billing search
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: capacityBillingGenerationSchema
  });
  //initial state of capacity billing Detail
  const DetailInitialState = {
    generationAddNew: "",
    conversionFactor: "",
    active: true
  };

  const handleAddNewDetailForm = (values) => {
    handleSave(values);
  };
  const generationDetailFormik = useFormik({
    initialValues: DetailInitialState,
    validationSchema: capacityBillingGenerationSchema,
    onSubmit: handleAddNewDetailForm
  });

  const [generations, setGenerations] = useState("");
  //last clicked button
  const [isDataFound, setIsDataFound] = useState(false);
  //loader
  const [isLoading, setIsLoading] = useState(false);
  //Disable find button
  const [disableFindButton, setDisableFindButton] = useState(false);
  //Disable the print button
  const [disablePrintButton, setDisablePrintButton] = useState(false);
  //checking is the row is edited or updated
  const [clickOnEdit, setClickOnEdit] = useState(false);
  //data for mui table
  const [searchResults, setSearchResults] = useState([]);
  // const [active, setActive] = useState(true);
  const [activeOnly, setActiveOnly] = useState(false);
  //handle modal
  const [openGeneartionDetailModal, setOpenGeneartionDetailModal] =
    useState(false);
  //selected index of the table row
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  //having the count of the result
  const [searchResultsCount, setSearchResultsCount] = useState(
    searchResults?.length
  );
  //suffixes
  const [suffixes, setSuffixes] = useState([]);
  const branchPrevious = useRef();
  const navigate = useNavigate();
  //alert
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  //open wraining modal
  const [openWarningModal, setOpenWarningModal] = useState(false);
  //success loader
  const [saveSuccessPopup, setSaveSuccsessPopup] = useState(false);
  //success message
  const [saveSuccessMessage, setSaveSuccsessMessage] = useState("");
  //page status
  const [pageToZero, setPageToZero] = useState(false);

  //warining modal
  const handleOpenWarningModal = () => setOpenWarningModal(true);
  const handleCloseWarningModal = () => setOpenWarningModal(false);

  //print
  const [openPrintModal, setOpenPrintModal] = useState(false);

  const [reportCFUrl, setReportCFUrl] = useState();

  //print modal
  const handleOpenPrintModal = () => {
    setOpenPrintModal(true);
    setReportCFUrl("");
  };
  const handleClosePrintModal = () => setOpenPrintModal(false);
  //open generation modal
  const handleOpenGeneartionDetailModal = () =>
    setOpenGeneartionDetailModal(true);
  const handleCloseGeneartionDetailModal = () => {
    setOpenGeneartionDetailModal(false);
  };
  const handleYes = () => {
    handleCloseWarningModal();
    generationDetailFormik.submitForm();
  };

  const handleNo = () => {
    //closing the modals and resetting the initial value
    handleCloseWarningModal();
    setSelectedRowIndex(-1);
    handleCloseGeneartionDetailModal();
    handleCheckFilledIconChanges(false, selectedRowIndex);
    generationDetailFormik.resetForm();
    generationDetailFormik.setTouched({});
  };

  const handleCancel = () => {
    const data = [...searchResults];
    const rowDetails = data[selectedRowIndex];
    const UpdatedValues = generationDetailFormik.values;
    let isAddOrEdit = false;
    if (
      UpdatedValues.generationAddNew.toString() !==
        rowDetails?.suffix.toString() ||
      UpdatedValues?.conversionFactor.toString() !==
        rowDetails?.conversionfactor.toString() ||
      (UpdatedValues.active.toString() !== rowDetails?.isactive.toString() &&
        clickOnEdit)
    ) {
      isAddOrEdit = true;
    }
    if (
      !clickOnEdit &&
      UpdatedValues?.generationAddNew.toString() === "" &&
      UpdatedValues?.conversionFactor.toString() === "" &&
      JSON.parse(UpdatedValues?.active) === true
    ) {
      isAddOrEdit = false;
    }
    handleCheckFilledIconChanges(isAddOrEdit, selectedRowIndex);
    if (isAddOrEdit) {
      handleOpenWarningModal();
    } else {
      handleCloseGeneartionDetailModal();
      setSelectedRowIndex(-1);
    }
  };

  //All Capacity Billing Suffixes
  const handleGetAllCapacityBillingSuffixes = async () => {
    try {
      setIsLoading(true);
      const reqBody = JSON.stringify({
        main_district_id: corporateAccessLevelDistrictId
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.capacityBillingGenerationSearch.getAllCapacityBillingSuffixes
      );
      const res = await getDataFromFirestore(response, 1, response.data.docId);
      if (res.data[0]) {
        if (Array.isArray(res.data[0])) {
          const data = res.data[0]?.map(
            ({ capacity_billing_suffix, capacity_billing_suffix_id }) => ({
              label: capacity_billing_suffix,
              value: capacity_billing_suffix_id
            })
          );
          const sortedData = [...data].sort((a, b) =>
            a.label.localeCompare(b.label)
          ); //sorting the data
          sortedData.unshift({ label: t("requestSearch.all"), value: 0 });
          setSuffixes(sortedData);
        }
        setIsLoading(false);
      } else if (res.error) {
        setAlertMessage(res.error);
        setAlert(true);
      }
      setIsLoading(false);
    } catch (e) {
      setAlertMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setAlert(true);
    }
  };

  useEffect(() => {
    handleGetAllCapacityBillingSuffixes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //checking the branch is changed, if so navigate to dashboard
    if (branchPrevious.current !== selectedBranchId) {
      branchPrevious.current !== undefined && navigate("/");
    }
    branchPrevious.current = selectedBranchId;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBranchId]);

  //handle on clear
  const handleClear = () => {
    setIsDataFound(false);
    formik.resetForm();
    formik.setFieldValue("generation", 0);
    setActiveOnly(false);
    setGenerations("");
    setSearchResults([]);
    setSearchResultsCount(initialSearchCount);
    setDisablePrintButton(false);
    setDisableFindButton(false);
    setPageToZero(true);
  };

  //handle reset
  const handleReset = () => {
    setSearchResults([]);
    setSearchResultsCount(initialSearchCount);
  };
  //handle save
  const handleSave = async (values) => {
    try {
      setIsLoading(true);
      const updatedConversionFactor = values.conversionFactor.replace(",", ".");
      const reqBody = JSON.stringify({
        main_district_id: corporateAccessLevelDistrictId,
        function: "sb_fs_capacity_billing_configuration_i_suffix_sp",
        capacity_billing_suffix: values.generationAddNew,
        active_flag: JSON.parse(values.active) ? trueValue : falseValue,
        capacity_billing_multiplier: updatedConversionFactor
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.capacityBillingGenerationSearch
          .insertCapacityBillingConfigSuffix
      );
      const res = await getDataFromFirestore(response, 1, response.data.docId);
      setIsLoading(false);
      if (res.data[0]) {
        handleCapacityBillingSuffixCF().then(() => {
          handleGetAllCapacityBillingSuffixes().then(() => {
            setSaveSuccsessPopup(true);
            clickOnEdit
              ? setSaveSuccsessMessage(t("common.updateMessage"))
              : setSaveSuccsessMessage(t("common.successfulMessage"));
            handleCloseGeneartionDetailModal();
            setSelectedRowIndex(-1);
          });
        });
      } else if (res.error) {
        setAlertMessage(res.error);
        setAlert(true);
      }
      handleCheckFilledIconChanges(false, selectedRowIndex);
    } catch (e) {
      setAlertMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setAlert(true);
    }
  };

  //Capacity Billing Suffix CF call
  const handleCapacityBillingSuffixCF = async () => {
    try {
      setIsLoading(true);
      const reqBody = JSON.stringify({
        main_district_id: corporateAccessLevelDistrictId,
        function: "capacity_billing_configuration_get_suffix_sp",
        suffix:
          formik.values.generation === Number(numberConstants.zero)
            ? VALUE_EMPTY_STRING
            : suffixes.filter(
                (suf) => suf.value === formik.values.generation
              )[0]?.label,
        active_flag: activeOnly ? numberConstants.one : VALUE_EMPTY_STRING
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.capacityBillingGenerationSearch.getCapacityBillingConfigSuffix
      );

      const res = await getDataFromFirestore(response, 1, response.data.docId);
      setIsDataFound(true);
      setIsLoading(false);
      if (res.data[0]) {
        const rowData = [...(res?.data?.[0] || [])];
        //filtering the data according to active only check box value
        let suffixeValue = activeOnly
          ? rowData.filter((i) => JSON.parse(i?.isactive) === true)
          : rowData;

        let activeSUffix = rowData.filter(
          (suf) => suf?.isactive === BOOLEAN_STRING_VALUES.TRUE
        );
        let inactiveSUffix = rowData.filter(
          (suf) => suf?.isactive === BOOLEAN_STRING_VALUES.FALSE
        );

        let sortActive = activeSUffix.sort((a, b) =>
          a.suffix.localeCompare(b.suffix)
        );
        let sortInactive = inactiveSUffix.sort((a, b) =>
          a.suffix.localeCompare(b.suffix)
        );
        const formattedRowData = [...sortActive, ...sortInactive].map(
          (item) => {
            return {
              ...item,
              conversionfactor: formatNumberByLocale(
                item.conversionfactor,
                localeByBranch
              )
            };
          }
        );
        setSearchResults(formattedRowData);
        //setting the count of the result
        setSearchResultsCount(suffixeValue?.length);
        suffixeValue?.length > 0
          ? setDisablePrintButton(true)
          : setDisablePrintButton(false);
        if (suffixeValue?.length > 0) {
          setDisableFindButton(true);
        }
      } else if (res.error) {
        setAlertMessage(res.error);
        setAlert(true);
      }
    } catch (e) {
      setAlertMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setAlert(true);
    }
  };

  //handle find
  const handleFind = async (event) => {
    event.preventDefault();
    setPageToZero(false);
    await handleCapacityBillingSuffixCF();
  };
  //handle check filled Icon change
  const handleCheckFilledIconChanges = (value, index) => {
    const updatedSearchResults = [...searchResults];
    //updating the existing
    const updatedRowData = {
      ...searchResults[index],
      isAddOrEdit: value
    };
    // Replace the selected row in the searchResults array with the updated row
    updatedSearchResults[index] = updatedRowData;
    // Set the updated searchResults as the new state
    setSearchResults(updatedSearchResults);
  };

  //handle on edit
  const handleEditRow = (_, tableMeta) => {
    setSelectedRowIndex(tableMeta.rowIndex);
    setClickOnEdit(true);
    const selectedData = searchResults[tableMeta.rowIndex];
    handleCheckFilledIconChanges(true, tableMeta.rowIndex);
    //setting the value to show the data in the popup
    generationDetailFormik.setFieldValue(
      "generationAddNew",
      selectedData.suffix
    );
    generationDetailFormik.setFieldValue(
      "conversionFactor",
      selectedData.conversionfactor
    );
    generationDetailFormik.setFieldValue(
      "active",
      JSON.parse(selectedData.isactive)
    );
    handleOpenGeneartionDetailModal();
  };
  //column for MUI table
  const columns = [
    {
      name: "",
      label: "",
      flex: 1,
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <Button onClick={() => handleEditRow(value, tableMeta)}>
              <SvgIcon
                component={
                  searchResults[tableMeta.rowIndex]?.isAddOrEdit === true
                    ? CheckFilled
                    : EditIcon
                }
              />
            </Button>
          );
        }
      }
    },
    {
      name: "suffix",
      label: t("generationSearchDetail.generationColLabel"),
      options: {
        setCellHeaderProps: () => ({
          style: { ...GenerationSearch.tableColumn }
        })
      }
    },
    {
      name: "conversionfactor",
      label: t("generationSearchDetail.conversionFactorColLabel"),
      options: {
        setCellHeaderProps: () => ({
          style: { ...GenerationSearch.tableColumn }
        })
      }
    },
    {
      name: "isactive",
      label: t("generationSearchDetail.activeFlagColLabel"),
      options: {
        setCellHeaderProps: () => ({
          style: { ...GenerationSearch.tableColumn }
        }),
        customBodyRender: (value) =>
          JSON.parse(value)
            ? t("rescheduleService.yes")
            : t("rescheduleService.no")
      }
    }
  ];

  //handle new
  const handleNew = () => {
    generationDetailFormik.setTouched({});
    generationDetailFormik.resetForm();
    setClickOnEdit(false);
    handleOpenGeneartionDetailModal();
  };

  //Option in Mui data tables
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    filter: false,
    print: false,
    search: false,
    sort: true,
    download: false,
    viewColumns: false,
    jumpToPage: searchResultsCount !== 0,
    pagination: searchResultsCount !== 0,
    selectableRowsHideCheckboxes: true,
    page: pageToZero && 0,
    textLabels: {
      body: {
        noMatch:
          isDataFound && searchResultsCount === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      },
      pagination: {
        jumpToPage: (
          <span style={SearchTableStyles.jumpToPage}>
            {t("requestSearch.jumpToPage")}
          </span>
        )
      }
    },
    customToolbar: () => (
      <>
        <Grid
          container
          direction={"row-reverse"}
          spacing={spacing.verticalMargin20}
          id="toolbat-buttons"
        >
          <Grid item>
            <Button variant="outlined" onClick={handleNew} id="new-button">
              {t("pickup.new")}
            </Button>
          </Grid>
          {disablePrintButton && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleOpenPrintModal}
                id="print-button"
              >
                {t("common.print")}
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    ),
    setRowProps: (row, dataIndex) => ({
      style: {
        backgroundColor:
          dataIndex === selectedRowIndex ? "var(--color-im-light-blue-100)" : ""
      }
    })
  };

  //handle active only checkbox
  const handleActiveOnlyCheckBox = (event) => {
    setActiveOnly(event.target.checked);
    setDisablePrintButton(false);
    handleReset();
    setDisableFindButton(false);
    setIsDataFound(false);
  };
  //handle suffix dropdown change
  const handleGenerationChange = (event, newValue) => {
    formik.setFieldValue("generation", event.target.value);
    setGenerations(
      newValue?.props.children === t("requestSearch.all")
        ? ""
        : newValue?.props.children
    );
    setDisableFindButton(false);
    setSearchResultsCount(initialSearchCount);
    setDisablePrintButton(false);
    setSearchResults([]);
    setIsDataFound(false);
  };
  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}
      {/*Success message popup*/}
      {saveSuccessPopup && (
        <SuccessPopup
          message={saveSuccessMessage}
          close={setSaveSuccsessPopup}
        />
      )}
      <form>
        <Stack gap={spacing.horizontalMargin20}>
          <Grid sx={GenerationSearch.TitleStyle}>
            <PageTitle title={t("generationSearchDetail.pageTitle")} />
          </Grid>
          {alert && (
            <Grid item>
              <Alert
                severity="error"
                icon={<img src={ErrorIcon} alt="error" />}
                id="button-wraning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    id="button-wraning-close-btn"
                    onClick={() => {
                      setAlert(false);
                      setAlertMessage("");
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {alertMessage}
              </Alert>
            </Grid>
          )}
          <Grid
            container
            spacing={spacing.verticalMargin20}
            alignItems={"center"}
          >
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <SelectField
                options={suffixes}
                labelId="generation"
                id="generation"
                label={t("generationSearchDetail.generationLabel")}
                value={formik.values.generation}
                onChange={handleGenerationChange}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    id="active-checkbox"
                    checked={activeOnly}
                    onChange={(event) => {
                      handleActiveOnlyCheckBox(event);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Active Only"
              />
            </Grid>
          </Grid>
          <Grid container spacing={spacing.horizontalMargin20}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleClear}
                id="clear-button"
              >
                {t("common.clear")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                disabled={disableFindButton}
                variant="contained"
                onClick={handleFind}
                id="find-button"
              >
                {t("common.find")}
              </Button>
            </Grid>
          </Grid>
          <Grid>
            <Stack gap={spacing.verticalMargin20} id="generation-modal">
              {/* capacity billing modal */}
              <CapacityBillingGenerationDetailModal
                open={openGeneartionDetailModal}
                handleClose={handleCancel}
                generationDetailFormik={generationDetailFormik}
                suffixes={suffixes}
                clickOnEdit={clickOnEdit}
              />
              {/* MUI data table */}
              <MUIDataTable
                columns={columns}
                options={options}
                data={searchResults}
                title={
                  isDataFound && (
                    <Typography
                      style={commonStyles.tableRecordsFoundTitleStyle}
                    >
                      {searchResultsCount} {t("common.recordsFound")}
                    </Typography>
                  )
                }
              />
            </Stack>
          </Grid>
          <ModalPopup
            title={t("pickup.confirm")}
            modalPopupOpen={openWarningModal}
            handleClick={handleCloseWarningModal}
            alertMessage={t("rescheduleService.saveChangesDialogMsg")}
            modalButton={
              <>
                <Button variant="outlined" onClick={handleYes}>
                  {t("common.yes")}
                </Button>
                <Button variant="outlined" onClick={handleNo}>
                  {t("common.no")}
                </Button>
                <Button variant="contained" onClick={handleCloseWarningModal}>
                  {t("common.cancel")}
                </Button>
              </>
            }
          />
          {/*Report download modal*/}
          {openPrintModal && (
            <DownloadReportModal
              isVisible={openPrintModal}
              setIsVisible={setOpenPrintModal}
              mainDistrictId={corporateAccessLevelDistrictId}
              suffix={generations}
              active={activeOnly}
              setIsLoading={setIsLoading}
              throwError={alert}
              setThrowError={setAlert}
              throwErrorMessage={alertMessage}
              setThrowErrorMessage={setAlertMessage}
              reportCFUrl={reportCFUrl}
              setReportCFUrl={setReportCFUrl}
            />
          )}
        </Stack>
      </form>
    </>
  );
};

export default withLoading(CapacityBillingGenerationSearch);
