import { ChevronLeft } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TableContainer,
  Typography
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { FullCircularLoader } from "components/core";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CORRECTIVE_ACTION_REQUESTS_DETAIL,
  CORRECTIVE_ACTION_REQUESTS_SEARCH,
  OPEN_MEDIA_PROCESSING_RESEARCH,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH,
  OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  DEFAULT_LOCALE,
  downloadOptionPdf,
  ERROR_TYPES,
  unauthorized,
  VALUE_N
} from "utils/constants";
import { getTheFirestoreErrorMessage } from "utils/constants/open-media-processing/OpenMediaAuditConstants";
import {
  dateTimeFormatByLocale,
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData,
  getUserName,
  printReport
} from "utils/helpers";
import { openMediaResearchStyles } from "./openMediaResearchStyles";
import { ResearchTableStyles } from "./ResearchTableStyles";

const OpenMediaResearchDetail = () => {
  const params = useParams();
  const location = useLocation();
  const { currentBranch, auth } = useSelector(selectAuth);
  const [tableData, setTableData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDownLoadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [cfUrl, setCFUrl] = useState("");
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [isViewCar, setIsViewCar] = useState(false);

  const districtId = getAuthenticatedUserBranch();
  const { row, customer, localeByBranch } = location.state;
  const navigate = useNavigate();
  let open_media_id = params.id;

  useEffect(() => {
    if (open_media_id && districtId) getData();
    // eslint-disable-next-line
  }, [districtId]);

  // handle session timeout and uncought error
  const handleCatchError = (error) => {
    if (error?.response?.data === unauthorized) {
      setErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
    } else {
      setErrorMessage(t("printTapeLabel.systemError"));
    }
  };

  // Handle error with error code
  const handleError = async (errorCode) => {
    let { errorMsg } = await getTheFirestoreErrorMessage(errorCode);
    setErrorMessage(errorMsg);
  };

  const getData = async () => {
    setLoading(true);
    try {
      let reqBody = {
        main_district_id: districtId,
        open_media_id,
        car_flag: ""
      };
      let data = await getResponseData(
        reqBody,
        CF_URLS.openMediaProcessing.research.getopenmediahistory,
        2
      );
      data = data.data;
      if (data[1]) {
        setIsViewCar(data[1][0].car_flag);
      }

      if (data[0] && data[0][0]?.error) {
        handleError(data[0][0]?.error);
        return;
      }
      let tableDataList = data[0]
        ?.sort((a, b) => {
          return dayjs(a.transaction_datetime).isAfter(
            dayjs(b.transaction_datetime)
          )
            ? -1
            : 1;
        })
        .map((item) => {
          return {
            ...item,
            transaction_datetime: dateTimeFormatByLocale(
              item.transaction_datetime,
              localeByBranch || DEFAULT_LOCALE
            )
          };
        });

      setTableData(tableDataList);
    } catch (error) {
      handleCatchError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadOpen = () => {
    let URL = CF_URLS.printReports.openMediaProcessing.getopenmediareport;
    setIsDownloadModalOpen(true);
    setCFUrl(URL);
  };

  const handleDownloadCancel = () => {
    setIsDownloadModalOpen(false);
    setCFUrl("");
  };

  const onClickPrintButtonClick = async () => {
    setLoading(true);

    let reqBody = JSON.stringify({
      main_district_id: districtId,
      district_id: districtId,
      open_media_id: open_media_id,
      district_name: currentBranch.name.replace("_", " "),
      customer_number: row.customer_number.trim(),
      media_number: row.volser,
      current_status: row.current_status_code,
      current_location: row.location,
      current_description: row.descr,
      user_name: getUserName(auth?.user),
      report_format: selectedValue?.type?.toUpperCase()
    });

    let URL = CF_URLS.printReports.openMediaProcessing.getopenmediareport;
    try {
      let reportResponse = await printReport(reqBody, URL);
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
      setIsDownloadModalOpen(false);
      setCFUrl("");
    } catch (error) {
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => {
    let route = `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${OPEN_MEDIA_PROCESSING_RESEARCH}`;
    navigate(route);
  };

  const goToViewCar = async () => {
    try {
      setLoading(true);
      const reqBody = {
        main_district_id: currentBranch?.value,
        car_id: null,
        car_type_id: null,
        customer_id: customer?.value || "",
        from_date: null,
        to_date: null,
        failure_point_id: null,
        root_cause_id: null,
        car_status_id: null,
        days_at_status: null,
        max_row_count: "1001",
        billable_flag: null,
        media_number: row?.volser,
        om_container_flag: "O",
        sort_order: null,
        asc_desc: null,
        ignore_cars_with_unresolved_discrepancies: VALUE_N,
        show_cars_ready_for_mgmt_review_only_flag: VALUE_N
      };

      const carSummarayResponse = await getResponseData(
        reqBody,
        CF_URLS.correctiveActionRequest.carSearchUrl,
        1
      );

      if (carSummarayResponse?.data[0]?.length === 1) {
        const record = carSummarayResponse?.data[0][0];
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`,
          { state: { record } }
        );
      }
      if (carSummarayResponse?.data[0]?.length > 1) {
        const record = { customer_id: customer?.value };
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_SEARCH}`,
          { state: { record } }
        );
      }
    } catch (error) {
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  };

  let columns = [
    {
      label: t("openMediaResearch.requestId"),
      name: "request_id"
    },
    {
      label: t("openMediaResearch.requestType"),
      name: "request_type"
    },
    {
      label: t("openMediaResearch.drEvent"),
      name: "dr_event"
    },
    {
      label: t("openMediaResearch.systemFunction"),
      name: "system_function"
    },
    {
      label: t("openMediaResearch.previousState"),
      name: "previous_status"
    },
    {
      label: t("openMediaResearch.currentStatusLabel"),
      name: "current_status"
    },
    {
      label: t("openMediaResearch.slotLocation"),
      name: "slot_location"
    },
    {
      label: t("openMediaResearch.logicalVaultLabel"),
      name: "logical_vault"
    },
    {
      label: t("openMediaResearch.mediaTypeLabel"),
      name: "media_type"
    },
    {
      label: t("openMediaResearch.transactionDateTime"),
      name: "transaction_datetime"
    },
    {
      label: t("openMediaResearch.transport"),
      name: "transport"
    },
    {
      label: t("openMediaResearch.employee"),
      name: "employee"
    },
    {
      label: t("openMediaResearch.scannedFlag"),
      name: "scanned_flag"
    },
    {
      label: t("openMediaResearch.batchId"),
      name: "batch_id"
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    pagination: true,
    textLabels: {
      body: {
        noMatch: (
          <Box pb={spacing.gap}>
            <Typography variant="caption" sx={ResearchTableStyles.noDataText}>
              {t("common.tableTextWithoutInputs")}
            </Typography>
          </Box>
        )
      }
    },

    customToolbar: () => (
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" id="printBtn" onClick={handleDownloadOpen}>
            {t("common.print")}
          </Button>
          <Button
            variant="outlined"
            id="printBtn"
            onClick={goToViewCar}
            disabled={isViewCar === VALUE_N}
          >
            {t("openMediaResearch.viewCar")}
          </Button>
        </Stack>
      </Stack>
    )
  };
  return (
    <>
      <Stack
        direction="column"
        gap={spacing.verticalMargin20}
        mb={spacing.verticalMargin32}
      >
        {loading && <FullCircularLoader />}
        {errorMessage && (
          <Box mb={spacing.gap}>
            <Alert
              severity="error"
              sx={ResearchTableStyles.errorAlert}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setErrorMessage(false)}
                >
                  <GridCloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMessage}
            </Alert>
          </Box>
        )}

        <Grid container spacing={spacing.horizontalMargin20}>
          <Grid item xs={3}>
            <Typography variant="subtitle1" color="textSecondary">
              {t("openMediaResearch.customer")}
            </Typography>
            <Typography variant="subtitle1">
              {row.customer_number || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" color="textSecondary">
              {t("openMediaResearch.mediaNumber")}
            </Typography>
            <Typography variant="subtitle1">{row.volser || "-"}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" color="textSecondary">
              {t("openMediaResearch.currentStatus")}
            </Typography>
            <Typography variant="subtitle1">
              {row.current_status_code || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" color="textSecondary">
              {t("openMediaResearch.currentLocation")}
            </Typography>
            <Typography variant="subtitle1">{row.location || "-"}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" color="textSecondary">
              {t("openMediaResearch.description")}
            </Typography>
            <Typography variant="subtitle1">{row.descr || "-"}</Typography>
          </Grid>
        </Grid>
        <Paper>
          <TableContainer>
            <MUIDataTable
              columns={columns}
              options={options}
              data={tableData}
              title={
                <Typography variant="subtitle1">
                  {tableData.length} {t("common.recordsFound")}
                </Typography>
              }
            />
          </TableContainer>
        </Paper>
        <Box mt={spacing.gap}>
          <Typography variant="caption">
            {t("openMediaResearch.locationLegend")}
          </Typography>
        </Box>
        <Divider />
        <Box mt={spacing.gap}>
          <Button
            variant="outlined"
            sx={openMediaResearchStyles.backButton}
            onClick={goBack}
          >
            <ChevronLeft /> {t("common.back")}
          </Button>
        </Box>
      </Stack>
      <DownloadPopup
        title={t("common.downloadReport")}
        showPrintModal={isDownLoadModalOpen}
        onCancleClick={handleDownloadCancel}
        onDownloadClick={onClickPrintButtonClick}
        setSelectedValue={setSelectedValue}
        setThrowError={setThrowError}
        throwError={throwError}
        throwErrorMessage={throwErrorMessage}
        cfUrl={cfUrl}
      />
    </>
  );
};

export default OpenMediaResearchDetail;
