import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  SvgIcon,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import {
  dateFormatWithoutTime,
  dateFormatWithTime,
  dateFormatWithTimeWithSeconds,
  dayMonthYearFormat,
  DEFAULT_DATA_TABLE_OPTIONS,
  errorMsgs,
  N_CHECKER,
  Y_CHECKER
} from "utils/constants";
import { DiscrepencyStyles } from "./discrepancyStyles";

import { ReactComponent as ViewIcon } from "assets/images/RemoveRedEyeFilled.svg";
import { useEffect, useRef, useState } from "react";
import {
  CONTAINER_INVENTORY_AUDIT_DETAIL,
  CORRECTIVE_ACTION_REQUESTS_DETAIL,
  DISCREPANCY_MANAGEMENT_DETAIL,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH,
  OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH
} from "routing/Paths";
import { getMessageFromCode } from "services/api/query";
import {
  setError,
  setType,
  setUpdateSelectedList
} from "store/slices/discrepancy-search";
import {
  changePage,
  containerDescripancyTypes,
  containerDiscrepancyTitle,
  customerMediaDescr,
  detectedInType,
  discrepancyTypeOrder,
  indefiniteDate,
  inventoryAuditSystemFunctionId,
  mediaTypes,
  mediaTypesTitle,
  OMText,
  resolutionTypes,
  suggestionTypeTitle,
  systemFunctionTitle,
  TRtext
} from "utils/constants/discrepancy-management/DiscrepancySearchConstants";
const ContainerAuditTable = ({
  tableData,
  selectedContainerRow,
  setSelectedContainerRow,
  selectedContainerId,
  setSelectedContainerId,
  handleNavigationClick,
  isResetGrid,
  selectedRow,
  setSelectedRow,
  customerMediaIndentifiersFlag,
  branchOwnedText,
  hasUpdatePermission,
  hasPermission327
}) => {
  const { localeByBranch } = useSelector(selectAuth);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedCheckedList, setSelectedCheckedList] = useState([]);
  const [selectedAllRows, setSelectedAllRows] = useState(null);
  const [containerPageNo, setContainerPageNo] = useState(0);
  const [isDisabled, setIsDisabled] = useState(null);
  const dispatch = useDispatch();
  const { searchFormValues } = useSelector((state) => state.discrepancySearch);
  const { isAuthenticated } = useSelector((state) => state.discrepancyUpdate);
  const selectedRowsData = useRef([]);

  const handleRowSelect = async(event, tableMeta, rowData) => {
    const customerId = rowData.customer_id;
    if (event.target.checked) {
      selectedRowsData.current = [...selectedRowsData.current, rowData];
      //check selected row is equal with other selected items
      if (selectedRowsData.current?.length > 1) {
        const allSame = (rowData.customerId === selectedRowsData.current[0].customerId &&
          rowData.runId === selectedRowsData.current[0].runId &&
          rowData.systemFunctionId ===
          selectedRowsData.current[0].systemFunctionId &&
            rowData.discrepancyType ===
            selectedRowsData.current[0].discrepancyType &&
          rowData.hasMedia === selectedRowsData.current[0].hasMedia &&
          rowData.isTransprot === selectedRowsData.current[0].isTransprot &&
          rowData.pendingReturnDate ===
          selectedRowsData.current[0].pendingReturnDate);
        if (!allSame) {
          const errMsg = await getMessageFromCode(errorMsgs.errorCode80278);
          //remove row from selected row detail state
          selectedRowsData.current = selectedRowsData.current.filter(item => item.index !== rowData.index);
          dispatch(setError(errMsg[0]?.descr));
          return;
        }
      }

      setSelectedAllRows(true);
      setSelectedCheckedList((prev) => [...prev, rowData.discrepancyId]);
      if (!selectedCustomerId || selectedCustomerId === customerId) {
        setSelectedCustomerId(customerId);
        setSelectedContainerRow((prevRows) => {
          return Array.isArray(prevRows) ? [...prevRows, rowData] : [rowData];
        });
      }
      let uId =
        rowData?.customerId?.trim() +
        rowData?.runId?.trim() +
        rowData.systemFunctionId?.trim() +
        rowData?.discrepancyTypeCode?.trim() +
        rowData?.hasMedia?.trim() +
        rowData.isTransprot?.trim() +
        rowData?.pendingReturnDate?.trim();
      setIsDisabled(uId);
    } else {
      //remove row from selected row detail state
      selectedRowsData.current = selectedRowsData.current.filter(item => item.index !== rowData.index);
      setSelectedContainerRow((prevRows) =>
        prevRows?.filter((row) => row.discrepancyId !== rowData.discrepancyId)
      );
      let listItem = selectedCheckedList?.filter(
        (item) => item !== rowData.discrepancyId
      );
      setSelectedCheckedList([...listItem]);
      if (!listItem?.length) {
        setIsDisabled(null);
        setSelectedAllRows(false);
      }
      if (!selectedRow.some((row) => row.customer_id === customerId)) {
        setSelectedCustomerId(null);
      }
    }
  };

  const handleRowClick = (value, tableMeta) => {
    setSelectedContainerId(value?.id);
    setSelectedContainerRow(mappedData[tableMeta?.rowIndex]);
    let type = t("discrepancyManagement.container");
    let path = `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT_DETAIL}`;
    handleNavigationClick({ value, path, type });
    dispatch(setUpdateSelectedList([{ ...mappedData[tableMeta?.rowIndex] }]));
    dispatch(setType(type));
  };
  useEffect(() => {
    if (selectedContainerRow?.length === 0) {
      setSelectedCheckedList([]);
      setSelectedAllRows(false);
      setIsDisabled(null);
      setContainerPageNo(0);
    }
  }, [selectedContainerRow]);
  const convertDatebyLocale = (date, format) => {
    let selectedFormat = format ? format : dateFormatWithoutTime;
    import(`dayjs/locale/${localeByBranch}.js`);
    const formattedDate = date
      ? dayjs.utc(date).locale(localeByBranch).format(selectedFormat)
      : "";
    return formattedDate;
  };

  const mappedData =
    tableData &&
    tableData
      ?.map((row, index) => {
        let detectedValue =
          row.system_function_id &&
          detectedInType[systemFunctionTitle + row.system_function_id];

        return {
          index: index,
          fileSectionTypeId: row.file_section_type_id,
          customerNumber: row.assigned_to_customer_number,
          mediaNumber:
            row.customer_id !== row.assigned_to_customer_id &&
            !row.container_number?.includes(branchOwnedText)
              ? row.container_number + `(${branchOwnedText})`
              : row.om_in_transport_flag === Y_CHECKER
                ? row.container_number + OMText
                : row.container_number,
          customerMediaDescr: row.customer_media_descr,
          discrepancyType:
            row.discrepancy_type_code &&
            containerDescripancyTypes[
              containerDiscrepancyTitle +
                row.discrepancy_type_code?.replace(/\s+/g, "").toLowerCase() +
                row.system_function_id
            ],
          detectedValue: detectedValue,
          detectedInDateTime: convertDatebyLocale(
            row.detected_in_datetime,
            dateFormatWithTime
          ),
          resolutionType:
            row.suggestion_type_code &&
            resolutionTypes[
              suggestionTypeTitle +
                row.suggestion_type_code?.replace(/-/g, "").toLowerCase()
            ],
          carId: row.car_id,
          mediaType:
            row.media_type_id &&
            mediaTypes[mediaTypesTitle + row.media_type_id],
          mediaTypeId: row.media_type_id,
          serviceDate: row.service_date,
          run: row.run_name,
          runId: row.run_id,
          pendingReturnDate: row.pending_return_date,
          displayToCustomerDatetime: row.display_to_customer_datetime,
          resolvedDatetime: row.resolved_datetime,
          resolvedByName: row.resolved_by_name,
          originalRequestId: row.original_request_id,
          containerNumber: row.container_number,
          containerAuditId: row.container_audit_id,
          customerName: row.customer_name,
          lastModDate: row.last_mod_date,
          logicalVaultCode: row.logical_vault_code,
          doNotCallPrefValue: row.do_not_call_pref_value,
          districtId: row.district_id,
          lastKnownLocation: row.last_known_location,
          detectedDatetime: row.detected_datetime,
          discrepancyTypeCode: row.discrepancy_type_code,
          customerFileSectionId: row.customer_file_section_id,
          discrepancyTypeContext: row.discrepancy_type_context,
          discrepancyId: row.discrepancy_id,
          volserNumber: row.volser_number,
          requestId: row.request_id,
          resolvedFlag: row.resolved_flag,
          suggestionTypeId: row.suggestion_type_id,
          requiresCourtesyCallFlag: row.requires_courtesy_call_flag,
          suggestionTypeCode: row.suggestion_type_code,
          originalSystemFunctionId: row.original_system_function_id,
          detectedInDatetime: row.detected_in_datetime,
          carCustomerDisplayFlag: row.car_customer_display_flag,
          logicalVaultId: row.logical_vault_id,
          resolvedById: row.resolved_by_id,
          customerId: row.customer_id,
          detectedInContext: row.detected_in_context,
          auditStatusId: row.audit_status_id,
          systemFunctionId: row.system_function_id,
          hasMedia: row.om_in_transport_flag,
          isTransprot: row?.container_number?.includes(TRtext)
            ? Y_CHECKER
            : N_CHECKER
        };
      })
      ?.sort((a, b) => {
        if (a.discrepancyType && b.discrepancyType) {
          const discrepancyOrder =
            discrepancyTypeOrder.indexOf(a.discrepancyType) -
            discrepancyTypeOrder.indexOf(b.discrepancyType);
          if (discrepancyOrder !== 0) return discrepancyOrder;
        }
        if (a.customerNumber < b.customerNumber) return -1;
        if (a.customerNumber > b.customerNumber) return 1;
        if (a.containerAuditId < b.containerAuditId) return -1;
        if (a.containerAuditId > b.containerAuditId) return 1;
        if (a.mediaNumber < b.mediaNumber) return -1;
        if (a.mediaNumber > b.mediaNumber) return 1;
        return 0;
      });

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: tableData?.length > 0,
    responsive: "standard",
    overflowX: "auto",
    sortOrder: {
      name: "name",
      direction: "asc"
    },
    onTableChange: (action, tableState) => {
      if (action === changePage) {
        setContainerPageNo(tableState.page);
      }
    },
    page: containerPageNo,
    textLabels: {
      body: {
        noMatch:
          isResetGrid && tableData.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    }
  };
  const columns = [
    {
      name: "",
      label: "",
      options: {
        sort: false,
        customHeadRender: (columnMeta, updateValue) => {
          return (
            <Box sx={DiscrepencyStyles.checkHeaderStyles}>
              <Checkbox
                checked={selectedAllRows}
                disabled={selectedRow?.length > 0 || mappedData?.length === 0}
                onChange={async(event) => {
                  if (event.target.checked) {
                    // Check if all rows have the same discrepancyTypeCode,  customerId etc..
                    const allSame =
                      mappedData?.length > 0 &&
                      mappedData?.every((row) => {
                        return (
                          row.customerId === mappedData[0].customerId &&
                          row.runId === mappedData[0].runId &&
                          row.systemFunctionId ===
                            mappedData[0].systemFunctionId &&
                          row.discrepancyType ===
                          mappedData[0].discrepancyType &&
                          row.hasMedia === mappedData[0].hasMedia &&
                          row.isTransprot === mappedData[0].isTransprot &&
                          row.pendingReturnDate ===
                          mappedData[0].pendingReturnDate
                        );
                      });
                    if (allSame) {
                      setSelectedAllRows(true);
                      setSelectedContainerRow(mappedData);
                      setIsDisabled(mappedData[0]?.customerNumber);
                      const newCheckedList = mappedData.map(
                        (row) => row.discrepancyId
                      );
                      setSelectedCheckedList((prev) => [
                        ...prev,
                        ...newCheckedList
                      ]);
                    } else {
                      const errMsg = await getMessageFromCode(errorMsgs.errorCode80278);
                      setSelectedContainerRow([]);
                      setSelectedAllRows(false);
                      setSelectedCheckedList([]);
                      dispatch(setError(errMsg[0]?.descr));
                      setIsDisabled(null);
                    }
                  } else {
                    setSelectedContainerRow([]);
                    setSelectedCheckedList([]);
                    setSelectedAllRows(false);
                    setIsDisabled(null);
                    selectedRowsData.current = [];
                  }
                }}
              />
            </Box>
          );
        },
        customBodyRender: (value, tableMeta) => {
          let row = tableMeta?.currentTableData[tableMeta.rowIndex];
          let dataIndex = row.index;
          let rowData = mappedData[dataIndex];
          const isAudit =
            rowData?.systemFunctionId === inventoryAuditSystemFunctionId &&
            rowData?.auditStatusId?.length === 0;

          let uId =
            rowData?.customerId?.trim() +
            rowData?.runId?.trim() +
            rowData.systemFunctionId?.trim() +
            rowData?.discrepancyTypeCode?.trim() +
            rowData?.hasMedia?.trim() +
            rowData.isTransprot?.trim() +
            rowData?.pendingReturnDate?.trim();
          return (
            <>
              {(rowData.resolvedFlag === N_CHECKER ||
                (isAuthenticated && !hasUpdatePermission) ||
                !hasPermission327) && (
                <Checkbox
                  checked={selectedCheckedList.includes(rowData.discrepancyId)}
                  onChange={(event) =>
                    handleRowSelect(event, tableMeta, rowData)
                  }
                  disabled={
                    isAudit ||
                    (selectedRow?.length > 0
                      ? true
                      : selectedCheckedList.includes(rowData.discrepancyId)
                        ? false
                        : isDisabled
                          ? !(isDisabled === uId)
                          : false)
                  }
                />
              )}
            </>
          );
        }
      }
    },
    {
      name: "",
      label: "",

      options: {
        sort: false,
        setCellProps: () => ({
          style: DiscrepencyStyles.actionColumnStyle
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = mappedData[tableMeta.rowIndex];

          return (
            <>
              <Button
                onClick={() => {
                  handleRowClick(rowData, tableMeta);
                }}
              >
                <SvgIcon component={ViewIcon} />
              </Button>
              {value}
            </>
          );
        }
      }
    },
    {
      name: "customerNumber",
      label: t("common.customer"),
      options: {
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? value : t("common.unknown")}</>;
        }
      }
    },
    {
      name: "mediaNumber",
      label: t("discrepancyManagement.media#"),
      options: {
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta) => {
          const rowData = mappedData[tableMeta.rowIndex];
          return (
            <Link
              onClick={() => {
                let type = t("discrepancyManagement.container");
                let path = `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT_DETAIL}`;
                handleNavigationClick({ value: rowData, path, type });
              }}
            >
              {value}
            </Link>
          );
        },
        setCellProps: (value) => ({
          style: {
            ...DiscrepencyStyles.linkStyles,
            ...DiscrepencyStyles.columnStylesm
          }
        })
      }
    },

    {
      name: "customerMediaDescr",
      label: t("discrepancyManagement.customerMediaIdentifier"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyleLg
        })
      }
    },

    {
      name: "discrepancyType",
      label: t("discrepancyManagement.discrepancyType"),
      options: {
        sortThirdClickReset: true,
        setCellProps: (value) => ({
          style:
            value?.trim() === t("discrepancyManagement.missing") ||
            value?.trim() === t("discrepancyManagement.notReceived") ||
            value?.trim() === t("discrepancyManagement.notAtIronMountain")
              ? {
                  ...DiscrepencyStyles.colorStyle,
                  ...DiscrepencyStyles.columnStyle
                }
              : { ...DiscrepencyStyles.columnStyle }
        })
      }
    },
    {
      name: "detectedValue",
      label: t("discrepancyManagement.detectedIn"),
      options: {
        sortThirdClickReset: true,
        customBodyRender: (value, tableMeta) => {
          let rowData = mappedData[tableMeta?.rowIndex];
          const isAudit = value.includes(t("discrepancyManagement.audit"));
          const id = isAudit ? rowData?.containerAuditId : selectedCustomerId;
          const text = isAudit && `${value} #${rowData?.containerAuditId}`;

          const path = `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${CONTAINER_INVENTORY_AUDIT_DETAIL}`;
          return (
            <>
              {isAudit ? (
                <Link
                  onClick={() => {
                    handleNavigationClick({ id, path });
                  }}
                >
                  {text}
                </Link>
              ) : (
                <>{value}</>
              )}
            </>
          );
        },
        setCellProps: (value) => ({
          style: {
            ...DiscrepencyStyles.linkStyles,
            ...DiscrepencyStyles.columnStylesm
          }
        })
      }
    },
    {
      name: "detectedDatetime",
      label: t("discrepancyManagement.detectedDate"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        }),
        customBodyRender: (value) =>
          convertDatebyLocale(value, dateFormatWithTimeWithSeconds)
      }
    },
    {
      name: "resolutionType",
      label: t("discrepancyManagement.resolution"),
      options: {
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        })
      }
    },
    {
      name: "carId",
      label: t("discrepancyManagement.carId"),
      options: {
        sortThirdClickReset: true,
        customBodyRender: (value) => (
          <Link
            onClick={() => {
              const id = value; // get id
              let path = `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`;
              handleNavigationClick({ id, path });
            }}
          >
            {value}
          </Link>
        ),
        setCellProps: (value) => ({
          style: {
            ...DiscrepencyStyles.linkStyles,
            ...DiscrepencyStyles.columnStylesm
          }
        })
      }
    },

    {
      name: "mediaType",
      label: t("discrepancyManagement.mediaType"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        })
      }
    },

    {
      name: "serviceDate",
      label: t("discrepancyManagement.serviceDate"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        }),
        customBodyRender: (value) =>
          convertDatebyLocale(value, dayMonthYearFormat)
      }
    },
    {
      name: "run",
      label: t("discrepancyManagement.run"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStylesm
        })
      }
    },
    {
      name: "pendingReturnDate",
      label: t("discrepancyManagement.pendingReturnDate"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyleLg
        }),
        customBodyRender: (value) => {
          if (value === indefiniteDate) {
            return t("discrepancyManagement.indefinite");
          } else {
            return convertDatebyLocale(value, dateFormatWithTime);
          }
        }
      }
    },
    {
      name: "displayToCustomerDate",
      label: t("discrepancyManagement.displayToCustomerDate"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyleLg
        }),
        customBodyRender: (value) =>
          convertDatebyLocale(value, dateFormatWithTime)
      }
    },
    {
      name: "resolvedDatetime",
      label: t("discrepancyManagement.resolvedDate"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        }),
        customBodyRender: (value) =>
          convertDatebyLocale(value, dateFormatWithoutTime)
      }
    },
    {
      name: "resolvedByName",
      label: t("discrepancyManagement.resolvedBy"),
      options: {
        sortThirdClickReset: true,
        setCellProps: () => ({
          style: DiscrepencyStyles.columnStyle
        })
      }
    }
  ];
  const filteredColumns =
    customerMediaIndentifiersFlag === N_CHECKER ||
    searchFormValues?.media.value === t("discrepancyManagement.openMedia")
      ? columns.filter((column) => column.name !== customerMediaDescr)
      : columns;
  return (
    <Grid item xs={12} lg={3} marginTop={4}>
      <MUIDataTable
        columns={filteredColumns}
        data={tableData?.length > 0 ? mappedData : []}
        options={options}
        title={
          isResetGrid ? (
            <Typography style={DiscrepencyStyles.titleStyle}>
              {tableData?.length} {t("common.recordsFound")}
            </Typography>
          ) : (
            ""
          )
        }
      />
    </Grid>
  );
};

export default ContainerAuditTable;
