import CloseIcon from "@mui/icons-material/Close";
import { Alert, Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import { FullCircularLoader } from "components/core";

import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetAllCustomersQuery,
  useGetPersonnelAuthQuery
} from "services/api";
import { CF_URLS } from "services/api/endpoints";
import {
  getDistricts,
  getMessageFromCode,
  getProgramSecurityList
} from "services/api/query";
import { selectAuth } from "store/slices";
import {
  clearError,
  clearWarning,
  resetSearchForm,
  setAllServiceOptionData,
  setAuthCustomers,
  setDetectedInTypes,
  setDiscrepancyTypes,
  setDontCallLabel,
  setError,
  setLoading,
  setLogicalVaultsOptions,
  setMediaList,
  setSearchResults,
  setType,
  setUpdateSelectedList,
  setWarning,
  updateSearchFormValues
} from "store/slices/discrepancy-search";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  C,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  M,
  N_CHECKER,
  SYSTEM_ID_SecureBaseWeb,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  all,
  allCapital,
  capitalAll,
  dateFormat,
  downloadOptionPdf,
  errorMsgs,
  globalAttributeTypeIdCustomerMediaIdentifiers,
  globalAttributeTypeIdMaxRows,
  numberConstants
} from "utils/constants";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getGlobalAttributeValue,
  getOptions,
  getResponseData,
  getSortedData,
  getUserName,
  printReport,
  removeDuplicates
} from "utils/helpers";

import DownloadPopup from "components/core/modal-components/DownloadPopup";
import {
  DISCREPANCY_MANAGEMENT_UPDATE,
  OPERATIONS_MODULE_BASE_PATH
} from "routing/Paths";
import { getAuthorizationPersonnelsAuthentications } from "services/common";
import {
  updateDiscrepancyAuthFormValues,
  updateDiscrepancyAuthentication
} from "store/slices/discrepancy-update";
import {
  UploadForArrival,
  UploadForRunPrep,
  UploadForStaging,
  audit,
  byPassText,
  defaultStatus,
  detectedInContextKey,
  detectedInDescrKey,
  detectedInId,
  detectedInIdKey,
  inventoryAudit,
  inventoryAuditSystemFunctionId,
  loaderTypes,
  picking,
  programSecurityCode326,
  programSecurityCode327,
  programSecurityCode331,
  reportConstantPayload,
  textXml,
  uploadForArrival,
  uploadForRunPrep
} from "utils/constants/discrepancy-management/DiscrepancySearchConstants";
import AuthForm from "../discrepancy-update/AuthForm";
import DiscrepencyTables from "./DiscrepacySearchTables";
import DiscrepencySearch from "./DiscrepancySearchForm";
import { DiscrepencyStyles } from "./discrepancyStyles";
const DiscrepencySearchLayout = () => {
  const {
    searchFormValues,
    openMediaData,
    containerData,
    isLoading,
    throwError,
    throwErrorMsg,
    throwWarning,
    throwWarningMsg,
    authCustomers,
    discrepancyTypes,
    allServiceOptionData,
    detectedInTypes,
    logicalVaultsOptions,
    hasInsufficientRights
  } = useSelector((state) => state.discrepancySearch);
  const { isAuthenticated, showAuthSection, discrepancyAuthFormValues } =
    useSelector((state) => state.discrepancyUpdate);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const prevLocation = location?.state?.from;
  const prevContainerId = location?.state?.containerAuditId;
  const prevOMAuditId = location?.state?.omAuditId;
  const prevToDo = location?.state?.todoProps;
  const prevToDoCar = location?.state?.carProps;
  const prevVault = location?.state?.vaultProps;
  const { auth, currentBranch, localeByBranch } = useSelector(selectAuth);
  const programSecurityList = auth?.user?.program_list;
  const hasPermission326 = programSecurityList.includes(programSecurityCode326);
  const hasPermission327 = programSecurityList.includes(programSecurityCode327);
  const dispatch = useDispatch();
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  const [allBranches, setAllBranches] = useState([]);
  const [isResetGrid, setIsResetGrid] = useState(true);
  const [isFindButtonDisabled, setIsFindButtonDisabled] = useState(false);
  const [minDate, setMinDate] = useState(null);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedId, setSelectedId] = useState(VALUE_EMPTY_STRING);
  const [selectedContainerRow, setSelectedContainerRow] = useState([]);
  const [selectedContainerId, setSelectedContainerId] =
    useState(VALUE_EMPTY_STRING);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [maxRecordMsg, setMaxRecordMsg] = useState(VALUE_EMPTY_STRING);
  const [discrepancyTypesOptions, setDiscrepancyTypesOptions] = useState([
    ALL_SELECTION
  ]);
  const [detectedTypesOptions, setDetectedTypesOptions] = useState([
    ALL_SELECTION
  ]);
  const [logicalVaults, setLogicalVaults] = useState([ALL_SELECTION]);
  const [isOpenMediaSelected, setIsOpenMediaSelected] = useState(false);
  const [today, setToday] = useState(dayjs());
  const [runOptions, setRunOptions] = useState([ALL_SELECTION]);
  const [detectedInValues, setDetectedInValues] = useState(
    detectedTypesOptions?.map((option) => option.label)
  );
  const [customerMediaIndentifiersFlag, setCustomerMediaIndentifiersFlag] =
    useState(null);
  const [hasUpdatePermission, setHasUpdatePermission] = useState(null);
  const [auditId, setAuditId] = useState(VALUE_EMPTY_STRING);
  const [prevValues, setPrevValues] = useState(false);
  const [loaders, setLoaders] = useState([]);
  const [branchOwnedText, setBranchOwnedText] = useState(VALUE_EMPTY_STRING);
  const [throwReportError, setThrowReportError] = useState(false);
  const [throwReportErrorMsg, setThrowReportErrorMsg] =
    useState(VALUE_EMPTY_STRING);
  const [reportCUrl, setReportCFUrl] = useState(VALUE_EMPTY_STRING);
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);
  const user_name = getUserName(auth?.user);
  let initialAuthValues = {
    customer: VALUE_EMPTY_STRING,
    personnel: VALUE_EMPTY_STRING,
    authNumber: VALUE_EMPTY_STRING,
    challengeQuestion: VALUE_EMPTY_STRING,
    challengeResponse: VALUE_EMPTY_STRING
  };
  let initialFormValues = {
    customer: null,
    customerMediaIdentifier: VALUE_EMPTY_STRING,
    detectedDateFrom: VALUE_EMPTY_STRING,
    detectedDateTo: VALUE_EMPTY_STRING,
    discrepancyType: ALL_SELECTION,
    logicalVault: ALL_SELECTION,
    mediaNumber: VALUE_EMPTY_STRING,
    run: VALUE_EMPTY_STRING,
    resolvedDateFrom: VALUE_EMPTY_STRING,
    resolvedDateTo: VALUE_EMPTY_STRING,
    serviceDate: VALUE_EMPTY_STRING,
    excludeAutoResolved: false
  };
  // selected personnel auth details
  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: discrepancyAuthFormValues?.personnel?.value
  });

  // getting the Data from useGetAllCustomersQuery
  const { data: allCustomers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  // getting the Customers on branch change in authentication
  const { data: authenticatedCustomers = [] } = useGetAllCustomersQuery({
    mainDistrictId: discrepancyAuthFormValues?.branch
  });
  const handlePrintButtonClick = () => {
    setShowPrintModal(true);
    setReportCFUrl(CF_URLS.printReports.discrepancy.discrepancySearchReport);
  };

  const getDatesForBranch = async () => {
    import(`dayjs/locale/${localeByBranch}.js`).then(() => {
      const todayDate = dayjs().utc().locale(localeByBranch);
      setToday(todayDate);
      const minServiceDate = todayDate.subtract(numberConstants.seven, "year");
      setMinDate(minServiceDate);
    });
  };

  const getDiscrepancyTypes = async () => {
    setLoaders((prev) => [...prev, loaderTypes.discrepancy]);
    try {
      const req = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        operational_mode_id: numberConstants?.four
      });
      const res = await getResponseData(
        req,
        CF_URLS.discrepancyManagement.operationsservicereference,
        11
      );
      let data = res.data;
      if (data[0] && data[0][0]?.error) {
        dispatch(setError(data[0][0]?.error));
        return;
      } else {
        const joinedData = [data[1], data[2], data[3], data[4]];
        dispatch(setAllServiceOptionData(joinedData));
        dispatch(setMediaList(data[7]));
        const uniqueData = [
          removeDuplicates(data[1], "formattedname"),
          removeDuplicates(data[2], "formattedname"),
          removeDuplicates(data[3], "formattedname"),
          removeDuplicates(data[4], "formattedname")
        ];
        const uniqueDataOptions = [
          getOptions(uniqueData[0], "formattedname", "formattedname"),
          getOptions(uniqueData[1], "formattedname", "formattedname"),
          getOptions(uniqueData[2], "formattedname", "formattedname"),
          getOptions(uniqueData[3], "formattedname", "formattedname")
        ];

        const joinedDetectedData = [
          ALL_SELECTION,
          uniqueDataOptions[0],
          uniqueDataOptions[1]
        ];

        dispatch(setDetectedInTypes(joinedDetectedData));

        const detectedTypesOptionsAll = [
          ...uniqueDataOptions[0],
          ...uniqueDataOptions[1]
        ];

        detectedTypesOptionsAll?.sort((a, b) => a.label.localeCompare(b.label));
        setDetectedTypesOptions([ALL_SELECTION, ...detectedTypesOptionsAll]);
        let detectedValues = [ALL_SELECTION, ...detectedTypesOptionsAll].map(
          (option) => option.label
        );
        dispatch(updateSearchFormValues({ detectedIn: detectedValues }));
        setDetectedInValues(detectedValues);
        const joinedDiscrepancyData = [
          ALL_SELECTION,
          uniqueDataOptions[2],
          uniqueDataOptions[3]
        ];
        dispatch(setDiscrepancyTypes(joinedDiscrepancyData));

        const discrepancyTypesOptionsAll = [
          ALL_SELECTION,
          ...uniqueDataOptions[2],
          ...uniqueDataOptions[3]
        ];
        const discrepancyTypesOptions = removeDuplicates(
          discrepancyTypesOptionsAll,
          "label"
        );
        //To do- sort options
        setDiscrepancyTypesOptions(discrepancyTypesOptions);
      }
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      setLoaders((prev) => prev.filter((id) => id !== loaderTypes.discrepancy));
    }
  };

  const getLogicalVaults = async (customer) => {
    try {
      setLoaders((prev) => [...prev, loaderTypes.vaults]);
      const req = JSON.stringify({
        main_district_id: mainDistrictId,
        customer_id: customer?.value
      });
      const res = await getResponseData(
        req,
        CF_URLS.discrepancyManagement.logicalVaults,
        1
      );
      let data = res.data;
      if (data[0] && data[0][0]?.error) {
        dispatch(setError(data[0][0]?.error));
        return;
      }
      if (data[0]?.length > 0) {
        let options = await data[0]?.map((item) => {
          return {
            label: item.logical_vault_code,
            value: item.logical_vault_id,
            type: item.container_media_flag
          };
        });
        options.sort((a, b) => a.label.localeCompare(b.label));
        if (searchFormValues?.media === t("discrepancyManagement.container")) {
          let logicalContainer = logicalVaultsOptions.filter(
            (vault) => vault.type === C
          );
          setLogicalVaults([ALL_SELECTION, ...logicalContainer]);
        } else if (
          searchFormValues?.media === t("discrepancyManagement.openMedia")
        ) {
          let logicalContainer = logicalVaultsOptions.filter(
            (vault) => vault.type === M
          );
          setLogicalVaults([ALL_SELECTION, ...logicalContainer]);
        } else setLogicalVaults([ALL_SELECTION, ...options]);
        dispatch(setLogicalVaultsOptions([ALL_SELECTION, ...options]));
      }
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      setLoaders((prev) => prev.filter((type) => type !== loaderTypes.vaults));
    }
  };

  const getListOfRuns = async (date) => {
    try {
      const isValid = dayjs(date).isValid();
      if (!isValid) {
        return;
      }
      setLoaders((prev) => [...prev, loaderTypes.runs]);
      const runDate = dayjs(date)?.format(dateFormat.universalFormat);
      const req = {
        main_district_id: mainDistrictId,
        run_date: runDate
      };
      const res = await getResponseData(
        req,
        CF_URLS.discrepancyManagement.listofruns,
        1
      );
      let data = res?.data;

      let options =
        data[0] &&
        data[0]?.map((item) => {
          return {
            label: item.run_name,
            value: item.run_id
          };
        });
      options?.sort((a, b) => a.label.localeCompare(b.label));
      setRunOptions([ALL_SELECTION, ...options]);
      if (data[0] && data[0][0]?.error) {
        dispatch(setError(data[0][0]?.error));
        return;
      }
    } catch (error) {
      dispatch(setError(error));
    } finally {
      setLoaders((prev) => prev.filter((type) => type !== loaderTypes.runs));
    }
  };
  const getMaxDispalyRecords = async () => {
    const auditSearchMaxDisplay = await getGlobalAttributeValue(
      mainDistrictId,
      SYSTEM_ID_SecureBaseWeb,
      globalAttributeTypeIdMaxRows
    );
    return auditSearchMaxDisplay;
  };
  const getAllowCustomerMediaIndentifiers = async () => {
    const attributeData = await getGlobalAttributeValue(
      mainDistrictId,
      SYSTEM_ID_SecureBaseWeb,
      globalAttributeTypeIdCustomerMediaIdentifiers
    );
    setCustomerMediaIndentifiersFlag(attributeData);
  };
  const getBranchOwnedText = async () => {
    const branchOwned = await getMessageFromCode(errorMsgs.errorCode80288);
    const text = branchOwned[0]?.descr;
    setBranchOwnedText(text);
  };
  const resetStatesOnAuthentication = () => {
    dispatch(clearWarning());
    setAuditId(null);
    setPrevValues(false);

    const typeOptions = [
      ALL_SELECTION,
      ...discrepancyTypes[1],
      ...discrepancyTypes[2]
    ];
    const discrepancyTypesOptions = removeDuplicates(typeOptions, "label");
    setDiscrepancyTypesOptions(discrepancyTypesOptions);
    setDetectedTypesOptions([
      ALL_SELECTION,
      ...detectedInTypes[1],
      ...detectedInTypes[2]
    ]);

    setLogicalVaults(logicalVaultsOptions);
    let detectedValuesAll = [
      ALL_SELECTION,
      ...detectedInTypes[1],
      ...detectedInTypes[2]
    ].map((option) => option.label);

    let updates = {
      customer: discrepancyAuthFormValues?.customer,
      media: ALL_SELECTION,
      discrepancyStatus: defaultStatus,
      detectedIn: detectedValuesAll,
      customerMediaIdentifier: VALUE_EMPTY_STRING,
      detectedDateFrom: VALUE_EMPTY_STRING,
      detectedDateTo: VALUE_EMPTY_STRING,
      discrepancyType: ALL_SELECTION,
      logicalVault: ALL_SELECTION,
      mediaNumber: VALUE_EMPTY_STRING,
      run: VALUE_EMPTY_STRING,
      resolvedDateFrom: VALUE_EMPTY_STRING,
      resolvedDateTo: VALUE_EMPTY_STRING,
      serviceDate: VALUE_EMPTY_STRING,
      excludeAutoResolved: false
    };
    dispatch(updateSearchFormValues(updates));

    const updateData = { containerData: [], openMediaData: [] };
    dispatch(setSearchResults(updateData));
  };

  useEffect(() => {
    if (!prevValues) {
      dispatch(
        updateSearchFormValues({
          detectedIn: detectedTypesOptions.map((option) => option.label)
        })
      );
    }
    // eslint-disable-next-line
  }, [detectedInValues]);

  useEffect(() => {
    if (throwError) {
      window.scrollTo(0, 0);
    }
  }, [throwError]);

  useEffect(() => {
    getDatesForBranch();
    // eslint-disable-next-line
  }, [localeByBranch]);

  useEffect(() => {
    if (discrepancyAuthFormValues?.branch && isAuthenticated) {
      getAllocatedCustomers();
      resetStatesOnAuthentication();
    }
    // eslint-disable-next-line
  }, [discrepancyAuthFormValues?.branch, isAuthenticated]);

  useEffect(() => {
    const loadData = async () => {
      if (discrepancyTypes && discrepancyTypes[1]?.length > 0) {
        try {
          await Promise.all([
            getAllBarnchesFromFireStore(),
            getBranchOwnedText()
          ]);

          if (prevContainerId) {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode80276);
            const errMsg = errDesc[0]?.descr.replace("|", prevContainerId);
            dispatch(setWarning(errMsg));
            const updatedValues = {
              media: {
                label: t("discrepancyManagement.container"),
                value: t("discrepancyManagement.container")
              },
              discrepancyStatus: ALL_SELECTION,
              detectedIn: [inventoryAudit]
            };
            dispatch(updateSearchFormValues(updatedValues));
            setAuditId(prevContainerId);
            setPrevValues(true);
            setDiscrepancyTypesOptions([ALL_SELECTION, ...discrepancyTypes[1]]);
            setDetectedTypesOptions([ALL_SELECTION, ...detectedInTypes[1]]);

            let logicalContainer = logicalVaultsOptions.filter(
              (vault) => vault.type === C
            );
            setLogicalVaults([ALL_SELECTION, ...logicalContainer]);
          } else if (prevOMAuditId) {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode80276);
            const errMsg = errDesc[0]?.descr.replace("|", prevOMAuditId);
            dispatch(setWarning(errMsg));
            const updatedValues = {
              ...initialFormValues,
              media: {
                label: t("discrepancyManagement.openMedia"),
                value: t("discrepancyManagement.openMedia")
              },
              discrepancyStatus: ALL_SELECTION,
              detectedIn: [audit]
            };

            dispatch(updateSearchFormValues(updatedValues));
            setAuditId(prevOMAuditId);
            setPrevValues(true);

            setDiscrepancyTypesOptions([ALL_SELECTION, ...discrepancyTypes[2]]);
            setDetectedTypesOptions([ALL_SELECTION, ...detectedInTypes[2]]);

            let logicalOM = logicalVaultsOptions.filter(
              (vault) => vault.type === M
            );
            setLogicalVaults([ALL_SELECTION, ...logicalOM]);
          } else if (prevToDo) {
            if (prevToDo.mediaType === t("discrepancyManagement.container")) {
              const updatedValues = {
                ...initialFormValues,
                media: {
                  label: t("discrepancyManagement.container"),
                  value: t("discrepancyManagement.container")
                },
                detectedIn:
                  prevToDo?.detectedIn === UploadForRunPrep
                    ? [uploadForRunPrep, UploadForStaging]
                    : prevToDo?.detectedIn === UploadForArrival
                      ? [uploadForArrival]
                      : detectedTypesOptions.map((option) => option.label)
              };
              dispatch(updateSearchFormValues(updatedValues));
              setAuditId(prevContainerId);
              setPrevValues(true);
              setDiscrepancyTypesOptions([
                ALL_SELECTION,
                ...discrepancyTypes[1]
              ]);
              setDetectedTypesOptions([ALL_SELECTION, ...detectedInTypes[1]]);

              let logicalContainer = logicalVaultsOptions.filter(
                (vault) => vault.type === C
              );
              setLogicalVaults([ALL_SELECTION, ...logicalContainer]);
            } else {
              const updatedValues = {
                ...initialFormValues,
                media: {
                  label: t("discrepancyManagement.openMedia"),
                  value: t("discrepancyManagement.openMedia")
                },
                detectedIn: [picking]
              };

              dispatch(updateSearchFormValues(updatedValues));
              setPrevValues(true);

              setDiscrepancyTypesOptions([
                ALL_SELECTION,
                ...discrepancyTypes[2]
              ]);
              setDetectedTypesOptions([ALL_SELECTION, ...detectedInTypes[2]]);

              let logicalOM = logicalVaultsOptions.filter(
                (vault) => vault.type === M
              );
              setLogicalVaults([ALL_SELECTION, ...logicalOM]);
            }
          } else if (prevToDoCar) {
            setPrevValues(true);
            dispatch(
              updateSearchFormValues({
                detectedIn: detectedTypesOptions.map((option) => option.label)
              })
            );
          } else if (prevVault) {
            let mediaType = prevVault?.type;
            if (prevVault?.type === t("discrepancyManagement.openMedia")) {
              setDiscrepancyTypesOptions([
                ALL_SELECTION,
                ...discrepancyTypes[2]
              ]);
              setDetectedTypesOptions([ALL_SELECTION, ...detectedInTypes[2]]);
              mediaType = {
                label: t("discrepancyManagement.openMedia"),
                value: t("discrepancyManagement.openMedia")
              };
            }
            if (prevVault?.type === t("discrepancyManagement.container")) {
              setDiscrepancyTypesOptions([
                ALL_SELECTION,
                ...discrepancyTypes[1]
              ]);
              setDetectedTypesOptions([ALL_SELECTION, ...detectedInTypes[1]]);
              mediaType = {
                label: t("discrepancyManagement.container"),
                value: t("discrepancyManagement.container")
              };
            }
            let vaultCustomer = {
              label: `${prevVault?.customer_number} -  ${prevVault?.customer_name}`,
              number: prevVault?.customer_number,
              value: prevVault?.customer_id
            };

            let updates = {
              customer: vaultCustomer,
              discrepancyStatus: ALL_SELECTION,
              media: mediaType,
              detectedIn: prevVault?.detected_in
                ? [prevVault?.detected_in]
                : detectedTypesOptions.map((option) => option.label),
              customerMediaIdentifier: VALUE_EMPTY_STRING,
              detectedDateFrom: dayjs(prevVault?.detected_date_from?.$d),
              detectedDateTo: dayjs(prevVault?.detected_date_to?.$d),
              discrepancyType: ALL_SELECTION,
              mediaNumber: VALUE_EMPTY_STRING,
              run: VALUE_EMPTY_STRING,
              resolvedDateFrom: VALUE_EMPTY_STRING,
              resolvedDateTo: VALUE_EMPTY_STRING,
              serviceDate: VALUE_EMPTY_STRING,
              excludeAutoResolved: false
            };

            await getLogicalVaults(vaultCustomer);
            const selectedLogicalVault = logicalVaults.find(
              (vault) => vault.label === prevVault?.logical_vault_code
            );
            updates.logicalVault = selectedLogicalVault;
            dispatch(updateSearchFormValues(updates));
            setPrevValues(true);
          } else if (prevLocation) {
            if (!isAuthenticated) {
              dispatch(
                updateSearchFormValues({
                  detectedIn: location?.state?.detectedIn
                })
              );
              setPrevValues(true);
            }
          } else {
            dispatch(clearError());
            dispatch(clearWarning());
            dispatch(setSearchResults([]));
            const typeOptions = [
              ALL_SELECTION,
              ...discrepancyTypes[1],
              ...discrepancyTypes[2]
            ];
            const discrepancyTypesOptions = removeDuplicates(
              typeOptions,
              "label"
            );
            setDiscrepancyTypesOptions(discrepancyTypesOptions);
            setDetectedTypesOptions([
              ALL_SELECTION,
              ...detectedInTypes[1],
              ...detectedInTypes[2]
            ]);
            setLogicalVaults(logicalVaultsOptions);
            await onFindClick();
          }
        } catch (error) {
          dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
        }
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    prevContainerId,
    prevOMAuditId,
    prevLocation,
    prevToDo,
    discrepancyTypes
  ]);
  useEffect(() => {
    if (mainDistrictId) {
      getAllowCustomerMediaIndentifiers();
      getDiscrepancyTypes();

      return () => {
        handleReset();
      };
    } // eslint-disable-next-line
  }, [mainDistrictId]);

  useEffect(() => {
    if (prevValues) {
      onFindClick();
    } // eslint-disable-next-line
  }, [prevValues]);
  // set the default branch into the auth section
  useEffect(() => {
    if (allBranches && allBranches?.length) {
      const selectedLocationDetails = allBranches.find(
        (location) => location.district_id === currentBranch?.value
      );
      const updates = {
        branch: selectedLocationDetails?.district_id,
        customer: VALUE_EMPTY_STRING,
        personnel: VALUE_EMPTY_STRING,
        authNumber: VALUE_EMPTY_STRING,
        challengeQuestion: VALUE_EMPTY_STRING,
        challengeResponse: VALUE_EMPTY_STRING
      };
      dispatch(updateDiscrepancyAuthFormValues(updates));
    }
    // eslint-disable-next-line
  }, [allBranches]);

  // get challange question
  useEffect(() => {
    if (personnelAuth?.length) {
      const updates = {
        ...discrepancyAuthFormValues,
        challengeQuestion: personnelAuth[0]?.challenge_question
      };
      dispatch(updateDiscrepancyAuthFormValues(updates));
    }
    // eslint-disable-next-line
  }, [personnelAuth]);

  //write in constant
  const createDiscrepancyTypeXml = async (data) => {
    if (data?.length > 0) {
      return `
        <ROOT>
          ${data
            .map(
              (item, index) => `
            <DiscrepancyType UniqueId="${item.uniqueid.trim()}" AlternateId="${
              item.alternate_id
            }" IDContext="${item.idcontext}"/>
          `
            )
            .join("")}
        </ROOT>
      `;
    } else {
      return "";
    }
  };

  const createDetectedTypeXml = async (data) => {
    if (data?.length > 0) {
      return `
        <ROOT>
          ${data
            // eslint-disable-next-line array-callback-return
            .map((item, index) => {
              const id = item.uniqueid.replace(/^.*_(\d+)$/, "$1");
              if (item?.uniqueid) {
                return `
                  <DetectedIn  Detected_In_Id="${id}" Detected_In_Descr="${item.formattedname}" Detected_In_Context="${item.idcontext}"/>
                `;
              }
            })
            .join("")}
        </ROOT>
      `;
    }
    return "";
  };

  const getDuplicateElements = (selectedItem, selectedKey, data) => {
    const duplicateElements = [];

    if (selectedItem?.includes(all)) {
      return data;
    }

    if (Array.isArray(selectedItem)) {
      selectedItem?.forEach((item) => {
        const value =
          typeof item === "object" && item !== null ? item.value : item;
        data?.forEach((dataItem) => {
          if (dataItem[selectedKey] === value) {
            duplicateElements?.push(dataItem);
          }
        });
      });
    } else {
      data?.forEach((dataItem) => {
        if (dataItem[selectedKey] === selectedItem) {
          duplicateElements?.push(dataItem);
        }
      });
    }

    return duplicateElements;
  };
  const onFindClick = async () => {
    setLoaders((prev) => [...prev, loaderTypes.dataLoaded]);
    try {
      dispatch(setDontCallLabel(null));
      setSelectedContainerRow([]);
      setSelectedRow([]);
      const updateData = { containerData: [], openMediaData: [] };
      dispatch(setSearchResults(updateData));
      setMaxRecordMsg(VALUE_EMPTY_STRING);

      const maxRowLimit = await getMaxDispalyRecords();
      const {
        customer,
        customerMediaIdentifier,
        detectedDateFrom,
        detectedDateTo,
        detectedIn,
        discrepancyType,
        discrepancyStatus,
        logicalVault,
        media,
        mediaNumber,
        run,
        resolvedDateFrom,
        resolvedDateTo,
        serviceDate,
        excludeAutoResolved
      } = searchFormValues;

      if (
        (detectedDateTo && !detectedDateFrom) ||
        (!detectedDateTo && detectedDateFrom)
      ) {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
        const errMsg = errDesc[0]?.descr.replace(
          "|",
          t("discrepancyManagement.detectedDate")
        );
        dispatch(setError(errMsg));
        return;
      }
      if (
        (resolvedDateTo && !resolvedDateFrom) ||
        (!resolvedDateTo && resolvedDateFrom)
      ) {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
        const errMsg = errDesc[0]?.descr.replace(
          "|",
          t("discrepancyManagement.resolvedDate")
        );
        dispatch(setError(errMsg));
        return;
      }

      if (detectedDateTo < detectedDateFrom) {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode80165);
        const errMsg = errDesc[0]?.descr.replace(
          "{0}",
          t("discrepancyManagement.detectedDate")
        );
        dispatch(setError(errMsg));
        return;
      }

      if (resolvedDateFrom > resolvedDateTo) {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode80165);
        const errMsg = errDesc[0]?.descr.replace(
          "{0}",
          t("discrepancyManagement.resolvedDate")
        );
        dispatch(setError(errMsg));
        return;
      }
      const detectedFrom = detectedDateFrom
        ? dayjs(detectedDateFrom).format(dateFormat.universalFormat)
        : VALUE_EMPTY_STRING;

      const detectedTo = detectedDateTo
        ? dayjs(detectedDateTo).format(dateFormat.universalFormat)
        : VALUE_EMPTY_STRING;
      const resolvedFrom = resolvedDateFrom
        ? dayjs(resolvedDateFrom).format(dateFormat.universalFormat)
        : VALUE_EMPTY_STRING;
      const resolvedTo = resolvedDateTo
        ? dayjs(resolvedDateTo).format(dateFormat.universalFormat)
        : VALUE_EMPTY_STRING;
      const dateService = serviceDate
        ? dayjs(serviceDate).format(dateFormat.universalFormat)
        : VALUE_EMPTY_STRING;

      const allDetectedTypes = [
        ...allServiceOptionData[0],
        ...allServiceOptionData[1]
      ];
      const allDiscrepancyTypes = [
        ...allServiceOptionData[2],
        ...allServiceOptionData[3]
      ];
      const discrepancyTypeDuplicates =
        discrepancyType?.value === t("common.all")
          ? VALUE_EMPTY_STRING
          : await getDuplicateElements(
              discrepancyType?.value,
              "formattedname",
              allDiscrepancyTypes
            );

      // Handle detectedIn
      const detectedInDuplicates = await getDuplicateElements(
        detectedIn,
        "formattedname",
        allDetectedTypes
      );

      let detectedInXML = null;

      const mediaValue = media?.value;
      const includesAllSelection = detectedIn?.includes(ALL_SELECTION?.value);

      if (mediaValue === t("common.all")) {
        detectedInXML = await createDetectedTypeXml(
          includesAllSelection ? allDetectedTypes : detectedInDuplicates
        );
      } else if (mediaValue === t("discrepancyManagement.container")) {
        detectedInXML = await createDetectedTypeXml(
          includesAllSelection ? allServiceOptionData[0] : detectedInDuplicates
        );
      } else if (mediaValue === t("discrepancyManagement.openMedia")) {
        detectedInXML = await createDetectedTypeXml(
          includesAllSelection ? allServiceOptionData[1] : detectedInDuplicates
        );
      }

      const discrepancyTypeXml =
        discrepancyType?.value === t("common.all")
          ? ""
          : await createDiscrepancyTypeXml(discrepancyTypeDuplicates);
      let customersList = VALUE_EMPTY_STRING;
      if (isAuthenticated) {
        const ids = authCustomers
          .map((cust) => cust.value)
          .filter((value) => value !== t("common.all"));
        customersList = customer
          ? customer?.value === t("common.all")
            ? ids.join(",")
            : customer.value
          : VALUE_EMPTY_STRING;
      } else {
        customersList = customer
          ? customer?.value === t("common.all")
            ? VALUE_EMPTY_STRING
            : customer.value
          : VALUE_EMPTY_STRING;
      }
      let reqBody = JSON.stringify({
        car_id: "",
        customer_id_list: customersList,
        customer_media_descr: customerMediaIdentifier,
        detected_in_xml: detectedInXML,
        discrepancy_status:
          discrepancyStatus?.value === ALL_SELECTION?.value
            ? allCapital
            : discrepancyStatus?.value?.replace(/\s+/g, ""),
        discrepancy_type_descr_xml: discrepancyTypeXml,
        exclude_auto_resolve_flag: excludeAutoResolved ? Y_CHECKER : N_CHECKER,
        exclude_unresolved_no_action_flag: Y_CHECKER,
        from_detected_date: detectedFrom,
        from_resolved_date: resolvedFrom,
        ga_courtesy_followup_days: numberConstants.three, //need to change acc to req
        ga_max_num_search_results: maxRowLimit,
        logical_vault_id:
          logicalVault?.value === ALL_SELECTION?.value
            ? VALUE_EMPTY_STRING
            : logicalVault?.value,
        main_district_id: mainDistrictId,
        media_program_type:
          media?.value === t("common.all")
            ? all
            : media?.value?.replace(/\s+/g, ""),
        om_cont_audit_id: prevValues ? auditId : VALUE_EMPTY_STRING,
        run_id:
          run?.value === ALL_SELECTION?.value
            ? VALUE_EMPTY_STRING
            : run?.value || VALUE_EMPTY_STRING,
        service_date: dateService,
        to_detected_date: detectedTo,
        to_resolved_date: resolvedTo,
        volser_number: mediaNumber
      });

      let res = await getResponseData(
        reqBody,
        `${CF_URLS.discrepancyManagement.discrepancmanagementpageforsbweb}`,
        2
      );

      setIsResetGrid(false);

      if (res) {
        const data = res?.data;
        if (res?.error) {
          dispatch(setError(res?.error));
          return;
        }
        if (data[0]?.[0] || data[1]?.[0]) {
          const error = data[0]?.[0]?.error || data[1]?.[0]?.error;

          if (error) {
            const errorCode =
              error === errorMsgs?.errorCode80168 ? error : error?.code;
            const errorObj = await getMessageFromCode(errorCode);
            const errorMsg = errorObj[0]?.descr;
            let errorString = errorMsg;
            if (errorCode === errorMsgs?.errorCode80168) {
              errorString = errorString?.replace("{0}", maxRowLimit);
              dispatch(clearWarning());
            }

            dispatch(
              setError(errorString || findErrorMessage(ERROR_TYPES.ISSUE))
            );
            return;
          }
        }
        const sortFunction = (a, b) => {
          if (a.customer_number < b.customer_number) return -1;
          if (a.customer_number > b.customer_number) return 1;
          if (a.discrepancy_type_code < b.discrepancy_type_code) return -1;
          if (a.discrepancy_type_code > b.discrepancy_type_code) return 1;

          return 0;
        };
        const checkDontCall = data[0]?.filter(
          (om) => om.do_not_call_pref_value === Y_CHECKER
        );
        const auditRecords = data[1].filter((record) => {
          return (
            record.system_function_id === inventoryAuditSystemFunctionId &&
            record.audit_status_id === ""
          );
        });
        if (checkDontCall?.length > 0) {
          const errDesc = await getMessageFromCode(errorMsgs.errorCode80280);
          const errMsg = errDesc[0]?.descr;
          dispatch(setDontCallLabel(errMsg));
        }
        if (auditRecords?.length > 0) {
          const errDesc = await getMessageFromCode(errorMsgs.errorCode80285);
          const errMsg = errDesc[0]?.descr;
          dispatch(setWarning(errMsg));
        }
        let omArr = [];
        let containerArr = [];
        data[0].forEach((item) => {
          omArr.push({ ...item, isCheck: false });
        });
        data[1].forEach((item) => {
          containerArr.push({ ...item, isCheck: false });
        });
        const updateData = {};

        if (data[0]) updateData.openMediaData = [...omArr].sort(sortFunction);

        if (data[1])
          updateData.containerData = [...containerArr]?.sort(sortFunction);

        dispatch(setSearchResults(updateData));
        dispatch(setSearchResults(updateData));
      }
    } catch (e) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      setLoaders((prev) =>
        prev.filter((type) => type !== loaderTypes.dataLoaded)
      );
    }
  };

  // get branches from firestore
  const getAllBarnchesFromFireStore = async () => {
    setLoaders((prev) => [...prev, loaderTypes.branch]);
    try {
      const branches = await getDistricts();
      let branchList = branches?.map((branch) => {
        return {
          ...branch,
          label: branch?.name,
          value: branch?.district_id
        };
      });
      const branchData = getSortedData(branchList, "name", "asc");
      setAllBranches(branchData);
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.EXCEPTION)));
    } finally {
      setLoaders((prev) => prev.filter((type) => type !== loaderTypes.branch));
    }
  };
  // if authenticated get the allocated customers
  const getAllocatedCustomers = async () => {
    setLoaders((prev) => [...prev, loaderTypes.customer]);
    try {
      if (authenticatedCustomers) {
        let personalCustomersList = [];
        if (discrepancyAuthFormValues?.personnel?.value) {
          const data = await getAuthorizationPersonnelsAuthentications({
            personnelId: discrepancyAuthFormValues?.personnel?.value
          });
          const personalCustomers = JSON.parse(data?.customer_list);
          personalCustomersList = personalCustomers?.map(
            ({ number, name, customer_Id }) => ({
              label: `${number} - ${name.trim()}`,
              value: customer_Id,
              number: number
            })
          );
          personalCustomersList?.sort((a, b) =>
            String(a.value).localeCompare(String(b.value))
          );
        } else if (
          discrepancyAuthFormValues?.personnelAuthValue === byPassText
        ) {
          const { number, name, customer_Id } =
            discrepancyAuthFormValues?.customer || {};
          personalCustomersList = [
            {
              label: `${number} - ${name.trim()}`,
              value: customer_Id,
              number: number
            }
          ];
        } else {
          personalCustomersList = allCustomers;
        }

        if (personalCustomersList.length) {
          const allCux = [ALL_SELECTION, ...personalCustomersList];
          dispatch(setAuthCustomers(allCux));
          if (!hasInsufficientRights) {
            const errorObj = await getMessageFromCode(
              errorMsgs?.errorCode80251
            );
            const errorMsg = errorObj[0]?.descr;
            if (prevLocation && throwErrorMsg) {
              dispatch(
                setWarning(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE))
              );
            }
          }
        } else {
          if (!hasInsufficientRights) {
            const errorObj = await getMessageFromCode(
              errorMsgs?.errorCode80241
            );
            const errorMsg = errorObj[0]?.descr;

            if (prevLocation && throwErrorMsg) {
              dispatch(
                setWarning(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE))
              );
            }
            dispatch(setAuthCustomers([]));
          }
        }
      } else {
        if (!hasInsufficientRights) {
          const errorObj = await getMessageFromCode(errorMsgs?.errorCode80241);
          const errorMsg = errorObj[0]?.descr;

          dispatch(setWarning(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE)));
          const updatedValues = {
            ...discrepancyAuthFormValues,
            authNumber: VALUE_EMPTY_STRING,
            challengeQuestion: VALUE_EMPTY_STRING,
            challengeResponse: VALUE_EMPTY_STRING
          };
          dispatch(updateDiscrepancyAuthFormValues(updatedValues));

          dispatch(updateDiscrepancyAuthentication(false));
        }
      }
      setLoaders((prev) =>
        prev.filter((type) => type !== loaderTypes.customer)
      );
    } catch (e) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));

      setLoaders((prev) =>
        prev.filter((type) => type !== loaderTypes.customer)
      );
    }
  };

  const handleReset = () => {
    if (isAuthenticated) {
      const updates = {
        customerMediaIdentifier: VALUE_EMPTY_STRING,
        detectedDateFrom: VALUE_EMPTY_STRING,
        detectedDateTo: VALUE_EMPTY_STRING,
        detectedIn: detectedTypesOptions.map((option) => option.label),
        discrepancyType: ALL_SELECTION,
        discrepancyStatus: defaultStatus,
        logicalVault: ALL_SELECTION,
        media: ALL_SELECTION,
        mediaNumber: VALUE_EMPTY_STRING,
        run: ALL_SELECTION,
        resolvedDateFrom: VALUE_EMPTY_STRING,
        resolvedDateTo: VALUE_EMPTY_STRING,
        serviceDate: VALUE_EMPTY_STRING,
        excludeAutoResolved: false
      };
      dispatch(updateSearchFormValues(updates));
      const updateData = { containerData: [], openMediaData: [] };
      dispatch(setDontCallLabel(null));
      dispatch(setSearchResults(updateData));
      dispatch(setLoading(false));
      dispatch(clearError());
      setSelectedContainerRow([]);
      setSelectedRow([]);
    } else {
      dispatch(resetSearchForm(detectedTypesOptions));
      setSelectedContainerRow([]);
      setSelectedRow([]);
    }

    setAuditId(VALUE_EMPTY_STRING);
    setIsResetGrid(true);
    setPrevValues(false);
    setLoaders([]);
    dispatch(clearWarning());
  };

  const handleNavigationClick = ({ id, path, value, type, carId }) => {
    const propsData = {
      authUser: discrepancyAuthFormValues,
      id,
      value,
      type,
      car_id: carId
    };
    let url = id ? `${path}/${id}` : `${path}`;
    if (isAuthenticated) {
      navigate(url, { state: propsData });
    } else navigate(url, { state: propsData });
  };

  const checkIfPersonnelHasPermission = async (customerPermissions) => {
    let personnalPermission = true;
    // Map to extract role_ids for the current customer
    let roleIds = customerPermissions.map((role) => role.role_id);

    //Add role_ids to uniqueRoleIds array, avoiding duplicates
    let uniqueIds = [...new Set(roleIds)];
    const idRelatedValuesFor331 = await getProgramSecurityList(
      uniqueIds,
      discrepancyAuthFormValues?.branch,
      programSecurityCode331
    );
    if (idRelatedValuesFor331?.length > 0) {
      personnalPermission = true;
    } else {
      personnalPermission = false;
    }
    return personnalPermission;
  };

  const getInitialPersonnelDetails = async () => {
    dispatch(setLoading(true));
    try {
      let reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        personnel_id: discrepancyAuthFormValues.personnel.value,
        customer_facing: N_CHECKER
      };

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.personnelDetail?.personnelinformationandpassedpersonnelid}`,
        4
      );
      let customerPermissions = data?.data[2];
      let hasPermission = customerPermissions.length
        ? await checkIfPersonnelHasPermission(customerPermissions)
        : false;
      setHasUpdatePermission(hasPermission);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (isAuthenticated && discrepancyAuthFormValues.personnel) {
      getInitialPersonnelDetails(discrepancyAuthFormValues.personnel.value);
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const handleUpdate = () => {
    let selectedData = selectedRow?.length ? selectedRow : selectedContainerRow;
    let type = selectedRow?.length ? t("discrepancyManagement.openMedia") : "";

    dispatch(setUpdateSelectedList(selectedData));
    dispatch(setType(type));
    navigate(`${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT_UPDATE}`);
  };

  //handle download todo

  const searchProps = {
    allCustomers,
    minDate,
    today,
    onFindClick,
    authCustomers,
    handleReset,
    isFindButtonDisabled,
    setIsFindButtonDisabled,
    isAuthenticated,
    initialAuthValues,
    getLogicalVaults,
    getListOfRuns,
    discrepancyTypes,
    discrepancyTypesOptions,
    setDiscrepancyTypesOptions,
    detectedTypesOptions,
    setDetectedTypesOptions,
    logicalVaults,
    runOptions,
    setRunOptions,
    detectedInValues,
    setDetectedInValues,
    setLogicalVaults,
    customerMediaIndentifiersFlag,
    setPrevValues
  };
  const tableProps = {
    openMediaData,
    containerData,
    isResetGrid,
    setSelectedRow,
    selectedRow,
    setSelectedId,
    selectedId,
    handleNavigationClick,
    handlePrintButtonClick,
    maxRecordMsg,
    hasPermission326,
    hasPermission327,
    selectedContainerRow,
    setSelectedContainerRow,
    selectedContainerId,
    setSelectedContainerId,
    isOpenMediaSelected,
    setIsOpenMediaSelected,
    customerMediaIndentifiersFlag,
    setCustomerMediaIndentifiersFlag,
    branchOwnedText,
    hasUpdatePermission
  };

  const formatDateToString = (date) => {
    return date ? date.toISOString().split("T")[0] : "";
  };

  const handleDownload = async () => {
    const allDetectedTypes = [
      ...allServiceOptionData[0],
      ...allServiceOptionData[1]
    ];
    // Handle detectedIn
    const detectedInDuplicates = await getDuplicateElements(
      searchFormValues?.detectedIn,
      "formattedname",
      allDetectedTypes
    );

    let detectedIn = null;
    let discrepancyType = null;
    const mediaValue = searchFormValues?.media?.value;
    const includesAllSelection = detectedIn?.includes(ALL_SELECTION?.value);

    const allDiscrepancyTypes = [
      ...allServiceOptionData[2],
      ...allServiceOptionData[3]
    ];
    const discrepancyTypeDuplicates =
      searchFormValues?.discrepancyType?.value === t("common.all")
        ? VALUE_EMPTY_STRING
        : await getDuplicateElements(
            searchFormValues?.discrepancyType?.value,
            "formattedname",
            allDiscrepancyTypes
          );
    if (mediaValue === t("common.all")) {
      detectedIn = includesAllSelection
        ? allDetectedTypes
        : detectedInDuplicates;
      discrepancyType = includesAllSelection
        ? allDiscrepancyTypes
        : discrepancyTypeDuplicates;
    } else if (mediaValue === t("discrepancyManagement.container")) {
      detectedIn = includesAllSelection
        ? allServiceOptionData[0]
        : detectedInDuplicates;
      discrepancyType = includesAllSelection
        ? allServiceOptionData[2]
        : discrepancyTypeDuplicates;
    } else if (mediaValue === t("discrepancyManagement.openMedia")) {
      detectedIn = includesAllSelection
        ? allServiceOptionData[1]
        : detectedInDuplicates;
      discrepancyType = includesAllSelection
        ? allServiceOptionData[3]
        : discrepancyTypeDuplicates;
    }
    const maxRowLimit = await getMaxDispalyRecords();

    let discrepancyId =
      discrepancyType && discrepancyType.map((i) => i.uniqueid.trim(""));

    let alternateId =
      discrepancyType && discrepancyType.map((i) => i.alternate_id);

    let discrepancyContext =
      discrepancyType && discrepancyType.map((i) => i.idcontext);

    const detectedInXML = await createDetectedTypeXml(
      includesAllSelection ? allServiceOptionData[0] : detectedInDuplicates
    );
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(detectedInXML, textXml);
    const detectedIns = xmlDoc.getElementsByTagName(detectedInId);

    const detectedInData = Array.from(detectedIns).map((detectedIn) =>
      detectedIn.getAttribute(detectedInIdKey)
    );
    const Detected_In_Descr = Array.from(detectedIns).map((detectedInDis) =>
      detectedInDis.getAttribute(detectedInDescrKey)
    );
    const Detected_In_Context = Array.from(detectedIns).map(
      (detectedInContext) =>
        detectedInContext.getAttribute(detectedInContextKey)
    );

    try {
      dispatch(setLoading(true));
      const payload = {
        main_district_id: mainDistrictId,
        district_id: mainDistrictId,
        detected_in_id: detectedInData.join(","),
        detected_in_descr: Detected_In_Descr.join(","),
        detected_in_context: Detected_In_Context.join(","),
        customer_id_list: searchFormValues?.customer?.value
          ? searchFormValues?.customer?.value
          : "",
        discrepancy_status:
          searchFormValues?.discrepancyStatus.value === ALL_SELECTION?.value
            ? capitalAll
            : searchFormValues?.discrepancyStatus.value,
        media_program_type:
          searchFormValues?.media?.value === ALL_SELECTION?.value
            ? all
            : searchFormValues?.media?.value,
        logical_vault_id:
          searchFormValues?.logicalVault?.value === ALL_SELECTION?.value
            ? VALUE_EMPTY_STRING
            : searchFormValues?.logicalVault?.value,
        from_detected_date: formatDateToString(
          searchFormValues?.detectedDateFrom
        ),
        to_detected_date: formatDateToString(searchFormValues?.detectedDateTo),
        from_resolved_date: formatDateToString(
          searchFormValues?.resolvedDateFrom
        ),
        to_resolved_date: formatDateToString(searchFormValues?.resolvedDateTo),
        volser_number: searchFormValues?.mediaNumber,
        service_date: formatDateToString(searchFormValues?.serviceDate),
        run_id:
          searchFormValues?.run?.value === ALL_SELECTION?.value
            ? VALUE_EMPTY_STRING
            : searchFormValues?.run?.value || VALUE_EMPTY_STRING,
        exclude_unresolved_no_action_flag: Y_CHECKER,
        exclude_auto_resolve_flag:
          searchFormValues?.excludeAutoResolved === true
            ? Y_CHECKER
            : N_CHECKER,
        om_cont_audit_id: prevValues ? auditId : VALUE_EMPTY_STRING,
        om_cont_discrepancy_id:
          searchFormValues?.discrepancyType?.value === ALL_SELECTION?.value
            ? VALUE_EMPTY_STRING
            : Number(searchFormValues?.discrepancyType?.alternateId),
        car_id: "",
        ga_max_num_search_results: maxRowLimit,
        ga_courtesy_followup_days: numberConstants.three,
        customer_media_descr: searchFormValues?.customerMediaIdentifier,
        region: process.env.REACT_APP_CF_REGION.toUpperCase(),
        district_name: currentBranch.label,
        user_name: user_name,
        report_format: selectedValue?.type?.toUpperCase(),

        uniqueid: discrepancyId ? discrepancyId.join(",") : "",
        alternateid: alternateId ? alternateId.join(",") : "",
        idcontext: discrepancyContext ? discrepancyContext.join(",") : "",

        om_discrepancy_id_list: "",
        om_detected_in_list: "",
        om_meida_type_list: "",
        om_resolution_list: "",
        container_discrepancy_id_list: "",
        container_detected_in_list: "",
        container_meida_type_list: "",
        container_resolution_list: "",

        ui_discrepancy_type:
          searchFormValues?.discrepancyType.value === ALL_SELECTION?.value
            ? capitalAll
            : searchFormValues?.discrepancyType.value,
        ui_media_hash: searchFormValues?.mediaNumber,
        ui_media:
          searchFormValues.media.value === ALL_SELECTION?.value
            ? capitalAll
            : searchFormValues.media.value,
        ui_logical_vault:
          searchFormValues?.logicalVault?.value === ALL_SELECTION?.value
            ? VALUE_EMPTY_STRING
            : searchFormValues?.logicalVault?.value,
        ui_detected_in: searchFormValues?.detectedIn.includes(
          ALL_SELECTION?.value
        )
          ? capitalAll
          : searchFormValues?.detectedIn.join(","),
        ui_run:
          searchFormValues?.run?.value === ALL_SELECTION?.value
            ? VALUE_EMPTY_STRING
            : searchFormValues?.run?.value || VALUE_EMPTY_STRING,

        ...reportConstantPayload
      };
      let reqBody = payload;
      let reqBodyString = JSON.stringify(reqBody);
      let res = await printReport(
        reqBodyString,
        CF_URLS.printReports.discrepancy.discrepancySearchReport
      );
      if (!res.success) {
        dispatch(setLoading(false));
        setThrowReportError(true);
        setThrowReportErrorMsg(res.error);
      }
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
      dispatch(setLoading(false));
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <>
      {(isLoading || loaders?.length > 0) && (
        <FullCircularLoader loading={isLoading || loaders?.length > 0} />
      )}
      <form>
        <Stack gap={spacing.gap} sx={DiscrepencyStyles.mainDivStyles}>
          <AuthForm
            setLoading={setLoading}
            resetStatesOnAuth={resetStatesOnAuthentication}
            isResetStatesOnAuth={true}
            showBypass={false}
          />
          {/* Download popup */}
          <DownloadPopup
            title={t("discrepancyManagement.title")}
            showPrintModal={showPrintModal}
            onCancleClick={() => {
              setShowPrintModal((prev) => !prev);
              setReportCFUrl(VALUE_EMPTY_STRING);
            }}
            onDownloadClick={handleDownload}
            setThrowError={setThrowReportError}
            throwError={throwReportError}
            throwErrorMessage={throwReportErrorMsg}
            cfUrl={reportCUrl}
            setSelectedValue={setSelectedValue}
          />
          {throwWarning && (
            <Alert
              severity="warning"
              icon={<img src={WarningIcon} alt="error" width="20" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(clearWarning());
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwWarningMsg}
            </Alert>
          )}

          {throwError && (
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" width="20" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(clearError());
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMsg}
            </Alert>
          )}

          {/* Table Section */}
          {(!showAuthSection || isAuthenticated) && (
            <>
              <DiscrepencySearch {...searchProps} />
              <Stack>
                <DiscrepencyTables {...tableProps} />
              </Stack>
              <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
                <Divider />
              </Grid>
              <Grid
                item
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="start"
                gap={spacing.verticalMargin20}
              >
                <Button
                  disabled={!openMediaData?.length && !containerData?.length}
                  id="clear-button"
                  variant="outlined"
                  type="button"
                  onClick={() => setShowPrintModal(true)}
                >
                  {t("containerAuditDetails.print")}
                </Button>

                <Button
                  variant="contained"
                  size="medium"
                  type="button"
                  onClick={() => {
                    handleUpdate();
                  }}
                  disabled={
                    selectedRow.length === 0 &&
                    selectedContainerRow.length === 0
                  }
                >
                  {t("discrepancyManagement.updateDiscrepancy")}
                </Button>
              </Grid>
            </>
          )}
        </Stack>
      </form>
    </>
  );
};
export default DiscrepencySearchLayout;
