import CloseIcon from "@mui/icons-material/Close";
import { Alert, Collapse, IconButton, Stack } from "@mui/material";
import { FullCircularLoader } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import { ErrorMessage, useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import {
  getCountries,
  getLocaleById,
  getMessageFromCode,
  getStateProvinceData
} from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  FIREBASE_FIELD_NAMES,
  VALUE_EMPTY_STRING,
  errorMsgs,
  notAvailableValue
} from "utils/constants";
import { vendorManagementConstants } from "utils/constants/vendorManagementContants";
import { findErrorMessage, getAuthenticatedUserBranch } from "utils/helpers";
import VendorManagementSearch from "./VendorManagementSearch";
import VendorManagementTable from "./VendorManagementTable";
import VendorManagementModal from "./VendorMangeMentModal";

const VendorManagementLayout = () => {
  const [vendorNameList, setVendorNameList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isResetGrid, setIsResetGrid] = useState(false);
  const [isFindButtonDisabled, setIsFindButtonDisabled] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [throwError, setThrowError] = useState(false);
  const [throwModalError, setThrowModalError] = useState(false);
  const [isShowSuccessPopup, setIsShowSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [selectedId, setSelctedId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [saveClicked, setSaveClicked] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [statesData, setStatesData] = useState();
  const [locationDetails, setLocationDetails] = useState(null);
  const [allLocations, setAllLocations] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [defaultLocaleId, setDefaultLocaleId] = useState(null);
  const [vendorUpdated, setVendorUpdated] = useState(false);
  const districtId = getAuthenticatedUserBranch();

  const { currentBranch } = useSelector(selectAuth);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  const initialSearchValues = {
    name: t("common.all"),
    stateProvinceId: t("common.all"),
    city: t("common.all")
  };

  const initialVendorValues = {
    name: "",
    contactName: "",
    accountNumber: "",
    address: "",
    city: "",
    stateProvinceId: null,
    zip: "",
    localeId: defaultLocaleId,
    countryId: selectedCountry,
    businessPhone1: "",
    businessPhone2: "",
    comment: ""
  };

  const setDefaultCountry = async () => {
    let localeData;
    if (selectedId) {
      if (!selectedRow?.locale_id) {
        return;
      }
      localeData = await getLocaleById(
        selectedRow?.locale_id,
        FIREBASE_FIELD_NAMES.LOCALE_ID
      );
    } else {
      if (!locationDetails?.iso_locale_code) {
        return;
      }
      localeData = await getLocaleById(
        locationDetails?.iso_locale_code,
        FIREBASE_FIELD_NAMES.ISO_CODE
      );
    }
    let country = countryList.filter((item) => {
      return item.value === localeData[0]?.country_id;
    });
    setSelectedCountry(country[0]);
    const states = statesData
      .filter((state) => state.country_id === localeData[0]?.country_id)
      .map((state) => ({
        value: state.id,
        label: state.name
      }));
    states.sort((a, b) => {
      if (a.label === notAvailableValue) return -1;
      if (b.label === notAvailableValue) return 1;
      return a.label.localeCompare(b.label);
    });
    setProvinces(states);
    let localeValue = localeData[0]?.locale_id;
    setDefaultLocaleId(localeValue);
    vendorManagementForm.setFieldValue("localeId", localeValue);
    vendorManagementForm.setFieldValue("countryId", country[0]);
  };

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(false);
    onFindClick();
  };

  const getLocaleData = async (country_id) => {
    let country = countryList.filter((item) => {
      return item.value === country_id?.value;
    });
    const localeData = await getLocaleById(
      country[0]?.value,
      FIREBASE_FIELD_NAMES.COUNTRY_ID
    );
    let vendorData = localeData[0];
    vendorManagementForm.setFieldValue("localeId", vendorData?.locale_id);
  };

  const handleSaveClickOnModal = async () => {
    const {
      name,
      contactName,
      accountNumber,
      address,
      city,
      stateProvinceId,
      zip,
      localeId,
      businessPhone1,
      businessPhone2,
      comment
    } = vendorManagementForm?.values || {};

    let request = {
      main_district_id: mainDistrictId,
      name: name.includes("'") ? name.replace(/'/g, "`") : name,
      contact_name: contactName.includes("'")
        ? contactName.replace(/'/g, "`")
        : contactName,
      account_number: accountNumber.replace(/'/g, "`"),
      address: address.includes("'") ? address.replace(/'/g, "`") : address,
      city: city.includes("'") ? city.replace(/'/g, "`") : city,
      state_province_id: stateProvinceId?.value || stateProvinceId,
      zip: zip,
      locale_id: localeId?.value || localeId,
      business_phone1: businessPhone1.includes("'")
        ? businessPhone1.replace(/'/g, "`")
        : businessPhone1,
      business_phone2: businessPhone2.includes("'")
        ? businessPhone2.replace(/'/g, "`")
        : businessPhone2,
      comment: comment.includes("'") ? comment.replace(/'/g, "`") : comment
    };
    const isNameExists = vendorNameList.some(
      (vname) => vname.label.toLowerCase() === name?.toLowerCase()
    );

    if (isCreating) {
      if (!name || !accountNumber || !businessPhone1 || !localeId) {
        setSaveClicked(true);
        return;
      }
      if (isNameExists) {
        setThrowErrorMessage(
          t("vendorManagement.nameExists") ||
            findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowModalError(true);
        return;
      }
      try {
        setIsLoading(true);
        const reqBody = JSON.stringify({
          ...request
        });
        let res = await getResponseData(
          reqBody,
          `${CF_URLS.vendorManagement.insertTransportVendor}`,
          1
        );
        setIsLoading(false);
        let saveData = res?.data[0][0];
        const errorId = saveData?.validation_error_id || saveData?.error;
        if (errorId === errorMsgs.noError) {
          setSuccessMessage(t("vendorManagement.insertMessage"));

          setIsShowSuccessPopup(true);
          handleCancelClick();
          await getVendorNameData();
          setVendorUpdated(true);
          setSearchedData(vendorData);
        } else if (errorId !== errorMsgs.noError) {
          const Msg = saveData?.format_example;
          const errorObj = await getMessageFromCode(errorMsgs?.errorCode63688);
          const errorMsg = errorObj[0]?.descr;
          let fieldName = "";
          if (errorId === vendorManagementConstants?.one) {
            fieldName = t("vendorManagement.phone1");
          } else if (errorId === vendorManagementConstants?.two) {
            fieldName = t("vendorManagement.phone2");
          } else if (errorId === vendorManagementConstants?.three) {
            fieldName = t("vendorManagement.postalCode");
          } else if (errorId === vendorManagementConstants?.four) {
            fieldName = t("vendorManagement.timestamp");
          }
          const errorString =
            fieldName?.length > 0
              ? errorMsg?.replace("|", fieldName)?.replace("|", Msg)
              : findErrorMessage(ERROR_TYPES.ISSUE);

          setThrowErrorMessage(
            errorString || findErrorMessage(ERROR_TYPES.ISSUE)
          );

          setThrowModalError(true);
        } else if (saveData?.error) {
          const errorMsg = await getMessageFromCode(saveData?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowModalError(true);
        } else if (res?.error) {
          setThrowErrorMessage(res?.error);
          setThrowModalError(true);
        }
      } catch (e) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowModalError(true);
      }
    }

    if (isUpdating) {
      if (!name || !accountNumber || !businessPhone1 || !localeId) {
        setSaveClicked(true);
        return;
      }
      try {
        setIsLoading(true);
        let requestBody = JSON.stringify({
          transportation_vendor_id: selectedId,
          ...request,
          ts1: selectedRow?.timestamp
        });
        let data = await getResponseData(
          requestBody,
          `${CF_URLS.vendorManagement.updateTransportVendor}`,
          1
        );
        let updateData = data?.data[0][0];
        const errorId = updateData?.validation_error_id || updateData?.error;
        if (updateData?.validation_error_id === errorMsgs.noError) {
          handleCancelClick();
          setSuccessMessage(t("vendorManagement.updateMessage"));
          setIsShowSuccessPopup(true);
          await getVendorNameData();
          setVendorUpdated(true);
          setSelectedRow((prev) => ({ ...prev, isEdited: true }));
        } else if (errorId !== errorMsgs.noError) {
          const Msg = updateData?.format_example;
          const errorObj = await getMessageFromCode(errorMsgs.errorCode63688);
          const errorMsg = errorObj[0]?.descr;
          let fieldName = "";
          if (errorId === vendorManagementConstants?.one) {
            fieldName = t("vendorManagement.phone1");
          } else if (errorId === vendorManagementConstants?.two) {
            fieldName = t("vendorManagement.phone2");
          } else if (errorId === vendorManagementConstants?.three) {
            fieldName = t("vendorManagement.postalCode");
          } else if (errorId === vendorManagementConstants?.four) {
            fieldName = t("vendorManagement.timestamp");
          }
          const errorString =
            fieldName?.length > 0
              ? errorMsg?.replace("|", fieldName)?.replace("|", Msg)
              : findErrorMessage(ERROR_TYPES.ISSUE);
          setThrowErrorMessage(
            errorString || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowModalError(true);
        } else if (data?.error) {
          setThrowErrorMessage(data.error);
          setThrowModalError(true);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowModalError(true);
      }
    }
    if (isDeleting) {
      setIsLoading(true);
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          transportation_vendor_id: selectedId
        });
        let res = await getResponseData(
          reqBody,
          `${CF_URLS.vendorManagement.deleteTransportVendor}`,
          1
        );
        setIsLoading(false);
        setIsDeleting(false);
        const err =
          res?.data[0][0]?.error === errorMsgs.errorCode547
            ? errorMsgs.errorCode20424
            : res?.data[0][0]?.error;
        if (err) {
          const errorMsg = await getMessageFromCode(err);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else if (res.error) {
          setThrowErrorMessage(res.error);
          setIsDeleting(false);
          setThrowModalError(true);
        } else {
          setSuccessMessage(t("vendorManagement.deleteMessage"));
          setIsShowSuccessPopup(true);
          handleCancelClick();
          await getVendorNameData();
          setVendorUpdated(true);
        }
      } catch (e) {
        setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
    }
  };
  const vendorSearchForm = useFormik({
    initialValues: initialSearchValues,
    onSubmit: handleSubmit
  });

  const vendorManagementForm = useFormik({
    initialValues: initialVendorValues,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      setSaveClicked(true);
      handleSaveClickOnModal(values);
    }
  });

  const getResponseData = async (reqBody, url, resultSetCount) => {
    try {
      const response = await callToCloudFunction(reqBody, url);
      const dataSets = await getDataFromFirestore(
        response,
        resultSetCount,
        response?.data?.docId
      );

      return dataSets;
    } catch (error) {
      return null;
    }
  };

  /**for vendor names and cities dropdown */
  const getVendorNameData = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.vendorManagement.getVendorNames,
        1
      );

      if (dataSets && dataSets?.data) {
        const vendorsAll = dataSets?.data[0]?.map((row) => ({
          ...row,
          isEdited: false
        }));

        vendorsAll?.sort((a, b) => a?.name?.localeCompare(b?.name));
        setVendorData(vendorsAll);
        setVendorUpdated(false);
        const names = dataSets?.data[0]?.map(
          ({ name, transportation_vendor_id }) => ({
            label: name,
            value: transportation_vendor_id
          })
        );

        if (names) {
          const namesAll = [ALL_SELECTION].concat(names);
          namesAll.sort((a, b) => a.label.localeCompare(b.label));
          setVendorNameList(namesAll);
        } else {
          setVendorNameList([]);
        }

        const citiesSet = new Set();
        dataSets?.data[0]?.forEach(({ city }) => {
          if (city.trim() !== "") {
            citiesSet.add(city);
          }
        });

        const cities = Array.from(citiesSet).map((city) => ({
          label: city,
          value: city
        }));

        const citiesAll = [ALL_SELECTION, ...cities];
        citiesAll.sort((a, b) => a.label.localeCompare(b.label));
        setCityList(citiesAll);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
    setIsLoading(false);
  };

  /* for state dropdown*/
  const getProvinceData = async () => {
    try {
      const result = await getStateProvinceData();
      const response = result.map((res) => ({
        value: res.id,
        label: res.name
      }));
      setStatesData(result);
      const responseWithAll = [ALL_SELECTION].concat(response);
      responseWithAll.sort((a, b) => a.label.localeCompare(b.label));
      setStateList(responseWithAll);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  /* for country dropdown*/
  const getCountryListData = async () => {
    try {
      const result = await getCountries();
      const countryData = result?.filter(
        (item) => item.phone_country_code !== notAvailableValue
      );
      const response = countryData?.map((res) => ({
        value: res.country_id,
        label: `${res.alt_name} +${res.phone_country_code}`
      }));
      response.sort((a, b) => a.label.localeCompare(b.label));
      setCountryList(response);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  useEffect(() => {
    mainDistrictId && getVendorNameData();
    getCountryListData();
    getProvinceData();
    getLocationsByBranch();
    setThrowError(false);
    setThrowModalError(false);
    vendorSearchForm.setValues(initialSearchValues);
    //eslint-disable-next-line
  }, [mainDistrictId]);

  useEffect(() => {
    if (vendorUpdated) {
      const filteredData = filteredVendors();
      setSearchedData(filteredData);
    }
    //eslint-disable-next-line
  }, [vendorUpdated, vendorData]);

  useEffect(() => {
    if (selectedId) {
      const updatedValues = {
        name: selectedRow?.name,
        contactName: selectedRow?.contact_name,
        accountNumber: selectedRow?.account_number,
        address: selectedRow.address,
        city: selectedRow?.city,
        stateProvinceId:
          selectedRow?.state_province_id === vendorManagementConstants.zero
            ? VALUE_EMPTY_STRING
            : selectedRow?.state_province_id,
        zip: selectedRow?.zip,
        localeId: selectedRow?.locale_id,
        countryId: selectedRow?.country_id,
        businessPhone1: selectedRow?.business_phone1,
        businessPhone2: selectedRow?.business_phone2,
        comment: selectedRow?.comment
      };
      vendorManagementForm.setValues(updatedValues);
    } else {
      vendorManagementForm.setValues(initialVendorValues);
    }

    //eslint-disable-next-line
  }, [selectedId]);

  useEffect(() => {
    if (locationDetails) {
      setDefaultCountry();
    }
    //eslint-disable-next-line
  }, [locationDetails, selectedId]);

  useEffect(() => {
    if (allLocations.length > 0) {
      const selectedLocationDetails = allLocations.filter(
        (location) => location.district_id === districtId
      );
      setLocationDetails(selectedLocationDetails[0]);
    }
    //eslint-disable-next-line
  }, [allLocations]);

  useEffect(() => {
    throwError && window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [throwError]);

  const getLocationsByBranch = async () => {
    try {
      setIsLoading(true);
      const locationReqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      });
      const locationsData = await getResponseData(
        locationReqBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.COMMON.getalllocations}`,
        1
      );

      if (locationsData?.data[0]) {
        setAllLocations(locationsData?.data[0]);
      } else if (locationsData?.error) {
        setThrowErrorMessage(locationsData.error);
        setThrowError(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(e?.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const onFindClick = async () => {
    setIsFindButtonDisabled(true);
    await getVendorNameData();

    const filteredData = filteredVendors();
    setSearchedData(filteredData);

    setIsResetGrid(true);
  };

  const filteredVendors = () => {
    const { name, city, stateProvinceId } = vendorSearchForm?.values || {};
    const cityName = city?.label === t("common.all") ? null : city?.label;
    const nameId = name?.label === t("common.all") ? null : name?.label;
    const stateId =
      stateProvinceId?.value === t("common.all")
        ? null
        : stateProvinceId?.value;
    const filtered = vendorData.filter((item) => {
      if (nameId && item.name !== nameId) return false;
      if (cityName && item.city !== cityName) return false;
      if (stateId && item.state_province_id !== stateId) return false;
      return true;
    });

    filtered?.sort((a, b) => a?.name?.localeCompare(b?.name));
    return filtered;
  };
  const handleReset = () => {
    vendorSearchForm.setValues(initialSearchValues);
    setThrowError(false);
    setIsFindButtonDisabled(false);
    setSearchedData([]);

    setIsResetGrid(false);
  };
  const searchFilterProps = {
    vendorSearchForm,
    cityList,
    stateList,
    vendorNameList,
    isFindButtonDisabled,
    setIsFindButtonDisabled,
    handleReset
  };

  const handleCancelClick = () => {
    setIsCreating(false);
    setIsUpdating(false);
    setIsViewing(false);
    setIsDeleting(false);
    setSelectedRow(null);
    setSelctedId(null);
    setSaveClicked(false);
    vendorManagementForm.setValues(initialVendorValues);
    setThrowModalError(false);
  };
  const modalProps = {
    isCreating,
    isUpdating,
    isDeleting,
    isViewing,
    handleCancelClick,
    handleSaveClickOnModal,
    vendorManagementForm,
    countryList,
    stateList,
    ErrorMessage,
    selectedRow,
    saveClicked,
    vendorName: selectedRow?.name,
    throwModalError,
    throwErrorMessage,
    setThrowModalError,
    getLocaleData,
    selectedCountry,
    statesData,
    provinces,
    setProvinces
  };

  const tableProps = {
    searchedData,
    setIsCreating,
    setIsUpdating,
    setIsDeleting,
    setIsViewing,
    selectedId,
    selectedRow,
    setSelectedRow,
    setSelctedId,
    isResetGrid,
    stateList,
    isUpdating,
    vendorManagementForm,
    initialVendorValues
  };

  return (
    <>
      {isLoading && <FullCircularLoader loading={isLoading} />}
      {isShowSuccessPopup && !isLoading && (
        <SuccessPopup message={successMessage} close={setIsShowSuccessPopup} />
      )}
      {throwError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            sx={{ marginBottom: spacing.margingTop20 }}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      <Stack>
        <VendorManagementSearch {...searchFilterProps} />
      </Stack>
      <Stack>
        <VendorManagementTable {...tableProps} />
      </Stack>

      <Stack>
        <VendorManagementModal {...modalProps} />
      </Stack>
    </>
  );
};
export default VendorManagementLayout;
