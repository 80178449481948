import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TableContainer,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { ContainerProcessingStyles } from "components/container-processing/ContainerProcessingStyles";
import { FullCircularLoader } from "components/core";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import {
  getCustomersQueryData,
  getMessageFromCode,
  getServiceResult
} from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  default_report_date,
  downloadOptionPdf,
  errorMsgs,
  incomplete_stops_comment,
  incomplete_stops_resolution
} from "utils/constants";
import {
  findErrorMessage,
  formatBillDateByLocale,
  getDateFromUTCFormat,
  getResponseData,
  getSortedData,
  getUserName,
  printReport
} from "utils/helpers";
import { incompleteStops } from "./IncompleteStopStyles";
import SaveChangesModal from "./SaveChangesModal";
const { default: MUIDataTable } = require("mui-datatables");

const IncompleteStops = () => {
  const { currentBranch, auth, localeByBranch } = useSelector(selectAuth);
  const [incompleteStopsData, setIncompleteStopsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorAlertMsg, setShowErrorAlertMsg] = useState("");
  // const [localDate, setLocalDate] = useState(""); // ToDo - Need a to get the confiramation and remove
  const [serviceDates, setServiceDates] = useState([]);
  const [resolutionData, setResolutionData] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [showPrintReportModal, setShowPrintReportModal] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [saveChangesWording, setSaveChangesWording] = useState("");
  const [findEnable, setFindEnable] = useState(false);
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);
  const [reportCFUrl, setReportCFUrl] = useState("");
  let initialValues = {
    isAllDatesChecked: true,
    runDate: null,
    run: null,
    resolution: null,
    comment: ""
  };

  const incompleteStopsForm = useFormik({
    initialValues: initialValues
  });

  // table columns
  const columns = [
    {
      name: "",
      label: ""
    },
    {
      name: "run_name",
      label: t("containerProcessing.incompleteStops.runLable")
    },
    {
      name: "service_date",
      label: t("containerProcessing.incompleteStops.serviceDates")
    },
    {
      name: "cls_customer_id",
      label: t("containerProcessing.incompleteStops.clsCustomerCode")
    },
    {
      name: "service_rep_id",
      label: t("containerProcessing.incompleteStops.serviceRep")
    }
  ];

  // table options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={incompleteStopsData?.length === 0}
            onClick={() => onPrintButtonCLick()}
          >
            {t("common.print")}
          </Button>
        </Grid>
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          incompleteStopsData?.length === 0 &&
          `${t("common.tableTextWithoutInputs")}`
      }
    },
    setRowProps: (row, dataIndex) => {
      return {
        style: {
          backgroundColor:
            dataIndex === selectedRow
              ? ContainerProcessingStyles?.selectedColor
              : ""
        }
      };
    },
    onRowClick: (row, dataIndex) => handleRowClick(row, dataIndex)
  };

  // ToDo - Need a to get the confiramation and remove
  // const getLocalDateAndTime = async () => {
  //   setIsLoading((prev) => true);
  //   try {
  //     let reqBody = {
  //       main_district_id: currentBranch?.value
  //     };

  //     const localDateAndTime = await getResponseData(
  //       reqBody,
  //       `${CF_URLS.incompleteStops?.getLocalDateTime}`,
  //       2
  //     );

  //     const localDateTimeErrorResponse = localDateAndTime?.data[1]; // get error code

  //     // if there is an error showing the error and exit from the process
  //     if (localDateTimeErrorResponse[0]?.error !== errorMsgs?.noError) {
  //       const errMsg = await getTheFirestoreErrorMessage(
  //         localDateTimeErrorResponse[0]?.error
  //       );
  //       setShowErrorAlert(true);
  //       setShowErrorAlertMsg(errMsg?.errorMsg);
  //       setIsLoading((prev) => false);
  //       return;
  //     }

  //     const localDateTimeDataResponse = localDateAndTime?.data[0]; // get the success response
  //     setLocalDate(localDateTimeDataResponse[0]?.local_getdate);
  //     setIsLoading((prev) => false);
  //   } catch (error) {
  //     setShowErrorAlert(true);
  //     setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
  //     setIsLoading(false);
  //   }
  // };

  const findButtonClick = async (run_id) => {
    setIsLoading((prev) => true);
    setIncompleteStopsData((prev) => []);
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        run_id: incompleteStopsForm.values.isAllDatesChecked
          ? ""
          : incompleteStopsForm.values.run?.run_id
      };

      const incompleteStopsData = await getResponseData(
        reqBody,
        `${CF_URLS.incompleteStops?.findallincompletestops}`,
        2
      );

      const incompleteStopsDataResponse = incompleteStopsData?.data[1]; // get error code

      // if there is an error showing the error and exit from the process
      if (incompleteStopsDataResponse[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          incompleteStopsDataResponse[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const incompleteStopsSuccessResponse = incompleteStopsData?.data[0]; // get the success response

      let mappedData = [];

      for (
        let index = 0;
        index < incompleteStopsSuccessResponse.length;
        index++
      ) {
        const element = incompleteStopsSuccessResponse[index];
        // Extract hours and minutes
        const hours = element?.service_time?.substring(0, 2);
        const minutes = element?.service_time?.substring(2, 4);

        const serviceDate = `${getDateFromUTCFormat(element?.run_date)}`;

        mappedData?.push({
          ...element,
          run_name: element?.run_name,
          service_date: `${serviceDate} ${hours}:${minutes}`,
          cls_customer_id: allCustomers?.find(
            (c) => c?.id === element?.csl_customer_id
          )?.number,
          service_rep_id: element?.employee_name
        });
      }

      // Sorting function
      await mappedData?.sort((a, b) => {
        // Compare run_date first
        const dateA = new Date(a.run_date);
        const dateB = new Date(b.run_date);
        if (dateA.getTime() !== dateB.getTime()) {
          return dateB.getTime() - dateA.getTime();
        }

        // If run_date is the same, compare service_time
        const timeA = timeToMinutes(a.service_time);
        const timeB = timeToMinutes(b.service_time);
        if (timeA !== timeB) {
          return timeA - timeB;
        }

        // If both run_date and service_time are the same, compare run_name
        return a.run_name.localeCompare(b.run_name);
      });

      const sortedData = mappedData?.map((i, key) => {
        return { key: key, ...i };
      });

      setIncompleteStopsData(sortedData);
      setFindEnable(false);
      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  // Function to convert time string to minutes
  function timeToMinutes(time) {
    const hours = parseInt(time.slice(0, 2), 10);
    const minutes = parseInt(time.slice(2), 10);
    return hours * 60 + minutes;
  }

  // all dates checkbox
  const handleAllDates = async (event) => {
    if (
      incompleteStopsForm?.values?.resolution ||
      incompleteStopsForm?.values?.comment?.trim()?.length
    ) {
      setShowErrorAlert(false);
      setShowErrorAlertMsg("");
      const infoMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode10039
      );
      setSaveChangesWording(infoMsg?.errorMsg);
      setShowSaveChangesModal(true);
      return;
    }
    incompleteStopsForm.setFieldValue(
      "isAllDatesChecked",
      event.target.checked
    );
    setFindEnable(true);
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setIncompleteStopsData([]);
  };

  const getRunDates = async () => {
    setIsLoading((prev) => true);
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        run_date: ""
      };

      const incompleteRunDatesData = await getResponseData(
        reqBody,
        `${CF_URLS.incompleteStops?.getServiceDates}`,
        2
      );

      const incompleteStopsDataResponse = incompleteRunDatesData?.data[1]; // get error code

      // if there is an error showing the error and exit from the process
      if (incompleteStopsDataResponse[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          incompleteStopsDataResponse[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const incompleteStopsDataSuccessResponse =
        incompleteRunDatesData?.data[0]; // get data set

      const mappedDates = incompleteStopsDataSuccessResponse?.map((date) => {
        return {
          ...date,
          value: date?.run_date
        };
      });

      let datesFormatted = await formatBillDateByLocale(
        mappedDates,
        localeByBranch,
        false
      );

      // Sort data array by run_date in descending order (most recent to oldest)
      datesFormatted.sort((a, b) => {
        // Convert run_date strings to Date objects for comparison
        let dateA = new Date(a.run_date);
        let dateB = new Date(b.run_date);

        // Compare dates (descending order)
        return dateB - dateA;
      });

      // Initialize an object to store grouped data
      let groupedData = {};

      // Group data by run_date
      datesFormatted.forEach((item) => {
        if (!groupedData[item.run_date]) {
          // If run_date key does not exist, initialize it with main details
          groupedData[item.run_date] = {
            run_date: item.run_date,
            value: item.value,
            label: item.label,
            run: [] // Initialize an empty array for run objects
          };
        }
        // Push run_name and run_id into the 'run' array
        groupedData[item.run_date].run.push({
          run_name: item.run_name,
          run_id: item.run_id,
          label: item.run_name,
          value: item.run_id
        });
      });

      // Convert groupedData object into an array of objects
      let result = Object.values(groupedData);
      setServiceDates(result);
      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  // run date change
  const onRunDateChange = (selectedDate) => {
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setIncompleteStopsData([]);
    incompleteStopsForm.setFieldValue("runDate", selectedDate?.target?.value);
    if (selectedDate?.target?.value?.run?.length === 1) {
      incompleteStopsForm.setFieldValue(
        "run",
        selectedDate?.target?.value?.run[0]
      );
    } else {
      incompleteStopsForm.setFieldValue("run", null);
    }
    setFindEnable(true);
  };

  // run change
  const onRunChange = (selectedRun) => {
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setIncompleteStopsData([]);
    incompleteStopsForm.setFieldValue("run", selectedRun?.target?.value);
    setFindEnable(true);
  };

  const getResolutions = async () => {
    setIsLoading((prev) => true);
    try {
      const res = await getServiceResult();
      const sortedData = res.sort((a, b) => {
        return parseInt(a.id) - parseInt(b.id);
      });
      setResolutionData(sortedData);
      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const onResolutionChange = async (selectedResolution) => {
    incompleteStopsForm.setFieldValue(
      "resolution",
      selectedResolution?.target?.value
    );
  };

  // handle field values
  const handleFieldChanges = (e, fieldName) => {
    const { value } = e.target;
    incompleteStopsForm.setFieldValue(fieldName, value);
  };

  const getCustomerDataForSelectedBranch = async () => {
    try {
      const customerList = await getCustomersQueryData(currentBranch?.value);
      let customers = customerList?.map((cus) => {
        return {
          ...cus,
          label: `${cus?.number} - ${cus?.name}`,
          value: cus?.id
        };
      });
      const listOfCustomers = getSortedData(customers, "number", "asc");
      setAllCustomers(listOfCustomers);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  // clear button - todo will be used in future
  // const clearButtonClick = () => {};

  // row click
  const handleRowClick = (rowData, rowState) => {
    setSelectedRow(rowState.dataIndex);
  };

  const onPrintButtonCLick = () => {
    setShowPrintReportModal(true);
    setReportCFUrl(
      CF_URLS.printReports.containerProcessing.incompleteStopsReport
    );
  };

  const onCancelClick = () => {
    setShowPrintReportModal(false);
    setReportCFUrl("");
  };

  const onDownloadClick = async () => {
    try {
      setIsLoading(true);
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.value,
        run_date: incompleteStopsForm?.values?.isAllDatesChecked
          ? default_report_date
          : incompleteStopsForm?.values?.runDate?.value?.split("T")[0],
        run_name: incompleteStopsForm?.values?.isAllDatesChecked
          ? ""
          : incompleteStopsForm?.values?.run?.run_name,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });
      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.containerProcessing.incompleteStopsReport}`
      );
      if (!reportResponse.success) {
        setShowErrorAlert(true);
        setShowErrorAlertMsg(reportResponse.error);
        setIsLoading(false);
      }
    } catch (e) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setIsLoading(false);
    }
  };

  // save click
  const onSaveClick = async () => {
    setIsLoading((prev) => true);

    if (
      !incompleteStopsForm?.values?.resolution ||
      !incompleteStopsForm?.values?.comment?.trim()?.length
    ) {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode20259
      );

      // Replace the placeholder with the field name
      const formattedErrorMsg = errMsg?.errorMsg?.replace(
        "|",
        !incompleteStopsForm?.values?.comment?.trim()?.length
          ? incomplete_stops_comment
          : incomplete_stops_resolution
      );

      setShowErrorAlert(true);
      setShowErrorAlertMsg(formattedErrorMsg);
      setIsLoading((prev) => false);
      return;
    }

    try {
      const rowSelected = incompleteStopsData?.find(
        (i) => i?.key === selectedRow
      );

      const reqBody = {
        main_district_id: currentBranch?.value,
        run_position_id: rowSelected?.run_position_id,
        service_result_id:
          incompleteStopsForm?.values?.resolution?.service_result_id,
        run_position_ts: rowSelected?.timestamp,
        comment: incompleteStopsForm?.values?.comment
      };

      const updateData = await getResponseData(
        reqBody,
        `${CF_URLS.incompleteStops?.updateRunPositionCompletion}`,
        2
      );

      const updateDataResponse = updateData?.data[0]; // get error code

      // if there is an error showing the error and exit from the process
      if (updateDataResponse[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          updateDataResponse[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      incompleteStopsForm.setFieldValue("resolution", null);
      incompleteStopsForm.setFieldValue("comment", "");
      setFindEnable(false);
      findButtonClick();
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const onCancelModalClick = async () => {
    setShowSaveChangesModal(false);
    setSaveChangesWording("");
  };

  const onNoModalClick = () => {
    setShowSaveChangesModal(false);
    setSaveChangesWording("");
    incompleteStopsForm.setFieldValue(
      "isAllDatesChecked",
      !incompleteStopsForm.values.isAllDatesChecked
    );
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setIncompleteStopsData([]);
  };

  const onYesModalClick = () => {
    setShowSaveChangesModal(false);
    setSaveChangesWording("");
    onSaveClick();
  };

  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };

  // ToDo - Need a to get the confiramation and remove
  // useEffect(() => {
  //   getLocalDateAndTime();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    setIsLoading((prev) => true);
  }, []);

  useEffect(() => {
    if (allCustomers?.length) {
      findButtonClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localeByBranch, allCustomers]);

  useEffect(() => {
    if (!incompleteStopsForm?.values?.isAllDatesChecked) {
      getRunDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incompleteStopsForm?.values?.isAllDatesChecked]);

  useEffect(() => {
    if (serviceDates?.length) {
      incompleteStopsForm.setFieldValue("runDate", serviceDates[0]);
      if (serviceDates[0]?.run?.length === 1) {
        incompleteStopsForm.setFieldValue("run", serviceDates[0]?.run[0]);
      } else {
        incompleteStopsForm.setFieldValue("run", null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceDates]);

  useEffect(() => {
    if (incompleteStopsData?.length) {
      getResolutions();
    }
  }, [incompleteStopsData]);

  useEffect(() => {
    getCustomerDataForSelectedBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <FullCircularLoader />}
      {showErrorAlert && (
        <Alert
          severity="error"
          icon={<img src={ErrorIcon} alt="error" width="20" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setShowErrorAlert(false);
                setShowErrorAlertMsg("");
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          style={incompleteStops?.margin}
        >
          {Array.isArray(showErrorAlertMsg)
            ? showErrorAlertMsg.map((message, index) => (
                <>
                  {index === 0 ? <b>{message}</b> : `${index}) ${message}`}
                  <br />
                </>
              ))
            : showErrorAlertMsg}
        </Alert>
      )}
      <Grid container spacing={2} alignItems="">
        <Grid item md={6} lg={3} sx={2}>
          <FormControl fullWidth>
            <InputLabel
              id="branch-selectLabel"
              shrink={incompleteStopsForm?.values?.runDate}
            >
              {t("billingStatus.billDate")}
            </InputLabel>
            <Select
              labelId="billCycleSelectLabel"
              id="bill-cycle-select"
              value={incompleteStopsForm?.values?.runDate}
              label={t("billingStatus.billDate")}
              onChange={onRunDateChange}
              disabled={incompleteStopsForm.values.isAllDatesChecked}
              notched={incompleteStopsForm?.values?.runDate}
            >
              {serviceDates?.length > 0 &&
                serviceDates?.map((b) => {
                  return (
                    <MenuItem value={b} key={b?.label}>
                      {b?.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} lg={3} sx={2}>
          <FormControl fullWidth variant="outlined">
            <>
              <InputLabel shrink={incompleteStopsForm?.values?.run}>
                {t("containerProcessing.incompleteStops.run")}
              </InputLabel>
              <Select
                label={t("containerProcessing.incompleteStops.run")}
                name={"Runlabel"}
                disabled={incompleteStopsForm.values.isAllDatesChecked}
                onChange={onRunChange}
                value={incompleteStopsForm?.values?.run}
                notched={incompleteStopsForm?.values?.run}
              >
                {incompleteStopsForm.values?.runDate &&
                  incompleteStopsForm.values?.runDate?.run?.map((b) => {
                    return (
                      <MenuItem value={b} key={b?.label}>
                        {b?.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </>
          </FormControl>
        </Grid>
        <Grid item md={6} lg={3} xs={2}>
          <Stack sx={ContainerProcessingStyles.formControllabelStart}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={incompleteStopsForm.values.isAllDatesChecked}
                  onChange={handleAllDates}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={t("containerProcessing.incompleteStops.allDates")}
            />
          </Stack>
        </Grid>
      </Grid>
      <br />
      <Stack direction="row" gap={spacing.gap}>
        {/* todo will be used in future*/}
        {/* <Button variant="outlined" onClick={() => clearButtonClick()}>
          {t("common.clear")}
        </Button> */}
        <Button
          variant="contained"
          onClick={() => findButtonClick()}
          disabled={!findEnable}
        >
          {t("common.find")}
        </Button>
      </Stack>
      <br />
      <Stack>
        <Typography sx={ContainerProcessingStyles.BorderStyles}></Typography>
      </Stack>
      <Grid container spacing={2} alignItems="">
        <Grid item md={6} lg={6} xs={2}>
          <FormControl fullWidth variant="outlined">
            <>
              <InputLabel>
                {t("containerProcessing.incompleteStops.resolution")}
              </InputLabel>
              <Select
                label={t("containerProcessing.incompleteStops.resolution")}
                name={"Resolutionlabel"}
                disabled={!incompleteStopsData?.length}
                onChange={onResolutionChange}
                value={incompleteStopsForm?.values?.resolution}
              >
                {resolutionData?.length > 0 &&
                  resolutionData?.map((b) => {
                    return (
                      <MenuItem value={b} key={b?.label}>
                        {b?.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </>
          </FormControl>
        </Grid>
        <Grid item md={6} lg={6} xs={2}>
          <FormControl fullWidth variant="outlined">
            <TextField
              placeholder="Comments"
              multiline
              rows={3}
              maxRows={8}
              disabled={!incompleteStopsData?.length}
              onChange={(e) => {
                handleFieldChanges(e, "comment");
              }}
              inputProps={{
                maxLength: 512
              }}
              value={incompleteStopsForm?.values?.comment}
            />
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Typography sx={ContainerProcessingStyles.BorderStyles1}></Typography>
      <br />
      <Paper>
        <TableContainer>
          <MUIDataTable
            columns={columns}
            options={options}
            data={incompleteStopsData}
            title={
              <Typography style={incompleteStops.titleStyle}>
                {incompleteStopsData?.length} {t("common.recordsFound")}
              </Typography>
            }
          />
        </TableContainer>
      </Paper>
      <br />
      <Stack direction="row" gap={spacing.gap}>
        <Button
          variant="contained"
          disabled={
            !incompleteStopsForm?.values?.resolution ||
            !incompleteStopsForm?.values?.comment?.trim()?.length
          }
          onClick={() => onSaveClick()}
        >
          {t("common.save")}
        </Button>
      </Stack>

      {/* Report download */}
      <DownloadPopup
        title={t("common.downloadReport")}
        showPrintModal={showPrintReportModal}
        cfUrl={reportCFUrl}
        onCancleClick={onCancelClick}
        onDownloadClick={onDownloadClick}
        setSelectedValue={setSelectedValue}
      />

      {/* save changes modal */}
      <SaveChangesModal
        showSaveChangesModal={showSaveChangesModal}
        saveChangesWording={saveChangesWording}
        onCancelModalClick={onCancelModalClick}
        onNoModalClick={onNoModalClick}
        onYesModalClick={onYesModalClick}
      />
    </>
  );
};
export default IncompleteStops;
