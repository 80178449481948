import {
  Button,
  Grid,
  Paper,
  SvgIcon,
  TableContainer,
  Typography
} from "@mui/material";
import { ReactComponent as ViewIcon } from "assets/images/RemoveRedEyeFilled.svg";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  dateFormatWithoutTime,
  dayMonthYearFormat
} from "utils/constants";
import { AuditStyles } from "./auditStyles";

const AuditTable = ({
  searchResults,
  setSelectedId,
  setSelectedRow,
  handleNavigationClick,
  handlePrintButtonClick,
  isNewVisible,
  isResetGrid,
  maxRecordMsg
}) => {
  const handleRowClick = (value, tableMeta) => {
    setSelectedId(value?.om_audit_id);
    setSelectedRow(searchResults[tableMeta?.rowIndex]);
    handleNavigationClick(value?.container_audit_id);
  };
  const { localeByBranch } = useSelector(selectAuth);
  const columns = [
    {
      name: "container_audit_id",
      label: t("containerAuditSearch.auditIdLabel"),

      options: {
        setCellProps: () => ({
          style: AuditStyles.actionColumnStyle
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = searchResults[tableMeta.rowIndex];

          return (
            <>
              <Button
                onClick={() => {
                  handleRowClick(rowData, tableMeta);
                }}
              >
                <SvgIcon component={ViewIcon} />
              </Button>
              {value}
            </>
          );
        }
      }
    },

    {
      name: "audit_type",
      label: t("containerAuditSearch.auditType")
    },
    {
      name: "audit_status",
      label: t("containerAuditSearch.auditStatusLabel")
    },
    {
      name: "due_date",
      label: t("containerAuditSearch.dueDateLabel"),
      options: {
        customBodyRender: (value) => {
          const formattedDate = value
            ? dayjs
              .utc(value)
              .locale(localeByBranch)
              .format(dateFormatWithoutTime)
            : "";
          return formattedDate;
        }
      }
    },
    {
      name: "customer_number",
      label: t("containerAuditSearch.customerLabel")
    },
    {
      name: "requested_by_personnel",
      label: t("containerAuditSearch.personnel")
    },
    {
      name: "audit_date_ltz",
      label: t("containerAuditSearch.audiDate"),
      options: {
        customBodyRender: (value) => {
          const formattedDate = value
            ? dayjs.utc(value).format(dayMonthYearFormat)
            : "";
          return formattedDate;
        }
      }
    },
    {
      name: "scan_count",
      label: t("containerAuditSearch.scanCount")
    },
    {
      name: "exception_count",
      label: t("containerAuditSearch.exceptions")
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    pagination: 0 && true,
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={searchResults?.length === 0}
            onClick={handlePrintButtonClick}
          >
            {t("common.print")}
          </Button>
          {isNewVisible && (
            <Button
              variant="outlined"
              size="medium"
              type="button"
              disabled={null}
              onClick={() => handleNavigationClick("")}
            >
              {t("common.new")}
            </Button>
          )}
        </Grid>
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          !isResetGrid && searchResults.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      }
    }
  };
  return (
    <>
      <Paper>
        <TableContainer>
          <MUIDataTable
            columns={columns}
            options={options}
            data={searchResults}
            title={
              !isResetGrid ? (
                <Typography style={AuditStyles.titleStyle}>
                  {maxRecordMsg ? (
                    maxRecordMsg
                  ) : (
                    <>
                      {searchResults?.length} {t("common.recordsFound")}
                    </>
                  )}
                </Typography>
              ) : (
                ""
              )
            }
          />
        </TableContainer>
      </Paper>
    </>
  );
};
export default AuditTable;
