export const TabShortcutsStyles = {
  Title1: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    color: "var(--color-secondary-blue)"
  },
  navigateTitle: {
    fontSize: "16px",
    fontWeight: 400,
    color: "black",
    textDecoration: "none"
  },
  iconDiv: {
    width: "51px"
  },
  iconStyles: { width: "100%", height: "auto" },
  navStyles: { cursor: "pointer", display: "flex", alignItems: "center" }
};
