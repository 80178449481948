import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DISCREPANCY_MANAGEMENT,
  DISCREPANCY_MANAGEMENT_DETAIL,
  OPERATIONS_MODULE_BASE_PATH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { getRunRequestStatus } from "services/common";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  authSection,
  dateFormat,
  dayMonthYearFormat,
  dayMonthYearTimeFormat,
  dropdownListValue,
  ERROR_TYPES,
  EXCHANGE_LOCATION_TYPE_SL,
  GLOBAL_ATTRIBUTE_VALUES,
  maximumDate,
  mdyDateFormat,
  N_CHECKER,
  NO,
  numberConstants,
  ON_CALL,
  SYSTEM_ID_SecureBaseWeb,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  YES
} from "utils/constants";

import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
  pickersDayClasses
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getExchangeStatusById,
  getMediaTypeList,
  getMessageFromCode
} from "services/api/query";
import { setWarning } from "store/slices/discrepancy-search";
import {
  clearError,
  setAuthTypeChange,
  setResolutionType,
  setShowAuthSection,
  updateDiscrepancyAuthFormValues,
  updatePreferencesAuthentication
} from "store/slices/discrepancy-update";
import {
  detectedInType,
  fileSectionTypeIds,
  systemFunctionTitle
} from "utils/constants/discrepancy-management/DiscrepancySearchConstants";
import {
  byPass,
  formatWithSeconds,
  incompleteStop,
  missing,
  noOrInvalidReturnDate,
  number_63,
  number_67,
  number_73,
  operationalModeId,
  programId,
  reportType,
  resolutionTypes,
  returnDate,
  suggestionTypes,
  TR_String,
  unexpected
} from "utils/constants/discrepancy-management/DiscrepancyUpdate";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getGlobalAttributeValue,
  getResponseData
} from "utils/helpers";
import { ReactComponent as DisabledDatePickerIcon } from "../../../assets/images/DatePickerDisableIcon.svg";
import { ReactComponent as DatePickerIcon } from "../../../assets/images/DatePickerIcon.svg";
import AdditionalInfoTable from "./AdditionalInfoTable";
import { DetailsStyles } from "./Styles";
import UploadErrorList from "./UploadErrorList";
const today = dayjs();
//max date state
const maxDate = dayjs(maximumDate);

const DiscrepancyUpdate = ({
  setIsLoading,
  setThrowErrorMessage,
  isLoading,
  handlePrint
}) => {
  const { localeByBranch, currentBranch, auth } = useSelector(selectAuth);
  let location = useLocation();
  const { selectedValues, type, mediaList, searchFormValues } = useSelector(
    ({ discrepancySearch }) => discrepancySearch
  );
  const { discrepancyAuthFormValues, isAuthenticated, resolutionType } =
    useSelector((state) => state.discrepancyUpdate);
  const [data, setData] = useState({});
  const [additionalResolution, setAdditionalResolution] = useState([]);
  const [temloader, setTempLoader] = useState(false);
  const [dropdownList, setDropDownList] = useState([]);
  const [personnelDropdown, setPersonnelDropdown] = useState([]);
  const [mediaDropdown, setMediaDropdown] = useState([]);
  const [personnelDropdownTem, setPersonnelDropdownTem] = useState([]);
  const [mediaTypeList, setMediaTypeList] = useState([]);
  const [logicalVaultList, setLogicalVaultList] = useState([]);
  const [runStatus, setRunStatus] = useState(null);
  const [requestStatusId, setRequestStatusId] = useState(null);
  const [slotDateList, setSlotDateList] = useState([]);
  const [uploadErroList, setUploadErroList] = useState([]);
  const [requestIdListForScheduledDays, setRequestIdListForScheduledDays] =
    useState({});

  const navigate = useNavigate();

  const [enabledControls, setEnabledControls] = useState({
    whereFoundDropDown: true,
    personnelContactedDropdown: true,
    commentsTextBox: true,
    retrurnRerunDate: true,
    mediaType: true,
    logicalVault: true,
    uploadError: true
  });
  const [formData, setFormData] = useState({
    resolution: "",
    personnelContacted: "",
    mediaFound: "",
    date: null,
    comment: "",
    mediaType: "",
    logicalVault: ""
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedValues?.length) {
      handleGetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  useEffect(() => {
    if (!isLoading && temloader) {
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (!resolutionType && !isAuthenticated && formData?.resolution) {
      handleResolutionChange("", false);
    } else if (resolutionType && !isAuthenticated && formData?.resolution) {
      handleResolutionChange(resolutionType, false);
    }
    //eslint-disable-next-line
  }, [isAuthenticated]);

  const getRequestId = (data, formData) => {
    const formattedDateKey = dayjs(formData.date).format(mdyDateFormat);
    const requestIdFromList = requestIdListForScheduledDays?.[formattedDateKey];

    return (
      (requestIdFromList !== undefined
        ? requestIdFromList
        : VALUE_EMPTY_STRING) ||
      data?.originalRequestId ||
      data?.requestId
    );
  };

  const handleGetData = async () => {
    try {
      let item = { ...selectedValues[0] };
      let mediaListTem = [];
      let dropdownList = [];
      setTempLoader(true);
      setIsLoading(true);
      const [res, res2, res3] = await Promise.allSettled([
        getRunRequestStatus({
          main_district_id: currentBranch?.value,
          request_id: item?.requestId || item?.originalRequestId
        }),
        getResponseData(
          JSON.stringify({
            main_district_id: currentBranch?.value,
            display_manual_only_flag: N_CHECKER
          }),
          `${CF_URLS.discrepancyManagement.correctiveactionrequesttypetabledata}`,
          2
        ),
        getResponseData(
          JSON.stringify({
            main_district_id: currentBranch?.value,
            customer_id: item?.customerId,
            program_id: programId
          }),
          `${CF_URLS.discrepancyManagement.personnelinformation}`,
          2
        )
      ]);
      let request_status_id = null,
        run_status_id = null;
      if (res?.value && res?.value[0]) {
        setRequestStatusId(res?.value[0]);
        request_status_id = res?.value[0]?.request_status_id;
        run_status_id = res?.value[0]?.run_status_id;
      }

      let corrective = res2?.value?.data[0]?.find(
        (item) => item?.corrective_action_request_type_id === dropdownListValue
      );

      let personnelDropdown = res3?.value?.data[0].map((item) => {
        return {
          key: item.personnel_id,
          value: item.first_name + ", " + item.last_name
        };
      });
      setPersonnelDropdownTem([...personnelDropdown]);
      let detectedIn = null;
      if (type === t("discrepancyManagement.openMedia")) {
        mediaList.forEach((temp) => {
          let alternateid = temp?.alternateid?.split("_");
          if (
            item?.discrepancyTypeCode?.trim() === alternateid[0] &&
            item?.fileSectionTypeId === alternateid[1]
          ) {
            mediaListTem.push(temp);
          }
        });
        if (item?.discrepancyTypeCode?.trim() === missing) {
          if (
            [numberConstants.one, numberConstants.nine].includes(
              item?.fileSectionTypeId
            )
          ) {
            if (
              item?.requestId > numberConstants.zero &&
              request_status_id !== numberConstants.three
            ) {
              if (
                run_status_id === numberConstants.nine ||
                run_status_id === numberConstants.tweleve
              ) {
                dropdownList = [
                  {
                    key: t("discrepancyManagement.vaultDT"),
                    value:
                      resolutionTypes["Operations_SuggestionTypes_vault-dt"]
                  },
                  {
                    key: t("discrepancyManagement.vaultIND"),
                    value: suggestionTypes?.Operations_SuggestionTypes_VaultInd
                  },
                  {
                    key: t("discrepancyManagement.reRun"),
                    value: suggestionTypes?.Operations_SuggestionTypes_ReRun
                  },
                  {
                    key: t("discrepancyManagement.custLeave"),
                    value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
                  }
                ];
              } else {
                dropdownList = [
                  {
                    key: t("discrepancyManagement.onRun"),
                    value: suggestionTypes?.Operations_SuggestionTypes_OnRun
                  },
                  {
                    key: t("discrepancyManagement.custLeave"),
                    value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
                  }
                ];
              }
            } else {
              dropdownList = [
                {
                  key: t("discrepancyManagement.vaultDT"),
                  value: resolutionTypes["Operations_SuggestionTypes_vault-dt"]
                },
                {
                  key: t("discrepancyManagement.vaultIND"),
                  value: suggestionTypes?.Operations_SuggestionTypes_VaultInd
                },
                {
                  key: t("discrepancyManagement.custLeave"),
                  value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
                }
              ];
            }
          }
          if (item?.fileSectionTypeId === numberConstants.fourTeen) {
            dropdownList = [
              {
                key: t("discrepancyManagement.vaultDT"),
                value: resolutionTypes["Operations_SuggestionTypes_vault-dt"]
              },
              {
                key: t("discrepancyManagement.vaultIND"),
                value: suggestionTypes?.Operations_SuggestionTypes_VaultInd
              },
              {
                key: t("discrepancyManagement.custLeave"),
                value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
              }
            ];
          }
          if (item?.fileSectionTypeId === numberConstants.two) {
            dropdownList = [
              {
                key: t("discrepancyManagement.vaultList"),
                value: suggestionTypes?.Operations_SuggestionTypes_VaultList
              },
              {
                key: t("discrepancyManagement.custLeave"),
                value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
              }
            ];
          }
          if (item?.fileSectionTypeId === numberConstants.sixteen) {
            dropdownList = [
              {
                key: t("discrepancyManagement.keepexist"),
                value: suggestionTypes?.Operations_SuggestionTypes_KeepExist
              },
              {
                key: t("discrepancyManagement.custLeave"),
                value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
              }
            ];
          }
        } else if (
          item?.discrepancyTypeCode?.trim() ===
          t("discrepancyUpdate.unexpected")
        ) {
          if (
            [
              numberConstants.two,
              numberConstants.three,
              numberConstants.sixteen
            ].includes(item?.fileSectionTypeId)
          ) {
            dropdownList = [
              {
                key: t("discrepancyManagement.vaultDT"),
                value: resolutionTypes["Operations_SuggestionTypes_vault-dt"]
              },
              {
                key: t("discrepancyManagement.vaultIND"),
                value: suggestionTypes?.Operations_SuggestionTypes_VaultInd
              }
            ];
          }
        }

        if (corrective?.auto_generate_on_flag === Y_CHECKER || !!item?.carId) {
          dropdownList.push({
            key: t("discrepancyManagement.pendingres"),
            value: suggestionTypes?.Operations_SuggestionTypes_PendingRes
          });
        }
      } else {
        mediaList.forEach((temp) => {
          let alternateid = temp?.alternateid?.split("_");
          if (
            item?.discrepancyTypeCode?.trim() === alternateid[0] &&
            item?.systemFunctionId === alternateid[1]
          ) {
            mediaListTem.push(temp);
          }
        });
        let uploadErroList = null;
        let exchangeStatusId = null;
        const requestBody = JSON.stringify({
          main_district_id: currentBranch?.value,
          customer_id: item?.customerId,
          container_list: item?.containerNumber,
          status_id: numberConstants.one,
          exclude_transports_flag: N_CHECKER
        });
        const requestBody2 = JSON.stringify({
          main_district_id: currentBranch?.value,
          district_id: currentBranch?.value,
          discrepancy_id: item?.discrepancyId
        });
        const response = await getResponseData(
          requestBody,
          `${CF_URLS.containerProcessing.getAllContainersForCustomer}`,
          2
        );
        const response2 = await getResponseData(
          requestBody2,
          `${CF_URLS.discrepancyManagement.loadcontainerdiscrepancydetails}`,
          2
        );
        const response3 = await getResponseData(
          JSON.stringify({
            main_district_id: currentBranch?.value,
            customer_id: item?.customerId,
            run_id: item?.runId,
            district_id: currentBranch?.value
          }),
          `${CF_URLS.discrepancyManagement.containeruploaderror}`,
          2
        );
        if (response3?.data[0]?.length) {
          setUploadErroList(response3?.data[0]);
          uploadErroList = response3?.data[0];
        }
        if (response?.data[0]) {
          exchangeStatusId = response?.data[0][0]?.exchange_status_id;
        }
        if (response2?.data[0]) {
          if (!exchangeStatusId) {
            exchangeStatusId = response2?.data[0][0]?.run_exchange_status_id;
          }
          let status = await getExchangeStatusById(exchangeStatusId);
          setRunStatus(status);
        }
        if (item?.discrepancyTypeCode?.trim() === missing) {
          if (
            [numberConstants.four, numberConstants.seven].includes(
              item?.systemFunctionId
            )
          ) {
            if (
              item.containerNumber.slice(0, 2) === TR_String &&
              item?.hasMedia === Y_CHECKER
            ) {
              if (
                !request_status_id ||
                (request_status_id !== numberConstants.three &&
                  request_status_id !== numberConstants.four)
              ) {
                dropdownList.push({
                  key: t("discrepancyManagement.onRun"),
                  value: suggestionTypes?.Operations_SuggestionTypes_OnRun
                });
              }
              if (
                run_status_id !== numberConstants.nine &&
                request_status_id === numberConstants.four
              ) {
                dropdownList.push(
                  {
                    key: t("discrepancyManagement.custLeave"),
                    value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
                  },
                  {
                    key: t("discrepancyManagement.createReRun"),
                    value: resolutionTypes["Operations_SuggestionTypes_rerun"]
                  }
                );
              } else if (
                (corrective?.auto_generate_on_flag === Y_CHECKER ||
                  item?.carId) &&
                request_status_id !== numberConstants.one &&
                run_status_id !== numberConstants.six &&
                request_status_id !== numberConstants.four &&
                run_status_id !== numberConstants.nine
              ) {
                dropdownList.push({
                  key: t("discrepancyManagement.createReRun"),
                  value: resolutionTypes["Operations_SuggestionTypes_rerun"]
                });
              }
            }
            if (
              (item.containerNumber.slice(0, 2) === TR_String &&
                item?.hasMedia === N_CHECKER) ||
              item.containerNumber.slice(0, 2) !== TR_String
            ) {
              let arr = [
                {
                  key: t("discrepancyManagement.custLeave"),
                  value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
                },
                {
                  key: t("discrepancyManagement.custRerun"),
                  value:
                    resolutionTypes["Operations_SuggestionTypes_cust-rerun"]
                },
                {
                  key: t("discrepancyManagement.vaultDT"),
                  value: resolutionTypes["Operations_SuggestionTypes_vault-dt"]
                },
                {
                  key: t("discrepancyManagement.vaultIND"),
                  value: resolutionTypes["Operations_SuggestionTypes_Vault-Ind"]
                }
              ];
              if (
                !request_status_id ||
                (request_status_id !== numberConstants.three &&
                  request_status_id !== numberConstants.four)
              ) {
                arr.push({
                  key: t("discrepancyManagement.onRun"),
                  value: suggestionTypes?.Operations_SuggestionTypes_OnRun
                });
              }
              dropdownList = [...arr];
            }
            if (
              corrective?.auto_generate_on_flag === Y_CHECKER ||
              !!item?.carId
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.pendingres"),
                value: suggestionTypes?.Operations_SuggestionTypes_PendingRes
              });
            }
          }
          if (item?.systemFunctionId === numberConstants.eleven) {
            if (
              item.containerNumber.slice(0, 2) === TR_String &&
              item?.hasMedia === Y_CHECKER
            ) {
              if (item?.requestId && item?.requestId > 0) {
                if (
                  run_status_id !== numberConstants.nine &&
                  request_status_id === numberConstants.four
                ) {
                  let arr = [
                    {
                      key: t("discrepancyManagement.custLeave"),
                      value:
                        suggestionTypes?.Operations_SuggestionTypes_CustLeave
                    },
                    {
                      key: t("discrepancyManagement.reRun"),
                      value: suggestionTypes?.Operations_SuggestionTypes_ReRun
                    }
                  ];
                  dropdownList = [...arr];
                }
              }
            }
            if (
              (item.containerNumber.slice(0, 2) === TR_String &&
                item?.hasMedia === N_CHECKER) ||
              item.containerNumber.slice(0, 2) !== TR_String
            ) {
              let arr = [
                {
                  key: t("discrepancyManagement.custLeave"),
                  value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
                },
                {
                  key: t("discrepancyManagement.custRerun"),
                  value:
                    resolutionTypes["Operations_SuggestionTypes_cust-rerun"]
                },
                {
                  key: t("discrepancyManagement.vaultDT"),
                  value: resolutionTypes["Operations_SuggestionTypes_vault-dt"]
                },
                {
                  key: t("discrepancyManagement.vaultIND"),
                  value: suggestionTypes?.Operations_SuggestionTypes_VaultInd
                }
              ];
              dropdownList = [...arr];
              if (item?.pendingReturnDate) {
                dropdownList.push({
                  key: t("discrepancyManagement.vaultPDT"),
                  value: suggestionTypes?.Operations_SuggestionTypes_VaultPDt
                });
              }
            }
            if (
              corrective?.auto_generate_on_flag === Y_CHECKER ||
              !!item?.carId
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.pendingres"),
                value: suggestionTypes?.Operations_SuggestionTypes_PendingRes
              });
            }
          }
          if (item?.systemFunctionId === number_67) {
            if (
              item.containerNumber.slice(0, 2) === TR_String &&
              item?.hasMedia === Y_CHECKER
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.custLeave"),
                value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
              });
              dropdownList.push({
                key: t("discrepancyManagement.reRun"),
                value: suggestionTypes?.Operations_SuggestionTypes_ReRun
              });
            }
            if (
              (item.containerNumber.slice(0, 2) === TR_String &&
                item?.hasMedia === N_CHECKER) ||
              item.containerNumber.slice(0, 2) !== TR_String
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.custLeave"),
                value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
              });
              dropdownList.push({
                key: t("discrepancyManagement.custRerun"),
                value: resolutionTypes["Operations_SuggestionTypes_cust-rerun"]
              });
              dropdownList.push({
                key: t("discrepancyManagement.vaultDT"),
                value: suggestionTypes?.Operations_SuggestionTypes_VaultDt
              });
              dropdownList.push({
                key: t("discrepancyManagement.vaultIND"),
                value: suggestionTypes?.Operations_SuggestionTypes_VaultInd
              });
            }
            if (
              corrective?.auto_generate_on_flag === Y_CHECKER &&
              !!item?.carId
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.pendingres"),
                value: suggestionTypes?.Operations_SuggestionTypes_PendingRes
              });
            }
          }
          if (item?.systemFunctionId === numberConstants.thirteen) {
            dropdownList = [
              {
                key: t("discrepancyManagement.custLeave"),
                value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
              },
              {
                key: t("discrepancyManagement.custRerun"),
                value: resolutionTypes["Operations_SuggestionTypes_cust-rerun"]
              }
            ];
            if (item.containerNumber.slice(0, 2) === TR_String) {
              if (uploadErroList?.length) {
                dropdownList.push({
                  key: t("discrepancyManagement.msVaultpd"),
                  value: suggestionTypes?.Operations_SuggestionTypes_MSVaultPD
                });
              }
              if (item?.hasMedia === N_CHECKER) {
                dropdownList.push({
                  key: t("discrepancyManagement.vaultIND"),
                  value: resolutionTypes["Operations_SuggestionTypes_Vault-Ind"]
                });
              }
            }
            if (item.containerNumber.slice(0, 2) !== TR_String) {
              dropdownList.push({
                key: t("discrepancyManagement.vaultDT"),
                value: resolutionTypes["Operations_SuggestionTypes_vault-dt"]
              });
              dropdownList.push({
                key: t("discrepancyManagement.vaultIND"),
                value: resolutionTypes["Operations_SuggestionTypes_Vault-Ind"]
              });
              if (uploadErroList?.length) {
                dropdownList.push({
                  key: t("discrepancyManagement.misScanVaultDt"),
                  value: suggestionTypes?.Operations_SuggestionTypes_MSVaultDt
                });
                dropdownList.push({
                  key: t("discrepancyManagement.msVaultid"),
                  value: resolutionTypes["Operations_SuggestionTypes_msvaultid"]
                });
              }
              if (item?.pending_return_date) {
                dropdownList.push({
                  key: t("discrepancyManagement.vaultPDT"),
                  value: resolutionTypes["Operations_SuggestionTypes_VaultPDt"]
                });
              }
            }
            if (
              corrective?.auto_generate_on_flag === Y_CHECKER &&
              !!item?.carId
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.pendingres"),
                value: suggestionTypes?.Operations_SuggestionTypes_PendingRes
              });
            }
          }

          if (item?.systemFunctionId === number_63) {
            if (
              (item.containerNumber.slice(0, 2) === TR_String &&
                item?.hasMedia === N_CHECKER) ||
              item.containerNumber.slice(0, 2) !== TR_String
            ) {
              dropdownList.push(
                ...[
                  {
                    key: t("discrepancyManagement.custLeave"),
                    value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
                  },
                  {
                    key: t("discrepancyManagement.vaultDT"),
                    value:
                      resolutionTypes["Operations_SuggestionTypes_vault-dt"]
                  },
                  {
                    key: t("discrepancyManagement.vaultIND"),
                    value: suggestionTypes?.Operations_SuggestionTypes_VaultInd
                  }
                ]
              );
            }
            if (
              corrective?.auto_generate_on_flag === Y_CHECKER &&
              !!item?.carId
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.pendingres"),
                value: suggestionTypes?.Operations_SuggestionTypes_PendingRes
              });
            }
          }
          if (item?.systemFunctionId === number_73) {
            if (
              (item.containerNumber.slice(0, 2) === TR_String &&
                item?.hasMedia === N_CHECKER) ||
              item.containerNumber.slice(0, 2) !== TR_String
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.delete"),
                value: suggestionTypes?.Operations_SuggestionTypes_Delete
              });
              dropdownList.push({
                key: t("discrepancyManagement.custLeave"),
                value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
              });
              dropdownList.push({
                key: t("discrepancyManagement.vaultIND"),
                value: resolutionTypes["Operations_SuggestionTypes_Vault-Ind"]
              });
              dropdownList.push({
                key: t("discrepancyManagement.keepexist"),
                value: suggestionTypes?.Operations_SuggestionTypes_KeepExist
              });
            }
            if (
              corrective?.auto_generate_on_flag === Y_CHECKER &&
              !!item?.carId
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.pendingres"),
                value: suggestionTypes?.Operations_SuggestionTypes_PendingRes
              });
            }
          }
        }
        if (item?.discrepancyTypeCode?.trim() === unexpected) {
          if (item?.systemFunctionId === numberConstants.thirteen) {
            if (item.containerNumber.slice(0, 2) === TR_String) {
              dropdownList.push({
                key: t("discrepancyManagement.reRun"),
                value: suggestionTypes?.Operations_SuggestionTypes_ReRun
              });
              if (item?.hasMedia === Y_CHECKER) {
                dropdownList.push({
                  key: t("discrepancyManagement.custLeave"),
                  value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
                });
              } else {
                dropdownList.push({
                  key: t("discrepancyManagement.vaultpd"),
                  value: suggestionTypes?.Operations_SuggestionTypes_VaultPD
                });
                if (uploadErroList?.length) {
                  dropdownList.push({
                    key: t("discrepancyManagement.msVaultpd"),
                    value: suggestionTypes?.Operations_SuggestionTypes_MSVaultPD
                  });
                }
              }
            }
            if (item.containerNumber.slice(0, 2) !== TR_String) {
              dropdownList.push({
                key: t("discrepancyManagement.vaultIND"),
                value: resolutionTypes["Operations_SuggestionTypes_Vault-Ind"]
              });
              dropdownList.push({
                key: t("discrepancyManagement.vaultDT"),
                value: resolutionTypes["Operations_SuggestionTypes_vault-dt"]
              });
              dropdownList.push({
                key: t("discrepancyManagement.reRun"),
                value: suggestionTypes?.Operations_SuggestionTypes_ReRun
              });
              if (uploadErroList?.length) {
                dropdownList.push({
                  key: t("discrepancyManagement.msVaultdt"),
                  value: suggestionTypes?.Operations_SuggestionTypes_MSVaultDt
                });
                dropdownList.push({
                  key: t("discrepancyManagement.msVaultid"),
                  value: suggestionTypes?.Operations_SuggestionTypes_MSVaultId
                });
              }
              if (item?.pendingReturnDate) {
                dropdownList.push({
                  key: t("discrepancyManagement.vaultPDT"),
                  value: suggestionTypes?.Operations_SuggestionTypes_VaultPDt
                });
              }
            }
            if (
              corrective?.auto_generate_on_flag === Y_CHECKER &&
              !!item?.carId
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.pendingres"),
                value: suggestionTypes?.Operations_SuggestionTypes_PendingRes
              });
            }
          }
          if (item?.systemFunctionId === numberConstants.fiveTeen) {
            if (
              (item.containerNumber.slice(0, 2) === TR_String &&
                item?.hasMedia === N_CHECKER) ||
              item.containerNumber.slice(0, 2) !== TR_String
            ) {
              if (exchangeStatusId === numberConstants.fiveTeen) {
                dropdownList.push({
                  key: t("discrepancyManagement.keepdelete"),
                  value: suggestionTypes?.Operations_SuggestionTypes_KeepDelete
                });
              }
              if (item.containerNumber.slice(0, 2) === TR_String) {
                dropdownList.push({
                  key: t("discrepancyManagement.vaultpd"),
                  value: suggestionTypes?.Operations_SuggestionTypes_VaultPD
                });
              } else {
                dropdownList.push({
                  key: t("discrepancyManagement.vaultIND"),
                  value: resolutionTypes["Operations_SuggestionTypes_Vault-Ind"]
                });
                dropdownList.push({
                  key: t("discrepancyManagement.vaultDT"),
                  value: suggestionTypes?.Operations_SuggestionTypes_VaultDt
                });
              }
            }
            if (
              corrective?.auto_generate_on_flag === Y_CHECKER &&
              !!item?.carId
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.pendingres"),
                value: suggestionTypes?.Operations_SuggestionTypes_PendingRes
              });
            }
          }
          if (item?.systemFunctionId === numberConstants.seventeethree) {
            dropdownList = [
              {
                key: t("discrepancyManagement.vaultIND"),
                value: suggestionTypes?.Operations_SuggestionTypes_VaultInd
              }
            ];
            if (item.containerNumber.slice(0, 2) !== TR_String) {
              if (exchangeStatusId === numberConstants.fiveTeen) {
                dropdownList.push({
                  key: t("discrepancyManagement.keepdelete"),
                  value: suggestionTypes?.Operations_SuggestionTypes_KeepDelete
                });
              }
              dropdownList.push({
                key: t("discrepancyManagement.reRun"),
                value: suggestionTypes?.Operations_SuggestionTypes_ReRun
              });
              dropdownList.push({
                key: t("discrepancyManagement.vaultDT"),
                value: resolutionTypes["Operations_SuggestionTypes_vault-dt"]
              });
            }
            if (
              item.containerNumber.slice(0, 2) === TR_String &&
              item?.hasMedia === N_CHECKER
            ) {
              if (exchangeStatusId === numberConstants.fiveTeen) {
                dropdownList.push({
                  key: t("discrepancyManagement.keepdelete"),
                  value: suggestionTypes?.Operations_SuggestionTypes_KeepDelete
                });
              }
            }
            if (
              corrective?.auto_generate_on_flag === Y_CHECKER &&
              !!item?.carId
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.pendingres"),
                value: suggestionTypes?.Operations_SuggestionTypes_PendingRes
              });
            }
          }
        }
        if (item?.discrepancyTypeCode?.trim() === noOrInvalidReturnDate) {
          if (item?.systemFunctionId === numberConstants?.thirteen) {
            if (
              (item.containerNumber.slice(0, 2) === TR_String &&
                item?.hasMedia === N_CHECKER) ||
              item.containerNumber.slice(0, 2) !== TR_String
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.vaultIND"),
                value: resolutionTypes["Operations_SuggestionTypes_vaultind"]
              });
              dropdownList.push({
                key: t("discrepancyManagement.delete"),
                value: suggestionTypes?.Operations_SuggestionTypes_Delete
              });
              dropdownList.push({
                key: t("discrepancyManagement.vaultDT"),
                value: resolutionTypes["Operations_SuggestionTypes_vault-dt"]
              });
            }
            if (
              corrective?.auto_generate_on_flag === Y_CHECKER &&
              !!item?.carId
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.pendingres"),
                value: suggestionTypes?.Operations_SuggestionTypes_PendingRes
              });
            }
          }
        }
        if (item?.discrepancyTypeCode?.trim() === incompleteStop) {
          if (item?.systemFunctionId === numberConstants.thirteen) {
            dropdownList.push(
              ...[
                {
                  key: t("discrepancyManagement.reRun"),
                  value: suggestionTypes?.Operations_SuggestionTypes_ReRun
                },
                {
                  key: t("discrepancyManagement.spSTD"),
                  value: suggestionTypes?.Operations_SuggestionTypes_SpStd
                },
                {
                  key: t("discrepancyManagement.vaultIND"),
                  value: suggestionTypes?.Operations_SuggestionTypes_VaultInd
                }
              ]
            );
            if (item.containerNumber.slice(0, 2) !== TR_String) {
              dropdownList.push({
                key: t("discrepancyManagement.vaultDT"),
                value: resolutionTypes["Operations_SuggestionTypes_vault-dt"]
              });
              dropdownList.push({
                key: t("discrepancyManagement.custLeave"),
                value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
              });
            }
            if (
              item.containerNumber.slice(0, 2) === TR_String &&
              item?.hasMedia === N_CHECKER
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.custLeave"),
                value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
              });
            }
            if (
              item.containerNumber.slice(0, 2) === TR_String &&
              item?.hasMedia === Y_CHECKER
            ) {
              dropdownList.push({
                key: t("discrepancyManagement.custLeave"),
                value: suggestionTypes?.Operations_SuggestionTypes_CustLeave
              });
              dropdownList.push({
                key: t("discrepancyManagement.vaultOMDT"),
                value: resolutionTypes["Operations_SuggestionTypes_vault-omdt"]
              });
            }
          }
        }
      }
      if (type === t("discrepancyManagement.openMedia")) {
        if (item?.fileSectionTypeId === numberConstants.one) {
          detectedIn = t("discrepancyManagementDetail.picking");
        } else if (item?.fileSectionTypeId === numberConstants.two) {
          detectedIn = t("discrepancyManagementDetail.distribution");
        } else if (item?.fileSectionTypeId === numberConstants.four) {
          detectedIn = t("discrepancyManagementDetail.inventory");
        }
        if (!detectedIn) {
          detectedIn = fileSectionTypeIds[item?.fileSectionTypeId];
        }

        detectedIn +=
          " " + dayjs.utc(item?.detectedDatetime).format(dayMonthYearFormat);
        if (item?.fileSectionTypeId === numberConstants.sixteen) {
          detectedIn = t("discrepancyManagementDetail.audit") + item?.omAuditId;
        }
        if (item?.fileSectionTypeId === numberConstants.fourTeen) {
          detectedIn = t("discrepancyManagement.pickingDestruction");
        }
      } else if (
        item.fileSectionTypeId === numberConstants.seventeethree ||
        item.systemFunctionId === numberConstants.seventeethree
      ) {
        let id = item?.omAuditId ? item?.omAuditId : item?.containerAuditId;
        detectedIn = t("discrepancyManagementDetail.inventoryAudit") + id;
      } else {
        detectedIn =
          detectedInType[systemFunctionTitle + item?.systemFunctionId];
      }
      let data = [];
      mediaList.forEach((listItem) => {
        let alternateid = listItem.alternateid.split("_");
        if (
          item?.discrepancyTypeCode?.trim() === alternateid[0] &&
          item?.originalSystemFunctionId === alternateid[1]
        ) {
          data.push(listItem);
        }
      });
      setData({ ...item, detectedIn });
      setTempLoader(false);
      setIsLoading(false);
      let sorting = dropdownList?.sort((a, b) =>
        a.value > b.value ? 1 : b.value > a.value ? -1 : 0
      );
      setDropDownList([...sorting]);
      setMediaDropdown([...data]);
      if (resolutionType && isAuthenticated) {
        handleResolutionChange(resolutionType, false, personnelDropdown);
      }
    } catch (err) {
      setIsLoading(false);
      setTempLoader(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const handleSave = async () => {
    let selectedList = [];
    if (!formData?.resolution?.trim()) {
      setThrowErrorMessage(t("discrepancyManagementDetail.resolutionRequired"));
      return;
    }
    if (
      !enabledControls?.whereFoundDropDown &&
      mediaDropdown?.length !== 0 &&
      !formData?.mediaFound?.trim() &&
      formData?.resolution?.trim() !== t("discrepancyManagement.vaultList")
    ) {
      setThrowErrorMessage(t("discrepancyManagementDetail.mediaRequired"));
      return;
    }
    if (!enabledControls?.commentsTextBox && !formData?.comment?.trim()) {
      setThrowErrorMessage(t("discrepancyManagementDetail.commentRequired"));
      return;
    }
    if (
      !enabledControls?.personnelContactedDropdown &&
      !formData?.personnelContacted?.trim()
    ) {
      setThrowErrorMessage(
        t("discrepancyManagementDetail.personnelContactedRequired")
      );
      return;
    }
    if (
      !enabledControls?.retrurnRerunDate &&
      !formData?.date &&
      [
        t("discrepancyManagement.vaultDT"),
        t("discrepancyManagement.reRun"),
        t("discrepancyManagement.custRerun"),
        t("discrepancyManagement.vaultPDT"),
        t("discrepancyManagement.vaultOMDT")
      ].includes(formData.resolution)
    ) {
      setThrowErrorMessage(t("discrepancyManagementDetail.reRunDateError"));
      return;
    }
    if (
      !enabledControls?.logicalVault &&
      logicalVaultList?.length !== 0 &&
      !formData?.logicalVault?.trim()
    ) {
      setThrowErrorMessage(
        t("discrepancyManagementDetail.logicalVaultRequired")
      );
      return;
    }
    if (
      !enabledControls?.mediaType &&
      mediaList?.length !== 0 &&
      !formData?.mediaType?.trim()
    ) {
      setThrowErrorMessage(t("discrepancyManagementDetail.mediaTypeRequired"));
      return;
    }
    if (
      !enabledControls?.uploadError &&
      uploadErroList?.length !== 0 &&
      !formData?.unknownContainerId?.trim()
    ) {
      setThrowErrorMessage(t("discrepancyManagementDetail.uploadError"));
      return;
    }
    for (let i = 0; i < selectedValues.length; i += 100) {
      selectedList.push(selectedValues.slice(i, i + 100));
    }
    for (const selected of selectedList) {
      try {
        let xml = "<Discrepancies>";
        selected.forEach((item) => {
          let id = item?.openMediaDiscrepancyId
            ? item?.openMediaDiscrepancyId
            : item?.discrepancyId;
          xml += `<Discrepancy DiscrepancyID="${id}" LastModificationDate="${dayjs
            .utc(item.lastModDate)
            .format(formatWithSeconds)}" ContextId="${
            item?.discrepancyTypeContext
          }"/>`;
        });
        xml += "</Discrepancies>";
        let res = {
          main_district_id: currentBranch?.value,
          operational_mode_id: operationalModeId,
          suggestion_type_code: formData?.resolution,
          contacted_personnel_id: formData?.personnelContacted,
          logged_in_personnel_id:
            formData?.personnelContacted === byPass?.key
              ? ""
              : formData?.personnelContacted ?? "",
          discrepancy_found_id: formData?.mediaFound,
          media_type_id: formData?.mediaType || "",
          selected_return_date: formData.date
            ? dayjs(formData.date).format(mdyDateFormat)
            : "",
          logical_vault_id: formData?.logicalVault || "",
          new_request_id: getRequestId(data, formData),
          logged_in_employee_id: auth?.user?.employee_id,
          courtesy_contact_dropdown_list_id: "",
          resolution_comment: "",
          comment_text: formData?.comment,
          auth_bypass_reason:
            t("discrepancyManagement.pendingres") !== formData?.resolution
              ? discrepancyAuthFormValues?.bypassMessage
              : "",
          om_discrepancy_id_last_mod_date_xml: xml
        };
        setIsLoading(true);
        let result = null;
        if (type === t("discrepancyManagement.openMedia")) {
          result = await getResponseData(
            JSON.stringify({
              ...res
            }),
            `${CF_URLS.discrepancyManagement.openmediadiscrepancy}`,
            2
          );
        } else {
          let request = {
            main_district_id: currentBranch?.value,
            operational_mode_id: operationalModeId,
            discrepancy_id_last_mod_date_xml: xml,
            suggestion_type_code: formData?.resolution,
            contacted_personnel_id: formData?.personnelContacted,
            discrepancy_found_id: formData?.mediaFound,
            selected_return_date: formData.date
              ? dayjs(formData.date).format(mdyDateFormat)
              : "",
            new_request_id: getRequestId(data, formData),
            logged_in_employee_id: auth?.user?.employee_id,
            logged_in_personnel_id:
              formData?.personnelContacted === byPass?.key
                ? ""
                : formData?.personnelContacted ?? "",
            unknown_container_id: data?.unknownContainerId ?? "",
            resolution_comment: data?.resolutionComment ?? "",
            comment_text: formData?.comment,
            auth_bypass_reason:
              t("discrepancyManagement.pendingres") !== formData?.resolution
                ? discrepancyAuthFormValues?.bypassMessage
                : ""
          };
          result = await getResponseData(
            JSON.stringify({
              ...request
            }),
            `${CF_URLS.discrepancyManagement.containerdiscupdate}`,
            2
          );
        }
        if (result?.data[0]?.length) {
          let resolutionMessage = null;

          const dataPromises = result?.data[0].map(async (item) => {
            const errDesc = await getMessageFromCode(
              item?.additional_info_message_id
            );

            if (errDesc[0]?.descr) {
              resolutionMessage = errDesc[0]?.descr;
            }

            return {
              media_number: item?.media_number,
              resolved_flag: item?.resolved_flag === Y_CHECKER ? YES : NO,
              additional_info: item?.additional_info_message_id
                ? resolutionMessage?.replace("|", item?.additional_info)
                : t("discrepancyUpdate.errorMessage")
            };
          });

          Promise.all(dataPromises).then((resolvedData) => {
            setAdditionalResolution(resolvedData);
          });
        } else if (
          result?.data[0]?.length === 0 &&
          result?.data[1]?.length === 0
        ) {
          handlePersonnelAuthChange();
          dispatch(
            setWarning(
              t("discrepancyManagement.successfully", {
                date: dayjs().format(dayMonthYearTimeFormat)
              })
            )
          );
          navigate(`${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`, {
            state: {
              from: location?.pathname,
              detectedIn: searchFormValues.detectedIn
            }
          });
        } else if (result?.data[1]?.length) {
          if (Number(result?.data[1][0]?.error)) {
            setIsLoading(false);
            setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
          } else {
            handlePersonnelAuthChange();
            dispatch(
              setWarning(
                t("discrepancyManagement.successfully", {
                  date: dayjs().format(dayMonthYearTimeFormat)
                })
              )
            );
            navigate(
              `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`,
              {
                state: {
                  from: location?.pathname,
                  detectedIn: searchFormValues.detectedIn
                }
              }
            );
          }
        }
        setTempLoader(false);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      }
    }
  };
  const handlePersonnelAuthChange = () => {
    const updatedValues = {
      ...discrepancyAuthFormValues,
      personnel: VALUE_EMPTY_STRING,
      authNumber: VALUE_EMPTY_STRING,
      challengeQuestion: VALUE_EMPTY_STRING,
      challengeResponse: VALUE_EMPTY_STRING,
      personnelAuthValue: authSection?.personnelAuth
    };
    dispatch(setAuthTypeChange(authSection?.personnelAuth));
    dispatch(updateDiscrepancyAuthFormValues(updatedValues));
    dispatch(updatePreferencesAuthentication(false));
    dispatch(clearError());
    dispatch(setShowAuthSection(false));
  };

  const getToDay = (toDate) => {
    const today = dayjs();
    const adjustedDate = dayjs(toDate).isBefore(today)
      ? today.add(2, "year")
      : dayjs(toDate);

    return adjustedDate
      .locale(localeByBranch)
      .format(dateFormat.universalFormat);
  };

  const handleGreenDate = async () => {
    try {
      let date = null;
      if (data.pendingReturnDate === returnDate || !data.pendingReturnDate) {
        date = dayjs();
      } else {
        date = dayjs(data.pendingReturnDate, dayMonthYearFormat);
      }
      setIsLoading(true);
      const requestBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        customer_id: data?.customerId ?? selectedValues[0]?.customerId,
        exchange_location_type_code: EXCHANGE_LOCATION_TYPE_SL,
        service_frequency_flag: Y_CHECKER
      });

      const response = await getResponseData(
        requestBody,
        `${CF_URLS.dashboard.todo.returncustomerlocationinfo}`,
        4
      );
      if (response?.data[0]) {
        const requestBody2 = JSON.stringify({
          main_district_id: getAuthenticatedUserBranch(),
          customer_id: data?.customerId ?? selectedValues[0]?.customerId,
          from_date: date
            .locale(localeByBranch)
            .format(dateFormat.universalFormat),
          to_date: getToDay(response?.data[0][0]?.generated_date_end),
          reschedule_flag: N_CHECKER,
          addon_cutoff_flag: N_CHECKER
        });
        const response2 = await getResponseData(
          requestBody2,
          `${CF_URLS.requestDetail.getScheduledServiceDates}`,
          2
        );
        if (response2?.data) {
          let arr = [];
          if (
            response?.data[0][0]?.service_frequency_code
              ?.trim()
              ?.toLowerCase() !== ON_CALL?.toLowerCase()
          ) {
            response2?.data[0]?.forEach((item) => {
              arr.push(
                dayjs
                  .utc(item?.service_date)
                  .locale(localeByBranch)
                  .format(mdyDateFormat)
              );
            });
          }

          const mappedRequestObject = response2?.data[0]?.reduce((acc, obj) => {
            acc[
              dayjs
                .utc(obj.service_date)
                .locale(localeByBranch)
                .format(mdyDateFormat)
            ] = obj.request_id;
            return acc;
          }, {});
          setRequestIdListForScheduledDays({ ...mappedRequestObject });
          setSlotDateList([...arr]);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const handleResolutionChange = async (
    value,
    isHandle = true,
    dataList = []
  ) => {
    setSlotDateList([]);
    if (isHandle && isAuthenticated) {
      handlePersonnelAuthChange();
      dispatch(setResolutionType(value));
    }
    if (value === resolutionType) {
      dispatch(setResolutionType(""));
    }
    setThrowErrorMessage(null);
    let item = { ...selectedValues[0] };
    let data = [],
      disable = {
        whereFoundDropDown: true,
        personnelContactedDropdown: true,
        commentsTextBox: true,
        retrurnRerunDate: true,
        mediaType: true,
        logicalVault: true,
        uploadError: true
      },
      isAuthCheck = false,
      personnelContacted = "";
    let fileId = "";
    if (type === t("discrepancyManagement.openMedia")) {
      fileId = item?.fileSectionTypeId;
    } else {
      fileId = item?.systemFunctionId;
    }
    mediaList.forEach((listItem) => {
      let alternateid = listItem.alternateid.split("_");
      if (
        item?.discrepancyTypeCode?.trim() === alternateid[0] &&
        fileId === alternateid[1]
      ) {
        data.push(listItem);
      }
    });
    if (value === t("discrepancyManagement.pendingres")) {
      isAuthCheck = true;
      disable = {
        ...disable,
        whereFoundDropDown: true,
        personnelContactedDropdown: false,
        commentsTextBox: false,
        retrurnRerunDate: true
      };
    } else if (value === t("discrepancyManagement.onRun")) {
      disable = {
        ...disable,
        whereFoundDropDown: data?.length !== 0 ? false : true,
        personnelContactedDropdown: true,
        commentsTextBox: true,
        retrurnRerunDate: true
      };
    } else if (value === t("discrepancyManagement.custLeave")) {
      const {
        discrepancyTypeCode,
        fileSectionTypeId,
        mediaTypeId,
        isTransprot
      } = item;
      if (
        !(
          discrepancyTypeCode?.trim() === missing &&
          fileSectionTypeId === number_73 &&
          mediaTypeId.slice(0, 2) === TR_String &&
          isTransprot === N_CHECKER
        )
      ) {
        if (!isAuthenticated) {
          dispatch(setResolutionType(value));
          dispatch(setShowAuthSection(true));
          return;
        } else {
          isAuthCheck = true;
        }
      }
      disable = {
        ...disable,
        whereFoundDropDown: true,
        personnelContactedDropdown: false,
        commentsTextBox: true,
        retrurnRerunDate: true
      };
    } else if (value === t("discrepancyManagement.vaultDT")) {
      await handleGreenDate();
      let personnelContactedDropdown = true;
      if (!data?.length) {
        mediaList.forEach((listItem) => {
          let alternateid = listItem.alternateid.split("_");
          if (
            item?.discrepancyTypeCode?.trim() === alternateid[0] &&
            item?.originalSystemFunctionId === alternateid[1]
          ) {
            data.push(listItem);
          }
        });
      }
      const { discrepancyTypeCode, fileSectionTypeId, systemFunctionId } = item;
      let id = fileSectionTypeId ?? systemFunctionId;
      if (
        (discrepancyTypeCode?.trim() === missing &&
          id === numberConstants.one &&
          requestStatusId?.request_status_id === numberConstants.four) ||
        (discrepancyTypeCode?.trim() === missing &&
          [
            numberConstants.fourTeen,
            numberConstants.four,
            numberConstants.seven,
            numberConstants.eleven,
            number_67,
            numberConstants.thirteen,
            number_63
          ].includes(id)) ||
        (discrepancyTypeCode?.trim() === unexpected &&
          [
            numberConstants.two,
            numberConstants.sixteen,
            numberConstants.thirteen,
            numberConstants.fiveTeen,
            number_73
          ].includes(id))
      ) {
        if (!isAuthenticated) {
          dispatch(setResolutionType(value));
          dispatch(setShowAuthSection(true));
          return;
        } else {
          isAuthCheck = true;
          personnelContactedDropdown = false;
        }
      }
      disable = {
        ...disable,
        whereFoundDropDown: data?.length !== 0 ? false : true,
        personnelContactedDropdown: personnelContactedDropdown,
        commentsTextBox: true,
        retrurnRerunDate: false
      };
    } else if (value === t("discrepancyManagement.vaultIND")) {
      let whereFoundDropDown = true,
        personnelContactedDropdown = true;
      if (!data?.length) {
        mediaList.forEach((listItem) => {
          let alternateid = listItem.alternateid.split("_");
          if (
            item?.discrepancyTypeCode?.trim() === alternateid[0] &&
            item?.originalSystemFunctionId === alternateid[1]
          ) {
            data.push(listItem);
          }
        });
      }
      let id = item?.systemFunctionId ?? item?.fileSectionTypeId;
      if (
        item?.discrepancyTypeCode?.trim() === missing &&
        item?.systemFunctionId !== number_73
      ) {
        whereFoundDropDown = data?.length !== 0 ? false : true;
      }
      if (
        (item?.discrepancyTypeCode?.trim() === missing &&
          id === numberConstants?.one &&
          item?.requestId === numberConstants.four) ||
        (item?.discrepancyTypeCode?.trim() === missing &&
          [
            numberConstants.fourTeen,
            numberConstants.four,
            numberConstants.seven,
            numberConstants.eleven,
            number_67,
            numberConstants.thirteen,
            number_63
          ].includes(id)) ||
        (item?.discrepancyTypeCode?.trim() === unexpected &&
          [
            numberConstants.two,
            numberConstants.sixteen,
            numberConstants.thirteen,
            numberConstants.fiveTeen,
            number_73
          ].includes(id))
      ) {
        if (!isAuthenticated) {
          dispatch(setResolutionType(value));
          dispatch(setShowAuthSection(true));
          return;
        } else {
          isAuthCheck = true;
        }
      }
      if (
        item?.discrepancyTypeCode?.trim() === unexpected &&
        id === numberConstants?.thirteen
      ) {
        personnelContactedDropdown = false;
      }
      disable = {
        ...disable,
        whereFoundDropDown: whereFoundDropDown,
        personnelContactedDropdown: personnelContactedDropdown,
        commentsTextBox: true,
        retrurnRerunDate: true
      };
    } else if (value === t("discrepancyManagement.vaultpd")) {
      disable = {
        ...disable,
        whereFoundDropDown: true,
        personnelContactedDropdown: false,
        commentsTextBox: true,
        retrurnRerunDate: true
      };
      if (!isAuthenticated) {
        dispatch(setResolutionType(value));
        dispatch(setShowAuthSection(true));
        return;
      } else {
        isAuthCheck = true;
      }
    } else if (value === t("discrepancyManagement.custRerun")) {
      disable = {
        ...disable,
        whereFoundDropDown: true,
        personnelContactedDropdown: false,
        commentsTextBox: true,
        retrurnRerunDate: false
      };
      if (!isAuthenticated) {
        dispatch(setResolutionType(value));
        dispatch(setShowAuthSection(true));
        return;
      } else {
        isAuthCheck = true;
      }
    } else if (value === t("discrepancyManagement.reRun")) {
      let whereFoundDropDown = true,
        personnel = true;
      if (item?.discrepancyType?.trim() === missing) {
        whereFoundDropDown = false;
        if (!data?.length) {
          mediaList.forEach((listItem) => {
            let alternateid = listItem.alternateid.split("_");
            if (
              item?.discrepancyTypeCode?.trim() === alternateid[0] &&
              item?.originalSystemFunctionId === alternateid[1]
            ) {
              data.push(listItem);
            }
          });
        }
        if (!isAuthenticated) {
          dispatch(setResolutionType(value));
          dispatch(setShowAuthSection(true));
          return;
        } else {
          isAuthCheck = true;
        }
      }
      let id = item?.fileSectionTypeId ?? item?.systemFunctionId;
      if (
        item?.discrepancyType?.trim() !== incompleteStop &&
        item?.discrepancyType?.trim() !== unexpected &&
        id === numberConstants?.thirteen
      ) {
        if (!isAuthenticated) {
          dispatch(setResolutionType(value));
          dispatch(setShowAuthSection(true));
          return;
        } else {
          isAuthCheck = true;
        }
      }
      if (
        item?.discrepancyType?.trim() === unexpected &&
        id === numberConstants?.thirteen
      ) {
        personnel = false;
        isAuthCheck = true;
      }
      disable = {
        ...disable,
        whereFoundDropDown: whereFoundDropDown,
        personnelContactedDropdown: personnel,
        commentsTextBox: true,
        retrurnRerunDate: false
      };
    } else if (value === t("discrepancyManagement.vaultList")) {
      let whereFoundDropDown = await handleMediaData();
      if (!data?.length) {
        mediaList.forEach((listItem) => {
          let alternateid = listItem.alternateid.split("_");
          if (
            item?.discrepancyTypeCode?.trim() === alternateid[0] &&
            item?.originalSystemFunctionId === alternateid[1]
          ) {
            data.push(listItem);
          }
        });
      }
      disable = {
        ...disable,
        whereFoundDropDown: data?.length !== 0 ? whereFoundDropDown : true,
        personnelContactedDropdown: true,
        commentsTextBox: true,
        retrurnRerunDate: true,
        mediaType: false,
        logicalVault: false
      };
    } else if (
      value === t("discrepancyManagement.msVaultdt") ||
      value === t("discrepancyManagement.msVaultid")
    ) {
      await handleGreenDate();
      if (selectedValues?.length > 1) {
        disable = {
          ...disable,
          whereFoundDropDown: true,
          personnelContactedDropdown: true,
          commentsTextBox: true,
          retrurnRerunDate: true
        };
      } else {
        disable = {
          ...disable,
          whereFoundDropDown: true,
          personnelContactedDropdown: false,
          commentsTextBox: true,
          retrurnRerunDate: true,
          uploadError: false
        };
        isAuthCheck = true;
      }
    } else if (
      value === t("discrepancyManagement.vaultpd") ||
      value === t("discrepancyManagement.delete")
    ) {
      if (!isAuthenticated) {
        dispatch(setResolutionType(value));
        dispatch(setShowAuthSection(true));
        return;
      } else {
        isAuthCheck = true;
      }
    } else if (value === t("discrepancyManagement.spSTD")) {
      if (!isAuthenticated) {
        dispatch(setResolutionType(value));
        dispatch(setShowAuthSection(true));
        return;
      } else {
        isAuthCheck = true;
      }
      disable = {
        ...disable,
        whereFoundDropDown: true,
        personnelContactedDropdown: true,
        commentsTextBox: true,
        retrurnRerunDate: false
      };
    } else if (value === t("discrepancyManagement.vaultOMDT")) {
      if (!isAuthenticated) {
        dispatch(setResolutionType(value));
        dispatch(setShowAuthSection(true));
        return;
      } else {
        isAuthCheck = true;
      }
      disable = {
        ...disable,
        whereFoundDropDown: true,
        personnelContactedDropdown: true,
        commentsTextBox: true,
        retrurnRerunDate: false
      };
    } else if (value === t("discrepancyManagement.keepexist")) {
      if (!data?.length) {
        mediaList.forEach((listItem) => {
          let alternateid = listItem.alternateid.split("_");
          if (
            item?.discrepancyTypeCode?.trim() === alternateid[0] &&
            item?.originalSystemFunctionId === alternateid[1]
          ) {
            data.push(listItem);
          }
        });
      }
      if (type === t("discrepancyManagement.openMedia")) {
        disable = {
          ...disable,
          whereFoundDropDown: data?.length !== 0 ? false : true,
          personnelContactedDropdown: true,
          commentsTextBox: true,
          retrurnRerunDate: true
        };
      }
    } else if (value === t("discrepancyManagement.vaultPDT")) {
      const { discrepancyType } = item;
      let returnDataCheck = true;
      let whereFoundDropDown = true;
      if (
        discrepancyType?.trim() === unexpected &&
        [numberConstants.eleven, numberConstants.thirteen].includes(
          item?.systemFunctionId
        )
      ) {
        whereFoundDropDown = false;
      }
      if (returnDate !== item?.pendingReturnDate) {
        await handleGreenDate(item?.customer_id);
        returnDataCheck = false;
      }
      if (!data?.length) {
        mediaList.forEach((listItem) => {
          let alternateid = listItem.alternateid.split("_");
          if (
            item?.discrepancyTypeCode?.trim() === alternateid[0] &&
            item?.originalSystemFunctionId === alternateid[1]
          ) {
            data.push(listItem);
          }
        });
      }
      disable = {
        ...disable,
        whereFoundDropDown: data?.length !== 0 ? whereFoundDropDown : true,
        personnelContactedDropdown: true,
        commentsTextBox: true,
        retrurnRerunDate: returnDataCheck
      };
    }
    if (isAuthCheck) {
      if (isAuthenticated && discrepancyAuthFormValues?.bypassMessage) {
        setPersonnelDropdown([byPass]);
        disable = {
          ...disable,
          personnelContactedDropdown: true
        };
        personnelContacted = byPass?.key;
      } else {
        let dataSet = [];
        if (personnelDropdownTem?.length) {
          dataSet = [...personnelDropdownTem];
        } else {
          dataSet = [...dataList];
        }
        if (discrepancyAuthFormValues?.personnel && isAuthenticated) {
          dataSet = dataSet.filter(
            (item) => item?.key === discrepancyAuthFormValues?.personnel
          );
          if (dataSet?.length === Number(numberConstants.one)) {
            personnelContacted = dataSet[0]?.key;
          }
        }
        setPersonnelDropdown([...dataSet]);
        disable = {
          ...disable,
          personnelContactedDropdown: false
        };
      }
    }
    setFormData({
      personnelContacted: personnelContacted,
      mediaFound: "",
      date: null,
      comment: "",
      resolution: value
    });
    setEnabledControls({
      ...disable
    });
    setMediaDropdown([...data]);
  };
  const handlePrintAction = () => {
    let temObj = {
      suggestion_type_code: formData?.resolution ?? "",
      contacted_personnel_id: formData?.personnelContacted ?? "",
      discrepancy_found_id: formData?.mediaFound ?? "",
      media_type_id: data?.mediaTypeId ?? "",
      selected_return_date: formData.date
        ? dayjs(formData.date).format(mdyDateFormat)
        : "",
      logical_vault_id: data?.logicalVaultId ?? "",
      new_request_id: getRequestId(data, formData),
      logged_in_employee_id: auth?.user?.employee_id ?? "",
      logged_in_personnel_id: discrepancyAuthFormValues?.customer?.value ?? "",
      comment_text: formData?.comment,
      auth_bypass_reason:
        t("discrepancyManagement.pendingres") === formData?.resolution
          ? discrepancyAuthFormValues?.bypassMessage
          : "",
      media_type:
        type === t("discrepancyManagement.openMedia")
          ? reportType?.o
          : reportType?.c,
      customer: data?.customerNumber ?? "",
      discrepancy_type: data?.discrepancyTypeCode ?? "",
      detected_in: data?.detectedValue?.replace("#", "%23") ?? "",
      unknown_container_id: data?.unknownContainerId ?? "",
      courtesy_contact_dropdown_list_id:
        data?.courtesyContactDropdownListId ?? "",
      service_date: data?.serviceDate
        ? dayjs(data?.serviceDate).format(mdyDateFormat)
        : "",
      run: data?.run ?? "",
      run_status: data?.runStatus ?? "",
      pending_return_date: data?.pendingReturnDate ?? "",
      customer_media_identifier: data?.customerMediaIdentifier ?? "",
      resolution_comment: data?.resolutionComment ?? "",
      additional_informations: additionalResolution
    };
    temObj["discrepancy_id_last_mod_date_array"] = selectedValues.map(
      (item) => {
        let id = item?.openMediaDiscrepancyId
          ? item?.openMediaDiscrepancyId
          : item?.discrepancyId;
        return {
          discrepancyid: id,
          lastmodificationdate: dayjs
            .utc(item.lastModDate)
            .format(formatWithSeconds),
          contextid: item?.discrepancyTypeContext
        };
      }
    );
    handlePrint(temObj);
  };
  const handleMediaData = async () => {
    try {
      setIsLoading(true);
      let isCheck = true;
      let dataSet = await getGlobalAttributeValue(
        currentBranch?.value,
        SYSTEM_ID_SecureBaseWeb,
        GLOBAL_ATTRIBUTE_VALUES.VALUE_87
      );
      let [dataSets1] = await Promise.allSettled([
        await getResponseData(
          JSON.stringify({
            main_district_id: currentBranch?.value,
            media_program_type: "",
            customer_id: data?.customerId
          }),
          CF_URLS.openMediaProcessing?.auditdetail
            ?.retrieveactivelogicalvaultsforcustomer,
          2
        )
      ]);
      if (dataSet) {
        isCheck = dataSet === Y_CHECKER;
      }
      if (dataSets1?.value?.data[0]?.length) {
        let logical = dataSets1?.value?.data[0]?.filter(
          (item) => item?.type === type
        );
        setLogicalVaultList([...logical]);
      }
      setIsLoading(false);
      return isCheck;
    } catch (err) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const handleLogicalVault = async (value) => {
    setIsLoading(true);
    setFormData({
      ...formData,
      logicalVault: value,
      mediaType: ""
    });
    setThrowErrorMessage("");
    let mediaData = await getMediaTypeList(data?.customerId, value);
    let mediaList = [];
    mediaData?.forEach((item) => {
      let label = `${item?.media_type} - ${item?.media_type_short_descr}`;
      mediaList.push({ ...item, label });
    });
    setMediaTypeList(mediaList);
    setIsLoading(false);
  };
  const shouldDisableDate = (date) => {
    if (slotDateList?.length) {
      return !slotDateList.includes(
        dayjs(date).locale(localeByBranch).format(mdyDateFormat)
      );
    } else {
      return false;
    }
  };

  const handleRowSelection = (data) => {
    setThrowErrorMessage("");
    setFormData({
      ...formData,
      unknownContainerId: data
    });
  };
  const handleUi = (val) => {
    let { day, selectedDate, outsideCurrentMonth } = val;
    const isSelected = slotDateList.includes(
      dayjs(day).locale(currentBranch).format(mdyDateFormat)
    );
    const bgColorScheduledSelectDate =
      dayjs(day).locale(currentBranch).format(mdyDateFormat) ===
      dayjs(selectedDate).locale(currentBranch).format(mdyDateFormat);
    const bgColorNonScheduledSelectDate =
      dayjs(day).locale(currentBranch).format(mdyDateFormat) ===
        dayjs(selectedDate).locale(currentBranch).format(mdyDateFormat) &&
      !isSelected;
    return (
      <Box
        sx={{
          backgroundColor:
            bgColorScheduledSelectDate && "var(--color-im-orange)",
          borderRadius: "12px"
        }}
      >
        <PickersDay
          day={day}
          selected={isSelected}
          outsideCurrentMonth={outsideCurrentMonth}
          sx={{
            [`&&.${pickersDayClasses.selected}`]: {
              backgroundColor: "var(--color-im-green)"
            },
            [`&&.${pickersDayClasses.dayWithMargin}`]: {
              backgroundColor: bgColorNonScheduledSelectDate
                ? "var(--color-im-orange)"
                : "normal",
              border: "none"
            }
          }}
          onDaySelect={(value) => {
            setFormData({
              ...formData,
              date: value
            });
            setThrowErrorMessage("");
          }}
        />
      </Box>
    );
  };
  return (
    <Grid container spacing={2} sx={DetailsStyles.fontColor}>
      <Grid item md={6} lg={3} xs={12}>
        {t("common.Customer")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.customerNumber}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyUpdate.selectedMedia")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.volserNumber || data?.containerNumber}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyUpdate.detectedIn")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.detectedIn}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyUpdate.discrepancyType")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.discrepancyType}
        </Typography>
      </Grid>
      {type !== t("discrepancyManagement.openMedia") && (
        <>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyUpdate.serviceData")}
            <Typography style={DetailsStyles.fontColor2}>
              {data?.serviceDate
                ? dayjs.utc(data?.serviceDate).format(dayMonthYearFormat)
                : ""}{" "}
            </Typography>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyUpdate.run")}
            <Typography style={DetailsStyles.fontColor2}>
              {data?.run}
            </Typography>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyUpdate.runStatus")}
            <Typography style={DetailsStyles.fontColor2}>
              {runStatus ? runStatus[0]?.type : ""}
            </Typography>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyUpdate.pendingReturnDate")}
            <Typography style={DetailsStyles.fontColor2}>
              {data?.pendingReturnDate
                ? dayjs(data?.pendingReturnDate)
                    .locale(localeByBranch)
                    .format(dayMonthYearFormat)
                : ""}{" "}
            </Typography>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyUpdate.customerMediaIdentifier")}
            <Typography style={DetailsStyles.fontColor2}>
              {data?.customerMediaDescr}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item md={12} lg={12} xs={12}>
        <Divider />
      </Grid>
      {additionalResolution.length ? (
        <>
          <AdditionalInfoTable tableData={additionalResolution} />
        </>
      ) : (
        <>
          <Grid item md={12} lg={12} xs={12}>
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="resolution">
                  {t("discrepancyUpdate.resolution")}
                </InputLabel>
                <Select
                  labelId="resolution"
                  value={formData.resolution}
                  label={t("discrepancyUpdate.resolution")}
                  onChange={(event) => {
                    handleResolutionChange(event.target.value);
                  }}
                >
                  {[...dropdownList]?.map((item) => (
                    <MenuItem value={item?.key} key={item?.value}>
                      {" "}
                      {item?.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {formData.resolution && (
            <>
              <Grid item xs={4} sm={6} md={4} lg={3}>
                <FormControl
                  fullWidth
                  disabled={enabledControls?.personnelContactedDropdown}
                >
                  <InputLabel id="personnelContacted">
                    {t("discrepancyUpdate.personnelContacted")}
                  </InputLabel>
                  <Select
                    labelId="personnelContacted"
                    value={formData?.personnelContacted}
                    label={t("discrepancyUpdate.personnelContacted")}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        personnelContacted: event.target.value
                      });
                      setThrowErrorMessage("");
                    }}
                  >
                    {[...personnelDropdown]?.map((item) => (
                      <MenuItem value={item?.key} key={item?.value}>
                        {" "}
                        {item?.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={6} md={4} lg={3}>
                <FormControl
                  fullWidth
                  disabled={enabledControls?.whereFoundDropDown}
                >
                  <InputLabel id="whereMediaWasFound">
                    {t("discrepancyUpdate.whereMediaWasFound")}
                  </InputLabel>
                  <Select
                    labelId="whereMediaWasFound"
                    value={formData?.mediaFound}
                    label={t("discrepancyUpdate.whereMediaWasFound")}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        mediaFound: event.target.value
                      });
                      setThrowErrorMessage("");
                    }}
                  >
                    {[...mediaDropdown]?.map((item) => (
                      <MenuItem value={item?.uniqueid} key={item?.alternateid}>
                        {" "}
                        {item?.formattedname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {!enabledControls?.logicalVault && (
                <Grid item xs={4} sm={6} md={4} lg={3}>
                  <FormControl
                    fullWidth
                    disabled={enabledControls?.logicalVault}
                  >
                    <InputLabel id="logicalVault">
                      {t("discrepancyUpdate.logicalVault")}
                    </InputLabel>
                    <Select
                      labelId="logicalVault"
                      value={formData?.logicalVault}
                      label={t("discrepancyUpdate.logicalVault")}
                      onChange={(event) => {
                        handleLogicalVault(event.target.value);
                      }}
                    >
                      {[...logicalVaultList]?.map((item) => (
                        <MenuItem
                          value={item?.logical_vault_id}
                          key={item?.logical_vault_id}
                        >
                          {" "}
                          {item?.code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {!enabledControls?.mediaType && (
                <Grid item xs={4} sm={6} md={4} lg={3}>
                  <FormControl fullWidth disabled={enabledControls?.mediaType}>
                    <InputLabel id="mediaType">
                      {t("discrepancyUpdate.mediaType")}
                    </InputLabel>
                    <Select
                      labelId="mediaType"
                      value={formData?.mediaType}
                      label={t("discrepancyUpdate.mediaType")}
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          mediaType: event.target.value
                        });
                        setThrowErrorMessage("");
                      }}
                    >
                      {[...mediaTypeList]?.map((item) => (
                        <MenuItem
                          value={item?.media_type_id}
                          key={item?.alternateid}
                        >
                          {" "}
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={4} sm={6} md={4} lg={3}>
                {[
                  t("discrepancyManagement.msVaultdt"),
                  t("discrepancyManagement.msVaultid")
                ].includes(formData?.resolution) || slotDateList?.length ? (
                  <FormControl
                    fullWidth
                    disabled={enabledControls?.retrurnRerunDate}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={localeByBranch}
                      disabled={enabledControls?.retrurnRerunDate}
                    >
                      <DatePicker
                        label={t("discrepancyUpdate.returnDate")}
                        value={formData?.date}
                        slots={{
                          openPickerIcon: enabledControls?.retrurnRerunDate
                            ? DisabledDatePickerIcon
                            : DatePickerIcon,
                          day: (props) => handleUi(props)
                        }}
                        minDate={today}
                        maxDate={maxDate}
                        defaultValue={today}
                        locale={localeByBranch}
                        onChange={(value) => {
                          setFormData({
                            ...formData,
                            date: [
                              t("discrepancyManagement.vaultDT"),
                              t("discrepancyManagement.reRun"),
                              t("discrepancyManagement.custRerun"),
                              t("discrepancyManagement.vaultPDT"),
                              t("discrepancyManagement.vaultOMDT")
                            ].includes(formData.resolution)
                              ? value
                              : null
                          });
                          setThrowErrorMessage("");
                        }}
                        renderInput={(params) => {
                          return <TextField {...params} />;
                        }}
                        shouldDisableDate={shouldDisableDate}
                        disabled={enabledControls?.retrurnRerunDate}
                      />
                    </LocalizationProvider>
                  </FormControl>
                ) : (
                  <FormControl
                    fullWidth
                    disabled={enabledControls?.retrurnRerunDate}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={localeByBranch}
                      disabled={enabledControls?.retrurnRerunDate}
                    >
                      <DatePicker
                        label={t("discrepancyUpdate.returnDate")}
                        value={formData?.date}
                        slots={{
                          openPickerIcon: enabledControls?.retrurnRerunDate
                            ? DisabledDatePickerIcon
                            : DatePickerIcon
                        }}
                        minDate={today}
                        maxDate={maxDate}
                        defaultValue={today}
                        locale={localeByBranch}
                        onChange={(value) => {
                          setFormData({
                            ...formData,
                            date: [
                              t("discrepancyManagement.vaultDT"),
                              t("discrepancyManagement.custRerun"),
                              t("discrepancyManagement.reRun"),
                              t("discrepancyManagement.vaultPDT"),
                              t("discrepancyManagement.vaultOMDT"),
                              t("discrepancyManagement.spSTD")
                            ].includes(formData.resolution)
                              ? value
                              : null
                          });
                          setThrowErrorMessage("");
                        }}
                        renderInput={(params) => {
                          return <TextField {...params} />;
                        }}
                        shouldDisableDate={shouldDisableDate}
                        disabled={enabledControls?.retrurnRerunDate}
                      />
                    </LocalizationProvider>
                  </FormControl>
                )}
              </Grid>
              <Grid item md={12} lg={12} xs={12}>
                <Divider />
              </Grid>
              <Grid item md={12} lg={12} xs={12}>
                <FormControl
                  fullWidth
                  disabled={enabledControls?.commentsTextBox}
                >
                  <TextField
                    label={t("discrepancyUpdate.comments")}
                    value={formData?.comment}
                    onChange={(event) => {
                      setFormData({
                        ...formData,
                        comment: event.target.value
                      });
                      setThrowErrorMessage("");
                    }}
                    fullWidth
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 225 }}
                    disabled={enabledControls?.commentsTextBox}
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </>
      )}
      <Grid item md={12} lg={12} xs={12}>
        <Divider />
      </Grid>
      {!enabledControls?.uploadError && formData.resolution && (
        <>
          <Grid item md={12} lg={12} xs={12}>
            <UploadErrorList
              dataList={uploadErroList}
              handleRowSelection={handleRowSelection}
            />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      <Grid item md={12} lg={12} xs={12}>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="start"
          gap={spacing.verticalMargin20}
          disabled={additionalResolution?.length}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handlePersonnelAuthChange();
              if (location?.state?.from) {
                navigate(
                  `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT_DETAIL}`
                );
              } else {
                navigate(
                  `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`,
                  {
                    state: {
                      from: location?.pathname,
                      detectedIn: searchFormValues.detectedIn
                    }
                  }
                );
              }
            }}
          >
            {t("common.back")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setFormData({
                resolution: "",
                personnelContacted: "",
                mediaFound: "",
                date: null,
                comment: ""
              });
              setThrowErrorMessage("");
            }}
            disabled={additionalResolution?.length}
          >
            {t("common.reset")}
          </Button>

          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              handleSave();
            }}
            disabled={additionalResolution?.length}
          >
            {t("common.save")}
          </Button>

          <Button
            variant="outlined"
            disabled={!additionalResolution?.length}
            onClick={() => handlePrintAction()}
          >
            {t("common.print")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DiscrepancyUpdate;
