import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  Snackbar,
  Stack,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { PageTitle } from "components/shared";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { TABLE_ROWS_PER_PAGE_DEFAULT } from "components/features/request-module/media-destruction-request-vault-itemized/Constants";
import { useSelector } from "react-redux";
import {
  ADMIN_MODULE_BASE_PATH,
  ADMIN_MODULE_BILLING_BASE_PATH,
  BILLING_STATUS,
  BILLING_TRANSACTIONS
} from "routing/Paths";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getCustomersQueryData, getMessageFromCode } from "services/api/query";
import { selectAuth, setCurrentBranch } from "store/slices";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  dateFormat,
  EMPTY_SELECTION,
  ERROR_TYPES,
  N_CHECKER,
  snackBarAutoHideDuration,
  VALUE_ALL,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y,
  Y_CHECKER
} from "utils/constants";
import { billingCycleCodeCheckEOM } from "utils/constants/biling-module";
import {
  billCycleInvoiceId,
  billingRunStatusIds,
  billingSystems,
  catalogItem,
  columns,
  customer,
  dateFormats,
  defaultUnitPrice,
  error,
  flatFee,
  headerDescription,
  headerQuantity,
  headerService,
  LogicalVault,
  messageCodes,
  numValues,
  printAdjHeader,
  priorMonth1,
  priorMonth2,
  requestType,
  sortByValues,
  transactionTypeIds,
  transactionTypes,
  tripType,
  TypeHeader,
  unitPriceHeader,
  variance
} from "utils/constants/biling-module/BillingTransactionsConstants";
import {
  asyncSort,
  billDateFormatByLocale,
  customerFilter,
  findErrorMessage,
  formatNumber,
  getAuthenticatedUserBranch,
  getAuthenticatedUserLogin,
  getSettingsValue
} from "utils/helpers";
import DownloadReportModal from "./BillingTransactionPrintModal";
import BillingTransactionsModal from "./BillingTransactionsModal";
import BillingTransactionsSearchFilter from "./BillingTransactionsSearchFilter";
import { billingTransactionsStyles } from "./BillingTransactionsStyles";
import BillingTransactionsTable from "./BillingTransactionsTable";

const BillingTransactionsLayout = () => {
  const { t } = useTranslation();
  const { localeByBranch } = useSelector(selectAuth);
  const data = useLocation()?.pathname?.split(`/${BILLING_TRANSACTIONS}/`)[1];
  const navigate = useNavigate();
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      setCurrentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  const initialValues = {
    branch: VALUE_EMPTY_STRING,
    billCycle: VALUE_EMPTY_STRING,
    billDate: VALUE_EMPTY_STRING,
    service: VALUE_ALL,
    customer: VALUE_ALL,
    classification: VALUE_ALL,
    catalogItem: VALUE_ALL,
    sortBy: VALUE_ALL,
    checkBox: false
  };

  //  ---------- React states  ----------

  const [firstLoad, setFirstLoad] = useState(true);
  const [fromBillingStatus, setFromBillingStatus] = useState(false);
  const [disableAll, setDisableAll] = useState(false);
  const [dateFormatted, setDateFormatted] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_PER_PAGE_DEFAULT);

  const [isLoading, setIsLoading] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState(VALUE_EMPTY_STRING);

  const [newlyCreated, setNewlyCreated] = useState(VALUE_EMPTY_STRING);
  const [sumOfAdj, setSumOfAdj] = useState(null);
  const [isOneYAdj, setIsOneYAdj] = useState(false);

  const defaultBillingStatusValues = {
    branch: VALUE_EMPTY_STRING,
    billCycle: VALUE_EMPTY_STRING,
    billDate: VALUE_EMPTY_STRING
  };

  const [valuesFromBillingStatus, setValuesFromBillingStatus] = useState(
    defaultBillingStatusValues
  );

  // error on modal
  // eslint-disable-next-line
  const [throwErrorModal, setThrowErrorModal] = useState(false);
  const [throwErrorMessageModal, setThrowErrorMessageModal] =
    useState(VALUE_EMPTY_STRING);

  // warning on modal
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessageModal, setWarningMessageModal] =
    useState(VALUE_EMPTY_STRING);

  // warning on main
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);
  const [warningMessageMain, setWarningMessageMain] =
    useState(VALUE_EMPTY_STRING);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isCreating, setIsCreating] = useState({
    new: false,
    adjust: false,
    repeat: false
  });

  const [unitPrincePrecision, setUnitPricePrecision] = useState(4);

  const [isOTOPriceManuallyEntered, setIsOTOPriceManuallyEntered] =
    useState(false);

  const [isArcVMS, setIsArcVMS] = useState(false);

  const [pricingContractCode, setPricingContractCode] =
    useState(VALUE_EMPTY_STRING);

  const [phasesStatus, setPhasesStatus] = useState(null);
  //  ---------- Search filter section (with initial values)
  const [arrayOTOTypes, setArrayOTOTypes] = useState([]);
  const [arrayStandardTypes, setArrayStandardTypes] = useState([]);

  // branch dropdown
  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(EMPTY_SELECTION);
  const [isBranchDisabled, setIsBranchDisabled] = useState(false);

  // bill cycle dropdown
  const [billCycleList, setBillCycleList] = useState([]);
  const [selectedBillCycle, setSelectedBillCycle] = useState(EMPTY_SELECTION);
  const [isBillCycleDisabled, setIsBillCycleDisabled] = useState(true);

  // bill date read only textFiled
  const [billDate, setBillDate] = useState(VALUE_EMPTY_STRING);
  const [billDateGlobal, setBillDateGlobal] = useState(VALUE_EMPTY_STRING);

  // service dropdown
  const [servicesList, setServicesList] = useState([]);
  const [selectedService, setSelectedService] = useState(ALL_SELECTION);
  const [isServiceDisabled, setIsServiceDisabled] = useState(true);

  const [selectedServiceNew, setSelectedServiceNew] =
    useState(VALUE_EMPTY_STRING);

  // customer dropdown
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(ALL_SELECTION);
  const [isCustomerDisabled, setIsCustomerDisabled] = useState(true);

  const [customerSelectedNew, setCustomerSelectedNew] =
    useState(VALUE_EMPTY_STRING);

  // classification dropdown
  const [classificationList, setClassificationList] = useState([]);
  const [selectedClassification, setSelectedClassification] =
    useState(ALL_SELECTION);
  const [isClassificationDisabled, setIsClassificationDisabled] =
    useState(true);

  const [newClassificationList, setNewClassificationList] = useState([]);
  const [selectedClassificationNew, setSelectedClassificationNew] =
    useState(VALUE_EMPTY_STRING);

  // catalog item dropdown
  const [catalogItemsList, setCatalogItemsList] = useState([]);
  const [selectedCatalogItem, setSelectedCatalogItem] = useState(ALL_SELECTION);
  const [isCatalogItemDisabled, setIsCatalogItemDisabled] = useState(true);

  const [catalogItemsListNew, setCatalogItemsListNew] = useState([]);
  const [selectedCatalogItemNew, setSelectedCatalogItemNew] = useState([]);

  // sort by dropdown
  const sortByList = sortByValues;
  const [selectedSortBy, setSelectedSortBy] = useState(sortByValues[0]);

  // counted item only checkbox
  const [isChecked, setIsChecked] = useState(false);
  // eslint-disable-next-line
  const [isCheckBoxDisabled, setIsCheckBoxDisabled] = useState(true); // it seems the never enabled this

  const [quantity, setQuantity] = useState(VALUE_EMPTY_STRING);
  const [descriptionSection, setDescriptionSection] =
    useState(VALUE_EMPTY_STRING);

  const [unitPrice, setUnitPrice] = useState(
    formatNumber(defaultUnitPrice, unitPrincePrecision)
  );
  const [valuePO, setValuePO] = useState(VALUE_EMPTY_STRING);
  const [printAdj, setPrintAdj] = useState(false);
  const [logicalVault, setLogicalVault] = useState(VALUE_EMPTY_STRING);

  // find button
  const [isFindBtnDisabled, setIsFindBtnDisabled] = useState(true);
  const [isFindButtonClicked, setIsFindButtonClicked] = useState(false);

  // new button
  const [isNewBtnDisabled, setIsNewBtnDisabled] = useState(true);

  // table data
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isClearClicked, setIsClearClicked] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [noResults, setNoResults] = useState(false);
  const [tableColumns, setTableColumns] = useState(columns);
  const [isAscending, setIsAscending] = useState(true);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [printRequestBody, setPrintRequestBody] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [reportCFUrl, setReportCFUrl] = useState();
  /**
   * modal service
   * modal customer
   * modal classification
   * modal catalogItem
   * modal quantity
   * modal description
   * modal unit price
   * modal po
   * modal print adj
   */

  const initialElementValues = {
    service: false,
    customer: false,
    classification: false,
    catalogItem: false,
    quantity: false,
    description: false,
    unitPrice: false,
    po: false,
    printAdj: false
  };

  const elementValues = {
    service: selectedServiceNew,
    customer: customerSelectedNew,
    classification: selectedClassificationNew,
    catalogItem: selectedCatalogItemNew,
    quantity: quantity,
    description: descriptionSection,
    unitPrice: unitPrice,
    po: valuePO,
    printAdj: printAdj
  };

  const [modalElementValue, setModalElementValue] = useState(elementValues);

  // modal element visibility
  const [modalElementVisible, setModalElementVisible] =
    useState(initialElementValues);

  // modal element isEnable
  const [modalElementEnable, setModalElementEnable] =
    useState(initialElementValues);

  const resetModalElementValidation = () => {
    setModalElementVisible(initialElementValues);

    setModalElementEnable(initialElementValues);
  };

  /**
   * Initial load #########################
   * formLoad >> fetchBillingInfo >> separateCatalogTypes >> fetchBillingInterfaceLogon >> getAllLocations
   */
  useEffect(() => {
    mainDistrictId && formLoad();
    // eslint-disable-next-line
  }, [mainDistrictId]);

  // from Billing Status
  useEffect(() => {
    if (data) {
      setFromBillingStatus((prev) => true);
      /**
       * RC- BN-06
       * If the user directs from the “Bill Status” screen get the values for the “Branch”,
       * “Bill Cycle” and “Bill Date” from the “ Billing Status” screen.
       */

      const [recivedBranch, receivedBillCycle, receivedBillDate] =
        data?.split("/") || [];

      const receivedData = {
        branch: recivedBranch,
        billCycle: receivedBillCycle,
        billDate: receivedBillDate
      };

      setValuesFromBillingStatus((prev) => receivedData);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (
      valuesFromBillingStatus?.branch !== VALUE_EMPTY_STRING &&
      !firstLoad &&
      fromBillingStatus
    ) {
      const branch = branchList?.filter(
        (branch) =>
          branch?.value?.trim() === String(valuesFromBillingStatus?.branch)
      );

      if (selectedBranch?.value === VALUE_EMPTY_STRING && branch?.length > 0) {
        setSelectedBranch((prev) => branch[0]);
      }
    }
    // eslint-disable-next-line
  }, [valuesFromBillingStatus, firstLoad, fromBillingStatus]);

  useEffect(() => {
    if (fromBillingStatus && billCycleList?.length > 0) {
      setFromBillingStatus(false);

      const billCycle = billCycleList?.filter((cycle) => {
        return (
          cycle?.value?.trim() === String(valuesFromBillingStatus?.billCycle)
        );
      });

      setSelectedBillCycle(billCycle[0]);
      navigate(
        `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_BILLING_BASE_PATH}/${BILLING_TRANSACTIONS}`
      );
      setFromBillingStatus((prev) => false);
    }
    // eslint-disable-next-line
  }, [billCycleList]);

  // modal element validations
  useEffect(() => {
    resetModalElementValidation();

    // If Billing Cycle Id == OTO Invoice Id (3)
    if (Number(selectedBillCycle?.value) === billCycleInvoiceId.oto) {
      if (isCreating?.new) {
        setModalElementVisible({
          ...initialElementValues,
          service: true,
          customer: true,
          classification: true,
          catalogItem: true,
          quantity: true,
          description: true,
          unitPrice: true,
          po: true
        });

        setModalElementEnable({
          ...initialElementValues,
          service: true,
          customer: true,
          quantity: true,
          description: true,
          unitPrice: true,
          po: true
        });
      }

      if (isCreating?.adjust) {
        setModalElementVisible({
          ...initialElementValues,
          quantity: true,
          description: true,
          printAdj: true
        });

        setModalElementEnable({
          ...initialElementValues,
          quantity: true,
          description: true,
          printAdj: true
        });
      }

      if (isCreating?.repeat) {
        setModalElementVisible({
          ...initialElementValues,
          quantity: true,
          description: true,
          unitPrice: true,
          po: true
        });

        setModalElementEnable({
          ...initialElementValues,
          quantity: true,
          description: true,
          unitPrice: true,
          po: true
        });
      }

      if (isUpdating) {
        if (
          selectedRow?.transaction_type_code?.trim() === transactionTypes.man ||
          selectedRow?.transaction_type_code?.trim() === transactionTypes.auto
        ) {
          setModalElementVisible({
            ...initialElementValues,
            quantity: true,
            description: true,
            unitPrice: true,
            po: true
          });

          if (selectedRow?.current_quantity === VALUE_EMPTY_STRING) {
            setModalElementEnable({
              ...initialElementValues,
              quantity: true,
              description: true,
              unitPrice: true,
              po: true
            });
          } else {
            setModalElementEnable({
              ...initialElementValues,
              description: true,
              unitPrice: true,
              po: true
            });
          }
        }

        if (
          selectedRow?.transaction_type_code?.trim() === transactionTypes.adj
        ) {
          setModalElementVisible({
            ...initialElementValues,
            description: true,
            printAdj: true
          });

          setModalElementEnable({
            ...initialElementValues,
            description: true,
            printAdj: true
          });
        }
      }
    }

    // If Billing Cycle Id == Hold OTO Invoice Id (4)
    if (Number(selectedBillCycle?.value) === billCycleInvoiceId.hOto) {
      if (isCreating?.new) {
        setModalElementVisible({
          ...initialElementValues,
          service: true,
          customer: true,
          classification: true,
          catalogItem: true,
          quantity: true,
          description: true,
          unitPrice: true
        });

        setModalElementEnable({
          ...initialElementValues,
          service: true,
          customer: true,
          quantity: true,
          unitPrice: true
        });
      }

      if (isCreating?.adjust) {
        setModalElementVisible({
          ...initialElementValues,
          customer: true,
          catalogItem: true,
          quantity: true,
          description: true,
          printAdj: true
        });

        setModalElementEnable({
          ...initialElementValues,
          quantity: true,
          description: true,
          printAdj: true
        });
      }

      if (isCreating?.repeat) {
        setModalElementVisible({
          ...initialElementValues,
          quantity: true,
          description: true,
          unitPrice: true
        });

        setModalElementEnable({
          ...initialElementValues,
          quantity: true,
          description: true,
          unitPrice: true
        });
      }

      if (isUpdating) {
        if (
          selectedRow?.transaction_type_code?.trim() === transactionTypes.man ||
          selectedRow?.transaction_type_code?.trim() === transactionTypes.auto
        ) {
          setModalElementVisible({
            ...initialElementValues,
            quantity: true,
            description: true,
            unitPrice: true
          });

          if (selectedRow?.current_quantity === VALUE_EMPTY_STRING) {
            setModalElementEnable({
              ...initialElementValues,
              quantity: true,
              description: true,
              unitPrice: true
            });
          } else {
            setModalElementEnable({
              ...initialElementValues,
              description: true,
              unitPrice: true
            });
          }
        }

        if (
          selectedRow?.transaction_type_code?.trim() === transactionTypes.adj
        ) {
          setModalElementVisible({
            ...initialElementValues,
            quantity: true,
            description: true,
            unitPrice: true,
            printAdj: true
          });

          setModalElementEnable({
            ...initialElementValues,
            description: true,
            printAdj: true
          });
        }
      }
    }

    // If Billing Cycle == EOM Invoice Id (1)
    if (Number(selectedBillCycle?.value) === billCycleInvoiceId.eom) {
      if (isCreating?.new) {
        setModalElementVisible({
          ...initialElementValues,
          service: true,
          customer: true,
          classification: true,
          catalogItem: true,
          quantity: true,
          description: true
        });

        setModalElementEnable({
          ...initialElementValues,
          service: true,
          customer: true,
          quantity: true
        });
      }

      if (isCreating?.adjust) {
        setModalElementVisible({
          ...initialElementValues,
          customer: true,
          catalogItem: true,
          quantity: true,
          description: true,
          printAdj: true
        });

        setModalElementEnable({
          ...initialElementValues,
          quantity: true,
          description: true,
          printAdj: true
        });
      }

      if (isCreating?.repeat) {
        setModalElementVisible({
          ...initialElementValues,
          customer: true,
          quantity: true,
          description: true
        });

        setModalElementEnable({
          ...initialElementValues,
          quantity: true,
          description: true
        });
      }

      if (isUpdating) {
        if (
          selectedRow?.transaction_type_code?.trim() === transactionTypes.man ||
          selectedRow?.transaction_type_code?.trim() === transactionTypes.auto
        ) {
          setModalElementVisible({
            ...initialElementValues,
            customer: true,
            quantity: true,
            description: true
          });

          if (selectedRow?.current_quantity === VALUE_EMPTY_STRING) {
            setModalElementEnable({
              ...initialElementValues,
              quantity: true,
              description: true
            });
          } else {
            setModalElementEnable({
              ...initialElementValues,
              description: true
            });
          }
        }

        if (
          selectedRow?.transaction_type_code?.trim() === transactionTypes.adj
        ) {
          setModalElementVisible({
            ...initialElementValues,
            quantity: true,
            description: true,
            printAdj: true
          });

          setModalElementEnable({
            ...initialElementValues,
            description: true,
            printAdj: true
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [isCreating?.new, isCreating?.adjust, isCreating?.repeat, isUpdating]);

  /**
   * On Branch selection
   * getOTOManualPricingFlag >> fetchSettingValues >> fetchBillingCycleByDistrict >> fetchCurrentBranchBillingSystem >> getCustomers
   */
  useEffect(() => {
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);

    setBillDate(VALUE_EMPTY_STRING);
    setIsNewBtnDisabled(true);

    setServicesList([]);
    setIsServiceDisabled(true);
    setSelectedService(ALL_SELECTION);

    setClassificationList([]);
    setIsClassificationDisabled(true);
    setSelectedClassification(ALL_SELECTION);

    setCatalogItemsList([]);
    setIsCatalogItemDisabled(true);
    setSelectedCatalogItem(ALL_SELECTION);

    setSelectedCustomer(ALL_SELECTION);

    setSelectedBillCycle(EMPTY_SELECTION);

    if (selectedBranch?.value && selectedBranch?.value !== VALUE_EMPTY_STRING) {
      // Check if OTO Price is entered manually
      getOTOManualPricingFlag();

      setIsBillCycleDisabled(false);
      setIsFindBtnDisabled(true);
    } else {
      setSelectedBillCycle(EMPTY_SELECTION);
      setIsBillCycleDisabled(true);
      setIsFindBtnDisabled(true);
    }
    // eslint-disable-next-line
  }, [selectedBranch]);

  /**
   * On Bill cycle selection + Table column visibility
   */
  useEffect(() => {
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);

    setBillDate(VALUE_EMPTY_STRING);
    setIsNewBtnDisabled(true);

    setServicesList([]);
    setIsServiceDisabled(true);
    setSelectedService(ALL_SELECTION);

    setIsClassificationDisabled(true);
    setSelectedClassification(ALL_SELECTION);

    setIsCatalogItemDisabled(true);
    setSelectedCatalogItem(ALL_SELECTION);

    setSelectedCustomer(ALL_SELECTION);

    if (selectedBranch?.value && selectedBranch?.value !== VALUE_EMPTY_STRING) {
      // If Billing Cycle Id == OTO Invoice Id (3)
      if (Number(selectedBillCycle?.value) === billCycleInvoiceId.oto) {
        checkBillRun();

        // column reset
        updateColumnVisibility((prev) => columns);

        /**
         * Hide the columns in the grid mentioned below
         *  ebtcPriorMonth1 (Index = 13)
         *  ebtcPriorMonth2 (Index = 14 )
         *  ebtcvariance (Index = 16 )
         *  ebtcDescription(Index =7 )
         *  ebtcRequestType (Index = 23)
         *  ebtcTripType (Index = 24)

         */

        updateColumnVisibility("priorMonth1", false);
        updateColumnVisibility("priorMonth2", false);
        updateColumnVisibility("variance", false);
        // updateColumnVisibility("description", false);
        updateColumnVisibility("requestType", false);
        updateColumnVisibility("tripType", false);

        /**
         * Hide/Show (Set Visibility to true/false) the columns in the grid
         * mentioned below based on OTO Price being entered manually
         *  ebtcunitprice (Index = 11)
         *  ebtcPO (Index = 12 )
         */
        if (isOTOPriceManuallyEntered) {
          updateColumnVisibility("unitPrice", true);
          updateColumnVisibility("po", true);
        } else {
          updateColumnVisibility("unitPrice", false);
          updateColumnVisibility("po", false);
        }
      }

      // If Billing Cycle Id == Hold OTO Invoice Id (4)
      if (Number(selectedBillCycle?.value) === billCycleInvoiceId.hOto) {
        const lastDateOfTheCurrentMonth = dayjs()
          .endOf("month")
          .format(dateFormats.slash);
        setBillDate(lastDateOfTheCurrentMonth); // consider locale as well !!!!!!!!!!!!!!!

        const globalDate = dayjs().endOf("month").format(dateFormats.dash);
        setBillDateGlobal(globalDate);
        setDateFormatted(false);

        // column reset
        updateColumnVisibility((prev) => columns);

        /**
         * Hide the columns in the grid mentioned below
         *  ebtcPriorMonth1 (Index = 13)
         *  ebtcPriorMonth2 (Index = 14 )
         *  ebtcvariance (Index = 16 )
         *  ebtcDescription(Index =7 )
         *  ebtcRequestType (Index = 23)
         *  ebtcTripType (Index = 24)
         *  ebtcPO (Index = 12 )
         *  ebtcvaru = ( Index 22) ?????????
         */

        // Show the following column in the grid
        // ebtcunitprice (Index = 11)

        updateColumnVisibility("priorMonth1", false);
        updateColumnVisibility("priorMonth2", false);
        updateColumnVisibility("variance", false);
        // updateColumnVisibility("description", false);
        updateColumnVisibility("requestType", false);
        updateColumnVisibility("tripType", false);
        updateColumnVisibility("po", false);
        // updateColumnVisibility("varu", false) ???????????

        updateColumnVisibility("unitPrice", true);
      }

      // If Billing Cycle == EOM Invoice Id (1)
      if (Number(selectedBillCycle?.value) === billCycleInvoiceId.eom) {
        checkBillRun();

        // column reset
        updateColumnVisibility((prev) => columns);

        /**
         * Show the columns in the grid mentioned below
         *  ebtcPriorMonth1 (Index = 13)
         *  ebtcPriorMonth2 (Index = 14 )
         *  ebtcvariance (Index = 16 )
         */

        updateColumnVisibility("priorMonth1", true);
        updateColumnVisibility("priorMonth2", true);
        updateColumnVisibility("variance", true);

        /**
         * Hide the columns in the grid mentioned below
         * ebtcunitprice (Index = 11)
         * ebtcPO (Index = 12 )
         */

        updateColumnVisibility("unitPrice", false);
        updateColumnVisibility("po", false);

        if (isArcVMS) {
          /**
           * If Billing system retrieved is ==  “ARCVMS”
           * Make the following columns in the grid visible
                Request Type
                Trip Type
           */
          updateColumnVisibility("requestType", true);
          updateColumnVisibility("tripType", true);
        } else {
          /**
           * Else (Not ARCVMS)
           * Hide the following columns in the grid
                Request Type
                Trip Type
           */
          updateColumnVisibility("requestType", false);
          updateColumnVisibility("tripType", false);
        }
      }

      loadServices();
    }

    // eslint-disable-next-line
  }, [selectedBillCycle]);

  useEffect(() => {
    if (billDate !== VALUE_EMPTY_STRING) {
      setIsFindBtnDisabled(false);
      setIsServiceDisabled(false);
      setIsCustomerDisabled(false);
    } else {
      setIsCustomerDisabled(true);
    }
  }, [billDate]);

  /**
   * On Service Selection
   * getBillingClassificationCodes >> getCatalogItems
   */
  useEffect(() => {
    if (selectedService?.value !== VALUE_ALL) {
      setIsClassificationDisabled(false);
      setIsCatalogItemDisabled(false);
      // setIsNewBtnDisabled(false);

      getBillingClassificationCodes();
    } else {
      setSelectedClassification(ALL_SELECTION);
      setIsClassificationDisabled(true);
      setIsCatalogItemDisabled(true);
      // setIsNewBtnDisabled(true);
    }

    // eslint-disable-next-line
  }, [selectedService]);

  /**
   * On Customer Selection
   */
  useEffect(() => {
    if (!isCreating?.new && selectedCustomer !== VALUE_EMPTY_STRING) {
      setSelectedClassification(ALL_SELECTION);
      setSelectedCatalogItem(ALL_SELECTION);
      getBillingClassificationCodes();
      getCatalogItems();
    }

    // eslint-disable-next-line
  }, [selectedCustomer]);

  useEffect(() => {
    if (isCreating?.new && customerSelectedNew !== VALUE_EMPTY_STRING) {
      getBillingClassificationCodes();
    }
    // eslint-disable-next-line
  }, [customerSelectedNew]);

  /**
   * On Classification Selection
   */
  useEffect(() => {
    getCatalogItems();
    setIsFindButtonClicked(false);
    // eslint-disable-next-line
  }, [selectedClassification]);

  useEffect(() => {
    if (selectedClassificationNew !== VALUE_EMPTY_STRING) {
      getCatalogItems();
    }
    // eslint-disable-next-line
  }, [selectedClassificationNew]);

  const formLoad = () => {
    fetchBillingInfo();
  };

  /**
   * Set selected service in modal
   */
  useEffect(() => {
    setSelectedServiceNew((prev) => servicesList[0] || VALUE_EMPTY_STRING);
  }, [servicesList]);

  /**
   * On Catalog Item selection in modal
   */
  useEffect(() => {
    if (selectedCatalogItemNew !== VALUE_EMPTY_STRING) {
      getPricingContractBasedOnCycle();
    }
    // eslint-disable-next-line
  }, [selectedCatalogItemNew]);

  // if the table is empty
  useEffect(() => {
    if (tableData?.length === 0) {
      setNewlyCreated(VALUE_EMPTY_STRING);
      setEditingRowIndex(null);
    }
  }, [tableData]);

  const loadServices = () => {
    /**
     * If the Billing Cycle Id == EOM Invoice Id (1)
     * Add the Standard Types to the Service Dropdown
     * Else
     * Add the OTO Types to the Service Dropdown
     */
    if (Number(selectedBillCycle?.value) === billCycleInvoiceId.eom) {
      setServicesList((prev) => arrayStandardTypes);
    } else {
      setServicesList((prev) => arrayOTOTypes);
    }
  };

  const onFindClick = () => {
    setEditingRowIndex(null);
    checkBillRunPhaseL1(Number(selectedBillCycle?.value));
  };

  const callGridLoadingCFs = (isModal) => {
    // If Billing Cycle Id == OTO Invoice Id (3)
    if (Number(selectedBillCycle?.value) === billCycleInvoiceId.oto) {
      billTransL2InBranchDB(isModal);
    }

    // If Billing Cycle Id == Hold OTO Invoice Id (4)
    if (Number(selectedBillCycle?.value) === billCycleInvoiceId.hOto) {
      interfaceTransactionInBranchDB(isModal);
    }

    // If Billing Cycle == EOM Invoice Id (1)
    if (Number(selectedBillCycle?.value) === billCycleInvoiceId.eom) {
      billTransL1ByDistrict(isModal);
    }
  };

  const reset = () => {
    setSelectedService(ALL_SELECTION);
    setSelectedCustomer(ALL_SELECTION);
    setSelectedClassification(ALL_SELECTION);
    setSelectedCatalogItem(ALL_SELECTION);
    setSelectedSortBy(sortByValues[0]);
    setIsChecked(false);
    setDisableAll(false);
    setSelectedRow(null);

    setEditingRowIndex(null);
    setPage(0);
    setRowsPerPage(TABLE_ROWS_PER_PAGE_DEFAULT);

    setTableData([]);
    setNoResults(false);

    setIsClearClicked(true);
    setIsFindButtonClicked(false);

    setIsNewBtnDisabled(true);
    setSelectedIndex(null);
  };

  //Separate out the standard and OTO catalog Types
  const separateCatalogTypes = (catalogTypes) => {
    setIsLoading(true);
    /**
     * While looping through the list of OTO types
     * If the catalog flag is “N”
     * Add it to the OTO Types array
     * Else
     * Add it to the Standard Types array
     */
    if (catalogTypes && catalogTypes?.length > 0) {
      const otoTypes = catalogTypes
        ?.filter((catalog) => catalog?.regular_service_flag === N_CHECKER)
        ?.map(({ billing_catalog_code, descr }) => ({
          label: descr,
          value: billing_catalog_code
        }));
      const standardTypes = catalogTypes
        ?.filter((catalog) => catalog?.regular_service_flag === Y_CHECKER)
        ?.map(({ billing_catalog_code, descr }) => ({
          label: descr,
          value: billing_catalog_code
        }));

      setArrayOTOTypes((prev) =>
        otoTypes.sort((a, b) => a.label.localeCompare(b.label))
      );
      setArrayStandardTypes((prev) =>
        standardTypes.sort((a, b) => a.label.localeCompare(b.label))
      );
    } else {
      setArrayOTOTypes([]);
      setArrayStandardTypes([]);
    }

    fetchBillingInterfaceLogon();
  };

  const handleCancelClick = () => {
    setIsCreating({
      new: false,
      adjust: false,
      repeat: false
    });
    setIsUpdating(false);
    setEditingRowIndex(null);

    setSelectedServiceNew(VALUE_EMPTY_STRING);
    setCustomerSelectedNew(VALUE_EMPTY_STRING);
    setSelectedClassificationNew(VALUE_EMPTY_STRING);
    setSelectedCatalogItemNew(VALUE_EMPTY_STRING);
    setQuantity(VALUE_EMPTY_STRING);
    setDescriptionSection(VALUE_EMPTY_STRING);
  };

  const handlePrintClick = () => {
    setIsPrintModalOpen(true);
    setReportCFUrl(CF_URLS.printReports.billing.billingTransactionsReport);
  };

  //  ---------- SP calls  ----------

  // Get the districts and catalogs
  const fetchBillingInfo = async () => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.billingTransactions.getBillingInfoExcludeHoldOTO,
        4
      );

      if (dataSets && dataSets?.data) {
        const branches = dataSets?.data[0]?.map(({ code, district_id }) => ({
          label: code,
          value: district_id
        }));

        if (branches) {
          // branches
          setBranchList((prev) =>
            branches.sort((a, b) => a.label.localeCompare(b.label))
          );
        } else {
          setBranchList([]);
        }

        // catalog types
        if (dataSets?.data[2]) {
          separateCatalogTypes(dataSets?.data[2]);
        } else {
          setArrayOTOTypes([]);
          setArrayStandardTypes([]);
        }
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  // Check if user from home office or not
  const fetchBillingInterfaceLogon = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        login: getAuthenticatedUserLogin() || VALUE_EMPTY_STRING
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.billingTransactions.getBillingInterfaceLogon,
        1
      );

      if (dataSets && dataSets?.data && dataSets?.data[0]?.length > 0) {
        if (dataSets?.data[0][0]?.valid === Y_CHECKER) {
          // enable
          setIsBranchDisabled(false);
          // setSelectedBranch(VALUE_EMPTY_STRING);
        } else {
          /**
           * If the user is not from home office
           * Disable the District dropdown.
           * Set the District ID to the currently logged in District.
           */
          setIsBranchDisabled(true);
          setSelectedBranch(mainDistrictId);
        }
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
      setFirstLoad(false);
    }
  };

  // Get Unit Price Precision
  const fetchSettingValues = async () => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const settngValue = await getSettingsValue(
          mainDistrictId,
          numValues.thousand
        );
        setUnitPricePrecision(settngValue);
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        fetchBillingCycleByDistrict();
      }
    }
  };

  // Get "Bill Cycle" Values
  const fetchBillingCycleByDistrict = async () => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          district_id: selectedBranch?.value
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.execBillingCycleL1ByDistrict,
          1
        );

        /**
         * While record count > 0
         * Set the Billing cycle dropdown based on the result obtained
         * from the valid billing cycles displaying the descr and setting the id as the value
         */

        if (dataSets && dataSets?.data && dataSets?.data[0]?.length > 0) {
          const cycles = dataSets?.data[0]?.map(
            ({ billing_cycle_code, invoice_date_type_id, descr }) => ({
              label: descr,
              value: invoice_date_type_id,
              code: billing_cycle_code?.trim()
            })
          );

          setBillCycleList((prev) =>
            cycles.sort((a, b) => a.label.localeCompare(b.label))
          );
        }
      } catch (error) {
        setBillCycleList([]);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        fetchCurrentBranchBillingSystem();
      }
    }
  };

  // Check ArcVMS
  const fetchCurrentBranchBillingSystem = async () => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          district_id: selectedBranch?.value
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.getCurrentBranchBillingSystem,
          1
        );

        if (dataSets && dataSets?.data) {
          if (dataSets?.data[0][0]?.billing_system === billingSystems.arcVMS) {
            setIsArcVMS((prev) => true);
          } else {
            setIsArcVMS((prev) => false);
          }
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        getCustomers();
      }
    }
  };

  // Get "Bill Date"
  const checkBillRun = async () => {
    setIsLoading(true);
    setDateFormatted(false);
    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          district_id: selectedBranch?.value,
          billing_run_status_id: billingRunStatusIds.fifth,
          billing_cycle_code: selectedBillCycle?.code
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.executeBillingRunL3,
          1
        );

        if (
          dataSets &&
          dataSets?.data &&
          dataSets?.data[0][0]?.billing_date !== VALUE_EMPTY_STRING
        ) {
          const [year, month, day] = (
            dataSets?.data[0][0]?.billing_date?.split("T")[0]?.split("-") || []
          ).map((part) => parseInt(part, 10));

          const gMonth = month?.length === 1 ? `0${month}` : month;
          const gDay = day?.length === 1 ? `0${day}` : day;
          setBillDateGlobal(`${year}-${gMonth}-${gDay}`);

          setBillDate(`${month}/${day}/${year}`);

          setThrowErrorMessage(VALUE_EMPTY_STRING);
          setThrowError(false);
        } else {
          setBillDate(VALUE_EMPTY_STRING);
          const errorMsg = await getMessageFromCode(messageCodes.value10183);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getFormattedDate = async () => {
    const formattedBillDate = await billDateFormatByLocale(
      billDate,
      localeByBranch
    );

    setDateFormatted(true);

    setBillDate(formattedBillDate);
  };

  useEffect(() => {
    if (billDate !== VALUE_EMPTY_STRING && localeByBranch && !dateFormatted) {
      getFormattedDate();
    }
    // eslint-disable-next-line
  }, [localeByBranch, billDate]);

  // Check OTO manually entered or not
  const getOTOManualPricingFlag = async () => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          district_id: selectedBranch?.value
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.getOTOManualPricingFlag,
          1
        );

        if (dataSets && dataSets?.data) {
          const isManuallyEntered =
            dataSets?.data[0][0]?.oto_manual_pricing_flag;

          setIsOTOPriceManuallyEntered(
            isManuallyEntered === Y_CHECKER ? true : false
          );
        } else {
          setIsOTOPriceManuallyEntered(false);
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        fetchSettingValues();
      }
    }
  };

  // Get "Classifications"
  const getBillingClassificationCodes = async () => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          district_id: selectedBranch?.value,
          customer_id: isCreating?.new
            ? customerSelectedNew?.value
            : selectedCustomer?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedCustomer?.value,
          billing_catalog_code: isCreating?.new
            ? selectedServiceNew?.value
            : selectedService?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedService?.value
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.getBillingClassificationCodes,
          1
        );

        if (dataSets?.data && dataSets?.data[0]?.length > 0) {
          const classifications = dataSets?.data[0]?.map(
            ({ recurring_flag, billing_classification_code, descr }) => ({
              label: descr,
              value: billing_classification_code,
              flag: recurring_flag
            })
          );

          if (isCreating?.new) {
            setNewClassificationList(classifications);
          } else {
            setClassificationList([ALL_SELECTION, ...classifications]);
          }
        }
      } catch (error) {
        setBillCycleList([]);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        getCatalogItems();
      }
    }
  };

  // check pahse to disable buttons
  const checkBillRunPhaseL1 = async (invoiceID) => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          district_id: selectedBranch?.value,
          bill_cycle_code: selectedBillCycle?.code,
          bill_date: billDateGlobal
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.checkBillRunPhaseL1,
          1
        );

        // If Billing Cycle Id == Hold OTO Invoice Id (4)
        if (invoiceID === billCycleInvoiceId.hOto) {
          setIsNewBtnDisabled(false);
        }

        if (dataSets && dataSets?.data && dataSets?.data[0]?.length > 0) {
          setPhasesStatus(dataSets?.data[0]);

          // If Billing Cycle Id == OTO Invoice Id (3)
          if (invoiceID === billCycleInvoiceId.oto) {
            const phase6 = dataSets?.data[0]?.filter(
              (phase) => phase?.billing_phase_id === billingRunStatusIds.sixth
            );
            if (phase6) {
              const phase6Completion =
                phase6[0]?.completed_flag === N_CHECKER ? false : true;

              if (!phase6Completion) {
                setDisableAll(false);
                setIsNewBtnDisabled(false);
              } else {
                setDisableAll(true);
                setIsNewBtnDisabled(true);
              }
            }

            // If Billing Cycle == EOM Invoice Id (1)
          } else if (invoiceID === billCycleInvoiceId.eom) {
            const phase5 = dataSets?.data[0]?.filter(
              (phase) => phase?.billing_phase_id === billingRunStatusIds.fifth
            );
            if (phase5) {
              const phase5Completion =
                phase5[0]?.completed_flag === N_CHECKER ? false : true;

              if (!phase5Completion) {
                setDisableAll(false);
                setIsNewBtnDisabled(false);
              } else {
                setDisableAll(true);
                setIsNewBtnDisabled(true);
              }
            }
          } else {
            if (selectedService?.value === VALUE_ALL) {
              setIsNewBtnDisabled(true);
            }
          }
        }
      } catch (error) {
        setBillCycleList([]);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        callGridLoadingCFs(null);
      }
    }
  };

  const getCatalogItems = async () => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          district_id: selectedBranch?.value,
          customer_id: isCreating?.new
            ? customerSelectedNew?.value
            : selectedCustomer?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedCustomer?.value,
          billing_catalog_code: isCreating?.new
            ? selectedServiceNew?.value
            : selectedService?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedService?.value,
          billing_classification_code: isCreating?.new
            ? selectedClassificationNew?.value
            : selectedClassification?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedClassification?.value
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.getCustomerContractCatalogItems,
          1
        );

        const obj = dataSets?.data;

        if (obj && obj[0]?.length > 0) {
          const catalogItems = obj[0]?.map(
            ({ flat_fee_flag, invoice_line_descr, catalog_item_code }) => ({
              label: invoice_line_descr,
              value: catalog_item_code,
              flag: flat_fee_flag
            })
          );

          if (isCreating?.new) {
            setCatalogItemsListNew((prev) => catalogItems);
          } else {
            setCatalogItemsList([ALL_SELECTION, ...catalogItems]);
          }
        }
      } catch (error) {
        setBillCycleList([]);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Billing Cycle == EOM Invoice Id (1)
  const billTransL1ByDistrict = async (isModal) => {
    setIsLoading(true);

    if (!firstLoad) {
      try {
        const reqBody = {
          main_district_id: mainDistrictId,
          district_id: selectedBranch?.value,
          bill_cycle_code: selectedBillCycle?.code,
          bill_catalog_code:
            selectedService?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedService?.value,
          cust_parent_id: VALUE_EMPTY_STRING, // when searching we don't have a parent id
          cust_id: isModal
            ? isModal
            : selectedCustomer?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedCustomer?.value,
          bill_class_code:
            selectedClassification?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedClassification?.value,
          catalog_item_code:
            selectedCatalogItem?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedCatalogItem?.value,
          manual_sort: selectedSortBy?.value,
          counted_item_flag: isChecked ? VALUE_Y : VALUE_EMPTY_STRING
        };
        setPrintRequestBody(reqBody);

        const dataSets = await getResponseData(
          JSON.stringify(reqBody),
          CF_URLS.billingTransactions.billTransL1ByDistrict,
          1
        );

        const obj = dataSets?.data;

        if (isModal) {
          if (obj && obj[0]?.length > 0) {
            const filteredItems = obj[0]?.filter(
              (item) =>
                item?.transaction_type_code?.trim() === transactionTypes.adj
            );

            if (filteredItems?.length > 0) {
              const sum = filteredItems?.reduce(
                (sum, item) => sum + Number(item?.current_quantity?.trim()),
                0
              );
              setSumOfAdj(sum);
            } else {
              setSumOfAdj(null);
            }

            const oneAdjHasY = obj[0]?.some(
              (item) =>
                item?.transaction_type_code?.trim() === transactionTypes.adj &&
                item?.print_adjustments_flag?.trim() === Y_CHECKER
            );

            setIsOneYAdj(oneAdjHasY);
          }
        } else {
          setSumOfAdj(null);
          setIsOneYAdj(false);

          if (obj && obj[0]?.length > 0) {
            const compareFunc = (a, b) =>
              parseInt(a.order_by, 10) - parseInt(b.order_by, 10);

            const sortedList = await asyncSort(obj[0], compareFunc);

            setTableData((prev) => sortedList);

            setNoResults(false);
          } else {
            setTableData((prev) => []);
            setNoResults(true);
          }
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Billing Cycle Id == OTO Invoice Id (3)
  const billTransL2InBranchDB = async (isModal) => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = {
          main_district_id: mainDistrictId,
          district_id: selectedBranch?.value,
          bill_cycle_code: selectedBillCycle?.code,
          bill_catalog_code:
            selectedService?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedService?.value,
          cust_parent_id: VALUE_EMPTY_STRING,
          cust_id: isModal
            ? isModal
            : selectedCustomer?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedCustomer?.value,
          bill_class_code:
            selectedClassification?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedClassification?.value,
          catalog_item_code:
            selectedCatalogItem?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedCatalogItem?.value,
          manual_sort: selectedSortBy?.value,
          oto_type: VALUE_EMPTY_STRING
        };
        setPrintRequestBody(reqBody);

        const dataSets = await getResponseData(
          JSON.stringify(reqBody),
          CF_URLS.billingTransactions.executeBillTransL2InBranchDB,
          1
        );

        const obj = dataSets?.data;

        if (isModal) {
          if (obj && obj[0]?.length > 0) {
            try {
              const sum = obj[0]
                ?.filter(
                  (item) =>
                    item?.transaction_type_code?.trim() === transactionTypes.adj
                )
                ?.reduce(
                  (sum, item) => sum + Number(item?.current_quantity?.trim())
                );
              setSumOfAdj(sum);
            } catch (error) {
              setSumOfAdj(null);
            }
          }
        } else {
          setSumOfAdj(null);

          if (obj && obj[0]?.length > 0) {
            const compareFunc = (a, b) =>
              parseInt(a.order_by, 10) - parseInt(b.order_by, 10);

            const sortedList = await asyncSort(obj[0], compareFunc);

            setTableData((prev) => sortedList);

            setNoResults(false);
          } else {
            setTableData([]);
            setNoResults(true);
          }
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // back to billing status
  const onClickBack = () => {
    let url = `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_BILLING_BASE_PATH}/${BILLING_STATUS}/${
      selectedBranch?.value
    }/${selectedBillCycle?.code?.trim()}/${dayjs(billDate).format(
      dateFormat?.universalFormat
    )}`;
    navigate(url);
  };

  // Billing Cycle Id == Hold OTO Invoice Id (4)
  const interfaceTransactionInBranchDB = async (isModal) => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = {
          main_district_id: mainDistrictId,
          district_id: selectedBranch?.value,
          bill_cycle_code: selectedBillCycle?.code,
          bill_catalog_code:
            selectedService?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedService?.value,
          cust_parent_id: VALUE_EMPTY_STRING,
          cust_id: isModal
            ? isModal
            : selectedCustomer?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedCustomer?.value,
          bill_class_code:
            selectedClassification?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedClassification?.value,
          catalog_item_code:
            selectedCatalogItem?.value === VALUE_ALL
              ? VALUE_EMPTY_STRING
              : selectedCatalogItem?.value,
          manual_sort: selectedSortBy?.value,
          oto_type: VALUE_EMPTY_STRING,
          billing_date: billDateGlobal
        };
        setPrintRequestBody(reqBody);

        const dataSets = await getResponseData(
          JSON.stringify(reqBody),
          CF_URLS.billingTransactions.executeInterfaceTransactionInBranchDB,
          1
        );

        const obj = dataSets?.data;

        if (isModal) {
          if (obj && obj[0]?.length > 0) {
            try {
              const sum = obj[0]
                ?.filter(
                  (item) =>
                    item?.transaction_type_code?.trim() === transactionTypes.adj
                )
                ?.reduce(
                  (sum, item) => sum + Number(item?.current_quantity?.trim())
                );

              setSumOfAdj(sum);
            } catch (error) {
              setSumOfAdj(null);
            }
          }
        } else {
          setSumOfAdj(null);

          if (obj && obj[0]?.length > 0) {
            const compareFunc = (a, b) =>
              parseInt(a.order_by, 10) - parseInt(b.order_by, 10);

            const sortedList = await asyncSort(obj[0], compareFunc);

            setTableData((prev) => sortedList);

            setNoResults(false);
          } else {
            setTableData([]);
            setNoResults(true);
          }
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Load customers
  const getCustomers = async () => {
    setIsLoading(true);
    try {
      const districtId = selectedBranch?.value;

      let result = await getCustomersQueryData(districtId);

      //Filter customers
      result = customerFilter(result)?.filter(
        (cus) =>
          cus.billing_cycle_code?.trim() === billingCycleCodeCheckEOM?.trim()
      );

      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id,
          number: number
        }));

        setCustomerList((prev) => data);
      } else {
        setCustomerList([]);
        setThrowErrorMessage(ERROR_TYPES.FAILED);
        setThrowError(true);
      }
    } catch (e) {
      setCustomerList([]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // To insert new billing transaction
  const billTransI2ByDistrict = async () => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          bill_cycle_code: selectedBillCycle?.code,
          bill_date: billDateGlobal,
          dist_id: selectedBranch?.value,
          cust_id: isCreating?.new
            ? customerSelectedNew?.value
            : selectedRow?.customer_id?.trim(),
          catalog_item_code: isCreating?.new
            ? selectedCatalogItemNew?.value
            : selectedRow?.catalog_item_code?.trim(),
          bill_catalog_code: isCreating?.new
            ? selectedServiceNew?.value
            : selectedRow?.billing_catalog_code?.trim(),
          bill_trans_type_id: isCreating?.new
            ? transactionTypeIds.man
            : isCreating?.adjust
              ? transactionTypeIds.adj
              : transactionTypeIds.man, // 1 - manual 3 -adj
          logical_vault_id: logicalVault?.lvID || VALUE_EMPTY_STRING,
          quantity: quantity,
          adj_trans_id: isCreating?.adjust
            ? selectedRow?.billing_transaction_id?.trim()
            : VALUE_EMPTY_STRING,
          print_adj_flag: isCreating?.adjust
            ? printAdj
              ? VALUE_Y
              : VALUE_N
            : VALUE_EMPTY_STRING,
          descr: descriptionSection,
          pricing_contract_code: pricingContractCode,
          po_number: valuePO,
          unit_price: isCreating?.adjust
            ? selectedRow?.unit_price?.trim() || VALUE_EMPTY_STRING
            : unitPrice
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.billTransI2ByDistrict,
          1
        );
        if (
          dataSets &&
          dataSets?.data &&
          dataSets?.data[0][0]?.billing_transaction_id
        ) {
          // Show success toast
          setSnackBarMessage({
            msg: t("billingTransactions.transCreatedSuccess"),
            severity: "success"
          });

          const newRow = {
            billing_transaction_id:
              dataSets?.data[0][0]?.billing_transaction_id,
            customer_number: isCreating?.new
              ? customerSelectedNew?.number
              : selectedRow?.customer_number?.trim(),
            customer_id: isCreating?.new
              ? customerSelectedNew?.value
              : selectedRow?.customer_id?.trim(),
            transaction_type_code: isCreating?.new
              ? transactionTypes.man
              : isCreating?.adjust
                ? transactionTypes.adj
                : transactionTypes.man,
            billing_catalog_code: isCreating?.new
              ? selectedServiceNew?.value
              : selectedRow?.billing_catalog_code?.trim(),
            catalog_item_code: isCreating?.new
              ? selectedCatalogItemNew?.value
              : selectedRow?.catalog_item_code?.trim(),
            bill_trans_descr: descriptionSection,
            lv_code: VALUE_EMPTY_STRING,
            current_quantity: quantity,
            unit_price: isCreating?.adjust
              ? selectedRow?.unit_price?.trim() || VALUE_EMPTY_STRING
              : unitPrice,
            po_number: valuePO,
            adjusted_transaction_id: isCreating?.adjust
              ? selectedRow?.billing_transaction_id?.trim()
              : VALUE_EMPTY_STRING,
            prev_1_quantity: VALUE_EMPTY_STRING,
            prev_2_quantity: VALUE_EMPTY_STRING,
            variance_threshold_pct: VALUE_EMPTY_STRING,
            flat_fee_flag: selectedCatalogItemNew?.flag,
            print_adjustments_flag: isCreating?.adjust
              ? printAdj
                ? VALUE_Y
                : VALUE_N
              : VALUE_EMPTY_STRING,
            message_descr: VALUE_EMPTY_STRING,
            request_type_descr: VALUE_EMPTY_STRING,
            trip_type_descr: VALUE_EMPTY_STRING
          };

          // add this to the grid

          if (isCreating?.new) {
            addRowToTop(newRow);
            setNewlyCreated(newRow || VALUE_EMPTY_STRING);
          } else {
            addRowBelow(selectedIndex, newRow);
          }

          handleCancelClick();
          setOpenSnackbar((prev) => true);
        } else {
          const errorMsg = await getMessageFromCode(messageCodes.value10001);
          setThrowErrorModal(true);
          setThrowErrorMessageModal(errorMsg[0]?.descr);
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const insertInterfaceTransByDistrict = async () => {
    setIsLoading(true);

    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          bill_cycle_code: selectedBillCycle?.code,
          bill_date: billDateGlobal,
          dist_id: selectedBranch?.value,
          cust_id: isCreating?.new
            ? customerSelectedNew?.value
            : selectedRow?.customer_id?.trim(),
          catalog_item_code: isCreating?.new
            ? selectedCatalogItemNew?.value
            : selectedRow?.catalog_item_code?.trim(),
          bill_catalog_code: isCreating?.new
            ? selectedServiceNew?.value
            : selectedRow?.billing_catalog_code?.trim(),
          bill_trans_type_id: isCreating?.new
            ? transactionTypeIds.man
            : isCreating?.adjust
              ? transactionTypeIds.adj
              : transactionTypeIds.man,
          logical_vault_id: logicalVault?.lvID || VALUE_EMPTY_STRING,
          quantity: quantity,
          adj_trans_id: isCreating?.adjust
            ? selectedRow?.billing_transaction_id?.trim()
            : VALUE_EMPTY_STRING,
          print_adj_flag: isCreating?.adjust
            ? printAdj
              ? VALUE_Y
              : VALUE_N
            : VALUE_EMPTY_STRING,
          descr: descriptionSection,
          pricing_contract_code: pricingContractCode || VALUE_EMPTY_STRING,
          unit_price: isCreating?.adjust
            ? selectedRow?.unit_price?.trim()
            : unitPrice
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.insertInterfaceTransByDistrict,
          1
        );

        if (
          dataSets &&
          dataSets?.data &&
          dataSets?.data[0][0] &&
          dataSets?.data[0][0]?.interface_trans_id
        ) {
          // Show success toast
          setSnackBarMessage({
            msg: t("billingTransactions.transCreatedSuccess"),
            severity: "success"
          });

          const newRow = {
            billing_transaction_id: dataSets?.data[0][0]?.interface_trans_id,
            customer_number: isCreating?.new
              ? customerSelectedNew?.number
              : selectedRow?.customer_number?.trim(),
            customer_id: isCreating?.new
              ? customerSelectedNew?.value
              : selectedRow?.customer_id?.trim(),
            transaction_type_code:
              ((isCreating?.new || isCreating?.repeat) &&
                transactionTypes.man) ||
              (isCreating?.adjust && transactionTypes?.adj) ||
              (isUpdating && selectedRow?.transaction_type_code?.trim()),
            billing_catalog_code: isCreating?.new
              ? selectedServiceNew?.value
              : selectedRow?.billing_catalog_code?.trim(),
            catalog_item_code: isCreating?.new
              ? selectedCatalogItemNew?.value
              : selectedRow?.catalog_item_code?.trim(),
            bill_trans_descr: descriptionSection,
            adjusted_transaction_id: isCreating?.adjust
              ? selectedRow?.billing_transaction_id?.trim()
              : VALUE_EMPTY_STRING,
            lv_code: logicalVault?.lvID,
            current_quantity: quantity,
            unit_price: isCreating?.adjust
              ? selectedRow?.unit_price?.trim()
              : unitPrice,
            po_number: valuePO,
            prev_1_quantity: VALUE_EMPTY_STRING,
            prev_2_quantity: VALUE_EMPTY_STRING,
            variance_threshold_pct: VALUE_EMPTY_STRING,
            flat_fee_flag: isCreating?.new
              ? selectedCatalogItemNew?.flag
              : selectedRow?.flat_fee_flag,
            print_adjustments_flag: isCreating?.adjust
              ? printAdj
                ? VALUE_Y
                : VALUE_N
              : VALUE_EMPTY_STRING,
            message_descr: VALUE_EMPTY_STRING,
            request_type_descr: VALUE_EMPTY_STRING,
            trip_type_descr: VALUE_EMPTY_STRING
          };

          // add this to the grid
          if (isCreating?.new) {
            addRowToTop(newRow);
            setNewlyCreated(newRow || VALUE_EMPTY_STRING);
          } else {
            addRowBelow(selectedIndex, newRow);
          }

          handleCancelClick();
          setOpenSnackbar((prev) => true);
        } else {
          const errorMsg = await getMessageFromCode(messageCodes.value10001);
          setSnackBarMessage({ msg: errorMsg, severity: "error" });
          setOpenSnackbar((prev) => true);
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // To update billing transaction
  const updateBillingTransactionByDistrict = async () => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          billing_transaction_id: selectedRow?.billing_transaction_id,
          logical_vault_id: selectedRow?.logical_vault_id,
          print_adj_flag:
            selectedRow?.transaction_type_code?.trim() === transactionTypes.adj
              ? printAdj
                ? VALUE_Y
                : VALUE_N
              : VALUE_EMPTY_STRING,
          quantity: quantity,
          descr: descriptionSection,
          district_id: selectedBranch?.value,
          po_number: valuePO,
          unit_price: unitPrice
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.updateBillingTransactionByDistrict,
          2
        );

        if (dataSets && dataSets?.data && dataSets?.data[1][0]?.timestamp) {
          // Show success toast
          setSnackBarMessage({
            msg: t("billingTransactions.transUpdatedSuccess"),
            severity: "success"
          });

          const newRow = {
            billing_transaction_id: selectedRow?.billing_transaction_id?.trim(),
            customer_number: selectedRow?.customer_number?.trim(),
            customer_id: selectedRow?.customer_id?.trim(),
            transaction_type_code: selectedRow?.transaction_type_code?.trim(),
            billing_catalog_code: selectedRow?.billing_catalog_code,
            catalog_item_code: selectedRow?.catalog_item_code,
            bill_trans_descr: descriptionSection,
            lv_code:
              selectedRow?.logical_vault_code?.trim() || VALUE_EMPTY_STRING,
            current_quantity: quantity,
            unit_price: unitPrice,
            po_number: valuePO,
            prev_1_quantity: selectedRow?.prev_1_quantity,
            prev_2_quantity: selectedRow?.prev_2_quantity,
            variance_threshold_pct: selectedRow?.variance_threshold_pct,
            flat_fee_flag: selectedRow?.flat_fee_flag,
            print_adjustments_flag:
              selectedRow?.print_adjustments_flag === VALUE_EMPTY_STRING
                ? VALUE_EMPTY_STRING
                : printAdj
                  ? VALUE_Y
                  : VALUE_N,
            message_descr: selectedRow?.message_descr,
            request_type_descr: selectedRow?.request_type_descr,
            trip_type_descr: selectedRow?.trip_type_descr
          };

          // add this to the grid
          const newData = tableData?.map((row, i) => {
            if (i === editingRowIndex) {
              row = { ...row, ...newRow };
            }

            if (
              unitPrice !== selectedRow?.unit_price?.trim() &&
              row?.transaction_type_code?.trim() === transactionTypes.adj
            ) {
              //get all the adjs for this customer and update the unit price
              if (
                row?.adjusted_transaction_id?.trim() ===
                selectedRow?.billing_transaction_id?.trim()
              ) {
                row = { ...row, unit_price: unitPrice };
              }
            }

            if (
              valuePO !== selectedRow?.po_number?.trim() &&
              row?.transaction_type_code?.trim() === transactionTypes.adj
            ) {
              //get all the adjs for this customer and update the unit price
              if (
                row?.adjusted_transaction_id?.trim() ===
                selectedRow?.billing_transaction_id?.trim()
              ) {
                row = { ...row, po_number: valuePO };
              }
            }

            return row;
          });

          setTableData(newData);

          handleCancelClick();
          setOpenSnackbar((prev) => true);
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const execInterfaceTransUpdateByDistrict = async () => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          interface_trans_id: selectedRow?.billing_transaction_id,
          logical_vault_id: selectedRow?.logical_vault_id,
          print_adj_flag:
            selectedRow?.transaction_type_code?.trim() === transactionTypes.adj
              ? printAdj
                ? VALUE_Y
                : VALUE_N
              : VALUE_EMPTY_STRING, // can update using modal
          quantity: quantity, // can update using the modal
          descr: descriptionSection, // can update using the modal
          district_id: selectedBranch?.value,
          unit_price: unitPrice
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.execInterfaceTransUpdateByDistrict,
          2
        );

        if (dataSets && dataSets?.data && dataSets?.data[1][0]?.timestamp) {
          // Show success toast
          setSnackBarMessage({
            msg: t("billingTransactions.transUpdatedSuccess"),
            severity: "success"
          });

          const newRow = {
            billing_transaction_id: selectedRow?.billing_transaction_id?.trim(),
            customer_number: selectedRow?.customer_number?.trim(),
            customer_id: selectedRow?.customer_id?.trim(),
            transaction_type_code: selectedRow?.transaction_type_code?.trim(),
            billing_catalog_code: selectedRow?.billing_catalog_code,
            catalog_item_code: selectedRow?.catalog_item_code,
            bill_trans_descr: descriptionSection,
            lv_code: selectedRow?.lv_code?.trim() || VALUE_EMPTY_STRING,
            current_quantity: quantity,
            unit_price: unitPrice,
            po_number: valuePO,
            prev_1_quantity: selectedRow?.prev_1_quantity,
            prev_2_quantity: selectedRow?.prev_2_quantity,
            variance_threshold_pct: selectedRow?.variance_threshold_pct,
            flat_fee_flag: selectedRow?.flat_fee_flag,
            print_adjustments_flag:
              selectedRow?.print_adjustments_flag === VALUE_EMPTY_STRING
                ? VALUE_EMPTY_STRING
                : printAdj
                  ? VALUE_Y
                  : VALUE_N,
            message_descr: selectedRow?.message_descr,
            request_type_descr: selectedRow?.request_type_descr,
            trip_type_descr: selectedRow?.trip_type_descr
          };

          // add this to the grid
          const newData = tableData?.map((row, i) => {
            if (i === editingRowIndex) {
              row = { ...row, ...newRow };
            }

            if (
              unitPrice !== selectedRow?.unit_price?.trim() &&
              row?.transaction_type_code?.trim() === transactionTypes.adj
            ) {
              //get all the adjs for this customer and update the unit price
              if (
                row?.adjusted_transaction_id?.trim() ===
                selectedRow?.billing_transaction_id?.trim()
              ) {
                row = { ...row, unit_price: unitPrice };
              }
            }

            if (
              valuePO !== selectedRow?.po_number?.trim() &&
              row?.transaction_type_code?.trim() === transactionTypes.adj
            ) {
              //get all the adjs for this customer and update the unit price
              if (
                row?.adjusted_transaction_id?.trim() ===
                selectedRow?.billing_transaction_id?.trim()
              ) {
                row = { ...row, po_number: valuePO };
              }
            }

            return row;
          });

          setTableData(newData);

          handleCancelClick();
          setOpenSnackbar((prev) => true);
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Get the pricing contract Code
  const getPricingContractBasedOnCycle = async () => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          billing_cycle_code: selectedBillCycle?.code,
          district_id: selectedBranch?.value,
          customer_id: isCreating?.new
            ? customerSelectedNew?.value
            : selectedRow?.customer_id?.trim(),
          billing_catalog_code: isCreating?.new
            ? selectedServiceNew?.value
            : selectedRow?.billing_catalog_code,
          catalog_item_code: isCreating?.new
            ? selectedCatalogItemNew?.value
            : selectedRow?.catalog_item_code?.trim()
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.getPricingContractBasedOnCycle,
          1
        );

        if (dataSets && dataSets?.data) {
          const code = dataSets?.data[0][0]?.pricing_contract_code;

          setPricingContractCode(code);
        } else {
          setPricingContractCode(VALUE_EMPTY_STRING);
        }

        if (!dataSets?.data) {
          // If no data found
          // Show Message 20359 passing “This Billing Catalog (service) has no Pricing Contracts. " as a parameter
          const errorMsg = await getMessageFromCode(messageCodes.value20359);
          setSnackBarMessage({ msg: errorMsg, severity: "error" });
          setOpenSnackbar(true);
          // Message - | Please make another selection.
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        getCatalogItemL1();
      }
    }
  };

  // rollback
  const rollbackBillingByDistricDatePhaseCycle = async () => {
    setIsLoading(true);
    if (!firstLoad) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: mainDistrictId,
          bill_cycle_code: selectedBillCycle?.code,
          district_id: selectedBranch?.value,
          billing_date: billDateGlobal,
          billing_phase_id: billingRunStatusIds.third
        });

        await getResponseData(
          reqBody,
          CF_URLS.billingTransactions.rollbackBillingByDistricDatePhaseCycle,
          1
        );

        // if response isSuccess and no error codes from the doc
        // Show success toast

        setWarningMessageMain(VALUE_EMPTY_STRING);
        setIsWarningModalVisible(false);
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // get logical vault
  const getCatalogItemL1 = async () => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        district_id: selectedBranch?.value,
        customer_id: isCreating?.new
          ? customerSelectedNew?.value
          : selectedRow?.customer_id,
        billing_catalog_code: isCreating?.new
          ? selectedServiceNew?.value
          : selectedRow?.billing_catalog_code
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.billingTransactions.getCatalogItemL1,
        1
      );

      if (dataSets?.data && dataSets?.data[0]?.length > 0) {
        const data = dataSets?.data[0]
          ?.map(
            ({ logical_vault_id, logical_vault_code, catalog_item_code }) => ({
              lvID: logical_vault_id,
              lvCode: logical_vault_code,
              catalogItemCode: catalog_item_code
            })
          )
          ?.filter(
            (item) =>
              item?.catalogItemCode?.trim() ===
              selectedCatalogItemNew?.value?.trim()
          );

        setLogicalVault(data || VALUE_EMPTY_STRING);
      } else {
        setLogicalVault(VALUE_EMPTY_STRING);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  //  ---------- Handler functions  ----------
  const handleSubmit = async (values, actions) => {
    // Continue with Formik's default form submission logic
    actions.setSubmitting(false);

    setIsFindButtonClicked(true);

    onFindClick();
  };

  const handleSaveClickOnModal = () => {
    if (isCreating?.new || isCreating?.adjust || isCreating?.repeat) {
      // If Billing Cycle Id !== Hold OTO Invoice Id (4)
      if (Number(selectedBillCycle?.value) !== billCycleInvoiceId?.hOto) {
        billTransI2ByDistrict();
      } else {
        insertInterfaceTransByDistrict();
      }
    }

    if (isUpdating) {
      // If Billing Cycle Id !== Hold OTO Invoice Id (4)
      if (Number(selectedBillCycle?.value) !== billCycleInvoiceId.hOto) {
        updateBillingTransactionByDistrict();
      } else {
        execInterfaceTransUpdateByDistrict();
      }
    }
  };

  // "New" button click
  const handleNewButtonClick = async () => {
    setSelectedServiceNew((prev) => servicesList[0] || VALUE_EMPTY_STRING);

    setIsCreating({
      new: true,
      adjust: false,
      repeat: false
    });
  };

  // "Adjustment" button click
  const handleAdjustButtonClick = async () => {
    callGridLoadingCFs(selectedRow?.customer_id?.trim());

    // If Billing Cycle == EOM Invoice Id (1)
    if (Number(selectedBillCycle?.value) === billCycleInvoiceId.eom) {
      const phase5 = phasesStatus?.filter(
        (phase) => phase?.billing_phase_id === billingRunStatusIds.fifth
      );

      if (phase5?.length > 0) {
        const phase5Completion =
          phase5[0]?.completed_flag === N_CHECKER ? false : true;

        if (phase5Completion) {
          const errorMsg = await getMessageFromCode(messageCodes.value20235);

          setSnackBarMessage({ msg: errorMsg, severity: "error" });
          setOpenSnackbar(true);
        } else {
          setSnackBarMessage(VALUE_EMPTY_STRING);
          setOpenSnackbar(false);

          setIsCreating({
            new: false,
            adjust: true,
            repeat: false
          });
        }
      }
    } else {
      setIsCreating({
        new: false,
        adjust: true,
        repeat: false
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const getResponseData = async (reqBody, url, resultSetCount) => {
    try {
      const response = await callToCloudFunction(reqBody, url);

      const dataSets = await getDataFromFirestore(
        response,
        resultSetCount,
        response?.data?.docId
      );

      return dataSets;
    } catch (error) {
      return null;
    }
  };

  // add row below the selected row
  const addRowBelow = (index, row) => {
    // event.stopPropagation()
    const newRow = row;
    const newTableData = [
      ...(tableData?.slice(0, index + 1) || []),
      newRow,
      ...(tableData?.slice(index + 1) || [])
    ];
    setTableData(newTableData);

    setSelectedIndex(index + 1);

    setSelectedRow(row);
  };

  // add row to the top of the table
  const addRowToTop = (row) => {
    const newRow = row;
    const newTableData = [newRow, ...tableData];
    setTableData(newTableData);
    setSelectedIndex(0);
    setSelectedRow(row);
  };

  const mrdForm = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit
  });

  const updateColumnVisibility = (columnId, isVisible) => {
    setTableColumns((currentColumns) =>
      currentColumns.map((column) =>
        column.id === columnId ? { ...column, visible: isVisible } : column
      )
    );
  };

  //  ---------- Child props  ----------
  const searchFilterProps = {
    mrdForm,
    setSelectedRow,
    setDisableAll,
    setSelectedIndex,

    isBranchDisabled,
    branchList,
    selectedBranch,
    setSelectedBranch,

    isBillCycleDisabled,
    billCycleList,
    selectedBillCycle,
    setSelectedBillCycle,

    billDate,

    isServiceDisabled,
    servicesList,
    selectedService,
    setSelectedService,
    setSelectedServiceNew,

    customerList,
    selectedCustomer,
    setSelectedCustomer,
    isCustomerDisabled,

    isClassificationDisabled,
    classificationList,
    selectedClassification,
    setSelectedClassification,

    isCatalogItemDisabled,
    catalogItemsList,
    selectedCatalogItem,
    setSelectedCatalogItem,

    selectedSortBy,
    sortByList,
    setSelectedSortBy,

    isCheckBoxDisabled,
    isChecked,
    setIsChecked,

    isFindBtnDisabled,

    reset,
    setTableData,
    setIsFindButtonClicked,
    setEditingRowIndex,
    setPage,
    setRowsPerPage
  };
  const handleSort = (column) => {
    const key = column?.id;
    const newDirection =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction: newDirection });
    if (column?.id === LogicalVault) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.logical_vault_code?.localeCompare(
          b?.logical_vault_code
        );
        return isAscending ? comparison : -comparison;
      });
      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === customer) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.customer_number?.localeCompare(
          b?.customer_number
        );
        return isAscending ? comparison : -comparison;
      });
      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === TypeHeader) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.transaction_type_code?.localeCompare(
          b?.transaction_type_code
        );
        return isAscending ? comparison : -comparison;
      });
      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === headerService) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.billing_catalog_code?.localeCompare(
          b?.billing_catalog_code
        );
        return isAscending ? comparison : -comparison;
      });
      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === catalogItem) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.catalog_item_code?.localeCompare(
          b?.catalog_item_code
        );
        return isAscending ? comparison : -comparison;
      });
      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === headerDescription) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.bill_trans_descr?.localeCompare(
          b?.bill_trans_descr
        );
        return isAscending ? comparison : -comparison;
      });
      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === headerQuantity) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.current_quantity?.localeCompare(
          b?.current_quantity
        );
        return isAscending ? comparison : -comparison;
      });
      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === priorMonth1) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.prev_1_quantity?.localeCompare(
          b?.prev_1_quantity
        );
        return isAscending ? comparison : -comparison;
      });
      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === priorMonth2) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.prev_2_quantity?.localeCompare(
          b?.prev_2_quantity
        );
        return isAscending ? comparison : -comparison;
      });

      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === variance) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.current_quantity?.localeCompare(
          b?.current_quantity
        );
        return isAscending ? comparison : -comparison;
      });

      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === flatFee) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.flat_fee_flag?.localeCompare(b?.flat_fee_flag);
        return isAscending ? comparison : -comparison;
      });

      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === printAdjHeader) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.print_adjustments_flag?.localeCompare(
          b?.print_adjustments_flag
        );
        return isAscending ? comparison : -comparison;
      });

      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === error) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.message_descr?.localeCompare(b?.message_descr);
        return isAscending ? comparison : -comparison;
      });

      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === requestType) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.request_type_descr?.localeCompare(
          b?.request_type_descr
        );
        return isAscending ? comparison : -comparison;
      });

      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === tripType) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.trip_type_descr?.localeCompare(
          b?.trip_type_descr
        );
        return isAscending ? comparison : -comparison;
      });

      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === unitPriceHeader) {
      const sorted = [...tableData]?.sort((a, b) => {
        const comparison = a?.unit_price?.localeCompare(b?.unit_price);
        return isAscending ? comparison : -comparison;
      });

      setTableData(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    }
  };
  const tableProps = {
    resultSet: tableData,
    setSelectedRow,
    selectedRow,
    isClearClicked,
    setIsClearClicked,
    editingRowIndex,
    setEditingRowIndex,
    noResults,
    setIsCreating,
    setIsUpdating,
    isNewBtnDisabled,
    handleNewButtonClick,
    tableColumns,
    handleAdjustButtonClick,
    newlyCreated,
    unitPrincePrecision,
    isFindButtonClicked,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handlePrintClick,
    disableAll,
    setSelectedIndex,
    handleSort,
    sortConfig
  };
  const modalProps = {
    tableData,
    isCreating,
    isUpdating,
    customers: customerList,
    selectedRow,
    handleCancelClick,
    servicesList,
    selectedService,
    catalogItemsListNew,
    customerSelectedNew,
    setCustomerSelectedNew,
    selectedServiceNew,
    setSelectedServiceNew,
    newClassificationList,
    setSelectedCatalogItemNew,
    selectedCatalogItemNew,
    selectedClassificationNew,
    setSelectedClassificationNew,
    handleSaveClickOnModal,
    quantity,
    setQuantity,
    descriptionSection,
    setDescriptionSection,
    setThrowErrorModal,
    throwErrorModal,
    throwErrorMessageModal,
    setThrowErrorMessageModal,
    showWarningModal,
    setShowWarningModal,
    warningMessageModal,
    setWarningMessageModal,
    getCatalogItems,
    selectedBillCycle,

    modalElementVisible,
    setModalElementVisible,
    modalElementEnable,
    setModalElementEnable,
    newlyCreated,
    unitPrice,
    setUnitPrice,
    valuePO,
    setValuePO,
    printAdj,
    setPrintAdj,
    initialElementValues,
    modalElementValue,
    setModalElementValue,
    elementValues,
    sumOfAdj,
    isOneYAdj,
    unitPrincePrecision,
    setSelectedIndex
  };
  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      {/*Report download modal*/}
      {isPrintModalOpen && (
        <DownloadReportModal
          isVisible={isPrintModalOpen}
          setIsVisible={setIsPrintModalOpen}
          printRequestBody={printRequestBody}
          setIsLoading={setIsLoading}
          throwError={throwError}
          setThrowError={setThrowError}
          throwErrorMessage={throwErrorMessage}
          setThrowErrorMessage={setThrowErrorMessage}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}

      <Snackbar
        anchorOrigin={billingTransactionsStyles.layoutStyles.snackbar}
        open={openSnackbar}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarMessage?.severity || "info"}
          variant="filled"
          sx={billingTransactionsStyles.commonStyles.widthFull}
        >
          {snackBarMessage?.msg || VALUE_EMPTY_STRING}
        </Alert>
      </Snackbar>

      <CommonModal
        open={isWarningModalVisible}
        title={t("common.warningTitle")}
        body={<Typography variant="subtitle1">{warningMessageMain}</Typography>}
        buttons={
          <Stack direction="row" gap={spacing.gap}>
            <Button
              variant="outlined"
              onClick={() => {
                setWarningMessageMain(VALUE_EMPTY_STRING);
                setIsWarningModalVisible(false);
              }}
            >
              {t("common.cancel")}
            </Button>{" "}
            <Button
              variant="contained"
              onClick={rollbackBillingByDistricDatePhaseCycle}
            >
              {t("common.ok")}
            </Button>
          </Stack>
        }
      />

      <Stack
        direction="column"
        gap={spacing.gap}
        marginTop={spacing.margingTop20}
      >
        {/* ---------- Page Title ----------  */}
        <PageTitle variant="h4" title={t("billingTransactions.pageTitle")} />

        {/* Error Alert */}
        {throwError && (
          <Collapse in={throwError}>
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          </Collapse>
        )}

        {/* ---------- Search Filter Section ----------  */}
        <BillingTransactionsSearchFilter {...searchFilterProps} />

        {/* ---------- Table to Display Search Results ----------  */}
        <BillingTransactionsTable {...tableProps} />

        {/* ---------- Modal to Create or Update Transactions ----------  */}
        <BillingTransactionsModal {...modalProps} />

        {valuesFromBillingStatus.branch ? (
          <Stack gap={spacing.gap}>
            <Divider />
            <Box>
              <Button variant="outlined" onClick={() => onClickBack()}>
                {t("common.back")}
              </Button>
            </Box>
          </Stack>
        ) : null}
      </Stack>
    </>
  );
};

export default BillingTransactionsLayout;
