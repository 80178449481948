import {
  Alert,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, DatePickerField } from "components/core";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import { dateFormat, ERROR_TYPES, VALUE_EMPTY_STRING } from "utils/constants";
import {
  day,
  fiveYears,
  month,
  oneMonth,
  twoYears,
  year
} from "utils/constants/reports-module/Reports";
import { findErrorMessage, getAuthenticatedUserBranch } from "utils/helpers";
import { postExpiryData } from "../Services";

const CustomerSheduleExpiration = ({
  isOpen,
  onCloseModal,
  setCommonDownloadModal,
  getCallbackData,
  setReportCFUrl
}) => {
  const { localeByBranch, currentBranch } = useSelector(selectAuth);
  const today = dayjs();
  const minDate = today.subtract(twoYears, year).startOf(day);
  const maxDate = today.add(fiveYears, year).endOf(day);
  const defaultValue = today.add(oneMonth, month).startOf(day);
  const [selectedServiceDate, setSelectedServiceDate] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );
  const handlePrint = async () => {
    const dateString = dayjs(selectedServiceDate).format(
      dateFormat.universalFormat
    );
    getCallbackData({ expriationDate: dateString });
    try {
      setIsLoading(true);
      let res = await postExpiryData(mainDistrictId, dateString);
      if (res) {
        setCommonDownloadModal(true);
        setReportCFUrl(
          CF_URLS.printReports.reports.customerScheduleExpirationReport
        );
        return res;
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDateChange = (date) => {
    setSelectedServiceDate(date);
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}
      <CommonModal
        open={isOpen}
        minWidth={600}
        title={t("reports.customerScheduleExpirationTitle")}
        body={
          <>
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}

            <Typography>
              {t("reports.customerScheduleExpirationdescription")}
            </Typography>
            <br />
            <Grid container>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <DatePickerField
                    name="expiration"
                    label={t("reports.continerInventory.expiration")}
                    value={dayjs(selectedServiceDate)}
                    minDate={minDate}
                    maxDate={maxDate}
                    defaultValue={defaultValue}
                    onChange={handleDateChange}
                    locale={localeByBranch}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={() => onCloseModal(false)}>
              {t("common.close")}
            </Button>
            <Button variant="contained" onClick={handlePrint}>
              {t("common.print")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default CustomerSheduleExpiration;
