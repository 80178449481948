import spacing from "styles/spacing";

export const RescheduleServiceFormStyles = {
  MarginLeft20: {
    marginLeft: spacing.verticalMargin20
  },
  PaddingLeft: {
    PaddingLeft: "0px !important"
  },
  PaddingTop: {
    PaddingTop: "0px !important"
  },
  alertBox: {
    marginBottom: spacing.horizontalMargin20,
    marginTop: spacing.horizontalMargin20
  },
};
