import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { SelectField } from "components/core";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  downloadOptionPdf,
  ERROR_TYPES,
  VALUE_EMPTY_STRING
} from "utils/constants";
import { reportTypes } from "utils/constants/customer-service-information/RouteManagementConstants";
import { findErrorMessage, getUserName, printReport } from "utils/helpers";
import { routeManagementStyles } from "./RouteManagementStyle";

const RoutePrintModal = ({
  isShowPrintModal,
  setIsShowPrintModal,
  routeList,
  dayOfWeekCode,
  mainDistrictId,
  throwError,
  throwErrorMessage,
  setThrowErrorMessage,
  setThrowError,
  setIsLoading
}) => {
  const { auth } = useSelector(selectAuth);
  const { t } = useTranslation();
  const [reportType, setReportType] = useState(reportTypes.day);
  const [isShowDownloadModal, setIsShowDownloadModal] = useState(false);
  const [route, setRoute] = useState(routeList[0].value);
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);
  const [reportCUrl, setReportCFUrl] = useState(VALUE_EMPTY_STRING);

  const handlePrint = () => {
    setThrowError(false);
    setIsShowDownloadModal(true);
    setReportCFUrl(CF_URLS.printReports.customerInformation.routeManagement);
  };

  const handleReportTypeChange = (e) => {
    setThrowError(false);
    setReportType(e.target.value);
  };

  const handleRouteChange = (e) => {
    setThrowError(false);
    setRoute(e.target.value);
  };

  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      const requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        day_of_week_code:
          reportType === reportTypes.day ? dayOfWeekCode : VALUE_EMPTY_STRING,
        route_code: route,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });
      let url = CF_URLS.printReports.customerInformation.routeManagement;
      let reportResponse = await printReport(requestBody, url);
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
      setIsShowDownloadModal(false);
    }
  };

  return (
    <>
      <CommonModal
        open={isShowPrintModal}
        title={t("routeManagement.reportTitle")}
        body={
          <Stack direction="column">
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  sx={routeManagementStyles.errorStyle}
                  icon={<img src={ErrorIcon} alt="error" width="20" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}

            <Grid container spacing={spacing.gap}>
              <Grid id="radio-grid" item>
                <FormControl id="radio-control">
                  <RadioGroup
                    id="radio-group"
                    name="printType"
                    row
                    value={reportType}
                    onChange={(e) => handleReportTypeChange(e)}
                  >
                    <FormControlLabel
                      value={reportTypes.day}
                      control={<Radio />}
                      label={
                        t("routeManagement.by") + " " + t("routeManagement.day")
                      }
                    />
                    <FormControlLabel
                      value={reportTypes.week}
                      control={<Radio />}
                      label={
                        t("routeManagement.by") +
                        " " +
                        t("routeManagement.week")
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={spacing.gap}>
              <Grid item xs={12} sx={routeManagementStyles.selectStyle}>
                <SelectField
                  label={t("routeManagement.route")}
                  options={routeList}
                  value={route}
                  onChange={handleRouteChange}
                />
              </Grid>
            </Grid>
          </Stack>
        }
        buttons={
          <>
            <Button
              id="print-close-btn"
              variant="outlined"
              size="medium"
              type="button"
              onClick={() => setIsShowPrintModal(false)}
            >
              {t("common.close")}
            </Button>
            <Button
              id="print-btn"
              variant="contained"
              size="medium"
              type="submit"
              onClick={() => handlePrint()}
            >
              {t("common.print")}
            </Button>
          </>
        }
      />
      {/* Download Popup */}
      <DownloadPopup
        title={t("routeManagement.routeDetailReport", {
          reportName: reportType
        })}
        showPrintModal={isShowDownloadModal}
        onCancleClick={() => {
          setIsShowDownloadModal(false);
          setReportCFUrl(VALUE_EMPTY_STRING);
        }}
        onDownloadClick={() => handleDownload()}
        setSelectedValue={setSelectedValue}
        cfUrl={reportCUrl}
        setThrowError={setThrowError}
        throwError={throwError}
        throwErrorMessage={throwErrorMessage}
      />
    </>
  );
};

export default RoutePrintModal;
