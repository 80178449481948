import { checkRequestAuthorization } from "services/common";
import {
  REQUEST_NEW_ITEM_TYPE,
  REQUEST_TYPES,
  TXT_BRANCH,
  TXT_None,
  Y_CHECKER
} from "utils/constants";
import { systemIdSixty } from "utils/constants/reports-module/Reports";
import { AUTHORIZATION_BYPASS_PROGRAM_ID } from "utils/constants/request-module/MediaRequestDetailConstants";
import {
  getGlobalAttributeValue,
  validateUserHasProgramId
} from "utils/helpers";

const GLOBAL_ATTRIBUTE_159 = "159";

/**
 * @name isAuthRequired
 * @description Determine if authorization is required for this type of request
 *
 * @param {string} requestType request type id
 * @param {string} requestAction request action id
 * @param {boolean} regularRateSpecial regular rate special checked
 * @returns
 */
export const isAuthRequired = async (
  requestType,
  requestAction,
  regularRateSpecial
) => {
  if (
    Number(requestType) === REQUEST_TYPES.STANDARD_SPECIAL &&
    regularRateSpecial
  ) {
    return false;
  } else {
    const data = await checkRequestAuthorization({
      requestTypeId: requestType,
      requestActionId: requestAction,
      customerId: "",
      personnelId: ""
    });
    const validAuthorizationFlag = data[0]?.valid_authorization_flag;
    return validAuthorizationFlag === Y_CHECKER;
  }
};

/**
 * @name checkAuthorization
 * @description Determine if confirm authorization is required for this type of request, the customer and personnel combination
 *
 * @param {number} customer customer id
 * @param {number} personnel personnel id
 * @param {string} requestType request type id
 * @param {string} requestAction request action id
 * @param {boolean} regularRateSpecial regular rate special checked
 * @returns
 */
export const checkAuthorization = async (
  customer,
  personnel,
  requestType,
  requestAction,
  regularRateSpecial
) => {
  if (
    (Number(requestType) === REQUEST_TYPES.STANDARD_SPECIAL &&
      regularRateSpecial) ||
    (await isAuthBypassPersonnel(personnel))
  ) {
    return true;
  } else {
    const data = await checkRequestAuthorization({
      requestTypeId: requestType,
      requestActionId: requestAction?.toString(),
      customerId: customer,
      personnelId: personnel
    });
    const validAuthorizationFlag = data[0].valid_authorization_flag;
    return validAuthorizationFlag === Y_CHECKER;
  }
};

/**
 * @name isAuthBypassPersonnel
 * @description Determine if authorization is bypassed
 *
 * @param {number} personnel
 * @returns
 */
export const isAuthBypassPersonnel = async (personnel) => {
  let districtId = localStorage.getItem(TXT_BRANCH);
  const globalAttribute = await getGlobalAttributeValue(
    districtId,
    systemIdSixty,
    GLOBAL_ATTRIBUTE_159
  );
  return Number(globalAttribute) === Number(personnel);
};

/**
 * @name isBypassEnabled
 * @description Enable aurthorization bypass panel
 *
 * @param {string} requestType request type id
 * @returns
 */
export const isBypassEnabled = (requestType) =>
  (Number(requestType) === REQUEST_TYPES.ADD_ON ||
    Number(requestType) === REQUEST_TYPES.STANDARD_SPECIAL ||
    Number(requestType) === REQUEST_TYPES.CRITICAL_SPECIAL) &&
  validateUserHasProgramId(AUTHORIZATION_BYPASS_PROGRAM_ID);

/**
 * @name checkConfirmedByFieldIsRequired
 * @description Determine if confirm field is required for this type of request, the customer and personnel combination
 *
 * @param {number} customer customer id
 * @param {number} personnel personnel id
 * @param {string} requestType request type id
 * @param {string} requestStatus request type id
 * @param {string} requestAction request action id
 * @param {boolean} regularRateSpecial regular rate special checked
 * @returns
 */
export const checkConfirmedByFieldIsRequired = async (
  customer,
  personnel,
  requestType,
  requestStatus,
  requestAction,
  regularRateSpecial
) => {
  const checkAuth = await checkAuthorization(
    customer,
    personnel,
    requestType,
    requestAction,
    regularRateSpecial
  );
  const authRequired = await isAuthRequired(
    requestType,
    requestAction,
    regularRateSpecial
  );
  if (checkAuth) {
    return true;
  } else if (requestStatus > 0 && authRequired && personnel === 0) {
    return true;
  } else if (!checkAuth) {
    return true;
  }
  return false;
};

/**
 * @name canExitTabOrChangeIssueType
 * @description A function that accepts the state of the slice and only allow current tab to be changed if it doesn't have data inserted
 *
 * @param {object} state state of the slice
 * @param {object} extraItemsForm extra items form instance
 * @returns
 **/
export const canExitTabOrChangeIssueType = (state, extraItemsForm) => {
  if (state.activeTab === 0) {
    return (
      state.deliverMedia.openMedias.length === 0 &&
      state.deliverMedia.containers.length === 0
    );
  } else if (state.activeTab === 1) {
    if (
      state.issueContainers.requestNewItemType ===
      REQUEST_NEW_ITEM_TYPE.CONTAINER
    ) {
      return (
        state.issueContainers.containers.length === 0 &&
        state.issueContainers.extraInserts.length === 0 &&
        extraItemsForm.isValid
      );
    } else {
      return state.issueContainers.containers.length === 0;
    }
  } else if (state.activeTab === 2) {
    return state.deletableContainers.length === 0;
  } else if (state.activeTab === 3) {
    return !state.otherComment;
  }
  return true;
};

/**
 *  @name sortInsertTypes
 *  @description sort extra inserts with/without "None" value and return the sorted result
 *
 *  @param {array} insertTypes insert types
 *  @param {boolean} withNone with/without "None" value
 *  @returns
 **/
export const sortInsertTypes = (insertTypes, withNone = false) => {
  let insertTypesCopy = insertTypes;
  // start sorting if only the insert types have data
  if (insertTypesCopy.length > 0) {
    // find and assign none value to a variable to later append to the result
    const noneInsertType = insertTypesCopy.find(
      (item) => item.label.trim() === TXT_None
    );

    // filter insert types except none value
    insertTypesCopy = insertTypesCopy.filter(
      (item) => item.label?.trim() !== TXT_None
    );

    // sort insert types
    insertTypesCopy = insertTypesCopy
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));

    if (withNone) {
      // add "None" value at the end of the array
      insertTypesCopy.push(noneInsertType);
    }
  }
  return insertTypesCopy;
};
