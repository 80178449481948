import { t } from "i18next";
import { getMessageFromCode } from "services/api/query";
import {
  falseValue,
  N_CHECKER,
  VALUE_EMPTY_STRING,
  Y_CHECKER
} from "utils/constants";
import {
  customerTypes,
  nodeTypes,
  rootIds,
  slotNames,
  sourceTree
} from "utils/constants/customer-service-information/RouteManagementConstants";
import { getSortedData } from "utils/helpers";

// Unassigned tree formation
export const getUnassignedTreeData = (rawData, customerData) => {
  let tree = [];
  let root = rootIds.unassignedTasks;
  let dataLength = rawData.length;
  let id = root + 1;
  let lookup = {};
  let customerLookup = {};

  for (let i = 0; i < customerData.length; i++) {
    customerLookup[customerData[i]["id"]] = customerData[i];
  }
  // Handle "C"
  for (let i = 0; i < dataLength; i++) {
    let customerId = rawData[i]["customer_id"];
    let customerNumber =
      customerLookup[customerId]?.number.trim() || VALUE_EMPTY_STRING;
    let scheduledId = rawData[i]["customer_schedule_id"];
    if (lookup[scheduledId] === undefined) {
      let node = {
        id: id,
        parent: root,
        droppable: false,
        text: customerNumber,
        type: nodeTypes.customer,
        source: sourceTree.unassigned,
        data: rawData[i]
      };
      lookup[scheduledId] = {
        id: id
      };
      id += 1;
      tree.push(node);
    }
  }

  // Handle "T"
  for (let i = 0; i < dataLength; i++) {
    let scheduledId = rawData[i]["customer_schedule_id"];
    let parentId = lookup[scheduledId].id;

    let nodeObj = {
      parent: parentId,
      droppable: false,
      type: nodeTypes.time,
      source: sourceTree.unassigned,
      data: rawData[i]
    };

    if (rawData[i]["on_call_flag"] === Y_CHECKER) {
      let onCall = t("routeManagement.onCall");
      let node = {
        ...nodeObj,
        id: id,
        text: onCall
      };
      id += 1;
      tree.push(node);
    } else {
      let sw1Text =
        `${t("routeManagement.sw1")} ` +
        `(` +
        getTimeString(rawData[i]["start_time_window_1"]) +
        `-` +
        getTimeString(rawData[i]["end_time_window_1"]) +
        `)`;
      let sw2Text =
        `${t("routeManagement.sw2")} ` +
        `(` +
        getTimeString(rawData[i]["start_time_window_2"]) +
        `-` +
        getTimeString(rawData[i]["end_time_window_2"]) +
        `)`;
      let node1 = {
        ...nodeObj,
        id: id,
        text: sw1Text
      };
      id += 1;
      tree.push(node1);
      let node2 = {
        ...nodeObj,
        id: id,
        text: sw2Text
      };
      id += 1;
      tree.push(node2);
    }
  }
  return tree;
};

// Route tree formation
export const getRouteTreeData = (rawData, customerData) => {
  let tree = [];
  let root = rootIds.currentRouteTasks;
  let dataLength = rawData.length;
  let id = root + 1;
  let lookup = {};
  let customerLookup = {};
  let lastCslIdAddCustomer = 0;

  for (let i = 0; i < customerData.length; i++) {
    customerLookup[customerData[i]["id"]] = customerData[i];
  }

  // Handle "R"
  for (let i = 0; i < dataLength; i++) {
    let routeCode = rawData[i]["route_code"].trim();
    let startTime = rawData[i]["start_time"] || slotNames.onCallTime;
    if (lookup[routeCode] === undefined) {
      let node = {
        id: id,
        parent: root,
        droppable: false,
        text: getCurrentRouteInfo(rawData[i]),
        type: nodeTypes.route,
        source: sourceTree.currentRoute,
        startTime: startTime,
        data: rawData[i]
      };
      lookup[routeCode] = {
        id: id
      };
      let parentId = id;
      id += 1;
      tree.push(node);
      let scheduledDepartTime = rawData[i]["depart_time"];
      let scheduledArriveTime = rawData[i]["arrive_time"];
      let timeArray = getTimeArray(scheduledDepartTime, scheduledArriveTime);
      for (let j = 0; j < timeArray.length; j++) {
        let childNode = {
          id: id,
          parent: parentId,
          droppable: true,
          text: timeArray[j],
          type: nodeTypes.time,
          source: sourceTree.currentRoute,
          startTime: startTime,
          data: rawData[i]
        };
        lookup[routeCode][timeArray[j]] = {
          id: id
        };
        id += 1;
        tree.push(childNode);
      }
    }
  }

  // Handle "L"
  for (let i = 0; i < dataLength; i++) {
    if (rawData[i]["service_time"]) {
      let isCslChild = getIsCslChild(rawData[i], lastCslIdAddCustomer);
      lastCslIdAddCustomer = isCslChild.lastCslIdAddCustomer;
      if (isCslChild.isCslChild) {
        let routeCode = rawData[i]["route_code"].trim();
        let serviceTime =
          rawData[i]["on_call_flag"] === Y_CHECKER ||
          rawData[i]["service_time"] === slotNames.onCallTime
            ? slotNames.onCall
            : getTimeString(rawData[i]["service_time"]);
        let startTime = rawData[i]["start_time"] || slotNames.onCallTime;
        let customerType = customerTypes.parentCustomer;

        let parentId = root;
        if (lookup[routeCode][serviceTime] === undefined) {
          let node = {
            id: id,
            parent: lookup[routeCode].id,
            droppable: true,
            text: serviceTime,
            type: nodeTypes.time,
            source: sourceTree.currentRoute,
            startTime: startTime,
            data: rawData[i],
            isCslChild: isCslChild.isCslChild
          };
          parentId = id;
          lookup[routeCode][serviceTime] = {
            id: id
          };
          id += 1;
          tree.push(node);
        } else {
          parentId = lookup[routeCode][serviceTime].id;
        }

        let cslCustomerId = rawData[i]["csl_customer_id"];
        let customerName =
          customerLookup[cslCustomerId]?.number.trim() || VALUE_EMPTY_STRING;
        let node = {
          id: id,
          parent: parentId,
          droppable: false,
          text: customerName.trim(),
          type: nodeTypes.parentCustomer,
          source: sourceTree.currentRoute,
          startTime: startTime,
          data: rawData[i],
          isCslChild: isCslChild.isCslChild,
          customerType: customerType,
          customerName: customerName.trim()
        };
        if (lookup[routeCode][serviceTime][cslCustomerId] === undefined) {
          lookup[routeCode][serviceTime][cslCustomerId] = {
            id: id
          };
          id += 1;
          tree.push(node);
        }
      }
    }
  }

  // // Handle C
  for (let i = 0; i < dataLength; i++) {
    if (rawData[i]["service_time"]) {
      let routeCode = rawData[i]["route_code"].trim();
      let serviceTime =
        rawData[i]["on_call_flag"] === Y_CHECKER ||
        rawData[i]["service_time"] === slotNames.onCallTime
          ? slotNames.onCall
          : getTimeString(rawData[i]["service_time"]);

      let startTime = rawData[i]["start_time"] || slotNames.onCallTime;
      let customerType = customerTypes.independentCustomer;
      let isCslChild = getIsCslChild(rawData[i], lastCslIdAddCustomer);
      lastCslIdAddCustomer = isCslChild.lastCslIdAddCustomer;

      if (!(rawData[i]["csl_flag"] === Y_CHECKER)) {
        let parentId = root;
        if (lookup[routeCode][serviceTime] === undefined) {
          let node = {
            id: id,
            parent: lookup[routeCode].id,
            droppable: true,
            text: serviceTime,
            type: nodeTypes.time,
            source: sourceTree.currentRoute,
            startTime: startTime,
            data: rawData[i],
            isCslChild: isCslChild.isCslChild
          };
          parentId = id;
          lookup[routeCode][serviceTime] = {
            id: id
          };
          id += 1;
          tree.push(node);
        } else {
          parentId = lookup[routeCode][serviceTime].id;
        }

        let cslCustomerId = rawData[i]["csl_customer_id"];
        let customerId = rawData[i]["customer_id"];
        let customerName =
          customerLookup[customerId]?.number.trim() || VALUE_EMPTY_STRING;
        let onCall =
          rawData[i]["on_call_flag"] === Y_CHECKER
            ? ` [${t("routeManagement.onCall")}]`
            : VALUE_EMPTY_STRING;

        if (lookup[routeCode][serviceTime][cslCustomerId] === undefined) {
          customerType = customerTypes.independentCustomer;
          lookup[routeCode][serviceTime][cslCustomerId] = {
            id: id,
            jsonNodeCustomerType: customerType
          };
        } else {
          parentId = lookup[routeCode][serviceTime][cslCustomerId].id;
          customerType = customerTypes.dependentCustomer;
          lookup[routeCode][serviceTime][cslCustomerId][customerId] = {
            id: id,
            jsonNodeCustomerType: customerType
          };
        }
        let node = {
          id: id,
          parent: parentId,
          droppable: false,
          text: customerName + onCall,
          type: nodeTypes.customer,
          source: sourceTree.currentRoute,
          startTime: startTime,
          data: rawData[i],
          isCslChild: isCslChild.isCslChild,
          customerType: customerType,
          customerName: customerName,
          name: customerLookup[customerId]?.name
        };
        id += 1;
        tree.push(node);
      }
    }
  }
  return tree;
};

export const getRouteList = (routeData) => {
  var unique = [];
  var routeList = [];
  for (let i = 0; i < routeData.length; i++) {
    let code = routeData[i].route_code?.trim();
    if (!unique[code]) {
      routeList.push({ label: code, value: code });
      unique[code] = 1;
    }
  }
  routeList = getSortedData(routeList, "label", "asc");
  return routeList;
};

export const getCurrentRouteInfo = (nodeData) => {
  let transportation = nodeData?.transportation_vendor_name;
  let driverInfo = nodeData?.service_rep_name;
  let driver = driverInfo || t("routeManagement.unassigned");
  let vehicle = nodeData?.vehicle_number;
  let vendor;
  if (transportation) {
    vendor = transportation || t("routeManagement.unassigned");
  } else {
    vendor = vehicle ? driver + " - " + vehicle : driver;
  }
  return `${nodeData?.route_code.trim()} ( ${vendor} )`;
};

export const getTimeString = (time) => {
  let timeInt = parseInt(time);
  let hours = time ? parseInt(timeInt / 100) : falseValue;
  let minutes = time ? timeInt % 100 : falseValue;

  let hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
  let minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return hoursStr + ":" + minutesStr;
};

export const getNearestIntervalTime = (time, interval = 15) => {
  time = parseInt(time);
  let roundOff = (time % 100) % interval;
  return roundOff === 0 ? time : time + (interval - roundOff);
};

export const getTimeArray = (startTime, endTime) => {
  let timeArray = [];
  startTime = getNearestIntervalTime(startTime);
  endTime = getNearestIntervalTime(endTime);
  if (isNaN(endTime)) {
    timeArray.push(getTimeString(startTime));
  }
  if (isNaN(startTime)) {
    timeArray.push(getTimeString(endTime));
  }
  if (startTime === endTime) {
    timeArray.push(getTimeString(startTime));
  }
  for (let i = startTime; i < endTime; i += 15) {
    let minutes = i % 100;
    if (minutes >= 60) {
      i += 25;
    } else {
      timeArray.push(getTimeString(i));
    }
  }
  timeArray.push(slotNames.onCall);
  return timeArray;
};

export const isOutsideTimeWindow = (sourceNode, dropNode) => {
  // Outside time window
  let outsideTimeWindow = false;
  let dropTime = getServiceTime(dropNode?.text);
  let startTime1 = sourceNode?.data.start_time_window_1;
  let endTime1 = sourceNode?.data.end_time_window_1;
  let startTime2 = sourceNode?.data.start_time_window_2;
  let endTime2 = sourceNode?.data.end_time_window_2;
  let outsideWindow1 = `${t("routeManagement.outsideWindow1", {
    startTime1: getTimeString(startTime1),
    endTime1: getTimeString(endTime1)
  })}`;
  let outsideWindow2 = `${t("routeManagement.outsideWindow2", {
    startTime2: getTimeString(startTime2),
    endTime2: getTimeString(endTime2)
  })}`;
  let timeWindow = outsideWindow1 + " " + outsideWindow2;
  if (
    (startTime1 && (dropTime < startTime1 || dropTime > endTime1)) ||
    (startTime2 && (dropTime < startTime2 || dropTime > endTime2))
  ) {
    outsideTimeWindow = true;
    if (sourceNode.source === sourceTree.currentRoute) {
      timeWindow =
        startTime1 && startTime2
          ? timeWindow
          : outsideWindow1 || outsideWindow2;
    }
  }
  return {
    outsideTimeWindow: outsideTimeWindow,
    timeWindow: timeWindow
  };
};

export const getErrorMessage = async (msgCode, fieldName) => {
  const errorMsg = await getMessageFromCode(String(msgCode));
  if (errorMsg) {
    let message = errorMsg[0]?.descr.replace("|", fieldName);
    return message;
  }
};

export const getNode = (tree, nodeId) => {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].id === nodeId) {
      return tree[i];
    }
  }
};

export const hasChild = (tree, nodeId) => {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].parent === nodeId) {
      return true;
    }
  }
  return false;
};

export const getServiceTime = (time) => {
  return time === slotNames.onCall
    ? slotNames.onCallTime
    : time?.replace(":", VALUE_EMPTY_STRING);
};

export const getIsCslChild = (node, lastCslIdAddCustomer) => {
  let isCsl = node.csl_flag === Y_CHECKER;
  let isCslChild = false;
  let lastCslId = lastCslIdAddCustomer;

  if (
    node.customer_id !== node.csl_customer_id &&
    node.csl_flag === N_CHECKER
  ) {
    isCslChild = true;
  }

  if (isCsl) {
    lastCslId = node.csl_customer_id;
  }
  return {
    lastCslIdAddCustomer: lastCslId,
    isCslChild: isCslChild
  };
};

export const getSequenceNumber = (treeData, sourceNode, targetNode) => {
  let sequenceNumber = 1;
  let rootNodeId = targetNode?.id;

  // get the root node
  if (sourceNode.customerType === customerTypes.dependentCustomer) {
    for (let i = 0; i < treeData.length; i++) {
      if (treeData[i].parent === targetNode?.id) {
        if (
          treeData[i].customerType === customerTypes.parentCustomer &&
          sourceNode.customerType === customerTypes.dependentCustomer
        ) {
          if (
            treeData[i].data.csl_customer_id ===
            sourceNode?.data?.csl_customer_id
          ) {
            rootNodeId = treeData[i].id;
            break;
          }
        }
      }
    }
  }

  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].parent === rootNodeId) {
      if (
        treeData[i].customerType === customerTypes.parentCustomer &&
        sourceNode.customerType === customerTypes.parentCustomer
      ) {
        if (
          treeData[i].data.csl_customer_id === sourceNode?.data?.csl_customer_id
        ) {
          return {
            sequenceNumber: treeData[i].data.sequence_number
          };
        }
      } else if (
        treeData[i].customerType === customerTypes.independentCustomer &&
        sourceNode.customerType === customerTypes.independentCustomer
      ) {
        if (treeData[i].data.customer_id === sourceNode?.data?.customer_id) {
          return {
            sequenceNumber: treeData[i].data.sequence_number
          };
        }
      } else if (
        treeData[i].customerType === customerTypes.dependentCustomer &&
        sourceNode.customerType === customerTypes.dependentCustomer
      ) {
        if (treeData[i].data.customer_id === sourceNode?.data?.customer_id) {
          return {
            sequenceNumber: treeData[i].data.sequence_number
          };
        }
      }
      sequenceNumber++;
    }
  }
  return {
    sequenceNumber: sequenceNumber
  };
};

export const getCustomerList = (treeData) => {
  let customerList = [];
  let list = [];
  for (let i = 0; i < treeData.length; i++) {
    if (
      treeData[i].type === nodeTypes.parentCustomer ||
      treeData[i].type === nodeTypes.customer
    ) {
      if (!list.includes(treeData[i].text) && treeData[i].customerName) {
        let customer = treeData[i]?.customerName + " " + treeData[i]?.name;
        customerList.push(customer);
        list.push(treeData[i].text);
      }
    }
  }
  customerList.sort();
  return customerList;
};

export const getOpenNodes = (treeData, nodeText) => {
  let openNodes = [];
  let firstNode = undefined;
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i]?.customerName?.trim() === nodeText.trim()) {
      if (firstNode === undefined) {
        firstNode = treeData[i].id;
      }
      openNodes.push(treeData[i].id);
      // get parent Nodes
      let node = treeData[i];
      while (node.type !== nodeTypes.route) {
        node = getNode(treeData, node.parent);
        openNodes.push(node.id);
      }
    }
  }
  return {
    openNodeList: openNodes,
    scrollToId: firstNode
  };
};

export const getListOfExpandedNodes = (treeData, openNodeIds) => {
  let expandedNodes = [];
  for (let i = 0; i < treeData.length; i++) {
    if (openNodeIds.includes(treeData[i].id)) {
      expandedNodes.push(treeData[i]);
    }
  }
  return expandedNodes;
};

export const getListOfExpandedNodesNewIds = (treeData, openNodes) => {
  const openIds = [];
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].type === nodeTypes.route) {
      for (let j = 0; j < openNodes.length; j++) {
        if (
          openNodes[j].type === nodeTypes.route &&
          treeData[i].data.route_code === openNodes[j].data.route_code
        ) {
          openIds.push(treeData[i].id);
        }
      }
    } else if (treeData[i].type === nodeTypes.time) {
      for (let j = 0; j < openNodes.length; j++) {
        if (
          openNodes[j].type === nodeTypes.time &&
          treeData[i].data.route_code === openNodes[j].data.route_code &&
          treeData[i].text === openNodes[j].text
        ) {
          openIds.push(treeData[i].id);
        }
      }
    } else if (treeData[i].type === nodeTypes.parentCustomer) {
      for (let j = 0; j < openNodes.length; j++) {
        if (
          openNodes[j].type === nodeTypes.parentCustomer &&
          treeData[i].data.route_code === openNodes[j].data.route_code &&
          treeData[i].data.csl_customer_id === openNodes[j].data.csl_customer_id
        ) {
          openIds.push(treeData[i].id);
        }
      }
    } else if (treeData[i].type === nodeTypes.customer) {
      for (let j = 0; j < openNodes.length; j++) {
        if (
          openNodes[j].type === nodeTypes.customer &&
          treeData[i].data.route_code === openNodes[j].data.route_code &&
          treeData[i].data.csl_customer_id ===
            openNodes[j].data.csl_customer_id &&
          treeData[i].data.customer_id === openNodes[j].data.customer_id
        ) {
          openIds.push(treeData[i].id);
        }
      }
    }
  }
  return openIds;
};

export const hasChildWithCustomerId = (tree, nodeId, customerId) => {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].parent === nodeId) {
      if (tree[i]?.data?.customer_id === customerId) {
        return true;
      }
    }
  }
  return false;
};
