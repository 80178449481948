import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stateToPersist: {},
  tableDefaults:{}
};

export const mdrSearchSlice = createSlice({
  name: "mdrSearch",
  initialState,
  reducers: {
    saveBeforeNavigateToMDRequest: (state, { payload }) => {
      state.stateToPersist = payload.state;
      state.tableDefaults = payload.tableDefaults;
    },
    resetMDRequestPersistedData: () => {
      return { ...initialState };
    }
  }
});

export default mdrSearchSlice.reducer;
