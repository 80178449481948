import { Alert, Box, Collapse, Grid, IconButton } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import VaultManagementReports from "components/vault-management-reports/VaultManagementReports";
import dayjs from "dayjs";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  REPORTS_MODULE_BASE_PATH,
  REPORTS_MODULE_VAULT_PATH,
  VAULT_STATUS_REPORT
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import {
  customerAuthorizationId,
  dateFormat,
  ERROR_TYPES,
  SYSTEM_ID_SecureBase,
  VALUE_EMPTY_STRING
} from "utils/constants";
import { fileTypes } from "utils/constants/container-processing/Research";
import {
  dailyBillableActivityCountsSummary,
  detail,
  disasterRecoveryActivityCounts,
  monthlyBillableActivityCounts,
  monthlyManagedContainerContentsCount,
  scanAtCustomerBillingActivityCounts
} from "utils/constants/reports-module/Reports";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getGlobalAttributeValue,
  openMinimizedWindow
} from "utils/helpers";
import rightArrow from "../../assets/images/rightArrowReports.svg";
import CommonReoprtDownloadModal from "./CommonReoprtDownloadModal";
import { BillableActivity } from "./modals/BillableActivity";
import ContianerInventory from "./modals/ContainerInvertory";
import CustomerAuthorizationList from "./modals/CustomerAuthorizationList";
import CustomerSheduleExpiration from "./modals/CustomerSheduleExpiration";
import DistributionListHistory from "./modals/DistributionListHistory";
import EmptyTransportdeficit from "./modals/EmptyTransportdeficit";
import InventoryFileExtract from "./modals/InventoryFileExtract";
import RoadNetData from "./modals/RoadNetData";
import RunArrival from "./modals/RunArrival";
import TemporaryTransportHistory from "./modals/TemporaryTransportHistory";
import UploadErrorsHistory from "./modals/UploadErrorsHistory";
import { ReportsStyles } from "./ReportsStyles";
import { getHolidayService } from "./Services";

const ReportsMain = () => {
  const reportItems = t("reports.reportItems", { returnObjects: true });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [callbackStateData, setCallbackStateData] = useState({});
  const [commonDownloadModal, setCommonDownloadModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [roadNetDataModal, setRoadNetDataModal] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [throwRoadNetError, setThrowRoadNetError] = useState(false);

  const [customerScheduleExpirationModal, setCustomerScheduleExpirationModal] =
    useState(false);
  const [customerAuthorizationList, setCustomerAuthorizationList] =
    useState(false);
  const [containerInventoryModal, setContainerInverntoryModal] =
    useState(false);
  const [emptyTransportdeficitModal, setEmptyTransportdeficitModal] =
    useState(false);
  const [temepararyTransportModal, setTemepararyTransportModal] =
    useState(false);
  const [createInventoryExtractModal, setCreateInventoryExtractModal] =
    useState(false);
  const [billableActivityCountReport, setbillableActivityCountReport] =
    useState(false);
  const [distributionListHistoryModal, setDistributionListHistoryModal] =
    useState(false);
  const [uploadErrorsModal, setUploadErrorsModal] = useState(false);
  const [runArrivalModal, setRunArrivalModal] = useState(false);
  const [vaultManagementlModal, setVaultManagementlModal] = useState(false);
  const [reportCFUrl, setReportCFUrl] = useState("");

  const { currentBranch } = useSelector(selectAuth);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );
  const openAuthorizationForm = async () => {
    setIsLoading(true);
    const url = await getGlobalAttributeValue(
      mainDistrictId,
      SYSTEM_ID_SecureBase,
      customerAuthorizationId
    );
    setIsLoading(false);
    openMinimizedWindow(url);
  };

  const handleHolidayService = async () => {
    setIsLoading(true);
    try {
      const response = await getHolidayService(mainDistrictId);
      downloadCsv(
        response?.data[0]?.sort((a, b) => a?.number?.localeCompare(b?.number))
      );
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setIsLoading(false);
    }
  };

  const downloadCsv = (data) => {
    const tableHeader = `
    <table border="1">
      <tr>
        <th style="text-align:center;">Customer Number</th><th style="text-align:center;">Preference</th><th style="text-align:center;">Last Modified Date</th><th style="text-align:center;">Last Modified User</th>
      </tr>`;

    const tableRows = data
      .map(
        (item) => `
    <tr>
      <td style="text-align:right;">="${
        item.number
      }"</td><td style="text-align:left;">${
        item.preference
      }</td><td style="text-align:right;">${dayjs(item.last_mod_date).format(
        dateFormat.universalFormat
      )}</td><td style="text-align:left;">${item.last_mod_user}</td>
    </tr>`
      )
      .join("");

    const tableContent = `${tableHeader}${tableRows}</table>`;
    const blob = new Blob([tableContent], { type: fileTypes.csv });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Customer_Data.xls";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleReporstClick = (item, index) => {
    setSelectedReport(item);
    switch (item) {
      case t("reports.customerAuthorizationFormTitle"):
        openAuthorizationForm();
        break;
      case t("reports.customerScheduleExpirationTitle"):
        setReportCFUrl(
          CF_URLS.printReports.reports.customerScheduleExpirationReport
        );
        setCustomerScheduleExpirationModal(true);
        break;
      case t("reports.customerAuthorizationListTitle"):
        setReportCFUrl(
          CF_URLS.printReports.reports.customerAuthorizationListReport
        );
        setCustomerAuthorizationList(true);
        break;
      case t("reports.continerInventoryTitle"):
        setContainerInverntoryModal(true);
        break;
      case t("reports.emptyTransportDeficitTilte"):
        setEmptyTransportdeficitModal(true);
        break;
      case t("reports.temporaryTransportHistoryTitle"):
        setReportCFUrl(
          CF_URLS.printReports.reports.temporyTransportHistoryReport
        );
        setTemepararyTransportModal(true);
        break;
      case t("reports.createInventoryExtractTitle"):
        setCreateInventoryExtractModal(true);
        break;
      case t("reports.billableActivityCounts"):
        if (
          disasterRecoveryActivityCounts ===
          callbackStateData?.breakout_handling
        ) {
          setReportCFUrl(
            CF_URLS.printReports.reports.disasterrecoveryactivitycountsreport
          );
        } else if (
          dailyBillableActivityCountsSummary ===
          callbackStateData?.breakout_handling
        ) {
          setReportCFUrl(
            CF_URLS.printReports.reports.dailybillableactivitycount
          );
        } else if (
          callbackStateData?.breakout_handling ===
          scanAtCustomerBillingActivityCounts
        ) {
          setReportCFUrl(
            CF_URLS.printReports.reports.scanAtCustomerActivityCountsReport
          );
        } else if (
          monthlyBillableActivityCounts === callbackStateData?.breakout_handling
        ) {
          setReportCFUrl(
            CF_URLS.printReports.reports.monthlyBillableActivityCountsReport
          );
        } else if (
          callbackStateData?.breakout_handling ===
          monthlyManagedContainerContentsCount
        ) {
          setReportCFUrl(
            CF_URLS.printReports.reports
              .monthlyManagedContainerContentsCountReport
          );
        }
        setbillableActivityCountReport(true);
        break;
      case t("reports.distributionListHistoryTitle"):
        setReportCFUrl(CF_URLS.printReports.reports.reportDist);
        setDistributionListHistoryModal(true);
        break;
      case t("reports.uploadErrorsHistoryTitle"):
        setUploadErrorsModal(true);
        break;
      case t("reports.runArrivalTitle"):
        setReportCFUrl(CF_URLS.printReports.reports.runArrivalReport);
        setRunArrivalModal(true);
        break;
      case t("reports.vaultStatusReport.title"):
        navigate(
          `${REPORTS_MODULE_BASE_PATH}/${REPORTS_MODULE_VAULT_PATH}/${VAULT_STATUS_REPORT}`
        );
        break;
      case t("reports.vaultManagementTitle"):
        setVaultManagementlModal(true);
        break;
      case t("reports.roadNetDataTitle"):
        setRoadNetDataModal(true);
        break;
      case t("reports.holidayServiceTitle"):
        handleHolidayService();
        break;
      case t("reports.transportLocationTitle"):
        setReportCFUrl(CF_URLS.printReports.reports.reportTransportLocation);
        setCommonDownloadModal(true);
        break;
      case t("reports.containerInventoryTitle"):
        setReportCFUrl(
          callbackStateData?.selectedReports === detail
            ? CF_URLS.printReports.reports.containerInventoryDetailReport
            : CF_URLS.printReports.reports.containerinventorysummaryreport
        );
        break;
      case t("reports.uploadErrorsTitle"):
        setReportCFUrl(CF_URLS.printReports.reports.uploadErrors);
        setCommonDownloadModal(true);
        break;
      case t("reports.inHouseAuthorizationListTitle"):
        setReportCFUrl(CF_URLS.printReports.reports.inHouseAuthorizationList);
        setCommonDownloadModal(true);
        break;
      case t("reports.inHouseAuthorizationUpdateTitle"):
        setReportCFUrl(
          CF_URLS.printReports.reports.inHouseAuthorizationListuReport
        );
        setCommonDownloadModal(true);
        break;
      default:
        setCommonDownloadModal(true);
    }
  };
  const handleCloseModal = () => {
    setCommonDownloadModal(false);
    setThrowError(false);
    setThrowErrorMessage("");
    setReportCFUrl("");
  };
  const getCallbackData = (data) => {
    setCallbackStateData(data);
  };

  return (
    <>
      {isLoading && <FullCircularLoader loading={isLoading} />}
      {throwRoadNetError && (
        <Collapse in={throwError}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => setThrowRoadNetError(false)}
              >
                <GridCloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      {messageSuccess && (
        <SuccessPopup
          message={messageSuccess}
          close={() => {
            setMessageSuccess("");
          }}
        />
      )}
      {customerAuthorizationList && (
        <CustomerAuthorizationList
          isOpen={customerAuthorizationList}
          onCloseModal={setCustomerAuthorizationList}
          setCommonDownloadModal={setCommonDownloadModal}
          getCallbackData={getCallbackData}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}
      {customerScheduleExpirationModal && (
        <CustomerSheduleExpiration
          isOpen={customerScheduleExpirationModal}
          onCloseModal={setCustomerScheduleExpirationModal}
          setCommonDownloadModal={setCommonDownloadModal}
          getCallbackData={getCallbackData}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}
      {containerInventoryModal && (
        <ContianerInventory
          isOpen={containerInventoryModal}
          onCloseModal={setContainerInverntoryModal}
          setCommonDownloadModal={setCommonDownloadModal}
          getCallbackData={getCallbackData}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
          callbackStateData={callbackStateData}
        />
      )}
      {emptyTransportdeficitModal && (
        <EmptyTransportdeficit
          isOpen={emptyTransportdeficitModal}
          onCloseModal={setEmptyTransportdeficitModal}
          setCommonDownloadModal={setCommonDownloadModal}
          getCallbackData={getCallbackData}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}
      {temepararyTransportModal && (
        <TemporaryTransportHistory
          isOpen={temepararyTransportModal}
          onCloseModal={setTemepararyTransportModal}
          setCommonDownloadModal={setCommonDownloadModal}
          getCallbackData={getCallbackData}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}
      {distributionListHistoryModal && (
        <DistributionListHistory
          isOpen={distributionListHistoryModal}
          onCloseModal={setDistributionListHistoryModal}
          setCommonDownloadModal={setCommonDownloadModal}
          getCallbackData={getCallbackData}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}
      {uploadErrorsModal && (
        <UploadErrorsHistory
          isOpen={uploadErrorsModal}
          onCloseModal={setUploadErrorsModal}
          setCommonDownloadModal={setCommonDownloadModal}
          getCallbackData={getCallbackData}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}
      {runArrivalModal && (
        <RunArrival
          isOpen={runArrivalModal}
          onCloseModal={setRunArrivalModal}
          setCommonDownloadModal={setCommonDownloadModal}
          getCallbackData={getCallbackData}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}
      {commonDownloadModal && (
        <CommonReoprtDownloadModal
          title={t("common.downloadReport")}
          showPrintModal={commonDownloadModal}
          setCloseModal={handleCloseModal}
          reportName={selectedReport}
          callbackStateData={callbackStateData}
          throwError={throwError}
          setThrowError={setThrowError}
          throwErrorMessage={throwErrorMessage}
          setThrowErrorMessage={setThrowErrorMessage}
          mainDistrictId={mainDistrictId}
          setIsLoading={setIsLoading}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}
      {createInventoryExtractModal && (
        <InventoryFileExtract
          isOpen={createInventoryExtractModal}
          onCloseModal={setCreateInventoryExtractModal}
          mainDistrictId={mainDistrictId}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}
      {billableActivityCountReport && (
        <BillableActivity
          isOpen={billableActivityCountReport}
          onCloseModal={setbillableActivityCountReport}
          setCommonDownloadModal={setCommonDownloadModal}
          getCallbackData={getCallbackData}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
          callbackStateData={callbackStateData}
          selectedReport={selectedReport}
        />
      )}
      {vaultManagementlModal && (
        <VaultManagementReports
          open={vaultManagementlModal}
          setIsReportsOpen={setVaultManagementlModal}
          fromDashboard={true}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}
      {roadNetDataModal && (
        <RoadNetData
          isOpen={roadNetDataModal}
          onCloseModal={setRoadNetDataModal}
          fromDashboard={true}
          setThrowRoadNetError={setThrowRoadNetError}
          setThrowErrorMessage={setThrowErrorMessage}
          setMessageSuccess={setMessageSuccess}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}
      <Grid container>
        {reportItems &&
          reportItems?.map((item, index) => {
            return (
              <Grid
                item
                md={item.flag ? 12 : 6}
                lg={item.flag ? 12 : 6}
                xs={12}
                sx={
                  !item.flag ? ReportsStyles.listItemsStyle : VALUE_EMPTY_STRING
                }
                key={item.flag}
              >
                <Box
                  sx={
                    item.flag
                      ? ReportsStyles?.reportsTopBorder
                      : VALUE_EMPTY_STRING
                  }
                >
                  <Box sx={ReportsStyles.reportsFlexItems}>
                    {!item.flag ? (
                      <Box
                        sx={ReportsStyles.cursorPointer}
                        onClick={() => handleReporstClick(item?.record, index)}
                      >
                        <img src={rightArrow} alt="rightArrow" />
                      </Box>
                    ) : (
                      VALUE_EMPTY_STRING
                    )}
                    <Box
                      onClick={() => handleReporstClick(item?.record, index)}
                      sx={ReportsStyles.cursorPointer}
                    >
                      {item.record}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default ReportsMain;
