import {
  Alert,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { GridCloseIcon } from "@mui/x-data-grid";
import ChevronLeftFilled from "assets/images/ChevronLeftFilled.svg";
import { CircularLoaderFullPage } from "components/core";
import { NonEditableField } from "components/shared";
import {
  CONTAINER_PROCESSING_RESEARCH,
  CORRECTIVE_ACTION_REQUESTS_SEARCH,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH,
  OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { getGlobalAttributeValueByTypeId } from "services/common";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  GLOBAL_ATTRIBUTE_VALUES,
  N_CHECKER,
  VALUE_EMPTY_STRING,
  Y_CHECKER
} from "utils/constants";
import {
  carMaxRowCount,
  carStatusClosed,
  containerLabelPrintersFilterKey,
  exchangeStatus,
  transactionDetailRowsPerPage
} from "utils/constants/container-processing/Research";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getDateFromUTCFormat,
  getResponseData
} from "utils/helpers";
import { containerResearchStyles } from "../research/ResearchStyle";
import PrintReportModal from "./PrintReportModal";
import RePrintLabelModal from "./RePrintLabelModal";

const ResearchTransactionDetail = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(true); // page loading state
  const [displayCustomerMediaId, setDisplayCustomerMediaId] = useState(true); // show customer_id column in the table
  // alert state
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "warning"
  });
  const [transactionDetails, setTransactionDetails] = useState([]); // transaction details from the route
  // transaction details count
  const transactionDetailsCount = useMemo(
    () => transactionDetails?.length || 0,
    [transactionDetails]
  );
  const [previousRowCount, setPreviousRowCount] = useState(0); // previous row count
  const [transactionDetailsRange, setTransactionDetailsRange] = useState(1); // range of the page
  // container research values from the route
  const containerResearch = useMemo(
    () => location.state?.selectedContainer,
    [location.state?.selectedContainer]
  );
  const [disableMoreButton, setDisableMoreButton] = useState(true); // disable load more button
  const [disableViewCARButton, setDisableViewCARButton] = useState(true); // disable view CAR button
  // disable reprint label button
  const disableReprintLabelButton = useMemo(
    () =>
      Number(location.state?.selectedContainer?.exchange_status_id || 0) ===
        exchangeStatus.tobeIssued ||
      Number(location.state?.selectedContainer?.exchange_status_id || 0) ===
        exchangeStatus.deleted,
    [location.state?.selectedContainer]
  );
  const [slottingContainerFlag, setSlottingContainerFlag] = useState(false); // slotting container flag
  const [showPrintReportModal, setShowPrintReportModal] = useState(false); // print report modal show/hide state
  const [showReprintReportModal, setShowReprintReportModal] = useState(false); // re-print label modal show/hide state
  const [reportCFUrl, setReportCFUrl] = useState("");
  // table options
  const tableOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: transactionDetailsCount > 0,
    responsive: "vertical",
    textLabels: {
      body: {
        noMatch:
          transactionDetailsCount === 0
            ? t("common.noRecordsFound")
            : t("common.tableTextWithoutInputs")
      }
    }
  };

  // table columns
  const columnsDefinition = [
    {
      name: "request_id",
      label: t("containerResearchTranactionDetail.requestIdCaps")
    },
    {
      name: "request_type_descr",
      label: t("containerResearchTranactionDetail.requestTypeCaps")
    },
    {
      name: "dr_event_id",
      label: t("containerResearchTranactionDetail.drEventCaps")
    },
    {
      name: "descr",
      label: t("containerResearchTranactionDetail.systemFunctionCaps")
    },
    {
      name: "run_name",
      label: t("containerResearchTranactionDetail.runCaps")
    },
    {
      name: "exchange_status_type",
      label: t("containerResearchTranactionDetail.statusCaps")
    },
    {
      name: "slotting_location",
      label: t("containerResearchTranactionDetail.locationCaps")
    },
    {
      name: "container_transaction_datetime",
      label: t("containerResearchTranactionDetail.transactionDateCaps"),
      options: {
        customBodyRender: (value) =>
          getDateFromUTCFormat(value, { withTime: true })
      }
    },
    {
      name: "employee_name",
      label: t("containerResearchTranactionDetail.employeeCaps")
    },
    {
      name: "personnel_name",
      label: t("containerResearchTranactionDetail.custPersonnelCaps")
    }
  ];

  // get corrective action requests and enable/disable button
  const getCorrectiveActionRequests = async () => {
    try {
      const requestBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        car_id: "",
        car_type_id: "",
        customer_id: containerResearch?.customer_id?.trim(),
        from_date: "",
        to_date: "",
        failure_point_id: "",
        root_cause_id: "",
        car_status_id: "",
        days_at_status: "",
        max_row_count: String(carMaxRowCount),
        billable_flag: N_CHECKER,
        media_number: containerResearch?.container_number.trim(),
        om_container_flag: containerLabelPrintersFilterKey,
        sort_order: "",
        asc_desc: "",
        ignore_cars_with_unresolved_discrepancies: N_CHECKER,
        show_cars_ready_for_mgmt_review_only_flag: N_CHECKER
      });
      const response = await getResponseData(
        requestBody,
        `${CF_URLS.containerProcessing.returnCorrectiveActionRequests}`,
        2
      );
      const errorCode = Number(response.data[1]?.[0]?.error || 0);
      const unresolvedCar = response?.data[0]?.filter(
        (car) => car.car_status_descr !== carStatusClosed
      );
      if (errorCode !== 0) {
        const errDesc = await getMessageFromCode(String(errorCode));
        setAlert((prevState) => ({
          show: true,
          message: errDesc[0]?.descr,
          severity: "warning"
        }));
      }

      // Enable the View CAR Button
      setDisableViewCARButton(
        !(response && errorCode === 0 && unresolvedCar?.length > 0)
      );
    } catch (error) {
      setAlert((prevState) => ({
        show: true,
        message: findErrorMessage(ERROR_TYPES.ISSUE),
        severity: "error"
      }));
    }
  };

  // fetch and store common district settings
  const fetchCommonDistrictSettings = async () => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch()
    });
    const response = await getResponseData(
      requestBody,
      `${CF_URLS.containerProcessing.getCommonDistrictSettings}`,
      2
    );

    const results = response?.data[0];
    setSlottingContainerFlag(
      (prevState) => results[0]?.slotting_container_flag === Y_CHECKER
    );
  };

  // initialize page with data
  const initializePage = async () => {
    setPageLoading((prevState) => true);
    const responses = await Promise.allSettled([
      // get global_attribute_type 96
      getGlobalAttributeValueByTypeId({
        id: GLOBAL_ATTRIBUTE_VALUES.VALUE_96
      }),
      getCorrectiveActionRequests(),
      fetchCommonDistrictSettings()
    ]);

    if (responses[0]?.value) {
      // set global_attribute_type 96
      const attributeValue = responses[0].value;
      setDisplayCustomerMediaId(
        (prevState) => attributeValue?.trim() === Y_CHECKER
      );
    }

    if (
      location.state?.containerTransactions &&
      location.state?.containerTransactions?.length > 0
    ) {
      // store transaction details in the state
      setTransactionDetails((prevState) =>
        location.state.containerTransactions.sort(
          (a, b) => Number(a.seq_id) - Number(b.seq_id)
        )
      );

      // when there are records less than 120 returned from first call
      setDisableMoreButton(
        (prevState) =>
          location.state.containerTransactions.length <
          transactionDetailRowsPerPage
      );
      setPreviousRowCount((prevState) => 0);
    }

    setPageLoading((prevState) => false);
  };

  // more button onClick event handler
  const handleMoreOnClick = async () => {
    setPageLoading((prevState) => true);
    // Assume there are no more records to retrieve
    if (
      !(
        transactionDetailsCount === previousRowCount ||
        transactionDetailsCount < transactionDetailRowsPerPage
      )
    ) {
      setTransactionDetailsRange((prevState) => prevState++);
      const requestBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        container_id: containerResearch.container_id,
        up2date: VALUE_EMPTY_STRING,
        range: transactionDetailsRange
      });
      const response = await getResponseData(
        requestBody,
        `${CF_URLS.containerProcessing.selectContainerTransactionsInfo}`,
        2
      );

      const results = response.data[0];
      if (results.length > 0) {
        const previousCount = transactionDetailsCount;
        // append new results to the existing results
        setTransactionDetails((prevState) => prevState.concat(results));
        setPreviousRowCount((prevState) => previousCount);
      }

      setDisableMoreButton(
        (prevState) =>
          transactionDetailsCount === previousRowCount ||
          transactionDetailsCount < transactionDetailRowsPerPage
      );

      setPageLoading((prevState) => false);
    }
  };

  // view CAR button onClick event handler
  // pass expected properties to the request search page
  const handleViewCarOnClick = () =>
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_SEARCH}`,
      {
        state: {
          record: {
            customer_id: containerResearch?.customer_id?.trim(),
            container_number: containerResearch?.container_number
          }
        }
      }
    );

  // init page
  useEffect(() => {
    initializePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Full page loader */}
      <CircularLoaderFullPage
        id="research-transaction-detail-full-page-loader"
        loading={pageLoading}
      />

      {/* Details and Data Table */}
      <Stack spacing={4} sx={containerResearchStyles.mainContent}>
        <Box>
          {/* Traction Details */}
          <Grid container rowGap={containerResearchStyles.searchBySection.gap}>
            <Grid
              container
              columnGap={containerResearchStyles.searchBySection.gap}
              py={containerResearchStyles.searchBySection.marginY}
            >
              {/* Customers */}
              <Grid item lg={3}>
                <NonEditableField
                  id="edit-container-research-modal-customer"
                  label={t("containerResearchTranactionDetail.customer")}
                  value={containerResearch?.customer_number}
                />
              </Grid>

              {/* Container Number */}
              <Grid item lg={3}>
                <NonEditableField
                  id="edit-container-research-modal-container-number"
                  label={t("containerResearchTranactionDetail.containerNumber")}
                  value={containerResearch?.container_number}
                />
              </Grid>

              {/* Customer Media Identifier */}
              {displayCustomerMediaId && (
                <Grid item lg={3}>
                  <NonEditableField
                    id="edit-container-research-modal-customer-media-identifier"
                    label={t(
                      "containerResearchTranactionDetail.customerMediaIdentifier"
                    )}
                    value={containerResearch?.customer_media_descr}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Alert section */}
          <Box mb={containerResearchStyles.searchBySection.gap}>
            {alert.show && (
              <Alert
                id="container-research-alert"
                severity={alert.severity}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() =>
                      setAlert((prevState) => ({
                        show: false,
                        message: "",
                        severity: "warning"
                      }))
                    }
                  >
                    <GridCloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {alert.message}
              </Alert>
            )}
          </Box>

          <Card>
            {/* Transaction count and Action Buttons */}
            <Box sx={containerResearchStyles.tableActionSection}>
              {/* results count */}
              <Typography sx={containerResearchStyles.transactionDetailsCount}>
                {transactionDetailsCount === 1
                  ? `${transactionDetailsCount} ${t("common.recordFound")}`
                  : `${transactionDetailsCount} ${t("common.recordsFound")}`}
              </Typography>

              {/* Action Buttons */}
              {transactionDetailsCount > 0 && (
                <Box sx={containerResearchStyles.tableActionButtonsSection}>
                  {/* Print Button */}
                  <Grid sx={containerResearchStyles.tableActionButtons}>
                    <Button
                      type="button"
                      disabled={transactionDetailsCount === 0}
                      variant="outlined"
                      onClick={() => {
                        setReportCFUrl(
                          CF_URLS.printReports.containerProcessing.research
                            .containerTransport
                        );
                        setShowPrintReportModal((prevState) => true);
                      }}
                    >
                      {t("common.print")}
                    </Button>
                  </Grid>

                  {/* More Button */}
                  <Grid sx={containerResearchStyles.tableActionButtons}>
                    <Button
                      type="button"
                      variant="outlined"
                      disabled={disableMoreButton}
                      onClick={handleMoreOnClick}
                    >
                      {t("containerResearchTranactionDetail.more")}
                    </Button>
                  </Grid>

                  {/* View CAR Button */}
                  <Grid sx={containerResearchStyles.tableActionButtons}>
                    <Button
                      type="button"
                      variant="outlined"
                      disabled={disableViewCARButton}
                      onClick={handleViewCarOnClick}
                    >
                      {t("containerResearchTranactionDetail.viewCAR")}
                    </Button>
                  </Grid>

                  {/* Reprint Label Button */}
                  <Grid sx={containerResearchStyles.tableActionButtons}>
                    <Button
                      type="button"
                      disabled={disableReprintLabelButton}
                      variant="outlined"
                      onClick={() =>
                        setShowReprintReportModal((prevState) => true)
                      }
                    >
                      {t("containerResearchTranactionDetail.reprintLabel")}
                    </Button>
                  </Grid>
                </Box>
              )}
            </Box>

            {/* Search Results Table */}
            <MUIDataTable
              id="research-transaction-detail-datatable"
              columns={columnsDefinition}
              options={tableOptions}
              data={transactionDetails}
            />
          </Card>

          <Box
            id="container-research-table-helpers"
            sx={containerResearchStyles.tableLegendSection}
          >
            <Typography sx={containerResearchStyles.tableLegenText}>
              {t("containerResearch.legendOMText")}
            </Typography>
            <Typography sx={containerResearchStyles.tableLegenText}>
              {slottingContainerFlag
                ? t("containerResearch.legendLocationSlotText")
                : t("containerResearch.legendLocationText")}
            </Typography>
          </Box>

          <Divider sx={containerResearchStyles.divider} />

          <Grid>
            {/* Back Button */}
            <Grid sx={containerResearchStyles.tableActionButtons}>
              <Button
                type="button"
                variant="outlined"
                startIcon={<img src={ChevronLeftFilled} alt="back icon" />}
                onClick={() =>
                  navigate(
                    `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${CONTAINER_PROCESSING_RESEARCH}`,
                    { state: { from: location } }
                  )
                }
              >
                {t("common.back")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>

      {/* Print Report Modal */}
      {showPrintReportModal && (
        <PrintReportModal
          open={showPrintReportModal}
          containerId={containerResearch?.container_id}
          customerNumber={containerResearch?.customer_number}
          containerNumber={containerResearch?.container_number}
          mediaType={containerResearch?.descr}
          cmi={containerResearch?.customer_media_descr}
          range={transactionDetailsRange}
          onClose={() => {
            setShowPrintReportModal((prevState) => false);
            setReportCFUrl("");
          }}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}

      {/* Re-print Label Report Modal */}
      {showReprintReportModal && (
        <RePrintLabelModal
          open={showReprintReportModal}
          container={containerResearch}
          includeCMI={displayCustomerMediaId}
          onClose={() => setShowReprintReportModal((prevState) => false)}
        />
      )}
    </>
  );
};

export default ResearchTransactionDetail;
