import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  Stack,
  TableContainer,
  TextField,
  Typography
} from "@mui/material";
import { DEFAULT_LOCALE } from "@mui/x-date-pickers";
import ErrorIcon from "assets/images/warning-2.svg";
import { ContainerProcessingStyles } from "components/container-processing/ContainerProcessingStyles";
import { CircularLoaderFullPage, DatePickerField } from "components/core";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y,
  errorMsgs,
  numberConstants,
  type,
  ymdDateFormat
} from "utils/constants";
import {
  accessCode313,
  currentStatus,
  enterKey,
  processTypes,
  rpType,
  systemFunctionId
} from "utils/constants/container-processing/RunPrepConstants";
import {
  dateTimeFormatByLocale,
  findErrorMessage,
  getResponseData
} from "utils/helpers";
import MissinMediaSnapbar from "./MissinMediaSnapbar";
import DownloadReportModal from "./PrintReportModal";
import RunReportsModal from "./RunReportsModal";
const { default: MUIDataTable } = require("mui-datatables");
const RunPrep = ({
  isUnsavedChanges,
  setIsUnsavedChanges,
  handleChange,
  showWarning,
  setShowWarning,
  warningMessage,
  setWarningMessage,
  tempMemory,
  setTempMemory
}) => {
  // main states
  const { currentBranch, roleAccess, auth, localeByBranch } =
    useSelector(selectAuth);
  const getHasAccess = (code) => roleAccess.includes(code);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] =
    useState(VALUE_EMPTY_STRING);
  const [missingMedia, setMissingMedia] = useState(false);

  const [isFindClicked, setIsFindClicked] = useState(false);

  const autoCompleteRef = useRef(null);
  const autoCompleteDropDownRef = useRef(null);

  // received states
  const [runDates, setRunDates] = useState({
    oldest: Date.now(),
    newest: Date.now()
  });
  const [runList, setRunList] = useState([]);
  const [runStatus, setRunStatus] = useState(VALUE_EMPTY_STRING);
  const [tableData, setTableData] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const [thirdPartyFlag, setThirdPartyFlag] = useState(false);
  const [serviceRepVal, setServiceRepVal] = useState(VALUE_EMPTY_STRING);
  const [runData, setRunData] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const [interactiveSite, setInteractiveSite] = useState(false);
  const [rowCountZeroPrint, setRowCountZeroPrint] = useState(false);

  const [missingMediaList, setMissingMediaList] = useState({
    title: "",
    list: [],
    columns: []
  });

  // filter criteria states
  const [selectedServiceDate, setSelectedServiceDate] = useState(Date.now());
  const [selectedRun, setSelectedRun] = useState(null);

  // run info update states
  const [selectedVehicle, setSelectedVehicle] = useState(VALUE_EMPTY_STRING);
  const [searchedServiceRepList, setSearchedServiceRepList] = useState([]);
  const [typedServiceRep, setTypedServiceRep] = useState(VALUE_EMPTY_STRING);

  // modals
  const [runReportModal, setRunReportModal] = useState(false);
  const [downloadReportModal, setDownloadReportModal] = useState(false);
  const [reportType, setReportType] = useState(rpType.runReport);
  const [reportCFUrl, setReportCFUrl] = useState("");
  // enable states
  const initButtonStates = {
    find: false,
    clear: false,
    save: false,
    print: false
  };

  const initElementStates = {
    vehicle: false,
    serviceRep: false,
    autoComp: false
  };

  const [buttonsEnabled, setButtonsEnabled] = useState(initButtonStates);
  const [elementsEnabled, setElementsEnabled] = useState(initElementStates);

  /**
   * On page load
   *
   * getAllLocations >> getVehicles >> getRunDates >> getLocalDateTime >> returnListOfRuns
   *
   */
  useEffect(() => {
    getVehicles();

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  /**
   * Show unsaved changes warning
   */
  useEffect(() => {
    if (isUnsavedChanges && tempMemory?.data) {
      setShowWarning(true);
    }
    // eslint-disable-next-line
  }, [isUnsavedChanges, tempMemory]);

  const handleClickOutside = (event) => {
    if (
      !autoCompleteRef?.current?.contains(event?.target) &&
      !autoCompleteDropDownRef?.current?.contains(event?.target)
    ) {
      setOpen(false);
    }
  };

  const getVehicles = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.vehicle,
        1
      );

      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0) {
        const sortedVehicles = obj[0]?.sort((a, b) =>
          a?.vehicle_number?.localeCompare(b?.vehicle_number)
        );
        setVehicles(sortedVehicles);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    } finally {
      getRunDates();
    }
  };

  /**
   * Get and Set Min, Max and Selected Dates
   */
  const getRunDates = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.getrundates,
        1
      );

      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        const oldestRunDate = obj[0][0].oldest_run_date?.split("T")[0]; // MinDate
        const newestRunDate = obj[0][0].newest_run_date?.split("T")[0]; // MaxDate
        setRunDates({
          oldest: oldestRunDate,
          newest: newestRunDate
        });

        const localeDateTime = await getLocalDateTime();

        if (localeDateTime) {
          const dtCurrentDate = localeDateTime?.local_getdate?.split("T")[0];

          let serviceDate = newestRunDate;

          if (dtCurrentDate > newestRunDate) {
            serviceDate = newestRunDate;
          } else {
            oldestRunDate > dtCurrentDate
              ? (serviceDate = oldestRunDate)
              : (serviceDate = dtCurrentDate);
          }

          setSelectedServiceDate(serviceDate);

          returnListOfRuns(serviceDate);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading(false);
    }
  };

  const getLocalDateTime = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.getlocaldatetime,
        1
      );

      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        return obj[0][0];
      } else {
        return null;
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      return null;
    }
  };

  /**
   * Get the Run list
   * @param {Object | string} RunDate
   */
  const returnListOfRuns = async (e) => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        run_date: typeof e !== type.string ? e?.format(ymdDateFormat) : e,
        exchange_status_id: VALUE_EMPTY_STRING
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.returnlistofruns,
        1
      );

      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        const sortedRuns = obj[0].sort((a, b) =>
          a.run_name.localeCompare(b.run_name)
        );
        setRunList(sortedRuns);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRunSelect = async (e) => {
    setSelectedRun(e.target.value);
  };

  /**
   * Get the Run(s) that are valid for this operation.
   *
   * Set the runStatus
   */
  const determineExchangeStatusId = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        run_id: selectedRun?.run_id
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.determineexchangestatusid,
        1
      );
      const obj = dataSets?.data;
      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        setRunStatus(obj[0][0]);
        return obj[0][0];
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Validate Run
   */
  const runRule = async (status, isFind) => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        system_function_id: isFind
          ? systemFunctionId?.find
          : systemFunctionId?.print,
        current_exchange_status_id: status?.current_exchange_status_id,
        called_by_sp: VALUE_N
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.runrule,
        1
      );
      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        return obj[0][0];
      } else {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode20342);

        let msg = errDesc[0]?.descr;
        msg = msg.replace(
          "|",
          `${selectedRun?.run_name || VALUE_EMPTY_STRING} - ${
            status?.current_status || VALUE_EMPTY_STRING
          }`
        );

        setThrowErrorMessage(msg);
        setThrowError(true);
        isFind && setRunStatus("");

        return;
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Check unresolved missing discrepencies
   * @param {Object} runList
   */
  const returnUnresolvedMissingDiscrepancies = async (runList) => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        run_id_list: runList,
        system_function_id: systemFunctionId?.find,
        batch_id: VALUE_EMPTY_STRING
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep
          .returnunresolvedmissingdiscrepancies,
        2
      );

      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0) {
        if (obj[1][0]?.error) {
          if (obj[1][0]?.error === errorMsgs.errorCode25313) {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode25313);
            if (errDesc[0]?.descr) {
              setMissingMediaList({
                title: errDesc[0]?.descr,
                list: obj[0],
                columns: [
                  t("containerProcessing.runprep.runLable"),
                  t("containerProcessing.runprep.customer"),
                  t("containerProcessing.runprep.mediaNumLable")
                ]
              });
              setRunStatus("");
              setMissingMedia(true);
              return true;
            }
          }

          if (obj[1][0]?.error === errorMsgs.errorCode10163) {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode10163);
            setThrowErrorMessage(errDesc[0]?.descr);
            setThrowError(true);
          }
        }
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  /**
   * Check for incomplete pick lists
   * @param {string} runIdList
   */
  const validatePickLists = async (runIdList) => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        run_id_list: runIdList
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.validatepicklists,
        1
      );
      const obj = dataSets?.data;
      if (obj && obj[0]?.length > 0) {
        if (obj[0]?.error) {
          if (obj[0]?.error === errorMsgs.errorCode10163) {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode10163);
            setThrowErrorMessage(errDesc[0]?.descr);
            setThrowError(true);
          }

          return;
        }

        if (obj && obj[0]?.length > 0 && obj[0][0]) {
          const replacements = [
            obj[0][0]?.run_name,
            !interactiveSite ? t("common.find") : t("common.report")
          ];
          let idx = 0;

          const errDesc = await getMessageFromCode(errorMsgs.errorCode25314);

          const message = errDesc[0]?.descr?.replace(/\|/g, () => {
            return replacements[idx++] || "|";
          });

          setThrowErrorMessage(message);
          setThrowError(true);
          return;
        }
      } else {
        return 1;
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Set the table data
   * @param {string} runIdList
   */
  const returnContainerListingRunInfo = async (runIdList) => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        run_id_list: runIdList,
        system_function_id: systemFunctionId?.find,
        batch_id: VALUE_EMPTY_STRING
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.returncontainerlistingruninfo,
        1
      );

      const obj = dataSets?.data;
      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        const moddedTableData = obj[0]?.map((item) => ({
          ...item,
          run_date_modded: dateTimeFormatByLocale(
            item?.run_date,
            localeByBranch || DEFAULT_LOCALE
          )?.split(" ")[0]
        }));
        setTableData(moddedTableData);
        setIsFindClicked(true);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Display additional run info
   */
  const retrieveAllRunData = async (status, data) => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        run_id: status?.run_id
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.retrieveallrundata,
        1
      );
      const obj = dataSets?.data;
      setRunData(obj[0][0]);

      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        setIsFindClicked(true);
        let enable = initElementStates;
        setThirdPartyFlag(obj[0][0]?.trans_vendor_flag === VALUE_Y);

        if (obj[0][0]?.trans_vendor_flag === VALUE_N) {
          enable = { ...enable, vehicle: true, serviceRep: true };
        } else {
          enable = initElementStates;
        }

        if (data) {
          if (Number(data?.next_exchange_status_id) >= 9) {
            enable = initElementStates;
          } else {
            enable = { ...initElementStates, vehicle: true, serviceRep: true };
          }
        }

        enable = {
          ...enable,
          autoComp:
            obj[0][0]?.trans_vendor_flag === VALUE_Y &&
            status?.current_status === currentStatus.staged &&
            getHasAccess(accessCode313)
        };

        setElementsEnabled(enable);
        setIsChecked(obj[0][0]?.auto_complete_flag === VALUE_Y);

        if (obj[0][0]?.vehicle_number !== VALUE_EMPTY_STRING) {
          const vehicle = vehicles?.filter(
            (item) => item?.vehicle_number === obj[0][0]?.vehicle_number
          );
          setSelectedVehicle((vehicle && vehicle[0]) || VALUE_EMPTY_STRING);
        } else {
          setSelectedVehicle(VALUE_EMPTY_STRING);
        }

        if (obj[0][0]?.service_rep_employee_id !== VALUE_EMPTY_STRING) {
          await returnEmployeeData(obj[0][0]?.service_rep_employee_id);
        }
      } else {
        setIsFindClicked(false);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Get employee data and set service rep
   * @param {string} empId
   */
  const returnEmployeeData = async (empId) => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        employee_id: empId
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.returnemployeedata,
        1
      );
      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        const empData = obj[0]?.map((item) => ({
          label: item.employee_name,
          value: item.employee_id
        }));

        setServiceRepVal(empData[0]);
        setTypedServiceRep(empData[0]?.label);
        await loadEmployees(empData[0]?.label);
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const addAwarenessOfOtherRequests = async () => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        run_id: selectedRun?.run_id,
        update_printed_flag: VALUE_EMPTY_STRING
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.addawarenessofotherrequests,
        1
      );

      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode25394);

        const msg = obj[0]?.map((obj) => ({
          number: obj?.customer_number,
          run_name: obj?.request_id,
          volser: obj?.comment || VALUE_EMPTY_STRING
        }));

        if (errDesc[0]?.descr) {
          setMissingMediaList({
            title: errDesc[0]?.descr,
            list: msg,
            columns: [
              t("containerProcessing.runprep.customer"),
              t("containerProcessing.runprep.requestLable"),
              ""
            ]
          });

          setMissingMedia(true);
        }
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const loadEmployees = async (val) => {
    if (typedServiceRep !== VALUE_EMPTY_STRING || val) {
      setIsLoading(true);
      try {
        const reqBody = JSON.stringify({
          main_district_id: currentBranch?.district_id,
          name_partial: val ? val : typedServiceRep,
          filter_by_op_center: VALUE_N,
          active_only: VALUE_Y,
          logged_in_employee_id: VALUE_EMPTY_STRING
        });

        const dataSets = await getResponseData(
          reqBody,
          CF_URLS.containerProcessing.runPrep.getemployeedata,
          1
        );
        const obj = dataSets?.data;

        if (obj && obj[0]?.length > 0) {
          const sortedEmps = obj[0]
            ?.filter((item) => item.active_flag === VALUE_Y)
            ?.sort((a, b) => a?.employee_name?.localeCompare(b?.employee_name))
            ?.map((item) => ({
              label: item.employee_name,
              value: item.employee_id
            }));

          setSearchedServiceRepList(sortedEmps || []);
          if (sortedEmps?.length === 1) {
            setTypedServiceRep(sortedEmps[0]?.label);
            setServiceRepVal(sortedEmps[0]);

            setButtonsEnabled((prev) => ({
              ...prev,
              save: !val,
              print: false
            }));
          } else {
            setOpen(true);
          }
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const runprep = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        run_id: selectedRun?.run_id
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.runprep,
        2
      );

      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        if (obj[1][0]?.error !== "0") {
          if (obj[1][0]?.error === errorMsgs.errorCode64113) {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode64113);

            let msg = errDesc[0]?.descr;

            msg = msg.replace(
              "|",
              obj[0][0]?.no_iss_customer_list || VALUE_EMPTY_STRING
            );

            setWarningMessage(msg);
            setShowWarning(true);
            setInteractiveSite(true);
          }

          return;
        } else {
          return true;
        }
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const updateRunRecord = async () => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        run_id: selectedRun?.run_id,
        actual_miles: runData?.actual_miles,
        actual_run_time: runData?.actual_run_time,
        vehicle_type_code: selectedVehicle?.vehicle_type_code,
        vehicle_number: selectedVehicle?.vehicle_number,
        service_rep_employee_id: serviceRepVal?.value,
        auto_complete_flag: isChecked ? VALUE_Y : VALUE_N,
        ts1: runData?.timestamp
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.updaterunrecord,
        1
      );

      const obj = dataSets?.data;

      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        if (
          obj[0][0]?.timestamp &&
          obj[0][0]?.timestamp !== VALUE_EMPTY_STRING
        ) {
          setButtonsEnabled((prev) => ({
            ...prev,
            save: false,
            clear: true,
            print: true
          }));
          setIsUnsavedChanges((prev) => false);
          setTempMemory({ process: null, data: null });
          setShowWarning(false);
          setWarningMessage(VALUE_EMPTY_STRING);
        }
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * The 'Find' process
   *
   * determineExchangeStatusId >> runRule
   * >> returnUnresolvedMissingDiscrepancies >> validatePickLists >>
   * returnContainerListingRunInfo >> retrieveAllRunData >> returnEmployeeData
   * loadEmployees >> addAwarenessOfOtherRequests
   *
   */
  const handleFind = async (e) => {
    clearErrors();

    const status = await determineExchangeStatusId();
    if (status) {
      const data = await runRule(status, true);
      if (!data) {
        return;
      }

      const runIdList = status.run_id?.padStart(10, " ");

      const missingMediaExist =
        await returnUnresolvedMissingDiscrepancies(runIdList);
      const valToProceed = await validatePickLists(runIdList);

      if (valToProceed) {
        await returnContainerListingRunInfo(runIdList);
        await retrieveAllRunData(status, data);
        await addAwarenessOfOtherRequests();

        const printEnabled = !missingMediaExist;
        setButtonsEnabled((prev) => ({
          ...prev,
          clear: true,
          print: printEnabled
        }));
      } else {
        return;
      }
    }

    setOpen(false);
  };

  const onSave = async () => {
    clearErrors();
    if (!thirdPartyFlag) {
      const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
      let msg = errDesc[0]?.descr;

      if (selectedVehicle === VALUE_EMPTY_STRING) {
        msg = msg.replace("|", t("containerProcessing.runprep.vehicle"));

        setThrowErrorMessage(msg);
        setThrowError(true);
        return;
      }

      const serRepSelected = searchedServiceRepList?.find(
        (obj) => obj?.label === typedServiceRep
      );

      if (!serRepSelected) {
        msg = msg.replace("|", t("containerProcessing.runprep.serviecRep"));

        setThrowErrorMessage(msg);
        setThrowError(true);

        return;
      }

      await updateRunRecord();
      return 1;
    } else {
      await updateRunRecord();
      return 1;
    }
  };

  const handlePrint = async () => {
    clearErrors();

    const status = await determineExchangeStatusId();
    if (status) {
      const run = await runRule(status, false);
      if (!run) {
        return;
      }

      if (!isChecked) {
        const proceed = await runprep();

        if (proceed) {
          printProcess(true);
        } else {
          return;
        }
      } else {
        printProcess(true);
      }
    }
  };

  const runPrepUpdateStatus = async () => {
    clearErrors();
    try {
      setIsLoading(true);

      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        run_id: selectedRun?.run_id
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.runPrep.updaterunstatus,
        1
      );

      const obj = dataSets?.data;
      if (obj && obj[0]?.length > 0 && obj[0][0]) {
        if (obj[0][0]?.sql_error !== errorMsgs.noError) {
          setThrowErrorMessage(obj[0][0]?.err_desc);
          setThrowError(true);
        }
      }
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getPerformMediaLifeCycleFunctions = async () => {
    if (runData?.requests_on_run !== numberConstants?.zero) {
      setIsLoading(true);

      try {
        const reqBody = {
          system_function_id: systemFunctionId?.print,
          employee_id: auth?.user?.id,
          main_district_id: currentBranch?.value,
          container: tableData
            ?.filter((item) => item.discrep_descr === VALUE_EMPTY_STRING)
            ?.map((item) => ({
              container_id: item.container_id,
              create_discrepancy_flag: numberConstants?.zero,
              container_ts: item?.timestamp
            })),
          run: [
            {
              run_id: selectedRun?.run_id
            }
          ]
        };

        const dataSets = await getResponseData(
          reqBody,
          `${CF_URLS.containerProcessing.getPerformMediaLifeCycleFunctions}`,
          1
        );

        const obj = dataSets?.data;

        let hasError = false;

        if (obj && obj[0]?.length > 0 && obj[0][0]) {
          if (obj[0][0]?.batch_id === VALUE_EMPTY_STRING) {
            hasError = true;
          } else {
            setIsLoading(false);

            return true;
          }
        } else {
          hasError = true;
        }

        if (hasError) {
          const errDesc = await getMessageFromCode(errorMsgs.errorCode10001);

          setThrowErrorMessage(errDesc[0]?.descr);
          setThrowError(true);
          setIsLoading(false);

          return;
        }
      } catch (error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
        setIsLoading(false);

        return;
      }
    } else {
      return true;
    }
  };

  const runReportPrintProcess = async () => {
    const updateContainerListSpecial =
      await getPerformMediaLifeCycleFunctions();
    setRunReportModal(false);
    let url =
      reportType === rpType.runReport
        ? `${CF_URLS.printReports.containerProcessing.runPrep.getmediarunreport}`
        : `${CF_URLS.printReports.containerProcessing.runPrep.runreport}`;
    setReportCFUrl(url);
    if (updateContainerListSpecial) {
      handleFind();
      setDownloadReportModal(true);
    } else {
      return;
    }
  };

  const printProcess = async (value) => {
    setShowWarning((prev) => false);
    setWarningMessage(VALUE_EMPTY_STRING);

    if (value) {
      const runIdList = runStatus?.run_id?.padStart(10, " ");
      const valToProceed = await validatePickLists(runIdList);
      if (valToProceed) {
        if (tableData?.length === 0) {
          setRowCountZeroPrint(true);
          if (runData?.requests_on_run === "0") {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode63957);

            setWarningMessage(errDesc[0]?.descr);
            setShowWarning(true);
          } else {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode63953);

            setWarningMessage(errDesc[0]?.descr);
            setShowWarning(true);
          }
        } else {
          setRowCountZeroPrint(false);
          setRunReportModal(true);
        }
      } else {
        return;
      }
    }
  };

  const clear = () => {
    setOpen(false);
    setThrowError(false);
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setTempMemory({ process: null, data: null });
    setShowWarning(false);
    setWarningMessage(VALUE_EMPTY_STRING);
    setIsFindClicked(false);
    setRunStatus(VALUE_EMPTY_STRING);
    setTableData([]);
    setThirdPartyFlag(false);
    setServiceRepVal(VALUE_EMPTY_STRING);
    setRunData(null);
    setIsChecked(false);
    setInteractiveSite(false);
    setRowCountZeroPrint(false);

    setSelectedVehicle(VALUE_EMPTY_STRING);
    setSearchedServiceRepList([]);
    setTypedServiceRep(VALUE_EMPTY_STRING);
    setRunReportModal(false);
    setDownloadReportModal(false);
    setReportType(rpType.runReport);
    setButtonsEnabled(initButtonStates);
    setElementsEnabled(initElementStates);
    setReportCFUrl("");
  };

  const handleLastMemory = () => {
    setIsUnsavedChanges((prev) => false);

    clear();

    switch (tempMemory?.process) {
      case processTypes.serviceDate:
        setSelectedRun(null);
        setRunList([]);
        setSelectedServiceDate(tempMemory?.data);
        returnListOfRuns(tempMemory?.data);
        setButtonsEnabled((prev) => ({
          ...initButtonStates,
          clear: true
        }));
        break;
      case processTypes.run:
        setSelectedRun(null);
        handleRunSelect(tempMemory?.data);
        break;
      case processTypes.tab:
        handleChange(1, tempMemory?.data);
        break;
      case processTypes.clear:
        clear();
        setRunList([]);
        setSelectedServiceDate(Date.now());
        setSelectedRun(null);
        getRunDates();

        break;

      default:
        break;
    }

    setTempMemory({ process: null, data: null });
    setShowWarning(false);
    setWarningMessage(VALUE_EMPTY_STRING);
  };

  const handleSaveClick = async () => {
    const proceed = await onSave();
    if (proceed) {
      handleLastMemory();
    }
  };

  const clearErrors = () => {
    setThrowError(false);
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setShowWarning(false);
    setWarningMessage(VALUE_EMPTY_STRING);
    setMissingMediaList({ title: "", list: [], columns: [] });
    setMissingMedia(false);
  };

  const columns = [
    {
      name: "",
      label: ""
    },
    {
      name: "run_name",
      label: t("containerProcessing.runprep.runLable")
    },
    {
      name: "run_date_modded",
      label: t("containerProcessing.runprep.serviceDates")
    },
    {
      name: "cust_number",
      label: t("containerProcessing.runprep.customer")
    },
    {
      name: "short_descr",
      label: t("containerProcessing.runprep.mediaType")
    },
    {
      name: "number",
      label: t("containerProcessing.runprep.cont")
    },
    {
      name: "type",
      label: t("containerProcessing.runprep.status")
    },
    {
      name: "discrep_descr",
      label: t("containerProcessing.runprep.discrepancy")
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: tableData?.length && true,
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="contained"
            size="medium"
            type="button"
            disabled={!buttonsEnabled.print}
            onClick={handlePrint}
          >
            {t("common.print")}
          </Button>
        </Grid>
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          tableData?.length === 0 && isFindClicked
            ? `${t("common.noRecordsFound")}`
            : tableData?.length === 0 && `${t("common.tableTextWithoutInputs")}`
      }
    }
  };

  const runReportModalProps = {
    runReportModal,
    setRunReportModal,
    selectedRun,
    reportType,
    setReportType,
    runReportPrintProcess
  };

  const printReportModalProps = {
    downloadReportModal,
    setDownloadReportModal,
    throwError,
    setThrowError,
    throwErrorMessage,
    setThrowErrorMessage,
    runId: selectedRun?.run_id,
    selectedServiceDate,
    reportType,
    setIsLoading,
    handleFind,
    runPrepUpdateStatus,
    runStatus,
    tableData,
    runData,
    reportCFUrl,
    setReportCFUrl
  };

  const missinMediaSnapbarProps = {
    setMissingMedia,
    missingMediaList
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      {/* Error Alert */}
      {throwError && (
        <Collapse in={throwError} sx={{ mb: 3 }}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}

      {/* Warning message */}
      {showWarning && !rowCountZeroPrint && (
        <ConfirmPopup
          modalPopupOpen={showWarning}
          message={
            !interactiveSite ? t("common.saveChangesNow") : warningMessage
          }
          handleCancel={() => {
            setShowWarning((prev) => false);
            setWarningMessage(VALUE_EMPTY_STRING);
          }}
          handleNo={() =>
            !interactiveSite ? handleLastMemory() : printProcess(false)
          }
          handleYes={() =>
            !interactiveSite ? handleSaveClick() : printProcess(true)
          }
          showCancel={!interactiveSite}
          showNo={true}
        />
      )}

      {/* snapbar */}
      {missingMedia && <MissinMediaSnapbar {...missinMediaSnapbarProps} />}

      {showWarning && rowCountZeroPrint && (
        <ConfirmPopup
          modalPopupOpen={showWarning}
          message={warningMessage}
          handleCancel={() => {
            setShowWarning((prev) => false);
            setWarningMessage(VALUE_EMPTY_STRING);
            setRowCountZeroPrint(false);
            setRunReportModal(false);
          }}
          handleYes={() => {
            setShowWarning((prev) => false);
            setWarningMessage(VALUE_EMPTY_STRING);
            setRowCountZeroPrint(false);

            setRunReportModal(true);
          }}
          showCancel={true}
          showOk={true}
        />
      )}

      <Grid container spacing={2}>
        <Grid item md={6} lg={3} xs={12}>
          {/* Service DatePicker*/}
          <FormControl fullWidth>
            <DatePickerField
              name="serviceDate"
              label={t("containerProcessing.runprep.serviceDate")}
              value={dayjs(selectedServiceDate)}
              minDate={dayjs(runDates.oldest)}
              maxDate={dayjs(runDates.newest)}
              defaultValue={dayjs(selectedServiceDate)}
              onChange={(e) => {
                clearErrors();
                if (isUnsavedChanges) {
                  setTempMemory({ process: processTypes.serviceDate, data: e });
                  setShowWarning(true);
                  setWarningMessage(t("common.saveChangesNow"));
                  return;
                } else {
                  clear();
                  setRunList([]);
                  setButtonsEnabled((prev) => ({
                    ...initButtonStates,
                    clear: true
                  }));
                  setSelectedServiceDate(e);
                  returnListOfRuns(e);
                }
              }}
              locale={localeByBranch}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} lg={3} xs={12}>
          {/* Run Selection */}
          <FormControl fullWidth variant="outlined">
            <>
              <InputLabel>{t("containerProcessing.runprep.run")}</InputLabel>
              <Select
                label={t("containerProcessing.runprep.run")}
                name={"run"}
                onChange={(e) => {
                  clearErrors();
                  if (isUnsavedChanges) {
                    setTempMemory({ process: processTypes.run, data: e });
                    setShowWarning(true);
                    setWarningMessage(t("common.saveChangesNow"));
                    return;
                  } else {
                    clear();
                    setButtonsEnabled((prev) => ({
                      ...initButtonStates,
                      clear: true,
                      find: true
                    }));
                    handleRunSelect(e);
                  }
                }}
                value={selectedRun}
              >
                {runList?.length > 0 &&
                  runList?.map((item) => (
                    <MenuItem value={item} key={item?.run_name}>
                      {item?.run_name}
                    </MenuItem>
                  ))}
              </Select>
            </>
          </FormControl>
        </Grid>
        <Grid item md={6} lg={3} xs={12}>
          {/* Run Status */}
          <FormControl fullWidth variant="outlined">
            <TextField
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              label={t("containerProcessing.runprep.runstatus")}
              fullWidth
              value={runStatus?.current_status || VALUE_EMPTY_STRING}
              InputLabelProps={{ shrink: true, style: { zIndex: 0 } }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Stack direction="row" gap={spacing.gap}>
        <Button
          variant="outlined"
          disabled={!buttonsEnabled.clear}
          onClick={() => {
            clearErrors();
            if (isUnsavedChanges) {
              setTempMemory({ process: processTypes.clear, data: null });
              setShowWarning(true);
              setWarningMessage(t("common.saveChangesNow"));
              return;
            } else {
              clear();
              setRunList([]);
              setSelectedServiceDate(Date.now());
              setSelectedRun(null);
              getRunDates();
            }
          }}
        >
          {t("common.clear")}
        </Button>
        <Button
          variant="contained"
          disabled={!buttonsEnabled.find}
          onClick={handleFind}
        >
          {t("common.find")}
        </Button>
      </Stack>
      <br />
      <Stack>
        <Typography variant="h5">
          {t("containerProcessing.runprep.runInformationTitle")}
        </Typography>
      </Stack>
      <br />
      <Grid container spacing={2} alignItems="">
        <Grid item md={6} lg={3} xs={12}>
          {/* Vehicle Selection */}
          <FormControl fullWidth variant="outlined">
            <>
              <InputLabel>
                {t("containerProcessing.runprep.vehicle")}
              </InputLabel>
              <Select
                label={t("containerProcessing.runprep.vehicle")}
                onChange={(e) => {
                  clearErrors();

                  setIsUnsavedChanges(true);
                  setSelectedVehicle(e?.target?.value);
                  setButtonsEnabled((prev) => ({
                    ...prev,
                    save: true,
                    print: false
                  }));
                }}
                value={selectedVehicle}
                disabled={!elementsEnabled.vehicle}
              >
                {vehicles?.length > 0 &&
                  vehicles?.map((item) => (
                    <MenuItem
                      key={item?.vehicle_number}
                      value={item}
                    >{`${item?.vehicle_number} ${item?.vehicle_type_code}`}</MenuItem>
                  ))}
              </Select>
            </>
          </FormControl>
        </Grid>
        <Grid item md={6} lg={3} xs={12}>
          {/* Service Rep Selection */}
          <FormControl fullWidth variant="outlined">
            <div>
              <Autocomplete
                ref={autoCompleteRef}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                PopperComponent={(props) => (
                  <Popper ref={autoCompleteDropDownRef} {...props} />
                )}
                id="servicerep-list"
                name={t("containerProcessing.runprep.serviecRep")}
                options={searchedServiceRepList}
                size="medium"
                value={typedServiceRep}
                fullWidth
                disabled={!elementsEnabled.serviceRep}
                onChange={(e, value) => {
                  clearErrors();
                  setIsUnsavedChanges(true);
                  setTypedServiceRep(value?.label || VALUE_EMPTY_STRING);
                  setServiceRepVal(value);

                  setButtonsEnabled((prev) => ({
                    ...prev,
                    save: true,
                    print: false
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(event) => {
                      if (event.key === enterKey) {
                        event.preventDefault();
                        clearErrors();
                        loadEmployees();
                        setButtonsEnabled((prev) => ({
                          ...prev,
                          save: true,
                          print: false
                        }));
                      }
                    }}
                    {...params}
                    onChange={(e) => {
                      clearErrors();
                      setButtonsEnabled((prev) => ({
                        ...prev,
                        save: true,
                        print: false
                      }));
                      setIsUnsavedChanges(true);
                      setTypedServiceRep(e?.target?.value);
                    }}
                    label={t("containerProcessing.runprep.serviecRep")}
                  />
                )}
              />
            </div>
          </FormControl>
        </Grid>
        <Grid item md={6} lg={3} xs={12}>
          <Stack sx={ContainerProcessingStyles.formControllabelStart}>
            {/* Auto Complete CheckBox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={(event) => {
                    clearErrors();
                    setIsChecked(event?.target?.checked);
                    setIsUnsavedChanges(true);
                    setButtonsEnabled((prev) => ({
                      ...prev,
                      save: true,
                      print: false
                    }));
                  }}
                  color="primary"
                  disabled={!elementsEnabled.autoComp}
                />
              }
              label={t("containerProcessing.runprep.autoComplete")}
            />
          </Stack>
        </Grid>
      </Grid>
      <br />
      <Paper>
        <TableContainer>
          <MUIDataTable
            columns={columns}
            options={options}
            data={tableData}
            title={
              isFindClicked && (
                <Typography style={ContainerProcessingStyles.titleStyle}>
                  {tableData.length} {t("common.recordsFound")}
                </Typography>
              )
            }
          />
        </TableContainer>
      </Paper>
      <br />
      <Stack direction="row" gap={spacing.gap}>
        <Button
          variant="contained"
          disabled={!buttonsEnabled.save}
          onClick={onSave}
        >
          {t("common.save")}
        </Button>
      </Stack>

      {<RunReportsModal {...runReportModalProps} />}

      {<DownloadReportModal {...printReportModalProps} />}
    </>
  );
};
export default RunPrep;
