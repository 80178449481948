import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { PickersDay, pickersDayClasses } from "@mui/x-date-pickers";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import {
  CircularLoader,
  CircularLoaderFullPage,
  DatePickerField,
  SelectField
} from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useAssignTransportsToCustomerMutation,
  useGetAllCustomersQuery,
  useGetCslInfoMutation,
  useGetCustLocationInfoMutation,
  useGetRequestInfoMutation,
  useGetRouteInfoMutation,
  useGetRunInfoMutation,
  useGetScheduleDateMutation,
  useRescheduleRequestsMutation
} from "services/api";
import { getMessageFromCode } from "services/api/query";
import { getLocaleByBranch } from "services/common";
import { selectAuth } from "store/slices";
import {
  DAYJS_LOCALES,
  DEFAULT_LOCALE,
  EXCHANGE_LOCATION_TYPE_SL,
  NUMBER_9999,
  N_CHECKER,
  ON_CALL,
  SUPPORTED_LANGUAGES,
  Y_CHECKER,
  errorMsgs
} from "utils/constants";
import { zeroChecker } from "utils/constants/container-processing/Distribution";
import { SF_ASSIGN_CODE, SF_REMOVE_CODE } from "utils/constants/request-module/MediaRequestDetailConstants";
import { getAuthenticatedUserBranch } from "utils/helpers";
import PersonnelAuthentication from "../personnel-authentication-form/PersonnelAuthenticationForm";
import ModalPopup from "../reschedule-modalPopup/RescheduleModalPopup";
import { rescheduleSchema } from "../schema";
import { RescheduleServiceFormStyles } from "./RescheduleServiceFormStyles";

const rescheduleInitialValues = {
  newDate: dayjs(),
  originalDate: dayjs(),
  customer: null,
  customerValue: null,
  customerNoRqst: false,
  serviceAtOriginal: "",
  serviceAtNew: "",
  scheduledDateNew: [],
  scheduledDateOriginal: [],
  ModalPopupOpen: false,
  requestId: "",
  cslCustomerId: null,
  routeOptions: [],
  cslNewRequestId: null,
  previousNewDate: "",
  previousOriginalDate: "",
  serviceAtNewDropdownVal: null,
  routeComboBox: false,
  modalButtonLabel: "",
  requestDetail: "",
  rescheduleDate: "",
  personnelId: null,
  transports: [],
  assignTransport: {}
};

// Highlighting the Scheduled Service dates
const ScheduleOriginalDate = (props) => {
  const {
    day,
    outsideCurrentMonth,
    fieldLabel,
    formikValues,
    serviceDate,
    ...other
  } = props;
  const { t } = useTranslation();
  const scheduledDates = handleServiceDateFormat(serviceDate);
  const isSelected =
    !props.outsideCurrentMonth && scheduledDates.includes(dateFormat(day));
  const bgColorScheduledSelectDate =
    fieldLabel === t("rescheduleService.newDate")
      ? dateFormat(day) === dateFormat(formikValues.newDate) && isSelected
      : dateFormat(day) === dateFormat(formikValues.originalDate) &&
      scheduledDates.includes(dateFormat(day));
  const bgColorNonScheduledSelectDate =
    fieldLabel === t("rescheduleService.newDate") &&
    dateFormat(day) === dateFormat(formikValues.newDate) &&
    !isSelected;
  return (
    <Box
      sx={{
        backgroundColor: bgColorScheduledSelectDate && "#F7931E",
        borderRadius: "12px"
      }}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={isSelected}
        sx={{
          [`&&.${pickersDayClasses.selected}`]: {
            backgroundColor: "#8DC13F"
          },
          [`&&.${pickersDayClasses.dayWithMargin}`]: {
            backgroundColor: bgColorNonScheduledSelectDate
              ? "#F7931E"
              : "normal"
          }
        }}
      />
    </Box>
  );
};

// secheduleDate in MM-DD-YYYY Format
const handleServiceDateFormat = (scheduledDates) => {
  const serviceDate = scheduledDates.map((i) => dateFormat(i?.service_date));
  return serviceDate;
};
//handle date format change
const dateFormat = (date) => {
  return dayjs(date).format("MM-DD-YYYY");
};

const ErrorAlert = ({ errors, onClose }) => {
  return (
    <Collapse in={errors.length > 0}>
      {errors.map((error, index) => (
        <Alert
          key={index}
          severity="warning"
          action={
            <IconButton
              aria-label="Close"
              color="inherit"
              size="small"
              onClick={() => onClose(index)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      ))}
    </Collapse>
  );
};
const RescheduleServiceForm = () => {
  const { auth, currentBranch } = useSelector(selectAuth);
  const districtId = getAuthenticatedUserBranch();
  const rescheduleForm = useFormik({
    initialValues: rescheduleInitialValues,
    validationSchema: rescheduleSchema
  });
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);

  const rescheduleFormVal = rescheduleForm.values;

  const minDate = dayjs();
  const maxDate = dayjs(t("requestSearch.maximumDate"));

  const [saveSuccess, setSaveSuccess] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [localeByBranch, setLocaleByBranch] = useState(DEFAULT_LOCALE); // locale of the selected branch
  //warning message showing states
  const [throwWarning, setThrowWarning] = useState(false);
  const [throwWarningMessage, setThrowWarningMessage] = useState(false);

  const { data: customers = [], isLoading: isCustomersLoading } =
    useGetAllCustomersQuery({
      mainDistrictId: currentBranch?.district_id
    });

  const [triggerScheduleDate, { isLoading: isScheduledServiceDatesLoading }] =
    useGetScheduleDateMutation({});
  const [triggerRequestInfo] = useGetRequestInfoMutation({});
  const [triggerRunInfo] = useGetRunInfoMutation({});
  const [triggerRouteInfo] = useGetRouteInfoMutation({});
  const [triggerCslInfo] = useGetCslInfoMutation({});
  const [triggercustomerLocationInfo] = useGetCustLocationInfoMutation({});
  const [saveRescheduleRequests] = useRescheduleRequestsMutation({});
  const [assignTransportsToCustomer] = useAssignTransportsToCustomerMutation(
    {}
  );

  // format service time value
  const formatServiceTime = (time) => {
    // if length 4 then add the ":" to middle
    if (time?.toString().length === 4) {
      return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
    }

    // if length 3 then append 0 to front and add the ":" to middle
    if (time?.toString().length === 3) {
      const formatted = `0${time}`;
      return `${formatted.slice(0, 2)}:${formatted.slice(2, 4)}`;
    }

    // otherwise return empty
    return "";
  };

  const settingServiceAtValue = (
    serviceInfo,
    serviceAtType,
    customerNumber
  ) => {
    const serviceTime =
      serviceInfo?.service_time === NUMBER_9999
        ? ON_CALL
        : formatServiceTime(serviceInfo?.service_time);
    if (serviceAtType === t("rescheduleService.bothServiceType")) {
      rescheduleForm.setFieldValue(
        "serviceAtNew",
        customerNumber +
          " " +
          (serviceInfo?.run_name?.trim() ||
            serviceInfo?.route_code?.trim() ||
            "") +
          " " +
          serviceTime
      );
      rescheduleForm.setFieldValue(
        "serviceAtOriginal",
        customerNumber +
          " " +
          (serviceInfo?.run_name?.trim() ||
            serviceInfo?.route_code?.trim() ||
            "") +
          " " +
          serviceTime
      );
    } else {
      rescheduleForm.setFieldValue(
        serviceAtType,
        customerNumber +
          " " +
          (serviceInfo?.run_name?.trim() ||
            serviceInfo?.route_code?.trim() ||
            "") +
          " " +
          serviceTime
      );
    }
  };

  //getting request Details
  const handleRequestInfo = async (requestInfoCust) => {
    requestInfoCust &&
      (await triggerRequestInfo({
        mainDistrictId: currentBranch?.district_id,
        requestId: requestInfoCust?.request_id
      })
        .unwrap()
        .then((requestInfo) => {
          const requestDetail = requestInfo.find(
            (i) => i?.request_id === requestInfoCust?.request_id
          );
          rescheduleForm.setFieldValue("requestDetail", requestDetail);
        })
        .catch((error) => {
          setError(`${t("requestSearch.unexpectedError")}:: ${error}`);
        }));
  };

  //getting runName/routecode ,time and cutomer csl number
  const getScheduleTime = async (
    date,
    serviceAtType,
    scheduledServices,
    calledFrom
  ) => {
    const customerVal = rescheduleFormVal.customer;
    //checking the selected date is in the scheduled date
    const specialService = scheduledServices.find((value) =>
      dayjs(date).isSame(dayjs(value?.service_date), "day")
    );

    if (specialService) {
      if (specialService?.run_id) {
        rescheduleForm.setFieldValue("runId", specialService?.run_id);
      }

      if (calledFrom !== t("rescheduleService.diffDateOnSave")) {
        handleRequestInfo(specialService);
      }

      if (serviceAtType !== t("rescheduleService.serviceAtOriginal")) {
        // This values is getting on initial Load and when ever we change new date.
        rescheduleForm.setFieldValue("routeComboBox", false);

        rescheduleForm.setFieldValue(
          "cslNewRequestId",
          specialService?.request_id
        );
      }

      handleRunNameRouteCode(specialService, serviceAtType, date, calledFrom);
    } else {
      rescheduleForm.setFieldValue("cslNewRequestId", null);
      if (serviceAtType === t("rescheduleService.bothServiceType")) {
        rescheduleForm.setFieldValue("serviceAtNew", customerVal?.number);
        rescheduleForm.setFieldValue("serviceAtOriginal", customerVal?.number);
      } else {
        rescheduleForm.setFieldValue(serviceAtType, customerVal?.number);
      }
    }
  };

  //setting the route code
  const handleServiceAtRoute = (routeInfo, customerVal, serviceAtType) => {
    rescheduleForm.setFieldValue(
      "cslCustomerId",
      routeInfo[0]?.csl_customer_id
    );
    //enable dropdown for serviceAtNew
    t("rescheduleService.serviceAtOriginal") !== serviceAtType &&
      rescheduleForm.setFieldValue("routeComboBox", routeInfo.length > 1);
    if (
      routeInfo.length > 1 &&
      t("rescheduleService.serviceAtOriginal") !== serviceAtType
    ) {
      const serviceRouteOption = routeInfo.map((i) => ({
        value: i.route_customer_schedule_id,
        label: `${rescheduleForm?.values?.customer.number} ${
          i.route_code
        } ${formatServiceTime(i.service_time)}`
      }));
      rescheduleForm.setFieldValue("routeOptions", serviceRouteOption);
    } else if (routeInfo.length > 0) {
      rescheduleForm.setFieldValue(
        "serviceAtNewDropdownVal",
        routeInfo[0].route_customer_schedule_id
      );
    }

    if (routeInfo.length === 0) {
      settingServiceAtValue(null, serviceAtType, customerVal?.number);
    } else {
      settingServiceAtValue(routeInfo[0], serviceAtType, customerVal?.number);
    }
  };

  //getting runName and RouteCode
  const handleRunNameRouteCode = async (
    requestInfoCust,
    serviceAtType,
    date,
    calledFrom
  ) => {
    const customerVal = rescheduleFormVal.customer;
    setPageLoading(true);

    if (requestInfoCust?.run_id) {
      if (calledFrom !== t("rescheduleService.sameDateOnSave")) {
        const response = await triggerRunInfo({
          mainDistrictId: currentBranch?.district_id,
          requestId: requestInfoCust.request_id.toString()
        })
          .unwrap()
          .catch((error) => {
            setPageLoading(false);
            setError(`${t("requestSearch.unexpectedError")} ${error}`);
          });
        setPageLoading(false);

        if (response) {
          const serviceRunInfo = response[0];

          if (
            calledFrom === t("rescheduleService.bothServiceType") ||
            calledFrom === t("rescheduleService.customerChange")
          ) {
            settingServiceAtValue(
              serviceRunInfo,
              t("rescheduleService.bothServiceType"),
              customerVal.number
            );
          } else {
            handleServiceAtRoute([serviceRunInfo], customerVal, serviceAtType);
          }
        }
      }
    } else {
      if (calledFrom !== t("rescheduleService.dateChangeAtNew")) {
        await triggerCslInfo({
          mainDistrictId: currentBranch?.district_id,
          customerId: customerVal?.value.toString(),
          rescheduleDate: dayjs(date).format(t("common.isDateTimeFormat"))
        })
          .unwrap()
          .then((routeInfo) => {
            handleServiceAtRoute(routeInfo, customerVal, serviceAtType);
          })
          .catch((error) => {
            setError(`${t("requestSearch.unexpectedError")} ${error}`);
          })
          .finally(() => {
            setPageLoading(false);
          });
      }

      if (calledFrom !== t("rescheduleService.dateChangeAtOriginal")) {
        await triggerRouteInfo({
          mainDistrictId: currentBranch?.district_id,
          customerId: customerVal?.value.toString(),
          rescheduleDate: dayjs(date).format(t("common.isDateTimeFormat"))
        })
          .unwrap()
          .then((routeInfo) => {
            handleServiceAtRoute(routeInfo, customerVal, serviceAtType);
          })
          .catch((error) => {
            setError(`${t("requestSearch.unexpectedError")}:: ${error}`);
          })
          .finally(() => {
            setPageLoading(false);
          });
      }
    }
  };
  //handle reset of the values
  const handleFieldsToReset = (fieldsToReset, valueToReset) => {
    fieldsToReset.forEach((field) => {
      rescheduleForm.setFieldValue(field, valueToReset);
    });
  };
  //handle Dialog Box button
  const handleDialogButton = async (buttonLabel) => {
    rescheduleForm.setFieldValue("ModalPopupOpen", false);
    switch (buttonLabel) {
      case "dateDialogOk":
        handleServiceDate(
          handleFromDate(
            rescheduleFormVal.scheduledDateOriginal[0]?.service_date
          ),
          handleToDate(
            rescheduleFormVal.scheduledDateOriginal[0]?.service_date
          ),
          t("rescheduleService.scheduledDateOriginal"),
          N_CHECKER
        );
        break;
      case "saveChangesDialogNo":
        handleFieldsToReset(["serviceAtNew", "serviceAtOriginal"], "");
        handleFieldsToReset(["customerNoRqst", "routeComboBox"], false);
        setSaveSuccess(true);
        handleFieldsToReset(["customer", "serviceAtNewDropdownVal"], null);
        handleFieldsToReset(
          [
            "originalDate",
            "newDate",
            "previousOriginalDate",
            "previousNewDate"
          ],
          dayjs()
        );
        break;
      case "saveChangesDialogYes":
        if (
          !dayjs(rescheduleFormVal.originalDate).isSame(
            rescheduleFormVal.newDate,
            "day"
          )
        ) {
          rescheduleForm.setFieldValue(
            "modalButtonLabel",
            t("rescheduleService.saveRescheduleDialog")
          );
        } else {
          rescheduleForm.setFieldValue(
            "modalButtonLabel",
            t("rescheduleService.newServiceDialog")
          );
        }
        rescheduleForm.setFieldValue("ModalPopupOpen", true);
        break;
      case "saveRescheduleDialogOk":
        if (
          !dayjs(rescheduleFormVal.originalDate).isSame(
            rescheduleFormVal.newDate,
            "day"
          )
        ) {
          setPageLoading(true);
          //reschedule save data
          let rescheduleSaveObj = {};
          rescheduleSaveObj.mainDistrictId = currentBranch?.district_id;
          rescheduleSaveObj.cslCustomerId = rescheduleFormVal.customer?.value;
          rescheduleSaveObj.oldServiceDate = dateFormat(
            rescheduleFormVal.originalDate
          );
          rescheduleSaveObj.newServiceDate = dateFormat(
            rescheduleFormVal.newDate
          );
          rescheduleSaveObj.personnelId = rescheduleFormVal.personnelId;
          rescheduleSaveObj.employeeId = auth.user?.employee_id;
          rescheduleSaveObj.holidayFlag = Y_CHECKER;
          rescheduleSaveObj.cslNewRcsId =
            rescheduleFormVal.serviceAtNewDropdownVal;
          rescheduleSaveObj.cslNewRequestId = rescheduleFormVal.cslNewRequestId;
          //assign district transport
          let assignTransport = {};
          assignTransport.mainDistrictId = currentBranch?.district_id;
          assignTransport.requestId =
            rescheduleFormVal.requestDetail.request_id;
          assignTransport.employeeId = auth.user.employee_id;
          assignTransport.sfAssignCode = t("rescheduleService.SFAssignCode");
          assignTransport.sfRemoveCode = t("rescheduleService.SFRemoveCode");
          rescheduleFormVal.assignTransport = assignTransport;

          saveRescheduleRequest(rescheduleSaveObj).then(() => {
            //resetting the date ,routeCode/runName and time after saving
            handleServiceDate(
              handleFromDate(rescheduleFormVal.originalDate),
              handleToDate(rescheduleFormVal.originalDate),
              t("rescheduleService.bothScheduledDate")
            ).then(async (scheduledDates) => {
              if (scheduledDates.length > 0) {
                settingDate(
                  scheduledDates,
                  dayjs(scheduledDates[0]?.service_date)
                );
                await getScheduleTime(
                  dayjs(scheduledDates[0]?.service_date),
                  t("rescheduleService.bothServiceType"),
                  scheduledDates,
                  t("rescheduleService.diffDateOnSave")
                );

                setTimeout(
                  async () =>
                    await handleServiceDate(
                      dayjs(),
                      dayjs(),
                      t("rescheduleService.bothScheduledDate")
                    ).then((scheduledDates) => {
                      if (scheduledDates.length > 0) {
                        settingDate(
                          scheduledDates,
                          dayjs(scheduledDates[0]?.service_date)
                        );
                        getScheduleTime(
                          dayjs(scheduledDates[0]?.service_date),
                          t("rescheduleService.bothServiceType"),
                          scheduledDates,
                          t("rescheduleService.sameDateOnSave")
                        );
                      }
                    }),
                  100
                );
                setPageLoading(false);
              }
            });
          });
        } else {
          rescheduleForm.setFieldValue(
            "modalButtonLabel",
            t("rescheduleService.newServiceDialog")
          );
        }
        setSaveSuccess(true);
        break;
      case "assignTransportDialog":
        rescheduleForm.setFieldValue(
          "modalButtonLabel",
          t("rescheduleService.assignTransportDialog")
        );
        rescheduleForm.setFieldValue("ModalPopupOpen", true);
        break;
      case "assignTransportDialogYes": {
        //#request_transport temp table
        const requestTransport = rescheduleFormVal.transports.map((i) => ({
          container_id: i.transportContainerId,
          request_id: i.containerRequestId,
          remove_flag: Y_CHECKER
        }));
        //loop through all transtports 
        const removeTransport = async () => {
          for (let i = 0; i < requestTransport.length; i++) {
            setPageLoading(true);
            const removeRequestId = requestTransport.map(({request_id,...rest})=>rest);
            const payload = {
              mainDistrictId: districtId,
              requestId: requestTransport[i].request_id,
              employeeId: auth?.user?.employee_id,
              sfAssignCode: SF_ASSIGN_CODE,
              sfRemoveCode: SF_REMOVE_CODE,
              requestTransport: removeRequestId,
            };
           const response= await assignTransportsToCustomer(payload);
           if(response.data){
            setPageLoading(false);
            if(response.data[0].error_code !== zeroChecker){
              const errorMsg = await getMessageFromCode(
                String(response.data[0].error_code)
              );
              setErrors([...errors, errorMsg[0]?.descr]);
            }
           }
          }
        };
        removeTransport();
        break;
      }
      default:
        break;
    }
  };
  //setting the Original and New date
  const settingDate = (serviceDate, selectedDate) => {
    if (serviceDate?.length !== 0) {
      handleFieldsToReset(
        ["originalDate", "newDate", "previousOriginalDate", "previousNewDate"],
        selectedDate
      );
      rescheduleForm.setFieldValue("customerNoRqst", false);
      setSaveSuccess(false);
    } else {
      //displaying No request alert for the particular customer
      rescheduleForm.setFieldValue("customerNoRqst", true);
      setError(t("rescheduleService.noScheduledDate"));
    }
  };
  //handling save changes dialog box clicking when cancel the customer
  const handleSaveChangeDialog = () => {
    const dateChanged = handleDatechangedStatus();
    if (rescheduleForm?.values?.customerNoRqst || dateChanged) {
      handleDialogButton(t("rescheduleService.saveChangesDialogNo"));
    } else {
      rescheduleForm.setFieldValue(
        "modalButtonLabel",
        t("rescheduleService.saveChangesDialog")
      );
      rescheduleForm.setFieldValue("ModalPopupOpen", true);
    }
  };
  //handling status of date changed in original and new
  const handleDatechangedStatus = () => {
    const values = rescheduleFormVal;
    const newDateChangeStatus =
      dateFormat(values.previousNewDate) === dateFormat(values.newDate);
    const originalDateChangeStatus =
      dateFormat(values.previousOriginalDate) ===
      dateFormat(values.originalDate);
    return newDateChangeStatus && originalDateChangeStatus;
  };
  //save button click
  const handleSave = () => {
    if (
      !dayjs(rescheduleFormVal.originalDate).isSame(
        rescheduleFormVal.newDate,
        "day"
      )
    ) {
      rescheduleForm.setFieldValue(
        "modalButtonLabel",
        t("rescheduleService.saveRescheduleDialog")
      );
    } else {
      rescheduleForm.setFieldValue(
        "modalButtonLabel",
        t("rescheduleService.newServiceDialog")
      );
    }
    rescheduleForm.setFieldValue("ModalPopupOpen", true);
  };
  const handleAuthSubmit = (personnelId) => {
    rescheduleForm.setFieldValue("personnelId", personnelId);
  };
  // For Initail load addonCutoffFlag (addon_cutoff_flag) it must be N, but made as Y.
  //getting scheduleDate
  const handleServiceDate = async (
    fromDate,
    toDate,
    serviceDateType,
    rescheduleFlag,
    addonCutoffFlag = N_CHECKER
  ) => {
    const customer = rescheduleFormVal.customer;
    const { data: scheduledDates } = await triggerScheduleDate({
      mainDistrictId: currentBranch?.district_id,
      customerId: customer?.value,
      fromDate: dayjs(fromDate).format(t("common.isDateTimeFormat")),
      toDate: dayjs(toDate).format(t("common.isDateTimeFormat")),
      rescheduleFlag: rescheduleFlag,
      addonCutoffFlag
    }).catch((error) => {
      console.error(t("requestSearch.unexpectedError"), error);
    });

    const serviceDate = scheduledDates?.filter(
      (i) =>
        dayjs(i?.service_date).isSame(dayjs(), "day") ||
        dayjs(i?.service_date).isAfter(dayjs())
    );

    if (serviceDateType === t("rescheduleService.bothScheduledDate")) {
      rescheduleForm.setFieldValue("scheduledDateNew", serviceDate);
      rescheduleForm.setFieldValue("scheduledDateOriginal", serviceDate);
    } else {
      rescheduleForm.setFieldValue(serviceDateType, serviceDate);
    }
    return serviceDate;
  };

  //save reschedule request
  const saveRescheduleRequest = async (rescheduleSaveObj) => {
    setErrors([]); // clear error messages
    await saveRescheduleRequests(rescheduleSaveObj)
      .then(async (response) => {
        if (response.data) {
          if (response.data?.errors?.length !== 0) {
            if (response.data?.transport.length !== 0) {
              const errorMsg = await getMessageFromCode(
                String(errorMsgs.errorCode25355)
              );
              if (errorMsg) {
                const partialString = response.data?.transport.map(transport=>transport.number.trim()).join(", ");
                const replacedSentence = errorMsg[0]?.descr
                  .replace("|", partialString);
                setThrowWarningMessage(replacedSentence);
                setThrowWarning(true);
              }

              const transports = response.data.containers.map((i) => ({
                transportContainerId: i.transport_container_id,
                containerNumber: i.number,
                containerRequestId: i.request_id
              }));
              rescheduleForm.setFieldValue("transports", transports);
              handleDialogButton(t("rescheduleService.assignTransportDialog"));
            } else if (response.data?.transport.length === 0 && response.data?.errors[0]?.error === errorMsgs.errorCode25390) {
              const transports = response.data.containers.map((i) => ({
                transportContainerId: i.transport_container_id,
                containerNumber: i.number,
                containerRequestId: i.request_id
              }));
              rescheduleForm.setFieldValue("transports", transports);
              handleDialogButton(t("rescheduleService.assignTransportDialog"));
            }
          } else {
            setSaveSuccess(true);
          }
        }
      })
      .catch((error) => {
        console.error(t("requestSearch.unexpectedError"), error);
      });
  };
  //handle customer changes
  const handleCustomerChange = (_, newValue) => {
    setErrors([]); // clear error messages
    if (newValue !== null) {
      setPageLoading(true);
      rescheduleForm.setFieldValue("customer", newValue);
    } else {
      handleSaveChangeDialog();
    }
  };
  const handleFromDate = (value) => {
    return dayjs(value).startOf("month");
  };
  const handleToDate = (value) => {
    return dayjs(value).endOf("month");
  };

  const handleIsDateOnServiceDate = (date) => {
    // check if selected date is on original service dates
    const serviceDate = handleServiceDateFormat(
      rescheduleFormVal.scheduledDateOriginal
    );
    const isSelected = serviceDate?.includes(dateFormat(date));
    // if exists, isSelected is true. get run and schedule time to display.
    if (isSelected) {
      setErrors([]); // clear error messages
      getScheduleTime(
        dayjs(date),
        t("rescheduleService.serviceAtOriginal"),
        rescheduleFormVal.scheduledDateOriginal,
        t("rescheduleService.dateChangeAtOriginal")
      );
    } else {
      // if not, show info message and select the initial date for original service date.
      rescheduleForm.setFieldValue(
        "originalDate",
        rescheduleFormVal.previousOriginalDate
      );

      setError(t("rescheduleService.dateDialogMsg"));
      handleServiceDate(
        handleFromDate(
          rescheduleFormVal.scheduledDateOriginal[0]?.service_date
        ),
        handleToDate(rescheduleFormVal.scheduledDateOriginal[0]?.service_date),
        t("rescheduleService.scheduledDateOriginal"),
        N_CHECKER
      );
    }
  };

  const setError = (errorMessage) => {
    // Check if the error message is not already in the array before adding it
    if (!errors.includes(errorMessage)) {
      setErrors([...errors, errorMessage]);
    }
  };
  const handleAlertClose = (index) => {
    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  // init page
  useEffect(() => {
    // locale of the selected branch
    getLocaleByBranch().then((resp) => {
      if (resp) {
        const languageCode =
          resp[0]?.iso_locale_code || SUPPORTED_LANGUAGES?.ENGLISH_US?.code;
        setLocaleByBranch(DAYJS_LOCALES[languageCode] || DEFAULT_LOCALE);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rescheduleFormVal.customer !== null) {
      Promise.allSettled([
        handleServiceDate(
          dayjs(),
          dayjs(),
          t("rescheduleService.bothScheduledDate"),
          Y_CHECKER
        ),
        triggercustomerLocationInfo({
          mainDistrictId: currentBranch?.district_id,
          customerId: rescheduleFormVal.customer.value,
          exchangeLocationTypeCode: EXCHANGE_LOCATION_TYPE_SL,
          serviceFrequencyFlag: Y_CHECKER
        })
      ])
        .then(async (resp) => {
          const scheduledDates = resp[0]?.value;
          if (scheduledDates) {
            setErrors([]);
            if (scheduledDates?.length > 0) {
              settingDate(
                scheduledDates,
                dayjs(scheduledDates[0]?.service_date)
              );
              await getScheduleTime(
                dayjs(scheduledDates[0]?.service_date),
                t("rescheduleService.bothServiceType"),
                scheduledDates,
                t("rescheduleService.customerChange")
              );
            } else {
              settingDate(scheduledDates, null);
            }
          }
          setPageLoading(false);
        })
        .catch((error) => {
          console.error(t("requestSearch.unexpectedError"), error);
        });
    } else {
      handleFieldsToReset(["serviceAtNew", "serviceAtOriginal"], "");
      handleFieldsToReset(["customer", "serviceAtNewDropdownVal"], null);
      setPageLoading(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rescheduleFormVal.customer]);

  return (
    <Grid sx={RescheduleServiceFormStyles.PaddingLeft}>
      {/* Full page loader */}
      <CircularLoaderFullPage
        id="mediaRequestDetailFullPageLoader"
        loading={pageLoading || isCustomersLoading}
      />

      <Collapse in={throwWarning}>
        {/*Warinng alert box*/}
        <Alert
          severity="warning"
          sx={RescheduleServiceFormStyles.alertBox}
          icon={<img src={WarningIcon} alt="warning" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setThrowWarning(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {throwWarningMessage}
        </Alert>
      </Collapse>
      <form onSubmit={rescheduleForm.handleSubmit}>
        <Stack spacing={1}>
          <Grid container spacing={2}>
            {/*Alert*/}
            {errors.length > 0 && (
              <Grid item xs={12}>
                <ErrorAlert errors={errors} onClose={handleAlertClose} />
              </Grid>
            )}
            <Grid item sx={RescheduleServiceFormStyles.PaddingTop} xs={3}>
              <CustomersField
                form={{ touched: {}, errors: {} }}
                options={customers}
                value={rescheduleFormVal?.customer}
                handleOnChange={(event, newValue) => {
                  handleCustomerChange("customer", newValue);
                }}
                required={true}
              />
            </Grid>
          </Grid>
          {/* Personnel Authentication */}
          <PersonnelAuthentication
            value={rescheduleFormVal}
            customer={rescheduleFormVal.customer}
            onAuthSubmit={handleAuthSubmit}
            key={rescheduleFormVal.customer}
            error={errors.length > 0 ? true : false}
            saveSuccess={saveSuccess}
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                {t("rescheduleService.serviceDates")}
              </Typography>
            </Grid>
            {/* Original Scheduled DatePicker */}
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <DatePickerField
                id="original-date"
                name={t("rescheduleService.originalDateName")}
                label={t("rescheduleService.original")}
                locale={localeByBranch}
                minDate={minDate}
                maxDate={maxDate}
                value={rescheduleFormVal.originalDate}
                loading={isScheduledServiceDatesLoading}
                renderLoading={() => <CircularLoader loading={true} />}
                onMonthChange={(value) => {
                  handleServiceDate(
                    handleFromDate(value),
                    handleToDate(value),
                    t("rescheduleService.scheduledDateOriginal"),
                    "N"
                  );
                }}
                onYearChange={(value) => {
                  handleServiceDate(
                    handleFromDate(value),
                    handleToDate(value),
                    t("rescheduleService.scheduledDateOriginal"),
                    "N"
                  );
                }}
                onOpen={() =>
                  handleServiceDate(
                    handleFromDate(rescheduleFormVal.originalDate),
                    handleToDate(rescheduleFormVal.originalDate),
                    t("rescheduleService.scheduledDateOriginal"),
                    "N"
                  )
                }
                onChange={async (value) => {
                  await rescheduleForm.setFieldValue("originalDate", value);
                  setSaveSuccess(false);
                }}
                onAccept={(date) => {
                  handleIsDateOnServiceDate(date);
                }}
                onBlur={rescheduleForm.handleBlur}
                error={Boolean(rescheduleForm.errors.originalDate)}
                helperText={rescheduleForm.errors.originalDate}
                disabled={
                  rescheduleFormVal.customer === null ||
                  rescheduleFormVal.customerNoRqst
                }
                highlightScheduleDays={(props) => (
                  <ScheduleOriginalDate
                    {...props}
                    serviceDate={rescheduleFormVal.scheduledDateOriginal}
                    fieldLabel={t("rescheduleService.originalDate")}
                    formikValues={rescheduleFormVal}
                  />
                )}
              />
            </Grid>
            {/* Original Service At */}
            <Grid
              sx={RescheduleServiceFormStyles.MarginLeft20}
              item
              xs={12}
              md={6}
              lg={3}
              xl={3}
            >
              <TextField
                id="service-at-original"
                label={t("rescheduleService.ServiceAt")}
                value={rescheduleFormVal.serviceAtOriginal}
                InputProps={{ readOnly: true }}
                fullWidth
                disabled={
                  rescheduleFormVal.customer === null ||
                  rescheduleFormVal.customerNoRqst
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* New Schedule DatePicker */}
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <DatePickerField
                id="new-Date"
                name={t("rescheduleService.newDateName")}
                label={t("rescheduleService.new")}
                locale={localeByBranch}
                minDate={minDate}
                maxDate={maxDate}
                value={rescheduleFormVal.newDate}
                loading={isScheduledServiceDatesLoading}
                renderLoading={() => <CircularLoader loading={true} />}
                onMonthChange={(value) => {
                  handleServiceDate(
                    handleFromDate(value),
                    handleToDate(value),
                    t("rescheduleService.scheduledDateNew"),
                    "N"
                  );
                }}
                onYearChange={(value) => {
                  handleServiceDate(
                    handleFromDate(value),
                    handleToDate(value),
                    t("rescheduleService.scheduledDateNew"),
                    "N"
                  );
                }}
                onOpen={() =>
                  handleServiceDate(
                    handleFromDate(rescheduleFormVal.newDate),
                    handleToDate(rescheduleFormVal.newDate),
                    t("rescheduleService.scheduledDateNew"),
                    "N"
                  )
                }
                onChange={async (value) => {
                  await rescheduleForm.setFieldValue("newDate", value);
                  getScheduleTime(
                    value,
                    t("rescheduleService.serviceAtNew"),
                    rescheduleFormVal.scheduledDateNew,
                    t("rescheduleService.dateChangeAtNew")
                  );
                  setSaveSuccess(false);
                }}
                onBlur={rescheduleForm.handleBlur}
                error={Boolean(rescheduleForm.errors.newDate)}
                helperText={rescheduleForm.errors.newDate}
                disabled={
                  rescheduleFormVal.customer === null ||
                  rescheduleFormVal.customerNoRqst
                }
                highlightScheduleDays={(props) => (
                  <ScheduleOriginalDate
                    {...props}
                    serviceDate={rescheduleFormVal.scheduledDateNew}
                    fieldLabel={t("rescheduleService.newDate")}
                    formikValues={rescheduleFormVal}
                  />
                )}
              />
            </Grid>
            {/* New Service At */}
            <Grid
              sx={RescheduleServiceFormStyles.MarginLeft20}
              item
              xs={12}
              md={6}
              lg={3}
              xl={3}
            >
              {!rescheduleFormVal.routeComboBox ? (
                <TextField
                  id="service-at-new"
                  label={t("rescheduleService.ServiceAt")}
                  value={rescheduleFormVal.serviceAtNew}
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled={
                    rescheduleFormVal.customer === null ||
                    rescheduleFormVal.customerNoRqst
                  }
                />
              ) : (
                <SelectField
                  options={rescheduleFormVal.routeOptions}
                  labelId="service-at"
                  label={t("rescheduleService.ServiceAt")}
                  value={rescheduleFormVal.serviceAtNewDropdownVal}
                  onChange={(event) =>
                    rescheduleForm.setFieldValue(
                      "serviceAtNewDropdownVal",
                      event.target.value
                    )
                  }
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* Save Button */}
            <Grid item xs="auto">
              <Button
                variant="contained"
                disabled={
                  rescheduleFormVal.customer === null ||
                  rescheduleFormVal.customerNoRqst ||
                  saveSuccess ||
                  Object.entries(rescheduleForm.errors).length !== 0 ||
                  rescheduleFormVal.originalDate === rescheduleFormVal.newDate
                }
                onClick={handleSave}
              >
                {t("rescheduleService.save")}
              </Button>
            </Grid>
          </Grid>

          <ModalPopup
            values={rescheduleFormVal}
            modalButtonLabel={rescheduleFormVal.modalButtonLabel}
            handleClick={handleDialogButton}
            error={Object.entries(rescheduleForm.errors).length !== 0}
          />
        </Stack>
      </form>
    </Grid>
  );
};

export default RescheduleServiceForm;
