export const ToDoStyles = {
  ItemCardWidth: {
    width: "100%",
    marginTop: "10px"
  },
  CardContentStyle: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "16px !important"
  },
  ImageMargin: { marginRight: "8px" },
  typoBold: "bold",
  typoNormal: "normal",
  cardMarginTop: "10px",
  border: "2px solid",
  transparentBackground: "transparent",
  colorWhite: "var(--color-white)",
  colorBlack: "var(--color-black)",
  buttonBorderRadius: "25px",
  buttonMargin: "5px",
  highPriorityColor: "var(--color-todo-highpriority)",
  requestRunsColor: "var(--color-todo-requestruns)",
  customersColor: "var(--color-im-mid-blue)",
  containersColor: "var(--color-todo-containers)",
  openmediaColor: "var(--color-todo-openmedia)",
  discrepacyColor: "var(--color-todo-discrepacy)",
  margin: {
    marginBottom: "20px"
  },
  buttonPadding: "6px 14px"
};
