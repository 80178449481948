import { t } from "i18next";

export const dayOfWeek = {
  monday: { day: "Monday", value: "Mon" },
  tuesday: { day: "Tuesday", value: "Tue" },
  wednesday: { day: "Wednesday", value: "Wed" },
  thursday: { day: "Thursday", value: "Thu" },
  friday: { day: "Friday", value: "Fri" },
  saturday: { day: "Saturday", value: "Sat" },
  sunday: { day: "Sunday", value: "Sun" }
};

export const keyList = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday"
];

export const minusOneChecker = "-1";
export const serviceFrequecyProps = {
  onMonday: "on_monday",
  onTuesday: "on_tuesday",
  onWednesday: "on_wednesday",
  onThursday: "on_thursday",
  onFriday: "on_friday",
  onSaturday: "on_saturday",
  onSunday: "on_sunday",
  cslFlag: "csl_flag"
};
export const onCallChecker = "On call";
export const exchLocationTypeCode = "SL";
export const dayWeekCodes = {
  mon: "Mon",
  tue: "Tue",
  wed: "Wed",
  thu: "Thu",
  fri: "Fri",
  sat: "Sat",
  sun: "Sun"
};
export const days = {
  monday: t("serviceWindows.monday"),
  tuesday: t("serviceWindows.tuesday"),
  wednesday: t("serviceWindows.wednesday"),
  thursday: t("serviceWindows.thursday"),
  friday: t("serviceWindows.friday"),
  saturday: t("serviceWindows.saturday"),
  sunday: t("serviceWindows.sunday")
};
export const dayList = [
  { value: "Mon", day: t("serviceWindows.monday") },
  { value: "Tue", day: t("serviceWindows.tuesday") },
  { value: "Wed", day: t("serviceWindows.wednesday") },
  { value: "Thu", day: t("serviceWindows.thursday") },
  { value: "Fri", day: t("serviceWindows.friday") },
  { value: "Sat", day: t("serviceWindows.saturday") },
  { value: "Sun", day: t("serviceWindows.sunday") }
];
export const dayCode = ["Mon", "Tue", "Wed", "Thu", "Fri"];
export const serviceInfo2 = t("serviceWindows.serviceInfo2");
export const one = "one";
export const two = "two";
export const midNightTime = "0000";
export const errormsg20359 = t("serviceWindows.errormsg20359");
export const For = t("serviceWindows.for");
