import {
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from "@firebase/auth";
import { getDocs, query, where } from "@firebase/firestore";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  logoutSuccess,
  setAuthInfo,
  setError,
  setLoggedUserData
} from "store/slices";
import {
  DOUBLE_EQUALS,
  FIREBASE_COLLECTION_NAMES,
  FIREBASE_FIELD_NAMES,
  IN,
  LOGIN_METHOD,
  NOT_EQUAL,
  ROLE_ACCESS_CODES,
  SECURITY_GROUPS,
  selectedDistrictId
} from "utils/constants";
import { getResponseData } from "utils/helpers";
import { CF_URLS } from "./api/endpoints";
import {
  appsettings_collection,
  authentication,
  branches_collection,
  employee_collection,
  googleAuthProvider
} from "./firebase";

// check the user entered email is alowed
const isEmailAllowed = async (email) => {
  let domains = [];
  const q = query(appsettings_collection);
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.id === FIREBASE_COLLECTION_NAMES?.SUPPORTED_DOMAINS) {
      domains.push(...(doc.data()?.domains || []));
    }
  });

  const domain = email.split("@")[1];
  if (domains.includes(domain)) {
    return true;
  } else {
    return false;
  }
};

// get the employee from the employee collection
const getUserFromTheEmployeeTable = async (emailAddress) => {
  let irmEployee = [];
  const q = query(
    employee_collection,
    where(
      FIREBASE_FIELD_NAMES.BUSINESS_EMAIL_ADDR_LOWER,
      DOUBLE_EQUALS,
      emailAddress?.toString().toLowerCase()
    ),
    where(
      FIREBASE_FIELD_NAMES.SECURITY_GROUP_ID,
      NOT_EQUAL,
      SECURITY_GROUPS?.TERMINATED_USER
    ),
    where(FIREBASE_FIELD_NAMES.EMP_END_DATE, DOUBLE_EQUALS, "")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    irmEployee.push({ id: doc?.id, ...doc?.data() });
  });
  return irmEployee;
};

// get the allocated branch id of the employee
const getAllocatedBranchIds = (irmEployee) => {
  const branchArray = irmEployee[0]?.district_list
    ?.split(",")
    ?.map((value) => value);
  return branchArray;
};

// get allocated branches from the branch collection
const getAllocatedBranchesForEmployee = async (branchArray) => {
  let allocatedBranches = [];

  if (branchArray.length > 0) {
    const chunkSize = 30; // in can handle only 30 values per time
    const chunks = [];
    for (let i = 0; i < branchArray.length; i += chunkSize) {
      chunks.push(branchArray.slice(i, i + chunkSize));
    }

    for (const chunk of chunks) {
      const q = query(
        branches_collection,
        where(FIREBASE_FIELD_NAMES.DISTRICT_ID, IN, chunk)
      );
      const querySnapshotOfBranches = await getDocs(q);
      querySnapshotOfBranches.forEach((doc) => {
        allocatedBranches.push({
          id: doc?.id,
          ...doc?.data(),
          label: doc?.data().name,
          value: doc?.id
        });
      });
    }

    // Sorting the allocatedBranches array by name
    allocatedBranches.sort((a, b) => {
      const nameA = a.label.toUpperCase(); // Ignore case
      const nameB = b.label.toUpperCase(); // Ignore case
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  return allocatedBranches;
};

const useAuthService = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //get session
  const getSessionId = async (employeeId) => {
    const reqBody = JSON.stringify({
      main_district_id: ROLE_ACCESS_CODES.CODE_50, //getAuthenticatedUserBranch(),
      district_id: selectedDistrictId,
      employee_id: employeeId
    });
    const data = await getResponseData(
      reqBody,
      `${CF_URLS.authentication.getSessionId}`,
      1
    );
    if (data?.data) {
      return data?.data[0][0].session_guid;
    }
  };

  const fetchLoggedUserData = async (userObj, emailAddress) => {
    if (!isEmailAllowed(emailAddress)) {
      await signOut(authentication);
      dispatch(setError(t("signin.notAValidUser")));
      return;
    }

    const irmEployee = await getUserFromTheEmployeeTable(emailAddress);
    if (irmEployee?.length === 0) {
      await signOut(authentication);
      dispatch(setError(t("signin.notAValidUser")));
      return;
    }

    // wait until role access and branches list of the employee fetched
    const programSecurity = irmEployee[0].program_list.split(",");
    const branchArray = getAllocatedBranchIds(irmEployee);

    // convert branches list into an array
    const allocatedBranches =
      await getAllocatedBranchesForEmployee(branchArray);

    // get previous branch name
    const session_guid = await getSessionId(irmEployee[0]?.id);

    // set fetched data into redux
    dispatch(setAuthInfo(JSON.stringify(userObj)));
    dispatch(
      setLoggedUserData({
        employee: irmEployee[0],
        roleAccess: programSecurity,
        branches: allocatedBranches,
        sessionId: session_guid
      })
    );

    return irmEployee;
  };

  const signInFunction = async (userId, password, authMethod) => {
    try {
      let user;

      if (authMethod === LOGIN_METHOD) {
        user = await signInWithEmailAndPassword(
          authentication,
          userId,
          password
        );
      } else {
        user = await signInWithPopup(authentication, googleAuthProvider);
      }

      return await fetchLoggedUserData(user.user, user.user.email);
    } catch (error) {
      return { msg: t("signin.invalidEmailOrPassword") };
    }
  };

  const logOut = async () => {
    await signOut(authentication);
    dispatch(logoutSuccess());
  };

  return {
    fetchLoggedUserData,
    signInFunction,
    logOut
  };
};

export default useAuthService;
