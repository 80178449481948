import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import ErrorIcon from "assets/images/warning-2.svg";
import {
  DatePickerField,
  FullCircularLoader,
  SelectField
} from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  MEDIA_REQUEST_DETAIL,
  REQUEST_MODULE_BASE_PATH,
  REQUEST_SEARCH
} from "routing/Paths";
import {
  useGetAllCustomersQuery,
  useGetAllRequestStatusesQuery,
  useGetAllRequestTypesQuery,
  useGetAllRoutesQuery,
  useSearchRequestMutation
} from "services/api";
import { customer } from "services/common/customer";
import {
  requestSearchPersit,
  resetRequestPersistedData,
  selectAuth
} from "store/slices";
import spacing from "styles/spacing";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  DEFAULT_LOCALE,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y
} from "utils/constants";
import { generateDayPattern } from "utils/helpers/generateDayPattern";
import PrintRequestSearchModal from "../print-request-search-modal/PrintRequestSearchModal";
import { filterSchema } from "../schema";
import SearchTable from "../search-table/SearchTable";
import { SearchFilterStyles } from "./SearchFilter.Styles";

const SearchFilter = () => {
  const { startDate, endDate, requestStatusId, limitRowCountFlag } =
    useParams();
  const { t } = useTranslation();
  const persistedState = useSelector(requestSearchPersit);
  const dispatchStore = useDispatch();
  const { currentBranch, localeByBranch, timeZoneOffset } =
    useSelector(selectAuth);
  const selectedBranchId = currentBranch?.district_id;
  const navigate = useNavigate();
  const childRef = useRef();
  const initFilterState = {
    fromDate: dayjs(Date.now()),
    toDate: dayjs(Date.now()),
    customer: t("requestSearch.all"),
    requestType: 0,
    routeName: 0,
    requestStatus: 1
  };

  const SearchFilterForm = useFormik({
    initialValues: initFilterState,
    validationSchema: filterSchema
  });

  const [disableFindButton, setDisableFindButton] = useState(false);
  const [disablePrintButton, setDisablePrintButton] = useState(true);
  const [warningAlertOpen, setWarningAlertOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState("noClicks");
  const [isLocaleLoading, setIsLocaleLoading] = useState(true);

  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [paramsDataSet, setParamsDataSet] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(
    DEFAULT_DATA_TABLE_OPTIONS.rowsPerPage
  );

  const [page, setPage] = useState(0);
  const [tableSearchText, setTableSearchText] = useState("");
  const [tableColumns, setTableColumns] = useState([]);
  const [tableFilterList, setTableFilterList] = useState(new Array(9).fill([]));

  useEffect(() => {
    localeByBranch && setIsLocaleLoading(false);
  }, [localeByBranch]);

  const { data: customers = [] } = useGetAllCustomersQuery(
    { mainDistrictId: selectedBranchId },
    {
      selectFromResult: ({ data }) => ({
        data:
          [].concat(
            [{ label: t("requestSearch.all"), value: t("requestSearch.all") }],
            data
          ) ?? []
      })
    }
  );
  const {
    data: requests = [],
    isError: isRequestError,
    error: requestError
  } = useGetAllRequestTypesQuery(
    {},
    {
      selectFromResult: ({ data, ...rest }) => {
        if (Array.isArray(data)) {
          // Check if data is an array
          const sortedData = [...data].sort((a, b) =>
            a.label.localeCompare(b.label)
          ); //sort the array
          return {
            data:
              [].concat(
                [{ label: t("requestSearch.all"), value: 0 }],
                sortedData
              ) ?? [], // Set error to null to avoid confusion
            ...rest
          };
        } else {
          return { data: [], ...rest }; // Return an empty array to avoid errors
        }
      }
    }
  );

  const [dayPattern, setDayPattern] = useState();
  const {
    data: routes = [],
    isError: isRouteError,
    error: routerError
  } = useGetAllRoutesQuery(
    {
      mainDistrictId: selectedBranchId,
      dayCodes: dayPattern
    },
    {
      selectFromResult: ({ data, ...rest }) => {
        if (Array.isArray(data)) {
          // Check if data is an array
          const sortedData = [...data].sort((a, b) =>
            a?.label?.localeCompare(b?.label)
          ); //sort the array
          return {
            data:
              [].concat(
                [{ label: t("requestSearch.all"), value: 0 }],
                sortedData
              ) ?? [],
            ...rest
          };
        } else {
          return { data: [], ...rest }; // Return an empty array to avoid errors
        }
      }
    }
  );

  const {
    data: statuses = [],
    isError: isRequestStatusError,
    error: requestStatusError
  } = useGetAllRequestStatusesQuery(
    { mainDistrictId: selectedBranchId },
    {
      selectFromResult: ({ data, ...rest }) => {
        // Log the error here

        return {
          data:
            [].concat([{ label: t("requestSearch.all"), value: 0 }], data) ??
            [],
          ...rest
        };
      }
    }
  );

  const [
    triggerSearchResult,
    { data = [], isLoading, status, isError: isSearchError, error: searchError }
  ] = useSearchRequestMutation();

  const [searchResults, setSearchResults] = useState(data);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    if (isRequestError) {
      setErrorMessages((prevErrorMessages) => {
        const newErrorMessages = new Set(prevErrorMessages);
        newErrorMessages.add(
          `${t("requestSearch.requestTypeError")} ${requestError.message}`
        );
        return newErrorMessages;
      });
    }
    if (isRouteError) {
      setErrorMessages((prevErrorMessages) => {
        const newErrorMessages = new Set(prevErrorMessages);
        newErrorMessages.add(
          `${t("requestSearch.useGetRoutesError")} ${routerError.message}`
        );
        return newErrorMessages;
      });
    }
    if (isRequestStatusError) {
      setErrorMessages((prevErrorMessages) => {
        const newErrorMessages = new Set(prevErrorMessages);
        newErrorMessages.add(
          `${t("requestSearch.request_statusError")} ${
            requestStatusError.message
          }`
        );
        return newErrorMessages;
      });
    }
    if (isSearchError) {
      setErrorMessages((prevErrorMessages) => {
        const newErrorMessages = new Set(prevErrorMessages);
        newErrorMessages.add(
          `${t("requestSearch.getsummaryrequestinfoError")} ${
            searchError.message
          }`
        );
        return newErrorMessages;
      });
    }
  }, [
    isRequestError,
    requestError?.message,
    isRouteError,
    routerError?.message,
    isRequestStatusError,
    requestStatusError?.message,
    isSearchError,
    searchError?.message,
    t
  ]);

  const minDate = dayjs(t("requestSearch.minimumDate"));
  const maxDate = dayjs(t("requestSearch.maximumDate"));

  useEffect(() => {
    setDayPattern(
      generateDayPattern(
        SearchFilterForm.values.fromDate,
        SearchFilterForm.values.toDate
      )
    );
  }, [SearchFilterForm.values.fromDate, SearchFilterForm.values.toDate]);

  const handleClear = () => {
    setButtonClicked("Clear");
    SearchFilterForm.resetForm();
    setDisablePrintButton(true);
    setDisableFindButton(false);
    setWarningAlertOpen(false);
    setSearchResults([]);
    setThrowError(false);
    setPage(0);
    setRowsPerPage(DEFAULT_DATA_TABLE_OPTIONS.rowsPerPage);
    setTableSearchText("");
    setTableColumns([]);
    setTableFilterList(new Array(9).fill([]));
  };

  const handleSelectFieldReset = () => {
    setDisableFindButton(false);
    setSearchResults([]);
    setWarningAlertOpen(false);
    setButtonClicked("Clear");
    setThrowError(false);
    setPage(0);
    setRowsPerPage(DEFAULT_DATA_TABLE_OPTIONS.rowsPerPage);
    setTableSearchText("");
    setTableColumns([]);
    setTableFilterList(new Array(9).fill([]));
  };

  const handleSearch = async () => {
    setButtonClicked("Find");
    const customerId =
      SearchFilterForm.values.customer.value === t("requestSearch.all")
        ? ""
        : SearchFilterForm.values.customer?.value?.toString();
    const requestTypeId =
      SearchFilterForm.values.requestType !== 0
        ? SearchFilterForm.values.requestType?.toString()
        : "";
    const routeName =
      SearchFilterForm.values.routeName !== 0
        ? SearchFilterForm.values.routeName?.toString().trimEnd()
        : "";
    let requestStatusId =
      SearchFilterForm.values.requestStatus !== 0
        ? SearchFilterForm.values.requestStatus?.toString()
        : "";
    let notCompletedFlag = VALUE_N;
    if (startDate || endDate) {
      requestStatusId = VALUE_EMPTY_STRING;
      notCompletedFlag = VALUE_Y;
    }
    try {
      const { data: triggeredData = [] } = await triggerSearchResult({
        mainDistrictId: selectedBranchId,
        customerId,
        serviceDateFrom: dayjs(SearchFilterForm.values.fromDate).format(
          t("common.isDateTimeFormat")
        ),
        serviceDateTo: dayjs(SearchFilterForm.values.toDate).format(
          t("common.isDateTimeFormat")
        ),
        routeName,
        requestTypeId,
        requestStatusId,
        notCompletedFlag
      });

      setSearchResults(triggeredData);

      if (triggeredData.length > 0) {
        setDisableFindButton(true);
        setDisablePrintButton(false);
      }

      if (triggeredData.length === 0) {
        setDisablePrintButton(true);
        setDisableFindButton(true);
      }

      if (triggeredData.length > 100) {
        setWarningAlertOpen(true);
      }

      if (triggeredData.length < 100) {
        setWarningAlertOpen(false);
      }
    } catch (error) {
      if (error.inner) {
        error.inner.forEach((e) => {
          SearchFilterForm.setFieldError(e.path, e.message);
        });
      }
    }
  };

  const handleCloseAlert = (index) => {
    const updatedErrorMessages = [...errorMessages];
    updatedErrorMessages.splice(index, 1);
    setErrorMessages(updatedErrorMessages);
  };

  useEffect(() => {
    const fillForm = () => {
      if (startDate && endDate) {
        SearchFilterForm.setValues({
          ...initFilterState,
          fromDate: dayjs(startDate),
          toDate: dayjs(endDate),
          requestStatus: requestStatusId === undefined ? 1 : requestStatusId,
          limitRowCountFlag:
            limitRowCountFlag === undefined
              ? VALUE_EMPTY_STRING
              : limitRowCountFlag
        });
        setParamsDataSet(true);
        window.history.replaceState(
          null,
          "",
          `${REQUEST_MODULE_BASE_PATH}/${REQUEST_SEARCH}`
        );
      }
    };

    fillForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (paramsDataSet) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsDataSet]);

  useEffect(() => {
    if (Object.keys(persistedState?.stateToPersist).length !== 0) {
      SearchFilterForm.setFieldValue(
        "fromDate",
        persistedState?.stateToPersist?.formData?.fromDate
      );
      SearchFilterForm.setFieldValue(
        "toDate",
        persistedState?.stateToPersist?.formData?.toDate
      );
      SearchFilterForm.setFieldValue(
        "customer",
        persistedState?.stateToPersist?.formData?.customer
      );
      SearchFilterForm.setFieldValue(
        "requestType",
        persistedState?.stateToPersist?.formData?.requestType
      );
      SearchFilterForm.setFieldValue(
        "routeName",
        persistedState?.stateToPersist?.formData?.routeName
      );
      SearchFilterForm.setFieldValue(
        "requestStatus",
        persistedState?.stateToPersist?.formData?.requestStatus
      );
      setSearchResults(persistedState?.stateToPersist?.tableData);
      setDisableFindButton(
        persistedState?.stateToPersist?.buttonsDisable?.findButton
      );
      setDisablePrintButton(
        persistedState?.stateToPersist?.buttonsDisable?.printButton
      );
      setPage(persistedState?.tableDefaults?.page);
      setRowsPerPage(persistedState?.tableDefaults?.rowsPerPage);
      setTableSearchText(persistedState?.tableDefaults?.tableSearchText);
      setTableColumns(persistedState?.tableDefaults?.tableColumns);
      setTableFilterList(persistedState?.tableDefaults?.tableFilterList);
      dispatchStore(resetRequestPersistedData());
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {(isLocaleLoading || isLoading || pageLoading) && (
        <FullCircularLoader
          loading={isLocaleLoading || isLoading || pageLoading}
        />
      )}

      <Collapse in={throwError}>
        <Alert
          severity="error"
          sx={{ marginBottom: spacing.horizontalMargin20 }}
          icon={<img src={ErrorIcon} alt="error" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setThrowError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {throwErrorMessage}
        </Alert>
      </Collapse>

      <form>
        {[...errorMessages].map((message, index) => (
          <Alert
            key={index}
            severity="error"
            sx={{
              marginTop: "20px"
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => handleCloseAlert(index)}
              >
                <GridCloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        ))}
        <Stack
          direction="column"
          gap={spacing.verticalMargin20}
          mb={spacing.verticalMargin32}
        >
          <Stack direction="row">
            <Typography variant="h4">
              {t("requestSearch.requestSearchPageTitle")}
            </Typography>
          </Stack>
          <Grid container spacing={spacing.horizontalMargin20}>
            <Grid item xs={3} id="req-search-date-from">
              <DatePickerField
                id="fromDate"
                label={t("requestSearch.dateFrom")}
                value={SearchFilterForm.values.fromDate}
                locale={localeByBranch || DEFAULT_LOCALE}
                onChange={async (date) => {
                  // Update toDate without triggering touched event
                  const toDateWithoutTouched = (date) => {
                    SearchFilterForm.setFieldValue("toDate", date);
                  };
                  await new Promise((resolve) => {
                    // Update fromDate and trigger touched event
                    SearchFilterForm.setFieldValue("fromDate", date);
                    SearchFilterForm.setFieldValue("routeName", 0);
                    resolve();
                  });

                  setDayPattern(
                    generateDayPattern(
                      SearchFilterForm.values.fromDate,
                      SearchFilterForm.values.toDate
                    )
                  );
                  handleSelectFieldReset();
                  toDateWithoutTouched(date);
                }}
                error={!!SearchFilterForm.errors.fromDate}
                helperText={SearchFilterForm.errors.fromDate}
                variant="input"
                minDate={minDate}
                maxDate={maxDate}
              />
            </Grid>

            <Grid item xs={3} id="req-search-date-to">
              <DatePickerField
                id="toDate"
                label={t("requestSearch.dateTo")}
                value={SearchFilterForm.values.toDate}
                locale={localeByBranch || DEFAULT_LOCALE}
                onChange={async (date) => {
                  await new Promise((resolve) => {
                    SearchFilterForm.setFieldValue("toDate", date);
                    resolve();
                  });
                  handleSelectFieldReset();
                  setDayPattern(
                    generateDayPattern(
                      SearchFilterForm.values.fromDate,
                      SearchFilterForm.values.toDate
                    )
                  );
                  SearchFilterForm.setFieldValue("routeName", 0);
                }}
                error={!!SearchFilterForm.errors.toDate}
                helperText={SearchFilterForm.errors.toDate}
                minDate={minDate}
                maxDate={maxDate}
              />
            </Grid>
          </Grid>
          <Grid container spacing={spacing.horizontalMargin20}>
            <Grid item xs={3}>
              <CustomersField
                isOptionEqualToValue={(option, value) => {
                  return option?.value === value;
                }}
                noOptionsText={t("requestSearch.customerNotFound")}
                form={{ touched: {}, errors: {} }}
                options={customers}
                label={t("requestSearch.customer")}
                value={SearchFilterForm.values.customer || null}
                handleOnChange={(event, newValue) => {
                  const selectedCustomer = customers.find(
                    (customer) => customer?.value === newValue?.value,
                    customer.label === newValue?.label &&
                      customer.number === newValue?.number
                  );
                  SearchFilterForm.setFieldValue("customer", selectedCustomer);
                  handleSelectFieldReset();
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <SelectField
                options={requests}
                labelId="requestType"
                label={t("requestSearch.requestType")}
                value={SearchFilterForm.values.requestType}
                onChange={(event) => {
                  SearchFilterForm.setFieldValue(
                    "requestType",
                    event.target.value
                  );
                  handleSelectFieldReset();
                }}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={3}>
              <SelectField
                label={t("requestSearch.route")}
                labelId="route"
                value={SearchFilterForm.values.routeName}
                onChange={(event) => {
                  SearchFilterForm.setFieldValue(
                    "routeName",
                    event.target.value
                  );
                  handleSelectFieldReset();
                }}
                options={routes}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={3}>
              <SelectField
                label={t("requestSearch.requestStatus")}
                labelId="requestStatus"
                value={
                  paramsDataSet ? null : SearchFilterForm.values.requestStatus
                }
                onChange={(event) => {
                  SearchFilterForm.setFieldValue(
                    "requestStatus",
                    event.target.value
                  );
                  handleSelectFieldReset();
                }}
                options={statuses}
                defaultValue=""
              />
            </Grid>
          </Grid>
          <Grid container spacing={spacing.horizontalMargin20}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(
                    `${REQUEST_MODULE_BASE_PATH}/${MEDIA_REQUEST_DETAIL}`
                  )
                }
              >
                {t("common.newRequest")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                disabled={disablePrintButton}
                onClick={() => {
                  if (childRef.current) {
                    childRef.current.handleToggle();
                  }
                }}
              >
                {t("common.print")}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleClear}>
                {t("common.clear")}
              </Button>
            </Grid>

            <Grid item>
              <Button
                disabled={
                  disableFindButton ||
                  Object.keys(SearchFilterForm.errors).length > 0
                }
                type="button"
                variant="contained"
                sx={SearchFilterStyles.findButton}
                onClick={() => handleSearch()}
              >
                {t("common.find")}
              </Button>
            </Grid>
          </Grid>
        </Stack>
        <Stack>
          <Collapse
            in={warningAlertOpen}
            sx={{
              marginBottom: warningAlertOpen ? spacing.horizontalMargin20 : 0
            }}
          >
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setWarningAlertOpen(false)}
                >
                  <GridCloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {t("requestSearch.searchRecordsLimitWarningMessage")}
            </Alert>
          </Collapse>
          <SearchTable
            {...{
              searchResults,
              isLoading,
              status,
              buttonClicked,
              setButtonClicked,
              SearchFilterForm,
              persistedState,
              disableFindButton,
              disablePrintButton,
              rowsPerPage,
              setRowsPerPage,
              page,
              setPage,
              tableSearchText,
              setTableSearchText,
              tableColumns,
              setTableColumns,
              tableFilterList,
              setTableFilterList
            }}
            locale={localeByBranch || DEFAULT_LOCALE}
            paramsDataSet={paramsDataSet}
            timeZoneOffset={timeZoneOffset}
          />
        </Stack>
      </form>
      <PrintRequestSearchModal
        ref={childRef}
        fromDate={SearchFilterForm.values.fromDate}
        toDate={SearchFilterForm.values.toDate}
        selectedCustomer={SearchFilterForm.values.customer}
        throwError={throwError}
        setThrowError={setThrowError}
        throwErrorMessage={throwErrorMessage}
        setThrowErrorMessage={setThrowErrorMessage}
        formValues={SearchFilterForm}
        setIsLoading={setPageLoading}
      />
    </>
  );
};

export default SearchFilter;
