/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CONTAINER_ISSUANCE,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import {
  downloadOptionPdf,
  ERROR_TYPES,
  myYearMonthDayFormat,
  todoContainers,
  TransportsPendingSelectionCriteria,
  VALUE_EMPTY_STRING,
  Y_CHECKER
} from "utils/constants";
import { zero } from "utils/constants/customer-information/PayInvoiceFilter";
import { programTypes } from "utils/constants/vault-configuration/VaultConfiguration";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData,
  getUserName,
  printReport
} from "utils/helpers";
import ToDoItem from "./ToDoItem";

const Containers = ({
  setShowErrorAlert,
  setShowErrorAlertMsg,
  setIsLoading,
  currentBranch
}) => {
  const navigate = useNavigate();
  const { auth } = useSelector(selectAuth);

  //containers tab states of todo items
  const [containersTransports, setContainersTransports] = useState(0);
  const [containersinPendingDistribution, setContainersinPendingDistribution] =
    useState(0);
  const [containerRackingNearCapacity, setContainerRackingNearCapacity] =
    useState(0);
  const [transportsonHold, setTransportsonHold] = useState(0);
  const [transportsinPendingDistribution, setTransportsinPendingDistribution] =
    useState(0);

  // selected download type
  const [selectedReportType, setSelectedReportType] =
    useState(downloadOptionPdf);
  const [reportCUrl, setReportCFUrl] = useState(VALUE_EMPTY_STRING);
  const [cardTextLabel, setCardTextLabel] = useState("");
  const [downloadReport, setDownloadReport] = useState({
    open: false,
    reportUrl: null,
    requestBody: null
  });
  const [throwError, setThrowError] = useState(false); // error message show/hide state in report download modal
  const [throwErrorMessage, setThrowErrorMessage] = useState(""); // error message state in report download modal
  const userName = getUserName(auth?.user);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );
  //get containers data
  const getContainersData = async (district_id) => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: district_id,
        spid: ""
      };

      const containersResponse = await getResponseData(
        reqBody,
        `${CF_URLS.dashboard.todo.todoContainers}`,
        6
      );
      setContainersTransports(parseInt(containersResponse?.data[0][0]?.count));
      setContainersinPendingDistribution(
        parseInt(containersResponse?.data[1][0]?.count)
      );
      setContainerRackingNearCapacity(
        parseInt(containersResponse?.data[3][0]?.count)
      );
      setTransportsonHold(parseInt(containersResponse?.data[4][0]?.count));
      setTransportsinPendingDistribution(
        parseInt(containersResponse?.data[5][0]?.count)
      );

      setIsLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  //on card click
  const onCardClick = async (labelText) => {
    setCardTextLabel(labelText);
    switch (labelText) {
      case t("dashboard.containersTransportstobeIssued"):
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${CONTAINER_ISSUANCE}/${Y_CHECKER}`
        );
        break;
      case t("dashboard.containersinPendingDistribution"):
        setDownloadReport((prevState) => ({
          open: true,
          reportUrl: null,
          requestBody: null
        }));
        break;
      case t("dashboard.containerRackingNearCapacity"):
        setDownloadReport((prevState) => ({
          open: true,
          reportUrl:
            CF_URLS.printReports.vaultConfiguration.rackingNearCapacityReport,
          requestBody: {
            main_district_id: currentBranch?.district_id,
            thecount: zero,
            count_only_flag: VALUE_EMPTY_STRING,
            container_media_flag: programTypes.c,
            interface_from: todoContainers,
            district_name: currentBranch?.label,
            user_name: getUserName(auth?.user),
            report_format: selectedReportType?.type?.toUpperCase()
          }
        }));
        break;
      case t("dashboard.transportsonHold"):
        setDownloadReport((prevState) => ({
          open: true,
          reportUrl: null,
          requestBody: null
        }));
        break;
      case t("dashboard.transportsinPendingDistribution"):
        setDownloadReport((prevState) => ({
          open: true,
          reportUrl: null,
          requestBody: null
        }));
        break;
      default:
    }
  };

  // donwload report cancel click event handler
  const handleDownloadReportCancelOnClick = () => {
    setDownloadReport((prevState) => ({
      open: false,
      reportUrl: null,
      requestBody: null
    }));
    setThrowErrorMessage((prevState) => "");
    setThrowError((prevState) => false);
    setReportCFUrl(VALUE_EMPTY_STRING);
  };

  const requestBodyPayload = {
    main_district_id: mainDistrictId,
    user_name: userName,
    report_format: selectedReportType?.type?.toUpperCase()
  };

  useEffect(() => {
    if (downloadReport.open) {
      if (cardTextLabel === t("dashboard.transportsonHold")) {
        setReportCFUrl(CF_URLS.printReports.todoReports.transportOnHoldReport);
      }
      if (cardTextLabel === t("dashboard.transportsinPendingDistribution")) {
        setReportCFUrl(CF_URLS.printReports.todoReports.transportsPending);
      }
      if (cardTextLabel === t("dashboard.containersinPendingDistribution")) {
        setReportCFUrl(
          CF_URLS.printReports.todoReports.containersPendingDistribution
        );
      }
      if (cardTextLabel === t("dashboard.containerRackingNearCapacity")) {
        setReportCFUrl(
          CF_URLS.printReports.vaultConfiguration.rackingNearCapacityReport
        );
      }
    } else {
      setReportCFUrl(VALUE_EMPTY_STRING);
    }
  }, [downloadReport.open]);

  // download report click event handler
  const handleDownloadOnClick = async () => {
    if (cardTextLabel === t("dashboard.transportsonHold")) {
      const requestBody = {
        ...requestBodyPayload,
        district_id: mainDistrictId,
        run_id: "",
        containers_only: zero,
        count: "",
        district_name: currentBranch?.label
      };

      try {
        setIsLoading(true);
        const reqBody = JSON.stringify(requestBody);

        const reportResponse = await printReport(
          reqBody,
          CF_URLS.printReports.todoReports.transportOnHoldReport
        );
        if (!reportResponse.success) {
          setThrowErrorMessage((prevState) => reportResponse.error);
          setThrowError((prevState) => true);
        }
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage(
          (prevState) => e.message || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError((prevState) => true);
      } finally {
        setIsLoading((prevState) => false);
      }
    }
    if (cardTextLabel === t("dashboard.transportsinPendingDistribution")) {
      const requestBody = {
        ...requestBodyPayload,
        selection_criteria: TransportsPendingSelectionCriteria,
        prior_to_date: dayjs().format(myYearMonthDayFormat)
      };
      try {
        setIsLoading(true);
        const reqBody = JSON.stringify(requestBody);

        const reportResponse = await printReport(
          reqBody,
          CF_URLS.printReports.todoReports.transportsPending
        );
        if (!reportResponse.success) {
          setThrowErrorMessage((prevState) => reportResponse.error);
          setThrowError((prevState) => true);
        }
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage(
          (prevState) => e.message || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError((prevState) => true);
      } finally {
        setIsLoading((prevState) => false);
      }
    }
    if (cardTextLabel === t("dashboard.containersinPendingDistribution")) {
      try {
        setIsLoading(true);
        const reqBody = JSON.stringify(requestBodyPayload);

        const reportResponse = await printReport(
          reqBody,
          CF_URLS.printReports.todoReports.containersPendingDistribution
        );
        if (!reportResponse.success) {
          setThrowErrorMessage((prevState) => reportResponse.error);
          setThrowError((prevState) => true);
        }
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage(
          (prevState) => e.message || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError((prevState) => true);
      } finally {
        setIsLoading((prevState) => false);
      }
    }
    if (cardTextLabel === t("dashboard.containerRackingNearCapacity")) {
      try {
        setIsLoading(true);
        const reqBody = JSON.stringify({
          ...requestBodyPayload,
          district_id: currentBranch?.district_id,
          thecount: zero,
          container_media_flag: programTypes.c,
          interface_from: todoContainers,
          count_only_flag: VALUE_EMPTY_STRING,
          district_name: currentBranch?.name?.replace("_", " ")
        });

        const reportResponse = await printReport(
          reqBody,
          CF_URLS.printReports.vaultConfiguration.rackingNearCapacityReport
        );
        if (!reportResponse.success) {
          setThrowErrorMessage(reportResponse.error);
          setThrowError(true);
        }
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage(
          () => e.message || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  //get containers data when loading and branch changing
  useEffect(() => {
    getContainersData(currentBranch?.district_id);
  }, [currentBranch?.district_id]);

  return (
    <>
      <Grid item xs={12} md={6}>
        <ToDoItem
          labelText={t("dashboard.containersTransportstobeIssued")}
          number={containersTransports}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.containersinPendingDistribution")}
          number={containersinPendingDistribution}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.containerRackingNearCapacity")}
          number={containerRackingNearCapacity}
          onCardClick={onCardClick}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ToDoItem
          labelText={t("dashboard.transportsonHold")}
          number={transportsonHold}
          onCardClick={onCardClick}
        />
        <ToDoItem
          labelText={t("dashboard.transportsinPendingDistribution")}
          number={transportsinPendingDistribution}
          onCardClick={onCardClick}
        />
      </Grid>

      {downloadReport.open && (
        <DownloadPopup
          title={t("common.downloadReport")}
          showPrintModal={downloadReport.open}
          onCancleClick={handleDownloadReportCancelOnClick}
          onDownloadClick={handleDownloadOnClick}
          setSelectedValue={setSelectedReportType}
          setThrowError={setThrowError}
          throwError={throwError}
          throwErrorMessage={throwErrorMessage}
          cfUrl={reportCUrl}
        />
      )}
    </>
  );
};

export default Containers;
