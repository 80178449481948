import UnauthorizedPage from "components/unauthorizedComponent/UnauthorizedPage";
import { Login, UnderConstruction } from "pages";
import BillingInterfaceRules from "pages/billing-module/BillingInterfaceRules";
import CapacityBillingGenerationSearchDetail from "pages/billing-module/CapacityBillingGenerationSearch";
import DefaultPricingUsageReportPage from "pages/billing-module/DefaultPricingUsageReport";
import RescheduleService from "pages/request-module/RescheduleService";
import { lazy } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { selectAuth } from "store/slices";
import {
  ADMIN_MODULE_AUTH_BASE_PATH,
  ADMIN_MODULE_BASE_PATH,
  ADMIN_MODULE_BILLING_BASE_PATH,
  ADMIN_MODULE_LABEL_BASE_PATH,
  ADMIN_MODULE_SECURITY_BASE_PATH,
  ASSIGN_SHELVES,
  BILLING_ERROR_LOG,
  BILLING_STATUS,
  BILLING_TRANSACTIONS,
  BRANCH_OUTAGE_PICK_LIST_COMPLETION,
  CAPACITY_BILLING_CONFIGURATION_SEARCH,
  CAPACITY_BILLING_GENERATION_SEARCH,
  CONTAINER_INVENTORY_AUDIT_DETAIL,
  CONTAINER_INVENTORY_AUDIT_SEARCH,
  CONTAINER_INVENTORY_VIEW_CAR,
  CONTAINER_INVENTORY_VIEW_DISCREPANCIES,
  CONTAINER_ISSUANCE,
  CONTAINER_PROCESSING_RESEARCH,
  CONTAINER_PROCESSING_RESEARCH_TRANSACTION_DETAIL,
  CORRECTIVE_ACTION_REQUESTS_DETAIL,
  CORRECTIVE_ACTION_REQUESTS_SEARCH,
  CREATE_DISTRIBUTION_LIST,
  CUSTOMER_BILLING_INFORMATION,
  CUSTOMER_FILE_SEARCH,
  CUSTOMER_MOVE_DETAIL,
  CUSTOMER_MOVE_SEARCH,
  DEFAULT_PRICING_USE_REPORT,
  DISCREPANCY_MANAGEMENT,
  DISCREPANCY_MANAGEMENT_DETAIL,
  DISCREPANCY_MANAGEMENT_UPDATE,
  EMPLOYEE,
  EMPLOYEE_DETAIL,
  EMPLOYEE_SEARCH,
  ENTERPRISE_BILLING_SUMMARY,
  EVENT,
  EVENT_REQUEST_GENERATOR_DELIVERY,
  EVENT_REQUEST_GENERATOR_OTHER,
  EVENT_SEARCH,
  GENERAL,
  GROUP_EMPLOYEE,
  GROUP_MANAGEMENT,
  INSTRUCTION_FILES,
  INSTRUCTION_FILES_CUSTOMER,
  LABEL_PRINTER_SETUP,
  LABEL_TEMPLATE_MAINTENANCE,
  LABEL_TEMPLATE_SEARCH,
  LOGICAL_VAULT_AUDIT_SEARCH,
  LOGICAL_VAULT_DETAILS,
  LOGIN,
  MAINTAIN_BILLING_RULES,
  MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY,
  MEDIA_DESTRUCTION_REQUEST_DETAIL_PICKUP,
  MEDIA_DESTRUCTION_REQUEST_DETAIL_VAULT_ITEMIZED,
  MEDIA_DESTRUCTION_REQUEST_SEARCH,
  MEDIA_REQUEST_DETAIL,
  NOTIFICATION_CONFIGURATION,
  OPEN_MEDIA_AUDIT_DETAIL,
  OPEN_MEDIA_AUDIT_SEARCH,
  OPEN_MEDIA_PROCESSING_RESEARCH,
  OPEN_MEDIA_PROCESSING_RESEARCH_DETAILS,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH,
  OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH,
  OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH,
  OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH,
  OPERATIONS_MODULE_LOGICAL_VAULT_BASE_PATH,
  OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH,
  OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH,
  PAYINVOICE,
  PAYROLL,
  PERSONAL_DETAIL,
  PERSONAL_HISTORY,
  PERSONAL_SEARCH,
  PERSONAL_VALIDATION,
  PREFERENCES,
  PREFERENCES_ADD_ONCUTOFF_DEADLINE,
  PREFERENCES_DONT_CALL_DESC,
  PREFERENCES_EMAIL_NOTIFICATION,
  PREFERENCES_HOLIDAY_SERVICE,
  PREFERENCES_MINIMUM_EMPTY_TRANSPORTS_REQUIRED_AT_CUSTOMER_SITE,
  PREFERENCES_PLACE_HOLD_ON_MEDIA_DESTRUCTION_REQUESTS,
  PREFERENCES_SCAN_ON_SITE,
  PREFERENCES_SERVICE_INDICATOR,
  PRINT_TAPE_LABEL,
  REASSIGN_TRANSPORT_CONTENTS,
  RECURRING_CHANGE_SEARCH,
  REPORTS_MODULE_BASE_PATH,
  REPORTS_MODULE_VAULT_PATH,
  REQUEST_MODULE_BASE_PATH,
  REQUEST_MODULE_DESTRUCTION_BASE_PATH,
  REQUEST_SEARCH,
  RESCHEDULE_SERVICE,
  ROOT,
  ROUTE_DETAIL,
  ROUTE_MANAGEMENT,
  RUN_MANAGEMENT,
  SCHEDULE,
  SERVICE_INFORMATION,
  SERVICE_WINDOWS,
  TEST_LABEL_TEMPLATE,
  UNDER_CONSTRUCTION,
  VAULT_CONFIGURATION,
  VAULT_STATUS_REPORT,
  VENDOR_MANAGEMENT,
  VIEW_CAR,
  View_Discrepancies,
  VIEW_DR_MEDIA,
  WORK_MANAGEMENT
} from "./Paths";
import ProtectedRoute from "./ProtectedRoute";
import RouteElement from "./RouteElement";

/* 
Lazy imports 
*/
const Dashboard = lazy(() => import("pages/dashboard/Dashboard"));
const MediaRequestDetail = lazy(
  () => import("pages/request-module/MediaRequestDetail")
);
const MediaDestructionRequestDetailVaultItemized = lazy(
  () =>
    import("pages/request-module/MediaDestructionRequestDetailVaultItemized")
);
const MediaDestructionRequestSearch = lazy(
  () => import("pages/request-module/MediaDestructionRequestSearch")
);
const MedDestReqPickupDetails = lazy(
  () => import("pages/request-module/MDRDPickupDetails")
);

function RouterConfig() {
  const { roleAccess, currentBranch } = useSelector(selectAuth);
  const selectedBranchId = currentBranch?.district_id;
  return (
    <BrowserRouter>
      <Routes>
        <Route path={LOGIN} element={<Login />} />
        <Route element={<ProtectedRoute />}>
          {/* Dashboard Module Start */}
          <Route path={ROOT} element={<Dashboard />} />
          {/* Dashboard Module End */}

          {/* Administration Module Start */}
          <Route path={`${ADMIN_MODULE_BASE_PATH}/*`}>
            {/* PAYROLL */}
            <Route path={PAYROLL} element={<UnderConstruction />} />
            <Route path={`${ADMIN_MODULE_BILLING_BASE_PATH}/*`}>
              {/* BILLING_STATUS */}
              <Route
                path={BILLING_STATUS}
                element={<RouteElement path={BILLING_STATUS} role={"33"} />}
              />
              <Route
                path={`${BILLING_STATUS}/:selectedBranch/:selectedBillCycle/:selectedBillDate`}
                element={<RouteElement path={BILLING_STATUS} role={"33"} />}
              />
              {/* BILLING_TRANSACTIONS */}
              <Route
                path={BILLING_TRANSACTIONS}
                element={
                  <RouteElement path={BILLING_TRANSACTIONS} role={"34"} />
                }
              />

              <Route
                path={`${BILLING_TRANSACTIONS}/*`}
                element={
                  <RouteElement path={BILLING_TRANSACTIONS} role={"34"} />
                }
              />
              {/* BILLING_ERROR_LOG */}
              <Route
                path={BILLING_ERROR_LOG}
                element={<RouteElement path={BILLING_ERROR_LOG} role={"35"} />}
              />
              {/* ENTERPRISE_BILLING_SUMMARY */}
              <Route
                path={ENTERPRISE_BILLING_SUMMARY}
                element={<RouteElement path={ENTERPRISE_BILLING_SUMMARY} />}
              />
              {/* MAINTAIN_BILLING_RULES */}
              <Route
                path={MAINTAIN_BILLING_RULES}
                element={
                  selectedBranchId ? (
                    <BillingInterfaceRules />
                  ) : (
                    <UnauthorizedPage />
                  )
                }
              />
              {/* customerBillingInformation */}
              <Route
                path={CUSTOMER_BILLING_INFORMATION}
                element={<RouteElement path={CUSTOMER_BILLING_INFORMATION} />}
              />
              {/* DEFAULT_PRICING_USE_REPORT */}
              <Route
                path={DEFAULT_PRICING_USE_REPORT}
                element={<DefaultPricingUsageReportPage />}
              />
              {/* RECURRING_CHANGE_SEARCH */}
              <Route
                path={RECURRING_CHANGE_SEARCH}
                element={
                  <RouteElement path={RECURRING_CHANGE_SEARCH} role={"160"} />
                }
              />
              {/* RECURRING_CHANGE_DETAIL */}
              <Route
                path={`${RECURRING_CHANGE_SEARCH}/:id`}
                element={
                  <RouteElement path={RECURRING_CHANGE_SEARCH} role={"160"} />
                }
              />

              {/* CAPACITY_BILLING_CONFIGURATION_SEARCH */}
              <Route
                path={CAPACITY_BILLING_CONFIGURATION_SEARCH}
                element={
                  <RouteElement path={CAPACITY_BILLING_CONFIGURATION_SEARCH} />
                }
              />
              {/* CAPACITY_BILLING_GENERATION_SEARCH */}
              <Route
                path={CAPACITY_BILLING_GENERATION_SEARCH}
                element={<CapacityBillingGenerationSearchDetail />}
              />
            </Route>
            <Route path={`${ADMIN_MODULE_AUTH_BASE_PATH}/*`}>
              {/* PERSONAL_DETAIL */}
              <Route
                path={PERSONAL_DETAIL}
                element={<RouteElement path={PERSONAL_DETAIL} role={"24"} />}
              />
              <Route
                path={`${PERSONAL_DETAIL}/:personnalID`}
                element={<RouteElement path={PERSONAL_DETAIL} role={"24"} />}
              />
              {/* PERSONAL_SEARCH */}
              <Route
                path={PERSONAL_SEARCH}
                element={<RouteElement path={PERSONAL_SEARCH} role={"11"} />}
              />
              {/* PERSONAL_VALIDATION */}
              <Route
                path={PERSONAL_VALIDATION}
                element={
                  <RouteElement path={PERSONAL_VALIDATION} role={"316"} />
                }
              />
              <Route
                path={`${PERSONAL_HISTORY}/:id`}
                element={<RouteElement path={PERSONAL_HISTORY} role={"316"} />}
              />
            </Route>
            <Route path={`${ADMIN_MODULE_LABEL_BASE_PATH}/*`}>
              {/* PRINT_TAPE_LABEL */}
              <Route
                path={PRINT_TAPE_LABEL}
                element={<RouteElement path={PRINT_TAPE_LABEL} role={"354"} />}
              />
              {/* LABEL_PRINTER_SETUP */}
              <Route
                path={LABEL_PRINTER_SETUP}
                element={
                  <RouteElement path={LABEL_PRINTER_SETUP} role={"353"} />
                }
              />
            </Route>
            <Route path={`${ADMIN_MODULE_SECURITY_BASE_PATH}/*`}>
              {/* EMPLOYEE */}
              <Route
                path={EMPLOYEE}
                element={
                  roleAccess.includes("26") ? (
                    <UnderConstruction />
                  ) : (
                    <UnauthorizedPage />
                  )
                }
              />
              {/* GROUP_EMPLOYEE */}
              <Route
                path={GROUP_EMPLOYEE}
                element={<RouteElement path={GROUP_EMPLOYEE} role={"160"} />}
              />
              {/* GROUP_MANAGEMENT */}
              <Route
                path={GROUP_MANAGEMENT}
                element={<RouteElement path={GROUP_MANAGEMENT} role={"28"} />}
              />
            </Route>
            {/* EMPLOYEE_DETAIL */}
            <Route
              path={EMPLOYEE_DETAIL}
              element={<RouteElement path={EMPLOYEE_DETAIL} role={"12"} />}
            />
             <Route
              path={`${EMPLOYEE_DETAIL}/:id`}
              element={<RouteElement path={EMPLOYEE_DETAIL} role={"12"} />}
            />
            {/* NOTIFICATION_CONFIGURATION */}
            <Route
              path={NOTIFICATION_CONFIGURATION}
              element={
                <RouteElement path={NOTIFICATION_CONFIGURATION} role={"304"} />
              }
            />
            {/* EMPLOYEE_SEARCH */}
            <Route
              path={EMPLOYEE_SEARCH}
              element={<RouteElement path={EMPLOYEE_SEARCH} role={"13"} />}
            />

            {/* CUSTOMER_MOVE_SEARCH */}
            <Route
              path={CUSTOMER_MOVE_SEARCH}
              element={
                <RouteElement path={CUSTOMER_MOVE_SEARCH} role={"361"} />
              }
            />
            <Route
              path={`${CUSTOMER_MOVE_SEARCH}/:activeOnlyFlag`}
              element={
                <RouteElement path={CUSTOMER_MOVE_SEARCH} role={"361"} />
              }
            />

            {/* CUSTOMER_MOVE_DETAIL */}
            <Route
              path={CUSTOMER_MOVE_DETAIL}
              element={
                <RouteElement path={CUSTOMER_MOVE_DETAIL} role={"361"} />
              }
            />
          </Route>
          {/* Administration Module End */}

          {/* Requests Module Start */}
          <Route path={`${REQUEST_MODULE_BASE_PATH}/*`}>
            {/* MediaRequestDetail Create*/}
            <Route
              path={`${MEDIA_REQUEST_DETAIL}`}
              element={<MediaRequestDetail />}
            />
            {/* MediaRequestDetail View/Edit */}
            <Route
              path={`${MEDIA_REQUEST_DETAIL}/:id`}
              element={<MediaRequestDetail />}
            />
            {/* RequestSearch */}
            <Route
              path={REQUEST_SEARCH}
              element={<RouteElement path={REQUEST_SEARCH} role={"49"} />}
            />
            <Route
              path={`${REQUEST_SEARCH}/:startDate/:endDate`}
              element={<RouteElement path={REQUEST_SEARCH} role={"49"} />}
            />
            <Route
              path={`${REQUEST_SEARCH}/:startDate/:endDate/:requestStatusId/:limitRowCountFlag`}
              element={<RouteElement path={REQUEST_SEARCH} role={"49"} />}
            />
            {/* RescheduleService */}
            <Route path={RESCHEDULE_SERVICE} element={<RescheduleService />} />
            {/* MediaDestructionRequestSearch */}
            <Route
              path={MEDIA_DESTRUCTION_REQUEST_SEARCH}
              element={<MediaDestructionRequestSearch />}
            />
            <Route
              path={`${MEDIA_DESTRUCTION_REQUEST_SEARCH}/:destructRequestStatusId/:systemId`}
              element={<MediaDestructionRequestSearch />}
            />
            <Route path={`${REQUEST_MODULE_DESTRUCTION_BASE_PATH}/*`}>
              {/* MedDestReqPickupDetails */}
              <Route
                path={MEDIA_DESTRUCTION_REQUEST_DETAIL_PICKUP}
                element={
                  <RouteElement
                    path={MEDIA_DESTRUCTION_REQUEST_DETAIL_PICKUP}
                    role={"290"}
                  />
                }
              />

              {/* MediaDestructionRequestDetailDelivery */}
              <Route
                path={`${MEDIA_DESTRUCTION_REQUEST_DETAIL_PICKUP}/:id`}
                element={<MedDestReqPickupDetails />}
              />
              <Route
                path={MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY}
                element={
                  <RouteElement
                    path={MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY}
                    role={"342"}
                  />
                }
              />
              {/* MediaDestructionRequestDetailDelivery */}
              <Route
                path={`${MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY}/:id`}
                element={
                  <RouteElement
                    path={MEDIA_DESTRUCTION_REQUEST_DETAIL_DELIVERY}
                    role={"342"}
                  />
                }
              />
              {/* MediaDestructionRequestDetailVaultItemized */}
              <Route
                path={MEDIA_DESTRUCTION_REQUEST_DETAIL_VAULT_ITEMIZED}
                element={<MediaDestructionRequestDetailVaultItemized />}
              />
              <Route
                path={`${MEDIA_DESTRUCTION_REQUEST_DETAIL_VAULT_ITEMIZED}/:id`}
                element={
                  <RouteElement
                    path={MEDIA_DESTRUCTION_REQUEST_DETAIL_VAULT_ITEMIZED}
                    multipleRole={["288", "287"]}
                  />
                }
              />
            </Route>
          </Route>
          {/* Requests Module End */}

          {/* Operations Module Start */}
          <Route path={`${OPERATIONS_MODULE_BASE_PATH}/*`}>
            <Route path={`${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/*`}>
              {/* SERVICE_INFORMATION */}
              <Route
                path={SERVICE_INFORMATION}
                element={
                  <RouteElement path={SERVICE_INFORMATION} role={"39"} />
                }
              />
              <Route
                path={`${SERVICE_INFORMATION}/:id`}
                element={
                  <RouteElement path={SERVICE_INFORMATION} role={"39"} />
                }
              />
              <Route
                path={`${SERVICE_INFORMATION}/:navigationData/:dataNavigation`}
                element={
                  <RouteElement path={SERVICE_INFORMATION} role={"39"} />
                }
              />
              {/* SCHEDULE */}
              <Route
                path={SCHEDULE}
                element={<RouteElement path={SCHEDULE} role={"43"} />}
              />
              <Route
                path={`${SCHEDULE}/:navigationData`}
                element={<RouteElement path={SCHEDULE} role={"43"} />}
              />
              {/* SERVICE_WINDOWS */}
              <Route
                path={SERVICE_WINDOWS}
                element={<RouteElement path={SERVICE_WINDOWS} role={"43"} />}
              />
              <Route
                path={`${SERVICE_WINDOWS}/:id`}
                element={<RouteElement path={SERVICE_WINDOWS} role={"43"} />}
              />
              <Route
                path={`${SERVICE_WINDOWS}/:navigationData/:dataNavigation`}
                element={<RouteElement path={SERVICE_WINDOWS} role={"43"} />}
              />
              {/* ROUTE_MANAGEMENT */}
              <Route
                path={ROUTE_MANAGEMENT}
                element={
                  <RouteElement
                    path={`${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${ROUTE_MANAGEMENT}`}
                    role={"51"}
                  />
                }
              />
              <Route
                path={`${ROUTE_MANAGEMENT}/:navigationData`}
                element={
                  <RouteElement
                    path={`${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${ROUTE_MANAGEMENT}`}
                    role={"51"}
                  />
                }
              />
              {/* LABEL_TEMPLATE_SEARCH */}
              <Route
                path={LABEL_TEMPLATE_SEARCH}
                element={
                  <RouteElement path={LABEL_TEMPLATE_SEARCH} role={"161"} />
                }
              />
              {/* LABEL_TEMPLATE_MAINTANANCE */}
              <Route
                path={LABEL_TEMPLATE_MAINTENANCE}
                element={
                  <RouteElement
                    path={LABEL_TEMPLATE_MAINTENANCE}
                    role={"161"}
                  />
                }
              />
              {/* TEST_LABEL_TEMPLATE */}
              <Route
                path={TEST_LABEL_TEMPLATE}
                element={
                  <RouteElement path={TEST_LABEL_TEMPLATE} role={"161"} />
                }
              />
              {/* PREFERENCES */}
              <Route
                path={PREFERENCES}
                element={<RouteElement path={PREFERENCES} />}
              />
              {/* PAYINVOICE */}
              <Route path={PREFERENCES}>
                <Route
                  path={PAYINVOICE}
                  element={
                    <RouteElement
                      path={`${PREFERENCES}/${PAYINVOICE}`}
                      multipleRole={["282", "284"]}
                    />
                  }
                />
              </Route>

              <Route
                path={`${PREFERENCES}/${PREFERENCES_PLACE_HOLD_ON_MEDIA_DESTRUCTION_REQUESTS}`}
                element={
                  <RouteElement
                    path={`${PREFERENCES}/${PREFERENCES_PLACE_HOLD_ON_MEDIA_DESTRUCTION_REQUESTS}`}
                    multipleRole={["282", "284"]}
                  />
                }
              />
              <Route
                path={`${PREFERENCES}/${PREFERENCES_SCAN_ON_SITE}`}
                element={
                  <RouteElement
                    path={`${PREFERENCES}/${PREFERENCES_SCAN_ON_SITE}`}
                    multipleRole={["282", "284"]}
                  />
                }
              />
              <Route
                path={`${PREFERENCES}/${PREFERENCES_HOLIDAY_SERVICE}`}
                element={
                  <RouteElement
                    path={`${PREFERENCES}/${PREFERENCES_HOLIDAY_SERVICE}`}
                    multipleRole={["282", "284"]}
                  />
                }
              />
              <Route
                path={`${PREFERENCES}/${PREFERENCES_SERVICE_INDICATOR}`}
                element={
                  <RouteElement
                    path={`${PREFERENCES}/${PREFERENCES_SERVICE_INDICATOR}`}
                    multipleRole={["282", "284"]}
                  />
                }
              />
              <Route
                path={`${PREFERENCES}/${PREFERENCES_EMAIL_NOTIFICATION}`}
                element={
                  <RouteElement
                    path={`${PREFERENCES}/${PREFERENCES_EMAIL_NOTIFICATION}`}
                    multipleRole={["282", "284"]}
                  />
                }
              />
              <Route
                path={`${PREFERENCES}/${PREFERENCES_DONT_CALL_DESC}`}
                element={
                  <RouteElement
                    path={`${PREFERENCES}/${PREFERENCES_DONT_CALL_DESC}`}
                    multipleRole={["282", "284"]}
                  />
                }
              />
              <Route
                path={`${PREFERENCES}/${PREFERENCES_ADD_ONCUTOFF_DEADLINE}`}
                element={
                  <RouteElement
                    path={`${PREFERENCES}/${PREFERENCES_ADD_ONCUTOFF_DEADLINE}`}
                    multipleRole={["282", "284"]}
                  />
                }
              />
              <Route
                path={`${PREFERENCES}/${PREFERENCES_MINIMUM_EMPTY_TRANSPORTS_REQUIRED_AT_CUSTOMER_SITE}`}
                element={
                  <RouteElement
                    path={`${PREFERENCES}/${PREFERENCES_MINIMUM_EMPTY_TRANSPORTS_REQUIRED_AT_CUSTOMER_SITE}`}
                    multipleRole={["282", "284"]}
                  />
                }
              />
            </Route>
            <Route path={`${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/*`}>
              {/* INSTRUCTION_FILES */}
              <Route
                path={`${INSTRUCTION_FILES}/*`}
                element={<RouteElement path={INSTRUCTION_FILES} role={"268"} />}
              />
              {/* INSTRUCTION_FILES_CUSTOMER */}
              <Route
                path={INSTRUCTION_FILES_CUSTOMER}
                element={<RouteElement path={INSTRUCTION_FILES} role={"268"} />}
              />
              {/* EVENT Create*/}
              <Route
                path={EVENT}
                element={<RouteElement path={EVENT} role={"257"} />}
              />
              {/* View DR media*/}
              <Route
                path={`${VIEW_DR_MEDIA}/:drId/:customerName/:customerId`}
                element={<RouteElement path={VIEW_DR_MEDIA} role={"257"} />}
              />
              {/* EVENT Edit */}
              <Route
                path={`${EVENT}/:id`}
                element={<RouteElement path={EVENT} role={"257"} />}
              />
              {/* EVENT Request Generator Delivery*/}
              <Route
                path={`${EVENT_REQUEST_GENERATOR_DELIVERY}`}
                element={
                  <RouteElement
                    path={EVENT_REQUEST_GENERATOR_DELIVERY}
                    role={"257"}
                  />
                }
              />
              {/* EVENT Request Generator Other*/}
              <Route
                path={`${EVENT_REQUEST_GENERATOR_OTHER}`}
                element={
                  <RouteElement
                    path={EVENT_REQUEST_GENERATOR_OTHER}
                    multipleRole={["257", "48"]}
                  />
                }
              />
              {/* EVENT_SEARCH */}
              <Route
                path={EVENT_SEARCH}
                element={<RouteElement path={EVENT_SEARCH} role={"256"} />}
              />
              <Route
                path={`${EVENT_SEARCH}/:statusId`}
                element={<RouteElement path={EVENT_SEARCH} role={"256"} />}
              />
            </Route>
            {/* DISCREPANCY_MANAGEMENT */}
            <Route
              path={DISCREPANCY_MANAGEMENT}
              element={
                <RouteElement
                  path={DISCREPANCY_MANAGEMENT}
                  multipleRole={["326", "327"]}
                />
              }
            />
            <Route
              path={`${DISCREPANCY_MANAGEMENT}/:discrepancystatus/:mediaType/:detectedIn/:discrepancyType`}
              element={
                <RouteElement
                  path={DISCREPANCY_MANAGEMENT}
                  multipleRole={["326", "327"]}
                />
              }
            />
            <Route
              path={`${DISCREPANCY_MANAGEMENT_DETAIL}/*`}
              element={
                <RouteElement
                  path={`${DISCREPANCY_MANAGEMENT_DETAIL}`}
                  multipleRole={["326", "327"]}
                />
              }
            />
            <Route
              path={DISCREPANCY_MANAGEMENT_UPDATE}
              element={
                <RouteElement
                  path={DISCREPANCY_MANAGEMENT_UPDATE}
                  role={"327"}
                />
              }
            />
            <Route path={`${OPERATIONS_MODULE_LOGICAL_VAULT_BASE_PATH}/*`}>
              <Route
                path={ASSIGN_SHELVES}
                element={<RouteElement path={ASSIGN_SHELVES} />}
              />
              {/* LOGICAL_VAULT_DETAILS */}
              <Route
                path={LOGICAL_VAULT_DETAILS}
                element={
                  <RouteElement path={LOGICAL_VAULT_DETAILS} role={"42"} />
                }
              />
              {/* LOGICAL_VAULT_AUDIT_SEARCH */}
              <Route
                path={LOGICAL_VAULT_AUDIT_SEARCH}
                element={
                  roleAccess.includes("186") ? (
                    <UnderConstruction />
                  ) : (
                    <UnauthorizedPage />
                  )
                }
              />
            </Route>
            <Route
              path={`${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/*`}
            >
              {/* GENERAL */}
              <Route
                path={`${GENERAL}/:date/:id`}
                element={<RouteElement path={GENERAL} />}
              />
              <Route
                path={`${GENERAL}`}
                element={<RouteElement path={GENERAL} />}
              />
              <Route
                path={`${GENERAL}/:id`}
                element={<RouteElement path={GENERAL} />}
              />
              <Route path={GENERAL} element={<RouteElement path={GENERAL} />} />
              {/* CONTAINER_ISSUANCE */}
              <Route
                path={CONTAINER_ISSUANCE}
                element={
                  <RouteElement
                    path={CONTAINER_ISSUANCE}
                    multipleRole={["40"]}
                  />
                }
              />
              <Route
                path={`${CONTAINER_ISSUANCE}/:allSelected`}
                element={
                  <RouteElement
                    path={CONTAINER_ISSUANCE}
                    multipleRole={["40"]}
                  />
                }
              />
              {/* CONTAINER_PROCESSING_RESEARCH */}
              <Route
                path={CONTAINER_PROCESSING_RESEARCH}
                element={
                  <RouteElement
                    path={CONTAINER_PROCESSING_RESEARCH}
                    multipleRole={["46"]}
                  />
                }
              />
              {/* CONTAINER_PROCESSING_RESEARCH_TRANSACTION_DETAIL */}
              <Route
                path={`${CONTAINER_PROCESSING_RESEARCH}/${CONTAINER_PROCESSING_RESEARCH_TRANSACTION_DETAIL}`}
                element={
                  <RouteElement
                    path={`${CONTAINER_PROCESSING_RESEARCH}/${CONTAINER_PROCESSING_RESEARCH_TRANSACTION_DETAIL}`}
                    multipleRole={["46"]}
                  />
                }
              />
              {/* REASSIGN_TRANSPORT_CONTENTS */}
              <Route
                path={REASSIGN_TRANSPORT_CONTENTS}
                element={
                  <RouteElement
                    path={REASSIGN_TRANSPORT_CONTENTS}
                    multipleRole={["175"]}
                  />
                }
              />
              {/* CONTAINER_INVENTORY_AUDIT_SEARCH */}
              <Route
                path={CONTAINER_INVENTORY_AUDIT_SEARCH}
                element={
                  <RouteElement
                    path={CONTAINER_INVENTORY_AUDIT_SEARCH}
                    multipleRole={["300"]}
                  />
                }
              />
              {/* CONTAINER_INVENTORY_AUDIT_DETAIL */}
              <Route
                path={`${CONTAINER_INVENTORY_AUDIT_DETAIL}*`}
                element={
                  <RouteElement
                    path={CONTAINER_INVENTORY_AUDIT_DETAIL}
                    multipleRole={["299"]}
                  />
                }
              />
              {/* CONTAINER_INVENTORY_AUDIT_DETAIL  View_Discrepancies*/}
              <Route
                path={CONTAINER_INVENTORY_VIEW_DISCREPANCIES}
                element={
                  roleAccess.includes("299") ? (
                    <UnderConstruction />
                  ) : (
                    <UnauthorizedPage />
                  )
                }
              />

              {/* CONTAINER_INVENTORY_AUDIT_DETAIL  VIEW_CAR*/}
              <Route
                path={CONTAINER_INVENTORY_VIEW_CAR}
                element={
                  roleAccess.includes("299") ? (
                    <UnderConstruction />
                  ) : (
                    <UnauthorizedPage />
                  )
                }
              />
            </Route>
            <Route
              path={`${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/*`}
            >
              {/* WORK_MANAGEMENT */}
              <Route
                path={WORK_MANAGEMENT}
                element={
                  // ["169", "190", "174", "176", "178"].some((value) =>
                  //   roleAccess.includes(value)
                  // ) ? (
                  //   <UnderConstruction />
                  // ) : (
                  //   <UnauthorizedPage />
                  // )
                  <RouteElement
                    path={WORK_MANAGEMENT}
                    multipleRole={["169", "190", "174", "176", "178"]}
                  />
                }
              />
              {/* OPEN_MEDIA_PROCESSING_RESEARCH */}
              <Route
                path={OPEN_MEDIA_PROCESSING_RESEARCH}
                element={
                  <RouteElement
                    path={`${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${OPEN_MEDIA_PROCESSING_RESEARCH}`}
                    role={"181"}
                  />
                }
              />

              {/* OPEN_MEDIA_PROCESSING_RESEARCH_DETAIL */}
              <Route
                path={`${OPEN_MEDIA_PROCESSING_RESEARCH_DETAILS}`}
                element={
                  <RouteElement
                    path={`${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${OPEN_MEDIA_PROCESSING_RESEARCH_DETAILS}`}
                    role={"181"}
                  />
                }
              />
              {/* BRANCH_OUTAGE_PICK_LIST_COMPLETION */}
              <Route
                path={BRANCH_OUTAGE_PICK_LIST_COMPLETION}
                element={
                  <RouteElement
                    path={BRANCH_OUTAGE_PICK_LIST_COMPLETION}
                    role={"206"}
                  />
                }
              />
              {/* CREATE_DISTRIBUTION_LIST */}
              <Route
                path={CREATE_DISTRIBUTION_LIST}
                element={
                  <RouteElement path={CREATE_DISTRIBUTION_LIST} role={"202"} />
                }
              />
              {/* CUSTOMER_FILE_SEARCH */}
              <Route
                path={CUSTOMER_FILE_SEARCH}
                element={
                  <RouteElement path={CUSTOMER_FILE_SEARCH} role={"201"} />
                }
              />
              {/* OPEN_MEDIA_AUDIT_SEARCH */}
              <Route
                path={OPEN_MEDIA_AUDIT_SEARCH}
                element={
                  <RouteElement path={OPEN_MEDIA_AUDIT_SEARCH} role={"307"} />
                }
              />
              {/* OPEN_MEDIA_AUDIT_DETAIL */}
              <Route
                path={`${OPEN_MEDIA_AUDIT_DETAIL}/*`}
                element={
                  <RouteElement path={OPEN_MEDIA_AUDIT_DETAIL} role={"308"} />
                }
              />
              {/* OPEN_MEDIA_AUDIT_DETAIL  View_Discrepancies*/}
              <Route
                path={View_Discrepancies}
                element={
                  roleAccess.includes("308") ? (
                    <UnderConstruction />
                  ) : (
                    <UnauthorizedPage />
                  )
                }
              />

              {/* OPEN_MEDIA_AUDIT_DETAIL  VIEW_CAR*/}
              <Route
                path={VIEW_CAR}
                element={
                  roleAccess.includes("308") ? (
                    <UnderConstruction />
                  ) : (
                    <UnauthorizedPage />
                  )
                }
              />
            </Route>
            <Route
              path={`${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/*`}
            >
              {/* CORRECTIVE_ACTION_REQUESTS_DETAIL */}
              <Route
                path={CORRECTIVE_ACTION_REQUESTS_DETAIL}
                element={
                  <RouteElement
                    path={CORRECTIVE_ACTION_REQUESTS_DETAIL}
                    role={"249"}
                  />
                }
              />
              {/* CORRECTIVE_ACTION_REQUESTS_SEARCH */}
              <Route
                path={`${CORRECTIVE_ACTION_REQUESTS_SEARCH}/:carTypeId/:carStatusId/:maxRowCount`}
                element={
                  <RouteElement
                    path={`${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_SEARCH}`}
                    role={"247"}
                  />
                }
              />
              <Route
                path={`${CORRECTIVE_ACTION_REQUESTS_SEARCH}`}
                element={
                  <RouteElement
                    path={`${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_SEARCH}`}
                    role={"247"}
                  />
                }
              />
            </Route>
            <Route path={`${OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/*`}>
              {/* ROUTE_DETAIL */}
              <Route
                path={ROUTE_DETAIL}
                element={<RouteElement path={ROUTE_DETAIL} role={"38"} />}
              />
              {/* RUN_MANAGEMENT */}
              <Route
                path={RUN_MANAGEMENT}
                element={<RouteElement path={RUN_MANAGEMENT} role={"52"} />}
              />
              <Route
                path={`${RUN_MANAGEMENT}/:date`}
                element={<RouteElement path={RUN_MANAGEMENT} role={"52"} />}
              />
              {/* VENDOR_MANAGEMENT */}
              <Route
                path={VENDOR_MANAGEMENT}
                element={<RouteElement path={VENDOR_MANAGEMENT} role={"44"} />}
              />
            </Route>
            {/* VAULT_CONFIGURATION */}
            <Route
              path={VAULT_CONFIGURATION}
              element={
                <RouteElement
                  path={VAULT_CONFIGURATION}
                  multipleRole={["159", "158"]}
                />
              }
            />
          </Route>
          {/* Operations Module End */}

          {/* Reports Module Start */}
          <Route
            path={`${REPORTS_MODULE_BASE_PATH}/*`}
            element={<RouteElement path={REPORTS_MODULE_BASE_PATH} />}
          />
          <Route
            path={`${REPORTS_MODULE_BASE_PATH}/${REPORTS_MODULE_VAULT_PATH}/${VAULT_STATUS_REPORT}`}
            element={<RouteElement path={VAULT_STATUS_REPORT} role={"328"} />}
          />

          {/* Reports Module End */}
          <Route path={UNDER_CONSTRUCTION} element={<UnderConstruction />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default RouterConfig;
