import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import CommonModal from "components/shared/common-modal/CommonModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { ERROR_TYPES, downloadOptionPdf } from "utils/constants";
import { reportTypes } from "utils/constants/transportation/RunManagementConstants";
import { findErrorMessage, getUserName, printReport } from "utils/helpers";
import { runManagementStyles } from "./RunManagementStyle";

const RunPrintModal = ({
  isShowPrintModal,
  setIsShowPrintModal,
  selectedRunId,
  currentRunInfoMain,
  mainDistrictId,
  throwError,
  throwErrorMessage,
  setThrowErrorMessage,
  setThrowError,
  setIsLoading,
  reportCFUrl,
  setReportCFUrl
}) => {
  const { auth } = useSelector(selectAuth);
  const { t } = useTranslation();
  const [reportType, setReportType] = useState(reportTypes.runSequence);
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);
  const [isShowDownloadModal, setIsShowDownloadModal] = useState(false);

  const handlePrint = () => {
    let url = null;
    if (reportType === reportTypes.runSequence) {
      url = CF_URLS.printReports.transportation.runSequenceReport;
    } else if (reportType === reportTypes.runSequenceWithDirections) {
      url = CF_URLS.printReports.transportation.runSequenceReportDirections;
    }
    setReportCFUrl(url);
    setThrowError(false);
    setIsShowDownloadModal(true);
  };

  const handleReportTypeChange = (e) => {
    setThrowError(false);
    setReportType(e.target.value);
  };

  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      let url;
      const requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        run_id: selectedRunId,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });

      if (reportType === reportTypes.runSequence) {
        url = CF_URLS.printReports.transportation.runSequenceReport;
      } else if (reportType === reportTypes.runSequenceWithDirections) {
        url = CF_URLS.printReports.transportation.runSequenceReportDirections;
      }
      let reportResponse = await printReport(requestBody, url);
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
      setIsShowDownloadModal(false);
    }
  };

  return (
    <>
      <CommonModal
        open={isShowPrintModal}
        title={t("runManagement.runSequenceReports")}
        body={
          <Stack direction="column">
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  sx={runManagementStyles.errorIconStyle}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <Typography sx={runManagementStyles.errorStyle}>
                    {throwErrorMessage}
                  </Typography>
                </Alert>
              </Collapse>
            )}

            <Grid container spacing={spacing.gap}>
              <Grid item>
                <Typography>
                  {t("common.reportFor")}: {currentRunInfoMain?.split(" ")[0]}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={spacing.gap}>
              <Grid id="radio-grid" item>
                <FormControl id="radio-control">
                  <RadioGroup
                    id="radio-group"
                    name="printType"
                    column
                    value={reportType}
                    onChange={(e) => handleReportTypeChange(e)}
                  >
                    <FormControlLabel
                      value={reportTypes.runSequence}
                      control={<Radio />}
                      label={t("runManagement.runSequence")}
                    />
                    <FormControlLabel
                      value={reportTypes.runSequenceWithDirections}
                      control={<Radio />}
                      label={t("runManagement.runSequenceWithDirections")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Stack>
        }
        buttons={
          <>
            <Button
              id="print-close-btn"
              variant="outlined"
              size="medium"
              type="button"
              onClick={() => setIsShowPrintModal(false)}
            >
              {t("common.close")}
            </Button>
            <Button
              id="print-btn"
              variant="contained"
              size="medium"
              type="submit"
              onClick={() => handlePrint()}
            >
              {t("common.print")}
            </Button>
          </>
        }
      />
      {/* Download Popup */}
      <DownloadPopup
        title={t("runManagement.runSequenceReports")}
        showPrintModal={isShowDownloadModal}
        cfUrl={reportCFUrl}
        onCancleClick={() => {
          setIsShowDownloadModal(false);
          setReportCFUrl("");
        }}
        onDownloadClick={handleDownload}
        setSelectedValue={setSelectedValue}
        setThrowError={setThrowError}
        throwError={throwError}
        throwErrorMessage={throwErrorMessage}
      />
    </>
  );
};

export default RunPrintModal;
