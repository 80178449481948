import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  Stack,
  SvgIcon,
  TextField,
  Typography
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { createElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { AddCircleIcon } from "assets/images";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import PencilIcon from "assets/images/pencil.svg";
import { ContainerProcessingStyles } from "components/container-processing/ContainerProcessingStyles";
import RePrintLabelModal from "components/container-processing/research-transaction-detail/RePrintLabelModal";
import {
  CircularLoaderFullPage,
  DatePickerField,
  SearchField,
  SelectField
} from "components/core";
import ModalPopup from "components/core/modal-components/ModalPopup";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CONTAINER_PROCESSING_RESEARCH,
  CONTAINER_PROCESSING_RESEARCH_TRANSACTION_DETAIL,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import {
  getExchangeStatus,
  getMessageFromCode,
  getNotOnHoldActiveCustomers
} from "services/api/query";
import {
  getGlobalAttributeValueByTypeId,
  getLocaleByBranch
} from "services/common";
import { selectAuth, selectContainerProcessingResearch } from "store/slices";
import {
  preserveState,
  resetState
} from "store/slices/container-processing/researchSlice";
import {
  DAYJS_LOCALES,
  DEFAULT_DATA_TABLE_OPTIONS,
  DEFAULT_LOCALE,
  ERROR_TYPES,
  GLOBAL_ATTRIBUTE_VALUES,
  MEDIA_PROGRAM_TYPES,
  N_CHECKER,
  ROLE_ACCESS_CODES,
  SUPPORTED_LANGUAGES,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  errorMsgs,
  ymdDateFormat
} from "utils/constants";
import {
  allValue,
  containerInventoryDateFormat,
  initialRangeOfTransactionDetail,
  researchBy,
  updateContainerSystemFunctionId
} from "utils/constants/container-processing/Research";
import {
  findErrorMessage,
  formatDateByLocale,
  getAuthenticatedUserBranch,
  getAuthenticatedUserId,
  getResponseData,
  validateUserHasProgramId
} from "utils/helpers";
import CreateFileModal from "./CreateFileModal";
import EditContainerResearchModal from "./EditContainerResearchModal";
import FindContainerModal from "./FindContainerModal";
import FindCustomerMediaIDModal from "./FindCustomerMediaIDModal";
import PrintReportModal from "./PrintReportModal";
import { containerResearchStyles } from "./ResearchStyle";

const researchFormInitialValues = {
  customer: "",
  customerId: "",
  customerNumber: "",
  mediaType: allValue,
  containerNumbers: "",
  excludeTransports: false,
  exchangeStatus: -2,
  customerMediaIds: "",
  serviceDate: dayjs(),
  runCode: ""
};

// Format date in a table cell
const TableFormattedDateCell = ({ date, locale }) => {
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    const processDate = async () => {
      if (date) {
        const result = await formatDateByLocale(
          date,
          locale,
          false,
          false,
          true
        );
        setFormattedDate((prevState) => result);
      }
    };
    processDate();
  }, [date, locale]);

  return formattedDate;
};

const Research = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const containerProcessingResearchState = useSelector(
    selectContainerProcessingResearch
  ); // redux slice for the page
  const { auth, timeZoneOffset, localeGetDate } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const branch = getAuthenticatedUserBranch();
  const [pageLoading, setPageLoading] = useState(true); // page loading state
  const [localeByBranch, setLocaleByBranch] = useState(DEFAULT_LOCALE); // locale of the selected branch
  const [message10039, setMessage10039] = useState(""); // message 10039 value
  const [message25284, setMessage25284] = useState(""); // message 25284 value
  const [displayCustomerMediaId, setDisplayCustomerMediaId] = useState(true); // show customer_id column in the table
  const [successMessage, setSuccessMessage] = useState({
    show: false,
    message: ""
  }); // success message
  const enableUpdate = validateUserHasProgramId(ROLE_ACCESS_CODES.CODE_47); // can edit container_research item values
  const enableUpdateNewStatusField = validateUserHasProgramId(
    ROLE_ACCESS_CODES.CODE_253
  ); // can edit container_research item new_status field
  const enableUpdateCustomerMediaIdField = validateUserHasProgramId(
    ROLE_ACCESS_CODES.CODE_276
  ); // can edit container_research item customer_media_id field
  const addDeletedStatusOption = validateUserHasProgramId(
    ROLE_ACCESS_CODES.CODE_279
  ); // add deleted option to container_research item new_status dropdown
  const [d2020Enabled, setD2020Enabled] = useState(false); // 2020D Flag
  const [commonDistrictSetting, setCommonDistrictSetting] = useState(null); // common distrcit settings
  const slottingContainerFlag = useMemo(
    () => commonDistrictSetting?.slotting_container_flag === Y_CHECKER,
    [commonDistrictSetting]
  );
  const [selectedOption, setSelectedOption] = useState(researchBy.customer); // search by option state
  const [customers, setCustomers] = useState([]); // customers list
  // media type list
  const [mediaTypes, setMediaTypes] = useState([
    { label: t("common.all"), value: allValue }
  ]);
  const [exchangeStatuses, setExchangeStatuses] = useState([]); // echange status list
  const [runDates, setRunDates] = useState({
    newest: dayjs(),
    oldest: dayjs()
  });
  const [runs, setRuns] = useState([]); // runs list
  const [searchResults, setSearchResults] = useState([]); // search results
  // search results count
  const searchResultsCount = useMemo(
    () => searchResults.length,
    [searchResults]
  );
  // alert state for find
  const [alertFindings, setAlertFindings] = useState({
    show: false,
    title: "",
    message: "",
    severity: "warning"
  });
  // alert state for save
  const [alert, setAlert] = useState({
    show: false,
    title: "",
    message: "",
    severity: "warning"
  });
  const [searchResultsLoading, setSearchResultsLoading] = useState(false); // fetching containers
  const [findButtonTriggered, setFindButtonTriggered] = useState(false); // whether the find button triggered or not
  const [updatedContainers, setUpdatedContainers] = useState([]); // updated containers state
  const [createFileButtonDisabled, setCreateFileButtonDisabled] =
    useState(false); // create file button disabled
  const researchForm = useFormik({
    initialValues: researchFormInitialValues
  }); // filter form
  const [openFindContainerModal, setOpenFindContainerModal] = useState(false); // find container modal show/hide state
  const [containerNumbers, setContainerNumbers] = useState([]); // container numbers list added from the modal
  const [openFindCustomerMediaIDModal, setOpenFindCustomerMediaIDModal] =
    useState(false); // find customer media identifier modal show/hide state
  const [customerMediaIDs, setCustomerMediaIDs] = useState([]); // customer media ID list added from the modal
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // save changes confirmation modal when form dirty
  const [
    showSaveChangesConfirmationModal,
    setShowSaveChangesConfirmationModal
  ] = useState(false); // save changes confirmation modal
  const isFindButtonDisabled = useMemo(() => {
    return (
      findButtonTriggered ||
      (selectedOption === researchBy.run && !researchForm.values?.runCode)
    );
  }, [findButtonTriggered, selectedOption, researchForm.values?.runCode]); // find button enable state
  const [openEditContainerModal, setOpenEditContainerModal] = useState(false); // edit container modal show/hide state
  const [selectedContainerResearch, setSelectedContainerResearch] =
    useState(null); // selected container research item from the table
  const [showCreateFileInfoModal, setShowCreateFileInfoModal] = useState(false); // create file info modal show/hide state
  const [showCreateFileModal, setShowCreateFileModal] = useState(false); // create file modal show/hide state
  const [showPrintReportModal, setShowPrintReportModal] = useState(false); // print report modal show/hide state
  const [mediaTypeChangedContainer, setMediaTypeChangedContainer] =
    useState(null); // media type changed container
  const [cmiChangedContainers, setCMIChangedContainers] = useState([]); // cmi changed container_number list
  const [showReprintReportModal, setShowReprintReportModal] = useState(false); // re-print label modal show/hide state
  const [requestIdListForScheduledDays, setRequestIdListForScheduledDays] =
    useState({});

  const [reportCFUrl, setReportCFUrl] = useState("");
  const selectedCustomerMediaIdList = useMemo(() => {
    let idList = [];
    if (selectedContainerResearch) {
      idList = searchResults?.filter(
        (item) =>
          item.customer_id === selectedContainerResearch?.customer_id &&
          item?.customer_media_descr !==
            selectedContainerResearch?.customer_media_descr
      );
    }
    return idList;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContainerResearch]);

  // table row selection event handler
  const handleTableRowSelection = (_, __, rowsSelected) => {
    if (rowsSelected.length > 0) {
      // if a row is selected get the data for that row
      const selectedRowIndex = rowsSelected[0];
      setSelectedContainerResearch(
        (prevState) => searchResults[selectedRowIndex]
      );
    } else {
      setSelectedContainerResearch((prevState) => null);
    }
  };

  /**
   * @name checkCustomerVisibility
   * @description check whether the customer exists in the customer list (active_flag='Y' AND on_hold='false')
   * @param {String} customerNUmber customer number
   * @returns
   */
  const checkCustomerVisibility = (customerNUmber) => {
    return (
      customers.findIndex(
        (cus) => cus.number?.trim() === customerNUmber?.trim()
      ) >= 0
    );
  };

  // table options
  const tableOptions = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    rowsPerPage: 100,
    sort: true,
    selectableRows: "single",
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    selectToolbarPlacement: "none",
    pagination:
      findButtonTriggered && !searchResultsLoading && searchResultsCount > 0,
    responsive: "vertical",
    textLabels: {
      body: {
        noMatch:
          findButtonTriggered && searchResultsCount === 0
            ? t("common.noRecordsFound")
            : t("common.tableTextWithoutInputs")
      }
    },
    setRowProps: (row, dataIndex) => {
      const mediaInDiscrepency =
        searchResults[dataIndex]?.discrep_resolved_flag === N_CHECKER;
      const isSelected =
        searchResults[dataIndex]?.container_id ===
        selectedContainerResearch?.container_id;
      return {
        style: {
          backgroundColor: isSelected
            ? "var(--color-im-light-blue-100)"
            : mediaInDiscrepency &&
              containerResearchStyles.grayCellBackgroundColor
        }
      };
    },
    onRowSelectionChange: handleTableRowSelection
  };

  // table columns
  const columnsDefinition = [
    {
      name: "container_id",
      label: " ",
      options: {
        setCellProps: () => ({
          style: containerResearchStyles.tableActionCell
        }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnEdit${value}`}
            aria-label="edit"
            onClick={() => {
              const selectedItem = searchResults.find(
                (item) => item.container_id === value
              );
              if (selectedItem) {
                setSelectedContainerResearch((prevState) => selectedItem);
                setOpenEditContainerModal((prevState) => true);
              }
            }}
            sx={ContainerProcessingStyles.tableIconButton}
          >
            <img src={PencilIcon} alt="edit icon" />
          </IconButton>
        )
      }
    },
    {
      name: "assigned_to_customer_id",
      label: t("containerResearch.customerCaps"),
      options: {
        customBodyRender: (value) => {
          const filteredCustomers = customers?.find(
            (cust) => cust.value === value
          );
          return filteredCustomers?.number;
        }
      }
    },
    {
      name: "invoice_descr",
      label: t("containerResearch.logicalVaultCaps")
    },
    {
      name: "descr",
      label: t("containerResearch.mediaTypeCaps"),
      options: {
        setCellProps: (_, rowIndex) => {
          const mediaInDiscrepency =
            searchResults[rowIndex]?.discrep_resolved_flag === N_CHECKER;
          return {
            style: {
              backgroundColor:
                mediaInDiscrepency &&
                containerResearchStyles.grayCellBackgroundColor
            }
          };
        }
      }
    },
    {
      name: "container_number",
      label: t("containerResearch.containerCaps"),
      options: {
        customBodyRender: (value, tableMeta) => {
          const hasOM =
            searchResults[tableMeta.rowIndex]?.open_media_in_transport ===
            Y_CHECKER;
          return hasOM ? `${value} ${t("containerResearch.omCaps")}` : value;
        }
      }
    },
    {
      name: "slotting_location",
      label: t("containerResearch.locationCaps")
    },
    {
      name: "indefinite_retention_flag",
      label: t("containerResearch.indefRetentionCaps"),
      options: {
        setCellProps: (_, rowIndex) => {
          const transportAssociated =
            Number(searchResults[rowIndex]?.media_program_type_id) ===
            Number(MEDIA_PROGRAM_TYPES.TRANSPORT.ID);
          return {
            style: {
              backgroundColor:
                transportAssociated &&
                containerResearchStyles.grayCellBackgroundColor
            }
          };
        },
        customBodyRender: (value, tableMeta) =>
          value === Y_CHECKER ? t("common.yes") : t("common.no")
      }
    },
    {
      name: "return_date",
      label: t("containerResearch.returnDateCaps"),
      options: {
        setCellProps: (_, rowIndex) => {
          const transportAssociated =
            Number(searchResults[rowIndex]?.media_program_type_id) ===
            Number(MEDIA_PROGRAM_TYPES.TRANSPORT.ID);
          return {
            style: {
              backgroundColor:
                transportAssociated &&
                containerResearchStyles.grayCellBackgroundColor
            }
          };
        },
        customBodyRender: (value) =>
          value === VALUE_EMPTY_STRING ? (
            value
          ) : (
            <TableFormattedDateCell date={value} locale={localeByBranch} />
          )
      }
    },
    {
      name: "exchange_status_type",
      label: t("containerResearch.currentStatusCaps")
    },
    {
      name: "new_exch_status_code",
      label: t("containerResearch.newStatusCaps")
    },
    {
      name: "deleted_date",
      label: t("containerResearch.deleteDateCaps"),
      options: {
        customBodyRender: (value) =>
          value === VALUE_EMPTY_STRING ? (
            value
          ) : (
            <TableFormattedDateCell date={value} locale={localeByBranch} />
          )
      }
    },
    {
      name: "issued_date",
      label: t("containerResearch.issueDateCaps"),
      options: {
        customBodyRender: (value) =>
          value === VALUE_EMPTY_STRING ? (
            value
          ) : (
            <TableFormattedDateCell date={value} locale={localeByBranch} />
          )
      }
    }
  ];

  // table columns with customer_media_identifier column
  const columnsDefinitionWithCustomerMediaID = [
    {
      name: "container_id",
      label: " ",
      options: {
        setCellProps: () => ({
          style: containerResearchStyles.tableActionCell
        }),
        customBodyRender: (value, tableMeta) => (
          <IconButton
            id={`btnEdit${value}`}
            aria-label="edit"
            onClick={() => {
              const selectedItem = searchResults.find(
                (item) => item.container_id === value
              );
              if (selectedItem) {
                setSelectedContainerResearch((prevState) => selectedItem);
                setOpenEditContainerModal((prevState) => true);
              }
            }}
            sx={ContainerProcessingStyles.tableIconButton}
          >
            <img src={PencilIcon} alt="edit icon" />
          </IconButton>
        )
      }
    },
    {
      name: "assigned_to_customer_id",
      label: t("containerResearch.customerCaps"),
      options: {
        customBodyRender: (value) => {
          const filteredCustomers = customers?.find(
            (cust) => cust.value === value
          );
          return filteredCustomers?.number;
        }
      }
    },
    {
      name: "invoice_descr",
      label: t("containerResearch.logicalVaultCaps")
    },
    {
      name: "descr",
      label: t("containerResearch.mediaTypeCaps")
    },
    {
      name: "container_number",
      label: t("containerResearch.containerCaps"),
      options: {
        customBodyRender: (value, tableMeta) => {
          const hasOM =
            searchResults[tableMeta.rowIndex]?.open_media_in_transport ===
            Y_CHECKER;
          return hasOM ? `${value} ${t("containerResearch.omCaps")}` : value;
        }
      }
    },
    {
      name: "customer_media_descr",
      label: t("containerResearch.customerMediaIdentifierCaps")
    },
    {
      name: "slotting_location",
      label: t("containerResearch.locationCaps")
    },
    {
      name: "indefinite_retention_flag",
      label: t("containerResearch.indefRetentionCaps"),
      options: {
        setCellProps: (_, rowIndex) => {
          const transportAssociated =
            Number(searchResults[rowIndex]?.media_program_type_id) ===
            Number(MEDIA_PROGRAM_TYPES.TRANSPORT.ID);
          return {
            style: {
              backgroundColor:
                transportAssociated &&
                containerResearchStyles.grayCellBackgroundColor
            }
          };
        },
        customBodyRender: (value, tableMeta) =>
          value === Y_CHECKER ? t("common.yes") : t("common.no")
      }
    },
    {
      name: "return_date",
      label: t("containerResearch.returnDateCaps"),
      options: {
        setCellProps: (_, rowIndex) => {
          const transportAssociated =
            Number(searchResults[rowIndex]?.media_program_type_id) ===
            Number(MEDIA_PROGRAM_TYPES.TRANSPORT.ID);
          return {
            style: {
              backgroundColor:
                transportAssociated &&
                containerResearchStyles.grayCellBackgroundColor
            }
          };
        },
        customBodyRender: (value) =>
          value === VALUE_EMPTY_STRING ? (
            value
          ) : (
            <TableFormattedDateCell date={value} locale={localeByBranch} />
          )
      }
    },
    {
      name: "exchange_status_type",
      label: t("containerResearch.currentStatusCaps")
    },
    {
      name: "new_exch_status_code",
      label: t("containerResearch.newStatusCaps"),
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowData[tableMeta.columnIndex - 1] === value
            ? ""
            : value;
        }
      }
    },
    {
      name: "deleted_date",
      label: t("containerResearch.deleteDateCaps"),
      options: {
        customBodyRender: (value) =>
          value === VALUE_EMPTY_STRING ? (
            value
          ) : (
            <TableFormattedDateCell date={value} locale={localeByBranch} />
          )
      }
    },
    {
      name: "issued_date",
      label: t("containerResearch.issueDateCaps"),
      options: {
        customBodyRender: (value) =>
          value === VALUE_EMPTY_STRING ? (
            value
          ) : (
            <TableFormattedDateCell date={value} locale={localeByBranch} />
          )
      }
    }
  ];

  const initializePage = async () => {
    const responses = await Promise.allSettled([
      fetchSecurityRulesData(),
      handleSearchByOnChange({ target: { value: researchBy.customer } }),
      getLocaleByBranch(),
      getMessageFromCode(errorMsgs.errorCode10039),
      getMessageFromCode(errorMsgs.errorCode25284)
    ]);

    // clear redux state of the page
    dispatch(resetState());

    if (responses[2]?.value) {
      const languageResp = responses[2].value;
      const languageCode =
        languageResp[0].iso_locale_code ||
        SUPPORTED_LANGUAGES?.ENGLISH_US?.code;
      setLocaleByBranch(DAYJS_LOCALES[languageCode] || DEFAULT_LOCALE);
    }

    if (responses[3]?.value) {
      const messageValue = responses[3].value;
      setMessage10039((prevState) => messageValue[0]?.descr);
    }

    if (responses[4]?.value) {
      const messageValue = responses[4].value;
      setMessage25284((prevState) => messageValue[0]?.descr);
    }
    setCreateFileButtonDisabled((prevState) => false);
  };

  // fill page using redux store values
  const fillPageFromRedux = async () => {
    const responses = await Promise.allSettled([
      fetchSecurityRulesData(),
      getLocaleByBranch(),
      getMessageFromCode(errorMsgs.errorCode10039),
      getMessageFromCode(errorMsgs.errorCode25284)
    ]);

    if (responses[1]?.value) {
      const languageResp = responses[1].value;
      const languageCode =
        languageResp[0].iso_locale_code ||
        SUPPORTED_LANGUAGES?.ENGLISH_US?.code;
      setLocaleByBranch(DAYJS_LOCALES[languageCode] || DEFAULT_LOCALE);
    }

    if (responses[2]?.value) {
      const messageValue = responses[2].value;
      setMessage10039((prevState) => messageValue[0]?.descr);
    }

    if (responses[3]?.value) {
      const messageValue = responses[3].value;
      setMessage25284((prevState) => messageValue[0]?.descr);
    }

    setSelectedOption(
      (prevState) => containerProcessingResearchState?.searchOption
    );

    if (containerProcessingResearchState?.searchOption === researchBy.run) {
      // search by is run
      await fetchRunDates();
    }

    researchForm.setValues(containerProcessingResearchState?.filters);
    setCustomers((prevState) => containerProcessingResearchState?.customers);
    setMediaTypes((prevState) => containerProcessingResearchState?.mediaTypes);
    setExchangeStatuses(
      (prevState) => containerProcessingResearchState?.exchangeStatuses
    );
    setRuns((prevState) => containerProcessingResearchState?.runs);
    setContainerNumbers(
      (prevState) => containerProcessingResearchState?.containerNumbers
    );
    setCustomerMediaIDs(
      (prevState) => containerProcessingResearchState?.customerMediaIds
    );
    setSearchResults((prevState) => containerProcessingResearchState?.results);
    setFindButtonTriggered((prevState) => true);
    setCreateFileButtonDisabled(
      (prevState) =>
        containerProcessingResearchState?.filters?.customerId ===
        researchFormInitialValues.customerId
    );
    // clear redux state of the page
    dispatch(resetState());
  };

  // fetch and store common district settings
  const fetchCommonDistrictSettings = async (distrcitId) => {
    const requestBody = JSON.stringify({
      main_district_id: distrcitId
    });
    const response = await getResponseData(
      requestBody,
      `${CF_URLS.containerProcessing.getCommonDistrictSettings}`,
      2
    );

    const results = response.data[0];
    setCommonDistrictSetting((prevState) => results[0]);
  };

  // fetch security rules data
  const fetchSecurityRulesData = async () => {
    const results = await Promise.allSettled([
      getGlobalAttributeValueByTypeId({
        id: GLOBAL_ATTRIBUTE_VALUES.VALUE_96
      }),
      getGlobalAttributeValueByTypeId({
        id: GLOBAL_ATTRIBUTE_VALUES.VALUE_118
      }),
      fetchCommonDistrictSettings(getAuthenticatedUserBranch())
    ]);
    setDisplayCustomerMediaId(
      (prevState) => results[0].value?.trim() === Y_CHECKER
    );
    setD2020Enabled((prevState) => results[1].value?.trim() === Y_CHECKER);
  };

  // fetch customers
  const fetchCustomers = async () => {
    const response = await getNotOnHoldActiveCustomers();
    const filteredCustomers = response?.map(
      ({ customer_id, number, name, locale_id }) => ({
        label: `${number} - ${name.trim()}`,
        value: customer_id,
        number,
        locale_id
      })
    );
    setCustomers((prevState) => filteredCustomers);
  };

  // fetch media types
  const fetchMediaTypes = async (customerId) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId
    });
    const response = await getResponseData(
      requestBody,
      `${CF_URLS.containerProcessing.mediaTypeAndDescription}`,
      1
    );
    const filteredMediaTypes = response?.data[0]?.map(
      ({ media_type_id, short_descr }) => ({
        label: short_descr.trim(),
        value: media_type_id
      })
    );
    // sort by media_type_id in ascending order
    const sortedMediaTypes = filteredMediaTypes?.sort(
      (a, b) => a?.value - b?.value
    );
    setMediaTypes((prevState) =>
      [{ label: t("common.all"), value: allValue }].concat(sortedMediaTypes)
    );
  };

  // fetch exchange status
  const fetchExchangeStatus = async () => {
    const response = await getExchangeStatus();
    const staticItems = [
      { label: t("common.all"), value: -1 },
      { label: t("containerResearch.allExcludingDeleted"), value: -2 }
    ];
    const sortedData = response
      .map((item) => ({
        label: item?.type,
        value: Number(item?.exchange_status_id)
      }))
      .concat(staticItems) // concat ststic items to array
      .sort((a, b) => a.value - b.value); // sort the items
    setExchangeStatuses((prevState) => sortedData);
  };

  // fetch run dates
  const fetchRunDates = async (setLocalDateTime = true) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch()
    });
    const response = await getResponseData(
      requestBody,
      `${CF_URLS.containerProcessing.runDates}`,
      1
    );
    const data = response.data[0][0];

    const newestDate = dayjs(data?.newest_run_date);
    const oldestDate = dayjs(data?.oldest_run_date);
    setRunDates((prevState) => ({
      newest: newestDate,
      oldest: oldestDate
    }));

    if (setLocalDateTime) {
      const localDate = dayjs
        .utc(localeGetDate)
        .utcOffset(Number(timeZoneOffset), true);
      let serviceDate;
      if (
        localDate.isSameOrAfter(oldestDate, "day") &&
        localDate.isSameOrBefore(newestDate, "day")
      ) {
        // local_date is between oldest and newest run dates
        serviceDate = localDate;
      } else {
        serviceDate = newestDate;
      }

      await researchForm.setFieldValue("serviceDate", serviceDate);
      await fetchRuns(serviceDate);
    }
  };

  // fetch runs by the selected run date
  const fetchRuns = async (runDate) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      run_date: dayjs(runDate).locale(localeByBranch).format(ymdDateFormat),
      exchange_status_id: VALUE_EMPTY_STRING
    });
    const response = await getResponseData(
      requestBody,
      `${CF_URLS.containerProcessing.runs}`,
      1
    );
    const data = response.data[0];

    const filteredRuns = data?.map(({ run_id, run_name }) => ({
      label: run_name,
      value: run_id
    }));
    setRuns((prevState) => filteredRuns);
  };

  // Filter by radio input onChange event handler
  const handleSearchByOnChange = async (event) => {
    if (updatedContainers.length > 0) {
      setShowConfirmationModal((prevState) => true);
    } else {
      const option = event.target?.value;
      setSelectedOption((prevState) => option);
      setAlert((prevState) => ({
        show: false,
        severity: "warning",
        message: ""
      }));
      setPageLoading((prevState) => true);
      researchForm.resetForm({ values: researchFormInitialValues });
      setContainerNumbers((prevState) => []);
      setCustomerMediaIDs((prevState) => []);
      setSearchResults((prevState) => []);
      setFindButtonTriggered((prevState) => false);
      if (option === researchBy.customer) {
        await Promise.allSettled([fetchCustomers(), fetchExchangeStatus()]);

        setRunDates((prevState) => ({ oldest: dayjs(), newest: dayjs() }));
        setRuns((prevState) => []);
      }
      if (option === researchBy.run) {
        await fetchRunDates();
        setMediaTypes((prevState) => []);
      }
      setPageLoading((prevState) => false);
    }
  };

  // handle customer onChange event handler
  const handleCustomerOnChange = async (_, option) => {
    if (updatedContainers.length > 0) {
      setShowConfirmationModal((prevState) => true);
    } else {
      const customerId = option?.value || "";
      await researchForm.setFieldValue("customer", option?.label || "");
      await researchForm.setFieldValue("customerId", customerId);
      await researchForm.setFieldValue("customerNumber", option?.number || "");
      if (customerId) {
        setPageLoading((prevState) => true);
        await fetchMediaTypes(option?.value);
        setPageLoading((prevState) => false);
      } else {
        setMediaTypes((prevState) => [{ label: t("common.all"), value: "" }]);
      }
    }
  };

  // media type onChange event handler
  const handleMediaTypeOnChange = async (event) => {
    if (updatedContainers.length > 0) {
      setShowConfirmationModal((prevState) => true);
    } else {
      await researchForm.handleChange(event);
    }
  };

  // handle find container modal onSubmit event handler
  const handleFindContainerModalOnSubmit = async (containers) => {
    setContainerNumbers((prevState) => containers);
    const containerNumberList = containers
      ?.map((cont) => cont.container_number)
      ?.toString();
    await researchForm.setFieldValue("containerNumbers", containerNumberList);
    setOpenFindContainerModal((prevState) => false);
  };

  // handle find customer media Identifier modal onSubmit event handler
  const handleFindCustomerMediaIdModalOnSubmit = async (mediaIdentifiers) => {
    setCustomerMediaIDs((prevState) => mediaIdentifiers);
    const customerMediaIdList = mediaIdentifiers
      ?.map((cont) => cont.customer_media)
      ?.toString();
    await researchForm.setFieldValue("customerMediaIds", customerMediaIdList);
    setOpenFindCustomerMediaIDModal((prevState) => false);
  };

  // clear filter section and the results
  const handleClearPage = async () => {
    if (updatedContainers.length > 0) {
      setShowConfirmationModal((prevState) => true);
    } else {
      setPageLoading((prevState) => true);
      researchForm.resetForm({ values: researchFormInitialValues });
      setSearchResults((prevState) => []);
      setContainerNumbers((prevState) => []);
      setCustomerMediaIDs((prevState) => []);
      setFindButtonTriggered((prevState) => false);
      setAlertFindings((prevState) => ({
        show: false,
        severity: "warning",
        message: ""
      }));
      setAlert((prevState) => ({
        show: false,
        severity: "warning",
        message: ""
      }));
      if (selectedOption === researchBy.customer) {
        setMediaTypes((prevState) => []);
      }
      if (selectedOption === researchBy.run) {
        await Promise.allSettled([fetchRunDates()]);
      }
      setPageLoading((prevState) => false);
    }
  };

  // find container button onClick event handler
  const handleFindOnClick = async () => {
    setSearchResultsLoading(true);
    await findContainers();
  };

  // find containers according to the filter criteria
  const findContainers = async () => {
    setFindButtonTriggered((prevState) => true);
    let containerList = researchForm.values?.containerNumbers;
    if (containerNumbers.length > 0) {
      containerList = containerNumbers
        .map((cont) => cont.container_number)
        .toString()
        .replaceAll(",", "|");
    }

    let mediaIdentifiers = researchForm.values?.customerMediaIds;
    if (customerMediaIDs.length > 0) {
      mediaIdentifiers = customerMediaIDs
        .map((media) => media.customer_media)
        .toString()
        .replaceAll(",", "|");
    }

    const requestBody = JSON.stringify({
      main_district_id: branch,
      customer_id: String(researchForm.values?.customerId),
      media_type_id:
        String(researchForm.values?.mediaType) === allValue
          ? VALUE_EMPTY_STRING
          : String(researchForm.values?.mediaType),
      run_id: String(researchForm.values?.runCode),
      container_list: containerList,
      customer_media_descr_list: mediaIdentifiers,
      exclude_transports_flag: researchForm.values?.excludeTransports
        ? Y_CHECKER
        : N_CHECKER,
      status_id: String(researchForm.values?.exchangeStatus),
      maxresults_flag: VALUE_EMPTY_STRING
    });
    const response = await getResponseData(
      requestBody,
      `${CF_URLS.containerProcessing.getAllContainersForCustomer}`,
      2
    );

    const results = response.data[0];
    const sortedResults = results.sort((a, b) => {
      if (a.customer_number === b.customer_number) {
        // both customer_number values equals, then sort by container_number
        return a.container_number.localeCompare(b.container_number);
      } else {
        // otherwise sort by customer_number
        return a.customer_number.localeCompare(b.customer_number);
      }
    });
    const errorCode = Number(response.data[1][0]?.error || 0);
    setSearchResults((prevState) => sortedResults);
    setSearchResultsLoading(false);
    // enable create file button when search with a customer
    setCreateFileButtonDisabled(
      (prevState) =>
        researchForm.values?.customerId === researchFormInitialValues.customerId
    );

    if (errorCode > 0) {
      if (errorCode === Number(errorMsgs.errorCode26077)) {
        const message = await getMessageFromCode(String(errorCode));
        setAlertFindings((prevState) => ({
          show: true,
          severity: "warning",
          message: message[0]?.descr
        }));
      }
    }
  };

  // save changes confirmation modal Yes button onClick event handler
  const handleSaveChangesOnYesClick = async () => {
    await handleSaveButtonOnClick();
    if (showSaveChangesConfirmationModal) {
      setShowSaveChangesConfirmationModal((prevState) => false);
    } else {
      setShowConfirmationModal((prevState) => false);
    }
  };

  // save changes confirmation modal No button onClick event handler
  const handleSaveChangesOnNoClick = async () => {
    setAlert((prevState) => ({
      show: false,
      title: "",
      message: "",
      severity: "warning"
    }));
    if (showSaveChangesConfirmationModal) {
      setShowSaveChangesConfirmationModal((prevState) => false);
      setSearchResultsLoading((prevState) => true);
      setCreateFileButtonDisabled((prevState) => false);
      await findContainers();
      setSearchResultsLoading((prevState) => false);
    } else {
      setShowConfirmationModal((prevState) => false);
      setSearchResults((prevState) => []);
      setUpdatedContainers((prevState) => []);
      setCreateFileButtonDisabled(
        (prevState) =>
          containerProcessingResearchState?.filters?.customerId ===
          researchFormInitialValues.customerId
      );
    }
  };

  // details button onClick event handler
  const handleDetailsButtonOnClick = async () => {
    setPageLoading((prevState) => true);
    const requestBody = JSON.stringify({
      main_district_id: branch,
      container_id: selectedContainerResearch.container_id,
      up2date: VALUE_EMPTY_STRING,
      range: initialRangeOfTransactionDetail
    });
    const response = await getResponseData(
      requestBody,
      `${CF_URLS.containerProcessing.selectContainerTransactionsInfo}`,
      1
    );

    const results = response.data[0];
    if (results.length === 0) {
      const message = await getMessageFromCode(errorMsgs.errorCode20234);
      setAlert((prevState) => ({
        message: message[0]?.descr,
        show: true,
        severity: "warning"
      }));
    }

    if (results.length > 0) {
      // store filter criteria and search results in the redux slice
      dispatch(
        preserveState({
          customers: customers,
          mediaTypes: mediaTypes,
          exchangeStatuses: exchangeStatuses,
          runs: runs,
          searchOption: selectedOption,
          filters: researchForm.values,
          containerNumbers: containerNumbers,
          customerMediaIds: customerMediaIDs,
          results: searchResults
        })
      );
      // navigate to transaction detail page
      navigate(CONTAINER_PROCESSING_RESEARCH_TRANSACTION_DETAIL, {
        state: {
          selectedContainer: selectedContainerResearch,
          containerTransactions: results
        }
      });
    }
    setPageLoading((prevState) => false);
  };

  // create file button onClick event handler
  const handleCreateFileButtonOnClick = () => {
    // warn the user of a subset of the inventory if any search criteria were specified
    if (
      researchForm.values?.mediaType !== researchFormInitialValues.mediaType ||
      researchForm.values?.containerNumbers !==
        researchFormInitialValues.containerNumbers ||
      researchForm.values?.excludeTransports !==
        researchFormInitialValues.excludeTransports ||
      researchForm.values?.exchangeStatus !==
        researchFormInitialValues.exchangeStatus ||
      researchForm.values?.customerMediaIds !==
        researchFormInitialValues.customerMediaIds ||
      !dayjs().isSame(researchForm.values?.serviceDate, "day") ||
      researchForm.values?.runCode !== researchFormInitialValues.runCode
    ) {
      setShowCreateFileInfoModal((prevState) => true);
    } else {
      setShowCreateFileModal((prevState) => true);
    }
  };

  // service date onChange event handler
  const handleServieDateOnChange = async (e) => {
    if (updatedContainers.length > 0) {
      setShowConfirmationModal((prevState) => true);
    } else {
      setPageLoading((prev) => true);
      researchForm.handleChange({ target: { name: "serviceDate", value: e } });
      await fetchRuns(e);
      researchForm.handleChange({ target: { name: "runCode", value: "" } });
      setPageLoading((prev) => false);
    }
  };

  // edit container modal onSubmit event handler
  const handleEditContainerOnSubmit = async (updatedContainer) => {
    let mediaTypeChanged = false;
    if (updatedContainer !== null) {
      // update search results
      setSearchResults((prevState) => {
        const index = prevState?.findIndex(
          (cont) =>
            cont?.container_id.trim() === updatedContainer?.container_id.trim()
        );
        let newState = prevState;
        if (index > -1) {
          // check media type changed
          if (
            prevState[index]?.media_type_id?.trim() !==
            updatedContainer?.media_type_id?.trim()
          ) {
            mediaTypeChanged = true;
            setMediaTypeChangedContainer((prevState) => updatedContainer);
          }

          // check customer media identifier's are enabled and have been changed
          if (
            displayCustomerMediaId &&
            prevState[index]?.customer_media_descr?.trim() !==
              updatedContainer?.customer_media_descr?.trim()
          ) {
            setCMIChangedContainers((prevState) => {
              if (
                !prevState.includes(updatedContainer?.container_number?.trim())
              ) {
                return prevState.concat(
                  updatedContainer?.container_number?.trim()
                );
              }
              return prevState;
            });
          }
          newState[index] = updatedContainer;
        }
        return newState;
      });

      // add or update the container changes state
      setUpdatedContainers((prevState) => {
        const alreadyUpdatedIndex = prevState?.findIndex(
          (cont) =>
            cont?.container_id.trim() === updatedContainer?.container_id.trim()
        );
        let newState = prevState;
        if (alreadyUpdatedIndex > -1) {
          newState[alreadyUpdatedIndex] = updatedContainer;
        } else {
          newState.push(updatedContainer);
        }

        return newState;
      });
    }
    setOpenEditContainerModal((prevState) => false);

    // trigger save changes
    if (mediaTypeChanged && updatedContainers.length === 0) {
      // container's media type changed and only one container is updated
      await handleSaveButtonOnClick();
    } else if (mediaTypeChanged && updatedContainers.length > 0) {
      // container's media type changed and more than one container is updated
      setShowSaveChangesConfirmationModal((prevState) => true);
    }
  };

  // refresh container results
  const refreshResults = async () => {
    // refetch containers
    await findContainers();
    setSelectedContainerResearch((prevState) => null);
    setUpdatedContainers((prevState) => []);
    setCMIChangedContainers((prevState) => []);
  };

  /**
   * @name modifyContainerMediaType
   * @description modify a container's media type
   * @param {object} container container values
   * @returns is media type updated or not
   */
  const modifyContainerMediaType = async (container) => {
    try {
      const requestBody = JSON.stringify({
        main_district_id: branch,
        container_id: container?.container_id,
        media_type_id: container?.media_type_id,
        employee_id: getAuthenticatedUserId(),
        container_time_stamp: container?.timestamp,
        last_mod_user: VALUE_EMPTY_STRING
      });
      const response = await getResponseData(
        requestBody,
        `${CF_URLS.containerProcessing.modifyContainerMediaType}`,
        1
      );

      if (response && response.data[0]?.length > 0) {
        const item = response.data[0][0];
        const errorCode = Number(item?.error || 0);

        if (errorCode > 0) {
          const errDesc = await getMessageFromCode(String(errorCode));
          setAlert((prevState) => ({
            show: true,
            title: "",
            message: errDesc[0]?.descr,
            severity: "warning"
          }));
          // concurrency check failed
          if (errorCode === Number(errorMsgs.errorCode10001)) {
            await refreshResults();
            setCreateFileButtonDisabled((prevState) => false);
          }
          setPageLoading((prevState) => false);
          return false;
        } else {
          // media type modification completed
          return true;
        }
      }
    } catch (error) {
      setAlert((prevState) => ({
        show: true,
        message: findErrorMessage(ERROR_TYPES.ISSUE),
        severity: "error"
      }));
      setPageLoading((prevState) => false);
      return false;
    }
  };

  /**
   * @name handleSaveButtonOnClick
   * @description save changes done to the containers
   * @param {boolean} rePrintLabelAfterSave trigger re-print label screen after save succedded
   * @returns
   */
  const handleSaveButtonOnClick = async () => {
    setPageLoading((prevState) => true);

    setAlert((prevState) => ({
      show: false,
      message: "",
      severity: "warning"
    }));

    let updates = updatedContainers?.map((item, index) => {
      return {
        ui_seq_id: String(index + 1),
        container_id: item?.container_id,
        new_exch_status_id:
          item?.new_exch_status_id || item?.exchange_status_id,
        issued_date:
          item?.issued_date !== VALUE_EMPTY_STRING
            ? dayjs(item?.issued_date).format(containerInventoryDateFormat)
            : VALUE_EMPTY_STRING,
        deleted_date:
          item?.deleted_date !== VALUE_EMPTY_STRING
            ? dayjs(item?.deleted_date).format(containerInventoryDateFormat)
            : undefined,
        request_id:
          requestIdListForScheduledDays?.[
            dayjs(item?.return_date).format(containerInventoryDateFormat)
          ],
        return_date:
          item?.return_date !== VALUE_EMPTY_STRING
            ? dayjs(item?.return_date).format(containerInventoryDateFormat)
            : VALUE_EMPTY_STRING,
        indefinite_retention_flag: item?.indefinite_retention_flag,
        container_ts: item?.timestamp,
        slotting_location: item?.slotting_location,
        error_return: VALUE_EMPTY_STRING,
        open_media_in_transport: item?.open_media_in_transport,
        part_of_dr_bit: item?.part_of_dr_bit,
        customer_id: item?.customer_id,
        customer_media_descr: item?.customer_media_descr,
        customer_media_descr_duplicate_flag: N_CHECKER
      };
    });

    try {
      // check if there is a media type changed container
      if (mediaTypeChangedContainer !== null) {
        // modify the media type
        const mediaTypeModified = await modifyContainerMediaType(
          mediaTypeChangedContainer
        );

        // check whether the media type modification succeeded
        if (mediaTypeModified) {
          await refreshResults();
          setCreateFileButtonDisabled((prevState) => false);
          // show re-print label popup
          if (mediaTypeChangedContainer !== null) {
            setShowReprintReportModal((prevState) => true);
          }
        }
        setPageLoading((prevState) => false);
        return;
      }
      const requestBody = JSON.stringify({
        system_function_id: updateContainerSystemFunctionId,
        employee_id: getAuthenticatedUserId(),
        personnel_id: VALUE_EMPTY_STRING,
        last_mod_user: auth?.user?.login,
        main_district_id: branch,
        container: updates
      });
      const response = await getResponseData(
        requestBody,
        `${CF_URLS.containerProcessing.updateContainerAttributes}`,
        2
      );

      const errorCode = Number(response.data[1][0]?.error || 0);
      if (errorCode > 0) {
        const errDesc = await getMessageFromCode(String(errorCode));
        setAlert((prevState) => ({
          show: true,
          title: "",
          message: errDesc[0]?.descr,
          severity: "warning"
        }));
      }

      if (response && response.data[0]?.length > 0) {
        const results = response.data[0];
        let errorMessages = [];
        let insufficientCapacityContainers = [];
        let sucessContainers = [];

        // check if there is any errors for the container records
        for (let index = 0; index < results.length; index++) {
          const item = results[index];
          if (
            item?.error_return !== VALUE_EMPTY_STRING &&
            Number(item?.error_return) > 0
          ) {
            // get error code value and store
            const childError = Number(item?.error_return);
            let message = await getMessageFromCode(childError.toString());
            const container = updatedContainers?.find(
              (cont) =>
                Number(cont?.container_id) === Number(item?.container_id)
            );

            if (childError === Number(errorMsgs.errorCode20392)) {
              message = message.replace("|", container?.container_number);
            }

            if (childError === Number(errorMsgs.errorCode20394)) {
              message = message
                .replace("|", container?.new_exch_status_code)
                .replace("|", container?.container_number);
            }

            // if the media assigned to a higher priority
            if (childError === Number(errorMsgs.errorCode26018)) {
              setAlert((prevState) => ({
                show: true,
                severity: "warning",
                message: `${t("containerResearch.containerHash")} ${
                  container?.container_number
                } - ${message[0]?.descr}`
              }));
              await refreshResults();
              setCreateFileButtonDisabled((prevState) => false);
              return;
            }

            // concurrency check failed
            if (childError === Number(errorMsgs.errorCode10001)) {
              setAlert((prevState) => ({
                show: true,
                severity: "warning",
                message: message[0]?.descr
              }));
              await refreshResults();
              setCreateFileButtonDisabled((prevState) => false);
              return;
            }

            // insufficient capacity check
            if (childError === Number(errorMsgs.errorCode62531)) {
              const customerNumber = customers?.find(
                (cus) => Number(cus.value) === Number(container?.customer_id)
              )?.number;
              const containerNumber = container?.container_number;

              insufficientCapacityContainers.push(
                `${t("containerResearch.customerHash")} ${customerNumber} - ${t(
                  "containerResearch.containerHash"
                )} ${containerNumber}`
              );
            } else {
              errorMessages.push(
                `${t("containerResearch.containerHash")} ${
                  container?.container_number
                } - ${message[0]?.descr}`
              );
            }
          } else {
            sucessContainers?.push(item?.container_id);
          }
        }

        if (insufficientCapacityContainers.length > 0) {
          // containers with insufficient capacity
          const errorTitle = await getMessageFromCode(errorMsgs.errorCode62547);

          let childrens = [];
          insufficientCapacityContainers.forEach((msg) =>
            childrens.push(createElement("li", null, msg))
          );
          const list = createElement("ul", null, ...childrens);
          setAlert((prevState) => ({
            show: true,
            title: errorTitle[0]?.descr,
            severity: "warning",
            message: list
          }));
          await refreshResults();
          setCreateFileButtonDisabled((prevState) => false);
        } else if (errorMessages.length > 0) {
          // have error message(s) for the container records
          const errorTitle = await getMessageFromCode(errorMsgs.errorCode80116);

          if (errorMessages.length === 1) {
            // only one error message
            setAlert((prevState) => ({
              show: true,
              severity: "warning",
              message: errorMessages[0]
            }));
          } else {
            // more than one error message
            const childrens = [];
            errorMessages.forEach((msg) =>
              childrens.push(createElement("li", null, msg))
            );
            const list = createElement("ul", null, ...childrens);
            setAlert((prevState) => ({
              show: true,
              title: errorTitle[0]?.descr,
              severity: "warning",
              message: list
            }));
          }
        } else {
          // check if there are CMI changed containers
          if (displayCustomerMediaId && cmiChangedContainers.length > 0) {
            const errorTitle = await getMessageFromCode(
              errorMsgs.errorCode63864
            );
            const commaSeparatedString = cmiChangedContainers.join(",");
            let firstReplacement = errorTitle[0]?.descr?.replace(
              "|",
              commaSeparatedString
            );

            setAlert((prevState) => ({
              show: true,
              severity: "warning",
              message: firstReplacement
            }));
          }
          await refreshResults();
          setCreateFileButtonDisabled((prevState) => false);
        }
      }
    } catch (error) {
      setAlert((prevState) => ({
        show: true,
        message: findErrorMessage(ERROR_TYPES.ISSUE),
        severity: "error"
      }));
    } finally {
      setPageLoading((prevState) => false);
    }
  };

  // init page
  useEffect(() => {
    setPageLoading((prevState) => true);
    if (
      location.state?.from &&
      location.state?.from?.pathname ===
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${CONTAINER_PROCESSING_RESEARCH}/${CONTAINER_PROCESSING_RESEARCH_TRANSACTION_DETAIL}` &&
      containerProcessingResearchState?.results.length > 0
    ) {
      fillPageFromRedux().then(() => setPageLoading((prevState) => false));
    } else {
      initializePage().then(() => setPageLoading((prevState) => false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check whether the filter section is dirty or not when the values changes
  useEffect(() => {
    if (findButtonTriggered) {
      // clear search results and alert when the values changes
      setSearchResults((prevState) => []);
      setFindButtonTriggered((prevState) => false);
      setAlert((prevState) => ({
        show: false,
        severity: "warning",
        message: ""
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    researchForm.values.customerId,
    researchForm.values.mediaType,
    researchForm.values.containerNumbers,
    researchForm.values.excludeTransports,
    researchForm.values.exchangeStatus,
    researchForm.values.customerMediaIds,
    researchForm.values.serviceDate,
    researchForm.values.runCode
  ]);

  return (
    <>
      {/* Full page loader */}
      <CircularLoaderFullPage
        id="container-research-full-page-loader"
        loading={pageLoading || searchResultsLoading}
      />

      {/*Success Message alert*/}
      {successMessage.show && (
        <SuccessPopup
          message={successMessage.message}
          close={(e) =>
            setSuccessMessage((prevState) => ({ show: false, message: "" }))
          }
        />
      )}

      {/* Filter Section and Data Table */}
      <Stack spacing={4} sx={containerResearchStyles.mainContent}>
        <Box>
          <Grid
            container
            gap={containerResearchStyles.searchBySection.gap}
            py={containerResearchStyles.searchBySection.marginY}
          >
            {/* Search by Customer Option */}
            <Grid item>
              <FormControlLabel
                value={researchBy.customer}
                control={
                  <Radio
                    id="container-research-searchby-customer"
                    checked={selectedOption === researchBy.customer}
                    onChange={handleSearchByOnChange}
                  />
                }
                label={t("containerResearch.byCustomer")}
              />
            </Grid>
            {/* Search by Run Option */}
            <Grid item>
              <FormControlLabel
                value={researchBy.run}
                control={
                  <Radio
                    id="container-research-searchby-run"
                    checked={selectedOption === researchBy.run}
                    onChange={handleSearchByOnChange}
                  />
                }
                label={t("containerResearch.byRun")}
              />
            </Grid>
          </Grid>

          {/* Search by Customer Section */}
          {selectedOption === researchBy.customer && (
            <Grid
              container
              rowGap={containerResearchStyles.searchBySection.gap}
            >
              <Grid
                container
                columnGap={containerResearchStyles.searchBySection.gap}
              >
                {/* Customers */}
                <Grid item lg={3}>
                  <SearchField
                    id="container-research-customer"
                    popupIcon={<ArrowDown />}
                    name={"customer"}
                    label={t("containerResearch.customer")}
                    options={customers}
                    value={researchForm.values?.customer}
                    onChange={handleCustomerOnChange}
                  />
                </Grid>

                {/* Media Types */}
                <Grid item lg={3}>
                  <SelectField
                    id="container-research-media-type"
                    disabled={!researchForm.values?.customerId}
                    name={"mediaType"}
                    label={t("containerResearch.mediaType")}
                    options={mediaTypes}
                    value={researchForm.values?.mediaType}
                    onChange={handleMediaTypeOnChange}
                  />
                </Grid>

                {/* Container Numbers */}
                <Grid item lg={3}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      id="container-research-container-number"
                      name="containerNumbers"
                      label={t("containerResearch.containerNumber")}
                      placeholder={t("containerResearch.containerNumber")}
                      fullWidth
                      value={researchForm.values?.containerNumbers}
                      onChange={researchForm.handleChange}
                      disabled={containerNumbers.length > 0}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                if (updatedContainers.length > 0) {
                                  setShowConfirmationModal((prevState) => true);
                                } else {
                                  setOpenFindContainerModal(
                                    (prevState) => true
                                  );
                                }
                              }}
                            >
                              <SvgIcon viewBox="0 0 24 24">
                                <AddCircleIcon
                                  fill={"var(--color-im-mid-blue, #1b75bc)"}
                                />
                              </SvgIcon>
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </FormControl>
                </Grid>

                {/* Exclude Transports */}
                <Grid item>
                  <FormControlLabel
                    id="container-research-exclude-transports"
                    disabled={!researchForm.values?.customer}
                    sx={ContainerProcessingStyles.formControllabelSx}
                    control={
                      <Checkbox
                        color="primary"
                        name="excludeTransports"
                        checked={researchForm.values?.excludeTransports}
                        onChange={researchForm.handleChange}
                      />
                    }
                    label={t("containerResearch.excludeTransports")}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                columnGap={containerResearchStyles.searchBySection.gap}
              >
                {/* Status */}
                <Grid item lg={3}>
                  <SelectField
                    id="container-research-exchange-status"
                    label={t("containerResearch.status")}
                    name={"exchangeStatus"}
                    options={exchangeStatuses}
                    value={researchForm.values?.exchangeStatus}
                    onChange={researchForm.handleChange}
                  />
                </Grid>

                {/* Customer Media ID */}
                {displayCustomerMediaId && (
                  <Grid item lg={3}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        id="container-research-customer-media-identifiers"
                        name="customerMediaIds"
                        label={t("containerResearch.customerMediaId")}
                        placeholder={t("containerResearch.customerMediaId")}
                        disabled={customerMediaIDs.length > 0}
                        fullWidth
                        value={researchForm.values?.customerMediaIds}
                        onChange={researchForm.handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  if (updatedContainers.length > 0) {
                                    setShowConfirmationModal(
                                      (prevState) => true
                                    );
                                  } else {
                                    setOpenFindCustomerMediaIDModal(
                                      (prevState) => true
                                    );
                                  }
                                }}
                              >
                                <SvgIcon viewBox="0 0 24 24">
                                  <AddCircleIcon
                                    fill={"var(--color-im-mid-blue, #1b75bc)"}
                                  />
                                </SvgIcon>
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          {/* Search by Run Section */}
          {selectedOption === researchBy.run && (
            <Grid
              container
              spacing={containerResearchStyles.searchBySection.gap}
            >
              {/* Run date */}
              <Grid item md={6} lg={3}>
                <DatePickerField
                  id="container-research-service-date"
                  name="serviceDate"
                  label={t("containerResearch.serviceDate")}
                  locale={localeByBranch}
                  minDate={runDates.oldest}
                  maxDate={runDates.newest}
                  value={researchForm.values?.serviceDate}
                  onChange={handleServieDateOnChange}
                />
              </Grid>

              {/* Run */}
              <Grid item lg={3}>
                <SelectField
                  id="container-research-run-code"
                  label={t("containerResearch.run")}
                  required
                  name={"runCode"}
                  options={runs}
                  value={researchForm.values?.runCode}
                  onChange={researchForm.handleChange}
                />
              </Grid>

              {/* Status */}
              <Grid item lg={3}>
                <SelectField
                  id="container-research-exchange-status"
                  label={t("containerResearch.status")}
                  name={"exchangeStatus"}
                  options={exchangeStatuses}
                  value={researchForm.values?.exchangeStatus}
                  onChange={researchForm.handleChange}
                />
              </Grid>
            </Grid>
          )}

          {/* Filter section Buttons */}
          <Stack
            direction="row"
            sx={containerResearchStyles.filterSectionButtons}
          >
            <Button
              id="container-research-clear-btn"
              type="button"
              variant="outlined"
              onClick={handleClearPage}
            >
              {t("common.clear")}
            </Button>
            <Button
              id="container-research-find-btn"
              type="button"
              variant="contained"
              disabled={isFindButtonDisabled}
              onClick={handleFindOnClick}
            >
              {t("common.find")}
            </Button>
          </Stack>

          {/* Alerts section */}
          <Box sx={containerResearchStyles.alertSection}>
            {/* save changes alert */}
            {alert.show && (
              <Alert
                id="container-research-alert"
                severity={alert.severity}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() =>
                      setAlert((prevState) => ({
                        show: false,
                        message: "",
                        severity: "warning"
                      }))
                    }
                  >
                    <GridCloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {alert?.title && <AlertTitle>{alert?.title}</AlertTitle>}
                {alert.message}
              </Alert>
            )}

            {/* found results alert */}
            {alertFindings.show && (
              <Alert
                id="container-research-alert"
                severity={alertFindings.severity}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() =>
                      setAlertFindings((prevState) => ({
                        show: false,
                        message: "",
                        severity: "warning"
                      }))
                    }
                  >
                    <GridCloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {alertFindings?.title && (
                  <AlertTitle>{alertFindings?.title}</AlertTitle>
                )}
                {alertFindings.message}
              </Alert>
            )}
          </Box>

          <Card>
            {/* Search Results count and Action Buttons */}
            {findButtonTriggered && !searchResultsLoading && (
              <Box sx={containerResearchStyles.tableActionSection}>
                {/* Results count */}
                <Typography sx={containerResearchStyles.searchResultsCount}>
                  {searchResultsCount === 1
                    ? `${searchResultsCount} ${t("common.recordFound")}`
                    : `${searchResultsCount} ${t("common.recordsFound")}`}
                </Typography>

                {/* Action buttons */}
                {searchResultsCount > 0 && updatedContainers.length === 0 && (
                  <Box sx={containerResearchStyles.tableActionButtonsSection}>
                    {/* Print Button */}
                    <Grid sx={containerResearchStyles.tableActionButtons}>
                      <Button
                        type="button"
                        variant="outlined"
                        disabled={updatedContainers?.length > 0}
                        onClick={() => {
                          setShowPrintReportModal((prevState) => true);
                          let url = displayCustomerMediaId
                            ? CF_URLS.printReports.containerProcessing.research
                                .containerSummaryWithCMI
                            : CF_URLS.printReports.containerProcessing.research
                                .containerSummary;
                          setReportCFUrl(url);
                        }}
                      >
                        {t("common.print")}
                      </Button>
                    </Grid>

                    {/* Details Button */}
                    <Grid sx={containerResearchStyles.tableActionButtons}>
                      <Button
                        type="button"
                        variant="outlined"
                        disabled={!selectedContainerResearch?.container_id}
                        onClick={handleDetailsButtonOnClick}
                      >
                        {t("containerResearch.details")}
                      </Button>
                    </Grid>

                    {/* Create File Button */}
                    <Grid sx={containerResearchStyles.tableActionButtons}>
                      <Button
                        type="button"
                        variant="outlined"
                        disabled={createFileButtonDisabled}
                        onClick={handleCreateFileButtonOnClick}
                      >
                        {t("containerResearch.createFile")}
                      </Button>
                    </Grid>
                  </Box>
                )}

                {searchResultsCount > 0 && updatedContainers.length > 0 && (
                  <Box sx={containerResearchStyles.tableActionButtonsSection}>
                    {/* Save Button */}
                    <Grid sx={containerResearchStyles.tableActionButtons}>
                      <Button
                        type="button"
                        variant="outlined"
                        onClick={handleSaveButtonOnClick}
                      >
                        {t("common.save")}
                      </Button>
                    </Grid>
                  </Box>
                )}
              </Box>
            )}

            {/* Search Results Table */}
            <MUIDataTable
              id="container-research-datatable"
              columns={
                displayCustomerMediaId
                  ? columnsDefinitionWithCustomerMediaID
                  : columnsDefinition
              }
              options={tableOptions}
              data={searchResults}
            />
          </Card>

          <Box
            id="container-research-table-helpers"
            sx={containerResearchStyles.tableLegendSection}
          >
            <Typography sx={containerResearchStyles.tableLegenText}>
              {t("containerResearch.legendOMText")}
            </Typography>
            <Typography sx={containerResearchStyles.tableLegenText}>
              {slottingContainerFlag
                ? t("containerResearch.legendLocationSlotText")
                : t("containerResearch.legendLocationText")}
            </Typography>
          </Box>
        </Box>
      </Stack>

      {/* Find Container Numbers Modal */}
      {openFindContainerModal && (
        <FindContainerModal
          open={openFindContainerModal}
          existingValues={containerNumbers}
          onClose={() => setOpenFindContainerModal((prevState) => false)}
          onSubmit={handleFindContainerModalOnSubmit}
        />
      )}

      {/* Find Container Media Identifiers Modal */}
      {openFindCustomerMediaIDModal && (
        <FindCustomerMediaIDModal
          open={openFindCustomerMediaIDModal}
          existingValues={customerMediaIDs}
          onClose={() => setOpenFindCustomerMediaIDModal((prevState) => false)}
          onSubmit={handleFindCustomerMediaIdModalOnSubmit}
        />
      )}

      {/* Save Changes Confirmation Modal */}
      {(showConfirmationModal || showSaveChangesConfirmationModal) && (
        <ConfirmPopup
          message={message10039}
          modalPopupOpen={showConfirmationModal}
          handleYes={handleSaveChangesOnYesClick}
          showNo={true}
          handleNo={handleSaveChangesOnNoClick}
          showCancel={false}
        />
      )}

      {/* Edit Container Research Modal */}
      {openEditContainerModal && (
        <EditContainerResearchModal
          open={EditContainerResearchModal}
          flag2020D={d2020Enabled}
          slottingContainerFlag={slottingContainerFlag}
          showCustomerNumber={checkCustomerVisibility(
            selectedContainerResearch?.customer_number
          )}
          enableUpdate={enableUpdate}
          enableUpdateNewStatusField={enableUpdateNewStatusField}
          enableUpdateCustomerMediaIdField={enableUpdateCustomerMediaIdField}
          addDeletedStatusOption={addDeletedStatusOption}
          containerValues={selectedContainerResearch}
          existingMediaIds={selectedCustomerMediaIdList || []}
          branchLocale={localeByBranch}
          onClose={() => setOpenEditContainerModal((prevState) => false)}
          onSubmit={handleEditContainerOnSubmit}
          setRequestIdListForScheduledDays={setRequestIdListForScheduledDays}
        />
      )}

      {/* Create File information message Modal */}
      {showCreateFileInfoModal && (
        <ModalPopup
          modalPopupOpen={showCreateFileInfoModal}
          title={t("common.confirm")}
          alertMessage={message25284}
          modalButton={
            <>
              <Box>
                <Button
                  id="edit-container-research-modal-ok-btn"
                  variant="outlined"
                  sx={containerResearchStyles.modalStyles.buttonStyle}
                  type="submit"
                  onClick={() => {
                    setShowCreateFileInfoModal((prevState) => false);
                    setShowCreateFileModal((prevState) => true);
                  }}
                >
                  {t("common.ok")}
                </Button>
              </Box>
              <Box>
                <Button
                  id="edit-container-research-modal-cancel-btn"
                  variant="contained"
                  sx={containerResearchStyles.modalStyles.buttonStyle}
                  type="reset"
                  onClick={() =>
                    setShowCreateFileInfoModal((prevState) => false)
                  }
                >
                  {t("common.cancel")}
                </Button>
              </Box>
            </>
          }
        />
      )}

      {/* Create File Modal */}
      {showCreateFileModal && (
        <CreateFileModal
          open={showCreateFileModal}
          branchLocale={localeByBranch}
          filters={researchForm.values}
          containerNumbers={containerNumbers}
          customerMediaIDs={customerMediaIDs}
          filterWithStatus={
            researchForm.values?.exchangeStatus !==
            researchFormInitialValues.exchangeStatus
          }
          timezoneOffset={timeZoneOffset}
          numbOfRecords={searchResultsCount}
          onSubmit={(msg) => {
            setShowCreateFileModal((prevState) => false);
            setSuccessMessage((prevState) => ({ show: true, message: msg }));
          }}
          onClose={() => setShowCreateFileModal((prevState) => false)}
          includeCMI={displayCustomerMediaId}
        />
      )}
      {/* Print Report Modal */}
      {showPrintReportModal && (
        <PrintReportModal
          open={showPrintReportModal}
          includeCMI={displayCustomerMediaId}
          formValues={researchForm.values}
          containerNumbers={containerNumbers}
          customerMediaIDs={customerMediaIDs}
          onClose={() => {
            setShowPrintReportModal((prevState) => false);
            setReportCFUrl("");
          }}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}

      {/* Re-print Label Report Modal */}
      {showReprintReportModal && (
        <RePrintLabelModal
          open={showReprintReportModal}
          container={mediaTypeChangedContainer}
          includeCMI={displayCustomerMediaId}
          onClose={() => {
            setShowReprintReportModal((prevState) => false);
            setMediaTypeChangedContainer((prevState) => null);
          }}
        />
      )}
    </>
  );
};
export default Research;
