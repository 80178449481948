/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Stack
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ClosedIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import {
  CircularLoaderFullPage,
  SearchField,
  SelectField
} from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import withLoading from "components/hoc/withLoading";
import { Formik, useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getCapacityQueryData, getMessageFromCode } from "services/api/query";
import spacing from "styles/spacing";
import { ERROR_TYPES, messageCode } from "utils/constants";
import {
  active,
  catalogItemCode,
  issuedType,
  regionDistrict
} from "utils/constants/biling-module";
import { findErrorMessage, getSortedData } from "utils/helpers";
import AddOrEditModal from "./AddOrEditModal";
import { CapacityBillingSearchStyles } from "./CapacityBillingConfigurationSearchStyles";
import DownloadReportModal from "./DownloadReportModal";
import SearchTable from "./SearchTable";
import { searchConfigurationSchema } from "./schema";

const SearchConfiguration = () => {
  const { t } = useTranslation();
  const buttonClick = {
    new: "new",
    edit: "edit",
    clear: "clear"
  };

  const [searchResults, setSearchResults] = useState([]);
  const [slicedSearchResults, setSlicedSearchResults] = useState();
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [saveSuccessPopup, setSaveSuccsessPopup] = useState(false);
  const initFilterState = {
    pricingContract: t("common.all"),
    catalogItemCode: catalogItemCode,
    startingGeneration: t("common.all"),
    isActive: false,
    row: [
      {
        pricingContract: "",
        catalogItemCode: catalogItemCode,
        startingGeneration: "",
        isActive: true
      }
    ],
    addedRow: [],
    editedRow: [],
    searchResults: []
  };

  const SearchConfigurationForm = useFormik({
    initialValues: initFilterState,
    validationSchema: searchConfigurationSchema,
    validateOnMount: false
  });

  const [disableFindButton, setDisableFindButton] = useState(false);

  const [buttonClicked, setButtonClicked] = useState("noClicks");
  const [isVisible, setIsVisible] = useState(false);
  const [isNewRowAdding, setIsNewRowAdding] = useState(false);
  const [isVisibleDownloadModal, setIsVisibleDownloadModal] = useState(false);
  //page status
  const [pageToZero, setPageToZero] = useState(false);

  //error message showing states
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);

  //error message showing states in edit/new modal
  const [throwErrorModal, setThrowErrorModal] = useState(false);
  const [throwErrorMessageModal, setThrowErrorMessageModal] = useState(false);

  //loading state
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPricing, setIsLoadingPricing] = useState(false);
  const [isLoadingCatalogItem, setIsLoadingCatalogItem] = useState(false);
  const [isLoadingStartingGeneration, setIsLoadingStartingGeneration] =
    useState(false);
  const [isLoadingPricingNew, setIsLoadingPricingNew] = useState(false);
  const [searchText, setSearchText] = useState([]);
  const [pricingContract, setPricingContract] = useState([]);

  const [itemCode, setItemCode] = useState([]);
  const [capacity, setCapacity] = useState([]);
  const [capacityNew, setCapacityNew] = useState([]);
  // edit modal warning alert states
  const [warningMessagePopup, setWarningMessagePopup] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [requestBody, setRequestBody] = useState(null);
  const [reportCFUrl, setReportCFUrl] = useState();

  //fetch issued pricing contract
  const fetchPricingContract = async (text) => {
    setIsLoadingPricing(true);
    try {
      var reqBody = JSON.stringify({
        main_district_id: regionDistrict,
        pricing_contract: text,
        isused: issuedType.one
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.capacityBillingConfigurationSearch.pricingContracts
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      if (data.data[0]) {
        const mappedResponseData = data?.data[0].map(
          ({ pricing_contract_code }) => ({
            label: pricing_contract_code,
            value: pricing_contract_code
          })
        );
        setPricingContract(
          [].concat(
            [{ label: t("common.all") || "", value: t("common.all") || "" }],
            mappedResponseData
          )
        );
        setIsLoading(false);
      } else if (data.error) {
        setIsLoading(false);
        setThrowErrorMessage(data?.error);
        setThrowError(true);
      }
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoadingPricing(false);
    }
  };

  //fetch catalog iem code
  const fetchCatalogItemCodes = async () => {
    setIsLoadingCatalogItem(true);
    try {
      var reqBody = JSON.stringify({
        main_district_id: regionDistrict
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.capacityBillingConfigurationSearch
          .capacityBillingCatalogItemCode
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      const catalogCode = data.data[0];
      if (catalogCode) {
        const mappedResponseData = catalogCode.map(({ catalog_item_code }) => ({
          label: catalog_item_code,
          value: catalog_item_code
        }));
        setItemCode(mappedResponseData);
      } else if (data.error) {
        setThrowErrorMessage(data?.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoadingCatalogItem(false);
    }
  };

  //fetch capacity loading
  const fetchCapacityLoading = async () => {
    setIsLoadingStartingGeneration(true);
    try {
      const data = await getCapacityQueryData();

      if (data) {
        const mappedResponseData = data?.map(
          ({ capacity_billing_suffix, capacity_billing_suffix_id }) => ({
            label: capacity_billing_suffix,
            value: capacity_billing_suffix_id
          })
        );
        const sortData = mappedResponseData.sort((a, b) =>
          a.label.localeCompare(b.label, undefined, { sensitivity: "base" })
        );
        return sortData;
      } else if (data?.error) {
        setThrowErrorMessage(data?.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoadingStartingGeneration(false);
    }
  };

  //handle clear
  const handleClear = () => {
    SearchConfigurationForm.resetForm();
    setButtonClicked(buttonClick.clear);
    setIsNewRowAdding(false);
    setDisableFindButton(false);
    setWarningMessagePopup(false);
    setWarningMessage("");
    setSearchResults([]);
    setPageToZero(true);
  };

  //clear table data
  const handleClearTable = () => {
    setButtonClicked(buttonClick.clear);
    setIsNewRowAdding(false);
    setDisableFindButton(false);
    setSearchResults([]);
  };

  //fetch table data
  const fetchTableData = async () => {
    try {
      setPageToZero(false);
      setIsLoading(true);
      const startingGeneration = capacity?.find(
        (option) =>
          option?.value === SearchConfigurationForm?.values?.startingGeneration
      )?.label;
      var reqBody = {
        main_district_id: regionDistrict,
        pricing_contract:
          SearchConfigurationForm.values?.pricingContract !== t("common.all")
            ? SearchConfigurationForm.values?.pricingContract
            : "",
        catalog_item_code: SearchConfigurationForm.values.catalogItemCode,
        capacity_billing_suffix:
          startingGeneration === t("common.all")
            ? ""
            : SearchConfigurationForm.values?.startingGeneration?.label,
        isactive: SearchConfigurationForm.values.isActive ? active.active : null
      };
      setRequestBody(reqBody);
      const response = await callToCloudFunction(
        JSON.stringify(reqBody),
        CF_URLS.capacityBillingConfigurationSearch.getTableData
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      let sortedData = getSortedData(
        data?.data[0],
        "pricing_contract_code",
        "asc"
      );
      return sortedData;
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  //handle find data
  const handleFind = async (event) => {
    const tableData = await fetchTableData();
    if (tableData) {
      setSearchResults(tableData);
      setDisableFindButton(true);
    } else if (tableData.error) {
      setThrowErrorMessage(tableData.error);
      setThrowError(true);
    }
  };

  //get single pricing contract details
  const getSinglePricingContractDetail = async (text) => {
    try {
      setIsLoadingPricingNew(true);
      var reqBody = JSON.stringify({
        main_district_id: regionDistrict,
        pricing_contract: text
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.capacityBillingConfigurationSearch.capacityBillingConfiguration
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      return data?.data[0];
    } catch (e) {
      setThrowErrorMessageModal(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowErrorModal(true);
    } finally {
      setIsLoadingPricingNew(false);
    }
  };

  //handle add/update
  const handleInsert = async () => {
    //before insert a new, check pricing contract is empty or not if empty show error message
    if (
      SearchConfigurationForm.values?.row[0]?.pricingContract === "" &&
      buttonClicked === buttonClick.new
    ) {
      const errorMsg = await getMessageFromCode(
        String(messageCode.requiredField)
      );
      if (errorMsg) {
        const message = errorMsg[0]?.descr.replace(
          "|",
          t("capacityBillingConfigurationSearch.pricingContractConcat")
        );
        SearchConfigurationForm.setFieldError("row.pricingContract", message);
      }
      //before insert a new, check starting generation selected if not show error message
    } else if (
      SearchConfigurationForm.values?.row[0]?.startingGeneration === "" &&
      buttonClicked === buttonClick.new
    ) {
      const errorMsg = await getMessageFromCode(
        String(messageCode.selectValidStartingGeneration)
      );
      if (errorMsg) {
        SearchConfigurationForm.setFieldError(
          "row.startingGeneration",
          errorMsg[0]?.descr
        );
      }
      //before insert a edit, check starting generation selected if not show error message
    } else if (
      SearchConfigurationForm.values?.editedRow[0]?.startingGeneration ===
        null &&
      buttonClicked === buttonClick.edit
    ) {
      const errorMsg = await getMessageFromCode(
        String(messageCode.selectValidStartingGeneration)
      );
      if (errorMsg) {
        SearchConfigurationForm.setFieldError(
          "row.startingGeneration",
          errorMsg[0]?.descr
        );
      }
    }
    //before edit, check starting generation selected as <ALL> if it is <ALL> show error message
    else if (
      SearchConfigurationForm.values?.editedRow[0]?.startingGeneration ===
        t("common.all") &&
      buttonClicked === buttonClick.edit
    ) {
      const errorMsg = await getMessageFromCode(
        String(messageCode.selectValidStartingGeneration)
      );
      if (errorMsg) {
        SearchConfigurationForm.setFieldError(
          "row.startingGeneration",
          errorMsg[0]?.descr
        );
      }
    }

    //if form doesn't have errors go to insert section
    else if (
      !SearchConfigurationForm?.errors?.row ||
      !SearchConfigurationForm?.errors?.row?.pricingContract
    ) {
      if (
        SearchConfigurationForm.values.row[0].pricingContract !== "" &&
        buttonClicked === buttonClick.new
      ) {
        try {
          setIsLoading(true);
          //before insert new,  check pricing contract when not in empty state if it is valid or not
          var reqBody = JSON.stringify({
            main_district_id: regionDistrict,
            pricing_contract:
              SearchConfigurationForm.values.row[0].pricingContract,
            isused: ""
          });
          const response = await callToCloudFunction(
            reqBody,
            CF_URLS.capacityBillingConfigurationSearch.pricingContracts
          );
          const data = await getDataFromFirestore(
            response,
            1,
            response.data.docId
          );
          const pricingContractReturn = data?.data[0];
          if (pricingContractReturn.length) {
            const singlePricingContract = await getSinglePricingContractDetail(
              SearchConfigurationForm.values.row[0].pricingContract
            );
            if (
              singlePricingContract.length &&
              buttonClicked === buttonClick.new
            ) {
              const errorMsg = await getMessageFromCode(
                String(messageCode.alreadyExistPricingContract)
              );
              if (errorMsg) {
                const replacedSentence = errorMsg[0]?.descr.replace(
                  "|",
                  SearchConfigurationForm.values.row[0].pricingContract
                );
                setWarningMessagePopup(true);
                setWarningMessage(replacedSentence);
              }
            } else {
              //compare entered pricing contract is valid or not
              const filterPricingContract = pricingContractReturn.filter(
                (pricingContract) =>
                  pricingContract.pricing_contract_code ===
                  SearchConfigurationForm.values.row[0].pricingContract
              );
              //if pricing contract is valid insert the record
              if (filterPricingContract.length !== 0 && !warningMessagePopup) {
                SearchConfigurationForm.setFieldError("row", false);
                setIsLoading(true);
                const create = SearchConfigurationForm?.values?.row[0];
                try {
                  //call to insert cloud function
                  var reqBodyNew = JSON.stringify({
                    main_district_id: regionDistrict,
                    pricing_contract_code: create?.pricingContract,
                    catalog_item_code: create?.catalogItemCode,
                    capacity_billing_suffix_id:
                      create?.startingGeneration?.value,
                    active_flag:
                      create?.isActive === true
                        ? active.active
                        : active.notActive
                  });
                  const response = await callToCloudFunction(
                    reqBodyNew,
                    CF_URLS.capacityBillingConfigurationSearch
                      .saveCapacityBillingConfiguration
                  );
                  const data = await getDataFromFirestore(
                    response,
                    1,
                    response.data.docId
                  );
                  if (data.data[0]) {
                    await handleFind();
                    setIsVisible(false);
                    setSaveSuccsessPopup(true);
                  } else if (data?.error) {
                    setThrowErrorMessageModal(data?.error);
                    setThrowErrorModal(true);
                  }
                } catch (e) {
                  setThrowErrorMessageModal(
                    findErrorMessage(ERROR_TYPES.ISSUE)
                  );
                  setThrowErrorModal(true);
                } finally {
                  setIsLoading(false);
                }
              } else if (data?.error) {
                setThrowErrorMessageModal(data?.error);
                setThrowErrorModal(true);
              }
              //if not valid show the error message
              else {
                const errorMsg = await getMessageFromCode(
                  String(messageCode.pricingContractNotFound)
                );
                if (errorMsg) {
                  SearchConfigurationForm.setFieldError(
                    "row.pricingContract",
                    errorMsg[0]?.descr
                  );
                }
              }
            }
          } else {
            const errorMsg = await getMessageFromCode(
              String(messageCode.pricingContractNotFound)
            );
            if (errorMsg) {
              SearchConfigurationForm.setFieldError(
                "row.pricingContract",
                errorMsg[0]?.descr
              );
            }
          }
        } catch (e) {
          setThrowErrorMessageModal(findErrorMessage(ERROR_TYPES.ISSUE));
          setThrowErrorModal(true);
        } finally {
          setIsLoading(false);
        }
      }
      //if we are in edit and nor errors in the fields edit the record
      else if (buttonClicked !== buttonClick.new) {
        SearchConfigurationForm.setFieldError("row", false);
        setIsLoading(true);
        const edit = SearchConfigurationForm?.values?.editedRow[0];
        try {
          var reqBodyEdit = JSON.stringify({
            main_district_id: regionDistrict,
            pricing_contract_code: edit?.pricingContract,
            catalog_item_code: edit?.catalogItemCode,
            capacity_billing_suffix_id: edit?.startingGeneration?.value,
            active_flag:
              edit?.isActive === true ? active.active : active.notActive
          });
          const response = await callToCloudFunction(
            reqBodyEdit,
            CF_URLS.capacityBillingConfigurationSearch
              .saveCapacityBillingConfiguration
          );
          const data = await getDataFromFirestore(
            response,
            1,
            response.data.docId
          );
          if (data.data[0]) {
            await handleFind();
            setIsVisible(false);
            setSaveSuccsessPopup(true);
            setSelectedRowIndex(null);
          } else if (data?.error) {
            setThrowErrorMessageModal(data?.error);
            setThrowErrorModal(true);
          }
        } catch (e) {
          setThrowErrorMessageModal(findErrorMessage(ERROR_TYPES.ISSUE));
          setThrowErrorModal(true);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    SearchConfigurationForm.setFieldValue("searchResults", searchResults);
    // eslint-disable-next-line
  }, [searchResults]);

  //handle open edit/new modal
  const handleOpenModal = (value) => {
    setIsVisible(value);
  };

  //handle close edit/new modal
  const handleDownloadModalOpen = (value) => {
    setIsVisibleDownloadModal(value);
    setReportCFUrl(
      CF_URLS.printReports.billing.capacityBillingConfigurationReport
    );
  };

  //handle click button when any button click
  const handleClickedButton = (value) => {
    setButtonClicked(value);
  };

  //set capacity to state
  const setCapacityToState = async () => {
    const sortData = await fetchCapacityLoading();
    if (sortData) {
      setCapacity(
        [].concat(
          [{ label: t("common.all") || "", value: t("common.all") || "" }],
          sortData
        )
      );
      setCapacityNew(sortData);
    }
  };

  //trigger functions when search text change
  useEffect(() => {
    fetchPricingContract(searchText);
  }, [searchText]);

  useEffect(() => {
    fetchCatalogItemCodes();
    setCapacityToState();
  }, []);

  return (
    <>
      {/*Circular loader*/}
      <CircularLoaderFullPage
        loading={
          isLoading || isLoadingCatalogItem || isLoadingStartingGeneration
        }
      />
      {/*Success message popup*/}

      {saveSuccessPopup && (
        <SuccessPopup
          message={t("common.successfulMessage")}
          close={setSaveSuccsessPopup}
        />
      )}

      <Formik>
        <Fragment>
          <form>
            {/*Error message popup*/}
            <Collapse in={throwError}>
              <Alert
                severity="error"
                icon={<img src={ErrorIcon} alt="error" />}
                sx={CapacityBillingSearchStyles.AlertBox}
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            </Collapse>

            <Grid
              display={"flex"}
              flexDirection={"column"}
              gap={spacing.verticalMargin20}
            >
              <Grid container spacing={spacing.verticalMargin20}>
                {/*Pricing contract field*/}
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  <SearchField
                    label={t(
                      "capacityBillingConfigurationSearch.pricingContractLabel"
                    )}
                    labelId="pricingContract"
                    options={pricingContract}
                    clearIcon={<ClosedIcon />}
                    popupIcon={<ArrowDown />}
                    value={
                      SearchConfigurationForm?.values?.pricingContract || null
                    }
                    isOptionEqualToValue={(option, value) => {
                      return option?.value === value;
                    }}
                    onChange={(event, value) => {
                      SearchConfigurationForm.setFieldValue(
                        "pricingContract",
                        value?.value
                      );
                      setDisableFindButton(false);
                      handleClearTable();
                    }}
                    onInputChange={(event, newInputValue) => {
                      setSearchText(newInputValue);
                    }}
                    defaultValue=""
                    error={!!SearchConfigurationForm.errors.pricingContract}
                    helperText={SearchConfigurationForm.errors.pricingContract}
                    loading={isLoadingPricing}
                  />
                </Grid>
                {/*Catalog iem code field*/}
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  <SelectField
                    label={t(
                      "capacityBillingConfigurationSearch.catalogItemCodeLabel"
                    )}
                    labelId="catalogItemCode"
                    value={SearchConfigurationForm.values.catalogItemCode}
                    onChange={(event) => {
                      SearchConfigurationForm.setFieldValue(
                        "catalogItemCode",
                        event.target.value
                      );
                      setDisableFindButton(false);
                      handleClearTable();
                    }}
                    options={itemCode}
                    defaultValue=""
                  />
                </Grid>
                {/*Starting generation field*/}
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  <SearchField
                    clearIcon={<ClosedIcon />}
                    popupIcon={<ArrowDown />}
                    label={t(
                      "capacityBillingConfigurationSearch.startingGenerationLabel"
                    )}
                    labelId="startingGeneration"
                    value={SearchConfigurationForm.values.startingGeneration}
                    isOptionEqualToValue={(option, value) => {
                      return option?.value === value;
                    }}
                    onChange={(event, value) => {
                      SearchConfigurationForm.setFieldValue(
                        "startingGeneration",
                        value
                      );
                      setDisableFindButton(false);
                      handleClearTable();
                    }}
                    options={capacity}
                    error={!!SearchConfigurationForm.errors.startingGeneration}
                    helperText={
                      SearchConfigurationForm.errors.startingGeneration
                    }
                    defaultValue=""
                  />
                </Grid>
                {/*Active field*/}
                <Grid item xs={12} md={6} lg={3} xl={3}>
                  <FormControlLabel
                    id="isActiveOnly"
                    style={{ marginLeft: 0 }}
                    control={
                      <Checkbox
                        id="isActiveOnly"
                        size="small"
                        color="primary"
                        style={{ margin: "9px" }}
                        checked={
                          SearchConfigurationForm.values.isActive || false
                        }
                        onChange={(event) => {
                          SearchConfigurationForm.setFieldValue(
                            "isActive",
                            event.target.checked
                          );
                          setDisableFindButton(false);
                          handleClearTable();
                        }}
                      />
                    }
                    label={t(
                      "capacityBillingConfigurationSearch.activeOnlyLabel"
                    )}
                  />
                </Grid>
              </Grid>
              <Stack direction="row" spacing={spacing.verticalMargin20}>
                {/*Clear button*/}
                <Button variant="outlined" onClick={handleClear}>
                  {t("common.clear")}
                </Button>
                {/*Find button*/}
                <Button
                  disabled={
                    disableFindButton ||
                    SearchConfigurationForm.errors?.pricingContract ||
                    SearchConfigurationForm.errors?.startingGeneration
                  }
                  type="button"
                  variant="contained"
                  onClick={(event) => handleFind(event)}
                >
                  {t("common.find")}
                </Button>
              </Stack>
              <Stack>
                {/*Search table*/}
                <SearchTable
                  {...{
                    searchResults,
                    setSearchResults,
                    buttonClicked,
                    setButtonClicked,
                    pricingContract,
                    itemCode,
                    capacity,
                    handleOpenModal,
                    handleDownloadModalOpen,
                    handleClickedButton,
                    isVisible,
                    isNewRowAdding,
                    handleFind,
                    SearchConfigurationForm,
                    slicedSearchResults,
                    setSlicedSearchResults,
                    showConfirm,
                    setShowConfirm,
                    setIsVisible,
                    handleInsert,
                    disableFindButton,
                    buttonClick,
                    capacityNew,
                    setSelectedRowIndex,
                    selectedRowIndex,
                    setWarningMessagePopup,
                    setWarningMessage,
                    pageToZero
                  }}
                />
              </Stack>
            </Grid>

            {/*Add/Edit Modal*/}
            {isVisible && (
              <AddOrEditModal
                {...{
                  isVisible,
                  setIsVisible,
                  buttonClicked,
                  setButtonClicked,
                  pricingContract,
                  itemCode,
                  capacity,
                  SearchConfigurationForm,
                  searchResults,
                  setSearchResults,
                  slicedSearchResults,
                  setSlicedSearchResults,
                  handleClickedButton,
                  handleFind,
                  showConfirm,
                  setShowConfirm,
                  handleInsert,
                  throwErrorModal,
                  throwErrorMessageModal,
                  setIsLoading,
                  setThrowErrorModal,
                  buttonClick,
                  fetchCapacityLoading,
                  capacityNew,
                  isLoading,
                  isLoadingPricingNew,
                  setIsLoadingPricingNew,
                  setSelectedRowIndex,
                  warningMessagePopup,
                  setWarningMessagePopup,
                  warningMessage,
                  setWarningMessage,
                  getSinglePricingContractDetail
                }}
              />
            )}
          </form>
        </Fragment>
      </Formik>
      {/*Report download modal*/}
      {isVisibleDownloadModal && (
        <DownloadReportModal
          isVisible={isVisibleDownloadModal}
          setIsVisible={setIsVisibleDownloadModal}
          requestBody={requestBody}
          setIsLoading={setIsLoading}
          throwError={throwError}
          setThrowError={setThrowError}
          throwErrorMessage={throwErrorMessage}
          setThrowErrorMessage={setThrowErrorMessage}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}
    </>
  );
};

export default withLoading(SearchConfiguration);
