import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth, selectMediaRequest } from "store/slices";
import {
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  downloadOptionPdf,
  numberConstants
} from "utils/constants";
import { findErrorMessage, getUserName, printReport } from "utils/helpers";

export default function DownloadReportModal({
  isVisible,
  setIsVisible,
  mainDistrictId,
  setIsLoading,
  requestId,
  customer,
  districtName,
  throwError,
  setThrowError,
  throwErrorMessage,
  setThrowErrorMessage
}) {
  const { auth } = useSelector(selectAuth);
  const { t } = useTranslation();
  const { issueContainers } = useSelector(selectMediaRequest);

  const [reportCUrl, setReportCFUrl] = useState(VALUE_EMPTY_STRING);
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);

  useEffect(() => {
    if (isVisible) {
      setReportCFUrl(CF_URLS.printReports.request.requestDetailReport);
    } else {
      setReportCFUrl(VALUE_EMPTY_STRING);
    }
  }, [isVisible]);

  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        user_name: getUserName(auth?.user),
        request_id: requestId,
        customer: customer,
        district_name: districtName,
        report_format: selectedValue?.type?.toUpperCase(),
        extra_insert_is_Purchase:
          issueContainers.billForInsertsFlag === Y_CHECKER
            ? numberConstants.one
            : numberConstants.zero
      });
      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.request.requestDetailReport}`
      );
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setThrowError(false);
    setIsVisible(false);
  };

  return (
    <DownloadPopup
      title={t("common.downloadReport")}
      showPrintModal={isVisible}
      onCancleClick={handleCancel}
      onDownloadClick={handleDownload}
      setSelectedValue={setSelectedValue}
      setThrowError={setThrowError}
      throwError={throwError}
      throwErrorMessage={throwErrorMessage}
      cfUrl={reportCUrl}
    />
  );
}
