import dayjs from "dayjs";
import { t } from "i18next";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { getMessageFromCode } from "services/api/query";
import { Y_CHECKER, dateFormatWithoutTime } from "utils/constants";
import {
  customerTypes,
  hintTextColor,
  mergeTypeValues,
  nodeTypes,
  requestType,
  rootIds,
  slotNames,
  sourceTree
} from "utils/constants/transportation/RunManagementConstants";

// Unassigned tree formation
export const getUnassignedTreeData = (
  rawData,
  customerData,
  localeByBranch
) => {
  let tree = [];
  let root = rootIds.unassignedTasks;
  let dataLength = rawData.length;
  let id = root + 1;
  let lookup = {};
  let customerLookup = {};
  let lastCslIdAddCustomer = 0;

  for (let i = 0; i < customerData.length; i++) {
    customerLookup[customerData[i]["id"]] = customerData[i];
  }
  // Handle "D"
  for (let i = 0; i < dataLength; i++) {
    if (rawData[i]["row_type"] === nodeTypes.date) {
      let serviceDate = dayjs
        .utc(rawData[i]["service_date"])
        .locale(localeByBranch)
        .format(dateFormatWithoutTime);
      if (lookup[serviceDate] === undefined) {
        let node = {
          id: id,
          parent: root,
          droppable: false,
          text: serviceDate,
          type: nodeTypes.date,
          source: sourceTree.unassigned,
          data: rawData[i]
        };
        lookup[serviceDate] = {
          id: id,
          jsonNodeData: rawData[i],
          jsonNodeType: "D",
          jsonNodeText: serviceDate
        };
        id += 1;
        tree.push(node);
      }
    }
  }

  // Handle "L"
  lastCslIdAddCustomer = 0;
  for (let i = 0; i < dataLength; i++) {
    if (rawData[i]["row_type"] === nodeTypes.parentCustomer) {
      let serviceDate = dayjs
        .utc(rawData[i]["service_date"])
        .locale(localeByBranch)
        .format(dateFormatWithoutTime);
      let parentId = lookup[serviceDate].id;
      let cslCustomerId = rawData[i]["csl_customer_id"];
      let isCslChild = getIsCslChild(rawData[i], lastCslIdAddCustomer);
      lastCslIdAddCustomer = isCslChild.lastCslIdAddCustomer;
      let customerType = customerTypes.parentCustomer;

      let node = {
        id: id,
        parent: parentId,
        droppable: false,
        text: customerLookup[cslCustomerId]?.number,
        type: nodeTypes.parentCustomer,
        source: sourceTree.unassigned,
        customerType: customerType,
        data: rawData[i],
        isCslChild: isCslChild.isCslChild
      };
      if (lookup[serviceDate][cslCustomerId] === undefined) {
        lookup[serviceDate][cslCustomerId] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      }
      id += 1;
      tree.push(node);
    }
  }

  // Handle C
  lastCslIdAddCustomer = 0;
  for (let i = 0; i < dataLength; i++) {
    if (rawData[i]["row_type"] === nodeTypes.customer) {
      let serviceDate = dayjs
        .utc(rawData[i]["service_date"])
        .locale(localeByBranch)
        .format(dateFormatWithoutTime);
      let parentId = lookup[serviceDate].id;
      let cslCustomerId = rawData[i]["csl_customer_id"];
      let customerId = rawData[i]["customer_id"];
      let isCslChild = getIsCslChild(rawData[i], lastCslIdAddCustomer);
      lastCslIdAddCustomer = isCslChild.lastCslIdAddCustomer;
      let customerType = customerTypes.independentCustomer;
      let onCall =
        rawData[i]["on_call_flag"] === Y_CHECKER
          ? `[${t("runManagement.onCall")}]`
          : "";

      if (lookup[serviceDate][cslCustomerId] === undefined) {
        customerType = customerTypes.independentCustomer;
        lookup[serviceDate][cslCustomerId] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      } else {
        customerType = customerTypes.dependentCustomer;
        parentId = lookup[serviceDate][cslCustomerId].id;
        lookup[serviceDate][cslCustomerId][customerId] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      }

      let node = {
        id: id,
        parent: parentId,
        droppable: false,
        text: (customerLookup[customerId]?.number || "") + onCall,
        type: nodeTypes.customer,
        source: sourceTree.unassigned,
        data: rawData[i],
        isCslChild: isCslChild.isCslChild,
        customerType: customerType
      };
      id += 1;
      tree.push(node);
    }
  }

  // Handle Q
  for (let i = 0; i < dataLength; i++) {
    if (rawData[i]["row_type"] === nodeTypes.request) {
      let serviceDate = dayjs
        .utc(rawData[i]["service_date"])
        .locale(localeByBranch)
        .format(dateFormatWithoutTime);
      let parentId = root;
      let cslCustomerId = rawData[i]["csl_customer_id"];
      let customerId = rawData[i]["customer_id"];
      let requestId = rawData[i]["request_id"];
      let customerType;
      if (lookup[serviceDate][cslCustomerId][customerId] === undefined) {
        parentId = lookup[serviceDate][cslCustomerId].id;
        customerType = lookup[serviceDate][cslCustomerId].jsonNodeCustomerType;
        lookup[serviceDate][cslCustomerId][requestId] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      } else {
        parentId = lookup[serviceDate][cslCustomerId][customerId].id;
        customerType =
          lookup[serviceDate][cslCustomerId][customerId].jsonNodeCustomerType;
        lookup[serviceDate][cslCustomerId][customerId][requestId] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      }
      let node = {
        id: id,
        parent: parentId,
        droppable: false,
        text: t("runManagement.request") + " " + requestId,
        type: nodeTypes.request,
        source: sourceTree.unassigned,
        data: rawData[i],
        customerType: customerType
      };
      id += 1;
      tree.push(node);
    }
  }

  // Handle M
  for (let i = 0; i < dataLength; i++) {
    if (rawData[i]["row_type"] === nodeTypes.container) {
      let serviceDate = dayjs
        .utc(rawData[i]["service_date"])
        .locale(localeByBranch)
        .format(dateFormatWithoutTime);
      let parentId = root;
      let cslCustomerId = rawData[i]["csl_customer_id"];
      let customerId = rawData[i]["customer_id"];
      let requestId = rawData[i]["request_id"];
      let containerNumber = rawData[i]["container_number"];
      let customerType;

      if (lookup[serviceDate][cslCustomerId][customerId] === undefined) {
        parentId = lookup[serviceDate][cslCustomerId][requestId].id;
        customerType =
          lookup[serviceDate][cslCustomerId][requestId].jsonNodeCustomerType;
        lookup[serviceDate][cslCustomerId][requestId][containerNumber] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      } else {
        parentId = lookup[serviceDate][cslCustomerId][customerId][requestId].id;
        customerType =
          lookup[serviceDate][cslCustomerId][customerId][requestId]
            .jsonNodeCustomerType;
        lookup[serviceDate][cslCustomerId][customerId][requestId][
          containerNumber
        ] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      }
      let node = {
        id: id,
        parent: parentId,
        droppable: false,
        text: containerNumber,
        type: nodeTypes.container,
        source: sourceTree.unassigned,
        data: rawData[i],
        customerType: customerType
      };
      id += 1;
      tree.push(node);
    }
  }

  return tree;
};

// Run tree formation
export const getRunTreeData = (rawData, customerData, driverData) => {
  let tree = [];
  let root = rootIds.currentRunTasks;
  let dataLength = rawData.length;
  let id = root + 1;
  let lookup = {};
  let customerLookup = {};
  let lastCslIdAddCustomer = 0;

  for (let i = 0; i < customerData.length; i++) {
    customerLookup[customerData[i]["id"]] = customerData[i];
  }

  // Handle "R"
  for (let i = 0; i < dataLength; i++) {
    if (rawData[i]["row_type"] === nodeTypes.run) {
      let runName = rawData[i]["run_name"];
      let node = {
        id: id,
        parent: root,
        droppable: false,
        text: getCurrentRunInfo(driverData, rawData[i]),
        type: nodeTypes.run,
        source: sourceTree.currentRun,
        data: rawData[i]
      };
      lookup[runName] = {
        id: id
      };
      let parentId = id;
      id += 1;
      tree.push(node);
      let scheduledDepartTime = rawData[i]["scheduled_depart_time"];
      let scheduledArriveTime = rawData[i]["scheduled_arrive_time"];
      let timeArray = getTimeArray(scheduledDepartTime, scheduledArriveTime);
      for (let j = 0; j < timeArray.length; j++) {
        let childNode = {
          id: id,
          parent: parentId,
          droppable: true,
          text: timeArray[j],
          type: nodeTypes.time,
          source: sourceTree.currentRun,
          data: rawData[i]
        };
        lookup[runName][timeArray[j]] = {
          id: id
        };
        id += 1;
        tree.push(childNode);
      }
    }
  }

  // Handle "L"
  lastCslIdAddCustomer = 0;
  for (let i = 0; i < dataLength; i++) {
    if (rawData[i]["row_type"] === nodeTypes.parentCustomer) {
      let isCslChild = getIsCslChild(rawData[i], lastCslIdAddCustomer);
      lastCslIdAddCustomer = isCslChild.lastCslIdAddCustomer;
      let runName = rawData[i]["run_name"];
      let runPositionTime =
        rawData[i]["run_position_time"] === slotNames.onCallTime
          ? slotNames.onCall
          : getTimeString(rawData[i]["run_position_time"]);
      let customerType = customerTypes.parentCustomer;

      let parentId = root;
      if (lookup[runName][runPositionTime] === undefined) {
        let node = {
          id: id,
          parent: lookup[runName].id,
          droppable: true,
          text: runPositionTime,
          type: nodeTypes.time,
          source: sourceTree.currentRun,
          data: rawData[i],
          isCslChild: isCslChild.isCslChild
        };
        parentId = id;
        lookup[runName][runPositionTime] = {
          id: id
        };
        id += 1;
        tree.push(node);
      } else {
        parentId = lookup[runName][runPositionTime].id;
      }

      let cslCustomerId = rawData[i]["csl_customer_id"];
      let node = {
        id: id,
        parent: parentId,
        droppable: false,
        text: customerLookup[cslCustomerId]?.number,
        type: nodeTypes.parentCustomer,
        source: sourceTree.currentRun,
        data: rawData[i],
        isCslChild: isCslChild.isCslChild,
        customerType: customerType
      };
      if (lookup[runName][runPositionTime][cslCustomerId] === undefined) {
        lookup[runName][runPositionTime][cslCustomerId] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      }
      id += 1;
      tree.push(node);
    }
  }

  // Handle C
  lastCslIdAddCustomer = 0;
  for (let i = 0; i < dataLength; i++) {
    if (rawData[i]["row_type"] === nodeTypes.customer) {
      let runName = rawData[i]["run_name"];
      let runPositionTime =
        rawData[i]["run_position_time"] === slotNames.onCallTime
          ? slotNames.onCall
          : getTimeString(rawData[i]["run_position_time"]);
      let customerType = customerTypes.independentCustomer;

      let isCslChild = getIsCslChild(rawData[i], lastCslIdAddCustomer);
      lastCslIdAddCustomer = isCslChild.lastCslIdAddCustomer;

      let parentId = root;
      if (lookup[runName][runPositionTime] === undefined) {
        let node = {
          id: id,
          parent: lookup[runName].id,
          droppable: true,
          text: runPositionTime,
          type: nodeTypes.time,
          source: sourceTree.currentRun,
          data: rawData[i],
          isCslChild: isCslChild.isCslChild
        };
        parentId = id;
        lookup[runName][runPositionTime] = {
          id: id
        };
        id += 1;
        tree.push(node);
      } else {
        parentId = lookup[runName][runPositionTime].id;
      }

      let cslCustomerId = rawData[i]["csl_customer_id"];
      let customerId = rawData[i]["customer_id"];
      let onCall =
        rawData[i]["on_call_flag"] === Y_CHECKER
          ? `[${t("runManagement.onCall")}]`
          : "";

      if (lookup[runName][runPositionTime][cslCustomerId] === undefined) {
        customerType = customerTypes.independentCustomer;
        lookup[runName][runPositionTime][cslCustomerId] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      } else {
        parentId = lookup[runName][runPositionTime][cslCustomerId].id;
        customerType = customerTypes.dependentCustomer;
        lookup[runName][runPositionTime][cslCustomerId][customerId] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      }

      let node = {
        id: id,
        parent: parentId,
        droppable: false,
        text: customerLookup[customerId]?.number + onCall,
        type: nodeTypes.customer,
        source: sourceTree.currentRun,
        data: rawData[i],
        name: customerLookup[customerId]?.name,
        customerName: customerLookup[customerId]?.number,
        isCslChild: isCslChild.isCslChild,
        customerType: customerType
      };
      id += 1;
      tree.push(node);
    }
  }

  // Handle Q
  for (let i = 0; i < dataLength; i++) {
    if (rawData[i]["row_type"] === nodeTypes.request) {
      let runName = rawData[i]["run_name"];
      let runPositionTime =
        rawData[i]["run_position_time"] === slotNames.onCallTime
          ? slotNames.onCall
          : getTimeString(rawData[i]["run_position_time"]);

      let parentId = root;
      let cslCustomerId = rawData[i]["csl_customer_id"];
      let customerId = rawData[i]["customer_id"];
      let requestId = rawData[i]["request_id"];
      let customerType;
      if (
        lookup[runName][runPositionTime][cslCustomerId][customerId] ===
        undefined
      ) {
        parentId = lookup[runName][runPositionTime][cslCustomerId].id;
        customerType =
          lookup[runName][runPositionTime][cslCustomerId].jsonNodeCustomerType;

        lookup[runName][runPositionTime][cslCustomerId][requestId] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      } else {
        parentId =
          lookup[runName][runPositionTime][cslCustomerId][customerId].id;
        customerType =
          lookup[runName][runPositionTime][cslCustomerId][customerId]
            .jsonNodeCustomerType;
        lookup[runName][runPositionTime][cslCustomerId][customerId][requestId] =
          {
            id: id,
            jsonNodeCustomerType: customerType
          };
      }
      let node = {
        id: id,
        parent: parentId,
        droppable: false,
        text: t("runManagement.request") + " " + requestId,
        type: nodeTypes.request,
        source: sourceTree.currentRun,
        data: rawData[i],
        customerType: customerType
      };
      id += 1;
      tree.push(node);
    }
  }

  // Handle M
  for (let i = 0; i < dataLength; i++) {
    if (rawData[i]["row_type"] === nodeTypes.container) {
      let runName = rawData[i]["run_name"];
      let runPositionTime =
        rawData[i]["run_position_time"] === slotNames.onCallTime
          ? slotNames.onCall
          : getTimeString(rawData[i]["run_position_time"]);

      let parentId = root;
      let cslCustomerId = rawData[i]["csl_customer_id"];
      let customerId = rawData[i]["customer_id"];
      let requestId = rawData[i]["request_id"];
      let containerNumber = rawData[i]["container_number"];
      let customerType;

      if (
        lookup[runName][runPositionTime][cslCustomerId][customerId] ===
        undefined
      ) {
        parentId =
          lookup[runName][runPositionTime][cslCustomerId][requestId].id;
        customerType =
          lookup[runName][runPositionTime][cslCustomerId][requestId]
            .jsonNodeCustomerType;
        lookup[runName][runPositionTime][cslCustomerId][requestId][
          containerNumber
        ] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      } else {
        parentId =
          lookup[runName][runPositionTime][cslCustomerId][customerId][requestId]
            .id;
        customerType =
          lookup[runName][runPositionTime][cslCustomerId][customerId][requestId]
            .jsonNodeCustomerType;
        lookup[runName][runPositionTime][cslCustomerId][customerId][requestId][
          containerNumber
        ] = {
          id: id,
          jsonNodeCustomerType: customerType
        };
      }
      let node = {
        id: id,
        parent: parentId,
        droppable: false,
        text: containerNumber,
        type: nodeTypes.container,
        source: sourceTree.currentRun,
        data: rawData[i],
        customerType: customerType
      };
      id += 1;
      tree.push(node);
    }
  }

  return tree;
};

export const getResponseData = async (requestBody, url, resultCount) => {
  const response = await callToCloudFunction(requestBody, url);
  const data = await getDataFromFirestore(
    response,
    resultCount,
    response.data.docId
  );
  return data;
};

export const getDriverInfo = (driverData, nodeData) => {
  let driverInfo = driverData.find(
    (data) => data.value === nodeData?.service_rep_employee_id
  );
  return driverInfo;
};

export const getVendorInfo = (vendorData, nodeData) => {
  let vendorInfo = vendorData.find(
    (data) => data.label.trim() === nodeData?.transportation_vendor_name.trim()
  );
  return vendorInfo?.value;
};

export const getCurrentRunInfo = (driverData, nodeData) => {
  let transportation = nodeData?.transportation_vendor_name;
  let driverInfo =
    nodeData?.service_rep_employee_id && getDriverInfo(driverData, nodeData);
  let driver = driverInfo
    ? driverInfo?.employee_name
    : t("runManagement.unassigned");
  let vehicle = nodeData?.vehicle_number;
  let vendor;
  if (transportation) {
    vendor = transportation || t("runManagement.unassigned");
  } else {
    vendor = vehicle ? driver + " - " + vehicle : driver;
  }
  return `${nodeData?.run_name} ( ${vendor} ) [${nodeData?.type}]`;
};

export const convertH2M = (timeInHour) => {
  var timeParts = timeInHour.split(":");
  return Number(timeParts[0]) * 60 + Number(timeParts[1]);
};

export const formatServiceTime = (time) => {
  let timeInt = parseInt(time);
  let hours = parseInt(timeInt / 100);
  let minutes = timeInt % 100;
  if (
    time.length < 3 ||
    isNaN(hours) ||
    isNaN(minutes) ||
    hours >= 24 ||
    minutes >= 60
  ) {
    return {
      error: true,
      value: ""
    };
  } else {
    minutes = hours === 23 && minutes > 45 ? 45 : minutes;
    let hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
    let minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return {
      error: false,
      value: hoursStr + ":" + minutesStr
    };
  }
};

export const getTimeString = (time) => {
  let timeInt = parseInt(time);
  let hours = parseInt(timeInt / 100);
  let minutes = timeInt % 100;

  let hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
  let minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
  return hoursStr + ":" + minutesStr;
};

export const getNearestIntervalTime = (time, interval = 15) => {
  time = parseInt(time);
  let roundOff = (time % 100) % interval;
  return roundOff === 0 ? time : time + (interval - roundOff);
};

export const getTimeArray = (startTime, endTime) => {
  let timeArray = [];
  startTime = getNearestIntervalTime(startTime);
  endTime = getNearestIntervalTime(endTime);
  if (isNaN(endTime)) {
    timeArray.push(getTimeString(startTime));
  }
  if (isNaN(startTime)) {
    timeArray.push(getTimeString(endTime));
  }
  for (let i = startTime; i < endTime; i += 15) {
    let minutes = i % 100;
    if (minutes >= 60) {
      i += 25;
    } else {
      timeArray.push(getTimeString(i));
    }
  }
  timeArray.push(slotNames.onCall);
  return timeArray;
};

export const getTimeDiff = (date1, date2) => {
  return Math.abs(date1 - date2) / 36e5;
};

export const isSpecialRequestOutside2Hrs = (sourceNode, dropNode) => {
  // Special request outside the 2hr range
  let specialRequestOutside2Hrs = false;
  if (sourceNode?.source === sourceTree.unassigned) {
    if (sourceNode?.data.request_type_id === requestType.cricitalSpecial) {
      let loggedTime = sourceNode?.data.request_logged_datetime;
      let startTime = dropNode?.data.scheduled_start_time;

      if (
        startTime === slotNames.onCall ||
        startTime === slotNames.onCallTime
      ) {
        specialRequestOutside2Hrs = true;
      } else {
        let hourDiff = getTimeDiff(new Date(startTime), new Date(loggedTime));
        if (hourDiff < 2) {
          specialRequestOutside2Hrs = false;
        } else {
          specialRequestOutside2Hrs = true;
        }
      }
    }
  }
  return specialRequestOutside2Hrs;
};

export const getErrorMessage = async (msgCode, fieldName) => {
  const errorMsg = await getMessageFromCode(String(msgCode));
  if (errorMsg) {
    let message = errorMsg[0]?.descr.replace("|", fieldName);
    return message;
  }
};

export const getNode = (tree, nodeId) => {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].id === nodeId) {
      return tree[i];
    }
  }
};

export const hasChild = (tree, nodeId) => {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].parent === nodeId) {
      return true;
    }
  }
  return false;
};

export const getCurrentRun = (tree, nodeId) => {
  let node = getNode(tree, nodeId);

  while (node.type !== nodeTypes.run) {
    node = getNode(tree, node.parent);
  }
  return node;
};

export const getServiceTime = (time) => {
  return time === slotNames.onCall
    ? slotNames.onCallTime
    : time?.replace(":", "");
};

export const getAllRunNames = (tree) => {
  let runNames = [];
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].row_type === nodeTypes.run) {
      runNames.push(tree[i].run_name);
    }
  }
  return runNames;
};

export const getIsCslChild = (node, lastCslIdAddCustomer) => {
  let isCsl = false;
  let isCslChild = false;
  let lastCslId = lastCslIdAddCustomer;

  if (node.row_type === nodeTypes.parentCustomer) {
    isCsl = true;
  } else {
    isCsl = false;
  }

  if (
    node.customer_id === node.csl_customer_id &&
    node.csl_customer_id === lastCslId
  ) {
    isCslChild = true;
  }
  if (node.customer_id !== node.csl_customer_id && !isCsl) {
    isCslChild = true;
  }

  if (isCsl) {
    lastCslId = node.csl_customer_id;
  }
  return {
    lastCslIdAddCustomer: lastCslId,
    isCslChild: isCslChild
  };
};

export const isRequestInPreviousSlot = (
  data,
  runId,
  requestId,
  runPositionTime
) => {
  for (let i = 0; i < data.length; i++) {
    if (
      data[i].run_id === runId &&
      data[i].request_id === requestId &&
      parseInt(data[i].run_position_time) < parseInt(runPositionTime)
    ) {
      return true;
    }
  }
  return false;
};
export const isRequestInFutureSlot = (
  data,
  runId,
  requestId,
  runPositionTime
) => {
  for (let i = 0; i < data.length; i++) {
    if (
      data[i].run_id === runId &&
      data[i].request_id === requestId &&
      parseInt(data[i].run_position_time) > parseInt(runPositionTime)
    ) {
      return true;
    }
  }
  return false;
};

export const isCslCustomerExists = (data, targetNode, cslCustomerId) => {
  let runPositionTime = undefined;
  if (targetNode.type === nodeTypes.time) {
    runPositionTime = getServiceTime(targetNode.text);
  } else if (targetNode.type !== nodeTypes.run) {
    runPositionTime = targetNode?.data?.run_position_time;
  }
  for (let i = 0; i < data.length; i++) {
    if (
      data[i].row_type === nodeTypes.parentCustomer &&
      data[i].run_id === targetNode?.data?.run_id &&
      data[i].run_position_time === runPositionTime &&
      data[i].csl_customer_id === cslCustomerId
    ) {
      return true;
    }
  }
  return false;
};

export const getMergeTypeData = (data, sourceNode, targetNode) => {
  let runPositionTime = undefined;
  let isMerge = false;
  let mergeType = mergeTypeValues.none;
  let subNodeList = [];

  if (targetNode.type === nodeTypes.time) {
    runPositionTime = getServiceTime(targetNode.text);
  } else if (targetNode.type !== nodeTypes.run) {
    runPositionTime = targetNode?.data?.run_position_time;
  }

  for (let i = 0; i < data.length; i++) {
    if (
      data[i].run_id === targetNode?.data?.run_id &&
      data[i].run_position_time === runPositionTime
    ) {
      subNodeList.push(data[i]);
    }
  }

  if (subNodeList.length > 0) {
    let sourceCslCustomerId = sourceNode?.data?.csl_customer_id;
    let sourceCustomerId = sourceNode?.data?.customer_id;

    for (let j = 0; j < subNodeList.length; j++) {
      let targetCslCustomerId = subNodeList[j].csl_customer_id;
      let targetCustomerId = subNodeList[j].customer_id;

      if (sourceCslCustomerId === targetCslCustomerId) {
        if (sourceCustomerId === targetCustomerId) {
          if (
            sourceCslCustomerId === sourceCustomerId &&
            targetCslCustomerId === targetCustomerId
          ) {
            mergeType = mergeTypeValues.mergeCustomerOnly;
            isMerge = true;
            break;
          } else if (sourceCustomerId === "" && targetCustomerId === "") {
            mergeType = mergeTypeValues.mergeCustomerOnly;
            isMerge = true;
            break;
          } else if (
            sourceCslCustomerId !== sourceCustomerId &&
            targetCslCustomerId !== targetCustomerId
          ) {
            mergeType = mergeTypeValues.mergeCustomerOnly;
            isMerge = true;
            break;
          }
        } else {
          if (sourceCustomerId === "" && targetCustomerId !== "") {
            mergeType = mergeTypeValues.tlMergeCsl2t01;
            break;
          } else if (
            targetCustomerId === "" &&
            sourceCustomerId !== "" &&
            sourceCslCustomerId === sourceCustomerId
          ) {
            if (
              sourceNode.isCslChild ||
              sourceNode.type === nodeTypes.request
            ) {
              mergeType = mergeTypeValues.tlMergeCslNoCustomer;
              isMerge = true;
              break;
            } else {
              mergeType = mergeTypeValues.tlMergeCsl1t02;
            }
          }
        }
      }
    }
  }

  return {
    isMerge: isMerge,
    mergeType: mergeType
  };
};

export const getSequenceAndPositionNumber = (
  treeData,
  sourceNode,
  targetNode
) => {
  let sequenceNumber = 1;
  let runPositionId = "";
  let rootNodeId = targetNode?.id;

  // get the root node
  if (sourceNode.customerType === customerTypes.dependentCustomer) {
    for (let i = 0; i < treeData.length; i++) {
      if (treeData[i].parent === targetNode?.id) {
        if (
          treeData[i].customerType === customerTypes.parentCustomer &&
          sourceNode.customerType === customerTypes.dependentCustomer
        ) {
          if (
            treeData[i].data.csl_customer_id ===
            sourceNode?.data?.csl_customer_id
          ) {
            rootNodeId = treeData[i].id;
            break;
          }
        }
      }
    }
  }

  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].parent === rootNodeId) {
      if (
        treeData[i].customerType === customerTypes.parentCustomer &&
        sourceNode.customerType === customerTypes.parentCustomer
      ) {
        if (
          treeData[i].data.csl_customer_id === sourceNode?.data?.csl_customer_id
        ) {
          return {
            sequenceNumber: treeData[i].data.sequence_number,
            runPositionId: treeData[i].data.run_position_id
          };
        }
      } else if (
        treeData[i].customerType === customerTypes.independentCustomer &&
        sourceNode.customerType === customerTypes.independentCustomer
      ) {
        if (treeData[i].data.customer_id === sourceNode?.data?.customer_id) {
          return {
            sequenceNumber: treeData[i].data.sequence_number,
            runPositionId: treeData[i].data.run_position_id
          };
        }
      } else if (
        treeData[i].customerType === customerTypes.dependentCustomer &&
        sourceNode.customerType === customerTypes.dependentCustomer
      ) {
        if (treeData[i].data.customer_id === sourceNode?.data?.customer_id) {
          return {
            sequenceNumber: treeData[i].data.sequence_number,
            runPositionId: treeData[i].data.run_position_id
          };
        }
      }
      sequenceNumber++;
    }
  }

  return {
    sequenceNumber: sequenceNumber,
    runPositionId: runPositionId
  };
};

export const getCustomerList = (treeData) => {
  let customerList = [];
  let list = [];
  for (let i = 0; i < treeData.length; i++) {
    if (
      treeData[i].type === nodeTypes.parentCustomer ||
      treeData[i].type === nodeTypes.customer
    ) {
      if (!list.includes(treeData[i].text) && treeData[i].customerName) {
        let customer = treeData[i]?.customerName + " " + treeData[i]?.name;
        customerList.push(customer);
        list.push(treeData[i].text);
      }
    }
  }
  customerList.sort();
  return customerList;
};

export const getOpenNodes = (treeData, nodeText) => {
  let openNodes = [];
  let firstNode = undefined;
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i]?.customerName?.trim() === nodeText.trim()) {
      if (firstNode === undefined) {
        firstNode = treeData[i].id;
      }
      openNodes.push(treeData[i].id);
      // get parent Nodes
      let node = treeData[i];
      while (node.type !== nodeTypes.run) {
        node = getNode(treeData, node.parent);
        openNodes.push(node.id);
      }
    }
  }
  return {
    openNodeList: openNodes,
    scrollToId: firstNode
  };
};

export const isRunEmpty = (data, runId) => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].run_id === runId && data[i].row_type !== nodeTypes.run) {
      return false;
    }
  }
  return true;
};

export const getRequestColor = (type) => {
  let color;
  if (type === requestType.mediaDestruction) {
    color = hintTextColor.destruction;
  } else if (type === requestType.addOn) {
    color = hintTextColor.addOns;
  } else if (type === requestType.reRun) {
    color = hintTextColor.reRuns;
  } else if (type === requestType.disasterRecovery) {
    color = hintTextColor.dr;
  } else if (
    type === requestType.standardSpecial ||
    type === requestType.cricitalSpecial
  ) {
    color = hintTextColor.specials;
  } else {
    color = "inherit";
  }
  return color;
};

export const getListOfExpandedNodes = (treeData, openNodeIds) => {
  let expandedNodes = [];
  for (let i = 0; i < treeData.length; i++) {
    if (openNodeIds.includes(treeData[i].id)) {
      expandedNodes.push(treeData[i]);
    }
  }
  return expandedNodes;
};

export const getListOfExpandedNodesNewIds = (treeData, openNodes) => {
  const openIds = [];
  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].type === nodeTypes.run) {
      for (let j = 0; j < openNodes.length; j++) {
        if (
          openNodes[j].type === nodeTypes.run &&
          treeData[i].data.run_id === openNodes[j].data.run_id
        ) {
          openIds.push(treeData[i].id);
        }
      }
    } else if (treeData[i].type === nodeTypes.time) {
      for (let j = 0; j < openNodes.length; j++) {
        if (
          openNodes[j].type === nodeTypes.time &&
          treeData[i].data.run_id === openNodes[j].data.run_id &&
          treeData[i].text === openNodes[j].text
        ) {
          openIds.push(treeData[i].id);
        }
      }
    } else if (treeData[i].type === nodeTypes.parentCustomer) {
      for (let j = 0; j < openNodes.length; j++) {
        if (
          openNodes[j].type === nodeTypes.parentCustomer &&
          treeData[i].data.run_id === openNodes[j].data.run_id &&
          treeData[i].data.csl_customer_id === openNodes[j].data.csl_customer_id
        ) {
          openIds.push(treeData[i].id);
        }
      }
    } else if (treeData[i].type === nodeTypes.customer) {
      for (let j = 0; j < openNodes.length; j++) {
        if (
          openNodes[j].type === nodeTypes.customer &&
          treeData[i].data.run_id === openNodes[j].data.run_id &&
          treeData[i].data.csl_customer_id ===
            openNodes[j].data.csl_customer_id &&
          treeData[i].data.customer_id === openNodes[j].data.customer_id
        ) {
          openIds.push(treeData[i].id);
        }
      }
    }
  }

  return openIds;
};

export const checkIfSendMail = (treeData, sourceNode) => {
  if (sourceNode.customerType !== customerTypes.dependentCustomer) {
    return false;
  }
  // get parent customer details
  let parentNodeId = sourceNode.parent;
  let node = getNode(treeData, parentNodeId);

  while (node.type !== nodeTypes.parentCustomer) {
    node = getNode(treeData, node.parent);
  }
  parentNodeId = node.id; // Parent Customer Node ID

  let numberOfChildren = 0;

  for (let i = 0; i < treeData.length; i++) {
    if (treeData[i].parent === parentNodeId) {
      numberOfChildren += 1;
    }
  }

  return numberOfChildren > 1 ? true : false;
};
