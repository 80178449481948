import { useTheme } from "@emotion/react";
import { Box, createTheme, ThemeProvider, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import {
  DataTableTheme,
  subTitleStyle,
  titleTwoStyles,
  typeColor
} from "./DataTable.Styles";

const DataTable = ({
  columns,
  options,
  data,
  components,
  searchResultsCount,
  noRecord,
  paramsDataSet = false,
  showTitle = true,
  ...props
}) => {
  const { t } = useTranslation();
  const globalTheme = useTheme();
  const intergratedDataTableTheme = createTheme(globalTheme, DataTableTheme);

  const title = !noRecord
    ? searchResultsCount > 1
      ? `${searchResultsCount} ${t("common.recordsFound")}`
      : `${searchResultsCount} ${t("common.recordFound")}`
    : undefined;

  const commonOptions = {
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    responsive: "scrollFullHeight"
  };

  const combinedOptions = { ...commonOptions, ...options };

  const titleComponent = (title && Boolean(searchResultsCount)) && (
    <div style={subTitleStyle}>
      <Typography style={typeColor}>{title}</Typography>
      {paramsDataSet && (
        <Typography style={titleTwoStyles} variant="subtitle1">
          {t("dashboard.tODORequestsNotCompleted")}
        </Typography>
      )}
    </div>
  );

  return (
    <ThemeProvider theme={intergratedDataTableTheme}>
      <Box>
        <MUIDataTable
          title={showTitle ? titleComponent : ""}
          options={combinedOptions}
          {...props}
          {...{ data, columns, components }}
          responsive="scrollFullHeight"
        />
      </Box>
    </ThemeProvider>
  );
};

export default DataTable;
