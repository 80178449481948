import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import { FullCircularLoader } from "components/core";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import AuthenticationCallerButton from "components/shared/auth/AuthenticationCallerButton";
import AuthenticationSection from "components/shared/auth/AuthenticationSection";
import SuccessAuthSection from "components/shared/auth/SuccessAuthSection";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_MODULE_AUTH_BASE_PATH,
  ADMIN_MODULE_BASE_PATH,
  PERSONAL_DETAIL
} from "routing/Paths";
import {
  useGetPersonnelAuthQuery,
  useGetValidateChallengeResponseMutation
} from "services/api";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import {
  getCustomers,
  getDistricts,
  getProgramSecurityList
} from "services/api/query";
import {
  selectAuth,
  selectPersonnel,
  setAuthenticationState
} from "store/slices";
import spacing from "styles/spacing";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  N_CHECKER,
  VALUE_ALL,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  authSection,
  downloadOptionPdf,
  personalDetailConstants,
  security_program_id,
  security_program_id_108,
  security_program_id_222
} from "utils/constants";
import {
  findErrorMessage,
  generateSHA256Hash,
  getGlobalAttributeValue,
  getLanguageId,
  getSortedData,
  getUserName,
  printReport
} from "utils/helpers";
import DebouncedValue from "../../shared/Debounce";
import { personalSearchStyles } from "./PersonnelSearchStyles";
import PersonnelSearchTable from "./PersonnelSearchTable";

const PersonnelSearch = () => {
  const { currentBranch, auth } = useSelector(selectAuth);
  const languageLocaleId = getLanguageId();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticationState } = useSelector(selectPersonnel);

  const [allBranches, setAllBranches] = useState([]); // all the branches
  const [allAuthenticatedBranches, setAllAuthenticatedBranches] = useState([]); // auth user branches
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [allAuthenticatedCustomers, setAllAuthenticatedCustomers] = useState(
    []
  );
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [reportCUrl, setReportCFUrl] = useState(VALUE_EMPTY_STRING);
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);
  const [valuePersonnelAuth, setValuePersonnelAuth] = useState("personnelAuth");
  const [showAuthenticationSection, setShowAuthenticationSection] =
    useState(false);
  const [isAuthenticationError, setIsAuthenticationError] = useState(false);
  const [hasPermissionToNavigateToDetail, setHasPermissionToNavigateToDetail] =
    useState(false);
  const [searchText, setSearchText] = useState(null);
  const debouncedSearchText = DebouncedValue(searchText, 500);
  const searchTextRef = useRef();
  searchTextRef.current = searchText;

  let initialValues = {
    // auth related fields
    customer: "",
    personnel: "",
    authNumber: "",
    challengeQuestion: "",
    challengeResponse: "",

    // form related fields
    branch: "",
    selectedCustomer: "",
    firstName: "",
    lastName: "",
    eLevel: false,
    disasterRecovery: false,
    contractOwner: false,
    discrepancyContact: false,
    authNumberSearch: "",
    userId: ""
  };

  const personalSearchForm = useFormik({
    initialValues: initialValues
  });

  const filteredCustomers =
    personalSearchForm?.values?.branch?.district_id ===
    DISTRICT_ID_GET_ALL_LOCATIONS
      ? allAuthenticatedCustomers
      : allAuthenticatedCustomers?.filter(
          (c) =>
            c?.district_id ===
              personalSearchForm?.values?.branch?.district_id ||
            c?.label === VALUE_ALL
        );

  useEffect(() => {
    if (authenticationState) {
      personalSearchForm.setFieldValue(
        "customer",
        authenticationState.customer
      );
      personalSearchForm.setFieldValue(
        "personnel",
        authenticationState.personnel
      );
      personalSearchForm.setFieldValue(
        "authNumber",
        authenticationState.authNumber
      );
      personalSearchForm.setFieldValue(
        "authenticationBranch",
        authenticationState.branch || authenticationState.authenticationBranch
      );
      personalSearchForm.setFieldValue(
        "challengeQuestion",
        authenticationState.challengeQuestion
      );
      personalSearchForm.setFieldValue(
        "challengeResponse",
        authenticationState.challengeResponse
      );
      personalSearchForm.setFieldValue(
        "selectedCustomer",
        authenticationState.customer
      );
      setIsAuthenticated(true);
    }
    //eslint-disable-next-line
  }, [authenticationState]);

  useEffect(() => {
    if (isAuthenticated && valuePersonnelAuth === authSection?.personnelAuth) {
      dispatch(setAuthenticationState(personalSearchForm.values));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: personalSearchForm.values?.personnel?.value
  });

  //checking the challenge response
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});

  // Handle Customer
  const handleCustomerChange = (event, newValue) => {
    setThrowErrorMessage("");
    setThrowError(false);
    personalSearchForm.handleChange({
      target: { name: "customer", value: newValue }
    });
    personalSearchForm.setFieldValue("personnel", "");
  };

  //   Handle Personnel
  const handlePersonnelChange = (selectedValue) => {
    setThrowErrorMessage("");
    setThrowError(false);
    setIsAuthenticationError(false);
    personalSearchForm.handleChange({
      target: { name: "personnel", value: selectedValue }
    });
    personalSearchForm.setFieldValue("authNumber", "");
    personalSearchForm.setFieldValue("challengeQuestion", "");
    personalSearchForm.setFieldValue("challengeResponse", "");
  };

  // authentication
  const onAuthenticateClick = async () => {
    setIsLoading((prev) => true);
    setIsAuthenticationError(false);
    if (
      !personalSearchForm.values?.authNumber?.length &&
      !personalSearchForm.values?.challengeResponse?.length
    ) {
      // const msg = await personnelValidationError();
      setIsAuthenticationError(true);
      setIsLoading((prev) => false);
      return;
    }

    // authenticate with authNumber
    if (personalSearchForm.values?.authNumber?.length) {
      if (
        personalSearchForm.values?.authNumber === personnelAuth[0]?.auth_number
      ) {
        setTableData([]);
        setIsAuthenticated(true);
        setIsLoading((prev) => false);
        return;
      } else {
        setIsAuthenticationError(true);
        setIsAuthenticated(false);
        setIsLoading((prev) => false);
        return;
      }
    }

    // authenticate with challenge response
    if (personalSearchForm.values?.challengeResponse?.length) {
      let hashCode = await generateSHA256Hash(
        personalSearchForm.values?.challengeResponse.trim().toUpperCase()
      );

      //challenge response validation
      let validateChallengeResponse = await triggerValidateChallengeResponse({
        main_district_id: String(currentBranch?.district_id),
        language_locale_id: languageLocaleId,
        personnel_id: personalSearchForm.values?.personnel?.value,
        challenge_response: hashCode
      })
        .unwrap()
        .then((data) => {
          return data[0]?.is_match_flag;
        })
        .catch((e) => {
          setThrowErrorMessage(e.error);
          setThrowError(true);
          return;
        });

      if (validateChallengeResponse === Y_CHECKER) {
        setTableData([]);
        setIsAuthenticated(true);
        setIsLoading((prev) => false);
        return;
      } else {
        setIsAuthenticationError(true);
        setIsLoading((prev) => false);
        setIsAuthenticated(false);
        return;
      }
    }
  };

  // branch change in auth section
  const onBranchChange = (branch) => {
    setThrowErrorMessage("");
    setThrowError(false);

    personalSearchForm?.handleChange({
      target: { name: "authenticationBranch", value: branch?.target?.value }
    });
    personalSearchForm.setFieldValue("customer", "");
  };

  const handleBranchChange = (e) => {
    if (
      personalSearchForm?.values?.branch?.district_id ===
      DISTRICT_ID_GET_ALL_LOCATIONS
    ) {
      personalSearchForm.setFieldValue("branch", e);
    } else {
      personalSearchForm.setFieldValue("branch", e?.target?.value);
      personalSearchForm.setFieldValue("selectedCustomer", "");
      setAllCustomers([]);
    }
  };

  // Handle Customer
  const onCustomerChange = (event, customer) => {
    setThrowErrorMessage("");
    setThrowError(false);
    setTableData([]);
    setAllCustomers([]);
    personalSearchForm.handleChange({
      target: { name: "selectedCustomer", value: customer }
    });
    if (
      personalSearchForm?.values?.branch?.district_id ===
        DISTRICT_ID_GET_ALL_LOCATIONS &&
      customer
    ) {
      const customerBranch = !isAuthenticated
        ? allBranches?.find((b) => b?.district_id === customer?.district_id)
        : allAuthenticatedBranches?.find(
            (b) => b?.district_id === customer?.district_id
          );

      handleBranchChange(customerBranch);
    }
  };

  // find button click
  const onFindClick = async () => {
    setIsLoading((prev) => true);
    setThrowErrorMessage("");
    setThrowError(false);
    setTableData([]);
    try {
      if (
        !isAuthenticated &&
        !personalSearchForm?.values?.selectedCustomer?.customer_id &&
        !personalSearchForm?.values?.firstName &&
        !personalSearchForm?.values?.lastName &&
        !personalSearchForm?.values?.authNumberSearch &&
        !personalSearchForm?.values?.userId
      ) {
        setThrowErrorMessage("Select at least one criteria.");
        setThrowError(true);
        setIsLoading((prev) => false);
        return;
      }

      let customerIds;
      if (
        isAuthenticated &&
        !personalSearchForm?.values?.selectedCustomer?.value
      ) {
        customerIds = allAuthenticatedCustomers
          .map((obj) => obj.customer_id)
          .join(", ");
      }

      let reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id:
          personalSearchForm?.values?.branch?.district_id ===
          DISTRICT_ID_GET_ALL_LOCATIONS
            ? ""
            : personalSearchForm?.values?.branch?.district_id,
        customer_id_list:
          personalSearchForm?.values?.selectedCustomer?.value || customerIds,
        first_name: personalSearchForm?.values?.firstName,
        last_name: personalSearchForm?.values?.lastName,
        login: personalSearchForm?.values?.userId,
        e_level_only_flag: personalSearchForm?.values?.eLevel
          ? Y_CHECKER
          : N_CHECKER,
        dr_auth_only_flag: personalSearchForm?.values?.disasterRecovery
          ? Y_CHECKER
          : N_CHECKER,
        contract_owner_only_flag: personalSearchForm?.values?.contractOwner
          ? Y_CHECKER
          : N_CHECKER,
        discrepancy_contact_only_flag: personalSearchForm?.values
          ?.discrepancyContact
          ? Y_CHECKER
          : N_CHECKER,
        requesting_personnel_id: "",
        auth_number: personalSearchForm?.values?.authNumberSearch,
        max_matches: "0"
      };

      const data = await getResponseData(
        reqBody,
        `${CF_URLS?.personnalSearch?.searchDetails}`,
        2
      );

      let newArray = [];

      data?.data[0]?.forEach((obj1) => {
        data?.data[1]?.forEach((obj2) => {
          if (obj1.personnel_id === obj2.personnel_id) {
            newArray.push({
              middle_name: obj1.middle_name,
              last_name: obj1.last_name,
              first_name: obj1.first_name,
              personnel_id: obj1.personnel_id,
              login: obj1.login,
              customer_id: obj2.customer_id,
              customer_number: obj2.customer_number,
              name: `${obj1.last_name}, ${obj1.first_name} ${
                obj1.middle_name ? obj1.middle_name.charAt(0) + "." : ""
              }`
            });
          }
        });
      });

      const combinedData = {};

      newArray.forEach((item) => {
        const { personnel_id, customer_number } = item;

        // If personnel_id already exists in combinedData, append the customer_number
        if (combinedData[personnel_id]) {
          combinedData[personnel_id].customer_number.push(customer_number);
        } else {
          // If personnel_id doesn't exist, create a new entry
          combinedData[personnel_id] = {
            ...item,
            customer_number: [customer_number] // Start an array with the current customer_number
          };
        }
      });

      // Convert the combinedData object into an array
      const newCusArray = Object.values(combinedData);

      // Convert the customer_number arrays to comma-separated strings
      newCusArray.forEach((item) => {
        item.customer_number = item.customer_number.join(", ");
      });

      newCusArray?.sort((a, b) => a?.last_name.localeCompare(b?.last_name));

      setTableData(newCusArray);

      if (personalSearchForm?.values?.authNumberSearch) {
        personalSearchForm.setFieldValue("firstName", "");
        personalSearchForm.setFieldValue("lastName", "");
        personalSearchForm.setFieldValue("eLevel", false);
        personalSearchForm.setFieldValue("disasterRecovery", false);
        personalSearchForm.setFieldValue("contractOwner", false);
        personalSearchForm.setFieldValue("discrepancyContact", false);
        personalSearchForm.setFieldValue("userId", "");
        personalSearchForm.setFieldValue("selectedCustomer", "");
      }

      setIsLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(error);
      setThrowError(true);
      setIsLoading((prev) => false);
    }
  };

  // clear button click
  const onClearClick = async () => {
    setIsLoading((prev) => true);

    setThrowErrorMessage("");
    setThrowError(false);

    if (allBranches.length > 0) {
      const selectedLocationDetails = allBranches.find(
        (location) => location.district_id === currentBranch?.value
      );
      personalSearchForm.handleChange({
        target: {
          name: "branch",
          value: selectedLocationDetails
        }
      });
    }

    if (!isAuthenticated) {
      personalSearchForm.handleChange({
        target: { name: "selectedCustomer", value: "" }
      });
    }

    personalSearchForm.handleChange({
      target: { name: "firstName", value: "" }
    });

    personalSearchForm.handleChange({
      target: { name: "lastName", value: "" }
    });

    personalSearchForm.handleChange({
      target: { name: "userId", value: "" }
    });

    personalSearchForm.handleChange({
      target: { name: "authNumberSearch", value: "" }
    });

    personalSearchForm.setFieldValue("eLevel", false);

    personalSearchForm.setFieldValue("disasterRecovery", false);

    personalSearchForm.setFieldValue("contractOwner", false);

    personalSearchForm.setFieldValue("discrepancyContact", false);

    setTableData([]);
    setIsLoading((prev) => false);
  };

  // call to cloud function and get data from firestore
  const getResponseData = async (requestBody, url, count) => {
    const response = await callToCloudFunction(requestBody, url);
    const data = await getDataFromFirestore(
      response,
      count,
      response.data.docId
    );
    return data;
  };

  const handleNewButtonClick = () => {
    navigate(
      `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_AUTH_BASE_PATH}/${PERSONAL_DETAIL}`
    );
  };

  const handlePrintButtonClick = () => {
    setShowPrintModal(true);
    setReportCFUrl(CF_URLS.printReports.personnelSearch.downloadReport);
  };

  const isShowAuthenticationSection = () => {
    if (showAuthenticationSection) {
      return true;
    }

    return false;
  };

  const onNavigate = (value, tableMeta) => {
    let row = tableData[tableMeta?.rowIndex];
    navigate(
      `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_AUTH_BASE_PATH}/${PERSONAL_DETAIL}/${row?.personnel_id}`,
      {
        state:
          isAuthenticated && valuePersonnelAuth === authSection?.personnelAuth
            ? personalSearchForm.values
            : null
      }
    );
  };

  const onAuthenticatedCallerCliked = () => {
    if (isAuthenticated) {
      dispatch(setAuthenticationState(null));
      personalSearchForm.setFieldValue("challengeQuestion", "");
      personalSearchForm.setFieldValue("challengeResponse", "");
      personalSearchForm.setFieldValue("customer", "");
      personalSearchForm.setFieldValue("selectedCustomer", "");
      personalSearchForm.setFieldValue("personnel", "");
      personalSearchForm.setFieldValue("authNumber", "");
      setIsAuthenticated(false);
      setAllCustomers([]);
    } else if (showAuthenticationSection) {
      setShowAuthenticationSection(false);
    } else {
      setShowAuthenticationSection(true);
    }
  };

  // personnel authentication type change
  const handlePersonnelAuthChange = (event) => {
    setValuePersonnelAuth(event.target.value);
  };

  // get all branches
  const getAllBarnchesFromFireStore = async () => {
    setIsLoading((prev) => true);
    try {
      const branches = await getDistricts();
      let bran = branches?.map((b) => {
        return {
          ...b,
          label: b?.name,
          value: b?.district_id
        };
      });
      const branchData = getSortedData(bran, "name", "asc");
      branchData.unshift({
        label: "<All>",
        value: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      });
      setAllBranches(branchData);
      setIsLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(error);
      setThrowError(true);
      setIsLoading((prev) => false);
    }
  };

  const onBypassClick = () => {
    setIsAuthenticated(true);
  };

  // if authenticated get the allocated customers
  const getAllocatedCustomers = async () => {
    setIsLoading((prev) => true);
    const jsonString = personnelAuth[0]?.customer_list;
    const distinctCustomers = JSON.parse(jsonString);
    // Extract distinct customer values
    const distinctCustomerIds = distinctCustomers.reduce((acc, obj) => {
      if (!acc.includes(obj.customer_Id)) {
        acc.push(`${obj.customer_Id}`);
      }
      return acc;
    }, []);

    let customerList = await getCustomers(distinctCustomerIds);
    const custData = getSortedData(customerList, "number", "asc");
    let modCux = custData?.map((cus) => {
      return {
        ...cus,
        label: `${cus?.number} - ${cus?.name}`,
        value: cus?.id
      };
    });
    if (modCux?.length > 1) {
      modCux.unshift({
        label: "<All>",
        value: "",
        customer_id: "",
        number: ""
      });
    }
    setAllAuthenticatedCustomers(modCux);

    personalSearchForm.handleChange({
      target: {
        name: "selectedCustomer",
        value:
          {
            ...personalSearchForm?.values?.customer,
            customer_id: personalSearchForm?.values?.customer?.value
          } || modCux[0]
      }
    });

    setIsLoading((prev) => false);
  };

  // if not authenticated get the customers for the branch
  const getCustomersBasedOnTheSelectedBranch = async (text) => {
    setIsLoading((prev) => true);
    try {
      var reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_filter:
          personalSearchForm?.values?.branch?.district_id ===
          DISTRICT_ID_GET_ALL_LOCATIONS
            ? ""
            : personalSearchForm?.values?.branch?.district_id,
        search_text: text,
        max_matches: personalDetailConstants.maxMatchesCust
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.personnelDetail.listOfCustomers
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      const custData = getSortedData(data?.data[0], "number", "asc");
      let modCux = custData?.map((cus) => {
        return {
          ...cus,
          label: `${cus?.number?.trim()} - ${cus?.name?.trim()} - ${cus?.branch_name?.trim()}`,
          value: cus?.customer_id,
          number: cus?.number?.trim()
        };
      });
      setAllCustomers(modCux);
      setSearchText(null);
      setIsLoading((prev) => false);
    } catch (error) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
      setIsLoading((prev) => false);
    }
  };

  // load the branches to auth
  useEffect(() => {
    getAllBarnchesFromFireStore();
  }, []);

  // get challange question
  useEffect(() => {
    if (personnelAuth?.length) {
      personalSearchForm.handleChange({
        target: {
          name: "challengeQuestion",
          value: personnelAuth[0]?.challenge_question
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnelAuth]);

  useEffect(() => {
    if (allBranches && allBranches.length > 0) {
      let selectedBranch = authenticationState
        ? authenticationState?.branch
        : currentBranch?.value;
      if (isAuthenticated) {
        setIsLoading((prev) => true);
        const jsonString = personnelAuth[0]?.customer_list;
        const distinctDistrict = JSON.parse(jsonString);
        // Extract distinct district_id values
        const distinctDistrictIds = distinctDistrict.reduce((acc, obj) => {
          if (!acc.includes(obj.district_id)) {
            acc.push(obj.district_id);
          }
          return acc;
        }, []);

        // Extract objects with district_id equal to one of the distinctDistrictIds
        const filteredObjects = allBranches.filter((obj) =>
          distinctDistrictIds.includes(parseInt(obj.district_id))
        );

        const branchData = getSortedData(filteredObjects, "name", "asc");
        if (branchData?.length > 1) {
          branchData.unshift({
            label: "<All>",
            value: DISTRICT_ID_GET_ALL_LOCATIONS,
            district_id: DISTRICT_ID_GET_ALL_LOCATIONS
          });
        }
        setAllAuthenticatedBranches(branchData);

        const selectedLocationDetails = branchData.find(
          (location) => location.district_id === selectedBranch
        );
        personalSearchForm.handleChange({
          target: {
            name: "branch",
            value: selectedLocationDetails || branchData[0]
          }
        });
        setIsLoading((prev) => false);
      } else {
        setIsLoading((prev) => true);
        const selectedLocationDetails = allBranches.find(
          (location) => location.district_id === selectedBranch
        );
        personalSearchForm.handleChange({
          target: {
            name: "branch",
            value: selectedLocationDetails
          }
        });
        personalSearchForm.handleChange({
          target: {
            name: "authenticationBranch",
            value: selectedLocationDetails?.id
          }
        });
        setIsLoading((prev) => false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBranches, isAuthenticated]);

  useEffect(() => {
    if (personalSearchForm?.values?.branch?.district_id) {
      if (isAuthenticated) {
        getAllocatedCustomers();
        getPersonnelDetail();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalSearchForm?.values?.branch?.district_id, isAuthenticated]);

  const getPersonnelDetail = async () => {
    setIsLoading(true);
    try {
      let reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        personnel_id: personalSearchForm?.values?.personnel?.value,
        customer_facing: N_CHECKER
      };

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.personnelDetail?.personnelinformationandpassedpersonnelid}`,
        3
      );

      let customerRollPermissions = data?.data[2];

      // Map to extract role_ids for the current customer
      let roleIds = customerRollPermissions.map((role) => role.role_id);

      // Add role_ids to uniqueRoleIds array, avoiding duplicates
      let uniqueIds = [...new Set(roleIds)];

      const idRelatedValues = await getProgramSecurityList(
        uniqueIds,
        personalSearchForm?.values?.branch?.district_id,
        security_program_id
      );

      const idRelatedValuesFor108 = await getProgramSecurityList(
        uniqueIds,
        personalSearchForm?.values?.branch?.district_id,
        security_program_id_108
      );

      const idRelatedValuesFor222 = await getProgramSecurityList(
        uniqueIds,
        personalSearchForm?.values?.branch?.district_id,
        security_program_id_222
      );

      let countMatches =
        idRelatedValuesFor108?.length + idRelatedValuesFor222?.length;

      setHasPermissionToNavigateToDetail(
        idRelatedValues?.length !== 0 || countMatches !== 0
      );
      setIsLoading(false);
    } catch (error) {
      setThrowErrorMessage(error);
      setThrowError(true);
      setIsLoading((prev) => false);
    }
  };

  useEffect(() => {
    debouncedSearchText?.length > 0 &&
      getCustomersBasedOnTheSelectedBranch(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  const programSecurityListArray = auth?.user?.program_list
    .split(",")
    .map(Number);

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      let url;
      // Get max_matches from global_attribute collection
      const maxMatchesResponse = await getGlobalAttributeValue(
        currentBranch?.value,
        personalDetailConstants.systemIdMax,
        personalDetailConstants.globalAttributeTypeIdMax
      );
      let customerIds;
      let customerNums;

      if (
        isAuthenticated &&
        !personalSearchForm?.values?.selectedCustomer?.value
      ) {
        customerIds = allAuthenticatedCustomers
          .map((obj) => obj.customer_id)
          .join(", ");
        customerNums = allAuthenticatedCustomers
          .map((obj) => obj.number?.trim())
          .join(", ");
      }

      const requestBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        district_id:
          personalSearchForm?.values?.branch?.district_id ===
          DISTRICT_ID_GET_ALL_LOCATIONS
            ? ""
            : personalSearchForm?.values?.branch?.district_id,
        customer_id_list:
          personalSearchForm?.values?.selectedCustomer?.value ||
          customerIds ||
          VALUE_EMPTY_STRING,
        customer_number:
          personalSearchForm?.values?.selectedCustomer?.number?.trim() ||
          customerNums ||
          VALUE_EMPTY_STRING,
        first_name: personalSearchForm?.values?.firstName,
        last_name: personalSearchForm?.values?.lastName,
        login: personalSearchForm?.values?.userId,
        e_level_only_flag: personalSearchForm?.values?.eLevel
          ? Y_CHECKER
          : N_CHECKER,
        dr_auth_only_flag: personalSearchForm?.values?.disasterRecovery
          ? Y_CHECKER
          : N_CHECKER,
        contract_owner_only_flag: personalSearchForm?.values?.contractOwner
          ? Y_CHECKER
          : N_CHECKER,
        discrepancy_contact_only_flag: personalSearchForm?.values
          ?.discrepancyContact
          ? Y_CHECKER
          : N_CHECKER,
        requesting_personnel_id: VALUE_EMPTY_STRING,
        auth_number: personalSearchForm?.values?.authNumberSearch,
        max_matches: maxMatchesResponse,
        district_name:
          personalSearchForm?.values?.branch?.label === VALUE_ALL
            ? ""
            : personalSearchForm?.values?.branch?.name,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });

      url = CF_URLS.printReports.personnelSearch.downloadReport;

      let reportResponse = await printReport(requestBody, url);
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* Page Loader */}
      {isLoading && <FullCircularLoader />}

      <form>
        <Box sx={personalSearchStyles?.formStyle}>
          <AuthenticationCallerButton
            isAuthenticated={isAuthenticated}
            onAuthenticatedCallerCliked={onAuthenticatedCallerCliked}
          />

          {throwError && (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                    setThrowErrorMessage("");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          )}

          {isShowAuthenticationSection() && (
            <>
              {!isAuthenticated && (
                <>
                  <AuthenticationSection
                    allBranches={allBranches}
                    handleBranchChange={onBranchChange}
                    pageFormDetails={personalSearchForm}
                    valuePersonnelAuth={valuePersonnelAuth}
                    handlePersonnelAuthChange={handlePersonnelAuthChange}
                    handleCustomerChange={handleCustomerChange}
                    handlePersonnelChange={handlePersonnelChange}
                    onAuthenticateClick={onAuthenticateClick}
                    isAuthenticationError={isAuthenticationError}
                    isAuthenticated={isAuthenticated}
                    onBypassClick={onBypassClick}
                    programSecurityListArray={[]}
                    setIsLoading={setIsLoading}
                    setThrowError={setThrowError}
                    setThrowErrorMessage={setThrowErrorMessage}
                  />
                  <Divider />
                </>
              )}
            </>
          )}

          {isAuthenticated && (
            <>
              {" "}
              <SuccessAuthSection
                personalDetailForm={personalSearchForm}
                valuePersonnelAuth={valuePersonnelAuth}
              />
              <Divider />
            </>
          )}

          <Grid container spacing={spacing.verticalMargin20}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth>
                <InputLabel id="branchSelectLabel">
                  {t("personalSearch.branch")}
                </InputLabel>
                <Select
                  labelId="branchSelectLabel"
                  id="branchSelect"
                  value={personalSearchForm.values.branch}
                  label={t("personalSearch.branch")}
                  onChange={(e) => handleBranchChange(e)}
                  disabled={
                    isAuthenticated && allAuthenticatedBranches.length === 1
                  }
                >
                  {!isAuthenticated
                    ? allBranches?.length > 0 &&
                      allBranches?.map((b) => {
                        return (
                          <MenuItem value={b} key={b?.label}>
                            {b?.label}
                          </MenuItem>
                        );
                      })
                    : allAuthenticatedBranches?.length > 0 &&
                      allAuthenticatedBranches?.map((b) => {
                        return (
                          <MenuItem value={b} key={b?.label}>
                            {b?.label}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="customerSelectLabel"
                  options={!isAuthenticated ? allCustomers : filteredCustomers}
                  onChange={(event, newValue) => {
                    onCustomerChange(event, newValue);
                  }}
                  onInputChange={(event, newInputValue, reason) => {
                    if (!isAuthenticated) {
                      setThrowErrorMessage("");
                      setThrowError(false);
                      reason !== personalDetailConstants.autoCompleteReset &&
                        setSearchText(newInputValue);
                    }
                  }}
                  value={personalSearchForm.values.selectedCustomer}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("personnelValidation.customer")}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label={t("personalSearch.firstName")}
                size="medium"
                variant="outlined"
                fullWidth
                name="firstName"
                onChange={personalSearchForm.handleChange}
                value={personalSearchForm?.values?.firstName}
                inputProps={{
                  maxLength: 30
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label={t("personalSearch.lastName")}
                size="medium"
                variant="outlined"
                fullWidth
                name="lastName"
                onChange={personalSearchForm.handleChange}
                value={personalSearchForm?.values?.lastName}
                inputProps={{
                  maxLength: 30
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={spacing.verticalMargin20}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                label={t("personalSearch.userId")}
                size="medium"
                variant="outlined"
                fullWidth
                name="userId"
                onChange={personalSearchForm.handleChange}
                value={personalSearchForm?.values?.userId}
              />
            </Grid>

            {programSecurityListArray.includes(318) && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  label={t("personalSearch.authNumber")}
                  size="medium"
                  variant="outlined"
                  fullWidth
                  name="authNumberSearch"
                  onChange={personalSearchForm.handleChange}
                  value={personalSearchForm?.values?.authNumberSearch}
                  inputProps={{
                    maxLength: 6
                  }}
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={spacing.verticalMargin20}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  {t("personalSearch.authorization")}
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={personalSearchForm.values.eLevel}
                        onChange={(event) => {
                          personalSearchForm.setFieldValue(
                            "eLevel",
                            event.target.checked
                          );
                        }}
                      />
                    }
                    label={t("personalSearch.eLevel")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={personalSearchForm.values.disasterRecovery}
                        onChange={(event) => {
                          personalSearchForm.setFieldValue(
                            "disasterRecovery",
                            event.target.checked
                          );
                        }}
                      />
                    }
                    label={t("personalSearch.disasterRecovery")}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">
                  {t("personalSearch.other")}
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={personalSearchForm.values.contractOwner}
                        onChange={(event) => {
                          personalSearchForm.setFieldValue(
                            "contractOwner",
                            event.target.checked
                          );
                        }}
                      />
                    }
                    label={t("personalSearch.contractOwner")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={personalSearchForm.values.discrepancyContact}
                        onChange={(event) => {
                          personalSearchForm.setFieldValue(
                            "discrepancyContact",
                            event.target.checked
                          );
                        }}
                      />
                    }
                    label={t("personalSearch.discrepancyContact")}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </form>

      <Stack
        direction={personalSearchStyles.directionColumn}
        gap={personalSearchStyles.gapSpacing}
      >
        <Stack
          direction={personalSearchStyles.directionRow}
          gap={personalSearchStyles.gapSpacing}
        >
          <Button variant="outlined" onClick={() => onClearClick()}>
            {t("common.clear")}
          </Button>
          <Button variant="contained" onClick={() => onFindClick()}>
            {t("common.find")}
          </Button>
        </Stack>

        <Stack
          direction={personalSearchStyles.directionRow}
          gap={personalSearchStyles.gapSpacing}
        >
          <PersonnelSearchTable
            personalSearchTableData={tableData}
            handleNewButtonClick={handleNewButtonClick}
            handlePrintButtonClick={handlePrintButtonClick}
            onNavigate={onNavigate}
            personalSearchForm={personalSearchForm}
            isAuthenticated={isAuthenticated}
            hasPermissionToNavigateToDetail={hasPermissionToNavigateToDetail}
          />
        </Stack>
      </Stack>

      {/* Download popup */}
      <DownloadPopup
        title={t("personalSearch.personalSearchReport")}
        showPrintModal={showPrintModal}
        onCancleClick={() => {
          setShowPrintModal((prev) => !prev);
          setReportCFUrl(VALUE_EMPTY_STRING);
        }}
        onDownloadClick={handleDownload}
        setSelectedValue={setSelectedValue}
        cfUrl={reportCUrl}
      />
    </>
  );
};

export default PersonnelSearch;
