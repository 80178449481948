import { Tree } from "@minoru/react-dnd-treeview";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Tooltip } from "@mui/material";
import { ReactComponent as CloseFilled } from "assets/images/CloseFilled.svg";
import { ReactComponent as Edit } from "assets/images/Edit.svg";
import AsyncConfirm from "components/shared/confirm-popup/AsyncConfirmPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import {
  getEmployeeDetailsByEmail,
  getMessageFromCode
} from "services/api/query";
import { selectAuth } from "store/slices";
import {
  FIREBASE_FIELD_NAMES,
  N_CHECKER,
  Y_CHECKER,
  dateFormat,
  dateFormatWithTime,
  errorMsgs
} from "utils/constants";
import {
  globalAttributeTypeId,
  nodeTypes,
  prepareForRun,
  requestType,
  rootIds,
  slotNames,
  sourceTree,
  systemId
} from "utils/constants/transportation/RunManagementConstants";
import { getGlobalAttributeValue, sendEmail } from "utils/helpers";
import {
  checkIfSendMail,
  getCurrentRun,
  getCurrentRunInfo,
  getErrorMessage,
  getMergeTypeData,
  getRequestColor,
  getResponseData,
  getSequenceAndPositionNumber,
  getServiceTime,
  getTimeDiff,
  hasChild,
  isCslCustomerExists,
  isRequestInFutureSlot,
  isRequestInPreviousSlot,
  isRunEmpty,
  isSpecialRequestOutside2Hrs
} from "./helper";
import RequestSummaryModal from "./RequestSummaryModal";
import { runManagementStyles } from "./RunManagementStyle";
import { TreeIcon } from "./TreeIcon";

const RunTreeView = ({
  runData,
  runTreeData,
  customerData,
  driverData,
  runDate,
  getError,
  setIsLoading,
  isRunExpand,
  setThrowError,
  setThrowErrorMessage,
  showError,
  unassignedTreeData,
  mainDistrictId,
  getUnassignedRequests,
  getAassignedRequests,
  selectedNodeIdMain,
  setSelectedNodeIdMain,
  selectedNodeIdSub,
  setSelectedNodeIdSub,
  mainTree,
  setCurrentRunInfoMain,
  setCurrentRunInfoSub,
  localeByBranch,
  setIsShowRunModal,
  setEditRunInfo,
  expandIds,
  updateOpenNodes,
  setSelectedRunId
}) => {
  const { auth } = useSelector(selectAuth);
  const [treeData, setTreeData] = useState(null);
  const [isShowRequestModal, setIsShowRequestModal] = useState(false);
  const [getConfirmation, Confirmation] = AsyncConfirm();
  const [requestId, setRequestId] = useState(null);
  const [toggledNodes, setToggledNodes] = useState(new Set());
  const root = rootIds.currentRunTasks;
  const loggedInUserId = auth?.user?.employee_id;

  useEffect(() => {
    setTreeData(runTreeData);
    //eslint-disable-next-line
  }, [runTreeData]);

  const saveData = async (requestBody, resultCount, url, sourceData) => {
    try {
      setThrowError(false);
      let data = await getResponseData(requestBody, url, resultCount);
      if (
        data?.data[0][0]?.sql_error === errorMsgs.noError ||
        data?.error === null
      ) {
        if (checkIfSendMail(runTreeData, sourceData)) {
          await getEmailDetail(sourceData);
        }
        await Promise.all([
          getUnassignedRequests(),
          getAassignedRequests(runDate)
        ]);
      } else if (data?.data[0][0].sql_error !== errorMsgs.noError) {
        let messageDetail = await getMessageFromCode(
          data?.data[0][0].sql_error
        );
        let messageDescr = messageDetail[0].descr;
        let message = "";
        if (messageDescr.includes("|")) {
          let errors = data?.data[0][0].err_parms.split("|");
          let msgArray = messageDescr.split("|");
          for (var i = 0; i < msgArray.length; i++) {
            message = message + msgArray[i] + (errors[i] || "");
          }
        }
        setThrowErrorMessage(message || messageDescr);
        setThrowError(true);
      } else if (data?.error) {
        showError();
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      showError();
    }
  };

  const getCustomerName = (id) => {
    let data = customerData.find((item) => item.id === id);
    return data?.number.trim();
  };

  const getEmailDetail = async (sourceData) => {
    try {
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        customer_id: sourceData.customer_id
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.runManagement.getEmailAddress}`,
        1
      );
      let email = data?.data[0][0].internal_email_addr;
      if (data?.error) {
        showError();
      } else if (email) {
        let mailBody = `${t("runManagement.emailBody", {
          customerName: getCustomerName(sourceData.customer_id),
          cslCustomerName: getCustomerName(sourceData.csl_customer_id),
          date: dayjs.utc().locale(localeByBranch).format(dateFormatWithTime),
          name: auth?.user?.first_name + " " + auth?.user?.last_name
        })}`;

        let mailSubject = `${t("runManagement.emailSubject")}`;

        let empDetail = await getEmployeeDetailsByEmail(
          email,
          FIREBASE_FIELD_NAMES.BUSINESS_EMAIL_ADDR
        );

        let emailAddress = [
          {
            emailAddress: email,
            name: empDetail[0].first_name + " " + empDetail[0].last_name
          }
        ];

        const response = await sendEmail(emailAddress, mailBody, mailSubject);

        if (response?.data?.isSuccess) {
          // email sent
          return true;
        } else {
          showError();
        }
      }
    } catch (e) {
      showError();
    }
  };

  const handleDrop = async (newTreeData, dropNodeDetails) => {
    setIsLoading(true);
    setThrowError(false);
    let dragSource = dropNodeDetails.dragSource;
    let dropTarget = dropNodeDetails.dropTarget;
    let runDateObj = new Date(runDate);
    let requestTypeId = dragSource?.data.request_type_id;
    let mergeTypeData;
    let sequenceAndPositionNumber = getSequenceAndPositionNumber(
      runTreeData,
      dragSource,
      dropTarget
    );
    let sequenceNumber = sequenceAndPositionNumber.sequenceNumber;
    let runPositionId = sequenceAndPositionNumber.runPositionId;

    if (!dropTarget || dragSource?.type === nodeTypes.time) {
      setIsLoading(false);
      return;
    }

    if (
      dragSource?.data.run_id === dropTarget?.data.run_id &&
      dragSource?.data.run_position_time === getServiceTime(dropTarget?.text)
    ) {
      setIsLoading(false);
      return;
    }

    // When Dropping an unassigned request
    if (dragSource?.source === sourceTree.unassigned) {
      // If the drop target is not a time slot or On Call slot
      if (dropTarget?.type !== nodeTypes.time) {
        setIsLoading(false);
        return;
      }
      if (dragSource?.type === nodeTypes.date) {
        setIsLoading(false);
        return;
      }
      //if the request date and the run date are not the same
      let requestDateObj = new Date(
        dragSource?.data.service_date.split("T")[0]
      );
      if (requestDateObj.getTime() !== runDateObj.getTime()) {
        getError(errorMsgs.errorCode20460);
        return;
      }
    }

    // Check the drop time slot and make sure it follows the On Call Rules
    if (dropTarget?.text === slotNames.onCall) {
      if (dragSource?.data.on_call_flag === N_CHECKER) {
        getError(errorMsgs.errorCode20410);
        return;
      }
    }

    // Check if its a special request outside 2 hrs (See 12)
    if (
      dragSource?.type === nodeTypes.parentCustomer ||
      dragSource?.type === nodeTypes.customer ||
      dragSource?.type === nodeTypes.request
    ) {
      let specialRequestOutside2Hrs = isSpecialRequestOutside2Hrs(
        dragSource,
        dropTarget
      );
      if (specialRequestOutside2Hrs) {
        let message = await getErrorMessage(errorMsgs.errorCode20463);
        setIsLoading(false);
        let selection = await getConfirmation(message);
        if (selection === false) {
          return;
        }
      }
    }
    // If Source type ==  Customer
    if (dragSource?.type === nodeTypes.customer) {
      if (
        isCslCustomerExists(
          runData,
          dropTarget,
          dragSource?.data?.csl_customer_id
        )
      ) {
        getError(errorMsgs.errorCode20405);
        return;
      }
    }

    // If Source type == Request
    if (dragSource?.type === nodeTypes.request) {
      if (dragSource?.source === sourceTree.unassigned) {
        // If dragging from the unassigned requests tree
        if (requestTypeId === requestType.cricitalSpecial) {
          if (dragSource.data.on_call_flag === N_CHECKER) {
            let dropTime = new Date(dropTarget?.data.scheduled_start_time);
            // get timestamp of the drop time
            let createdTime = new Date(
              dragSource?.data.request_logged_datetime
            );
            var hours = getTimeDiff(dropTime, createdTime);
            if (hours > 2) {
              let message = await getErrorMessage(errorMsgs.errorCode20463);
              setIsLoading(false);
              let selection = await getConfirmation(message);
              if (selection === false) {
                return;
              }
            }
          }
        }
      } else {
        // If dragging from the current run tree
        //       requestHasContainers - If the selected request has subsequent containers (M nodes)
        // if (requestHasContainers && (Request_Type_Id = 1 || Request_Type_Id = 2)  && onSameRun):
        //   Show message 20406

        let onSameRun = false;
        let sourceRun = getCurrentRun(treeData, dragSource.parent);
        let targetRun = getCurrentRun(treeData, dropTarget.parent);
        if (sourceRun.id === targetRun.id) {
          onSameRun = true;
        } else {
          onSameRun = false;
        }
        let requestHasContainers = hasChild(treeData, dragSource.id);
        if (
          requestHasContainers &&
          (requestTypeId === requestType.scheduledService ||
            requestTypeId === requestType.addOn) &&
          onSameRun
        ) {
          getError(errorMsgs.errorCode20406);
          return;
        }
      }
    }

    // If Source type == Container
    if (dragSource?.type === nodeTypes.container) {
      let onSameRun = false;
      if (dragSource?.source === sourceTree.currentRun) {
        if (dragSource?.data.run_id === dropTarget?.data.run_id) {
          onSameRun = true;
        }
      } else if (dragSource?.source === sourceTree.unassigned) {
        getError(errorMsgs.errorCode20404);
        return;
      }

      if (
        requestTypeId === requestType.reRun ||
        requestTypeId === requestType.standardSpecial ||
        requestTypeId === requestType.cricitalSpecial ||
        requestTypeId === requestType.disasterRecovery
      ) {
        getError(errorMsgs.errorCode20403);
        return;
      }

      if (
        (requestTypeId === requestType.scheduledService ||
          requestTypeId === requestType.addOn) &&
        onSameRun
      ) {
        getError(errorMsgs.errorCode20406);
        return;
      }

      if (
        requestTypeId === requestType.scheduledService &&
        !onSameRun &&
        isRequestInPreviousSlot(
          runData,
          dropTarget?.data?.run_id,
          dropTarget?.data?.request_id,
          dropTarget?.data?.run_position_id
        )
      ) {
        getError(errorMsgs.errorCode20400);
        return;
      }
      if (
        requestTypeId === requestType.scheduledService &&
        !onSameRun &&
        isRequestInFutureSlot(
          runData,
          dropTarget?.data?.run_id,
          dropTarget?.data?.request_id,
          dropTarget?.data?.run_position_id
        )
      ) {
        getError(errorMsgs.errorCode20403);
        return;
      }
    }

    mergeTypeData = getMergeTypeData(runData, dragSource, dropTarget);

    const attributeData = await getGlobalAttributeValue(
      mainDistrictId,
      systemId,
      globalAttributeTypeId
    );

    if (attributeData === Y_CHECKER) {
      // Target must be in the "Prepared for Run" (type) (exchange_status_id = 9) status and if so -->
      if (dropTarget?.data.exchange_status_id === prepareForRun) {
        let messageId = errorMsgs.errorCode80108;
        let isRedeliveryPossible = true;
        if (dragSource?.type === nodeTypes.request) {
          if (dragSource?.source === sourceTree.unassigned) {
            if (dragSource?.data.redelivery_flag === Y_CHECKER) {
              if (
                dragSource?.data.redelivery_run_name !==
                dropTarget.data.run_name
              ) {
                messageId = errorMsgs.errorCode80107;
              }
            } else {
              let requestHasChild = hasChild(unassignedTreeData, dragSource.id);
              if (requestHasChild) {
                messageId = errorMsgs.errorCode80109;
                isRedeliveryPossible = false;
              }
            }
          } else {
            let requestHasChild = hasChild(treeData, dragSource.id);
            if (requestHasChild) {
              messageId = errorMsgs.errorCode80109;
              isRedeliveryPossible = false;
            }
          }

          if (
            !(
              requestTypeId === requestType.reRun ||
              requestTypeId === requestType.standardSpecial ||
              requestTypeId === requestType.cricitalSpecial
            )
          ) {
            messageId = errorMsgs.errorCode80109;
            isRedeliveryPossible = false;
          }
        }

        if (isRedeliveryPossible === false) {
          if (messageId === errorMsgs.errorCode80107) {
            getError(errorMsgs.errorCode80107, dropTarget?.data.run_name);
            return;
          } else {
            getError(messageId);
            return;
          }
        } else {
          // If Run_IsRedeliveryPossible returns true
          let message = await getErrorMessage(errorMsgs.errorCode80108);
          setIsLoading(false);
          let selection = await getConfirmation(message);
          if (selection === false) {
            return;
          }
        }
      }
    }

    // Finally Save the node
    let bAssignToPrepForRunStat = true;
    if (dropTarget?.data.assign_to_prep_for_run_stat === Y_CHECKER) {
      bAssignToPrepForRunStat = true;
    } else {
      bAssignToPrepForRunStat = false;
    }

    let requestBody;
    let url;
    let newServiceTime = getServiceTime(dropTarget?.text);
    let serviceDate = dayjs.utc(runDate).format(dateFormat.universalFormat);

    // When dragging from unassigned requests tree (Insert)
    if (dragSource.source === sourceTree.unassigned) {
      if (
        dragSource?.type === nodeTypes.parentCustomer ||
        dragSource?.type === nodeTypes.customer
      ) {
        if (!dragSource?.isCslChild) {
          if (bAssignToPrepForRunStat === true) {
            // call run_mgmt_redelivery_assign_csl_sp
            requestBody = JSON.stringify({
              main_district_id: mainDistrictId,
              service_date: serviceDate,
              csl_customer_id: dragSource?.data.csl_customer_id,
              run_id: dropTarget?.data.run_id,
              service_time: newServiceTime,
              sequence_number: sequenceNumber,
              old_run_id: "",
              old_service_time: "",
              child_customer_id: dragSource?.data.customer_id,
              employee_id: loggedInUserId
            });
            url = `${CF_URLS.runManagement.assignCslToRun}`;
          } else {
            // call run_mgmt_assign_location_sp
            requestBody = {
              main_district_id: mainDistrictId,
              service_date: serviceDate,
              csl_customer_id: dragSource?.data.csl_customer_id,
              new_run_id: dropTarget?.data.run_id,
              new_service_time: newServiceTime,
              new_sequence_number: sequenceNumber,
              employee_id: loggedInUserId
            };
            url = `${CF_URLS.runManagement.assignLocationToRun}`;
          }
        } else {
          if (bAssignToPrepForRunStat === true) {
            // call run_mgmt_redelivery_assign_sp
            requestBody = JSON.stringify({
              main_district_id: mainDistrictId,
              service_date: serviceDate,
              csl_customer_id: dragSource?.data.csl_customer_id,
              customer_id: dragSource?.data.customer_id,
              request_id: dropTarget?.data.request_id,
              run_id: dropTarget?.data.run_id,
              run_position_id: runPositionId,
              service_time: newServiceTime,
              sequence_number: sequenceNumber,
              old_run_position_id: "",
              redelivery_csl_guid: "",
              employee_id: loggedInUserId
            });
            url = `${CF_URLS.runManagement.assignDeliveryRequestToRun}`;
          } else {
            // call run_mgmt_assign_customer_sp
            requestBody = JSON.stringify({
              main_district_id: mainDistrictId,
              service_date: serviceDate,
              csl_customer_id: dragSource?.data.csl_customer_id,
              customer_id: dragSource?.data.customer_id,
              new_run_id: dropTarget?.data.run_id,
              new_run_position_id: runPositionId || 0,
              new_service_time: newServiceTime,
              new_sequence_number: sequenceNumber,
              employee_id: loggedInUserId
            });
            url = `${CF_URLS.runManagement.assignCustomerToRun}`;
          }
        }
      } else if (dragSource?.type === nodeTypes.request) {
        if (bAssignToPrepForRunStat === true) {
          // call run_mgmt_redelivery_assign_sp
          requestBody = JSON.stringify({
            main_district_id: mainDistrictId,
            service_date: serviceDate,
            csl_customer_id: dragSource?.data.csl_customer_id,
            customer_id: dragSource?.data.customer_id,
            request_id: dropTarget?.data.request_id,
            run_id: dropTarget?.data.run_id,
            run_position_id: runPositionId,
            service_time: newServiceTime,
            sequence_number: sequenceNumber,
            old_run_position_id: "",
            redelivery_csl_guid: "",
            employee_id: loggedInUserId
          });
          url = `${CF_URLS.runManagement.assignDeliveryRequestToRun}`;
        } else {
          // call run_mgmt_assign_request_sp
          requestBody = {
            main_district_id: mainDistrictId,
            service_date: serviceDate,
            csl_customer_id: dragSource?.data.csl_customer_id,
            customer_id: dragSource?.data.customer_id,
            old_request_id: dragSource?.data.request_id,
            new_run_id: dropTarget?.data.run_id,
            new_run_position_id: mergeTypeData.isMerge ? runPositionId : "",
            new_service_time: newServiceTime,
            new_sequence_number: sequenceNumber,
            new_request_id: dropTarget?.data.request_id,
            employee_id: loggedInUserId
          };
          url = `${CF_URLS.runManagement.assignServiceRequestToRun}`;
        }
      } else if (dragSource?.type === nodeTypes.container) {
        // call run_mgmt_assign_container_sp
        requestBody = {
          main_district_id: mainDistrictId,
          service_date: serviceDate,
          csl_customer_id: dragSource?.data.csl_customer_id,
          customer_id: dragSource?.data.customer_id,
          container_id: dragSource?.data.container_id,
          old_request_id: dragSource?.data.request_id,
          new_run_id: dropTarget?.data.run_id,
          new_run_position_id: mergeTypeData.isMerge ? runPositionId : "",
          new_service_time: newServiceTime,
          new_sequence_number: sequenceNumber,
          new_request_id: dropTarget?.data.request_id,
          employee_id: loggedInUserId
        };
        url = `${CF_URLS.runManagement.assignContainerToRun}`;
      }
    }
    // When not dragging from unassigned requests tree (Update)
    else {
      let oldServiceTime = getServiceTime(dragSource?.data.run_position_time);
      if (
        dragSource?.type === nodeTypes.parentCustomer ||
        dragSource?.type === nodeTypes.customer
      ) {
        if (!dragSource?.isCslChild) {
          if (bAssignToPrepForRunStat === true) {
            // call run_mgmt_redelivery_assign_csl_sp
            requestBody = JSON.stringify({
              main_district_id: mainDistrictId,
              service_date: serviceDate,
              csl_customer_id: dragSource?.data.csl_customer_id,
              run_id: dropTarget?.data.run_id,
              service_time: newServiceTime,
              sequence_number: sequenceNumber,
              old_run_id: dragSource?.data.run_id,
              old_service_time: oldServiceTime,
              child_customer_id: dragSource?.data.customer_id,
              employee_id: loggedInUserId
            });
            url = `${CF_URLS.runManagement.assignCslToRun}`;
          } else {
            // call run_mgmt_reassign_location_sp
            requestBody = {
              main_district_id: mainDistrictId,
              service_date: serviceDate,
              csl_customer_id: dragSource?.data.csl_customer_id,
              old_run_id: dragSource?.data.run_id,
              old_service_time: oldServiceTime,
              old_sequence_number: dragSource?.data.sequence_number,
              new_run_id: dropTarget?.data.run_id,
              new_service_time: newServiceTime,
              new_sequence_number: sequenceNumber,
              employee_id: loggedInUserId
            };
            url = `${CF_URLS.runManagement.reAssignLocationToRun}`;
          }
        } else {
          if (bAssignToPrepForRunStat === true) {
            // call run_mgmt_redelivery_assign_sp
            requestBody = JSON.stringify({
              main_district_id: mainDistrictId,
              service_date: serviceDate,
              csl_customer_id: dragSource?.data.csl_customer_id,
              customer_id: dragSource?.data.customer_id,
              request_id: dropTarget?.data.request_id,
              run_id: dropTarget?.data.run_id,
              run_position_id:
                runPositionId || dragSource?.data.run_position_id,
              service_time: newServiceTime,
              sequence_number: sequenceNumber,
              old_run_position_id: dragSource?.data.run_position_id,
              redelivery_csl_guid: "",
              employee_id: loggedInUserId
            });
            url = `${CF_URLS.runManagement.assignDeliveryRequestToRun}`;
          } else {
            // call run_mgmt_reassign_customer_sp
            requestBody = JSON.stringify({
              main_district_id: mainDistrictId,
              service_date: serviceDate,
              csl_customer_id: dragSource?.data.csl_customer_id,
              customer_id: dragSource?.data.customer_id,
              old_run_id: dragSource?.data.run_id,
              old_run_position_id: dragSource?.data.run_position_id,
              old_service_time: oldServiceTime,
              old_sequence_number: dragSource?.data.sequence_number,
              new_run_id: dropTarget?.data.run_id,
              new_run_position_id:
                runPositionId || dragSource?.data.run_position_id,
              new_service_time: newServiceTime,
              new_sequence_number: sequenceNumber,
              employee_id: loggedInUserId
            });
            url = `${CF_URLS.runManagement.reAssignCustomerToRun}`;
          }
        }
      } else if (dragSource?.type === nodeTypes.request) {
        if (bAssignToPrepForRunStat === true) {
          // call run_mgmt_redelivery_assign_sp
          requestBody = JSON.stringify({
            main_district_id: mainDistrictId,
            service_date: serviceDate,
            csl_customer_id: dragSource?.data.csl_customer_id,
            customer_id: dragSource?.data.customer_id,
            request_id: dropTarget?.data.request_id,
            run_id: dropTarget?.data.run_id,
            run_position_id: runPositionId,
            service_time: newServiceTime,
            sequence_number: sequenceNumber,
            old_run_position_id: dragSource?.data.run_position_id,
            redelivery_csl_guid: "",
            employee_id: loggedInUserId
          });
          url = `${CF_URLS.runManagement.assignDeliveryRequestToRun}`;
        } else {
          // call run_mgmt_reassign_request_sp
          requestBody = {
            main_district_id: mainDistrictId,
            service_date: serviceDate,
            csl_customer_id: dragSource?.data.csl_customer_id,
            customer_id: dragSource?.data.customer_id,
            old_run_id: dragSource?.data.run_id,
            old_run_position_id: dragSource?.data.run_position_id,
            old_service_time: oldServiceTime,
            old_sequence_number: dragSource?.data.sequence_number,
            old_request_id: dragSource?.data.request_id,
            new_run_id: dropTarget?.data.run_id,
            new_run_position_id: runPositionId,
            new_service_time: newServiceTime,
            new_sequence_number: sequenceNumber,
            new_request_id: dropTarget?.data.request_id,
            employee_id: loggedInUserId
          };
          url = `${CF_URLS.runManagement.reAssignDeliveryRequestToRun}`;
        }
      } else if (dragSource?.type === nodeTypes.container) {
        // call run_mgmt_reassign_container_sp
        requestBody = {
          main_district_id: mainDistrictId,
          service_date: serviceDate,
          csl_customer_id: dragSource?.data.csl_customer_id,
          customer_id: dragSource?.data.customer_id,
          container_id: dragSource?.data.container_id,
          old_run_id: dragSource?.data.run_id,
          old_run_position_id: dragSource?.data.run_position_id,
          old_service_time: oldServiceTime,
          old_sequence_number: dragSource?.data.sequence_number,
          old_request_id: dragSource?.data.request_id,
          new_run_id: dropTarget?.data.run_id,
          new_run_position_id: runPositionId,
          new_service_time: newServiceTime,
          new_sequence_number: sequenceNumber,
          new_request_id: dropTarget?.data.request_id,
          employee_id: loggedInUserId
        };
        url = `${CF_URLS.runManagement.reAssignContainerToRun}`;
      }
    }
    saveData(requestBody, 2, url, dragSource?.data);
  };

  const handleSelect = (node) => {
    setThrowError(false);
    mainTree ? setSelectedNodeIdMain(node.id) : setSelectedNodeIdSub(node.id);
    let runInfo = getCurrentRunInfo(driverData, node.data, node);
    mainTree ? setCurrentRunInfoMain(runInfo) : setCurrentRunInfoSub(runInfo);
    mainTree && setSelectedRunId(node?.data?.run_id);
  };

  const editRunClick = (node) => {
    setThrowError(false);
    setEditRunInfo(node);
    setIsShowRunModal(true);
  };

  const deleteRun = async (runId) => {
    try {
      setIsLoading(true);
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        run_id: runId
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.runManagement.deleteRun}`,
        1
      );
      if (data?.data[0]) {
        await getAassignedRequests(runDate);
        setIsShowRunModal(false);
      } else if (data?.error) {
        showError();
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      showError();
    }
  };

  const deleteRunClick = async (node) => {
    setThrowError(false);
    setIsLoading(true);
    let isEmpty = isRunEmpty(runData, node?.data?.run_id);
    if (!isEmpty) {
      getError(errorMsgs.errorCode20457);
    } else {
      let message = await getErrorMessage(
        errorMsgs.errorCode20465,
        getCurrentRunInfo(driverData, node?.data)
      );
      setIsLoading(false);
      let selection = await getConfirmation(message);
      if (selection) {
        deleteRun(node?.data?.run_id);
      }
    }
  };

  const handleRequestClick = (node) => {
    setRequestId(node.data.request_id);
    setIsShowRequestModal(true);
  };

  const handleToggle = (nodeId, onToggle) => {
    if (!toggledNodes.has(nodeId)) {
      onToggle();
      setToggledNodes(new Set(toggledNodes).add(nodeId));
    }
  };

  return (
    <div className="tree-container">
      {treeData && (
        <Tree
          tree={treeData}
          rootId={root}
          onDrop={handleDrop}
          sort={(node1, node2) => {
            return (
              parseInt(node1?.data?.sort_order) -
              parseInt(node2?.data?.sort_order)
            );
          }}
          initialOpen={expandIds.length > 0 ? expandIds : isRunExpand}
          enableAnimateExpand={true}
          onChangeOpen={(items) => {
            updateOpenNodes(items);
          }}
          render={(node, { depth, isOpen, onToggle, hasChild }) => {
            if (!isOpen && hasChild && node.type === nodeTypes.time) {
              handleToggle(node.id, onToggle);
            }
            return (
              <div
                id={`${node.id}`}
                style={{
                  marginLeft: hasChild
                    ? `${depth * 20}px`
                    : `${depth * 20 + 20}px`
                }}
                className={`root-node ${
                  (mainTree && node.id === selectedNodeIdMain) ||
                  (!mainTree && node.id === selectedNodeIdSub) ||
                  ((node.type === nodeTypes.customer ||
                    node.type === nodeTypes.parentCustomer) &&
                    node.id === expandIds[0])
                    ? "selected"
                    : ""
                }`}
                onClick={() => handleSelect(node)}
              >
                {hasChild && (
                  <span onClick={() => onToggle()} className="arrow-icon">
                    {isOpen ? (
                      <KeyboardArrowDownIcon
                        sx={runManagementStyles.iconKey1}
                        id={
                          node.type === nodeTypes.run
                            ? `${node.text}`
                            : `${node.data.run_name + " " + node.text}`
                        }
                      />
                    ) : (
                      <KeyboardArrowRightIcon
                        sx={runManagementStyles.iconKey1}
                        id={
                          node.type === nodeTypes.run
                            ? `${node.text}`
                            : `${node.data.run_name + " " + node.text}`
                        }
                      />
                    )}
                  </span>
                )}
                <TreeIcon type={node.type} />
                <span
                  className="node-text"
                  style={{
                    color: getRequestColor(node?.data?.request_type_id)
                  }}
                  onDoubleClick={() =>
                    node.type === nodeTypes.request && handleRequestClick(node)
                  }
                >
                  {node.text}
                </span>
                {node.type === nodeTypes.run && mainTree && (
                  <span className="node-text">
                    <Tooltip
                      title={t("runManagement.editRun")}
                      onClick={() => editRunClick(node)}
                    >
                      <Edit />
                    </Tooltip>
                    <Tooltip
                      title={t("runManagement.deleteRun")}
                      onClick={() => deleteRunClick(node)}
                    >
                      <CloseFilled />
                    </Tooltip>
                  </span>
                )}
              </div>
            );
          }}
        />
      )}

      {/* Confimation popup */}
      <Confirmation />

      {isShowRequestModal && (
        <RequestSummaryModal
          setIsShowRequestModal={setIsShowRequestModal}
          mainDistrictId={mainDistrictId}
          requestId={requestId}
          customerData={customerData}
          setIsLoading={setIsLoading}
          setThrowError={setThrowError}
          setThrowErrorMessage={setThrowErrorMessage}
        />
      )}
    </div>
  );
};
export default RunTreeView;
