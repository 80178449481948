import {
  Alert,
  Autocomplete,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, DatePickerField } from "components/core";

import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";

import { CF_URLS } from "services/api/endpoints";
import {
  CLEAR,
  dateFormat,
  ERROR_TYPES,
  VALUE_EMPTY_STRING
} from "utils/constants";
import { day } from "utils/constants/reports-module/Reports";
import { findErrorMessage, getAuthenticatedUserBranch } from "utils/helpers";
import { getRunDates, listRunsByDate } from "../Services";

const DistributionListHistory = ({
  isOpen,
  onCloseModal,
  setCommonDownloadModal,
  getCallbackData,
  setReportCFUrl
}) => {
  const { localeByBranch, currentBranch } = useSelector(selectAuth);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const today = dayjs();

  const defaultValue = today.subtract(1, day);
  const [selectedServiceDate, setSelectedServiceDate] = useState(defaultValue);
  const [selectedOption, setSelectedOption] = useState(null);
  const [getRunDate, setGetRunDate] = useState(null);
  const [isDisable, setIsDisable] = useState(true);
  const [runList, setRunList] = useState([]);

  // print button functionality
  const handlePrint = () => {
    const dateString = dayjs(selectedServiceDate).format(
      dateFormat.universalFormat
    );

    const propData = {
      serviceDate: dateString,
      run: selectedOption
    };
    getCallbackData(propData);
    setReportCFUrl(CF_URLS.printReports.reports.reportDist);
    setCommonDownloadModal(true);
  };

  const handleDateChange = (newValue) => {
    setSelectedServiceDate(newValue);
    setSelectedOption(null);
    setIsDisable(true);
  };
  const handleRunChange = (event, newValue, reason) => {
    if (reason === CLEAR) {
      setIsDisable(true);
      setSelectedOption(null);
    }
    if (newValue) {
      setSelectedOption(newValue);
      setIsDisable(false);
    }
  };
  const getDistriButionRetunDate = async () => {
    try {
      setIsLoading(true);
      let res = await getRunDates(mainDistrictId);
      if (res) {
        setGetRunDate(res.data[0][0]);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getDistriButionRetunDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get runs dropdown Data
  const getRunsList = async () => {
    const dateString = dayjs(selectedServiceDate).format(
      dateFormat.universalFormat
    );

    try {
      setIsLoading(true);
      let res = await listRunsByDate(mainDistrictId, dateString);
      if (res) {
        setRunList(res.data[0]);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getRunsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServiceDate]);

  return (
    <>
      <CommonModal
        open={isOpen}
        minWidth={600}
        title={t("reports.distributionListHistory.title")}
        body={
          <>
            {isLoading && <CircularLoaderFullPage loading={isLoading} />}
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}
            <Typography>
              {t("reports.distributionListHistory.runInformation")}
            </Typography>
            <br />
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <DatePickerField
                    name="serviceDate"
                    label={t("reports.distributionListHistory.serviceDate")}
                    value={dayjs(selectedServiceDate)}
                    minDate={
                      getRunDate ? dayjs(getRunDate.oldest_run_date) : today
                    }
                    maxDate={today}
                    defaultValue={defaultValue}
                    onChange={handleDateChange}
                    locale={localeByBranch}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Autocomplete
                  componentsProps={{
                    clearIndicator: ArrowDown
                  }}
                  popupIcon={<ArrowDown />}
                  clearIcon={<CloseIcon />}
                  name={t("reports.distributionListHistory.run")}
                  disabled={null}
                  options={runList || []}
                  getOptionLabel={(option) => option.run_name}
                  size="medium"
                  value={selectedOption}
                  onChange={handleRunChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("reports.distributionListHistory.run")}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={() => onCloseModal(false)}>
              {t("common.close")}
            </Button>
            <Button
              variant="contained"
              onClick={handlePrint}
              disabled={isDisable}
            >
              {t("common.print")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default DistributionListHistory;
