import i18n from "i18n";

export const activeChargesFilterValues = {
  active: "active",
  stopped: "stopped",
  both: "both"
};

export const activeChargesFilter = [
  {
    label: i18n.t("billingRecurringCharge.activeChargesOnly"),
    value: activeChargesFilterValues.active
  },
  {
    label: i18n.t("billingRecurringCharge.stoppedChargesOnly"),
    value: activeChargesFilterValues.stopped
  },
  {
    label: i18n.t("billingRecurringCharge.activeAndStoppedCharges"),
    value: activeChargesFilterValues.both
  }
];

export const recurringChargeTableColumns = [
  { id: "i", label: "", visible: true },
  {
    id: "customer",
    label: "CUSTOMER",
    visible: true
  },
  {
    id: "catalogItem",
    label: "CATALOG ITEM",
    visible: true
  },
  {
    id: "quantity",
    label: "QUANTITY",
    visible: true
  },
  {
    id: "description",
    label: "DESCRIPTION",
    visible: true
  },
  {
    id: "firstBillingMonth",
    label: "FIRST BILLING MONTH",
    visible: true
  },
  {
    id: "finalMonthBilled",
    label: "FINAL MONTH BILLED",
    visible: true
  },
  {
    id: "reviewMonthly",
    label: "REVIEW MONTHLY",
    visible: true
  }
];

export const defaultQuantity = "0.00";
export const monthSliceLimit = 10;

export const bugCheckerTemp = 999.99;
export const quantityLimit = 9999999.99;
export const quantityTFInputProps = { maxLength: 10, inputMode: "decimal" };
export const descriptionTFInputProps = { maxLength: 50 };

export const finalBillingMonthTemp = "Cancelled";

export const monthMapping = {
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12"
};
export const customer = "customer";
export const catalogItem = "catalogItem";
export const headerQuantity = "quantity";
export const headerDescription = "description";
export const firstBillingMonth = "firstBillingMonth";
export const finalBillingMonth = "finalBillingMonth";
export const reviewMonthly = "reviewMonthly";
