import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack
} from "@mui/material";

import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";

import { GridCloseIcon } from "@mui/x-data-grid";
import { FullCircularLoader } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH,
  SERVICE_WINDOWS
} from "routing/Paths";
import { useGetAllCustomersQuery } from "services/api";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  downloadOptionPdf,
  errorMsgs,
  numberConstants
} from "utils/constants";
import {
  listOfDates,
  monthlyByDay,
  monthlyByWeek
} from "utils/constants/customer-service-information/CustomerScheduleConst";
import {
  For,
  dayCode,
  dayList,
  dayOfWeek,
  dayWeekCodes,
  days,
  errormsg20359,
  keyList,
  midNightTime,
  minusOneChecker,
  onCallChecker,
  one,
  serviceFrequecyProps,
  serviceInfo2,
  two
} from "utils/constants/customer-service-information/service-windows/ServiceWindowsConstants";
import {
  findErrorMessage,
  getResponseData,
  getUserName,
  printReport
} from "utils/helpers";
import ServiceWindowsTable from "./ServiceWindowsTable";
import { ServiceWindowsTableStyles } from "./ServiceWindowsTableStyles";

export const CustomerServiceWindows = () => {
  const { id, navigationData, dataNavigation } = useParams();
  const location = useLocation();
  const { businessDay } = location.state || {};
  const prevCustomersRef = useRef([]);
  const { t } = useTranslation();
  const { currentBranch, auth } = useSelector(selectAuth);
  const [customerServiceWindows, setcustomerServiceWindows] = useState([]);
  const [isErr, setIsErr] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [customer, setCustomer] = useState();
  const [scheduleToSave, setScheduleToSave] = useState([]);
  const [schedulesToUpdate, setSchedulesToUpdate] = useState([]);
  const [schedulesToDelete, setSchedulesToDelete] = useState([]);
  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [isPrintButtonDisabled, setIsPrintButtonDisabled] = useState(false);
  const [isResetCSLDisabled, setIsResetCSLDisabled] = useState(true);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(true);
  const [isChangesUnSaved, setIsChangsUnSaved] = useState(false);
  const [infoMsg, setInfomsg] = useState("");
  const [infoMsg2, setInfomsg2] = useState("");
  const [isPrintVisible, setIsPrintVisible] = useState(false);
  const [isCancelDisabled, setIsCancelDisabled] = useState(true);
  // Print
  const [selectedPrintType, setSelectedPrintType] = useState(downloadOptionPdf);
  const [reportCUrl, setReportCFUrl] = useState(VALUE_EMPTY_STRING);
  const [throwReportError, setThrowReportError] = useState(false);
  const [throwReportErrorMessage, setThrowReportErrorMessage] = useState(null);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [
    withSchedulesRequiringServiceWindows,
    setWithSchedulesRequiringServiceWindows
  ] = useState(false);
  const selectedBranchId = currentBranch?.district_id;
  const { data: customers = [] } = useGetAllCustomersQuery(
    { mainDistrictId: selectedBranchId },
    {
      selectFromResult: ({ data }) => ({
        data: [].concat(data) ?? []
      })
    }
  );
  const initVals = useRef();

  useEffect(() => {
    if (
      id &&
      customers[0] &&
      customers.length > 0 &&
      customer === undefined &&
      customer !== null
    ) {
      const customer = customers.filter((ele) => ele.value === id);
      setCustomer([customer[0]]);
      onURLParam(customer[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, customers, customer]);

  useEffect(() => {
    if (
      customerServiceWindows &&
      customerServiceWindows.length > 0 &&
      customer
    ) {
      let isDisbled =
        customerServiceWindows.filter(
          (item) => item.tripInformation[1] !== customer[0].value
        ).length === 0;
      setIsResetCSLDisabled(isDisbled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerServiceWindows]);

  const onURLParam = async (newValue) => {
    setScheduleToSave([]);
    setSchedulesToUpdate([]);
    setSchedulesToDelete([]);
    if (newValue !== null) {
      try {
        setIsloading(true);
        const reqBodyCustomerExists = {
          main_district_id: selectedBranchId,
          customer_id: newValue.value
        };
        const reqBodyCustomerServiceFrequncy = {
          main_district_id: selectedBranchId,
          customer_id: newValue.value
        };
        const reqBodyCustomerSchedules = {
          main_district_id: selectedBranchId,
          customer_id: newValue.value
        };
        const frequencyExist = await getResponseData(
          reqBodyCustomerExists,
          CF_URLS.serviceWindows?.customerFrequencyExist,
          2
        );

        if (frequencyExist.data[0][0].exists === Y_CHECKER) {
          setIsErr(false);
          setErrorMsg("");
          const serviceFreqData = await getResponseData(
            reqBodyCustomerServiceFrequncy,
            CF_URLS.serviceWindows?.customerServiceFrequency,
            3
          );

          const daysSelected = [];
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onMonday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.monday);
          }
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onTuesday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.tuesday);
          }
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onWednesday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.wednesday);
          }
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onThursday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.thursday);
          }
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onFriday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.friday);
          }
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onSaturday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.saturday);
          }
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onSunday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.sunday);
          }
          if (
            serviceFreqData.data[0][0]["service_frequency_code"].trim() ===
              listOfDates &&
            !daysSelected?.length
          ) {
            const [response, lastDate] = await Promise.allSettled([
              getResponseData(
                JSON.stringify({
                  customer_id: newValue.value,
                  main_district_id: currentBranch?.value
                }),
                `${CF_URLS.customerInformationSchedule.customerServiceFrequencyInformation}`,
                2
              ),
              getResponseData(
                JSON.stringify({
                  main_district_id: currentBranch?.value
                }),
                `${CF_URLS.customerInformationSchedule.lastGenrunDate}`,
                2
              )
            ]);
            let selectedDateListItem = [];
            response?.value?.data[1]?.forEach((item) => {
              if (
                dayjs(lastDate?.value?.data[0][0]?.last_gen_run_date).isBefore(
                  item?.service_date
                ) &&
                dayjs(
                  response?.value?.data[0][0]?.generated_date_start
                ).isBefore(item?.service_date)
              ) {
                let day = Number(dayjs.utc(item?.service_date).get("day"));
                if (!selectedDateListItem?.includes(`${day}`)) {
                  selectedDateListItem.push(`${day}`);
                }
              }
            });
            if (selectedDateListItem?.length) {
              selectedDateListItem?.forEach((item) => {
                daysSelected.push(dayOfWeek[keyList[item]]);
              });
            }
          }
          if (
            serviceFreqData.data[0][0]["service_frequency_code"].trim() ===
            monthlyByWeek
          ) {
            let dayOfWeekCode = serviceFreqData.data[0][0]?.day_of_week_code;
            if (dayOfWeekCode === dayWeekCodes.mon) {
              daysSelected.push(dayOfWeek.monday);
            } else if (dayOfWeekCode === dayWeekCodes.tue) {
              daysSelected.push(dayOfWeek.tuesday);
            } else if (dayOfWeekCode === dayWeekCodes.wed) {
              daysSelected.push(dayOfWeek.wednesday);
            } else if (dayOfWeekCode === dayWeekCodes.thu) {
              daysSelected.push(dayOfWeek.thursday);
            } else if (dayOfWeekCode === dayWeekCodes.fri) {
              daysSelected.push(dayOfWeek.friday);
            } else if (dayOfWeekCode === dayWeekCodes.sat) {
              daysSelected.push(dayOfWeek.saturday);
            } else if (dayOfWeekCode === dayWeekCodes.sun) {
              daysSelected.push(dayOfWeek.sunday);
            }
          }
          if (
            serviceFreqData.data[0][0]["service_frequency_code"].trim() ===
            monthlyByDay
          ) {
            if (businessDay) {
              const filteredData = dayList.filter((day) =>
                dayCode.includes(day.value)
              );
              setDaysOfWeek(filteredData);
            } else {
              setDaysOfWeek(dayList);
            }
          }
          if (daysSelected.length > 0) setDaysOfWeek(daysSelected);

          if (
            serviceFreqData.data[0][0]["service_frequency_code"].trim() !==
            onCallChecker
          ) {
            const serviceApiData = await getResponseData(
              reqBodyCustomerSchedules,
              CF_URLS.serviceWindows?.customerScheduleRoutes,
              3
            );
            const orderedWeeks = Object.values(dayWeekCodes);

            const sortedData = serviceApiData.data[0].sort(
              (a, b) =>
                orderedWeeks.indexOf(a.day_of_week_code) -
                orderedWeeks.indexOf(b.day_of_week_code)
            );
            const custSerWindowsVals = sortedData.map((ele) => ({
              tripInformation: [
                ele["day_of_week_code"],
                ele["csl_customer_id"]
              ],
              window1: [ele["start_time_window1"], ele["end_time_window1"]],
              window2: [ele["start_time_window2"], ele["end_time_window2"]],
              data: {
                cslCustomerId: ele.csl_customer_id,
                cslCustomerScheduleId: ele.csl_customer_schedule_id,
                customerScheduleId: ele.customer_schedule_id,
                customerSubprograms: ele.customer_subprograms,
                dayOfWeekCode: ele.day_of_week_code,
                endTimeWindow1: ele.end_time_window1,
                endTimeWindow2: ele.end_time_window2,
                exchangeLocationTypeCode: ele.exchange_location_type_code,
                routeCode: ele.route_code,
                serviceTime: ele.service_time,
                startTimeWindow1: ele.start_time_window1,
                startTimeWindow2: ele.start_time_window2,
                timeStamp: ele.timestamp
              }
            }));
            setcustomerServiceWindows(custSerWindowsVals);
            setIsResetCSLDisabled(false);
            setIsCancelDisabled(false);
            initVals.current = custSerWindowsVals;
            setIsloading(false);
          } else {
            let errorMsg = await getMessageFromCode(errorMsgs.errorCode20391);
            if (Array.isArray(errorMsg)) {
              errorMsg = errorMsg.find(
                (item) => item.id === errorMsgs.errorCode20391
              )?.descr;
              setErrorMsg(errorMsg);
            }
            setCustomer();
            setcustomerServiceWindows([]);
            setIsCancelDisabled(false);
            setIsloading(false);
            setIsErr(true);
          }
        } else {
          let errorMsg = await getMessageFromCode(errorMsgs.errorCode20359);
          if (Array.isArray(errorMsg)) {
            errorMsg = errorMsg.find(
              (item) => item.id === errorMsgs.errorCode20359
            )?.descr;
            setErrorMsg(errorMsg.replace("|", errormsg20359));
          }
          setCustomer();
          setcustomerServiceWindows([]);
          setIsCancelDisabled(false);
          setIsloading(false);
          setIsErr(true);
        }
      } catch (error) {
        setErrorMsg(findErrorMessage(ERROR_TYPES.ISSUE));
        setCustomer();
        setcustomerServiceWindows([]);
        setIsCancelDisabled(false);
        setIsloading(false);
      }
    } else {
      setCustomer();
      setcustomerServiceWindows([]);
      setIsCancelDisabled(false);
    }
  };

  const setServiceWindows = (value, isadd, index) => {
    setIsSaveButtonEnabled(false);
    setIsCancelDisabled(false);
    if (!isChangesUnSaved) setIsChangsUnSaved(true);
    if (isadd) {
      setScheduleToSave((ele) => [...ele, value]);
      setcustomerServiceWindows((ele) => {
        const newArr = [
          ...ele,
          {
            tripInformation: [value.dayOfWeekCode, value.cslCustomerId],
            window1: [value.startTimeWindow1, value.endTimeWindow1],
            window2: [value.startTimeWindow2, value.endTimeWindow2],
            data: value
          }
        ];
        const orderedWeeks = Object.values(dayWeekCodes);
        return newArr.sort(
          (a, b) =>
            orderedWeeks.indexOf(a.tripInformation[0]) -
            orderedWeeks.indexOf(b.tripInformation[0])
        );
      });
    } else {
      setSchedulesToUpdate((ele) =>
        ele.some((item) => item.ts1 === value.ts1)
          ? ele.map((item, idx) =>
              item.ts1 === value.ts1 && idx === index ? value : item
            )
          : [...ele, value]
      );
      let tempSave = scheduleToSave?.map((item) => {
        if (item?.id === value?.id) {
          return {
            ...item,
            startTimeWindow1: value.startTimeWindow1,
            startTimeWindow2: value.startTimeWindow2,
            endTimeWindow1: value.endTimeWindow1,
            endTimeWindow2: value.endTimeWindow2
          };
        }
        return item;
      });
      setScheduleToSave([...tempSave]);
      setcustomerServiceWindows((ele) =>
        ele.map((item, idx) =>
          item.data?.customerScheduleId === value.customerScheduleId &&
          idx === index
            ? {
                tripInformation: [
                  item.data?.dayOfWeekCode,
                  value.cslCustomerId
                ],
                window1: [value.startTimeWindow1, value.endTimeWindow1],
                window2: [value.startTimeWindow2, value.endTimeWindow2],
                data: {
                  cslCustomerId: value.cslCustomerId,
                  cslCustomerScheduleId: item.data.cslCustomerScheduleId,
                  customerScheduleId: item.data.customerScheduleId,
                  customerSubprograms: item.data.customerSubprograms,
                  dayOfWeekCode: item.data.dayOfWeekCode,
                  endTimeWindow1: value.endTimeWindow1,
                  endTimeWindow2: value.endTimeWindow2,
                  exchangeLocationTypeCode: item.data.exchangeLocationTypeCode,
                  routeCode: item.data.routeCode,
                  serviceTime: item.data.serviceTime,
                  startTimeWindow1: value.startTimeWindow1,
                  startTimeWindow2: value.startTimeWindow2,
                  timeStamp: item.data.timeStamp,
                  id: value?.id
                }
              }
            : item
        )
      );
    }
  };

  const setServiceWindowsToDelete = async (value, data) => {
    let errorCode;
    const index = customerServiceWindows.findIndex(
      (item) => item.data.customerScheduleId === value.customer_schedule_id
    );
    if (index >= 0) {
      const day = data?.day;
      // Check if there is atleast single trip for the scheduled days. DAYDOESNOTHAVETRIPDEFINED 20369
      const canDeleteTrip = () => {
        const isSameTripAvailable = customerServiceWindows.some(
          (item, i) => i !== index && day === item.tripInformation[0]
        );
        const tripList = customerServiceWindows?.filter(
          (item) => day === item.tripInformation[0]
        );
        if (
          !isSameTripAvailable &&
          tripList?.length === Number(numberConstants.one)
        ) {
          errorCode = errorMsgs.errorCode20369;
          return false;
        }
        return true;
      };
      if (canDeleteTrip()) {
        setIsChangsUnSaved(true);
        setIsSaveButtonEnabled(false);
        setIsCancelDisabled(false);
        if (!data.isUnsaved) {
          setSchedulesToDelete((ele) => [...ele, value]);
          customerServiceWindows.splice(index, 1);
        } else {
          setScheduleToSave((pre) =>
            pre.filter((item) => item.id !== data?.id)
          );
          setcustomerServiceWindows((pre) =>
            pre.filter((item) => item.data.id !== data?.id)
          );
        }
      } else {
        let errorMsg = await getMessageFromCode(errorCode);

        if (Array.isArray(errorMsg)) {
          errorMsg = errorMsg.find((item) => item.id === errorCode)?.descr;
          setErrorMsg(errorMsg);
        }
        setIsloading(false);
        setIsErr(true);
      }
    }
  };

  const showErrorWithCode = async (errorCode, concadStr = "") => {
    if (errorCode) {
      if (errorCode === errorMsgs.errorCode20361) {
        let infoMsg = await getMessageFromCode(errorCode);
        if (Array.isArray(infoMsg)) {
          infoMsg = infoMsg.find((item) => item.id === errorCode)?.descr;
          setInfomsg2(infoMsg.replace("|", serviceInfo2));
        }
        return;
      }
      let errorMsg = await getMessageFromCode(errorCode);
      if (Array.isArray(errorMsg)) {
        errorMsg = errorMsg.find((item) => item.id === errorCode)?.descr;
        setErrorMsg(
          (errorMsg.includes("|") && concadStr
            ? errorMsg.slice(0, errorMsg.indexOf("|"))
            : errorMsg) + concadStr
        );
        setIsErr(true);
      }
    }
  };

  const validateTrips = async () => {
    let errorCode;

    // 1. Check if there are atleast single row present for all days of week. DAYDOESNOTHAVETRIPDEFINED 20370
    const weekDays = Object.values(daysOfWeek);
    weekDays.forEach((day) => {
      const isTripPresentForDay = customerServiceWindows.filter(
        (trip) =>
          trip.tripInformation &&
          trip.tripInformation.length &&
          trip.tripInformation?.[0] === day.value
      );
      if (isTripPresentForDay.length < 1) {
        errorCode = errorMsgs.errorCode20370;
      }
    });

    if (errorCode) {
      await showErrorWithCode(errorCode);
      return false;
    }

    let concadStr = "";
    customerServiceWindows.forEach((trip, index) => {
      if (errorCode) return;
      const { window1, window2, tripInformation } = trip;
      const [day] = tripInformation;
      const [from1, to1] = window1.map(Number);
      const [from2, to2] = window2.map(Number);
      const [from2_1, to2_1] = window2;

      if (to1 < from1 || to2 < from2) {
        errorCode = errorMsgs.errorCode20361;
        return;
      }

      // 3 4. check the values of from and to fields in window 1 and window 2, they should not be empty. Window 1 should have data for both fields, window 2 can/cannot have data for both fields.
      // TIMESFORSVCWIN1INVALID 20367 || TIMESFORSVCWIN2INVALID 20367
      if (!from1 || !to1) {
        errorCode = errorMsgs.errorCode20367;
      }

      if (from2 && !to2) {
        errorCode = errorMsgs.errorCode20367;
      }
      if (!from2 && to2) {
        errorCode = errorMsgs.errorCode20367;
      }

      // 5. If from value is greater than to value for either service windows. TIMEISGREATER 20361
      if (from1 > to1) {
        errorCode = errorMsgs.errorCode20361;
      }
      if (from2 > to2) {
        errorCode = errorMsgs.errorCode20361;
      }

      // 7. Either of from and to values should not be equal for any of the same day. timeoverlap in same window DAYSWITHSAMESTARTANDENDTIMEWIN1 20368 || DAYSWITHSAMESTARTANDENDTIMEWIN2 30008
      if (from1 === to1) {
        concadStr = from1 === to1 ? one : two;
        concadStr += ` ${For} ${
          weekDays.find((item) => item.value === day)?.day
        }`;
        errorCode = errorMsgs.errorCode20368;
      }
      // 6. Both values of from and to should not be equal for both windows. timesoverlap among windows. TIMESOVERLAP 20372
      if (from1 === from2 && to1 === to2) {
        errorCode = errorMsgs.errorCode20372;
      }

      //7. If the start time or end time matches for the same day in both of the windows. DAYSWITHSAMESTARTANDENDTIMEWIN1 20368
      daysOfWeek.forEach((day) => {
        const sameDayTrips = customerServiceWindows.filter(
          (trip) =>
            trip.tripInformation &&
            trip.tripInformation.length &&
            trip.tripInformation?.[0] === day.value
        );

        const sameDayAndServiceLoc = sameDayTrips.filter(
          (t) => t.tripInformation?.[1] === trip.tripInformation?.[1]
        );

        sameDayAndServiceLoc.some((sameDayTrip, idx, matchedObjs) =>
          // eslint-disable-next-line array-callback-return
          matchedObjs.some((otherDayTrip, otherIdx) => {
            const [sfrom1, sto1] = sameDayTrip.window1.map(Number);
            const [sfrom2, sto2] = sameDayTrip.window2.map(Number);
            const [ofrom2, oto2] = otherDayTrip.window2.map(Number);
            const validation =
              idx !== otherIdx &&
              ((sfrom1 < sfrom2 && sfrom2 < sto1) ||
                (sfrom1 < sto2 && sto2 < sto1) ||
                (sfrom1 < ofrom2 && ofrom2 < sto1) ||
                (sfrom1 < oto2 && oto2 < sto1));
            if (validation && !errorCode) {
              errorCode = errorMsgs.errorCode20372;
            }
          })
        );
        const startTimeMatch = sameDayTrips.some(
          (sameDayTrip, idx, matchedObjs) =>
            matchedObjs.some((otherDayTrip, otherIdx) => {
              const validation =
                otherIdx !== idx &&
                sameDayTrip.tripInformation[0] ===
                  otherDayTrip.tripInformation[0] &&
                ((sameDayTrip.window1[0] === otherDayTrip.window1[0] &&
                  sameDayTrip.window1[1] === otherDayTrip.window1[1]) ||
                  (sameDayTrip.window2[0] &&
                  sameDayTrip.window2[1] &&
                  otherDayTrip.window2[0] &&
                  otherDayTrip.window2[1]
                    ? sameDayTrip.window2[0] === otherDayTrip.window2[0] &&
                      sameDayTrip.window2[1] === otherDayTrip.window2[1]
                    : false));
              if (validation) {
                concadStr =
                  sameDayTrip.window1[0] === otherDayTrip.window1[0] &&
                  sameDayTrip.window1[1] === otherDayTrip.window1[1]
                    ? one
                    : two;
                concadStr += ` ${For} ${
                  weekDays.find(
                    (item) => item.value === sameDayTrip.tripInformation[0]
                  )?.day
                }`;
              }
              return validation;
            })
        );
        if (startTimeMatch) {
          errorCode = errorMsgs.errorCode20368;
        }
      });

      // 8. Both of from and to values are 12 am to 6 am for any of the window. Both times are midnight. BOTHTIMESAREMIDNIGHT 30009
      const isMidnightWindow = (from, to) =>
          Number(from) === 0 && Number(to) === 0,
        isMidnightTime = (time) => Number(time) === 0;

      const isMidnightWindow2 = (from, to) =>
          from === midNightTime && to === midNightTime,
        isMidnightTime2 = (time) => time === midNightTime;

      if (isMidnightWindow(from1, to1) || isMidnightWindow2(from2_1, to2_1))
        errorCode = errorMsgs.errorCode20418;

      if (
        isMidnightTime(from1) ||
        isMidnightTime(to1) ||
        isMidnightTime2(from2_1) ||
        isMidnightTime2(to2_1)
      ) {
        errorCode = errorMsgs.errorCode20418;
      }

      return false;
    });

    if (errorCode) {
      await showErrorWithCode(errorCode, concadStr);
      return false;
    }

    return true;
  };

  const onRefresh = async () => {
    try {
      setIsloading(true);
      const reqBodyCustomerSchedules = {
        main_district_id: selectedBranchId,
        customer_id: customer[0].value
      };

      const serviceApiData = await getResponseData(
        reqBodyCustomerSchedules,
        CF_URLS.serviceWindows?.customerScheduleRoutes,
        3
      );
      const orderedWeeks = Object.values(dayWeekCodes);

      const sortedData = serviceApiData.data[0].sort(
        (a, b) =>
          orderedWeeks.indexOf(a.day_of_week_code) -
          orderedWeeks.indexOf(b.day_of_week_code)
      );
      const custSerWindowsVals = sortedData.map((ele) => ({
        tripInformation: [ele["day_of_week_code"], ele["csl_customer_id"]],
        window1: [ele["start_time_window1"], ele["end_time_window1"]],
        window2: [ele["start_time_window2"], ele["end_time_window2"]],
        data: {
          cslCustomerId: ele.csl_customer_id,
          cslCustomerScheduleId: ele.csl_customer_schedule_id,
          customerScheduleId: ele.customer_schedule_id,
          customerSubprograms: ele.customer_subprograms,
          dayOfWeekCode: ele.day_of_week_code,
          endTimeWindow1: ele.end_time_window1,
          endTimeWindow2: ele.end_time_window2,
          exchangeLocationTypeCode: ele.exchange_location_type_code,
          routeCode: ele.route_code,
          serviceTime: ele.service_time,
          startTimeWindow1: ele.start_time_window1,
          startTimeWindow2: ele.start_time_window2,
          timeStamp: ele.timestamp
        }
      }));
      setcustomerServiceWindows(custSerWindowsVals);
      setIsResetCSLDisabled(false);
      initVals.current = custSerWindowsVals;
      setIsloading(false);
    } catch (error) {
      setErrorMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setcustomerServiceWindows([]);
      setIsloading(false);
    }
  };
  const onSave = async (isInfoMsgYes = false) => {
    setIsloading(true);
    setIsResetCSLDisabled(true);
    setIsPrintButtonDisabled(false);

    const isValid = await validateTrips();
    if (isValid) {
      if (Array.isArray(scheduleToSave) && scheduleToSave.length > 0) {
        await scheduleToSave.forEach(async (val, ind) => {
          const jsonData = {
            main_district_id: val.mainDistrictId,
            customer_id: val.customerId,
            day_of_week_code: val.dayOfWeekCode,
            start_time_window1: val.startTimeWindow1,
            end_time_window1: val.endTimeWindow1,
            start_time_window2: val.startTimeWindow2,
            end_time_window2: val.endTimeWindow2,
            csl_customer_sched_id: val.cslCustomerSchedId,
            exch_location_type_code: val.exchLocationTypeCode
          };
          await getResponseData(
            { ...jsonData },
            CF_URLS.serviceWindows?.customerScheduleInsert
          )
            .then((e) => {
              if (ind === scheduleToSave.length - 1) {
                setIsloading(false);
                if (isInfoMsgYes) {
                  setcustomerServiceWindows([]);
                  setCustomer();
                  setScheduleToSave([]);
                  setSchedulesToDelete([]);
                  setSchedulesToUpdate([]);
                  setIsCancelDisabled(false);
                  setIsSaveButtonEnabled(true);
                } else onRefresh();
              }
            })
            .catch((e) => {
              setIsloading(false);
              setErrorMsg(findErrorMessage(ERROR_TYPES.ISSUE));
            });
        });
      }
      if (Array.isArray(schedulesToUpdate) && schedulesToUpdate.length > 0) {
        await schedulesToUpdate.forEach(async (val, ind) => {
          const jsonData = {
            main_district_id: val.mainDistrictId,
            customer_schedule_id: val.customerScheduleId,
            start_time_window1: val.startTimeWindow1,
            end_time_window1: val.endTimeWindow1,
            start_time_window2: val.startTimeWindow2,
            end_time_window2: val.endTimeWindow2,
            csl_customer_sched_id: val.cslCustomerScheduleId,
            exch_location_type_code: val.exchLocationTypeCode,
            ts1: val.ts1
          };
          await getResponseData(
            { ...jsonData },
            CF_URLS.serviceWindows?.customerScheduleUpdate
          )
            .then((e) => {
              if (ind === schedulesToUpdate.length - 1) {
                setIsloading(false);
                if (isInfoMsgYes) {
                  setcustomerServiceWindows([]);
                  setCustomer();
                  setScheduleToSave([]);
                  setSchedulesToDelete([]);
                  setSchedulesToUpdate([]);
                  setIsCancelDisabled(false);
                  setIsSaveButtonEnabled(true);
                } else onRefresh();
              }
            })
            .catch((e) => {
              setIsloading(false);
              setErrorMsg(findErrorMessage(ERROR_TYPES.ISSUE));
            });
        });
      }
      if (Array.isArray(schedulesToDelete) && schedulesToDelete.length > 0) {
        await schedulesToDelete.forEach(async (val, ind) => {
          await getResponseData(
            { ...val },
            CF_URLS.serviceWindows?.customerScheduleDelete
          )
            .then((e) => {
              if (ind === schedulesToDelete.length - 1) {
                setIsloading(false);
                if (isInfoMsgYes) {
                  setcustomerServiceWindows([]);
                  setCustomer();
                  setScheduleToSave([]);
                  setSchedulesToDelete([]);
                  setSchedulesToUpdate([]);
                  setIsCancelDisabled(false);
                  setIsSaveButtonEnabled(true);
                } else onRefresh();
              }
            })
            .catch((e) => {
              setIsloading(false);
              setErrorMsg(findErrorMessage(ERROR_TYPES.ISSUE));
            });
        });
      }
      setScheduleToSave([]);
      setSchedulesToUpdate([]);
      setSchedulesToDelete([]);
      setIsSaveButtonEnabled(true);
      setIsChangsUnSaved(false);
      setIsCancelDisabled(false);
      setIsloading(false);
      setIsErr(false);
      setErrorMsg(null);
    } else {
      setIsloading(false);
    }
  };

  const handleClickPrint = () => {
    setIsPrintVisible(true);
    setReportCFUrl(CF_URLS.printReports.customerInformation.serviceWindows);
  };

  const onResetCSLButtonclick = () => {
    setIsloading(true);
    const updArr = [];
    const resettedServiceLocs = customerServiceWindows.map((c) => {
      if (c.tripInformation?.[1] !== customer?.[0]?.value) {
        updArr.push({
          mainDistrictId: selectedBranchId,
          customerScheduleId: c.data.customerScheduleId,
          startTimeWindow1: c.data.startTimeWindow1,
          endTimeWindow1: c.data.endTimeWindow1,
          startTimeWindow2: c.data.startTimeWindow2,
          endTimeWindow2: c.data.endTimeWindow2,
          cslCustomerSchedId: c.data.cslCustomerScheduleId,
          exchLocationTypeCode: c.data.exchangeLocationTypeCode,
          ts1: c.data.timeStamp,
          cslCustomerScheduleId: c.data.customerScheduleId
        });
      }
      return c.tripInformation?.[1] === customer?.[0]?.value
        ? c
        : {
            ...c,
            tripInformation: [c.tripInformation?.[0], customer?.[0]?.value]
          };
    });
    setSchedulesToUpdate(updArr);
    setcustomerServiceWindows(resettedServiceLocs);
    setIsloading(false);
    setIsSaveButtonEnabled(false);
    setIsCancelDisabled(false);
    setIsPrintButtonDisabled(true);
  };

  const onCustomerSelect = async (newValue) => {
    setScheduleToSave([]);
    setSchedulesToUpdate([]);
    setSchedulesToDelete([]);
    setIsSaveButtonEnabled(true);
    setIsCancelDisabled(false);
    if (newValue !== null) {
      setCustomer([newValue]);
      try {
        setIsloading(true);
        const reqBodyCustomerExists = {
          main_district_id: selectedBranchId,
          customer_id: newValue.value
        };
        const reqBodyCustomerServiceFrequncy = {
          main_district_id: selectedBranchId,
          customer_id: newValue.value
        };
        const reqBodyCustomerSchedules = {
          main_district_id: selectedBranchId,
          customer_id: newValue.value
        };
        const frequencyExist = await getResponseData(
          reqBodyCustomerExists,
          CF_URLS.serviceWindows?.customerFrequencyExist,
          2
        );
        if (frequencyExist.data[0][0].exists === Y_CHECKER) {
          setIsErr(false);
          setErrorMsg("");
          const serviceFreqData = await getResponseData(
            reqBodyCustomerServiceFrequncy,
            CF_URLS.serviceWindows?.customerServiceFrequency,
            3
          );
          const daysSelected = [];
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onMonday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.monday);
          }
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onTuesday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.tuesday);
          }
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onWednesday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.wednesday);
          }
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onThursday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.thursday);
          }
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onFriday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.friday);
          }
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onSaturday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.saturday);
          }
          if (
            serviceFreqData.data[0][0][serviceFrequecyProps.onSunday] ===
            minusOneChecker
          ) {
            daysSelected.push(dayOfWeek.sunday);
          }
          if (
            serviceFreqData.data[0][0]["service_frequency_code"].trim() ===
              listOfDates &&
            !daysSelected?.length
          ) {
            const [response, lastDate] = await Promise.allSettled([
              getResponseData(
                JSON.stringify({
                  customer_id: newValue.value,
                  main_district_id: currentBranch?.value
                }),
                `${CF_URLS.customerInformationSchedule.customerServiceFrequencyInformation}`,
                2
              ),
              getResponseData(
                JSON.stringify({
                  main_district_id: currentBranch?.value
                }),
                `${CF_URLS.customerInformationSchedule.lastGenrunDate}`,
                2
              )
            ]);
            let selectedDateListItem = [];
            response?.value?.data[1]?.forEach((item) => {
              if (
                dayjs(lastDate?.value?.data[0][0]?.last_gen_run_date).isBefore(
                  item?.service_date
                ) &&
                dayjs(
                  response?.value?.data[0][0]?.generated_date_start
                ).isBefore(item?.service_date)
              ) {
                let day = Number(dayjs.utc(item?.service_date).get("day"));
                if (!selectedDateListItem?.includes(`${day}`)) {
                  selectedDateListItem.push(`${day}`);
                }
              }
            });
            if (selectedDateListItem?.length) {
              selectedDateListItem?.forEach((item) => {
                daysSelected.push(dayOfWeek[keyList[item]]);
              });
            }
          }
          if (daysSelected.length > 0) setDaysOfWeek(daysSelected);
          if (
            serviceFreqData.data[0][0]["service_frequency_code"].trim() !==
            onCallChecker
          ) {
            const serviceApiData = await getResponseData(
              reqBodyCustomerSchedules,
              CF_URLS.serviceWindows?.customerScheduleRoutes,
              3
            );

            const orderedWeeks = Object.values(dayWeekCodes);

            const sortedData = serviceApiData.data[0].sort(
              (a, b) =>
                orderedWeeks.indexOf(a.day_of_week_code) -
                orderedWeeks.indexOf(b.day_of_week_code)
            );
            const custSerWindowsVals = sortedData.map((ele) => ({
              tripInformation: [
                ele["day_of_week_code"],
                ele["csl_customer_id"]
              ],
              window1: [ele["start_time_window1"], ele["end_time_window1"]],
              window2: [ele["start_time_window2"], ele["end_time_window2"]],
              data: {
                cslCustomerId: ele.csl_customer_id,
                cslCustomerScheduleId: ele.csl_customer_schedule_id,
                customerScheduleId: ele.customer_schedule_id,
                customerSubprograms: ele.customer_subprograms,
                dayOfWeekCode: ele.day_of_week_code,
                endTimeWindow1: ele.end_time_window1,
                endTimeWindow2: ele.end_time_window2,
                exchangeLocationTypeCode: ele.exchange_location_type_code,
                routeCode: ele.route_code,
                serviceTime: ele.service_time,
                startTimeWindow1: ele.start_time_window1,
                startTimeWindow2: ele.start_time_window2,
                timeStamp: ele.timestamp
              }
            }));
            setcustomerServiceWindows(custSerWindowsVals);
            setIsResetCSLDisabled(false);
            initVals.current = custSerWindowsVals;
            setIsloading(false);
          } else {
            let errorMsg = await getMessageFromCode(errorMsgs.errorCode20391);
            if (Array.isArray(errorMsg)) {
              errorMsg = errorMsg.find(
                (item) => item.id === errorMsgs.errorCode20391
              )?.descr;
              setErrorMsg(errorMsg);
            }
            setCustomer();
            setcustomerServiceWindows([]);
            setIsloading(false);
            setIsErr(true);
          }
        } else {
          let errorMsg = await getMessageFromCode(errorMsgs.errorCode20359);
          if (Array.isArray(errorMsg)) {
            errorMsg = errorMsg.find(
              (item) => item.id === errorMsgs.errorCode20359
            )?.descr;
            setErrorMsg(errorMsg.replace("|", errormsg20359));
          }
          setCustomer();
          setcustomerServiceWindows([]);
          setIsloading(false);
          setIsErr(true);
        }
      } catch (error) {
        setErrorMsg(findErrorMessage(ERROR_TYPES.ISSUE));
        setCustomer();
        setcustomerServiceWindows([]);
        setIsloading(false);
      }
    } else {
      setCustomer();
      setcustomerServiceWindows([]);
    }
  };

  const getDayOfWeek = (value) => {
    let day = "";
    switch (value) {
      case dayWeekCodes.mon:
        day = days.monday;
        break;
      case dayWeekCodes.tue:
        day = days.tuesday;
        break;
      case dayWeekCodes.wed:
        day = days.wednesday;
        break;
      case dayWeekCodes.thu:
        day = days.thursday;
        break;
      case dayWeekCodes.fri:
        day = days.friday;
        break;
      case dayWeekCodes.sat:
        day = days.saturday;
        break;
      case dayWeekCodes.sun:
        day = days.sunday;
        break;
      default:
        break;
    }
    return day;
  };

  const handleCancel = async () => {
    if (isChangesUnSaved) {
      let infoMsg = await getMessageFromCode(errorMsgs.errorCode10039);
      if (Array.isArray(infoMsg)) {
        infoMsg = infoMsg.find(
          (item) => item.id === errorMsgs.errorCode10039
        )?.descr;
        setInfomsg(infoMsg);
      }
    } else {
      setcustomerServiceWindows([]);
      setScheduleToSave([]);
      setSchedulesToDelete([]);
      setSchedulesToUpdate([]);
      setCustomer(null);
      setIsCancelDisabled(true);
    }
  };

  const handleReportDownload = async () => {
    try {
      setIsloading(true);
      setThrowReportError(false);

      let requestBody = {
        main_district_id: selectedBranchId,
        customer_id: customer[0].value,
        customer: customer[0].number,
        user_name: getUserName(auth?.user),
        report_format: selectedPrintType?.type?.toUpperCase()
      };
      let response = await printReport(
        JSON.stringify(requestBody),
        CF_URLS.printReports.customerInformation.serviceWindows
      );

      if (!response.success) {
        setThrowReportErrorMessage(response.error);
        setThrowReportError(true);
      }

      return response;
    } catch (exception) {
      setThrowReportErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowReportError(true);
    } finally {
      setIsloading(false);
    }
  };
  const savechanges = async () => {
    await onSave(true);
    setIsloading(true);
    setIsCancelDisabled(true);
  };

  const getSelectedCustomers = async () => {
    try {
      const reqBody = {
        main_district_id: selectedBranchId
      };

      const customersData = await getResponseData(
        reqBody,
        CF_URLS.serviceWindows?.getActiveCustnumWithNoScheduletodo,
        1
      );

      const numbersToMatch = customersData?.data[0]?.map((item) =>
        item?.number.trim()
      );

      const matchingObjects = customers?.filter((item) =>
        numbersToMatch?.includes(item?.number.trim())
      );

      matchingObjects?.sort((a, b) => a?.number?.localeCompare(b?.number));

      setSelectedCustomers(matchingObjects);
    } catch (error) {
      setThrowReportErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowReportError(true);
    }
  };

  const arraysEqual = (a, b) => {
    if (a.length !== b.length) return false;
    return a.every((item, index) => item === b[index]);
  };

  useEffect(() => {
    if (!arraysEqual(customers, prevCustomersRef.current)) {
      if (customers.length) {
        getSelectedCustomers();
      }
      prevCustomersRef.current = customers;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  useEffect(() => {
    if (navigationData && dataNavigation) {
      setWithSchedulesRequiringServiceWindows(true);
      window.history.replaceState(
        null,
        "",
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SERVICE_WINDOWS}`
      );
    }
  }, [navigationData, dataNavigation]);

  return (
    <>
      {isLoading && <FullCircularLoader />}
      <Collapse
        in={isErr}
        sx={{
          marginBottom: isErr ? spacing.verticalMargin20 : 0
        }}
      >
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsErr(false);
              }}
            >
              <GridCloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {errorMsg}
        </Alert>
      </Collapse>

      {infoMsg && (
        <ConfirmPopup
          modalPopupOpen={infoMsg !== ""}
          handleCancel={() => {
            setInfomsg("");
          }}
          handleNo={() => {
            setInfomsg("");
            if (isChangesUnSaved) {
              const { current } = initVals;
              setIsChangsUnSaved(false);
              if (Array.isArray(current)) {
                setcustomerServiceWindows([]);
                setCustomer();
                setScheduleToSave([]);
                setSchedulesToDelete([]);
                setSchedulesToUpdate([]);
                setIsCancelDisabled(true);
                setIsSaveButtonEnabled(true);
              }
            } else {
              setcustomerServiceWindows([]);
              setScheduleToSave([]);
              setSchedulesToDelete([]);
              setSchedulesToUpdate([]);
              setCustomer();
              setIsCancelDisabled(true);
            }
          }}
          handleYes={() => {
            setInfomsg("");
            if (isChangesUnSaved) {
              savechanges();
            }
          }}
          showCancel={true}
          showNo={true}
          message={infoMsg}
        />
      )}
      {infoMsg2 && (
        <ConfirmPopup
          modalPopupOpen={infoMsg2 !== ""}
          handleCancel={() => {
            setInfomsg2("");
          }}
          handleNo={() => {
            setInfomsg2("");
          }}
          handleYes={async () => {
            setInfomsg2("");
            let errorMsg = await getMessageFromCode(errorMsgs.errorCode20485);
            if (Array.isArray(errorMsg)) {
              errorMsg = errorMsg.find(
                (item) => item.id === errorMsgs.errorCode20485
              )?.descr;
              setErrorMsg(errorMsg);
              setIsErr(true);
            }
            return;
          }}
          showCancel={true}
          showNo={true}
          message={infoMsg2}
        />
      )}
      <DownloadPopup
        title={t("common.downloadReport")}
        showPrintModal={isPrintVisible}
        onCancleClick={() => {
          setReportCFUrl(VALUE_EMPTY_STRING);
          setIsPrintVisible((prev) => !prev);
        }}
        onDownloadClick={() => {
          handleReportDownload();
        }}
        setSelectedValue={setSelectedPrintType}
        setThrowError={setThrowReportError}
        throwError={throwReportError}
        throwErrorMessage={throwReportErrorMessage}
        cfUrl={reportCUrl}
      />
      <Box>
        <Grid
          container
          spacing={2}
          alignItems="flex-start "
          paddingBlock={spacing.verticalMargin25}
        >
          <Grid item md={6} lg={3} xs={12}>
            <CustomersField
              isOptionEqualToValue={(option, value) => {
                return option?.value === value;
              }}
              noOptionsText={t("serviceWindows.customerNotFound")}
              form={{ touched: {}, errors: {} }}
              options={
                withSchedulesRequiringServiceWindows
                  ? selectedCustomers
                  : customers.filter((item) => !!item)
              }
              label={t("common.customer")}
              value={customer?.[0]?.label || null}
              handleOnChange={(event, newValue) => {
                onCustomerSelect(newValue);
              }}
              required
              disabled={isLoading}
            />
          </Grid>

          <Grid item md={6} lg={6} sx={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={withSchedulesRequiringServiceWindows}
                    onChange={(e) => {
                      setWithSchedulesRequiringServiceWindows(e.target.checked);
                      onCustomerSelect(null);
                    }}
                    disabled={!selectedCustomers?.length}
                  />
                }
                label={t("dashboard.withSchedulesRequiringServiceWindows")}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
      <Paper sx={ServiceWindowsTableStyles.PaperWidth}>
        <TableContainer sx={ServiceWindowsTableStyles.TableContainerHeight}>
          <ServiceWindowsTable
            customerServiceWindows={customerServiceWindows}
            customer={customer}
            scheduleToSave={scheduleToSave}
            setScheduleToSave={setServiceWindows}
            daysOfWeek={daysOfWeek}
            setdeleteSchedules={setServiceWindowsToDelete}
            getDayOfWeek={getDayOfWeek}
            isPrintButtonDisabled={isPrintButtonDisabled}
            isResetCSLDisabled={isResetCSLDisabled}
            resetCSLButtonClick={onResetCSLButtonclick}
            customers={customers}
            handleClickPrint={handleClickPrint}
          />
        </TableContainer>
      </Paper>
      <Divider />
      <Stack
        direction="row"
        gap={spacing.verticalMargin20}
        paddingBlock={spacing.verticalMargin20}
      >
        <Button
          id="newBtnfilter"
          variant="outlined"
          disabled={isCancelDisabled}
          onClick={handleCancel}
        >
          {t("serviceWindows.cancelButtonLabel")}
        </Button>
        <Button
          id="saveBtnfilter"
          variant="contained"
          type="submit"
          disabled={isSaveButtonEnabled}
          onClick={() => {
            onSave();
          }}
        >
          {t("serviceWindows.saveButtonLabel")}
        </Button>
      </Stack>
    </>
  );
};

export default CustomerServiceWindows;
