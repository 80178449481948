import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularLoaderFullPage } from "components/core";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { CF_URLS } from "services/api/endpoints";
import {
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  downloadOptionPdf
} from "utils/constants";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getAuthenticatedUserName,
  printReport
} from "utils/helpers";

const PrintReportModal = (props) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf); // selected download type
  const [pageLoading, setPageLoading] = useState(false); // page loading state
  const [throwError, setThrowError] = useState(false); // error message show/hide state
  const [throwErrorMessage, setThrowErrorMessage] = useState(""); // error message state

  // download click event handler
  const handleDownloadOnClick = async () => {
    try {
      setPageLoading((prevState) => true);
      setThrowError((prevState) => false);

      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        container_id: String(props.containerId),
        customer_number: String(props.customerNumber),
        container_number: String(props.containerNumber).trim(),
        media_type: String(props.mediaType),
        customer_media_identifier: String(props.cmi),
        up2date: VALUE_EMPTY_STRING,
        range: String(props.range),
        display_container_slot: Y_CHECKER,
        user_name: getAuthenticatedUserName(),
        report_format: selectedValue?.type?.toUpperCase()
      });

      const reportResponse = await printReport(
        reqBody,
        CF_URLS.printReports.containerProcessing.research.containerTransport
      );

      if (!reportResponse.success) {
        setThrowErrorMessage((prevState) => reportResponse.error);
        setThrowError((prevState) => true);
      } else {
        // report print success
      }
    } catch (e) {
      setThrowErrorMessage(
        (prevState) => e.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError((prevState) => true);
    } finally {
      setPageLoading((prevState) => false);
    }
  };

  return (
    <>
      {/* Full page loader */}
      <CircularLoaderFullPage
        id="research-transaction-detail-full-page-loader"
        loading={pageLoading}
      />

      <DownloadPopup
        title={t("common.downloadReport")}
        showPrintModal={props.open}
        cfUrl={props.reportCFUrl}
        onCancleClick={props.onClose}
        onDownloadClick={handleDownloadOnClick}
        setSelectedValue={setSelectedValue}
        setThrowError={setThrowError}
        throwError={throwError}
        throwErrorMessage={throwErrorMessage}
      />
    </>
  );
};

PrintReportModal.propTypes = {
  open: PropTypes.bool.isRequired, // show/hide modal state
  containerId: PropTypes.string.isRequired, // id of the container
  customerNumber: PropTypes.string.isRequired, // number of the customer
  containerNumber: PropTypes.string.isRequired, // number of the container
  mediaType: PropTypes.string.isRequired, // media type
  cmi: PropTypes.string.isRequired, // Customer Media Identifier of the container
  range: PropTypes.string.isRequired, // page range
  onClose: PropTypes.func // handle modal close event
};

export default PrintReportModal;
