import CheckIcon from "@mui/icons-material/Check";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ArrowDownDisabled } from "assets/images/ArrowDownDisabled.svg";
import WarningIcon from "assets/images/warning-2.svg";
import {
  CircularLoader,
  CircularLoaderFullPage,
  DatePickerField,
  SelectField
} from "components/core";
import { HorizontalTabs } from "components/features/request-module";
import DeleteContainersTab from "components/features/request-module/media-request-detail/delete-containers-tab/DeleteContainersTab";
import IssueContainersTab from "components/features/request-module/media-request-detail/issue-containers-tab/IssueContainersTab";
import OtherTab from "components/features/request-module/media-request-detail/other-tab/OtherTab";

import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "assets/images/warning-2.svg";
import { NonEditableField } from "components/shared";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import { useCallbackPrompt } from "hooks";
import i18n from "i18n";
import { useTranslation } from "react-i18next";
import { MEDIA_REQUEST_DETAIL, REQUEST_MODULE_BASE_PATH } from "routing/Paths";
import {
  useFindMediaRequestMutation,
  useGetAddonCutoffValuesMutation,
  useGetAllCustomersWithDetailsQuery,
  useGetAllRequestTypesQuery,
  useGetAuthorizationPersonnelsAuthenticationsMutation,
  useGetAuthorizationPersonnelsMutation,
  useGetBranchBillingSystemQuery,
  useGetEligibleRequestLocationsMutation,
  useGetMessageByIdMutation,
  useGetRequestStatusesMutation,
  useGetScheduledServiceDatesMutation,
  useGetServiceAuthCommentsMutation,
  useGetServiceLocationsMutation,
  useGetTripTypesMutation,
  useGetValidateChallengeResponseMutation,
  useSaveMediaRequestMutation,
  useUpdateMediaRequestMutation
} from "services/api";
import {
  getBypassReason,
  getMessageByIdFromMessage,
  loadMediaRequestTransports
} from "services/common";
import {
  cancelConfirmation,
  cancelExitProcedure,
  cancelSaveProcedure,
  removeErrorMessage,
  removeInfoMessage,
  resetMediaRequestDetail,
  resetRouteState,
  selectAuth,
  selectMediaRequest,
  selectRoute,
  setClearRequest,
  setErrorMessage,
  setHaveChanges,
  setInfoMessage,
  setLockedTab,
  setOtherComment,
  setRefetch,
  setShowConfirmationModal,
  setTransports
} from "store/slices";
import spacing from "styles/spacing";
import {
  APP_REGIONS,
  ARC_VMS,
  BOOLEAN_STRING_VALUES,
  CANCEL,
  DEFAULT_LOCALE,
  Dates,
  MRD_BYPASS_IM_PERSONNEL_ID,
  NO,
  N_CHECKER,
  ON_CALL,
  REQUEST_ACTIONS,
  REQUEST_NEW_ITEM_TYPE,
  REQUEST_STATUS,
  REQUEST_TYPES,
  VALUE_EMPTY_STRING,
  YES,
  YES_AUTHORIZE,
  Y_CHECKER,
  dayMonthYearFormat,
  errorMsgs,
  ymdDateFormat
} from "utils/constants";
import {
  MESSAGE_20222,
  MESSAGE_20349,
  MESSAGE_20452,
  MESSAGE_20528,
  MESSAGE_20529,
  MESSAGE_63944
} from "utils/constants/request-module/MediaRequestDetailConstants";
import {
  formatDateByLocale,
  generateSHA256Hash,
  getAuthenticatedUserName,
  getDateFromUTCFormat,
  getDayOfWeekCode,
  getLanguageId,
  limitFormikTextFieldLength
} from "utils/helpers";
import CancelRequestModal from "../cancel-media-request/CancelRequestModal";
import CancelRequestRequiresAuthModal from "../cancel-media-request/CancelRequestRequiresAuthModal";
import CancelRequestWithoutAuthorizationModal from "../cancel-media-request/CancelRequestWithoutAuthorizationModal";
import CancellingMediaDestructionModal from "../cancel-media-request/CancellingMediaDestructionModal";
import DeliverMediaTab from "../deliver-media-tab/DeliverMediaTab";
import { checkAuthorization, isAuthRequired, isBypassEnabled } from "../utils";
import FindRequestModal from "./FindRequestModal";
import { HighlightScheduledDates } from "./HighlightScheduledDates";
import { MediaRequestDetailFormStyles } from "./MediaRequestDetailForm.Styles";
import DownloadReportModal from "./PrintReportModal";
import AssignTransportModal from "./assign-transport-modal/AssignTransportModal";
import { extraItemsValidationSchema } from "./schema";

const extraItemsFormInitialValues = {
  lock_quantity: 0,
  lock_type_code: VALUE_EMPTY_STRING,
  clip_quantity: 0
};

const maxDate = dayjs(Dates.MaxDate); // maximum date for service date

const MediaRequestDetailForm = () => {
  const { t } = useTranslation();
  const routeParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { authInfo, currentBranch, timeZoneOffset, localeByBranch } =
    useSelector(selectAuth); // authenticated users branch in redux
  const {
    refetch,
    error,
    info,
    clearRequest,
    actionId,
    deliverMedia,
    issueContainers,
    deletableContainers,
    otherComment,
    transports
  } = useSelector(selectMediaRequest); // select media request slice
  const { haveChanges, showConfirmationModal } = useSelector(selectRoute); // select route slice
  const dispatch = useDispatch(); // redux actions dispatcher
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(haveChanges); // browser back and forward blocker hook
  const [timeZoneOffsetChanged, setTimeZoneOffsetChanged] = useState(false); // timezone offset value changed
  const currentDate = useMemo(() => {
    let date = dayjs.utc(new Date());
    if (timeZoneOffset !== null) {
      date = date.utcOffset(parseFloat(timeZoneOffset));
    }
    setTimeZoneOffsetChanged((prevState) => !prevState);
    return date;
  }, [timeZoneOffset]); // minimum date
  const [exitAfterSave, setExitAfterSave] = useState(false); // after saving request exit the page or not state
  const [pageLoading, setPageLoading] = useState(false); // page loading state
  const [resetCustomerField, setResetCustomerField] = useState(false); // customer field key
  const [resetAuthoirzedByField, setResetAuthoirzedByField] = useState(false); // authorized by field key
  const [resetConfirmedByField, setResetConfirmedByField] = useState(false); // confirmed by field key
  const [branchBillingSystem, setBranchBillingSystem] = useState({});
  const [cancelRequestRequiresAuthModal, setCancelRequestRequiresAuthModal] =
    useState(false); // cancel request requires authroization
  const [scheduledServiceDates, setScheduledServiceDates] = useState([]); // persist the scheduled services of the customer
  const [serviceAuthComments, setServiceAuthComments] = useState({}); // persist service auth comments of the customer
  const [addOnCutOffValue, setAddOnCutOffValue] = useState({}); // persist the add on cutoff values
  const [serviceLocations, setServiceLocations] = useState([]); // persist the service locations
  const [isCalled, setIsCalled] = useState(false); // scheduled dates, service auth comments and addoncutoff values fetched
  const [needsValidation, setNeedsValidation] = useState(false); // force form validation of the selected child of issue containers tab
  const [enableConfirmedBy, setEnableConfirmedBy] = useState(false); // enable confirmed by field state
  const [confirmedByRequired, setConfirmedByRequired] = useState(false); // requires confirmed by field state
  const [preConfirmed, setPreConfirmed] = useState(false); // if the request is preconfirmed or not state
  const [cancelModalWarning, setCancelModalWarning] = useState(false); // cancel request modal warning message show/hide state
  const [findRequestModalVisible, setFindRequestModalVisible] = useState(false); //find request modal show/hide state
  const [assignTransportModalVisible, setAssignTransportModalVisible] =
    useState(false); // assign transport modal show/hide state
  const [cancelRequestCheckModal, setCancelRequestCheckModal] = useState(false); // cancel request check modal

  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);

  const [{ isError: isMessageByIdError, error: messageByIdError }] =
    useGetMessageByIdMutation(); // get info/warning/error message api
  // get branch billing system request api
  const { data: branchBillingData } = useGetBranchBillingSystemQuery();

  const [
    saveMediaRequest,
    { isError: isSaveMediaRequestError, error: saveMediaRequestError }
  ] = useSaveMediaRequestMutation(); // save media request api
  const [
    updateMediaRequest,
    { isError: isUpdateMediaRequestError, error: updateMediaRequestError }
  ] = useUpdateMediaRequestMutation(); // update media request api
  const [
    getRequestStatuses,
    {
      data: requestStatuses = [],
      isError: isRequestStatusesError,
      error: requestStatusesError
    }
  ] = useGetRequestStatusesMutation(); // fetch request statuses api
  const [
    triggerValidateChallengeResponse,
    { isLoading: validatingChallengeResponse }
  ] = useGetValidateChallengeResponseMutation(); //checking the challenge response

  // service date month start day value
  const handleFromDate = (value) => {
    return dayjs(value).startOf("month").format(ymdDateFormat);
  };
  // service date month end day value
  const handleToDate = (value) => {
    return dayjs(value).endOf("month").format(ymdDateFormat);
  };

  // media request detail save
  const handleOnSave = async (values, formikHelpers) => {
    await extraItemsForm.submitForm();
    formikHelpers.setSubmitting(true);
    mrdForm.setTouched(true);
    if (mrdForm.isValid && extraItemsForm.isValid) {
      dispatch(removeInfoMessage());
      dispatch(removeErrorMessage());
      setThrowError(false);
      setPageLoading((prevState) => true);

      if (
        Number(values.requestType) === REQUEST_TYPES.ADD_ON &&
        ((actionId === REQUEST_ACTIONS.MEDIA_PICKUP_DELIVERY.ID &&
          deliverMedia.openMedias.length === 0 &&
          deliverMedia.containers.length === 0) ||
          (actionId === REQUEST_ACTIONS.ISSUE_CONTAINER_LOCK_INSERT.ID &&
            issueContainers.containers.length === 0 &&
            issueContainers.extraInserts.length === 0) ||
          (actionId === REQUEST_ACTIONS.ISSUE_TRANSPORT_LOCK.ID &&
            issueContainers.containers.length === 0) ||
          (actionId === REQUEST_ACTIONS.DELETE_CONTAINERS.ID &&
            deletableContainers.length === 0))
      ) {
        // Add-On request doesn't have media
        const message20452 = await getMessageByIdFromMessage({
          message_id: MESSAGE_20452
        });
        setPageLoading((prevState) => false);
        dispatch(
          setErrorMessage(
            message20452[0]?.descr.replace("|", t("mediaRequestDetail.addon"))
          )
        );
      } else if (
        Number(values.requestType) === REQUEST_TYPES.CRITICAL_SPECIAL &&
        ((actionId === REQUEST_ACTIONS.ISSUE_CONTAINER_LOCK_INSERT.ID &&
          issueContainers.containers.length === 0 &&
          issueContainers.extraInserts.length === 0) ||
          (actionId === REQUEST_ACTIONS.ISSUE_TRANSPORT_LOCK.ID &&
            issueContainers.containers.length === 0) ||
          (actionId === REQUEST_ACTIONS.DELETE_CONTAINERS.ID &&
            deletableContainers.length === 0))
      ) {
        // Critical Special request doesn't have media
        const message20452 = await getMessageByIdFromMessage({
          message_id: MESSAGE_20452
        });
        setPageLoading((prevState) => false);
        dispatch(
          setErrorMessage(
            message20452[0]?.descr.replace(
              "|",
              t("mediaRequestDetail.criticalSpecial")
            )
          )
        );
      } else if (
        Number(values.requestType) === REQUEST_TYPES.RE_RUN &&
        ((actionId === REQUEST_ACTIONS.MEDIA_PICKUP_DELIVERY.ID &&
          deliverMedia.containers.length === 0 &&
          deliverMedia.openMedias.length === 0) ||
          (actionId === REQUEST_ACTIONS.ISSUE_CONTAINER_LOCK_INSERT.ID &&
            issueContainers.containers.length === 0 &&
            issueContainers.extraInserts.length === 0) ||
          (actionId === REQUEST_ACTIONS.ISSUE_TRANSPORT_LOCK.ID &&
            issueContainers.containers.length === 0) ||
          (actionId === REQUEST_ACTIONS.DELETE_CONTAINERS.ID &&
            deletableContainers.length === 0))
      ) {
        // Re-Run request doesn't have media
        const message20452 = await getMessageByIdFromMessage({
          message_id: MESSAGE_20452
        });
        setPageLoading((prevState) => false);
        dispatch(
          setErrorMessage(
            message20452[0]?.descr.replace("|", t("mediaRequestDetail.rerun"))
          )
        );
      } else if (
        Number(values.requestType) === REQUEST_TYPES.SCHEDULED_SERVICE &&
        ((actionId === REQUEST_ACTIONS.ISSUE_CONTAINER_LOCK_INSERT.ID &&
          issueContainers.containers.length === 0 &&
          issueContainers.extraInserts.length === 0) ||
          (actionId === REQUEST_ACTIONS.ISSUE_TRANSPORT_LOCK.ID &&
            issueContainers.containers.length === 0) ||
          (actionId === REQUEST_ACTIONS.DELETE_CONTAINERS.ID &&
            deletableContainers.length === 0))
      ) {
        // Scheduled Service request doesn't have media
        const message20452 = await getMessageByIdFromMessage({
          message_id: MESSAGE_20452
        });
        setPageLoading((prevState) => false);
        dispatch(
          setErrorMessage(
            message20452[0]?.descr.replace(
              "|",
              t("mediaRequestDetail.scheduledService")
            )
          )
        );
      } else if (
        Number(values.requestType) === REQUEST_TYPES.STANDARD_SPECIAL &&
        ((actionId === REQUEST_ACTIONS.ISSUE_CONTAINER_LOCK_INSERT.ID &&
          issueContainers.containers.length === 0 &&
          issueContainers.extraInserts.length === 0) ||
          (actionId === REQUEST_ACTIONS.ISSUE_TRANSPORT_LOCK.ID &&
            issueContainers.containers.length === 0) ||
          (actionId === REQUEST_ACTIONS.DELETE_CONTAINERS.ID &&
            deletableContainers.length === 0))
      ) {
        // Standard Special request doesn't have media
        const message20452 = await getMessageByIdFromMessage({
          message_id: MESSAGE_20452
        });
        setPageLoading((prevState) => false);
        dispatch(
          setErrorMessage(
            message20452[0]?.descr.replace(
              "|",
              t("mediaRequestDetail.standardSpecial")
            )
          )
        );
      } else if (
        !pageIsViewOnly &&
        mrdForm.values.isRedelivery &&
        deliverMedia.containers.length === 0
      ) {
        const message63944 = await getMessageByIdFromMessage({
          message_id: MESSAGE_63944
        });
        setPageLoading((prevState) => false);
        dispatch(setErrorMessage(message63944[0]?.descr));
      } else if (
        enableConfirmedBy &&
        Number(values.confirmedBy) === 0 &&
        Number(mrdForm.values.requestStatus) !==
          REQUEST_STATUS.NEEDS_AUTHORIZATION
      ) {
        // save request if the confirmed by field is enabled and empty
        // also the form is valid
        setPageLoading((prevState) => false);
        getMessageByIdFromMessage({
          message_id: MESSAGE_20349
        }).then((resp) => {
          dispatch(setInfoMessage({ message: resp[0]?.descr }));
          setPageLoading((prevState) => true);
          saveRequest({
            ...values,
            requestStatus: REQUEST_STATUS.NEEDS_AUTHORIZATION
          });
        });
      } else if (
        !values.isRegularRateSpecial &&
        Number(mrdForm.values.requestStatus) ===
          REQUEST_STATUS.NEEDS_AUTHORIZATION &&
        Number(values.confirmedBy) > 0
      ) {
        // if the request status is needs authorization and the request is confirmed
        // then change the request status to needs scheduling and update
        saveRequest({
          ...values,
          requestStatus: REQUEST_STATUS.NEEDS_SCHEDULING
        });
      } else {
        // clear authorized personnel if a personnel confirmed the request
        saveRequest(values);
      }
    }
    formikHelpers.setSubmitting(false);
  };

  // check whether the personeel authorized to create/update request type and then save/update a media request
  const saveRequest = (values) => {
    // check if page is in create mode then save
    // if it's in view mode update the existing request
    if (!pageIsViewOnly) {
      const requestNewItems = [];
      // add extra inserts as a container row
      if (issueContainers.extraInserts.length > 0) {
        let insert_type_list = "";
        // loop through extra inserts and add it to a string
        issueContainers.extraInserts.forEach(
          (item) =>
            (insert_type_list += `${item.insert_type_id},${item.quantity};`)
        );
        requestNewItems.push({
          record_id: requestNewItems.length + 1,
          request_new_item_type_id: String(REQUEST_NEW_ITEM_TYPE.INSERT),
          logical_vault_id: "",
          media_type_id: "",
          quantity: "",
          lock_type_code: "",
          customer_owned_container_flag: N_CHECKER,
          bill_for_inserts_flag: issueContainers.billForInsertsFlag,
          insert_type_id_list: insert_type_list.slice(0, -1),
          customer_media_descr_list: "",
          is_managed_container_flag: N_CHECKER
        });
      }

      // add extra locks and quantity as a container row
      if (
        extraItemsForm.values?.lock_type_code !== "" &&
        extraItemsForm.values?.lock_quantity > 0
      ) {
        requestNewItems.push({
          record_id: requestNewItems.length + 1,
          request_new_item_type_id: String(REQUEST_NEW_ITEM_TYPE.LOCKS),
          logical_vault_id: "",
          media_type_id: "",
          quantity: extraItemsForm.values.lock_quantity,
          lock_type_code: extraItemsForm.values.lock_type_code,
          customer_owned_container_flag: N_CHECKER,
          bill_for_inserts_flag: Y_CHECKER,
          insert_type_id_list: "",
          customer_media_descr_list: "",
          is_managed_container_flag: N_CHECKER
        });
      }

      // add clip quantity as a container row
      if (extraItemsForm.values?.clip_quantity > 0) {
        requestNewItems.push({
          record_id: requestNewItems.length + 1,
          request_new_item_type_id: String(REQUEST_NEW_ITEM_TYPE.CLIPS),
          logical_vault_id: "",
          media_type_id: "",
          quantity: extraItemsForm.values.clip_quantity,
          lock_type_code: "",
          customer_owned_container_flag: N_CHECKER,
          bill_for_inserts_flag: Y_CHECKER,
          insert_type_id_list: "",
          customer_media_descr_list: "",
          is_managed_container_flag: N_CHECKER
        });
      }

      // add containers
      issueContainers.containers.forEach((item) => {
        let insert_type_list = "";
        // loop through insert configuration list and add it to a string
        item?.insertConfigs.forEach(
          (insert) =>
            (insert_type_list += `${insert.insert_type_id},${insert.quantity};`)
        );

        let customer_media_identifier_list = "";
        // loop through media identifiers list and add it to a string trailing with a pipe
        item?.mediaIdentifiers.forEach(
          (cmi) => (customer_media_identifier_list += `${cmi.mediaIdentifier}|`)
        );

        requestNewItems.push({
          record_id: requestNewItems.length + 1,
          request_new_item_type_id: String(issueContainers.requestNewItemType),
          logical_vault_id: String(item.logical_vault_code),
          media_type_id: String(item.media_type_id),
          quantity: item.quantity,
          lock_type_code: item.have_locks
            ? item.lock_type_code
            : VALUE_EMPTY_STRING,
          customer_owned_container_flag: item.customer_owned_container_flag,
          bill_for_inserts_flag: Y_CHECKER,
          insert_type_id_list: insert_type_list?.slice(0, -1),
          customer_media_descr_list: customer_media_identifier_list.slice(
            0,
            -1
          ),
          is_managed_container_flag: item.is_managed_container_flag
        });
      });

      saveMediaRequest({
        requestActionId: actionId,
        requestTypeId: values?.requestType,
        openMediaList: deliverMedia.openMedias
          .map((media) => media.volser.padEnd(20))
          .toString()
          .replaceAll(",", ""),
        reRunComment: values?.rerunReason,
        regularRateSpecialFlag: values?.isRegularRateSpecial,
        redeliveryFlag: values?.isRedelivery,
        tripTypeId: values?.tripType,
        authBypassReason: values?.bypassReason,
        dayOfWeekCode: getDayOfWeekCode(values?.serviceDate),
        requestStatus: values?.requestStatus,
        serviceLocation: values?.serviceLocation,
        serviceDate: values?.serviceDate,
        ironMountainComment: values?.ironMountainComments,
        customerComment: values?.customerComments,
        customerId: values?.customer,
        personnelId: authorizationStatus?.isByPassed
          ? MRD_BYPASS_IM_PERSONNEL_ID
          : Number(values?.authorizedBy) > 0
            ? values.authorizedBy
            : "",
        confirmPersonnelId: Number(values?.confirmedBy > 0)
          ? values?.confirmedBy
          : "",
        comment: otherComment,
        noChargeSpecialFlag: values?.isNoChargeSpecial,
        noChargeSpecialComment: values?.noChargeSpecialComment,
        parentRequestId: values?.parentRequestId || 0,
        requestMediaIu: deliverMedia.containers.map((container, index) => ({
          record_id: index + 1,
          container_id: container.container_id,
          container_number: container.container_number,
          slot_location: "",
          container_ts: "",
          manual_media_flag: N_CHECKER
        })),
        requestNewItem: requestNewItems,
        container: deletableContainers.map(({ container_id, timestamp }) => ({
          container_id,
          container_ts: timestamp
        }))
      }).then(async ({ data }) => {
        await dispatch(setHaveChanges(false));

        // exit page after saving the request if needed
        if (exitAfterSave) {
          confirmNavigation();
          setExitAfterSave((prevState) => false);
          setPageLoading((prevState) => false);
          return;
        }

        // clear page and navigate to the view request page with the request id
        if (data?.request_id && !exitAfterSave) {
          // if there is no next location to be routed
          mrdForm.setValues(mediaRequestInitialValues);
          resetMediaRequest();
          setPageLoading((prevState) => false);
          dispatch(resetMediaRequestDetail());
          navigate(`/requests/media/${data?.request_id}`);
          setPageLoading((prevState) => false);
          return;
        }
      });
    } else {
      updateMediaRequest({
        requestId: requestId,
        requestStatus: values?.requestStatus,
        confirmedBy: Number(values?.confirmedBy) > 0 ? values?.confirmedBy : "",
        noChargeSpecialComment: values?.noChargeSpecialComment,
        noChargeSpecialFlag: values?.isNoChargeSpecial,
        ironMountainComment: values?.ironMountainComments,
        customerComment: values?.customerComments,
        timestamp: values?.timestamp,
        reRunComment: values?.rerunReason,
        regularRateSpecialFlag: values?.isRegularRateSpecial,
        tripType: values?.tripType
      }).then(async () => {
        await dispatch(setHaveChanges(false));

        // exit page after saving the request if needed
        if (exitAfterSave) {
          confirmNavigation();
          setExitAfterSave((prevState) => false);
          setPageLoading((prevState) => false);
          return;
        }

        // refresh the data if there is no next location to be routed
        if (!exitAfterSave) {
          dispatch(removeInfoMessage());
          dispatch(removeErrorMessage());
          setThrowError(false);
          dispatch(setRefetch(true));
        }
      });
    }
  };

  // form on reset event handler
  const handleFormOnReset = (values, formikHelpers) => {
    formikHelpers.setFieldValue("serviceDate", currentDate);
  };

  /* Media Request Detail init values */
  const mediaRequestInitialValues = {
    customer: 0,
    serviceDate: currentDate,
    requestType: 0,
    requestStatus: 0,
    rerunReason: "",
    isRegularRateSpecial: false,
    isNoChargeSpecial: false,
    isRedelivery: false,
    noChargeSpecialComment: "",
    authorizedBy: 0,
    authorizedByName: "",
    authorizationType: i18n.t("mediaRequestDetail.personnel"),
    authNumber: "",
    authorizationChallengeQuestion: "",
    authorizationChallengeResponse: "",
    bypassReason: "",
    bypassReasonComment: "",
    bypassedBy: "",
    confirmedBy: 0,
    confirmedByName: "",
    confirmAuthNumber: "",
    confirmAuthorizationChallengeQuestion: "",
    confirmAuthorizationChallengeResponse: "",
    serviceLocation: "",
    tripType: "",
    loggedBy: "",
    loggedDate: "",
    canceledBy: "",
    canceledDate: "",
    customerServiceNotes: "",
    customerAuthorizationNotes: "",
    ironMountainComments: "",
    customerComments: "",
    otherComment: "",
    parentRequestId: "",
    timestamp: "",
    inMdrFlag: N_CHECKER
  };

  /* Media Request Detail filter schema */
  const mediaRequestDetailValidationSchema = Yup.object().shape({
    customer: Yup.string().required(
      i18n.t("mediaRequestDetail.customerRequired")
    ),
    serviceDate: Yup.date().test(
      "serviceDateRequired",
      i18n.t("mediaRequestDetail.serviceDateRequired"),
      async (value) => {
        const selectedDate = dayjs
          .utc(value)
          .utcOffset(parseFloat(timeZoneOffset));
        // Make the test function async
        if (!value) {
          return false;
        }
        if (selectedDate.startOf("day") < currentDate.startOf("day")) {
          const message62546 = await getMessageByIdFromMessage({
            message_id: errorMsgs.errorCode62546
          });

          let errorString = message62546[0]?.descr
            ?.replace("|", i18n.t("mediaRequestDetail.serviceDate"))
            ?.replace("|", i18n.t("mediaRequestDetail.currentDate"));

          // No direct form field update or dispatch here. Return an error.
          return new Yup.ValidationError(
            errorString, // Use the fetched error message
            value,
            "serviceDate"
          );
        }
        return true;
      }
    ),
    requestType: Yup.string().test(
      "requestTypeRequired",
      i18n.t("mediaRequestDetail.requestTypeRequired"),
      (value) =>
        value !== undefined && value !== null && value !== "" && value > 0
    ),
    rerunReason: Yup.string().test(
      "rerunReasonRequired",
      i18n.t("mediaRequestDetail.rerunReasonRequired"),
      (value, context) => {
        if (context.parent?.requestType === REQUEST_TYPES.RE_RUN.toString()) {
          return value !== undefined && value !== null && value !== "";
        }
        return true;
      }
    ),
    isRegularRateSpecial: Yup.bool(),
    isNoChargeSpecial: Yup.bool(),
    isRedelivery: Yup.bool(),
    noChargeSpecialComment: Yup.string().test(
      "noChargeSpecialCommentRequired",
      i18n.t("mediaRequestDetail.noChargeSpecialCommentRequired"),
      (value, context) => {
        if (context.parent?.isNoChargeSpecial) {
          return value !== undefined && value !== null && value !== "";
        }
        return true;
      }
    ),
    authorizedBy: Yup.string().test(
      "authRequired",
      i18n.t("mediaRequestDetail.authorizedByRequired"),
      (value, context) => {
        if (
          Number(location?.state?.mediaRequest?.request_type_id) ===
          REQUEST_TYPES.SCHEDULED_SERVICE
        ) {
          return {};
        }
        if (
          Number(context.parent.requestType) === REQUEST_TYPES.RE_RUN ||
          context.parent.isRegularRateSpecial ||
          ((Number(context.parent.requestType) ===
            REQUEST_TYPES.STANDARD_SPECIAL ||
            Number(context.parent.requestType) ===
              REQUEST_TYPES.CRITICAL_SPECIAL) &&
            !context.parent.isRegularRateSpecial &&
            Number(context.parent.requestStatus) >= REQUEST_STATUS.SCHEDULED)
        ) {
          return true;
        }
        return (
          value !== undefined && value !== null && value !== "" && value > 0
        );
      }
    ),
    authorizationType: Yup.string().oneOf([
      i18n.t("mediaRequestDetail.personnel"),
      i18n.t("mediaRequestDetail.bypass")
    ]),
    authNumber: Yup.string(),
    authorizationChallengeQuestion: Yup.string(),
    authorizationChallengeResponse: Yup.string(),
    bypassReason: Yup.string(),
    bypassReasonComment: Yup.string(),
    bypassedBy: Yup.string(),
    confirmedBy: Yup.string(),
    serviceLocation: Yup.string().test(
      "serviceLocation",
      i18n.t("mediaRequestDetail.serviceLocationRequired"),
      () => {
        if (
          Number(location?.state?.mediaRequest?.request_type_id) ===
          REQUEST_TYPES.SCHEDULED_SERVICE
        ) {
          return {};
        } else {
          return true;
        }
      }
    ),
    tripType: Yup.string().test(
      "tripTypeRequired",
      i18n.t("mediaRequestDetail.tripTypeRequired"),
      (value, context) => {
        if (
          process.env.REACT_APP_CF_REGION !== APP_REGIONS.IMNA &&
          branchBillingSystem?.billing_system?.toLowerCase().trim() ===
            ARC_VMS.toLowerCase().trim() &&
          context.parent?.requestType ===
            REQUEST_TYPES.CRITICAL_SPECIAL.toString()
        ) {
          return value !== undefined && value !== null && value !== "";
        }
        return true;
      }
    ),
    loggedBy: Yup.string(),
    loggedDate: Yup.string(),
    canceledBy: Yup.string(),
    canceledDate: Yup.string(),
    customerServiceNotes: Yup.string(),
    customerAuthorizationNotes: Yup.string(),
    ironMountainComments: Yup.string(),
    otherComment: Yup.string().test(
      "otherCommentRequired",
      i18n.t("mediaRequestDetail.otherDescriptionRequired"),
      (value) => {
        if (actionId === REQUEST_ACTIONS.OTHER.ID) {
          return (
            value !== undefined &&
            value !== null &&
            value !== "" &&
            value.length > 0
          );
        }
        return true;
      }
    )
  });

  // media request form
  const mrdForm = useFormik({
    initialValues: mediaRequestInitialValues,
    validationSchema: mediaRequestDetailValidationSchema,
    onSubmit: handleOnSave,
    onReset: handleFormOnReset
  });

  // extra items form
  const extraItemsForm = useFormik({
    initialValues: extraItemsFormInitialValues,
    validationSchema: extraItemsValidationSchema,
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      extraItemsForm.setTouched(true);
      formikHelpers.setSubmitting(false);
    }
  });

  const [printModalVisible, setPrintModalVisible] = useState(false); // print modal show/hide state
  const [cancelRequestModalVisible, setCancelRequestModalVisible] =
    useState(false); // cancel request modal show/hide state
  const [
    cancellingMediaDestructionVisible,
    setCancellingMediaDestructionVisible
  ] = useState(false);

  const pageIsViewOnly = useMemo(
    () => Boolean(routeParams?.id),
    [routeParams?.id]
  ); // page is in the view/update or create media request state
  const requestId = useMemo(() => Number(routeParams.id), [routeParams?.id]); // request id passed to the route params

  const [
    findMediaRequest,
    { isSuccess: mediaRequestFound, reset: resetMediaRequest }
  ] = useFindMediaRequestMutation(); // fetch details for a request id
  const [authorizationStatus, setAuthorizationStatus] = useState({
    authorizedBy: 0,
    isAuthenticated: false,
    isByPassed: false,
    isError: false
  });
  const [confirmationStatus, setConfirmationStatus] = useState({
    authorizedBy: 0,
    isAuthenticated: false,
    isError: false
  });
  const {
    data: customers = [],
    isLoading: isCustomersLoading,
    isError: isCustomersError,
    error: customersError,
    refetch: refetchCustomers
  } = useGetAllCustomersWithDetailsQuery(); // get all customers
  const [
    getScheduledServiceDates,
    {
      isLoading: isScheduledServiceDatesLoading,
      isError: isScheduledServiceDatesError,
      error: scheduledServiceDatesError
    }
  ] = useGetScheduledServiceDatesMutation({
    selectFromResult: ({ data, ...rest }) => ({
      data: data,
      ...rest
    })
  }); // get scheduled service dates for a selected customer
  const [
    getServiceAuthComments,
    { isError: isServiceAuthCommentsError, error: serviceAuthCommentsError }
  ] = useGetServiceAuthCommentsMutation(); // get service auth comments
  const [
    getAddonCutoffValues,
    { isError: isAddonCutoffValuesError, error: addonCutoffValuesError }
  ] = useGetAddonCutoffValuesMutation(); // get addon cutoff values
  const [
    getEligibleRequestLocations,
    {
      isLoading: isEligibleLocationsLoading,
      isError: isEligibleLocationsError,
      error: eligibleLocationsError
    }
  ] = useGetEligibleRequestLocationsMutation(); // get eligible request locations
  const {
    data: requestTypes = [],
    isLoading: isRequestTypesLoading,
    isError: isRequestTypesError,
    error: requestTypesError
  } = useGetAllRequestTypesQuery({
    dreFlag:
      pageIsViewOnly &&
      mrdForm.values?.requestType === REQUEST_TYPES.DISASTER_RECOVERY.toString()
        ? Y_CHECKER
        : N_CHECKER
  }); // get all request types
  const [
    getAuthorizationPersonnels,
    {
      data: authorizationPersonnels = [],
      isLoading: isAuthorizationPersonnelsLoading,
      isError: isAuthorizePersonnelsError,
      error: authorizePersonnelsError,
      reset: resetAuthorizationPersonnels
    }
  ] = useGetAuthorizationPersonnelsMutation(); // get authorized personnels for a selected customer
  const [
    getAuthorizationPersonnelsData,
    {
      data: authroizedPersonnelsData = {},
      isError: isAuthorizePersonnelsDataError,
      error: authorizePersonnelsDataError,
      reset: resetAuthroizedPersonnelsData
    }
  ] = useGetAuthorizationPersonnelsAuthenticationsMutation(); // get authorized personnel's details
  const [
    getServiceLocations,
    {
      isLoading: isServiceLocationsLoading,
      isError: isServiceLocationsError,
      error: serviceLocationsError
    }
  ] = useGetServiceLocationsMutation(); // get service locations
  const [
    getTripTypes,
    {
      data: tripTypes = [],
      isLoading: isTripTypesLoading,
      isError: isTripTypesError,
      error: tripTypesError,
      reset: resetTripTypes
    }
  ] = useGetTripTypesMutation(); // get trip types

  // Check state of the confirmed by field
  const checkConfirmedByFieldStatus = async (
    regularRateSpecialChecked,
    customer,
    authorizedBy,
    requestType,
    action
  ) => {
    setPageLoading((prevState) => true);

    if (!pageIsViewOnly) {
      if (Number(authorizedBy) > 0) {
        const checkAuth = await checkAuthorization(
          customer,
          authorizedBy,
          requestType,
          action,
          regularRateSpecialChecked
        );

        if (!checkAuth) {
          setEnableConfirmedBy((prevState) => true);
          setConfirmedByRequired((prevState) => false);
        } else {
          setEnableConfirmedBy((prevState) => false);
          setConfirmedByRequired((prevState) => false);
        }
      }
      setPageLoading((prevState) => false);
    }

    if (pageIsViewOnly) {
      // check authorization requires or not for this request
      const authRequired = await isAuthRequired(
        requestType,
        action,
        regularRateSpecialChecked
      );

      if (authRequired) {
        const checkAuth = await checkAuthorization(
          customer,
          authorizedBy,
          requestType,
          action,
          regularRateSpecialChecked
        );

        if (Number(authorizedBy) === 0) {
          setEnableConfirmedBy((prevState) => true);
          setConfirmedByRequired((prevState) => true);
        } else if (!checkAuth) {
          setEnableConfirmedBy((prevState) => true);
          setConfirmedByRequired((prevState) => true);
        }
      } else {
        setEnableConfirmedBy((prevState) => false);
        setConfirmedByRequired((prevState) => false);
      }
      setPageLoading((prevState) => false);
    }
  };

  // fetch details of a media request and data required for the view
  const fetchMediaRequestDetails = async () => {
    dispatch(resetMediaRequestDetail());
    setPageLoading((prevState) => true);
    getRequestStatuses();

    // clear states before setting new values to the form
    setAuthorizationStatus((prevState) => ({
      authorizedBy: 0,
      isAuthenticated: false,
      isByPassed: false,
      isError: false
    }));
    setConfirmationStatus((prevState) => ({
      authorizedBy: 0,
      isAuthenticated: false,
      isError: false
    }));

    // if request data in the route state set it to the form
    if (
      Object.keys(location.state?.mediaRequest || {}).length > 0 &&
      !refetch
    ) {
      // fetch transports available for assigning and assigned to an existing request
      const requestTransports = await loadMediaRequestTransports({
        customerId: location.state.mediaRequest.customer_id,
        requestId: requestId
      });
      dispatch(setTransports(requestTransports));

      await setRequestDataToForm(location.state.mediaRequest);
    } else {
      // else fetch from the CF
      await findMediaRequest(requestId)
        .unwrap()
        .then((resp) => {
          setPageLoading((prevState) => false);
          navigate(
            `${REQUEST_MODULE_BASE_PATH}/${MEDIA_REQUEST_DETAIL}/${requestId}`,
            { replace: true, state: { mediaRequest: resp } }
          );
          dispatch(setRefetch(false));
        });
    }
  };

  // filter scheduled services
  const filteredServiceDates = (servicesDates, addonCutoffValues) =>
    servicesDates?.filter((item) => {
      if (
        dayjs.utc(item?.service_date).format(dayMonthYearFormat) ===
        currentDate.format(dayMonthYearFormat)
      ) {
        if (
          !(
            Number(addonCutoffValues?.num_day_of_week_code) === 1 &&
            addonCutoffValues?.addoncutoffflag === BOOLEAN_STRING_VALUES.TRUE
          )
        ) {
          return true;
        }
      } else if (
        dayjs.utc(item?.service_date).format(dayMonthYearFormat) ===
        dayjs(currentDate.add(1, "day")).format(dayMonthYearFormat)
      ) {
        if (
          !(
            addonCutoffValues?.previousdayflag === BOOLEAN_STRING_VALUES.TRUE &&
            Number(addonCutoffValues?.tomorrow_service_count) === 1
          )
        ) {
          return true;
        }
      } else {
        return true;
      }

      // default
      return false;
    });

  // fetch addon cutoff values, scheduled service dates
  const fetchAddonCutoffAndScheduledDates = async () => {
    // get addon cutoff values
    const addOnCutOffResp = await getAddonCutoffValues(
      mrdForm.values.customer
    ).unwrap();
    setAddOnCutOffValue((prevState) => addOnCutOffResp);

    if (!pageIsViewOnly) {
      // scheduled service dates
      const scheduledServiceDatesResp = await getScheduledServiceDates({
        customerId: mrdForm.values?.customer,
        fromDate: handleFromDate(mrdForm.values?.serviceDate),
        toDate: handleToDate(mrdForm.values?.serviceDate),
        addonCutoffFlag:
          addOnCutOffResp?.addoncutoffflag === BOOLEAN_STRING_VALUES.TRUE ||
          addOnCutOffResp?.previousdayflag === BOOLEAN_STRING_VALUES.TRUE
            ? Y_CHECKER
            : N_CHECKER
      }).unwrap();
      setScheduledServiceDates((prevState) =>
        filteredServiceDates(scheduledServiceDatesResp, addOnCutOffResp)
      );
      // service call frequency code and auth comments
      const serviceAuthCommentsResp = await getServiceAuthComments(
        mrdForm.values.customer
      ).unwrap();
      setServiceAuthComments((prevState) => serviceAuthCommentsResp);
      setIsCalled((prevState) => true);
    }
  };

  // reset Autocomplete fields
  const resetAutocompletes = () => {
    // Toggle the value to force a re-render
    setResetCustomerField((prevState) => !prevState);
    setResetAuthoirzedByField((prevState) => !prevState);
    setResetConfirmedByField((prevState) => !prevState);
  };

  // init page
  useEffect(() => {
    const init = async () => {
      setPageLoading((prevState) => true);
      await mrdForm.setFieldValue("serviceDate", currentDate);
      setPageLoading((prevState) => false);
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when branch billing data fetched set data to state
  useEffect(() => {
    if (branchBillingData) {
      setBranchBillingSystem((prevState) => branchBillingData[0]);
    }
  }, [branchBillingData]);

  // refetch customers and locales when branch changed
  useEffect(() => {
    if (currentBranch?.district_id) {
      refetchCustomers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch?.district_id]);

  // fetch data and/or reset fields when a customer changes
  useEffect(() => {
    // reset all fields when customer changes in a new request
    if (!pageIsViewOnly) {
      mrdForm.setValues({
        ...mediaRequestInitialValues,
        customer: mrdForm.values?.customer
      });
      setAuthorizationStatus((prevState) => ({
        authorizedBy: 0,
        isAuthenticated: false,
        isByPassed: false,
        isError: false
      }));
      setConfirmationStatus((prevState) => ({
        authorizedBy: 0,
        isAuthenticated: false,
        isError: false
      }));
      setEnableConfirmedBy((prevState) => false);
      setConfirmedByRequired((prevState) => false);
    }

    // customer is not empty
    if (mrdForm.values.customer > 0) {
      setPageLoading((prevState) => true);
      Promise.allSettled([
        // init values for determine the request type
        fetchAddonCutoffAndScheduledDates(),

        // fetch authorization personnels
        getAuthorizationPersonnels({
          customerId: mrdForm.values?.customer,
          select_all_personnel_flag: Y_CHECKER
        })
      ]).then(async () => {
        if (!pageIsViewOnly) {
          // filter selected customers details and set to the fields when customer changes
          const selectedCustomerData = customers.find(
            (cus) =>
              String(cus.value).trim() === String(mrdForm.values?.customer)
          );
          await mrdForm.setFieldValue(
            "customerServiceNotes",
            selectedCustomerData?.serviceComment
          );
          await mrdForm.setFieldValue(
            "customerAuthorizationNotes",
            selectedCustomerData?.authorizationComment
          );
        } else {
          const onCallCustomerFlag =
            serviceAuthComments?.service_frequency_code
              ?.trim()
              .toLowerCase() === ON_CALL.trim().toLowerCase();
          await loadServiceLocationsToDropdown(
            mrdForm.values.requestType,
            onCallCustomerFlag,
            0,
            mrdForm.values.serviceLocation
          );
          setPageLoading((prevState) => false);
        }
      });
    } else {
      // reset fetched data if customer removed
      resetAuthorizationPersonnels();
      resetAutocompletes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mrdForm.values?.customer]);

  // fetch trip types when a customer and request type changes
  useEffect(() => {
    if (mrdForm.values?.customer > 0 && mrdForm.values?.requestType > 0) {
      if (process.env.REACT_APP_CF_REGION !== APP_REGIONS.IMNA) {
        getTripTypes({
          customerId: mrdForm.values.customer,
          requestTypeId: mrdForm.values.requestType
        });
      }
    } else resetTripTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mrdForm.values?.customer, mrdForm.values?.requestType]);

  // trigger service date initial value set when a customer selected and the other required data are fetched
  useEffect(() => {
    if (isCalled && addOnCutOffValue && serviceAuthComments) {
      setIsCalled((prevState) => false);
      // set service date to current date
      handleServiceDateOnChange(currentDate).then(() => {
        setPageLoading((prevState) => false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalled, addOnCutOffValue, scheduledServiceDates, serviceAuthComments]);

  // set parent_request_id and service_location of the existing scheduled service request for the selected service date
  useEffect(() => {
    if (Number(mrdForm.values?.requestType) === REQUEST_TYPES.ADD_ON) {
      // if the request type is add-on
      setRequestIdAndServiceLocationOfParentRequest(
        mrdForm.values?.serviceDate
      );
    } else {
      mrdForm.setFieldValue("parentRequestId", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mrdForm.values?.serviceDate, mrdForm.values?.requestType]);

  // Fetch media request details if a request id passed to the route
  useEffect(() => {
    // check request id passed in the route params or not
    if (
      (!isNaN(requestId) && requestId > 0 && !refetch) ||
      (!isNaN(requestId) && requestId > 0 && refetch)
    ) {
      mrdForm.resetForm();
      fetchMediaRequestDetails();
    } else {
      // if a request id not passed as route params then reset the page
      dispatch(resetMediaRequestDetail());
      mrdForm.setValues(mediaRequestInitialValues);
      resetMediaRequest();
      dispatch(resetMediaRequestDetail());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, location.state, refetch]);

  // extra items set to the form when request have extra locks, clips
  useEffect(() => {
    if (pageIsViewOnly) {
      extraItemsForm.setValues({
        lock_quantity: issueContainers.extraItems?.lock_quantity || 0,
        lock_type_code: issueContainers.extraItems?.lock_type_code || "",
        clip_quantity: issueContainers.extraItems.clip_quantity || 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueContainers.extraItems]);

  // set otherComment value to form and clear it when the tab changes
  const setAndClearOtherTab = async () => {
    if (isNaN(requestId) && actionId !== REQUEST_ACTIONS.OTHER.ID) {
      await mrdForm.setFieldValue("otherComment", "", false);
    }

    if (actionId === REQUEST_ACTIONS.OTHER.ID) {
      await mrdForm.setFieldValue("otherComment", otherComment);
    }
  };

  // set other comment to the form when the value changed in the redux store
  useEffect(() => {
    setAndClearOtherTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, actionId, otherComment]);

  // clear form data
  useEffect(() => {
    if (clearRequest) {
      if (pageIsViewOnly) {
        navigate(`${REQUEST_MODULE_BASE_PATH}/${MEDIA_REQUEST_DETAIL}`);
      }
      handleCreateNewMediaRequest();
      dispatch(setClearRequest(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearRequest]);

  // set error message in redux if one occurs
  useEffect(() => {
    let message = "";
    if (isScheduledServiceDatesError) {
      message = `${t("mediaRequestDetail.errorInScheduledServiceDates")} ${
        scheduledServiceDatesError.message
      }`;
    } else if (isCustomersError) {
      message = `${t("mediaRequestDetail.errorInCustomers")} ${
        customersError.message
      }`;
    } else if (isRequestTypesError) {
      message = `${t("mediaRequestDetail.errorInrequestTypes")} ${
        requestTypesError.message
      }`;
    } else if (isAuthorizePersonnelsError) {
      message = `${t("mediaRequestDetail.errorInAuthorizedBy")} ${
        authorizePersonnelsError.message
      }`;
    } else if (isAuthorizePersonnelsDataError) {
      message = `${t("mediaRequestDetail.errorInAuthorizePersonnelData")} ${
        authorizePersonnelsDataError.message
      }`;
    } else if (isServiceLocationsError) {
      message = `${t("mediaRequestDetail.errorInServiceLocations")} ${
        serviceLocationsError.message
      }`;
    } else if (isEligibleLocationsError) {
      message = `${t("mediaRequestDetail.errorInServiceLocations")} ${
        eligibleLocationsError.message
      }`;
    } else if (isTripTypesError) {
      message = `${t("mediaRequestDetail.errorInTripTypes")} ${
        tripTypesError.message
      }`;
    } else if (isSaveMediaRequestError) {
      message = `${t("mediaRequestDetail.errorInSaveMediaRequest")} ${
        saveMediaRequestError.message
      }`;
      setPageLoading((prevState) => false);
    } else if (isUpdateMediaRequestError) {
      message = `${t("mediaRequestDetail.errorInSaveMediaRequest")} ${
        updateMediaRequestError.message
      }`;
    } else if (isRequestStatusesError) {
      message = `${t("mediaRequestDetail.errorInRequestStatus")} ${
        requestStatusesError.message
      }`;
    } else if (isMessageByIdError) {
      message = `${t("mediaRequestDetail.errorInMessageId")} ${
        messageByIdError.message
      }`;
    } else if (isAddonCutoffValuesError) {
      message = `${t("mediaRequestDetail.errorInAddonCutoffValues")} ${
        addonCutoffValuesError.message
      }`;
    } else if (isServiceAuthCommentsError) {
      message = `${t("mediaRequestDetail.errorInServiceAuthComments")} ${
        serviceAuthCommentsError.message
      }`;
    }

    // if message has set error message to redux, if not remove error message from redux
    if (message) {
      dispatch(setErrorMessage(message));
    } else {
      dispatch(removeErrorMessage());
      setThrowError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isScheduledServiceDatesError,
    isCustomersError,
    isRequestTypesError,
    isAuthorizePersonnelsError,
    isAuthorizePersonnelsDataError,
    isServiceLocationsError,
    isTripTypesError,
    isCustomersError,
    isSaveMediaRequestError,
    isRequestStatusesError,
    isMessageByIdError,
    isAddonCutoffValuesError,
    isServiceAuthCommentsError,
    isUpdateMediaRequestError,
    isEligibleLocationsError
  ]);

  // fetch transports available for assigning and assigned to an existing request
  useEffect(() => {
    if (requestId > 0 || refetch) {
      loadMediaRequestTransports({
        customerId: Number(mrdForm.values.customerId),
        requestId: requestId
      }).then((resp) => dispatch(setTransports(resp)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, refetch]);

  // customer on change event handler
  const handleCustomerOnChange = (_, value) => {
    if (validateNewAction()) {
      mrdForm.setFieldValue("customer", value?.value);
      dispatch(setHaveChanges(true));
    } else {
      dispatch(setHaveChanges(true));
      dispatch(setShowConfirmationModal(true));
    }
  };

  const loadServiceLocationsToDropdown = async (
    requestType,
    onCallCustomerFlag,
    cslCustomerId,
    cslCustomerNumber
  ) => {
    let localServiceLocations = [];
    if (!pageIsViewOnly) {
      let localCSLCustomerId = cslCustomerId;
      let localCSLCustomerNumber = cslCustomerNumber;
      //Now select the proper location from the Service Location combo
      if (
        onCallCustomerFlag &&
        Number(requestType) === REQUEST_TYPES.SCHEDULED_SERVICE
      ) {
        localServiceLocations = await getEligibleRequestLocations({
          customerId: mrdForm.values?.customer,
          dayOfWeekCode: getDayOfWeekCode(mrdForm.values.serviceDate)
        }).unwrap();
        setServiceLocations((prevState) => localServiceLocations);
        // pick the first item in the list
        if (localServiceLocations.length > 0) {
          localCSLCustomerId = localServiceLocations[0].value;
        }
      } else if (
        Number(requestType) !== REQUEST_TYPES.ADD_ON &&
        Number(requestType) !== REQUEST_TYPES.SCHEDULED_SERVICE
      ) {
        localServiceLocations = await getServiceLocations({
          customerId: mrdForm.values?.customer
        }).unwrap();
        setServiceLocations((prevState) => localServiceLocations);
      } else {
        // we should have the correct csl for the add-on or future dated request
        localServiceLocations = [].concat({
          label: localCSLCustomerNumber,
          value: localCSLCustomerId
        });
        setServiceLocations((prevState) => localServiceLocations);
      }

      if (
        localCSLCustomerId > 0 ||
        Number(requestType) === REQUEST_TYPES.DISASTER_RECOVERY
      ) {
        const locationId =
          Number(requestType) === REQUEST_TYPES.DISASTER_RECOVERY
            ? mrdForm.values.customer
            : localCSLCustomerId;
        await mrdForm.setFieldValue("serviceLocation", locationId);
      } else if (
        !mrdForm.values?.serviceLocation &&
        localServiceLocations.length === 1
      ) {
        // if nothing was selected from service location combo box and there is only one item in the
        // drop down, select it.
        await mrdForm.setFieldValue(
          "serviceLocation",
          localServiceLocations[0].value
        );
      }
    }

    if (pageIsViewOnly) {
      if (
        (onCallCustomerFlag &&
          Number(requestType) === REQUEST_TYPES.SCHEDULED_SERVICE) ||
        Number(requestType) === REQUEST_TYPES.ADD_ON
      ) {
        localServiceLocations = await getEligibleRequestLocations({
          customerId: mrdForm.values?.customer,
          dayOfWeekCode: getDayOfWeekCode(mrdForm.values.serviceDate)
        }).unwrap();
        setServiceLocations((prevState) => localServiceLocations);
      } else if (
        Number(requestType) !== REQUEST_TYPES.ADD_ON &&
        Number(requestType) !== REQUEST_TYPES.SCHEDULED_SERVICE
      ) {
        localServiceLocations = await getServiceLocations({
          customerId: mrdForm.values?.customer
        }).unwrap();
        setServiceLocations((prevState) => localServiceLocations);
      }
      const cslCustomer = localServiceLocations.find(
        (item) => item?.label?.trim() === cslCustomerNumber
      );
      await mrdForm.setFieldValue("serviceLocation", cslCustomer?.value);
    }
  };

  // default request type of customer
  const setDefaultRequestTypeAndAuthComments = async (date) => {
    setPageLoading((prevState) => true);
    let newRequestType = REQUEST_TYPES.STANDARD_SPECIAL.toString();
    let parentRequestId = 0;
    let cslCustomerId = 0;
    let cslCustomerNumber = "";

    const onCallCustomerFlag =
      serviceAuthComments?.service_frequency_code?.trim().toLowerCase() ===
      ON_CALL.trim().toLowerCase();
    // service date has a scheduled service
    if (
      scheduledServiceDates.findIndex(
        (item) =>
          date.format(dayMonthYearFormat) ===
          dayjs.utc(item?.service_date).format(dayMonthYearFormat)
      ) >= 0
    ) {
      newRequestType = REQUEST_TYPES.ADD_ON.toString();

      // find if the selected date has a scheduled service
      const parentRequest = scheduledServiceDates.find(
        (item) =>
          date.format(dayMonthYearFormat) ===
          dayjs.utc(item?.service_date).format(dayMonthYearFormat)
      );
      // set existing scheduled service's id and service location to the new request
      if (parentRequest) {
        parentRequestId = parentRequest.request_id;
        cslCustomerNumber = parentRequest?.csl_customer_number;
        if (parentRequest?.csl_customer_number !== "") {
          cslCustomerId = serviceLocations.find(
            (loc) =>
              loc.label.trim() === parentRequest?.csl_customer_number.trim()
          )?.value;
        }
      }
    } else {
      // check to see if the selected date is beyond the generated schedule end date
      if (serviceAuthComments?.generated_date_end !== "") {
        if (
          !onCallCustomerFlag &&
          date
            .utc()
            .isAfter(
              dayjs(serviceAuthComments?.generated_date_end).utc(),
              "day"
            )
        ) {
          //if it is beyond the generated end date
          newRequestType = REQUEST_TYPES.SCHEDULED_SERVICE.toString();

          cslCustomerId = Number(mrdForm.values.customer);
          cslCustomerNumber = customers.find(
            (cus) => Number(cus.value) === Number(mrdForm.values.customer)
          )?.number;
        } else if (onCallCustomerFlag) {
          // if it is an on-call customer and within their generated schedule
          if (
            addOnCutOffValue?.num_day_of_week_code === 1 &&
            addOnCutOffValue?.addoncutoffflag === BOOLEAN_STRING_VALUES.TRUE &&
            date.format(dayMonthYearFormat) ===
              currentDate.format(dayMonthYearFormat)
          ) {
            // if it is beyond the generated end date
            newRequestType = REQUEST_TYPES.CRITICAL_SPECIAL.toString();
          } else if (
            addOnCutOffValue?.tomorrow_service_count === 1 &&
            date.format(dayMonthYearFormat) ===
              currentDate.add(1, "day").format(dayMonthYearFormat) &&
            addOnCutOffValue?.previousdayflag === BOOLEAN_STRING_VALUES.TRUE
          ) {
            // if it is an on-call customer and within their generated schedule
            newRequestType = REQUEST_TYPES.STANDARD_SPECIAL.toString();
          } else {
            newRequestType = REQUEST_TYPES.SCHEDULED_SERVICE.toString();
          }
        } else {
          // else it is a special (current day = emergency, otherwise standard)
          if (
            date.format(dayMonthYearFormat) ===
            currentDate.format(dayMonthYearFormat)
          ) {
            newRequestType = REQUEST_TYPES.CRITICAL_SPECIAL.toString();
          } else {
            newRequestType = REQUEST_TYPES.STANDARD_SPECIAL.toString();
          }

          parentRequestId = 0;
          cslCustomerId = 0;
          cslCustomerNumber = "";
        }
      } else {
        // else it is a special (current day = emergency, otherwise standard)
        // if it got here this customer does not have a scheudle created
        if (
          date.format(dayMonthYearFormat) ===
          currentDate.format(dayMonthYearFormat)
        ) {
          newRequestType = REQUEST_TYPES.CRITICAL_SPECIAL.toString();
        } else {
          newRequestType = REQUEST_TYPES.STANDARD_SPECIAL.toString();
        }
      }
    }

    // set parent request id
    await mrdForm.setFieldValue("parentRequestId", parentRequestId);
    await handleRequestTypeOnChange(
      { target: { value: newRequestType } },
      date,
      parentRequestId,
      cslCustomerId,
      cslCustomerNumber
    );
  };

  // set media request data to form
  const setRequestDataToForm = async (data) => {
    dispatch(setLockedTab(Number(data?.request_action_id)));

    let bypassReasonComment = "";
    if (data?.authentication_bypass_id) {
      // get authentication bypass reason of the request
      const authenticationBypass = await getBypassReason(
        data?.authentication_bypass_id
      );
      const bypassedDate = dayjs(authenticationBypass.bypass_date)
        .set("seconds", 0)
        .utc();
      const authBypassDate = await formatDateByLocale(
        bypassedDate,
        localeByBranch,
        true,
        true
      );
      bypassReasonComment = `(Create) ${authenticationBypass.employee_name} ${authBypassDate}: ${authenticationBypass.reason} \n`;
    }

    if (data?.cancel_bypass_id) {
      // get cancel bypass reason of the request
      const cancelBypass = await getBypassReason(data?.cancel_bypass_id);
      const canceledBypassDate = dayjs(cancelBypass.bypass_date)
        .set("seconds", 0)
        .utc();
      const cancelBypassDate = await formatDateByLocale(
        canceledBypassDate,
        localeByBranch,
        true,
        true
      );

      bypassReasonComment += `(Cancel) ${cancelBypass.employee_name} ${cancelBypassDate}: ${cancelBypass.reason}`;
    }

    const newValues = {
      customer: data?.customer_id,
      serviceDate: getDateFromUTCFormat(data?.service_date, { asObject: true }),
      requestType: data?.request_type_id,
      requestStatus: data?.request_status_id,
      rerunReason: data?.re_run_comment,
      isRegularRateSpecial: data.regular_rate_special_flag === Y_CHECKER,
      isNoChargeSpecial: data.non_billable_special_flag === Y_CHECKER,
      isRedelivery: data.redelivery_flag === Y_CHECKER,
      noChargeSpecialComment: data?.non_billable_special_comment,
      authorizedBy: data?.personnel_id || 0,
      authorizedByName: data?.personnel_full_name,
      bypassReason: bypassReasonComment,
      bypassReasonComment,
      bypassedBy:
        Number(data?.personnel_id || 0) === MRD_BYPASS_IM_PERSONNEL_ID
          ? data?.personnel_full_name
          : VALUE_EMPTY_STRING,
      confirmedBy: data?.confirm_personnel_id || 0,
      serviceLocation: data?.csl_customer_number.trim(),
      tripType: data?.trip_type_id,
      loggedBy: data?.full_name,
      loggedDate: await formatDateByLocale(
        dayjs(data?.logged_datetime).utc(),
        localeByBranch
      ),
      canceledBy: data?.cancel_employee_or_personnel_name,
      canceledDate: await formatDateByLocale(
        dayjs(data?.cancel_datetime).utc(),
        localeByBranch
      ),
      customerServiceNotes: data?.service_comment,
      customerAuthorizationNotes: data?.authorization_comment,
      ironMountainComments: data.employee_comment,
      customerComments: data.personnel_comment,
      requestAction: Number(data?.request_action_id),
      timestamp: data?.timestamp,
      inMdrFlag: data?.in_mdr_flag
    };
    mrdForm.setValues(newValues, false);
    setFormInitVal((prevState) => newValues);
    dispatch(setHaveChanges(false));
    window.scrollTo({ top: 0 });
    dispatch(setOtherComment(data?.comment));

    // check whether the request pre confirmed or not
    setPreConfirmed((prevState) => Number(newValues.confirmedBy) > 0);

    // if request status needs confirmation then enable confirmed by field
    if (
      Number(newValues.requestStatus) === REQUEST_STATUS.NEEDS_AUTHORIZATION
    ) {
      setEnableConfirmedBy((prevState) => true);
      setConfirmedByRequired((prevState) => false);
    } else if (Number(newValues.confirmedBy) > 0) {
      setEnableConfirmedBy((prevState) => false);
      setConfirmedByRequired((prevState) => false);
    }

    setPageLoading((prevState) => false);
  };

  // find and set the request_id, service_location of the parent request
  const setRequestIdAndServiceLocationOfParentRequest = async (serviceDate) => {
    if (!pageIsViewOnly) {
      // find if the selected date has a scheduled service
      const parentRequest = scheduledServiceDates.find(
        (item) =>
          serviceDate.format(dayMonthYearFormat) ===
          dayjs.utc(item.service_date).format(dayMonthYearFormat)
      );

      // set existing scheduled service's id and service location to the new request
      if (parentRequest) {
        await mrdForm.setFieldValue(
          "parentRequestId",
          parentRequest?.request_id || 0
        );
        if (parentRequest?.csl_customer_number !== "") {
          await mrdForm.setFieldValue(
            "serviceLocation",
            serviceLocations.find(
              (loc) =>
                loc.label.trim() === parentRequest?.csl_customer_number.trim()
            )?.value
          );
        }
      } else {
        await mrdForm.setFieldValue("parentRequestId", 0);
      }
    }
  };

  // service date on change event handler
  const handleServiceDateOnChange = async (date) => {
    await mrdForm.setFieldValue("serviceDate", date);

    if (!pageIsViewOnly && mrdForm.values.customer > 0) {
      await setDefaultRequestTypeAndAuthComments(date);
    }
  };
  // service date on change event handler
  const handleServiceDateOnBlur = async () => {
    if (mrdForm?.values.serviceDate >= currentDate) {
      mrdForm.setFieldError("serviceDate", undefined);
      mrdForm.setFieldTouched("serviceDate", false);
    } else mrdForm.setFieldTouched("serviceDate", true);
  };

  // request type on change event handler
  const handleRequestTypeOnChange = async (
    event,
    serviceDate = mrdForm.values?.serviceDate,
    parentRequestId = 0,
    cslCustomerId = 0,
    cslCustomerNumber = ""
  ) => {
    if (!pageIsViewOnly) {
      dispatch(removeErrorMessage());
      setThrowError(false);
      setPageLoading((prevState) => true);
      const selectedRequestType = event.target.value;
      let newRequestType = selectedRequestType;
      let localParentRequestId = parentRequestId;
      let localCSLCustomerId = cslCustomerId;
      let localCSLCustomerNumber = cslCustomerNumber;

      const onCallCustomerFlag =
        serviceAuthComments?.service_frequency_code?.trim().toLowerCase() ===
        ON_CALL.trim().toLowerCase();
      // If the customer selected does not have a service frequency then they cannot selected
      // scheduled service, so we are changing it to Critical Special
      if (
        serviceAuthComments?.service_frequency_code === "" &&
        selectedRequestType === REQUEST_TYPES.SCHEDULED_SERVICE.toString()
      ) {
        newRequestType = REQUEST_TYPES.CRITICAL_SPECIAL.toString();
      }

      // if it is an addOn request type and if service date is not an schedule service date
      if (
        selectedRequestType === REQUEST_TYPES.ADD_ON.toString() &&
        scheduledServiceDates?.findIndex(
          (item) =>
            serviceDate.format(dayMonthYearFormat) ===
            dayjs.utc(item?.service_date).format(dayMonthYearFormat)
        ) < 0
      ) {
        dispatch(setErrorMessage(t("mediaRequestDetail.invalidDateForAddon")));
        // reset the request type to an appropriate type
        if (onCallCustomerFlag) {
          if (
            Number(addOnCutOffValue?.num_day_of_week_code) === 1 &&
            addOnCutOffValue?.addoncutoffflag === BOOLEAN_STRING_VALUES.TRUE &&
            serviceDate.format(dayMonthYearFormat) ===
              currentDate.format(dayMonthYearFormat)
          ) {
            newRequestType = REQUEST_TYPES.CRITICAL_SPECIAL.toString();
          } else if (
            Number(addOnCutOffValue?.tomorrow_service_count) === 1 &&
            serviceDate.format(dayMonthYearFormat) ===
              currentDate.add(1, "day").format(dayMonthYearFormat) &&
            addOnCutOffValue?.previousdayflag === BOOLEAN_STRING_VALUES.TRUE
          ) {
            newRequestType = REQUEST_TYPES.STANDARD_SPECIAL.toString();
          } else {
            newRequestType = REQUEST_TYPES.SCHEDULED_SERVICE.toString();
          }
        } else {
          // if current date = Critical Special, otherwise Standard Special
          if (
            serviceDate.format(dayMonthYearFormat) ===
            currentDate.format(dayMonthYearFormat)
          ) {
            newRequestType = REQUEST_TYPES.CRITICAL_SPECIAL.toString();
          } else {
            newRequestType = REQUEST_TYPES.STANDARD_SPECIAL.toString();
          }
        }
      }

      // If the user wants to create a scheduled request for an on-call customer or future scheduling
      // there can't already be a scheduled request for that date
      if (
        selectedRequestType === REQUEST_TYPES.SCHEDULED_SERVICE.toString() &&
        scheduledServiceDates?.findIndex(
          (item) =>
            serviceDate.format(dayMonthYearFormat) ===
            dayjs.utc(item?.service_date).format(dayMonthYearFormat)
        ) >= 0
      ) {
        dispatch(
          setErrorMessage(
            t("mediaRequestDetail.invalidDateForScheduledService")
          )
        );
        newRequestType = REQUEST_TYPES.ADD_ON.toString();

        // find if the selected date has a scheduled service
        const parentRequest = scheduledServiceDates.find(
          (item) =>
            serviceDate.format(dayMonthYearFormat) ===
            dayjs.utc(item?.service_date).format(dayMonthYearFormat)
        );
        // set existing scheduled service's id and service location to the new request
        if (parentRequest) {
          localParentRequestId = parentRequest.request_id;
          localCSLCustomerNumber = parentRequest?.csl_customer_number;
          if (parentRequest?.csl_customer_number !== "") {
            localCSLCustomerId = serviceLocations.find(
              (loc) =>
                loc.label.trim() === parentRequest?.csl_customer_number.trim()
            )?.value;
          }
        }
      }

      // If the user selected scheduled (for future dated) then it must be beyond the schedule end date
      if (
        !onCallCustomerFlag &&
        selectedRequestType === REQUEST_TYPES.SCHEDULED_SERVICE.toString()
      ) {
        if (
          serviceDate
            .utc()
            .isBefore(dayjs(serviceAuthComments?.generated_date_end).utc())
        ) {
          // try to reset the request to special (same as logic above)
          dispatch(
            setErrorMessage(
              t("mediaRequestDetail.dateMustBeBeyondGeneratedSchedule")
            )
          );
          //  it is a special (current day = emergency, otherwise standard)
          if (serviceDate.utc().isSame(currentDate.utc(), "day")) {
            newRequestType = REQUEST_TYPES.CRITICAL_SPECIAL.toString();
          } else {
            newRequestType = REQUEST_TYPES.STANDARD_SPECIAL.toString();
          }
        }
      }

      if (
        onCallCustomerFlag &&
        selectedRequestType === REQUEST_TYPES.SCHEDULED_SERVICE.toString()
      ) {
        if (
          Number(addOnCutOffValue?.num_day_of_week_code) === 1 &&
          addOnCutOffValue?.addoncutoffflag === BOOLEAN_STRING_VALUES.TRUE &&
          serviceDate.format(dayMonthYearFormat) ===
            currentDate.format(dayMonthYearFormat)
        ) {
          dispatch(
            setErrorMessage(
              t("mediaRequestDetail.invalidDateForScheduledService")
            )
          );
          newRequestType = REQUEST_TYPES.CRITICAL_SPECIAL.toString();
        } else if (
          Number(addOnCutOffValue?.tomorrow_service_count) === 1 &&
          serviceDate.format(dayMonthYearFormat) ===
            currentDate.add(1, "day").format(dayMonthYearFormat) &&
          addOnCutOffValue?.previousdayflag === BOOLEAN_STRING_VALUES.TRUE
        ) {
          dispatch(
            setErrorMessage(
              t("mediaRequestDetail.invalidDateForScheduledService")
            )
          );
          newRequestType = REQUEST_TYPES.STANDARD_SPECIAL.toString();
        }
      }

      // set request type
      await mrdForm.setFieldValue("requestType", newRequestType);
      // set parent request id
      await mrdForm.setFieldValue("parentRequestId", localParentRequestId);
      // load service locations to the dropdown
      await loadServiceLocationsToDropdown(
        newRequestType,
        onCallCustomerFlag,
        localCSLCustomerId,
        localCSLCustomerNumber
      );

      // clear request type specials
      await mrdForm.setFieldValue(
        "rerunReason",
        mediaRequestInitialValues?.rerunReason
      );
      await mrdForm.setFieldValue(
        "isRegularRateSpecial",
        mediaRequestInitialValues.isRegularRateSpecial
      );
      await mrdForm.setFieldValue(
        "isNoChargeSpecial",
        mediaRequestInitialValues.isNoChargeSpecial
      );
      await mrdForm.setFieldValue(
        "noChargeSpecialComment",
        mediaRequestInitialValues.noChargeSpecialComment
      );
      if (!mrdForm.values.isRedelivery) {
        await mrdForm.setFieldValue(
          "isRedelivery",
          mediaRequestInitialValues.isRedelivery
        );
      }
      setPageLoading((prevState) => false);
    }
  };

  // regular rate special checkbox onchange event handler
  const handleRegularRateSpecialOnChange = async (event, checked) => {
    mrdForm.handleChange(event);
    if (checked) {
      // clear pre confirmed status and confirm personnel
      // if the request is in view mode
      setPreConfirmed((prevState) => false);
      mrdForm.setFieldValue("confirmedBy", 0);
    }
    await checkConfirmedByFieldStatus(
      checked,
      mrdForm.values.customer,
      mrdForm.values.authorizedBy,
      mrdForm.values.requestType,
      actionId
    );
  };

  // no-charge-special checkbox on change event handler
  const handleNoChargeSpecialOnChange = async (event, checked) => {
    mrdForm.handleChange(event);
    dispatch(removeInfoMessage()); // clear info alert
    if (checked) {
      if (
        pageIsViewOnly &&
        mrdForm.values.serviceDate.isBefore(currentDate, "day")
      ) {
        const message = await getMessageByIdFromMessage({
          message_id: MESSAGE_20528
        });
        dispatch(setInfoMessage({ message: message[0]?.descr }));
      }
    } else {
      if (
        pageIsViewOnly &&
        mrdForm.values.serviceDate.isBefore(currentDate, "day")
      ) {
        const message = await getMessageByIdFromMessage({
          message_id: MESSAGE_20529
        });
        dispatch(setInfoMessage({ message: message[0]?.descr }));
      }
    }
  };

  // redelivery checkbox on change event handler
  const handleRedeliveryOnChange = async (event, checked) => {
    if (!pageIsViewOnly && !mrdForm.values.isRedelivery) {
      const message63944 = await getMessageByIdFromMessage({
        message_id: MESSAGE_63944
      });
      dispatch(
        setInfoMessage({
          message: message63944[0]?.descr
        })
      );
      mrdForm.setFieldValue("isRedelivery", checked);
    } else if (
      !pageIsViewOnly &&
      mrdForm.values.isRedelivery &&
      (deliverMedia.openMedias.length > 0 || deliverMedia.containers.length > 0)
    ) {
      dispatch(
        setInfoMessage({
          message: t(
            "mediaRequestDetail.removeAllmediaPriorToUncheckRedeliveryError"
          )
        })
      );
    } else {
      mrdForm.setFieldValue(
        "rerunReason",
        mediaRequestInitialValues?.rerunReason
      );
      mrdForm.setFieldValue(
        "isRegularRateSpecial",
        mediaRequestInitialValues.isRegularRateSpecial
      );
      mrdForm.setFieldValue(
        "isNoChargeSpecial",
        mediaRequestInitialValues.isNoChargeSpecial
      );
      mrdForm.setFieldValue(
        "noChargeSpecialComment",
        mediaRequestInitialValues.noChargeSpecialComment
      );
      if (!mrdForm.values.isRedelivery) {
        mrdForm.setFieldValue(
          "isRedelivery",
          mediaRequestInitialValues.isRedelivery
        );
      }
      mrdForm.handleChange(event);
    }
  };

  // authorized by personnel on change event handler
  const handleAuthorizedByOnChange = async (_, value) => {
    if (value) {
      const authPersonnel = value?.value;
      mrdForm.setFieldValue("authorizedBy", authPersonnel);
      // fetch selected authorize personnel's details
      getAuthorizationPersonnelsData({
        personnelId: authPersonnel
      })
        .unwrap()
        .then((resp) => {
          setAuthorizationStatus({
            authorizedBy: 0,
            isAuthenticated: false,
            isByPassed: false,
            isError: false
          });
          mrdForm.setFieldValue("authNumber", "");
          mrdForm.setFieldValue(
            "authorizationChallengeQuestion",
            resp.secretQuestion
          );
          mrdForm.setFieldValue("authorizationChallengeResponse", "");
          mrdForm.setFieldValue(
            "bypassedBy",
            t("mediaRequestDetail.imPersonnelCaps")
          );
          mrdForm.setFieldValue("bypassReason", "");
          mrdForm.setFieldValue("bypassReasonComment", "");
        });
    } else {
      // clear fields after a personnel changes
      mrdForm.setFieldValue("authorizedBy", 0);
      mrdForm.setFieldValue("confirmedBy", 0);
      mrdForm.setFieldValue("authNumber", "");
      mrdForm.setFieldValue("authorizationChallengeQuestion", "");
      mrdForm.setFieldValue("authorizationChallengeResponse", "");
      mrdForm.setFieldValue("bypassedBy", "");
      mrdForm.setFieldValue("bypassReason", "");
      mrdForm.setFieldValue("bypassReasonComment", "");
      setAuthorizationStatus({
        authorizedBy: 0,
        isAuthenticated: false,
        isByPassed: false,
        isError: false
      });
      resetAuthroizedPersonnelsData();
      setEnableConfirmedBy((prevState) => false);
      setConfirmedByRequired((prevState) => false);
    }
  };

  // confirmed by personnel on change event handler
  const handleConfirmedByOnChange = async (e, value) => {
    dispatch(removeInfoMessage());
    if (value) {
      const confirmPersonnel = value?.value;
      mrdForm.setFieldValue("confirmedBy", confirmPersonnel);
      // fetch selected confirm personnel's details
      getAuthorizationPersonnelsData({
        personnelId: confirmPersonnel
      })
        .unwrap()
        .then((resp) => {
          setConfirmationStatus({
            authorizedBy: 0,
            isAuthenticated: false,
            isError: false
          });
          mrdForm.setFieldValue("confirmAuthNumber", "");
          mrdForm.setFieldValue(
            "confirmAuthorizationChallengeQuestion",
            resp.secretQuestion
          );
          mrdForm.setFieldValue("confirmAuthorizationChallengeResponse", "");
        });
    } else {
      mrdForm.setFieldValue("confirmedBy", 0);
      resetAuthroizedPersonnelsData();
      // clear fields after a personnel changes
      mrdForm.setFieldValue("confirmAuthNumber", "");
      mrdForm.setFieldValue("confirmAuthorizationChallengeQuestion", "");
      mrdForm.setFieldValue("confirmAuthorizationChallengeResponse", "");
      setConfirmationStatus({
        authorizedBy: 0,
        isAuthenticated: false,
        isError: false
      });
    }
  };

  // handle request authroization event
  const handleAuthorization = async ({ target }) => {
    dispatch(removeInfoMessage());
    switch (target.id) {
      case "mediaRequestDetailAuthenticateButton": {
        setPageLoading((prevState) => true);
        //generate hash code
        let validateChallengeResponse;
        if (mrdForm.values?.authorizationChallengeResponse?.length > 0) {
          let hashCode = await generateSHA256Hash(
            mrdForm.values?.authorizationChallengeResponse.trim().toUpperCase()
          );

          //challenge response validation
          validateChallengeResponse = await triggerValidateChallengeResponse({
            main_district_id: currentBranch?.district_id,
            language_locale_id: getLanguageId(),
            personnel_id: mrdForm.values?.authorizedBy,
            challenge_response: hashCode
          })
            .unwrap()
            .then((data) => {
              if (data[0]?.is_match_flag) {
                return data[0].is_match_flag;
              }
            });
        }
        if (
          authroizedPersonnelsData?.authNumber === mrdForm.values?.authNumber ||
          validateChallengeResponse === Y_CHECKER
        ) {
          setAuthorizationStatus((prevState) => ({
            authorizedBy: mrdForm.values?.authorizedBy,
            isAuthenticated: true,
            isByPassed: false,
            isError: false
          }));
          if (
            Number(mrdForm.values.requestType) ===
              REQUEST_TYPES.STANDARD_SPECIAL &&
            Boolean(mrdForm.values.isRegularRateSpecial)
          ) {
            // if request type is standard special and regular rate special checked then the authorization is not needed
            setConfirmedByRequired((prevState) => false);
            setEnableConfirmedBy((prevState) => false);
            setPageLoading((prevState) => false);
          } else {
            await checkAuthorization(
              mrdForm.values.customer,
              mrdForm.values?.authorizedBy,
              mrdForm.values.requestType,
              actionId,
              mrdForm.values.isRegularRateSpecial
            ).then(async (checkAuth) => {
              // if the authorized personnel doesn't have access
              if (!checkAuth) {
                setConfirmedByRequired((prevState) => false);
                setEnableConfirmedBy((prevState) => true);
              } else {
                setConfirmedByRequired((prevState) => false);
                setConfirmedByRequired((prevState) => false);
              }
              setPageLoading((prevState) => false);
            });
          }
        } else {
          setAuthorizationStatus((prevState) => ({
            authorizedBy: 0,
            isAuthenticated: false,
            isByPassed: false,
            isError: true
          }));
          setPageLoading((prevState) => false);
        }
        break;
      }
      case "mediaRequestDetailBypassButton": {
        setPageLoading((prevState) => true);
        setAuthorizationStatus((prevState) => ({
          authorizedBy: mrdForm.values?.authorizedBy,
          isAuthenticated: true,
          isByPassed: true,
          isError: false
        }));

        let bypassReasonComment = "";
        const authBypassDate = await formatDateByLocale(
          mrdForm.values?.serviceDate,
          localeByBranch,
          false
        );
        bypassReasonComment = `(Create) ${getAuthenticatedUserName()} ${authBypassDate}: ${
          mrdForm.values.bypassReason
        }`;
        await mrdForm.setFieldValue("bypassReasonComment", bypassReasonComment);
        setConfirmedByRequired(false);
        setEnableConfirmedBy(false);
        setPageLoading(false);
        break;
      }
      case "mediaRequestDetailConfirmAuthenticateButton": {
        setPageLoading((prevState) => true);
        //generate hash code
        let validateConfirmChallengeResponse;
        if (mrdForm.values?.confirmAuthorizationChallengeResponse?.length > 0) {
          let hashCode = await generateSHA256Hash(
            mrdForm.values?.confirmAuthorizationChallengeResponse
              .trim()
              .toUpperCase()
          );

          //challenge response validation
          validateConfirmChallengeResponse =
            await triggerValidateChallengeResponse({
              main_district_id: currentBranch?.district_id,
              language_locale_id: getLanguageId(),
              personnel_id: mrdForm.values?.confirmedBy,
              challenge_response: hashCode
            })
              .unwrap()
              .then((data) => {
                if (data[0]?.is_match_flag) {
                  return data[0].is_match_flag;
                }
              });
        }
        if (
          authroizedPersonnelsData?.authNumber ===
            mrdForm.values?.confirmAuthNumber ||
          validateConfirmChallengeResponse === Y_CHECKER
        ) {
          await checkAuthorization(
            mrdForm.values.customer,
            mrdForm.values?.confirmedBy,
            mrdForm.values.requestType,
            actionId,
            mrdForm.values.isRegularRateSpecial
          ).then(async (checkAuth) => {
            // if the confirm personnel doesn't have access display message 2022
            // otherwise set the confirmed by personnel value
            if (!checkAuth) {
              await getMessageByIdFromMessage({
                message_id: MESSAGE_20222
              }).then((resp) => {
                mrdForm.setFieldValue("confirmedBy", 0);
                setResetConfirmedByField((prevState) => !prevState);
                setConfirmationStatus({
                  authorizedBy: 0,
                  isAuthenticated: false,
                  isError: false
                });
                dispatch(setInfoMessage({ message: resp[0]?.descr }));
                setPageLoading((prevState) => false);
              });
            } else {
              setConfirmationStatus({
                authorizedBy: mrdForm.values?.confirmedBy,
                isAuthenticated: true,
                isError: false
              });
              dispatch(removeInfoMessage());
              setPageLoading((prevState) => false);
            }
          });
        } else {
          setConfirmationStatus((prevState) => ({
            authorizedBy: 0,
            isAuthenticated: false,
            isError: true
          }));
          setPageLoading((prevState) => false);
        }
        break;
      }
      default:
        break;
    }
  };

  // check proceed to create/find/save request
  const validateNewAction = () => pageIsViewOnly || !mrdForm.values?.customer;

  // handle create new media request button event
  const handleCreateNewMediaRequest = (_) => {
    resetMediaRequest();
    setEnableConfirmedBy((prevState) => false);
    setConfirmedByRequired((prevState) => false);
    dispatch(resetMediaRequestDetail());
    mrdForm.resetForm();
    extraItemsForm.resetForm();
  };

  // hanlde assign transport modal
  const handleAssignTransportModal = () => {
    if (transports.available.length > 0 || transports.assigned.length > 0) {
      setAssignTransportModalVisible((prevState) => true);
    } else {
      dispatch(
        setErrorMessage(
          t("mediaRequestDetail.noTransportsAvailableForAssignment")
        )
      );
    }
  };

  // validate cancel request
  const validateCancelRequest = async () => {
    let authRequiredCancel = false;
    let authRequiredForRedeliveryCancel = false;

    if (
      Number(mrdForm.values.requestType) === REQUEST_TYPES.DISASTER_RECOVERY &&
      Number(mrdForm.values.authorizedBy) === 0
    ) {
      // DR without authorization can be canceled by anyone
      authRequiredCancel = false;
    } else {
      const authRequires = await isAuthRequired(
        mrdForm.values.requestType,
        actionId,
        mrdForm.values.isRegularRateSpecial
      );
      authRequiredCancel = authRequires;
    }

    if (Number(mrdForm.values.authorizedBy) !== 0) {
      authRequiredCancel = true;
    }

    if (mrdForm.values.isRedelivery) {
      if (
        Number(mrdForm.values.requestType) === REQUEST_TYPES.STANDARD_SPECIAL ||
        Number(mrdForm.values.requestType) === REQUEST_TYPES.CRITICAL_SPECIAL
      ) {
        authRequiredForRedeliveryCancel = true;
      } else {
        authRequiredCancel = false;
      }
    }

    // If the request type/action requires authorization
    // or the request was authorized when created
    // then authorization is needed to cancel the request
    if (authRequiredCancel || authRequiredForRedeliveryCancel) {
      //we need to know the personnel who is requesting the cancellation
      //next check if user want to authorize this type of cancellation.
      setCancelRequestRequiresAuthModal((prevState) => true);
    } else {
      setCancelRequestCheckModal((prevState) => true);
    }
    setPageLoading((prevState) => false);
  };

  // handle cancel request button event
  const handleCancelRequest = async (_) => {
    setPageLoading((prevState) => true);
    if (mrdForm.values.inMdrFlag === Y_CHECKER) {
      setPageLoading((prevState) => false);
      setCancellingMediaDestructionVisible(true);
    } else {
      await validateCancelRequest();
    }
  };

  // handle cancel request modal submit event
  const handleCancelRequestModalSubmit = () => {
    setCancelRequestModalVisible((prevState) => false);
    setCancelRequestCheckModal((prevState) => false);
    navigate(
      `${REQUEST_MODULE_BASE_PATH}/${MEDIA_REQUEST_DETAIL}/${requestId}`,
      { replace: true }
    );
  };

  // cancel request message modals on click
  const handleButtonClick = (action) => {
    if (action === YES) {
      setCancellingMediaDestructionVisible((prevState) => false);
      validateCancelRequest();
    } else if (action === YES_AUTHORIZE) {
      setCancelRequestRequiresAuthModal((prevState) => false);
      setCancelRequestModalVisible((prevState) => true);
    } else if (action === NO || action === CANCEL) {
      setCancellingMediaDestructionVisible((prevState) => false);
      setCancelRequestRequiresAuthModal((prevState) => false);
    }
  };

  //if the Page with view mode and change the  values the save button will enabled
  const [formInitVal, setFormInitVal] = useState({});

  // check whether the form is dirty or not
  useEffect(() => {
    if (pageIsViewOnly) {
      const formTouchedKeys = Object.keys(mrdForm.touched);
      if (formTouchedKeys.length > 0) {
        let formIsDirty = false;
        formTouchedKeys.every((key) => {
          if (formInitVal[key] !== mrdForm.values[key]) {
            formIsDirty = true;
            return false;
          } else {
            formIsDirty = false;
            return true;
          }
        });
        dispatch(setHaveChanges(formIsDirty));
      } else {
        dispatch(setHaveChanges(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mrdForm.touched,
    mrdForm.values.isRegularRateSpecial,
    mrdForm.values.isNoChargeSpecial,
    mrdForm.values.noChargeSpecialComment,
    mrdForm.values.isRedelivery,
    mrdForm.values.confirmedBy,
    mrdForm.values.serviceLocation,
    mrdForm.values.tripType,
    mrdForm.values.ironMountainComments,
    mrdForm.values.customerComments
  ]);

  // handle confirmation popup "onYes" click event handler
  const handleConfirmationOnYes = () => {
    setExitAfterSave((prevState) => false);
    if (showConfirmationModal) {
      dispatch(setShowConfirmationModal(false));
    }

    // if navigation triggered by browser button
    if (showPrompt) {
      setExitAfterSave((prevState) => true);
      cancelNavigation();
    }

    dispatch(cancelExitProcedure());
    mrdForm.handleSubmit();
    window.scrollTo({ top: 0 });
  };

  // handle confirmation popup "onNo" event handler
  const handleConfirmationOnNo = () => {
    dispatch(resetRouteState());
    if (showConfirmationModal) {
      dispatch(cancelSaveProcedure());
    }

    // if navigation triggered by browser button
    if (showPrompt) {
      confirmNavigation();
    }
  };

  // handle confirmation popup "onCancel" click event handler
  const handleConfirmationOnCancel = () => {
    if (showConfirmationModal) {
      dispatch(cancelConfirmation());
      dispatch(cancelExitProcedure());
    }

    // if navigation triggered by browser button
    if (showPrompt) {
      cancelNavigation();
    }
  };

  return (
    <>
      {/* Full page loader */}
      <CircularLoaderFullPage
        id="mediaRequestDetailFullPageLoader"
        loading={pageLoading || isCustomersLoading || isRequestTypesLoading}
      />
      <Box
        id="mediaRequestDetailBox1"
        sx={MediaRequestDetailFormStyles.PageContainer}
      >
        <form
          id="mediaRequestDetailForm"
          style={MediaRequestDetailFormStyles.FormWrapper}
        >
          {/* Error message display area */}
          {error.show && (
            <Grid id="mediaRequestDetailGrid1" item>
              <Alert
                id="mediaRequestDetailErrorAlert"
                icon={<img src={WarningIcon} alt="Warning" />}
                severity="error"
                color="error"
                onClose={() => dispatch(removeErrorMessage())}
                sx={{ borderRadius: "9px" }}
              >
                {error.message}
              </Alert>
            </Grid>
          )}

          {/* Info message display area */}
          {info.show && (
            <Grid id="mediaRequestDetailGrid1" item>
              <Alert
                id="mediaRequestDetailErrorAlert"
                severity="info"
                color="info"
                onClose={() => dispatch(removeInfoMessage())}
                sx={{ borderRadius: "9px" }}
              >
                {Boolean(info?.title) && <AlertTitle>{info.title}</AlertTitle>}
                {info.message}
              </Alert>
            </Grid>
          )}

          <Grid
            id="mediaRequestDetailGrid2"
            container
            spacing={spacing.verticalMargin20}
          >
            {/* Customer field */}
            <Grid id="mediaRequestDetailGrid3" item xs={12} md={6} lg={3}>
              {pageIsViewOnly ? (
                <SelectField
                  id="mediaRequestDetailCustomer"
                  name="customer"
                  label={t("mediaRequestDetail.customerStarred")}
                  options={customers}
                  value={mrdForm.values?.customer}
                  disabled
                  sx={{ cursor: "default" }}
                />
              ) : (
                <Autocomplete
                  key={resetCustomerField}
                  popupIcon={<ArrowDown />}
                  id="mediaRequestDetailCustomer"
                  disablePortal
                  size="medium"
                  options={customers}
                  noOptionsText={t("mediaRequestDetail.noCustomerRecordsFound")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="customer"
                      label={t("mediaRequestDetail.customer")}
                      required
                      value={mrdForm.values?.customer}
                      error={
                        mrdForm.touched.customer &&
                        Boolean(mrdForm.errors.customer)
                      }
                      helperText={
                        mrdForm.touched.customer && mrdForm.errors.customer
                      }
                    />
                  )}
                  onChange={handleCustomerOnChange}
                  onBlur={(e) => mrdForm.setFieldTouched(e.target.name, true)}
                />
              )}
            </Grid>

            {/* Service Date field */}
            <Grid id="mediaRequestDetailGrid4" item xs={12} md={6} lg={3}>
              {pageIsViewOnly ? (
                <DatePickerField
                  id="mediaRequestDetailServiceDate"
                  disabled
                  name="serviceDate"
                  label={t("mediaRequestDetail.serviceDate*")}
                  locale={localeByBranch || DEFAULT_LOCALE}
                  value={mrdForm.values?.serviceDate}
                />
              ) : (
                <DatePickerField
                  id="mediaRequestDetailServiceDate"
                  key={timeZoneOffsetChanged}
                  disabled={
                    pageIsViewOnly
                      ? mediaRequestFound
                      : mrdForm.values?.customer === 0 ||
                        mrdForm.values?.customer === undefined ||
                        (Number(mrdForm.values.requestType) ===
                          REQUEST_TYPES.CRITICAL_SPECIAL &&
                          mrdForm.values.isRedelivery === true) ||
                        (Number(mrdForm.values.requestType) ===
                          REQUEST_TYPES.RE_RUN &&
                          mrdForm.values.isRedelivery === true) ||
                        (Number(mrdForm.values.requestType) ===
                          REQUEST_TYPES.STANDARD_SPECIAL &&
                          mrdForm.values.isRedelivery === true)
                  }
                  required
                  locale={localeByBranch || DEFAULT_LOCALE}
                  name="serviceDate"
                  label={t("mediaRequestDetail.serviceDate*")}
                  minDate={currentDate}
                  maxDate={maxDate}
                  value={mrdForm.values?.serviceDate}
                  loading={isScheduledServiceDatesLoading}
                  renderLoading={() => <CircularLoader loading={true} />}
                  onOpen={() =>
                    getScheduledServiceDates({
                      customerId: mrdForm.values?.customer,
                      fromDate: handleFromDate(mrdForm.values.serviceDate),
                      toDate: handleToDate(mrdForm.values.serviceDate),
                      addonCutoffFlag:
                        addOnCutOffValue?.addoncutoffflag ===
                          BOOLEAN_STRING_VALUES.TRUE ||
                        addOnCutOffValue?.previousdayflag ===
                          BOOLEAN_STRING_VALUES.TRUE
                          ? Y_CHECKER
                          : N_CHECKER
                    })
                      .unwrap()
                      .then((resp) =>
                        setScheduledServiceDates((prevState) =>
                          filteredServiceDates(resp, addOnCutOffValue)
                        )
                      )
                  }
                  onMonthChange={(value) =>
                    getScheduledServiceDates({
                      customerId: mrdForm.values?.customer,
                      fromDate: handleFromDate(value),
                      toDate: handleToDate(value),
                      addonCutoffFlag:
                        addOnCutOffValue?.addoncutoffflag ===
                          BOOLEAN_STRING_VALUES.TRUE ||
                        addOnCutOffValue?.previousdayflag ===
                          BOOLEAN_STRING_VALUES.TRUE
                          ? Y_CHECKER
                          : N_CHECKER
                    })
                      .unwrap()
                      .then((resp) =>
                        setScheduledServiceDates((prevState) =>
                          filteredServiceDates(resp, addOnCutOffValue)
                        )
                      )
                  }
                  onYearChange={(value) =>
                    getScheduledServiceDates({
                      customerId: mrdForm.values?.customer,
                      fromDate: handleFromDate(value),
                      toDate: handleToDate(value),
                      addonCutoffFlag:
                        addOnCutOffValue?.addoncutoffflag ===
                          BOOLEAN_STRING_VALUES.TRUE ||
                        addOnCutOffValue?.previousdayflag ===
                          BOOLEAN_STRING_VALUES.TRUE
                          ? Y_CHECKER
                          : N_CHECKER
                    })
                      .unwrap()
                      .then((resp) =>
                        setScheduledServiceDates((prevState) =>
                          filteredServiceDates(resp, addOnCutOffValue)
                        )
                      )
                  }
                  onChange={(selectedDate) =>
                    handleServiceDateOnChange(selectedDate)
                  }
                  error={
                    mrdForm.touched.serviceDate &&
                    Boolean(mrdForm.errors.serviceDate)
                  }
                  helperText={
                    mrdForm.touched.serviceDate && mrdForm.errors.serviceDate
                  }
                  onBlur={handleServiceDateOnBlur}
                  highlightScheduleDays={(props) => (
                    <HighlightScheduledDates
                      {...props}
                      selectedDate={mrdForm.values?.serviceDate}
                      hightlightedDates={scheduledServiceDates}
                    />
                  )}
                />
              )}
            </Grid>

            {/* Run Date Label */}
            {!pageIsViewOnly &&
              scheduledServiceDates.findIndex((item) =>
                mrdForm.values?.serviceDate
                  .utc()
                  ?.isSame(dayjs(item?.service_date).utc(), "day")
              ) >= 0 &&
              mrdForm.values.requestType ===
                REQUEST_TYPES.ADD_ON.toString() && (
                <Grid id="mediaRequestDetailGrid7" item my={"auto"}>
                  <NonEditableField value={t("mediaRequestDetail.runDate")} />
                </Grid>
              )}

            {/* Request Type field */}
            <Grid id="mediaRequestDetailGrid7" item xs={12} md={6} lg={3}>
              {pageIsViewOnly ? (
                <SelectField
                  id="mediaRequestDetailRequestType"
                  name="requestType"
                  label={t("mediaRequestDetail.requestTypeStarred")}
                  disabled
                  options={requestTypes}
                  value={mrdForm.values?.requestType}
                />
              ) : (
                <SelectField
                  id="mediaRequestDetailRequestType"
                  name="requestType"
                  label={t("mediaRequestDetail.requestTypeStarred")}
                  disabled={
                    mrdForm.values?.customer === 0 ||
                    mrdForm.values?.customer === undefined
                  }
                  options={requestTypes.filter((item) =>
                    mrdForm.values?.isRedelivery
                      ? Number(item.value) === REQUEST_TYPES.CRITICAL_SPECIAL ||
                        Number(item.value) === REQUEST_TYPES.RE_RUN ||
                        Number(item.value) === REQUEST_TYPES.STANDARD_SPECIAL
                      : true
                  )}
                  value={mrdForm.values?.requestType}
                  onChange={handleRequestTypeOnChange}
                  onBlur={(e) => mrdForm.setFieldTouched(e.target.name, true)}
                  error={
                    mrdForm.touched.requestType &&
                    Boolean(mrdForm.errors.requestType)
                  }
                  helperText={
                    mrdForm.touched.requestType && mrdForm.errors.requestType
                  }
                />
              )}
            </Grid>

            {/* Request Status field */}
            {pageIsViewOnly && (
              <Grid id="mediaRequestDetailGrid8" item xs={12} md={6} lg={3}>
                <SelectField
                  id="media_request_requestType"
                  name="requestStatus"
                  label={t("mediaRequestDetail.requestStatus")}
                  disabled
                  options={requestStatuses}
                  value={mrdForm.values?.requestStatus}
                />
              </Grid>
            )}

            {/* Rerun Reason field */}
            {mrdForm.values.requestType === REQUEST_TYPES.RE_RUN.toString() && (
              <Grid id="mediaRequestDetailGrid9" item xs={12}>
                <TextField
                  id="mediaRequestDetailRerunReason"
                  required={
                    !pageIsViewOnly &&
                    mrdForm.values.requestType ===
                      REQUEST_TYPES.RE_RUN.toString()
                  }
                  disabled={
                    Number(mrdForm.values.requestStatus) ===
                      REQUEST_STATUS.CANCELED ||
                    Number(mrdForm.values.requestStatus) ===
                      REQUEST_STATUS.COMPLETE ||
                    Number(mrdForm.values.requestStatus) ===
                      REQUEST_STATUS.DELETED
                  }
                  name="rerunReason"
                  label={t("mediaRequestDetail.whyCustomerCalledForRerun")}
                  size="medium"
                  sx={MediaRequestDetailFormStyles.FlexShrinkNoGrow}
                  variant="outlined"
                  multiline
                  rows={2}
                  value={mrdForm.values.rerunReason}
                  onChange={mrdForm.handleChange}
                  onBlur={(e) => mrdForm.setFieldTouched(e.target.name, true)}
                  error={
                    mrdForm.touched.rerunReason &&
                    Boolean(mrdForm.errors.rerunReason)
                  }
                  helperText={
                    mrdForm.touched.rerunReason && mrdForm.errors.rerunReason
                  }
                />
              </Grid>
            )}
          </Grid>

          {/* Request Special Checkboxes */}
          <Grid
            id="mediaRequestDetailGrid10"
            container
            spacing={spacing.verticalMargin20}
          >
            {/* Regular Rate Special Checkbox */}
            <Grid id="mediaRequestDetailGrid11" item xs={12} md={6} lg={3}>
              <FormControlLabel
                id="mediaRequestDetailReqularRateSpecialLabel"
                control={
                  <Checkbox
                    id="mediaRequestDetailRegularRateSpecial"
                    disabled={
                      mrdForm.values?.customer === 0 ||
                      mrdForm.values?.customer === undefined ||
                      mrdForm.values.requestType ===
                        REQUEST_TYPES.ADD_ON.toString() ||
                      mrdForm.values.requestType ===
                        REQUEST_TYPES.CRITICAL_SPECIAL.toString() ||
                      mrdForm.values.requestType ===
                        REQUEST_TYPES.RE_RUN.toString() ||
                      mrdForm.values.requestType ===
                        REQUEST_TYPES.SCHEDULED_SERVICE.toString() ||
                      Number(mrdForm.values.requestStatus) ===
                        REQUEST_STATUS.CANCELED ||
                      Number(mrdForm.values.requestStatus) ===
                        REQUEST_STATUS.DELETED
                    }
                    name="isRegularRateSpecial"
                    size="medium"
                    color="primary"
                    checked={mrdForm.values.isRegularRateSpecial}
                    onChange={handleRegularRateSpecialOnChange}
                    onBlur={(e) => mrdForm.setFieldTouched(e.target.name, true)}
                  />
                }
                label={t("mediaRequestDetail.regularRateSpecial")}
                labelPlacement="end"
              />
            </Grid>

            {/* No Charge Special Checkbox */}
            <Grid id="mediaRequestDetailGrid12" item xs={12} md={6} lg={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="mediaRequestDetailNoChargeSpecial"
                    disabled={
                      mrdForm.values?.customer === 0 ||
                      mrdForm.values?.customer === undefined ||
                      mrdForm.values.requestType ===
                        REQUEST_TYPES.ADD_ON.toString() ||
                      mrdForm.values.requestType ===
                        REQUEST_TYPES.RE_RUN.toString() ||
                      mrdForm.values.requestType ===
                        REQUEST_TYPES.SCHEDULED_SERVICE.toString() ||
                      !(
                        mrdForm.requestType !==
                          REQUEST_TYPES.STANDARD_SPECIAL.toString() ||
                        mrdForm.requestType !==
                          REQUEST_TYPES.CRITICAL_SPECIAL.toString()
                      ) ||
                      Number(mrdForm.values.requestStatus) ===
                        REQUEST_STATUS.CANCELED ||
                      Number(mrdForm.values.requestStatus) ===
                        REQUEST_STATUS.DELETED
                    }
                    name="isNoChargeSpecial"
                    size="medium"
                    color="primary"
                    checked={mrdForm.values.isNoChargeSpecial}
                    onChange={handleNoChargeSpecialOnChange}
                    onBlur={(e) => mrdForm.setFieldTouched(e.target.name, true)}
                  />
                }
                label={t("mediaRequestDetail.noChargeSpecial")}
                labelPlacement="end"
              />
            </Grid>

            {/* Redelivery Checkbox */}
            <Grid id="mediaRequestDetailGrid13" item xs={12} md={6} lg={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="mediaRequestDetailRedelivery"
                    disabled={
                      pageIsViewOnly ||
                      mrdForm.values?.customer === 0 ||
                      mrdForm.values?.customer === undefined ||
                      mrdForm.values.requestType ===
                        REQUEST_TYPES.ADD_ON.toString() ||
                      mrdForm.values.requestType ===
                        REQUEST_TYPES.SCHEDULED_SERVICE.toString() ||
                      !(
                        actionId === REQUEST_ACTIONS.MEDIA_PICKUP_DELIVERY.ID &&
                        mrdForm.values.serviceDate?.isSame(
                          currentDate,
                          "day"
                        ) &&
                        (mrdForm.values.requestType ===
                          REQUEST_TYPES.CRITICAL_SPECIAL.toString() ||
                          mrdForm.values.requestType ===
                            REQUEST_TYPES.STANDARD_SPECIAL.toString() ||
                          mrdForm.values.requestType ===
                            REQUEST_TYPES.RE_RUN.toString())
                      ) ||
                      Number(mrdForm.values.requestStatus) ===
                        REQUEST_STATUS.CANCELED ||
                      Number(mrdForm.values.requestStatus) ===
                        REQUEST_STATUS.COMPLETE ||
                      Number(mrdForm.values.requestStatus) ===
                        REQUEST_STATUS.DELETED
                    }
                    name="isRedelivery"
                    size="medium"
                    color="primary"
                    checked={mrdForm.values.isRedelivery}
                    onChange={handleRedeliveryOnChange}
                    onBlur={(e) => mrdForm.setFieldTouched(e.target.name, true)}
                  />
                }
                label={t("mediaRequestDetail.redelivery")}
                labelPlacement="end"
              />
            </Grid>
          </Grid>

          {/*No charge special comment text field*/}
          {mrdForm.values?.isNoChargeSpecial && (
            <Grid id="mediaRequestDetailGrid14" item xs={12} md={6} lg={12}>
              <TextField
                id="mediaRequestDetailNoChargeSpecialComment"
                disabled={
                  Number(mrdForm.values.requestStatus) ===
                    REQUEST_STATUS.CANCELED ||
                  Number(mrdForm.values.requestStatus) ===
                    REQUEST_STATUS.DELETED
                }
                required={
                  mrdForm.values?.customer === undefined ||
                  mrdForm.values.isNoChargeSpecial
                }
                name="noChargeSpecialComment"
                label={t("mediaRequestDetail.noChargeSpecialComment")}
                size="medium"
                sx={MediaRequestDetailFormStyles.FlexShrinkNoGrow}
                variant="outlined"
                multiline
                rows={2}
                value={mrdForm.values.noChargeSpecialComment}
                onChange={(event) =>
                  limitFormikTextFieldLength(mrdForm, event, 254)
                }
                onBlur={(e) => mrdForm.setFieldTouched(e.target.name, true)}
                error={
                  mrdForm.touched.noChargeSpecialComment &&
                  Boolean(mrdForm.errors.noChargeSpecialComment)
                }
                helperText={
                  mrdForm.touched.noChargeSpecialComment &&
                  mrdForm.errors.noChargeSpecialComment
                }
              />
            </Grid>
          )}

          {/*Authorization title*/}
          <Grid
            id="mediaRequestDetailGrid47"
            container
            spacing={spacing.verticalMargin20}
          >
            <Grid id="mediaRequestDetailGrid48" item xs={12}>
              <Typography
                id="mediaRequestDetailPersonnelAuthTitle"
                variant="h5"
                sx={MediaRequestDetailFormStyles.AuthorizationHeaderTitle}
              >
                {t("mediaRequestDetail.personnelAuth")}
              </Typography>
            </Grid>
          </Grid>

          {/* Authorization Form Controls */}
          <Grid
            id="mediaRequestDetailGrid15"
            container
            spacing={spacing.verticalMargin20}
          >
            <Grid id="mediaRequestDetailGrid16" item xs={12}>
              <Grid
                id="mediaRequestDetailGrid17"
                container
                spacing={spacing.verticalMargin20}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                {/* Authorized By field */}
                <Grid id="mediaRequestDetailGrid18" item xs={12} md={6} lg={3}>
                  {pageIsViewOnly ? (
                    <SelectField
                      id="mediaRequestDetailAuthorizedBy"
                      name="authorizedBy"
                      label={`${
                        mrdForm.values.requestType ===
                          REQUEST_TYPES.RE_RUN.toString() ||
                        mrdForm.values.isRegularRateSpecial
                          ? t("mediaRequestDetail.authorizedBy")
                          : t("mediaRequestDetail.authorizedByStarred")
                      }`}
                      disabled
                      options={
                        Number(mrdForm.values?.authorizedBy) ===
                        MRD_BYPASS_IM_PERSONNEL_ID
                          ? [
                              {
                                label: mrdForm.values?.bypassedBy,
                                value: MRD_BYPASS_IM_PERSONNEL_ID
                              }
                            ].concat(authorizationPersonnels)
                          : authorizationPersonnels
                      }
                      value={mrdForm.values?.authorizedBy}
                    />
                  ) : (
                    <Autocomplete
                      popupIcon={
                        mrdForm.values?.customer === 0 ||
                        mrdForm.values?.customer === undefined ? (
                          <ArrowDownDisabled />
                        ) : (
                          <ArrowDown />
                        )
                      }
                      disabled={
                        mrdForm.values?.customer === 0 ||
                        mrdForm.values?.customer === undefined
                      }
                      key={resetAuthoirzedByField}
                      id="mediaRequestDetailAuthorizedBy"
                      disablePortal
                      size="medium"
                      loading={isAuthorizationPersonnelsLoading}
                      options={
                        authorizationStatus?.isByPassed
                          ? [
                              {
                                label: mrdForm.values?.bypassedBy,
                                value: MRD_BYPASS_IM_PERSONNEL_ID
                              }
                            ]
                          : authorizationPersonnels
                      }
                      noOptionsText={t(
                        "mediaRequestDetail.noPersonnelRecordsFound"
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={
                            !(
                              mrdForm.values.requestType ===
                                REQUEST_TYPES.RE_RUN.toString() ||
                              mrdForm.values.isRegularRateSpecial
                            )
                          }
                          name="authorizedBy"
                          label={t("mediaRequestDetail.authorizedBy")}
                          error={
                            mrdForm.touched.authorizedBy &&
                            Boolean(mrdForm.errors.authorizedBy)
                          }
                          helperText={
                            mrdForm.touched.authorizedBy &&
                            mrdForm.errors.authorizedBy
                          }
                        />
                      )}
                      onChange={handleAuthorizedByOnChange}
                      onBlur={(e) =>
                        mrdForm.setFieldTouched(e.target.name, true)
                      }
                      value={
                        authorizationPersonnels.find(
                          (personnel) =>
                            personnel.value ===
                            (authorizationStatus?.isByPassed
                              ? mrdForm.values?.bypassedBy
                              : mrdForm.values.authorizedBy)
                        ) ||
                        (authorizationStatus?.isByPassed
                          ? {
                              label: mrdForm.values?.bypassedBy,
                              value: MRD_BYPASS_IM_PERSONNEL_ID
                            }
                          : null)
                      }
                      getOptionLabel={(option) => option.label}
                    />
                  )}
                </Grid>

                {/* Bypassed personnel name */}
                {!pageIsViewOnly && authorizationStatus?.isByPassed && (
                  <Grid id="mediaRequestDetailGrid19" item>
                    <NonEditableField
                      id="mediaRequestDetailBypassedBy"
                      label={t("mediaRequestDetail.bypassedBy")}
                      value={authInfo?.displayName}
                    />
                  </Grid>
                )}

                {/* Request authorization status */}
                <Grid id="mediaRequestDetailGrid20" item xs={12} md={6} lg={3}>
                  {!pageIsViewOnly &&
                    // New media request authentication status
                    authorizationStatus.isAuthenticated && (
                      <Typography
                        id="mediaRequestDetailAuthenticationStatus"
                        variant="subtitle1"
                        color="success"
                        sx={MediaRequestDetailFormStyles.AuthenticatedText}
                      >
                        <CheckIcon id="icnAuthorize" marginRight={"4px"} />
                        {mrdForm.values?.authorizationType ===
                        t("mediaRequestDetail.personnel")
                          ? t("mediaRequestDetail.authenticated")
                          : t("mediaRequestDetail.bypassed")}
                      </Typography>
                    )}
                </Grid>
              </Grid>
            </Grid>

            {!pageIsViewOnly &&
              mrdForm.values?.authorizedBy > 0 &&
              !authorizationStatus.isAuthenticated && (
                <>
                  {/* Authorization Type Selector*/}
                  <FormControl
                    id="mediaRequestDetailAuthenticationTypeControl"
                    disabled={
                      mrdForm.values?.customer === 0 ||
                      mrdForm.values?.customer === undefined ||
                      validatingChallengeResponse
                    }
                    error={
                      mrdForm.touched.authorizationType &&
                      Boolean(mrdForm.errors.authorizationType)
                    }
                  >
                    <RadioGroup
                      id="mediaRequestDetailAuthorizationType"
                      name="authorizationType"
                      sx={{
                        marginTop: "1.25rem",
                        marginLeft: "2rem",
                        marginBottom: "0.625px"
                      }}
                      row
                      aria-labelledby="Media-Request-Authorization-Type"
                      value={mrdForm.values.authorizationType}
                      onChange={mrdForm.handleChange}
                    >
                      <FormControlLabel
                        id="mediaRequestDetailAuthorizationTypePersonnel"
                        value={t("mediaRequestDetail.personnel")}
                        control={<Radio color="secondary" />}
                        label={t("mediaRequestDetail.personnelAuth")}
                      />
                      <FormControlLabel
                        id="mediaRequestDetailAuthorizationTypeBypass"
                        disabled={!isBypassEnabled(mrdForm.values.requestType)}
                        value={t("mediaRequestDetail.bypass")}
                        control={<Radio color="secondary" />}
                        label={t("mediaRequestDetail.bypassCaps")}
                      />
                    </RadioGroup>
                  </FormControl>

                  {mrdForm.values.authorizationType ===
                  t("mediaRequestDetail.personnel") ? (
                    <>
                      {/* Personal Authentication Helper Text */}
                      <Grid id="mediaRequestDetailGrid21" item xs={12}>
                        <Typography
                          id="mediaRequestDetailAuthHelper"
                          variant="body2"
                        >
                          {t("mediaRequestDetail.personnelAuthHelper")}
                        </Typography>
                      </Grid>

                      {/* Auth Number field */}
                      <Grid
                        id="mediaRequestDetailGrid22"
                        item
                        xs={12}
                        md={6}
                        lg={3}
                      >
                        <TextField
                          id="mediaRequestDetailAuthNumber"
                          disabled={
                            mrdForm.values?.customer === 0 ||
                            mrdForm.values?.customer === undefined ||
                            Boolean(
                              mrdForm.values.authorizationChallengeResponse
                            )
                          }
                          name="authNumber"
                          label={t("mediaRequestDetail.authNumber")}
                          size="medium"
                          variant="outlined"
                          fullWidth
                          value={mrdForm.values.authNumber}
                          onChange={mrdForm.handleChange}
                          error={
                            mrdForm.touched.authNumber &&
                            Boolean(mrdForm.errors.authNumber)
                          }
                          helperText={
                            mrdForm.touched.authNumber &&
                            mrdForm.errors.authNumber
                          }
                        />
                      </Grid>

                      {/* Authorization Challenge Question field */}
                      {Boolean(
                        mrdForm.values.authorizationChallengeQuestion
                      ) && (
                        <Grid
                          id="mediaRequestDetailGrid23"
                          item
                          xs={12}
                          md={6}
                          lg={3}
                        >
                          <NonEditableField
                            id="mediaRequestDetailChallengeQuestion"
                            label={t("mediaRequestDetail.challengeQuestion")}
                            value={
                              mrdForm.values?.authorizationChallengeQuestion
                            }
                          />
                        </Grid>
                      )}

                      {/* Authorization Challenge Response field */}
                      {Boolean(
                        mrdForm.values.authorizationChallengeQuestion
                      ) && (
                        <Grid
                          id="mediaRequestDetailGrid24"
                          item
                          xs={12}
                          md={6}
                          lg={3}
                        >
                          <TextField
                            id="mediaRequestDetailChallengeResponse"
                            disabled={
                              mrdForm.values?.customer === 0 ||
                              mrdForm.values?.customer === undefined ||
                              Boolean(mrdForm.values.authNumber)
                            }
                            name="authorizationChallengeResponse"
                            label={t("mediaRequestDetail.challengeResponse")}
                            size="medium"
                            sx={MediaRequestDetailFormStyles.FlexShrinkNoGrow}
                            variant="outlined"
                            type="password"
                            value={
                              mrdForm.values.authorizationChallengeResponse
                            }
                            onChange={mrdForm.handleChange}
                            error={
                              mrdForm.touched.authorizationChallengeResponse &&
                              Boolean(
                                mrdForm.errors.authorizationChallengeResponse
                              )
                            }
                            helperText={
                              mrdForm.touched.authorizationChallengeResponse &&
                              mrdForm.errors.authorizationChallengeResponse
                            }
                          />
                        </Grid>
                      )}

                      {/* Authenticate Button */}
                      <Grid
                        id="mediaRequestDetailGrid25"
                        item
                        xs={12}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Button
                          id="mediaRequestDetailAuthenticateButton"
                          disabled={
                            mrdForm.values?.customer === 0 ||
                            mrdForm.values?.customer === undefined
                          }
                          variant="contained"
                          color="primary"
                          size="medium"
                          sx={{ textTransform: "none" }}
                          onClick={handleAuthorization}
                        >
                          {t("mediaRequestDetail.authenticate")}
                        </Button>
                      </Grid>

                      {/* Authentication failed error message */}
                      {authorizationStatus.isError && (
                        <Grid id="mediaRequestDetailGrid26" item>
                          <Alert
                            id="mediaRequestDetailAuthenticateErrorAlert"
                            severity="error"
                            color="error"
                            icon={<img src={WarningIcon} alt="Warning" />}
                            sx={{ borderRadius: "9px" }}
                          >
                            {t("mediaRequestDetail.authenticateFailed")}
                          </Alert>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <>
                      <Grid
                        id="mediaRequestDetailGrid27"
                        container
                        columnSpacing={spacing.verticalMargin20}
                        item
                        xs={12}
                      >
                        {/* Authorization Bypass Reason field */}
                        <Grid
                          id="mediaRequestDetailGrid28"
                          item
                          xs={12}
                          md={6}
                          lg={4.5}
                        >
                          <TextField
                            id="mediaRequestDetailBypassReason"
                            disabled={
                              mrdForm.values?.customer === 0 ||
                              mrdForm.values?.customer === undefined
                            }
                            name="bypassReason"
                            label={t("mediaRequestDetail.reason")}
                            size="medium"
                            sx={MediaRequestDetailFormStyles.FlexShrinkNoGrow}
                            variant="outlined"
                            multiline
                            rows={2}
                            value={mrdForm.values.bypassReason}
                            onChange={mrdForm.handleChange}
                            error={
                              mrdForm.touched.bypassReason &&
                              Boolean(mrdForm.errors.bypassReason)
                            }
                            helperText={
                              mrdForm.touched.bypassReason &&
                              mrdForm.errors.bypassReason
                            }
                          />
                        </Grid>

                        {/* Bypassed By field */}
                        <Grid
                          id="mediaRequestDetailGrid29"
                          item
                          xs={12}
                          md={6}
                          lg={3}
                        >
                          <Typography
                            id="mediaRequestDetailBypassedByLabel"
                            variant="subtitle1"
                            sx={MediaRequestDetailFormStyles.BypassedByText}
                          >
                            {t("mediaRequestDetail.bypassedBy")}
                          </Typography>
                          <Typography
                            id="mediaRequestDetailBypassedBy"
                            disabled={
                              mrdForm.values?.customer === 0 ||
                              mrdForm.values?.customer === undefined
                            }
                          >
                            {authInfo?.displayName}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Bypass Button */}
                      <Grid id="mediaRequestDetailGrid30" item xs={12}>
                        <Button
                          id="mediaRequestDetailBypassButton"
                          disabled={
                            mrdForm.values?.customer === 0 ||
                            mrdForm.values?.customer === undefined ||
                            !mrdForm.values?.bypassReason
                          }
                          variant="contained"
                          color="primary"
                          size="medium"
                          sx={{ textTransform: "none" }}
                          onClick={handleAuthorization}
                        >
                          {t("mediaRequestDetail.bypassLabel")}
                        </Button>
                      </Grid>
                    </>
                  )}
                </>
              )}
            <Grid id="mediaRequestDetailAuthDivider" item xs={12}>
              <Box sx={MediaRequestDetailFormStyles.DividerBox} />
            </Grid>
          </Grid>

          <Grid
            id="mediaRequestDetailGrid31"
            container
            spacing={spacing.verticalMargin20}
          >
            <Grid
              id="mediaRequestDetailGrid32"
              item
              container
              spacing={spacing.verticalMargin20}
              xs={12}
            >
              {/* Confirmed By field */}
              {pageIsViewOnly && preConfirmed ? (
                <Grid id="mediaRequestDetailGrid33" item xs={12} md={6} lg={3}>
                  <SelectField
                    id="mediaRequestDetailConfirmedBy"
                    name="confirmedBy"
                    label={t("mediaRequestDetail.confirmedByStarred")}
                    options={authorizationPersonnels}
                    value={mrdForm.values?.confirmedBy}
                    disabled
                  />
                </Grid>
              ) : (
                enableConfirmedBy &&
                !(
                  Number(mrdForm.values.requestStatus) ===
                    REQUEST_STATUS.CANCELED ||
                  Number(mrdForm.values.requestStatus) ===
                    REQUEST_STATUS.COMPLETE ||
                  Number(mrdForm.values.requestStatus) ===
                    REQUEST_STATUS.DELETED
                ) && (
                  <Grid
                    id="mediaRequestDetailGrid33"
                    item
                    xs={12}
                    md={6}
                    lg={3}
                  >
                    <Autocomplete
                      id="mediaRequestDetailConfirmedBy"
                      key={resetConfirmedByField}
                      disablePortal
                      size="medium"
                      loading={isAuthorizationPersonnelsLoading}
                      options={authorizationPersonnels}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="confirmedBy"
                          label={t("mediaRequestDetail.confirmedBy")}
                          value={mrdForm.values?.confirmedBy}
                          required={confirmedByRequired}
                          error={
                            mrdForm.touched.confirmedBy &&
                            Boolean(mrdForm.errors.confirmedBy)
                          }
                          helperText={
                            mrdForm.touched.confirmedBy &&
                            mrdForm.errors.confirmedBy
                          }
                        />
                      )}
                      onChange={handleConfirmedByOnChange}
                      onBlur={(e) =>
                        mrdForm.setFieldTouched(e.target.name, true)
                      }
                    />
                  </Grid>
                )
              )}

              {/* Media request confirmation status */}
              {enableConfirmedBy && confirmationStatus.isAuthenticated && (
                <Grid id="mediaRequestDetailGrid47" item xs={12} md={6} lg={3}>
                  <Typography
                    id="mediaRequestDetailAuthenticationStatus"
                    variant="subtitle1"
                    color="success"
                    sx={MediaRequestDetailFormStyles.AuthenticatedText}
                  >
                    <CheckIcon id="icnConfirm" marginRight={"4px"} />
                    {t("mediaRequestDetail.authenticated")}
                  </Typography>
                </Grid>
              )}

              {/* Service Location field */}
              <Grid id="mediaRequestDetailGrid34" item xs={12} md={6} lg={3}>
                <SelectField
                  id="mediaRequestDetailServiceLocation"
                  size="medium"
                  loading={
                    isEligibleLocationsLoading || isServiceLocationsLoading
                  }
                  options={serviceLocations}
                  name="serviceLocation"
                  disabled={
                    mrdForm.values?.customer === 0 ||
                    mrdForm.values?.customer === undefined ||
                    pageIsViewOnly ||
                    mrdForm.values?.requestType ===
                      REQUEST_TYPES.ADD_ON.toString() ||
                    mrdForm.values?.requestType ===
                      REQUEST_TYPES.DISASTER_RECOVERY.toString() ||
                    Number(mrdForm.values?.requestStatus) ===
                      REQUEST_STATUS.CANCELED ||
                    Number(mrdForm.values?.requestStatus) ===
                      REQUEST_STATUS.COMPLETE
                  }
                  label={t("mediaRequestDetail.serviceLocationStarred")}
                  value={mrdForm.values?.serviceLocation}
                  error={
                    mrdForm.touched.serviceLocation &&
                    Boolean(mrdForm.errors.serviceLocation)
                  }
                  helperText={
                    mrdForm.touched.serviceLocation &&
                    mrdForm.errors.serviceLocation
                  }
                  onChange={mrdForm.handleChange}
                  onBlur={(e) => mrdForm.setFieldTouched(e.target.name, true)}
                />
              </Grid>

              {/* Trip Type field */}
              <Grid id="mediaRequestDetailGrid35" item xs={12} md={6} lg={3}>
                {process.env.REACT_APP_CF_REGION !== APP_REGIONS.IMNA &&
                  branchBillingSystem?.billing_system?.toLowerCase().trim() ===
                    ARC_VMS.toLowerCase().trim() &&
                  (Number(mrdForm.values?.requestType) ===
                    REQUEST_TYPES.SCHEDULED_SERVICE ||
                    Number(mrdForm.values?.requestType) ===
                      REQUEST_TYPES.CRITICAL_SPECIAL) && (
                    <SelectField
                      id="mediaRequestDetailTripType"
                      disabled={
                        mrdForm.values?.customer === 0 ||
                        mrdForm.values?.customer === undefined ||
                        Number(mrdForm.values?.requestStatus) ===
                          REQUEST_STATUS.CANCELED ||
                        Number(mrdForm.values?.requestStatus) ===
                          REQUEST_STATUS.COMPLETE ||
                        Number(mrdForm.values?.requestStatus) ===
                          REQUEST_STATUS.DELETED
                      }
                      size="medium"
                      loading={isTripTypesLoading}
                      options={tripTypes}
                      name="tripType"
                      label={
                        mrdForm.values?.requestType ===
                        REQUEST_TYPES.CRITICAL_SPECIAL.toString()
                          ? t("mediaRequestDetail.tripTypeStarred")
                          : t("mediaRequestDetail.tripType")
                      }
                      value={mrdForm.values.tripType}
                      error={
                        mrdForm.touched.tripType &&
                        Boolean(mrdForm.errors.tripType)
                      }
                      helperText={
                        mrdForm.touched.tripType && mrdForm.errors.tripType
                      }
                      onChange={mrdForm.handleChange}
                      onBlur={(e) =>
                        mrdForm.setFieldTouched(e.target.name, true)
                      }
                    />
                  )}
              </Grid>
            </Grid>

            {/* Confirm Request authorization */}
            <Grid
              id="mediaRequestDetailGrid32"
              item
              container
              spacing={spacing.verticalMargin20}
              xs={12}
            >
              {enableConfirmedBy &&
                !preConfirmed &&
                Number(mrdForm.values?.confirmedBy) > 0 &&
                !confirmationStatus.isAuthenticated && (
                  <>
                    {/* Personal Authentication Helper Text */}
                    <Grid id="mediaRequestDetailGrid21" item xs={12}>
                      <Typography
                        id="mediaRequestDetailAuthHelper"
                        variant="body2"
                      >
                        {t("mediaRequestDetail.personnelAuthHelper")}
                      </Typography>
                    </Grid>

                    {/* Confirm Auth Number field */}
                    <Grid
                      id="mediaRequestDetailGrid22"
                      item
                      xs={12}
                      md={6}
                      lg={3}
                    >
                      <TextField
                        id="mediaRequestDetailAuthNumber"
                        disabled={
                          mrdForm.values?.customer === 0 ||
                          mrdForm.values?.customer === undefined ||
                          Boolean(
                            mrdForm.values.confirmAuthorizationChallengeResponse
                          )
                        }
                        name="confirmAuthNumber"
                        label={t("mediaRequestDetail.authNumber")}
                        size="medium"
                        variant="outlined"
                        fullWidth
                        value={mrdForm.values.confirmAuthNumber}
                        onChange={mrdForm.handleChange}
                        error={
                          mrdForm.touched.confirmAuthNumber &&
                          Boolean(mrdForm.errors.confirmAuthNumber)
                        }
                        helperText={
                          mrdForm.touched.confirmAuthNumber &&
                          mrdForm.errors.confirmAuthNumber
                        }
                      />
                    </Grid>

                    {/* Confirm Authorization Challenge Question field */}
                    {Boolean(
                      mrdForm.values.confirmAuthorizationChallengeQuestion
                    ) && (
                      <Grid
                        id="mediaRequestDetailGrid23"
                        item
                        xs={12}
                        md={6}
                        lg={3}
                      >
                        <NonEditableField
                          id="mediaRequestDetailChallengeQuestion"
                          label={t("mediaRequestDetail.challengeQuestion")}
                          value={
                            mrdForm.values
                              ?.confirmAuthorizationChallengeQuestion
                          }
                        />
                      </Grid>
                    )}

                    {/* Confirm Authorization Challenge Response field */}
                    {Boolean(
                      mrdForm.values.confirmAuthorizationChallengeQuestion
                    ) && (
                      <Grid
                        id="mediaRequestDetailGrid24"
                        item
                        xs={12}
                        md={6}
                        lg={3}
                      >
                        <TextField
                          id="mediaRequestDetailChallengeResponse"
                          disabled={
                            mrdForm.values?.customer === 0 ||
                            mrdForm.values?.customer === undefined ||
                            Boolean(mrdForm.values.confirmAuthNumber)
                          }
                          name="confirmAuthorizationChallengeResponse"
                          label={t("mediaRequestDetail.challengeResponse")}
                          size="medium"
                          sx={MediaRequestDetailFormStyles.FlexShrinkNoGrow}
                          variant="outlined"
                          type="password"
                          value={
                            mrdForm.values.confirmAuthorizationChallengeResponse
                          }
                          onChange={mrdForm.handleChange}
                          error={
                            mrdForm.touched
                              .confirmAuthorizationChallengeResponse &&
                            Boolean(
                              mrdForm.errors
                                .confirmAuthorizationChallengeResponse
                            )
                          }
                          helperText={
                            mrdForm.touched
                              .confirmAuthorizationChallengeResponse &&
                            mrdForm.errors.confirmAuthorizationChallengeResponse
                          }
                        />
                      </Grid>
                    )}

                    {/* Confirm Authenticate Button */}
                    <Grid
                      id="mediaRequestDetailGrid25"
                      item
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Button
                        id="mediaRequestDetailConfirmAuthenticateButton"
                        disabled={
                          mrdForm.values?.customer === 0 ||
                          mrdForm.values?.customer === undefined
                        }
                        variant="contained"
                        color="primary"
                        size="medium"
                        sx={{ textTransform: "none" }}
                        onClick={handleAuthorization}
                      >
                        {t("mediaRequestDetail.authenticate")}
                      </Button>
                    </Grid>

                    {/* Confirm Authentication failed error message */}
                    {confirmationStatus.isError && (
                      <Grid id="mediaRequestDetailGrid26" item>
                        <Alert
                          id="mediaRequestDetailAuthenticateErrorAlert"
                          severity="error"
                          color="error"
                          icon={<img src={WarningIcon} alt="Warning" />}
                          sx={{ borderRadius: "9px" }}
                        >
                          {t("mediaRequestDetail.authenticateFailed")}
                        </Alert>
                      </Grid>
                    )}
                  </>
                )}
              <Grid id="mediaRequestDetailAuthDivider" item xs={12}>
                <Box sx={MediaRequestDetailFormStyles.DividerBox} />
              </Grid>
            </Grid>

            <Grid
              id="mediaRequestDetailGrid36"
              item
              container
              spacing={spacing.verticalMargin20}
              xs={12}
            >
              {/* Logged By field */}
              {pageIsViewOnly && mrdForm.values?.loggedBy && (
                <Grid id="mediaRequestDetailGrid37" item xs={12} md={6} lg={3}>
                  <TextField
                    id="mediaRequestDetailLoggedBy"
                    disabled
                    name="loggedBy"
                    label={t("mediaRequestDetail.loggedBy")}
                    size="medium"
                    variant="outlined"
                    fullWidth
                    value={mrdForm.values.loggedBy}
                  />
                </Grid>
              )}

              {/* Logged Date field */}
              {pageIsViewOnly && mrdForm.values?.loggedDate && (
                <Grid id="mediaRequestDetailGrid38" item xs={12} md={6} lg={3}>
                  <TextField
                    id="mediaRequestDetailLoggedDate"
                    disabled
                    name="loggedDate"
                    label={t("mediaRequestDetail.loggedDate")}
                    size="medium"
                    variant="outlined"
                    fullWidth
                    value={mrdForm.values.loggedDate}
                  />
                </Grid>
              )}

              {/* Canceled By field */}
              {pageIsViewOnly &&
                mrdForm.values?.requestStatus ===
                  REQUEST_STATUS.CANCELED.toString() && (
                  <Grid
                    id="mediaRequestDetailGrid39"
                    item
                    xs={12}
                    md={6}
                    lg={3}
                  >
                    <TextField
                      id="mediaRequestDetailCanceledBy"
                      disabled
                      name="canceledBy"
                      label={t("mediaRequestDetail.canceledBy")}
                      size="medium"
                      variant="outlined"
                      fullWidth
                      value={mrdForm.values.canceledBy}
                    />
                  </Grid>
                )}

              {/* Canceled Date field */}
              {pageIsViewOnly &&
                mrdForm.values?.requestStatus ===
                  REQUEST_STATUS.CANCELED.toString() && (
                  <Grid
                    id="mediaRequestDetailGrid40"
                    item
                    xs={12}
                    md={6}
                    lg={3}
                  >
                    <TextField
                      id="mediaRequestDetailCanceledDate"
                      disabled
                      name="canceledDate"
                      label={t("mediaRequestDetail.canceledDate")}
                      size="medium"
                      variant="outlined"
                      fullWidth
                      value={mrdForm.values.canceledDate}
                    />
                  </Grid>
                )}
            </Grid>

            {/* Customer Service Notes field */}
            <Grid id="mediaRequestDetailGrid41" item xs={12} md={6} lg={6}>
              <TextField
                id="mediaRequestDetailCustomerServiceNotes"
                disabled
                name="customerServiceNotes"
                label={t("mediaRequestDetail.customerServiceNotes")}
                size="medium"
                variant="outlined"
                multiline
                rows={2}
                sx={MediaRequestDetailFormStyles.FlexShrinkNoGrow}
                value={mrdForm.values.customerServiceNotes}
              />
            </Grid>

            {/* Customer Authorization Notes */}
            <Grid id="mediaRequestDetailGrid42" item xs={12} md={6} lg={6}>
              <TextField
                id="mediaRequestDetailCustomerAuthorizationNotes"
                disabled
                name="customerAuthorizationNotes"
                label={t("mediaRequestDetail.customerAuthorizationNotes")}
                size="medium"
                variant="outlined"
                multiline
                rows={2}
                sx={MediaRequestDetailFormStyles.FlexShrinkNoGrow}
                value={mrdForm.values.customerAuthorizationNotes}
              />
            </Grid>

            {/* Request Comments title */}
            <Grid id="mediaRequestDetailGrid43" item xs={12}>
              <Typography
                id="mediaRequestDetailRequestCommentTitle"
                variant="h5"
                sx={MediaRequestDetailFormStyles.CommentsHeaderTitle}
              >
                {t("mediaRequestDetail.requestCommentsTitle")}
              </Typography>
            </Grid>

            {/* Iron Mountain comments field */}
            <Grid id="mediaRequestDetailGrid44" item xs={12} md={4}>
              <TextField
                id="mediaRequestDetailIronMountainComments"
                disabled={
                  mrdForm.values?.customer === 0 ||
                  mrdForm.values?.customer === undefined ||
                  (pageIsViewOnly &&
                    (mrdForm?.values?.requestStatus ===
                      REQUEST_STATUS.CANCELED.toString() ||
                      mrdForm?.values?.requestStatus ===
                        REQUEST_STATUS.COMPLETE.toString()))
                }
                name="ironMountainComments"
                label={t("mediaRequestDetail.ironMountainComments")}
                size="medium"
                variant="outlined"
                multiline
                rows={2}
                sx={MediaRequestDetailFormStyles.FlexShrinkNoGrow}
                value={mrdForm.values.ironMountainComments}
                onChange={mrdForm.handleChange}
                onBlur={(e) => mrdForm.setFieldTouched(e.target.name, true)}
                error={
                  mrdForm.touched.ironMountainComments &&
                  Boolean(mrdForm.errors.ironMountainComments)
                }
                helperText={
                  mrdForm.touched.ironMountainComments &&
                  mrdForm.errors.ironMountainComments
                }
              />
            </Grid>

            {/* Customer comments field */}
            <Grid id="mediaRequestDetailGrid45" item xs={12} md={4}>
              <TextField
                id="media_request_customerComments"
                disabled={
                  mrdForm.values?.customer === 0 ||
                  mrdForm.values?.customer === undefined ||
                  (pageIsViewOnly &&
                    (mrdForm?.values?.requestStatus ===
                      REQUEST_STATUS.CANCELED.toString() ||
                      mrdForm?.values?.requestStatus ===
                        REQUEST_STATUS.COMPLETE.toString()))
                }
                name="customerComments"
                label="Customer Comments"
                size="medium"
                variant="outlined"
                multiline
                rows={2}
                sx={MediaRequestDetailFormStyles.FlexShrinkNoGrow}
                value={mrdForm.values.customerComments}
                onChange={mrdForm.handleChange}
                onBlur={(e) => mrdForm.setFieldTouched(e.target.name, true)}
                error={
                  mrdForm.touched.customerComments &&
                  Boolean(mrdForm.errors.customerComments)
                }
                helperText={
                  mrdForm.touched.customerComments &&
                  mrdForm.errors.customerComments
                }
              />
            </Grid>

            {/* Bypass Reason field */}
            <Grid id="mediaRequestDetailGrid46" item xs={12} md={4}>
              <TextField
                id="mediaRequestDetailBypassReasonComment"
                disabled
                name="bypassReasonComment"
                label={t("mediaRequestDetail.bypassReason")}
                size="medium"
                variant="outlined"
                multiline
                rows={2}
                sx={MediaRequestDetailFormStyles.FlexShrinkNoGrow}
                value={mrdForm.values.bypassReasonComment}
              />
            </Grid>
          </Grid>

          {/* Media Request Detail Tabs */}
          <HorizontalTabs
            id="mediaRequestDetailTabs"
            disabled={
              mrdForm.values?.customer === 0 ||
              mrdForm.values?.customer === undefined
            }
            extraItemsForm={extraItemsForm}
            tabs={[
              {
                label: t("mediaRequestDetail.deliverMedia"),
                element: (
                  <DeliverMediaTab
                    requestId={requestId}
                    customerId={Number(mrdForm.values?.customer)}
                    requestType={Number(mrdForm.values.requestType)}
                    requestStatus={Number(mrdForm.values.requestStatus)}
                    isRedelivery={mrdForm.values?.isRedelivery}
                    pageIsViewOnly={pageIsViewOnly}
                  />
                )
              },
              {
                label: t("mediaRequestDetail.issueContainers"),
                disabled: mrdForm.values.isRedelivery,
                element: (
                  <IssueContainersTab
                    requestId={requestId}
                    customerId={Number(mrdForm.values?.customer)}
                    defaultLockType={
                      serviceAuthComments?.default_lock_type_code
                    }
                    extraItemsForm={extraItemsForm}
                    validate={needsValidation}
                    onValidate={setNeedsValidation}
                  />
                )
              },
              {
                label: t("mediaRequestDetail.deleteContainers"),
                disabled: mrdForm.values.isRedelivery,
                element: (
                  <DeleteContainersTab
                    requestId={requestId}
                    customerId={Number(mrdForm.values?.customer)}
                  />
                )
              },
              {
                label: t("mediaRequestDetail.other"),
                disabled: mrdForm.values.isRedelivery,
                element: (
                  <OtherTab
                    requestId={requestId}
                    hasError={
                      mrdForm.touched.otherComment &&
                      Boolean(mrdForm.errors.otherComment)
                    }
                    errorMessage={mrdForm.errors.otherComment}
                  />
                )
              }
            ]}
            styles={{ marginTop: "44px" }}
          />

          {throwError && (
            <Collapse in={throwError}>
              <Alert
                severity="error"
                icon={<img src={ErrorIcon} alt="error" />}
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            </Collapse>
          )}

          {/* Bottom Action Buttons */}
          <Stack
            id="mediaRequestDetailStack1"
            direction="row"
            spacing={spacing.verticalMargin20}
            sx={MediaRequestDetailFormStyles.BottomButtonsContainer}
          >
            {/* Find Request button */}
            <Button
              id="mediaRequestDetailFindRequestButton"
              sx={{
                ...MediaRequestDetailFormStyles.TextTransformNone,
                textDecoration: "none",
                height: "2.25rem",
                borderRadius: "8px",
                m: 1
              }}
              variant="outlined"
              color="primary"
              size="medium"
              type="button"
              onClick={() => {
                if (validateNewAction()) {
                  setFindRequestModalVisible((prevState) => true);
                } else {
                  dispatch(setHaveChanges(true));
                  dispatch(setShowConfirmationModal(true));
                }
              }}
            >
              {t("mediaRequestDetail.findRequest")}
            </Button>

            {/* Assign Transport button */}
            <Button
              id="mediaRequestDetailAssignTransportButton"
              disabled={
                !pageIsViewOnly ||
                haveChanges ||
                Number(mrdForm.values.requestStatus) ===
                  REQUEST_STATUS.CANCELED ||
                Number(mrdForm.values.requestStatus) ===
                  REQUEST_STATUS.DELETED ||
                Number(mrdForm.values.requestStatus) === REQUEST_STATUS.COMPLETE
              }
              sx={{
                ...MediaRequestDetailFormStyles.TextTransformNone,
                textDecoration: "none",
                height: "2.25rem",
                borderRadius: "8px",
                m: 1
              }}
              variant="outlined"
              color="primary"
              size="medium"
              type="button"
              onClick={handleAssignTransportModal}
            >
              {t("mediaRequestDetail.assignTransport")}
            </Button>

            {/* Cancel Request button */}
            <Button
              id="mediaRequestDetailCancelRequestButton"
              disabled={
                !pageIsViewOnly ||
                mrdForm.values?.requestStatus ===
                  REQUEST_STATUS.CANCELED.toString() ||
                mrdForm.values?.requestStatus ===
                  REQUEST_STATUS.COMPLETE.toString()
              }
              sx={{
                ...MediaRequestDetailFormStyles.TextTransformNone,
                height: "2.25rem",
                borderRadius: "8px",
                m: 1
              }}
              variant="outlined"
              color="primary"
              size="medium"
              type="button"
              onClick={async () => await handleCancelRequest()}
            >
              {t("mediaRequestDetail.cancelRequest")}
            </Button>

            {/* Print button */}
            <Button
              id="mediaRequestDetailPrintButton"
              disabled={!pageIsViewOnly || haveChanges}
              sx={{
                ...MediaRequestDetailFormStyles.TextTransformNone,
                height: "2.25rem",
                borderRadius: "8px",
                m: 1
              }}
              variant="outlined"
              color="primary"
              size="medium"
              type="button"
              onClick={() => setPrintModalVisible((prevState) => !prevState)}
            >
              {t("mediaRequestDetail.print")}
            </Button>

            {/* New button */}
            <Button
              id="mediaRequestDetailNewButton"
              component={Link}
              to={`${REQUEST_MODULE_BASE_PATH}/${MEDIA_REQUEST_DETAIL}`}
              sx={{
                ...MediaRequestDetailFormStyles.TextTransformNone,
                height: "2.25rem",
                borderRadius: "8px",
                m: 1
              }}
              variant="outlined"
              color="primary"
              size="medium"
              type="button"
              onClick={(e) => {
                if (haveChanges) {
                  dispatch(setShowConfirmationModal(true));
                } else {
                  handleCreateNewMediaRequest();
                }
              }}
            >
              {t("mediaRequestDetail.new")}
            </Button>

            {/* Save button */}
            <Button
              id="mediaRequestDetailSaveButton"
              disabled={
                mrdForm.values?.customer === 0 ||
                mrdForm.values?.customer === undefined ||
                mrdForm.values?.requestStatus ===
                  REQUEST_STATUS.CANCELED.toString() ||
                (pageIsViewOnly && !haveChanges) ||
                mrdForm.values?.requestStatus ===
                  REQUEST_STATUS.COMPLETE.toString()
              }
              sx={{
                ...MediaRequestDetailFormStyles.TextTransformNone,
                height: "2.25rem",
                borderRadius: "8px",
                m: 1
              }}
              variant="contained"
              color="primary"
              size="medium"
              type="button"
              onClick={() => mrdForm.handleSubmit()}
            >
              {t("mediaRequestDetail.save")}
            </Button>
          </Stack>
        </form>
      </Box>

      {/* Print report modal */}
      {printModalVisible && (
        <DownloadReportModal
          isVisible={printModalVisible}
          setIsVisible={setPrintModalVisible}
          mainDistrictId={currentBranch?.district_id}
          districtName={currentBranch?.name}
          setIsLoading={setPageLoading}
          requestId={requestId}
          customer={
            location.state.mediaRequest?.customer_number?.trim() ||
            VALUE_EMPTY_STRING
          }
          throwError={throwError}
          setThrowError={setThrowError}
          throwErrorMessage={throwErrorMessage}
          setThrowErrorMessage={setThrowErrorMessage}
        />
      )}

      {/* Confirm Cancelling a Media Destruction Modal*/}
      {cancellingMediaDestructionVisible && (
        <CancellingMediaDestructionModal
          isVisible={cancellingMediaDestructionVisible}
          handleButtonClick={handleButtonClick}
        />
      )}

      {/* Cancel Request Require Auth modal */}
      {cancelRequestRequiresAuthModal && (
        <CancelRequestRequiresAuthModal
          open={cancelRequestRequiresAuthModal}
          onClick={handleButtonClick}
        />
      )}

      {/* Cancel request modal */}
      {cancelRequestModalVisible && (
        <CancelRequestModal
          show={cancelRequestModalVisible}
          requestId={requestId}
          cancelModalWarning={cancelModalWarning}
          setCancelModalWarning={setCancelModalWarning}
          requestType={Object.values(REQUEST_TYPES).find(
            (value) => Number(value) === Number(mrdForm.values?.requestType)
          )}
          personnels={authorizationPersonnels}
          timestamp={mrdForm.values?.timestamp}
          onClose={setCancelRequestModalVisible}
          onSubmit={handleCancelRequestModalSubmit}
        />
      )}

      {/* Cancel request check modal */}
      {cancelRequestCheckModal && (
        <CancelRequestWithoutAuthorizationModal
          show={cancelRequestCheckModal}
          requestId={requestId}
          requestType={Object.values(REQUEST_TYPES).find(
            (value) => Number(value) === Number(mrdForm.values?.requestType)
          )}
          timestamp={mrdForm.values?.timestamp}
          onClose={setCancelRequestCheckModal}
          onSubmit={handleCancelRequestModalSubmit}
        />
      )}

      {/* Find request modal */}
      {findRequestModalVisible && (
        <FindRequestModal
          show={findRequestModalVisible}
          onClose={setFindRequestModalVisible}
        />
      )}

      {/* Assign Transport modal */}
      {assignTransportModalVisible && (
        <AssignTransportModal
          show={assignTransportModalVisible}
          requestId={requestId}
          customerId={mrdForm.values.customer}
          requestStatus={mrdForm.values.requestStatus}
          onClose={setAssignTransportModalVisible}
        />
      )}

      {/* In Page => Save Changes Confirmation Modal */}
      {(showConfirmationModal || showPrompt) && (
        <ConfirmPopup
          modalPopupOpen={showConfirmationModal || showPrompt}
          handleYes={() => handleConfirmationOnYes()}
          handleNo={() => handleConfirmationOnNo()}
          showNo={true}
          handleCancel={() => handleConfirmationOnCancel()}
          showCancel={true}
        />
      )}
    </>
  );
};

export default MediaRequestDetailForm;
