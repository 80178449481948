import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import {
  ERROR_TYPES,
  downloadOptionPdf,
  type,
  ymdDateFormat
} from "utils/constants";
import {
  currentStatusId7,
  rpType
} from "utils/constants/container-processing/RunPrepConstants";
import { findErrorMessage, getUserName, printReport } from "utils/helpers";

export default function DownloadReportModal({
  downloadReportModal,
  setDownloadReportModal,
  setIsLoading,
  throwError,
  setThrowError,
  throwErrorMessage,
  setThrowErrorMessage,
  runId,
  selectedServiceDate,
  reportType,
  handleFind,
  runPrepUpdateStatus,
  runStatus,
  tableData,
  runData,
  reportCFUrl,
  setReportCFUrl
}) {
  const { auth, currentBranch } = useSelector(selectAuth);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);

  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.district_id,
        district_id: currentBranch?.district_id,
        run_id: runId,
        run_date:
          typeof selectedServiceDate === type.string
            ? selectedServiceDate
            : selectedServiceDate?.format(ymdDateFormat),
        district_name: currentBranch?.name,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });
      let reportResponse = await printReport(
        reqBody,
        reportType === rpType.runReport
          ? `${CF_URLS.printReports.containerProcessing.runPrep.getmediarunreport}`
          : `${CF_URLS.printReports.containerProcessing.runPrep.runreport}`
      );
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
        setDownloadReportModal(false);
      } else {
        if (runData?.requests_on_run !== "0") {
          if (tableData?.length > 0) {
            if (runStatus?.current_exchange_status_id !== currentStatusId7) {
              await runPrepUpdateStatus();
            }
          } else {
            await runPrepUpdateStatus();
          }
        }
        handleFind();
      }
    } catch (e) {
      setDownloadReportModal(false);
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setThrowError(false);
    setDownloadReportModal(false);
    setReportCFUrl("");
  };

  return (
    <DownloadPopup
      title={t("common.downloadReport")}
      showPrintModal={downloadReportModal}
      cfUrl={reportCFUrl}
      onCancleClick={handleCancel}
      onDownloadClick={handleDownload}
      setSelectedValue={setSelectedValue}
      setThrowError={setThrowError}
      throwError={throwError}
      throwErrorMessage={throwErrorMessage}
    />
  );
}
