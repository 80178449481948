import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  TableContainer,
  Typography
} from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import spacing from "styles/spacing";
import {
  dateFormatWithoutTime,
  DEFAULT_DATA_TABLE_OPTIONS
} from "utils/constants";
import {
  actionTypes,
  bitOne,
  bitZero,
  desc,
  eventchangePage,
  eventchangeRowsPerPage,
  sortingCode
} from "utils/constants/open-media-processing/Research";
import { sortDateColumn } from "utils/helpers";
import { ResearchTableStyles } from "./ResearchTableStyles";

const ResearchDataTable = ({
  customers,
  searchResults,
  disableSaveButton,
  disablePrintButton,
  disableDetailButton,
  disableCreateFileButton,
  setIsUpdateModalOpen,
  confirmBeforePrint,
  handleRowSelection,
  selectedRows,
  setEditIndex,
  dispatch,
  showMediaClosedContainers,
  isUserHasUpdatePermission,
  isUserHasDetailViewPermission,
  isUserHasCreateeFileermission,
  onClickEditSave,
  localeByBranch,
  searchMade,
  saveToPersistBeforeNavigate,
  persistedState,
  resultCount,
  onCreateFileModalOpen,
  setSortingOrder
}) => {
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(
    DEFAULT_DATA_TABLE_OPTIONS.rowsPerPage
  );

  const [page, setPage] = useState(0);

  const handleChangePage = (currentPage) => {
    setPage(currentPage);
  };
  const handleChangeRowsPerPage = (numberOfRows) => {
    setRowsPerPage(numberOfRows);
  };
  const handleEdit = (index) => {
    setEditIndex(searchResults[index]);
    setIsUpdateModalOpen(true);
  };
  useEffect(() => {
    if (persistedState?.page && persistedState?.rowsPerPage) {
      setPage(persistedState?.page);

      setRowsPerPage(persistedState?.rowsPerPage);
    }
  }, [persistedState]);

  const onClickDetailButton = () => {
    if (!isUserHasDetailViewPermission) return;
    saveToPersistBeforeNavigate({ page, rowsPerPage });
    if (searchResults[selectedRows[0]]) {
      navigate(`details/${searchResults[selectedRows[0]]?.open_media_id}`, {
        state: {
          row: searchResults[selectedRows[0]],
          customer: customers?.find(
            (obj) =>
              obj?.number === searchResults[selectedRows[0]]?.customer_number
          ),
          localeByBranch: localeByBranch
        }
      });
    }
  };
  const onClickCreateFile = () => {
    if (!isUserHasCreateeFileermission) return;
    onCreateFileModalOpen();
  };

  const handleSortChange = (column, direction) => {
    setSortingOrder({
      sortColumnCode: sortingCode[column],
      sortDirectionBit: desc === direction ? bitZero : bitOne
    });
  };

  useEffect(() => {
    dispatch({ type: actionTypes.rowSelect, payload: !selectedRows?.length });
    // eslint-disable-next-line
  }, [selectedRows]);

  const columns = [
    {
      name: "",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <IconButton onClick={() => handleEdit(dataIndex)}>
              <EditOutlinedIcon color="primary" />
            </IconButton>
          );
        },
        headerNoWrap: true
      },
      label: ""
    },

    { label: t("openMediaResearch.customerLabel"), name: "customer_number" },
    { label: t("openMediaResearch.mediaNumberLabel"), name: "volser" },
    {
      label: t("openMediaResearch.logicalVaultLabel"),
      name: "logical_vault_code"
    },
    {
      label: t("openMediaResearch.mediaTypeLabel"),
      name: "media_type_type_descr"
    },
    { label: t("openMediaResearch.locationLabel"), name: "location" },
    {
      label: t("openMediaResearch.indefiniteRetensionLabel"),
      name: "active_in_mdr_bit"
    },
    {
      label: t("openMediaResearch.returnDateLabel"),
      name: "return_date",
      options: {
        sortCompare: sortDateColumn(dateFormatWithoutTime),
        sortThirdClickReset: true
      }
    },
    {
      label: t("openMediaResearch.currentStatusLabel"),
      name: "current_status_code"
    },
    { label: t("openMediaResearch.newStatusLabel"), name: "new_status_code" },
    { label: t("openMediaResearch.drpCodeLabel"), name: "drp_code" },
    { label: t("openMediaResearch.descriptionLabel"), name: "descr" },
    { label: "", name: "hasDiscrepancies", options: { display: "false" } }
  ];

  const closedContainersColumns = [
    {
      label: t("openMediaResearch.mediaNumberLabel"),
      name: "media_number"
    },
    {
      label: t("openMediaResearch.containerNumberLabel"),
      name: "container_number"
    }
  ];

  const data = searchResults || [];
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    pagination: data.length > 0,
    rowStyle: {
      fontSize: "10px"
    },
    page: page,
    rowsPerPage: rowsPerPage,
    onColumnSortChange: handleSortChange,
    onTableChange: (action, tableState) => {
      switch (action) {
        case eventchangePage:
          handleChangePage(tableState.page);
          break;
        case eventchangeRowsPerPage:
          handleChangeRowsPerPage(tableState.rowsPerPage);
          break;
        default:
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: data?.length === 0 && (
          <Box pb={spacing.gap}>
            <Typography sx={ResearchTableStyles.noDataText}>
              {t("common.tableTextWithoutInputs")}
            </Typography>
          </Box>
        )
      }
    },
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    onRowSelectionChange: handleRowSelection,
    selectableRows: "single",
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    rowsSelected: selectedRows,
    setRowProps: (row, dataIndex) => {
      const isSelected = selectedRows?.includes(dataIndex);
      const hasDiscrepancies = row[12];
      return {
        style: {
          rowStyle: {
            fontSize: 6
          },
          backgroundColor: getRowBackground(isSelected, hasDiscrepancies)
        }
      };
    },
    customToolbar: () => (
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            id="printBtn"
            onClick={onClickEditSave}
            disabled={isUserHasUpdatePermission ? disableSaveButton : true}
          >
            {t("common.save")}
          </Button>
          <Button
            variant="outlined"
            id="printBtn"
            disabled={disablePrintButton}
            onClick={confirmBeforePrint}
          >
            {t("common.print")}
          </Button>
          <Button
            variant="outlined"
            id="printBtn"
            onClick={onClickDetailButton}
            disabled={
              isUserHasDetailViewPermission ? disableDetailButton : true
            }
          >
            {t("openMediaResearch.detail")}
          </Button>
          <Button
            variant="outlined"
            id="createFileBtn"
            disabled={
              isUserHasCreateeFileermission ? disableCreateFileButton : true
            }
            onClick={onClickCreateFile}
          >
            {t("openMediaResearch.createFile")}
          </Button>
        </Stack>
      </Stack>
    )
  };

  const getRowBackground = (isSelected, hasDiscrepancies) => {
    if (isSelected) {
      return "var(--color-im-light-blue-100)";
    }
    if (hasDiscrepancies) {
      return "var(--color-im-grey-100)";
    }
  };
  return (
    <>
      <Stack mt={spacing.gap}>
        <Paper>
          <TableContainer>
            <MUIDataTable
              columns={
                showMediaClosedContainers ? closedContainersColumns : columns
              }
              options={options}
              data={data}
              title={
                searchMade && (
                  <Typography variant="subtitle1">
                    {resultCount.found !== undefined
                      ? `${resultCount.found} ${
                          resultCount.found > 1
                            ? t("common.recordsFound")
                            : t("openMediaResearch.recordFound")
                        }`
                      : null}
                    .
                    {resultCount.not_found !== undefined
                      ? ` ${resultCount.not_found} ${
                          resultCount.not_found > 1
                            ? t("openMediaResearch.recordsNotFound")
                            : t("openMediaResearch.recordNotFound")
                        }`
                      : null}
                  </Typography>
                )
              }
            />
          </TableContainer>
        </Paper>
        <Box my={spacing.gap}>
          <Typography variant="caption">
            {t("openMediaResearch.locationLegend")}
          </Typography>
        </Box>
      </Stack>
    </>
  );
};
export default ResearchDataTable;
