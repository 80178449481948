import {
  Alert,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { CF_URLS } from "services/api/endpoints";
import { getCustomersQueryData } from "services/api/query";
import { ALL_SELECTION, CLEAR, ERROR_TYPES } from "utils/constants";
import { findErrorMessage, getAuthenticatedUserBranch } from "utils/helpers";

const CustomerAuthorizationList = ({
  isOpen,
  onCloseModal,
  setCommonDownloadModal,
  getCallbackData,
  setReportCFUrl
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([ALL_SELECTION]);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [isDisable, setIsDisable] = useState(true);
  const handlePrint = () => {
    const payload = {
      value: selectedCustomer?.value,
      label: selectedCustomer?.label?.split(" ")[0]
    };
    getCallbackData(payload);
    setCommonDownloadModal(true);
    setReportCFUrl(
      CF_URLS.printReports.reports.customerAuthorizationListReport
    );
  };
  const getCustomers = async () => {
    try {
      setIsLoading(true);
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);
      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id,
          number: number
        }));
        setCustomers((prev) => data);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCustomers();
  }, []);

  const handleCustomerChange = (e, newValue, reason) => {
    if (reason === CLEAR) {
      setSelectedCustomer("");
      setIsDisable(true);
    }
    if (newValue) {
      newValue = { label: newValue.label, value: newValue.value.trim() };

      setSelectedCustomer(newValue);
      setIsDisable(false);
    }
  };
  const customersFieldProps = {
    options: customers,
    handleOnChange: handleCustomerChange,
    value: selectedCustomer,
    required: true,
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}
      <CommonModal
        open={isOpen}
        minWidth={600}
        title={t("reports.customerAuthorizationListTitle")}
        body={
          <>
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}

            <Grid container>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <CustomersField {...customersFieldProps} />
                </FormControl>
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={() => onCloseModal(false)}>
              {t("common.close")}
            </Button>
            <Button
              variant="contained"
              onClick={handlePrint}
              disabled={isDisable}
            >
              {t("common.print")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default CustomerAuthorizationList;
