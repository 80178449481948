import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stateToPersist: {},
  tableDefaults:{}
};

export const mediaRequestSearchSlice = createSlice({
  name: "mediaRequestSearch",
  initialState,
  reducers: {
    saveBeforeNavigateToRequest: (state, { payload }) => {
      state.stateToPersist = payload.state;
      state.tableDefaults = payload.tableDefaults;
    },
    resetRequestPersistedData: () => {
      return { ...initialState };
    }
  }
});

export default mediaRequestSearchSlice.reducer;
