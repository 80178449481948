import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { CustomersField } from "components/core/customer-field/CustomersField";
import { t } from "i18next";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllCustomersQuery } from "services/api";

import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import CommonModal from "components/shared/common-modal/CommonModal";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import {
  getInventoryCompareDifference,
  getMessageFromCode
} from "services/api/query";
import { selectAuth } from "store/slices";
import { inforeducer, initialInfoState } from "store/slices/work-management";
import spacing from "styles/spacing";
import {
  DEFAULT_LOCALE,
  VALUE_N,
  VALUE_Y,
  errorMsgs,
  unauthorized
} from "utils/constants";
import {
  InventoryCompareResultsColumns,
  actionTypes,
  discrepancyTypeCodeMissing,
  discrepancyTypeCodeUnexpected,
  global_attribute_id
} from "utils/constants/open-media-processing/WorkManagement";
import {
  dateTimeFormatByLocale,
  getAuthenticatedUserBranch
} from "utils/helpers";
import WorkManagementDataTable from "../WorkManagementDataTable";
import { InventoryStyles, WorkManagementStyles } from "../WorkManagementStyles";
import InventoryTree from "./InventoryTree";

let customerDefaultOption = { label: t("common.all"), value: "" };
function countResolved(data) {
  const counts = { Y: 0, N: 0 };

  data.forEach((item) => {
    if (item.discrepancy_type_code.trim() === discrepancyTypeCodeUnexpected) {
      counts.Y += 1;
    } else if (
      item.discrepancy_type_code.trim() === discrepancyTypeCodeMissing
    ) {
      counts.N += 1;
    }
  });

  return counts;
}

const Inventory = ({
  updateCanChangeTab,
  changeTabTriggered,
  handleTabChange,
  setChangeTabTriggered,
  localeByBranch
}) => {
  const { currentBranch } = useSelector(selectAuth);
  const [state, dispatch] = useReducer(inforeducer, initialInfoState);
  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });

  const [selectedCustomer, setSelectedCustomer] = useState({
    ...customerDefaultOption
  });

  const [inventoryList, setInventoryList] = useState();
  const [inventoryCompareList, setInventoryCompareList] = useState([]);
  const [focusedInventoryItem, setFocusedInventoryItem] = useState({});
  const [intermediateFocusedItem, setIntermediateFocusedItem] = useState({});

  const [resolvedCount, setResolvedCount] = useState(0);
  const [unReslovedCount, setUnResolvedCount] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [compareLoading, setCompareLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [refreshButtonDisable, setRefreshButtonDisable] = useState(true);
  const [awaitRefresh, setAwaitRefresh] = useState();
  const [showTree, setShowTree] = useState(false);

  // call to cloud function and get data from firestore
  const getResponseData = useCallback(async (requestBody, url, count) => {
    const response = await callToCloudFunction(requestBody, url);
    if (response.data.docId) {
      let data = await getDataFromFirestore(
        response,
        count,
        response.data.docId
      );
      return data;
    } else {
      return true;
    }
  }, []);

  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };

  const handleCustomerChange = (e, newValue) => {
    if (!inventoryCompareList.length) {
      setRefreshButtonDisable(true);
      setSelectedCustomer(newValue);
    }
  };

  const handleCustomerFormFieldClick = (e) => {
    if (inventoryCompareList.length) {
      handleFocusInventoryChange();
    }
  };

  const handleFindButtonClick = async () => {
    if (!selectedCustomer?.value) {
      setSelectedCustomer({ ...customerDefaultOption });
    }
    setInventoryCompareList([]);
    setFocusedInventoryItem({});
    setIntermediateFocusedItem({});
    setResolvedCount(0);
    setUnResolvedCount(0);
    setErrorMessage("");
    const districtId = await getAuthenticatedUserBranch();

    let reqBody = {
      main_district_id: districtId,
      customer_id: selectedCustomer?.value || ""
    };
    let URL = `${CF_URLS.openMediaProcessing.workManagement.inventory.fetchInventoryFile}`;

    try {
      setErrorMessage("");
      setLoading(true);

      let data = await getResponseData(reqBody, URL, 1);
      data = data.data[0];
      setLoading(false);
      setRefreshButtonDisable(false);

      if (data[0]?.error && data[0]?.error !== "0") {
        handleError(data[0].error);
        return;
      }
      setInventoryList(data);
      setShowTree(true);
    } catch (error) {
      setLoading(false);
      setRefreshButtonDisable(false);
      if (error?.response?.data === unauthorized) {
        setErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
      } else {
        setErrorMessage(t("printTapeLabel.systemError"));
      }
    }
  };

  const reset = () => {
    setResolvedCount(0);
    setUnResolvedCount(0);
    setInventoryCompareList([]);
  };

  const handleFocusInventoryChange = useCallback(
    async (item) => {
      if (inventoryCompareList.length) {
        if (
          item?.customer_file_section_id !==
          focusedInventoryItem.customer_file_section_id
        ) {
          const res = await getTheFirestoreErrorMessage(
            errorMsgs.errorCode25286
          );
          dispatch({
            type: actionTypes.setInfo,
            code: errorMsgs.errorCode25286,
            show: true,
            message: res.errorMsg
          });
          setIntermediateFocusedItem(item || {});
        }
      } else {
        if (item) setFocusedInventoryItem(item);
      }
    },
    [inventoryCompareList, focusedInventoryItem]
  );

  useEffect(() => {
    if (changeTabTriggered !== undefined) {
      handleFocusInventoryChange();
    }
    if (inventoryCompareList.length) {
      updateCanChangeTab(false);
    } else {
      updateCanChangeTab(true);
    }
  }, [
    inventoryCompareList,
    updateCanChangeTab,
    changeTabTriggered,
    handleFocusInventoryChange
  ]);

  const handleCompareButtonClick = async (type) => {
    if (compareLoading) return;
    const districtId = await getAuthenticatedUserBranch();
    let reqBody = {
      main_district_id: districtId,
      customer_file_section_id: focusedInventoryItem.customer_file_section_id,
      commit_flag: type
    };
    let URL = `${CF_URLS.openMediaProcessing.workManagement.inventory.performinventorymediacomparison}`;
    try {
      setErrorMessage("");
      setCompareLoading(true);
      let data = await getResponseData(reqBody, URL, 1);
      data = data.data[0];
      setCompareLoading(false);
      let errorCode = data[0]?.error;
      if (errorCode && errorCode !== "0") {
        handleError(data[0].error);
        return;
      }
      if (type === VALUE_N) {
        setInventoryCompareList(data);
        const { Y: resolvedCount, N: unreslovedCount } = countResolved(data);
        setResolvedCount(resolvedCount);
        setUnResolvedCount(unreslovedCount);
      } else {
        if (errorCode && errorCode === "0") {
          // Commit is successfull
          reset();
          refresh();
          resetErrorMessage();
        }
      }
    } catch (error) {
      setCompareLoading(false);
      if (error?.response?.data === unauthorized) {
        setErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
      } else {
        setErrorMessage(t("printTapeLabel.systemError"));
      }
    }
  };

  // Handle error with error code
  const handleError = async (errorCode) => {
    dispatch({ type: actionTypes.reset });
    let { errorMsg } = await getTheFirestoreErrorMessage(errorCode);
    setErrorMessage(errorMsg);
  };

  const handleCommitButtonClick = async () => {
    setLoading(true);
    resetErrorMessage();
    const res = await getTheFirestoreErrorMessage(errorMsgs.errorCode80273);
    dispatch({
      type: actionTypes.setInfo,
      code: errorMsgs.errorCode80273,
      show: true,
      message: res.errorMsg
    });
    setLoading(false);
  };

  const resetErrorMessage = () => {
    setErrorMessage("");
    dispatch({ type: actionTypes.reset });
  };

  const hanldeCommitConfirm = async () => {
    setConfirmLoading(true);
    let attributeData =
      await getInventoryCompareDifference(global_attribute_id);
    let global_attribute_value = attributeData[0].global_attribute_value;
    let countOfCompareList = inventoryCompareList.length;
    if (parseInt(global_attribute_value, 10) < countOfCompareList) {
      const res = await getTheFirestoreErrorMessage(errorMsgs.errorCode25388);
      resetErrorMessage();
      setConfirmLoading(false);
      dispatch({
        type: actionTypes.setInfo,
        code: errorMsgs.errorCode25388,
        show: true,
        message: res.errorMsg
      });
      setInventoryCompareList([]);
    } else {
      handleCompareButtonClick(VALUE_Y);
    }
  };

  const onClickRefresh = () => {
    if (inventoryCompareList.length) {
      setAwaitRefresh(true);
      handleFocusInventoryChange();
    } else {
      refresh();
    }
  };

  const onClickNotCommitAndContinue = () => {
    setInventoryCompareList([]);
    reset();
    setFocusedInventoryItem(intermediateFocusedItem);
    resetErrorMessage();
    tabChangeIfNeeded();
    if (awaitRefresh) {
      refresh();
      setAwaitRefresh(false);
    }
  };

  const refresh = () => {
    handleFindButtonClick();
  };

  const tabChangeIfNeeded = () => {
    if (changeTabTriggered !== undefined) {
      handleTabChange(null, changeTabTriggered, true);
    }
  };

  const onClickClear = () => {
    setSelectedCustomer(null);
    reset();
    setInventoryList();
    resetErrorMessage();
  };

  const getModalContent = () => {
    switch (state.errorCode) {
      case errorMsgs.errorCode80273:
        return (
          <>
            <Button
              variant="outlined"
              onClick={hanldeCommitConfirm}
              disabled={confirmLoading}
              sx={InventoryStyles.compareButton}
            >
              {t("common.yes")}
              {confirmLoading ? (
                <CircularProgress
                  size={18}
                  sx={InventoryStyles.compareButtonLoader}
                />
              ) : null}
            </Button>
            <Button
              variant="contained"
              onClick={resetErrorMessage}
              disabled={confirmLoading}
            >
              {t("common.no")}
            </Button>
          </>
        );

      case errorMsgs.errorCode25388:
        return (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                resetErrorMessage();
                setInventoryCompareList([]);
              }}
            >
              {t("common.yes")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleCompareButtonClick(VALUE_Y);
              }}
            >
              {t("common.no")}
            </Button>
          </>
        );

      case errorMsgs.errorCode25286:
        return (
          <>
            <Button variant="outlined" onClick={handleCommitButtonClick}>
              {t("common.yes")}
            </Button>
            <Button variant="contained" onClick={onClickNotCommitAndContinue}>
              {t("common.no")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                resetErrorMessage();
                setChangeTabTriggered(undefined);
              }}
            >
              {t("common.cancel")}
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  const getCompareButtonStatus = () => {
    return (
      Object.keys(focusedInventoryItem).length === 0 ||
      inventoryCompareList.length
    );
  };

  const tableOptions = {
    sort: true
  };

  const getDateWithFormat = (date) => {
    return date
      ? `${dateTimeFormatByLocale(date, localeByBranch || DEFAULT_LOCALE)}`
      : "-";
  };
  const tableTitleSection = (
    <Box pt={spacing.padding}>
      <Typography variant="h5" style={WorkManagementStyles.titleStyle}>
        {t("workManagement.inventory.tableHeading")}
      </Typography>

      <Stack
        direction="row"
        spacing={10}
        mt={spacing.verticalMargin32}
        mb={spacing.verticalMargin16}
      >
        <Typography variant="body1" style={WorkManagementStyles.titleStyle}>
          {`${t("workManagement.inventory.notInInventory")} : ${
            unReslovedCount || "-"
          }`}
        </Typography>{" "}
        <Typography variant="body1" style={WorkManagementStyles.titleStyle}>
          {`${t("workManagement.inventory.notOnCustomerInventory")} : ${
            resolvedCount || "-"
          }`}
        </Typography>
      </Stack>
    </Box>
  );

  return (
    <>
      {(loading || compareLoading) && <FullCircularLoader />}
      {!!errorMessage && (
        <Box sx={InventoryStyles.errorBox}>
          <Collapse in={!!errorMessage}>
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorMessage("");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMessage}
            </Alert>
          </Collapse>
        </Box>
      )}
      <CommonModal
        open={state.showConfirmModal}
        buttons={getModalContent()}
        title={t("common.confirm")}
        body={<Typography>{state.infoMessage}</Typography>}
      />
      <Grid container sx={InventoryStyles.containerGrid}>
        {/* ----------- Customers Field ----------- */}
        <Grid item md={6} lg={3}>
          <FormControl
            fullWidth
            variant="outlined"
            onClick={handleCustomerFormFieldClick}
          >
            <CustomersField
              options={[
                {
                  label: t("common.all"),
                  value: ""
                },
                ...customers
              ]}
              value={selectedCustomer || null}
              handleOnChange={handleCustomerChange}
            />
          </FormControl>
        </Grid>

        {/* ----------- Clear and Find Buttons ----------- */}

        <Grid item md={12} lg={12}>
          <Stack sx={InventoryStyles.clearFindWrapper}>
            <Button variant="outlined" onClick={onClickClear}>
              {t("common.clear")}
            </Button>
            <Button
              variant="contained"
              onClick={handleFindButtonClick}
              disabled={!refreshButtonDisable}
            >
              {t("common.find")}
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={12} sx={InventoryStyles.mainGridWrapper}>
          <Grid container spacing={2}>
            {/* ----------- Tree Structure ----------- */}

            <Grid item xs={3}>
              <Card sx={WorkManagementStyles.treeCard}>
                <CardContent>
                  {showTree && (
                    <InventoryTree
                      inventoryList={inventoryList || []}
                      handleFocusInventoryChange={handleFocusInventoryChange}
                      hasCompareList={inventoryCompareList.length > 0}
                      localeByBranch={localeByBranch}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>

            {/* ----------- Info Section ----------- */}

            <Grid item xs={9}>
              <Box>
                <Stack flexDirection="row">
                  <Box sx={InventoryStyles.descriptionContainer}>
                    <TextField
                      sx={InventoryStyles.textFieldNoBorder}
                      label={t("common.Customer")}
                      value={focusedInventoryItem.number || "-"}
                    />
                  </Box>
                  <Box sx={InventoryStyles.descriptionContainer}>
                    <TextField
                      sx={InventoryStyles.textFieldNoBorder}
                      label={t("workManagement.inventory.logicalVault")}
                      value={focusedInventoryItem.code || "-"}
                    />
                  </Box>
                </Stack>
              </Box>
              <Divider />
              <Box>
                <Typography
                  variant="h5"
                  sx={WorkManagementStyles.titleStyle}
                  mt={spacing.margingTop20}
                >
                  Selection
                </Typography>
                <Box mt={spacing.margingTop20}>
                  <Stack flexDirection="row">
                    <Box sx={InventoryStyles.descriptionContainer}>
                      <TextField
                        sx={InventoryStyles.textFieldNoBorder}
                        label={t("workManagement.inventory.inventoryFile")}
                        value={getDateWithFormat(
                          focusedInventoryItem.created_datetime
                        )}
                      />
                    </Box>
                    <Box sx={InventoryStyles.descriptionContainer}>
                      <TextField
                        sx={InventoryStyles.textFieldNoBorder}
                        label={t("workManagement.inventory.totalCount")}
                        value={focusedInventoryItem.data_row_count || "-"}
                      />
                    </Box>
                  </Stack>
                </Box>
              </Box>

              {/* ----------- Refresh and Compare Buttons ----------- */}

              <Grid item md={12} lg={12}>
                <Stack flexDirection="row" mt={spacing.margingTop20} gap={2}>
                  <Button
                    variant="outlined"
                    onClick={onClickRefresh}
                    disabled={refreshButtonDisable}
                  >
                    {t("common.refresh")}
                  </Button>
                  <Button
                    variant="contained"
                    disabled={getCompareButtonStatus()}
                    onClick={() => handleCompareButtonClick(VALUE_N)}
                  >
                    Compare
                  </Button>
                </Stack>
              </Grid>

              {/* ----------- Inventory Compare Results Table ----------- */}

              <Grid item md={12} lg={12}>
                <Box mt={spacing.margingTop20}>
                  <WorkManagementDataTable
                    columns={InventoryCompareResultsColumns}
                    tableData={inventoryCompareList.sort((a, b) =>
                      a.volser.localeCompare(b.volser)
                    )}
                    customToolbar={() => (
                      <Button
                        variant="outlined"
                        onClick={handleCommitButtonClick}
                        disabled={!inventoryCompareList.length}
                      >
                        {t("workManagement.inventory.commit")}
                      </Button>
                    )}
                    title={tableTitleSection}
                    customOptions={tableOptions}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Inventory;
