import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  OPEN_MEDIA_AUDIT_SEARCH,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH
} from "routing/Paths";
import {
  clearError,
  updateAuthFormValues,
  updateFormValues
} from "store/slices/open-media-audit-search";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  numberConstants
} from "utils/constants";
import { notCompletedSelection } from "utils/constants/open-media-processing/OpenMediaAuditSearchConstants";
import { verifyConstants } from "utils/constants/open-media-processing/WorkManagement";
import { OpenMediaAuditSearchStyles } from "./OpenMediaAuditSearchStyles";

const OpenMediaAuditSearch = ({
  openMediaAuditSearchForm,
  auditTypes,
  auditStatusList,
  reqList,
  handleReset,
  isFindButtonDisabled,
  allCustomers,
  setIsFindButtonDisabled,
  minDate,
  maxDate,
  today,
  getAuditTypeList,
  onFindClick,
  isAuthenticated,
  authCustomers = [],
  setThrowError,
  setThrowErrorMsg,
  setSearchText,
  handleRequestor,
  initialAuthValues
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { formValues } = useSelector((state) => state.openMediaAuditSearch);

  const clearAuthForm = () => {
    if (!isAuthenticated) {
      dispatch(updateAuthFormValues(initialAuthValues));
    }
  };

  const handleInputChange = (name, value) => {
    const updatedValues = {
      ...formValues,
      auditId: "",
      [name]: value
    };
    dispatch(updateFormValues(updatedValues));
    dispatch(clearError());
    clearAuthForm();
  };
  const handleReqChange = (newValue) => {
    const updatedValues = {
      ...formValues,
      auditId: "",
      reqBy: newValue,
      auditType: ALL_SELECTION
    };
    dispatch(updateFormValues(updatedValues));
    handleRequestor(newValue?.value);
    dispatch(clearError());
    clearAuthForm();
  };
  const handleAuditTypeChange = (newValue) => {
    const updatedValues = {
      ...formValues,
      auditId: "",
      auditType: newValue
    };

    if (
      newValue.value === numberConstants.seven ||
      newValue.value === numberConstants.six
    ) {
      updatedValues.customer = "";
    }

    dispatch(updateFormValues(updatedValues));
    dispatch(clearError());
    clearAuthForm();
  };
  const handleAuditChange = (value) => {
    if (value > numberConstants?.zero) {
      const updatedValues = {
        auditId: value
      };
      dispatch(updateFormValues(updatedValues));
    } else {
      dispatch(updateFormValues({ auditId: null }));
    }
  };

  const onCustomerChange = (event, value) => {
    dispatch(clearError());
    const updatedValues = {
      auditId: "",
      customer: value
    };
    dispatch(updateFormValues(updatedValues));
    clearAuthForm();
  };

  useEffect(() => {
    // if the route state has auditStatus
    if (
      location.state?.auditStatus &&
      location.state?.auditStatus === verifyConstants.lvForListGrid
    ) {
      onFindClick().then(() =>
        window.history.replaceState(
          null,
          "",
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${OPEN_MEDIA_AUDIT_SEARCH}`
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <form>
      <Grid container spacing={spacing.gap}>
        <Grid item xs={12}>
          <Grid container spacing={spacing.gap}>
            <Grid item xs={3}>
              <TextField
                label={t("openMediaAuditSearch.auditId")}
                id="audit-id"
                fullWidth
                disabled={null}
                value={formValues.auditId}
                onChange={(event, newValue) => {
                  handleAuditChange(event.target.value);
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                }}
                inputProps={{ maxLength: 9 }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label={t("openMediaAuditSearch.media")}
                id="media-number"
                fullWidth
                disabled={null}
                value={formValues.media_number}
                onChange={(event, newValue) => {
                  const updatedValues = {
                    ...formValues,
                    media_number: event.target.value
                  };
                  dispatch(updateFormValues(updatedValues));
                  dispatch(clearError());
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="requested-by"
                disablePortal
                name={t("openMediaAuditSearch.reqBy")}
                disabled={null}
                options={reqList}
                size="medium"
                value={formValues.reqBy || t("common.all")}
                onChange={(event, newValue) => {
                  handleReqChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("openMediaAuditSearch.reqBy")}
                  />
                )}
                sx={OpenMediaAuditSearchStyles.fieldStyle}
              />
            </Grid>

            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="audit-type"
                disablePortal
                name={t("openMediaAuditSearch.auditType")}
                disabled={formValues.reqBy === ALL_SELECTION}
                options={auditTypes}
                size="medium"
                value={formValues.auditType || t("common.all")}
                onChange={(event, newValue) => {
                  handleAuditTypeChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("openMediaAuditSearch.auditType")}
                  />
                )}
                sx={OpenMediaAuditSearchStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                componentsProps={{
                  clearIndicator: ArrowDown
                }}
                popupIcon={<ArrowDown />}
                clearIcon={<CloseIcon />}
                id="audit-status"
                disablePortal
                name={t("openMediaAuditSearch.auditStatus")}
                disabled={null}
                options={auditStatusList}
                size="medium"
                value={formValues.auditStatus || notCompletedSelection}
                onChange={(event, newValue) =>
                  handleInputChange("auditStatus", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("openMediaAuditSearch.auditStatus")}
                  />
                )}
                sx={OpenMediaAuditSearchStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomersField
                disabled={
                  formValues.auditType.value === numberConstants.six ||
                  formValues.auditType.value === numberConstants.seven
                }
                options={isAuthenticated ? authCustomers : allCustomers}
                value={formValues.customer}
                handleOnChange={onCustomerChange}
                label={t("containerAuditSearch.customer")}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="audit-date"
                name="auditDate"
                label={t("openMediaAuditSearch.auditDate")}
                value={formValues.auditDate || formValues.auditTo || null}
                minDate={minDate}
                maxDate={today}
                onChange={(value) => {
                  const updatedValues = {
                    ...formValues,
                    auditDate: value
                  };
                  dispatch(updateFormValues(updatedValues));
                  dispatch(clearError());
                }}
                sx={OpenMediaAuditSearchStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="audit-to"
                name="auditTo"
                label={t("openMediaAuditSearch.to")}
                value={formValues.auditTo || formValues.auditDate || null}
                minDate={minDate}
                maxDate={today}
                onChange={(value) => {
                  const updatedValues = {
                    ...formValues,
                    auditTo: value
                  };
                  dispatch(updateFormValues(updatedValues));
                  dispatch(clearError());
                }}
                sx={OpenMediaAuditSearchStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="due-date"
                name="dueDate"
                label={t("openMediaAuditSearch.dueDate")}
                value={formValues.dueDate || formValues.dueDateTo || null}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(value) => {
                  handleInputChange("dueDate", value);
                }}
                sx={OpenMediaAuditSearchStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField
                id="due-to"
                name="dueDateTo"
                label={t("openMediaAuditSearch.to")}
                value={formValues.dueDateTo || formValues.dueDate || null}
                minDate={minDate}
                maxDate={maxDate}
                onChange={(value) => {
                  handleInputChange("dueDateTo", value);
                }}
                sx={OpenMediaAuditSearchStyles.fieldStyle}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="exceptionsOnly"
                    id="exceptions-only"
                    size="small"
                    color="primary"
                    style={OpenMediaAuditSearchStyles?.checkBoxStyle}
                    checked={formValues.exceptionsOnly}
                    onChange={(event) => {
                      if (event && event?.target) {
                        const updatedValues = {
                          auditId: "",
                          exceptionsOnly: event?.target?.checked
                        };
                        dispatch(updateFormValues(updatedValues));
                        dispatch(clearError());
                        clearAuthForm();
                      }
                    }}
                  />
                }
                label={t("openMediaAuditSearch.exceptionsOnly")}
                sx={OpenMediaAuditSearchStyles.fieldStyle}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={spacing.gap}>
            <Grid id="clear-button-grid" item xs="auto">
              <Button
                id="clear-button"
                variant="outlined"
                type="button"
                onClick={handleReset}
              >
                {t("common.clear")}
              </Button>
            </Grid>
            <Grid id="find-button-grid" item xs="auto">
              <Button
                id="find-button"
                variant="contained"
                type="button"
                disabled={isFindButtonDisabled}
                onClick={onFindClick}
              >
                {t("common.find")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default OpenMediaAuditSearch;
