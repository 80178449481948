import CloseIcon from "@mui/icons-material/Close";
import { Alert, Collapse, IconButton, Stack } from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import { CircularLoaderFullPage } from "components/core";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { downloadOptionPdf, ERROR_TYPES } from "utils/constants";
import {
  reportType,
  severityError
} from "utils/constants/reports-module/VaultStatusReport";
import { findErrorMessage, getUserName, printReport } from "utils/helpers";
import AuthForm from "./AuthForm";
import { DetailsStyles } from "./Styles";
import VaultStatusReportForm from "./VaultStatusReportForm";

const VaultStatusReportLayout = ({ setShowTitle, showTitle }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [severity, setSeverity] = useState(severityError);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [throwError, setThrowError] = useState(false);
  const { showAuthSection, isAuthenticated } = useSelector(
    (state) => state.vaultStatusReport
  );
  const { auth } = useSelector(selectAuth);
  const user_name = getUserName(auth?.user);
  const [reportData, setReportData] = useState({});
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [reportCFUrl, setReportCFUrl] = useState("");
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);

  useEffect(() => {
    throwErrorMessage && window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [throwErrorMessage]);

  const handleReportDownload = async () => {
    setIsLoading(true);
    try {
      let reqBody = {
        district_id: reportData?.main_district_id,
        report_type: reportData?.report_type,
        customer: reportData?.customer_id,
        top: reportData?.top,
        target_media_type: reportData?.target_media_type,
        detected_in: reportData?.detected_in,
        discrepancy_type: reportData?.discrepancy_type,
        include_other_open_media_exception:
          reportData?.include_other_open_media_exception,
        customer_id_list: reportData?.customer_id_list,
        start_date: reportData?.from_date,
        end_date: reportData?.to_date,
        customer_facing_flag: reportData?.customer_facing_flag,
        target_available_media_type_for_UI_developer_information: t(
          "reports.media_type_Information"
        ),
        file_section_type_id_list:
          reportData?.report_type === reportType[1] ||
          reportData?.report_type === reportType[3] ||
          reportData?.report_type === reportType[4] ||
          reportData?.report_type === reportType[5]
            ? ""
            : reportData?.file_section_type_id_list,
        system_function_id_list:
          reportData?.report_type === reportType[1] ||
          reportData?.report_type === reportType[3] ||
          reportData?.report_type === reportType[4] ||
          reportData?.report_type === reportType[5]
            ? ""
            : reportData?.system_function_id_list,
        discrepancy_type_code: reportData?.discrepancy_type_code,
        region: reportData.region,

        user_name: user_name,
        report_format: selectedValue?.type?.toLocaleUpperCase()
      };
      let res = await printReport(
        reqBody,
        `${CF_URLS.printReports.reports.vaultStatusReport}`
      );
      if (!res.success) {
        setThrowErrorMessage(res.error);
        setThrowError(true);
      }

      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack gap={spacing.gap}>
      <DownloadPopup
        title={t("common.downloadReport")}
        showPrintModal={showPrintModal}
        cfUrl={reportCFUrl}
        onCancleClick={() => {
          setShowPrintModal(false);
          setReportCFUrl("");
        }}
        onDownloadClick={handleReportDownload}
        throwError={throwError}
        setThrowError={setThrowError}
        throwErrorMessage={throwErrorMessage}
        setSelectedValue={setSelectedValue}
      />
      <AuthForm setLoading={setIsLoading} showBypass={false} />
      {throwErrorMessage && (
        <Collapse in={!!throwErrorMessage}>
          <Alert
            severity={severity}
            sx={DetailsStyles.errorStyle}
            icon={
              <img
                src={severity === severityError ? ErrorIcon : WarningIcon}
                alt="error"
                style={DetailsStyles.iconStyle}
              />
            }
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowErrorMessage(null);
                  setSeverity(severityError);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Collapse>
      )}
      <CircularLoaderFullPage loading={isLoading} />
      {(!showAuthSection || isAuthenticated) && (
        <VaultStatusReportForm
          setLoading={setIsLoading}
          setThrowErrorMessage={setThrowErrorMessage}
          handlePrint={() => {
            setShowPrintModal(true);
            setReportCFUrl(CF_URLS.printReports.reports.vaultStatusReport);
          }}
          setReportData={setReportData}
          setShowTitle={setShowTitle}
          showTitle={showTitle}
          setSeverity={setSeverity}
          isLoading={isLoading}
        />
      )}
    </Stack>
  );
};

export default VaultStatusReportLayout;
