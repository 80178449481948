import CloseIcon from "@mui/icons-material/Close";
import { Alert, Collapse, IconButton, Stack } from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { PageTitle } from "components/shared";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ADMIN_MODULE_BASE_PATH,
  ADMIN_MODULE_BILLING_BASE_PATH,
  RECURRING_CHANGE_SEARCH
} from "routing/Paths";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getCustomersQueryData, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  ERROR_TYPES,
  VALUE_ALL,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y
} from "utils/constants";
import { billingCycleCodeCheckEOM } from "utils/constants/biling-module";
import {
  catalogItem,
  customer,
  finalBillingMonth,
  firstBillingMonth,
  headerDescription,
  headerQuantity,
  reviewMonthly
} from "utils/constants/biling-module/BillingRecurringChargeConstants";
import {
  customerFilter,
  dateFormatToMonthAndYear,
  findErrorMessage,
  formatNumberByLocale,
  getAuthenticatedUserBranch
} from "utils/helpers";
import BillingRecurringChargeModal from "./BillingRecurringChargeModal";
import DownloadReportModal from "./BillingRecurringChargePrintModal";
import BillingRecurringChargeSearchFilter from "./BillingRecurringChargeSearchFilter";
import BillingRecurringChargeTable from "./BillingRecurringChargeTable";

const BillingRecurringChargeLayout = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const { currentBranch, localeByBranch } = useSelector(selectAuth);

  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  // initial values ----------
  const customerSelectionAll = ALL_SELECTION;
  const catalogItemsSelectionAll = ALL_SELECTION;

  const initialValues = {
    customer: t("common.all"),
    catelogItem: t("common.all"),
    activeChargesFilter: t("billingRecurringCharge.activeChargesOnly"),
    displayMarkedForReviewChargesOnly: false
  };

  // react states ----------
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const [catelogItems, setCatelogItems] = useState([catalogItemsSelectionAll]);

  const [customerSelected, setCustomerSelected] = useState(ALL_SELECTION);
  const [catalogItemSelected, setCatalogItemSelected] = useState(
    initialValues.catelogItem
  );
  const [activeChargesFilterSelected, setActiveChargesFilterSelected] =
    useState(initialValues.activeChargesFilter);
  const [displayMarked, setDisplayMarked] = useState(
    initialValues.displayMarkedForReviewChargesOnly
  );

  const [isFindEnabled, setIsFindEnabled] = useState(true);
  const [isFindClicked, setIsFindClicked] = useState(false);
  const [isClearClicked, setIsClearClicked] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const [updateList, setUpdateList] = useState([]);
  const [newChargeList, setNewChargeList] = useState([]);
  const [resultSet, setResultSet] = useState([]);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [currentEOMBillingDate, setCurrentEOMBillingDate] =
    useState(VALUE_EMPTY_STRING);

  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(false);

  const [customers, setCustomers] = useState([]);

  const [createdOrUpdated, setCreatedOrUpdated] = useState(0);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
  const [printRequestBody, setPrintRequestBody] = useState(null);
  const [isAscending, setIsAscending] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [reportCFUrl, setReportCFUrl] = useState("");
  // formik ----------

  const handleSubmit = async (values, actions) => {
    setIsFindClicked(true);
    setIsFindEnabled(false);
    setResultSet([]);

    // Continue with Formik's default form submission logic
    actions.setSubmitting(false);
  };

  const mrdForm = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit
  });

  // useEffects ----------
  useEffect(() => {
    !firstLoad && fetchCurrentEOMBillingCycle();
    // eslint-disable-next-line
  }, [mainDistrictId, firstLoad]);

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      navigate(
        `${ADMIN_MODULE_BASE_PATH}/${ADMIN_MODULE_BILLING_BASE_PATH}/${RECURRING_CHANGE_SEARCH}`
      );
      setIsCreating(true);
      setIsUpdating(false);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (isFindClicked) {
      fetchRecurringTransactions();
    }
    // eslint-disable-next-line
  }, [isFindClicked]);

  useEffect(() => {
    setIsFindEnabled(true);
    setResultSet([]);

    if (customerSelected && mainDistrictId) {
      fetchRecurringCatalogItems();
    }
    // eslint-disable-next-line
  }, [customerSelected, mainDistrictId]);

  // reset the page
  useEffect(() => {
    if (isClearClicked) {
      pageReset();
    }
    // eslint-disable-next-line
  }, [isClearClicked]);

  // CF calls ----------

  const getCustomers = async () => {
    setIsLoading(true);
    try {
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);

      //Filter customers
      result = customerFilter(result)?.filter(
        (cus) =>
          cus.billing_cycle_code?.trim() === billingCycleCodeCheckEOM?.trim()
      );

      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id,
          number: number
        }));

        setCustomers((prev) => data);
      } else {
        setCustomers([]);
        setThrowErrorMessage(ERROR_TYPES.FAILED);
        setThrowError(true);
      }
    } catch (e) {
      setCustomers([]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setFirstLoad(false);
      setIsLoading(false);
    }
  };

  const fetchCurrentEOMBillingCycle = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId
      });

      const data = await getResponseData(
        reqBody,
        CF_URLS.billingRecurringCharge.getCurrentEOMBillingDate,
        1
      );

      if (data?.data) {
        setCurrentEOMBillingDate(data?.data[0][0]?.current_eom_billing_date);
      } else if (data?.error) {
        setCurrentEOMBillingDate(VALUE_EMPTY_STRING);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setCurrentEOMBillingDate(VALUE_EMPTY_STRING);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRecurringTransactions = async () => {
    setIsLoading(true);

    let active_recurring_chargesflag = VALUE_EMPTY_STRING;

    if (activeChargesFilterSelected) {
      if (
        activeChargesFilterSelected ===
        t("billingRecurringCharge.activeChargesOnly")
      ) {
        active_recurring_chargesflag = VALUE_Y;
      }

      if (
        activeChargesFilterSelected ===
        t("billingRecurringCharge.stoppedChargesOnly")
      ) {
        active_recurring_chargesflag = VALUE_N;
      }

      if (
        activeChargesFilterSelected ===
        t("billingRecurringCharge.activeAndStoppedCharges")
      ) {
        active_recurring_chargesflag = VALUE_EMPTY_STRING;
      }
    }

    try {
      const reqBody = {
        main_district_id: mainDistrictId,
        customer_id:
          customerSelected?.value === VALUE_ALL
            ? VALUE_EMPTY_STRING
            : customerSelected?.value,
        catalog_item_code:
          catalogItemSelected?.value === VALUE_ALL ||
          catalogItemSelected === VALUE_ALL
            ? VALUE_EMPTY_STRING
            : catalogItemSelected?.value,
        active_recurring_charges_flag: active_recurring_chargesflag,
        monthly_review_flag: displayMarked ? VALUE_Y : VALUE_EMPTY_STRING,
        recurring_transaction_id: VALUE_EMPTY_STRING
      };
      setPrintRequestBody(reqBody);

      const data = await getResponseData(
        JSON.stringify(reqBody),
        CF_URLS.billingRecurringCharge.fetchRecurringTransaction,
        1
      );

      if (data?.data && data?.data[0]) {
        if (data?.data[0]?.length === 0) {
          setNoResults(true);
          setResultSet([]);
        } else {
          setNoResults(false);
          //Formatting data quantity  to support number format
          const formattedData = data?.data[0]?.map((e) => {
            e.quantity = formatNumberByLocale(e.quantity, localeByBranch);
            return e;
          });

          setResultSet(
            (prev) =>
              formattedData?.sort((a, b) =>
                a.number?.trim().localeCompare(b.number?.trim())
              ) || []
          );
        }
      } else if (data?.error) {
        setNoResults(false);
        setResultSet([]);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setNoResults(false);
      setResultSet([]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
      setIsFindClicked(false);
    }
  };

  const fetchRecurringCatalogItems = async () => {
    setIsLoading(true);

    try {
      const reqBody = JSON.stringify({
        main_district_id: mainDistrictId,
        customer_id:
          customerSelected?.value === VALUE_ALL
            ? VALUE_EMPTY_STRING
            : customerSelected?.value
      });

      const data = await getResponseData(
        reqBody,
        CF_URLS.billingRecurringCharge.getCatalogItems,
        1
      );

      if (data?.data) {
        if (data?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          const mapping = data?.data[0]?.map(
            ({ invoice_line_descr, catalog_item_code }) => ({
              label: invoice_line_descr,
              value: catalog_item_code
            })
          );

          const fillList = [ALL_SELECTION, ...mapping];
          setCatelogItems((prev) => fillList);
        }
      } else if (data?.error) {
        setCatelogItems((prev) => [ALL_SELECTION]);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setCatelogItems((prev) => [ALL_SELECTION]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const findCatalogItemByCode = (code) => {
    try {
      const filteredCatalogItem = catelogItems?.filter(
        (value) => value?.value === code
      );

      return filteredCatalogItem
        ? filteredCatalogItem[0]?.label
        : VALUE_EMPTY_STRING;
    } catch (error) {
      return VALUE_EMPTY_STRING;
    }
  };

  // handler functions ----------
  const handleCustomerChange = (event, newValue) => {
    setNoResults(false);
    if (newValue === null) {
      setCustomerSelected(ALL_SELECTION);
    } else {
      newValue = { label: newValue.label, value: newValue.value?.trim() };

      setCustomerSelected(newValue);
    }
  };

  const getResponseData = async (reqBody, url, resultSetCount) => {
    try {
      const response = await callToCloudFunction(reqBody, url);

      const dataSets = await getDataFromFirestore(
        response,
        resultSetCount,
        response?.data?.docId
      );

      return dataSets;
    } catch (error) {
      return null;
    }
  };

  const pageReset = () => {
    setCustomerSelected(ALL_SELECTION);
    setCatalogItemSelected(initialValues.catelogItem);
    setActiveChargesFilterSelected(initialValues.activeChargesFilter);
    setDisplayMarked(initialValues.displayMarkedForReviewChargesOnly);

    setIsFindEnabled(true);
    setIsFindClicked(false);

    setIsUpdating(false);
    setIsCreating(false);

    setSelectedRow(null);
    setUpdateList([]);
    setNewChargeList([]);
    setResultSet([]);

    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);

    setIsClearClicked(false);
  };

  const handlePrintClick = () => {
    setIsPrintModalOpen(true);
    setReportCFUrl(CF_URLS.printReports.billing.recurringChargeReport);
  };

  // props ----------
  const modalCreateAndUpdateChargeProps = {
    customers,
    customerSelected,
    selectedRow,
    isUpdating,
    isCreating,
    findCatalogItemByCode,
    localeByBranch,
    setIsUpdating,
    setIsCreating,
    mainDistrictId,
    setEditingRowIndex,
    setSelectedRow,
    currentEOMBillingDate,
    setIsLoading,
    setCreatedOrUpdated,
    createdOrUpdated,
    setIsFindClicked,
    resultSet
  };
  const sectionSearchFilterProps = {
    mrdForm,
    customers: [customerSelectionAll, ...customers],
    customerSelected,
    handleCustomerChange,
    catelogItems,
    catalogItemSelected,
    setCatalogItemSelected,
    setDisplayMarked,
    activeChargesFilterSelected,
    setActiveChargesFilterSelected,
    isFindEnabled,
    setIsFindEnabled,
    setResultSet,
    setIsClearClicked,
    displayMarked,
    setNoResults
  };
  const handleSort = (column) => {
    const key = column?.id;
    const newDirection =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction: newDirection });
    if (column?.id === customer) {
      const sorted = [...resultSet]?.sort((a, b) => {
        const comparison = a?.number?.localeCompare(b?.number);
        return isAscending ? comparison : -comparison;
      });
      setResultSet(sorted);
      setIsAscending(!isAscending); // Toggle sort order
    } else if (column?.id === catalogItem) {
      const sorted = [...resultSet]?.sort((a, b) => {
        const comparison = a?.catalog_item_code?.localeCompare(
          b?.catalog_item_code
        );
        return isAscending ? comparison : -comparison;
      });
      setResultSet(sorted);
      setIsAscending(!isAscending);
    } else if (column?.id === headerQuantity) {
      const sorted = [...resultSet]?.sort((a, b) => {
        const comparison = a?.quantity?.localeCompare(b?.quantity);
        return isAscending ? comparison : -comparison;
      });
      setResultSet(sorted);
      setIsAscending(!isAscending);
    } else if (column?.id === headerDescription) {
      const sorted = [...resultSet]?.sort((a, b) => {
        const comparison = a?.descr?.localeCompare(b?.descr);
        return isAscending ? comparison : -comparison;
      });
      setResultSet(sorted);
      setIsAscending(!isAscending);
    } else if (column?.id === firstBillingMonth) {
      const sorted = [...resultSet]?.sort((a, b) => {
        const comparison = dateFormatToMonthAndYear(
          a.first_billing_month,
          localeByBranch
        )?.localeCompare(
          dateFormatToMonthAndYear(b.first_billing_month, localeByBranch)
        );
        return isAscending ? comparison : -comparison;
      });
      setResultSet(sorted);
      setIsAscending(!isAscending);
    } else if (column?.id === finalBillingMonth) {
      const sorted = [...resultSet]?.sort((a, b) => {
        const comparison = dateFormatToMonthAndYear(
          a.final_billing_month,
          localeByBranch
        )?.localeCompare(
          dateFormatToMonthAndYear(b.final_billing_month, localeByBranch)
        );
        return isAscending ? comparison : -comparison;
      });
      setResultSet(sorted);
      setIsAscending(!isAscending);
    } else if (column?.id === reviewMonthly) {
      const sorted = [...resultSet]?.sort((a, b) => {
        const comparison = a?.monthly_review_flag?.localeCompare(
          b?.monthly_review_flag
        );
        return isAscending ? comparison : -comparison;
      });
      setResultSet(sorted);
      setIsAscending(!isAscending);
    }
  };
  const tableSearchResultsProps = {
    handleSort,
    setIsCreating,
    setIsUpdating,
    updateList,
    newChargeList,
    resultSet,
    findCatalogItemByCode,
    setSelectedRow,
    localeByBranch,
    editingRowIndex,
    setEditingRowIndex,
    noResults,
    isFindEnabled,
    handlePrintClick,
    setResultSet,
    sortConfig
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      {/*Report download modal*/}
      {isPrintModalOpen && (
        <DownloadReportModal
          isVisible={isPrintModalOpen}
          setIsVisible={setIsPrintModalOpen}
          printRequestBody={printRequestBody}
          currentBranch={currentBranch}
          setIsLoading={setIsLoading}
          throwError={throwError}
          setThrowError={setThrowError}
          throwErrorMessage={throwErrorMessage}
          setThrowErrorMessage={setThrowErrorMessage}
          reportCFUrl={reportCFUrl}
          setReportCFUrl={setReportCFUrl}
        />
      )}

      <Stack
        direction="column"
        gap={spacing.gap}
        marginTop={spacing.margingTop20}
      >
        {/* ---------- Page Title ----------  */}
        <PageTitle variant="h4" title={t("billingRecurringCharge.pageTitle")} />

        {throwError && !isPrintModalOpen && (
          <Collapse in={throwError}>
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          </Collapse>
        )}

        {/* ---------- Filter Section ----------  */}
        <BillingRecurringChargeSearchFilter {...sectionSearchFilterProps} />

        {/* ---------- Table to Display Search Results and Cache (updated and new rows) ----------  */}
        <BillingRecurringChargeTable {...tableSearchResultsProps} />

        {/* ---------- Modal to Create or Update Recurring Charge ----------  */}
        <BillingRecurringChargeModal {...modalCreateAndUpdateChargeProps} />
      </Stack>
    </>
  );
};

export default BillingRecurringChargeLayout;
