const formCreateAndUpdateChargeStyles = {
  cardContentStack: {
    alignItems: "start",
    marginBottom: "20px",
    minWidth: "720px",
    maxWidth: "720px"
  },
  tfInputLabelPtops: { shrink: true, style: { zIndex: 0 } },
  textFieldSX: { width: "350px", height: "64px" },
  buttonSX: {
    display: "flex",
    padding: "6px 16px !important",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "unset",
    marginLeft: "0px !important"
  },
  errorIcon: {
    width: "22px"
  }
};

const tableSearchResultsAndCacheStyles = {
  mainStack: {
    boxShadow:
      "0px 3px 3px -2px rgba(0, 0, 0, 0.20), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "9px",
    marginBottom: "5px",
    marginTop: "12px"
  },
  topButtonsBox: { display: "flex", justifyContent: "space-between" },
  nextButtonsBox: {
    display: "flex",
    gap: "20px"
  },
  tableContainer: { height: "500px", overflowY: "auto" },
  tableHead: {
    position: "sticky",
    top: 0,
    backgroundColor: "var(--color-white-100)"
  },
  tableCell: { color: "rgba(0,0,0,0.60)", fontWeight: 700 },
  headerCursor: { cursor: "pointer", userSelect: "none" },
  tableRow: {
    "&.Mui-selected": {
      backgroundColor: "var(--color-im-light-blue-100)"
    },
    "&.Mui-selected:hover": {
      backgroundColor: "var(--color-im-light-blue-100)"
    },
    "&:hover": {
      backgroundColor: "var(--color-im-grey-50)"
    }
  },
  tableCellBox: {
    minWidth: "0 !important",
    display: "flex",
    gap: "20px"
  },
  buttons: { textTransform: "none" },
  paper: {
    position: "relative",
    overflowX: "auto",
    boxShadow: "unset",
    borderRadius: "0 0 9px 9px !important"
  },
  tablePagination: { position: "sticky", bottom: 0 },
  tableFooterBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 2
  },
  tableFooterTypo: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.43
  },
  tableFooterFormControl: { marginRight: 2 },
  tableFooterSelect: {
    boxShadow: "none",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: 0
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: 0
    }
  }
};

const commonStyles = {
  widthFixed: { width: "350px" },
  widthFull: { width: "100%" },
  widthFit: { width: "fit-content" },
  alignCenter: { alignItems: "center" },
  pointerCursor: { cursor: "pointer" },
  inputProps: { shrink: true, style: { zIndex: 0 } }
};

const layoutStyles = {
  snackbar: { vertical: "top", horizontal: "right" }
};

const modal = {
  description: { marginBottom: "10px !important" },
  box: { height: "30px" }
};

const printAdjPadding = "0px !important";

export const billingTransactionsStyles = {
  formCreateAndUpdateChargeStyles,
  tableSearchResultsAndCacheStyles,
  commonStyles,
  layoutStyles,
  modal,
  printAdjPadding
};
