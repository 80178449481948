import { Button, FormControlLabel, Grid, Radio } from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { useState } from "react";
import { CF_URLS } from "services/api/endpoints";
import {
  oneChecker,
  twoChecker,
  withOutTransportLocations,
  withTransportLocations
} from "utils/constants/reports-module/Reports";

const EmptyTransportdeficit = ({
  isOpen,
  onCloseModal,
  setCommonDownloadModal,
  getCallbackData,
  setReportCFUrl
}) => {
  const [selectedOption, setSelectedOption] = useState(
    withOutTransportLocations
  );
  // onchange for redio buttons
  const handleChange = (v) => {
    setSelectedOption(v);
  };
  // print button functionality
  const handlePrint = () => {
    getCallbackData({
      transportLocation:
        selectedOption === withOutTransportLocations ? oneChecker : twoChecker
    });
    setReportCFUrl(CF_URLS.printReports.reports.emptyTransportDeficit);
    setCommonDownloadModal(true);
  };

  return (
    <>
      <CommonModal
        open={isOpen}
        minWidth={600}
        title={t("reports.emptyTransportDefilt.title")}
        body={
          <>
            <Grid container>
              <Grid item lg={6} md={6} xs={12}>
                <FormControlLabel
                  value={t(
                    "reports.emptyTransportDefilt.withOutTransportLocations"
                  )}
                  control={
                    <Radio
                      checked={selectedOption === withOutTransportLocations}
                      onChange={() => handleChange(withOutTransportLocations)}
                    />
                  }
                  label={t(
                    "reports.emptyTransportDefilt.withOutTransportLocations"
                  )}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <FormControlLabel
                  value={t(
                    "reports.emptyTransportDefilt.withTransportLocations"
                  )}
                  control={
                    <Radio
                      checked={selectedOption === withTransportLocations}
                      onChange={() => handleChange(withTransportLocations)}
                    />
                  }
                  label={t(
                    "reports.emptyTransportDefilt.withTransportLocations"
                  )}
                />
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={() => onCloseModal(false)}>
              {t("common.close")}
            </Button>
            <Button variant="contained" onClick={handlePrint}>
              {t("common.print")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default EmptyTransportdeficit;
