import {
  Alert,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Typography
} from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { CF_URLS } from "services/api/endpoints";
import { getCustomersQueryData, getMessageFromCode } from "services/api/query";
import {
  ALL_SELECTION,
  CLEAR,
  ERROR_TYPES,
  errorMsgs,
  VALUE_EMPTY_STRING
} from "utils/constants";
import {
  active,
  allkey,
  deleteKey,
  detail,
  summary
} from "utils/constants/reports-module/Reports";
import { findErrorMessage, getAuthenticatedUserBranch } from "utils/helpers";

const ContianerInventory = ({
  isOpen,
  onCloseModal,
  setCommonDownloadModal,
  getCallbackData,
  setReportCFUrl,
  callbackStateData
}) => {
  // initial States
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([ALL_SELECTION]);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [selectedFromCustomer, setSelectedFromCustomer] = useState("");
  const [selectedToCustomer, setSelectedToCustomer] = useState("");
  const [selectedOption, setSelectedOption] = useState(detail);
  const [selectedStatus, setSelectedStatus] = useState(active);

  // Convert numbers to integers for comparison
  const fromNumber = (selectedFromCustomer.number || "").trim();
  const toNumber = (selectedToCustomer.number || "").trim();

  // print button fuctionality
  const handlePrint = async () => {
    if (fromNumber > toNumber) {
      let errorMsg = await getMessageFromCode(errorMsgs.errorCode20456);
      let replacedText = errorMsg[0]?.descr.replace(
        "|",
        selectedFromCustomer?.number
      );
      let replacedCustomer = replacedText.replace(
        "|",
        `${selectedToCustomer?.number} ${t(
          "reports.continerInventory.errorMessage"
        )}`
      );
      setThrowErrorMessage(replacedCustomer);
      setThrowError(true);
    } else {
      setCommonDownloadModal(true);
      setReportCFUrl(
        callbackStateData?.selectedReports === detail
          ? CF_URLS.printReports.reports.containerInventoryDetailReport
          : CF_URLS.printReports.reports.containerinventorysummaryreport
      );
    }
  };

  // get all Customers
  const getCustomers = async () => {
    try {
      setIsLoading(true);
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);
      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id,
          number: number
        }));
        setCustomers((prev) => data);
      }
      setIsLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCustomers();
  }, []);

  // From Feild Customers change
  const handleFromCustomerChange = (e, newValue, reason) => {
    if (reason === CLEAR) {
      setSelectedFromCustomer(VALUE_EMPTY_STRING);
      setThrowError(false);
    }
    if (newValue) {
      newValue = {
        label: newValue.label,
        value: newValue.value.trim(),
        number: newValue.number
      };
      setSelectedFromCustomer(newValue);
      setSelectedToCustomer(newValue);
      setThrowError(false);
    }
  };

  // To Feild Customers change
  const handleToCustomerChange = (e, newValue, reason) => {
    if (reason === CLEAR) {
      setSelectedToCustomer(VALUE_EMPTY_STRING);
      setThrowError(false);
    }

    if (newValue) {
      newValue = {
        label: newValue.label,
        value: newValue.value.trim(),
        number: newValue.number
      };
      setSelectedToCustomer(newValue);
      setThrowError(false);
    }
  };

  // From Field Props
  const fromCustomersFieldProps = {
    options: customers,
    handleOnChange: handleFromCustomerChange,
    value: selectedFromCustomer,
    labelValue: t("reports.continerInventory.customerFrom"),
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };

  // To Field Props
  const toCustomersFieldProps = {
    options: customers,
    handleOnChange: handleToCustomerChange,
    value: selectedToCustomer,
    labelValue: t("reports.continerInventory.customerTo"),
    isOptionEqualToValue: (option, value) =>
      option?.value === value?.value && option?.label === value?.label
  };
  // handeChange for radio buttons
  const handleChange = (val) => {
    setSelectedOption(val);
    if (val === summary) {
      setSelectedStatus(active);
    }
  };
  const handleStatusChange = (data) => {
    setSelectedStatus(data);
  };
  useEffect(() => {
    let propDate = {
      selectedReports: selectedOption,
      customerFrom: selectedFromCustomer?.number,
      customerTo: selectedToCustomer?.number,
      containerStatus: selectedStatus
    };
    getCallbackData(propDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedOption,
    selectedToCustomer,
    selectedFromCustomer,
    selectedStatus
  ]);

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}

      <CommonModal
        open={isOpen}
        minWidth={600}
        title={t("reports.continerInventory.title")}
        body={
          <>
            {throwError && (
              <Collapse in={throwError}>
                <Alert
                  severity="error"
                  icon={<img src={ErrorIcon} alt="error" />}
                  action={
                    <IconButton
                      aria-label={t("common.close")}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setThrowError(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {throwErrorMessage}
                </Alert>
              </Collapse>
            )}

            <Typography> {t("reports.continerInventory.report")}</Typography>
            <br />
            <Grid container>
              <Grid item lg={3} md={3} xs={12}>
                <FormControlLabel
                  value={t("reports.continerInventory.detail")}
                  control={
                    <Radio
                      checked={selectedOption === detail}
                      onChange={() => handleChange(detail)}
                    />
                  }
                  label={t("reports.continerInventory.detail")}
                />
              </Grid>
              <Grid item lg={3} md={3} xs={12}>
                <FormControlLabel
                  value={t("reports.continerInventory.summary")}
                  control={
                    <Radio
                      checked={selectedOption === summary}
                      onChange={() => handleChange(summary)}
                    />
                  }
                  label={t("reports.continerInventory.summary")}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={4}>
              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <CustomersField {...fromCustomersFieldProps} />
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <FormControl fullWidth>
                  <CustomersField {...toCustomersFieldProps} />
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <Typography>
              {t("reports.continerInventory.containerStatus")}
            </Typography>
            <Grid container>
              <Grid item lg={3} md={3} xs={12}>
                <FormControlLabel
                  value={t("reports.continerInventory.active")}
                  control={
                    <Radio
                      checked={selectedStatus === active}
                      onChange={() => handleStatusChange(active)}
                    />
                  }
                  label={t("reports.continerInventory.active")}
                />
              </Grid>

              <Grid item lg={3} md={3} xs={12}>
                <FormControlLabel
                  value={t("reports.continerInventory.deleted")}
                  control={
                    <Radio
                      checked={selectedStatus === deleteKey}
                      onChange={() => handleStatusChange(deleteKey)}
                    />
                  }
                  label={t("reports.continerInventory.delete")}
                />
              </Grid>
              <Grid item lg={3} md={3} xs={12}>
                <FormControlLabel
                  value={t("reports.continerInventory.all")}
                  control={
                    <Radio
                      disabled={selectedOption === summary}
                      checked={selectedStatus === allkey}
                      onChange={() => handleStatusChange(allkey)}
                    />
                  }
                  label={t("reports.continerInventory.all")}
                />
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={() => onCloseModal(false)}>
              {t("common.close")}
            </Button>
            <Button variant="contained" onClick={handlePrint}>
              {t("common.print")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default ContianerInventory;
