import { ChevronLeft, ExpandMore, HighlightOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";

import FilterListIcon from "@mui/icons-material/FilterList";
import { Alert, Chip, IconButton, TableCell } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { DEFAULT_LOCALE } from "@mui/x-date-pickers";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { FullCircularLoader } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CF_URLS } from "services/api/endpoints";
import {
  getMessageFromCode,
  getProgramSecurityList,
  getServiceIndicatorType
} from "services/api/query";
import { selectAuth } from "store/slices";
import {
  setDefaultBranchValues,
  setIsRefreshRequired,
  setShowAuthSection,
  updateAuthFormValues,
  updatePreferencesAuthentication
} from "store/slices/customer-preferences";
import spacing from "styles/spacing";
import {
  dateFormatWithTime,
  DEFAULT_DATA_TABLE_OPTIONS,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  errorMsgs,
  hyphen,
  N_CHECKER,
  NO,
  padStartLength,
  unauthorized,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y,
  YES
} from "utils/constants";

import { useGetAllCustomersQuery } from "services/api";
import { getPreferenceTypesPayloadForReport } from "utils/constants/customer-information/CustomerPreference";
import {
  all,
  filterIndicatorOptions,
  getTheFirestoreErrorMessage,
  interBranch,
  permissionId325,
  preferenceServiceIndicatorTypeMap,
  preferencesServiceIndicatorTypeIds,
  preferenceXML,
  preferenceXMLValues,
  systemId,
  thirdParty,
  thirdPartyId,
  zero
} from "utils/constants/customer-information/ServiceIndicatore";
import {
  escapeXml,
  escapeXmlForReport,
  findErrorMessage,
  formatDateByLocale,
  getResponseData
} from "utils/helpers";
import PreferencesLayout from "../preferences-main/PreferencesLayout";
import PreferencesReoprtDownloadModal from "../PreferencesReoprtDownloadModal";
import { PreferencesStyles } from "../PreferencesStyles";
import FilterModal from "./FilterModal";

const ServiceIndicator = () => {
  const { state = {} } = useLocation();
  const navigate = useNavigate();
  const customer_id = state?.customer_id;
  const { authFormValues, defaultAuthBranchValues, vendorResponseList } =
    useSelector((state) => state.customerPreferences);
  const { auth, localeByBranch, currentBranch } = useSelector(selectAuth);
  const [filterValue, setFilterValue] = useState(filterIndicatorOptions[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const filterModalOpen = Boolean(anchorEl);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [serviceTypeData, setServiceTypeData] = useState([]);
  const [serviceType, setServiceType] = useState(null);
  const [thirdPartShipper, setThirdPartyShipper] = useState(null);
  const [serviceLevel, setServiceLevel] = useState("");
  const [shipper, setShipper] = useState("");
  const [billToCustomer, setBillToCustomer] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [parentData, setParentData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [enableThirdParty, setEnableThirdParty] = useState(true);
  const [enableShipper, setEnableShipper] = useState(true);
  const [enableSave, setEnableSave] = useState(false);

  const { preference_value, children } =
    defaultAuthBranchValues?.prefenceFilteredTableData.find(
      (obj) => obj.preference_type_id === preferencesServiceIndicatorTypeIds[0]
    ) || {};
  const { isAuthenticated } = useSelector((state) => state.customerPreferences);

  const dispatch = useDispatch();
  const handleFilterModalOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterModalClose = () => {
    setAnchorEl(null);
  };
  const handleFilterModalReset = () => {
    setFilterValue(filterIndicatorOptions[0]);
    handleFilterModalChange(filterIndicatorOptions[0]);
  };
  let nestedColumns = [
    {
      label: t("preferences.name"),
      name: "name"
    },
    {
      label: t("preferences.previousValueLabel"),
      name: "previousValue"
    },
    {
      label: t("preferences.newValueLabel"),
      name: "newValue"
    }
  ];
  const nestedTableOption = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: false,
    elevation: 1
  };

  const handleError = async (errorCode, replaceText) => {
    let { errorMsg } = await getTheFirestoreErrorMessage(errorCode);

    if (errorMsg) {
      if (replaceText) {
        setThrowError(true);
        setThrowErrorMessage(errorMsg?.replace("|", replaceText));
      } else {
        setThrowError(true);
        setThrowErrorMessage(errorMsg);
      }
    }
  };
  const getServiceType = async () => {
    try {
      const data = await getServiceIndicatorType();
      const selectServiceType = data.find(
        (obj) => obj?.id === preference_value
      );
      serviceType === null && setServiceType(selectServiceType);
      if (
        (isAuthenticated &&
          selectServiceType?.service_indicator_type_id === thirdPartyId &&
          enableSave) ||
        (isAuthenticated &&
          selectServiceType?.service_indicator_type_id === interBranch &&
          enableSave)
      ) {
        setEnableThirdParty(false);
      } else {
        setEnableThirdParty(true);
      }
      setServiceTypeData(data);
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const handleCatchError = (error) => {
    if (error?.response?.data === unauthorized) {
      setThrowError(true);
      setThrowErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
    } else {
      setThrowError(true);
      setThrowErrorMessage(t("common.systemErrorMessage"));
    }
  };

  const getStatus = async(preference_type_id, value) => {
    const serviceTypesData = await getServiceIndicatorType();
    if (preference_type_id === preferencesServiceIndicatorTypeIds[0]) {
      return serviceTypesData?.find(
        (obj) => obj?.service_indicator_type_id === value
      )?.descr;
    } else if (preference_type_id === preferencesServiceIndicatorTypeIds[1]) {
      return vendorResponseList?.find(
        (obj) => obj?.transportation_vendor_id === value
      )?.name;
    } else if (preference_type_id === preferencesServiceIndicatorTypeIds[3]) {
      return value === VALUE_N ? NO : value === VALUE_Y ? YES : value;
    } else if (preference_type_id === preferencesServiceIndicatorTypeIds[4]) {
      return value;
    } else {
      return value;
    }
  };
  const getPreferencesHistory = async () => {
    try {
      setLoading(true);
      const res = await getResponseData(
        {
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          customer_id,
          preference_type_id_list: preferencesServiceIndicatorTypeIds.reduce(
            (a, key) => (a += key.padStart(padStartLength, " ")),
            ""
          )
        },
        CF_URLS.customerPreferences.getCustomerPreferenceHistory,
        3
      );
      let data = res.data;
      if (data[0] && data[0][0]?.error) {
        handleError(data[0][0]?.error);
        return;
      }
      let parent = data[0];
      let child = data[1];
      const parentDataPromises = parent
        .sort((a, b) => {
          return dayjs(b.change_history_datetime).isAfter(
            dayjs(a.change_history_datetime)
          )
            ? 1
            : -1;
        })
        .map(async (parentItem) => {
          const formattedDate = await formatDateByLocale(
            dayjs(parentItem.change_history_datetime).utc(),
            localeByBranch || DEFAULT_LOCALE,
            true,
            true
          );

          const filteredChildren = child.filter(
            (childItem) =>
              childItem.change_history_id === parentItem.change_history_id
          );

          const changedValues = filteredChildren
            .map(
              (childItem) =>
                preferenceServiceIndicatorTypeMap[childItem.preference_type_id]
            )
            .sort((a, b) => a.localeCompare(b))
            .join(", ");
          return {
            date: formattedDate,
            imEmployee: parentItem.employee_last_name
              ? `${parentItem.employee_last_name}, ${parentItem.employee_first_name}`
              : "",
            authorizedBy: `${parentItem.personnel_last_name} ${parentItem.personnel_first_name}`,
            changedValues: changedValues,
            change_history_id: parentItem.change_history_id,
            children: filteredChildren
              .map(async(childItem) => {
                let previousValue = await getStatus(
                  childItem.preference_type_id,
                  childItem.old_value
                );
                let newValue =await getStatus(
                  childItem.preference_type_id,
                  childItem.new_value
                );
                return {
                  name: preferenceServiceIndicatorTypeMap[
                    childItem.preference_type_id
                  ],
                  previousValue,
                  newValue
                };
              })
          };
        });
      Promise.all(parentDataPromises).then(async(resolvedParentData) => {
        //resolving child promises
        const resolvedData = await Promise.all(
          resolvedParentData.map(async (item) => {
            if (Array.isArray(item.children)) {
              item.children = await Promise.all(
                item.children.map(async (child) => {
                  return child instanceof Promise ? await child : child;
                })
              );
            }
            return item;
          })
        );
        // sorting child history array
        const sortedChildData = resolvedData.map(item => ({
          ...item,
          children: item.children.sort((a, b) => a.name.localeCompare(b.name))
        }));
        setParentData(sortedChildData);
        setFilterData(sortedChildData);
        setLoading(false);
      });
    } catch (error) {
      handleCatchError(error);
    }
  };

  const checkIfPersonnelHasPermission = async (customerPermissions) => {
    let personnalPermission = true;
    // Map to extract role_ids for the current customer
    let roleIds = customerPermissions.map((role) => role.role_id);

    //Add role_ids to uniqueRoleIds array, avoiding duplicates
    let uniqueIds = [...new Set(roleIds)];
    const idRelatedValuesFor325 = await getProgramSecurityList(
      uniqueIds,
      authFormValues?.branch,
      permissionId325
    );
    if (idRelatedValuesFor325?.length > 0) {
      personnalPermission = true;
    } else {
      if (isAuthenticated) {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode64097);
        setThrowError(true);
        setThrowErrorMessage(errDesc[0]?.descr);
        clearAuth();
      }
      personnalPermission = false;
    }
    return personnalPermission;
  };
  const getInitialPersonnelDetails = async () => {
    try {
      let reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        personnel_id: defaultAuthBranchValues?.personnel_id,
        customer_facing: N_CHECKER
      };

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.personnelDetail?.personnelinformationandpassedpersonnelid}`,
        4
      );
      let customerPermissions = data?.data[2];

      let hasPermission = customerPermissions.length
        ? await checkIfPersonnelHasPermission(customerPermissions)
        : false;
      return hasPermission;
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await getServiceType();
    };
    fetchData();
    //eslint-disable-next-line
  }, [enableSave]);
  useEffect(() => {
    const fetchData = async () => {
      setServiceLevel(
        children?.find(
          (obj) =>
            obj?.preference_type_id === preferencesServiceIndicatorTypeIds[2]
        )?.preference_value
      );
      setShipper(
        children?.find(
          (obj) =>
            obj?.preference_type_id === preferencesServiceIndicatorTypeIds[4]
        )?.preference_value || ""
      );

      setBillToCustomer(
        children?.find(
          (obj) =>
            obj?.preference_type_id === preferencesServiceIndicatorTypeIds[3]
        )?.preference_value === VALUE_Y
      );

      if (
        children?.find(
          (obj) =>
            obj?.preference_type_id === preferencesServiceIndicatorTypeIds[3]
        )?.preference_value === VALUE_Y &&
        isAuthenticated &&
        enableSave
      ) {
        setEnableShipper(false);
      }
      getPreferencesHistory();
    };
    fetchData();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (
      children?.find(
        (obj) =>
          obj?.preference_type_id === preferencesServiceIndicatorTypeIds[3]
      )?.preference_value === VALUE_Y &&
      isAuthenticated &&
      enableSave
    ) {
      setEnableShipper(false);
    }
    //eslint-disable-next-line
  }, [enableSave]);
  useEffect(() => {
    setServiceLevel(
      children?.find(
        (obj) =>
          obj?.preference_type_id === preferencesServiceIndicatorTypeIds[2]
      )?.preference_value
    );

    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    const getThirdParty = localStorage.getItem(thirdParty);
    if (preference_value === thirdPartyId) {
      setThirdPartyShipper(JSON.parse(getThirdParty));
    }
  }, [preference_value]);

  useEffect(() => {
    if (state?.thirdPartyData?.transportation_vendor_id) {
      setThirdPartyShipper(state?.thirdPartyData);
    }
    //eslint-disable-next-line
  }, [state?.thirdPartyData?.transportation_vendor_id]);

  useEffect(() => {
    getInitialPersonnelDetails();

    if (enableSave) {
      if (isAuthenticated) {
        if (
          serviceType?.service_indicator_type_id === thirdPartyId ||
          serviceType?.service_indicator_type_id === interBranch
        ) {
          if (billToCustomer) {
            setEnableShipper(false);
          }
          setEnableThirdParty(false);
        }
      } else {
        setEnableShipper(true);
        setEnableThirdParty(true);
      }
    }
    //eslint-disable-next-line
  }, [isAuthenticated]);
  const handleServiceType = (_, val) => {
    setServiceType(val);
    if (
      (isAuthenticated && val?.service_indicator_type_id === thirdPartyId) ||
      (isAuthenticated && val?.service_indicator_type_id === interBranch)
    ) {
      setEnableThirdParty(false);
    } else {
      setEnableThirdParty(true);
    }
    setThirdPartyShipper(null);
    setShipper("");
    setServiceLevel("");
    setEnableShipper(true);
    setBillToCustomer(false);
  };
  const handleThirdPartyShipper = (_, val) => {
    setThirdPartyShipper(val);
    setBillToCustomer(false);
  };
  const getValueWithPreferenceId = (pId) => {
    switch (pId) {
      case preferencesServiceIndicatorTypeIds[0]:
        return serviceType.service_indicator_type_id || "";
      case preferencesServiceIndicatorTypeIds[1]:
        return thirdPartShipper?.transportation_vendor_id || "";
      case preferencesServiceIndicatorTypeIds[2]:
        return escapeXml(serviceLevel) || "";
      case preferencesServiceIndicatorTypeIds[3]:
        return billToCustomer ? VALUE_Y : VALUE_N;
      case preferencesServiceIndicatorTypeIds[4]:
        return escapeXml(shipper) || "";
      default:
        break;
    }
  };

  let initialAuthValues = {
    customer: VALUE_EMPTY_STRING,
    personnel: VALUE_EMPTY_STRING,
    authNumber: VALUE_EMPTY_STRING,
    challengeQuestion: VALUE_EMPTY_STRING,
    challengeResponse: VALUE_EMPTY_STRING,
    bypassMessage: VALUE_EMPTY_STRING
  };

  useEffect(() => {
    if (!isAuthenticated && enableSave) {
      setEnableSave(false);
    }
    //eslint-disable-next-line
  }, [isAuthenticated]);
  const clearAuth = async () => {
    if (isAuthenticated) {
      dispatch(updateAuthFormValues(initialAuthValues));
      dispatch(updatePreferencesAuthentication(false));
      dispatch(setShowAuthSection(false));
      dispatch(
        setDefaultBranchValues({
          isAuthenticated: false,
          authenticatateBranch: []
        })
      );
    }
    setEnableSave(false);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      if (isAuthenticated) {
        if (enableSave) {
          if (
            (serviceType?.service_indicator_type_id === thirdPartyId &&
              !thirdPartShipper) ||
            (serviceType?.service_indicator_type_id === interBranch &&
              !thirdPartShipper)
          ) {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
            setThrowError(true);
            setThrowErrorMessage(
              errDesc[0]?.descr.replace(
                "|",
                t("preferences.serviceIndicator.3rdPartyShipper")
              )
            );
            return;
          }
          if (
            (serviceType?.service_indicator_type_id === thirdPartyId &&
              !serviceLevel) ||
            (serviceType?.service_indicator_type_id === interBranch &&
              !serviceLevel)
          ) {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
            setThrowError(true);
            setThrowErrorMessage(
              errDesc[0]?.descr.replace(
                "|",
                t("preferences.serviceIndicator.serviceLevel")
              )
            );
            return;
          }
          if (
            (serviceType?.service_indicator_type_id === thirdPartyId &&
              billToCustomer &&
              !shipper) ||
            (serviceType?.service_indicator_type_id === interBranch &&
              billToCustomer &&
              !shipper)
          ) {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode20259);
            setThrowError(true);
            setThrowErrorMessage(
              errDesc[0]?.descr.replace(
                "|",
                t("preferences.serviceIndicator.shipper")
              )
            );
            return;
          }
          const reqBody = {
            main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
            customer_id,
            employee_id: auth?.user?.id,
            personnel_id: authFormValues?.personnel?.value,
            system_id: systemId,
            preferences_xml: preferenceXML.replace("|", () => {
              return preferencesServiceIndicatorTypeIds
                .map((key) => {
                  return preferenceXMLValues
                    .replace(/\\/g, "")
                    .replace("|", key)
                    .replace("|", getValueWithPreferenceId(key));
                })
                .join("");
            }),
            auth_bypass_reason: authFormValues?.bypassMessage
          };
          const res = await getResponseData(
            reqBody,
            CF_URLS.customerPreferences.updateCustomerPreferences,
            3
          );
          const data = res.data;
          if (data[0]) {
            let code = data[0][0]?.error;
            if (code === zero) {
              dispatch(setIsRefreshRequired(true));
              let { errorMsg } = await getTheFirestoreErrorMessage(
                errorMsgs.errorCode64095
              );
              setMessageSuccess(
                errorMsg
                  .replace(
                    "|",
                    preferenceServiceIndicatorTypeMap[
                      preferencesServiceIndicatorTypeIds[0]
                    ]
                  )
                  .replace("|", dayjs().utc().format(dateFormatWithTime))
              );
              localStorage.setItem(
                thirdParty,
                JSON.stringify(thirdPartShipper)
              );
              getPreferencesHistory();
              setThrowError(false);
              setThrowErrorMessage(null);
              setEnableShipper(true);
              clearAuth();
              if (
                serviceType?.service_indicator_type_id !== thirdPartyId ||
                serviceType?.service_indicator_type_id !== interBranch
              ) {
                setEnableShipper(true);
                setEnableThirdParty(true);
              }
              return;
            }
            return;
          }
        } else {
          setEnableSave(true);
        }
        // Validate Service Level
      } else {
        dispatch(setShowAuthSection(true));
        setEnableSave(true);
      }
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoading(false);
    }
  };
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    pagination: true,
    textLabels: {
      body: {
        noMatch: (
          <Box pb={spacing.gap}>
            <Typography style={PreferencesStyles.noDataText}>
              {t("common.noRecordsFound")}
            </Typography>
          </Box>
        )
      }
    },
    expandableRows: true,
    expandableRowsHeader: false,
    renderExpandableRow: (rowData, rowMeta) => {
      const parentItem = parentData[rowMeta.dataIndex];
      return (
        <TableRow>
          <TableCell colSpan={5} sx={PreferencesStyles.nestedTableCell}>
            <MUIDataTable
              columns={nestedColumns}
              options={nestedTableOption}
              data={parentItem?.children}
            />
          </TableCell>
        </TableRow>
      );
    },
    customToolbar: () => {
      return (
        <>
          <IconButton
            onClick={handleFilterModalOpen}
            title="Filter"
            id="filter-button"
          >
            <FilterListIcon />
          </IconButton>
          <FilterModal
            open={filterModalOpen}
            anchorEl={anchorEl}
            filterValue={filterValue}
            handleClose={handleFilterModalClose}
            handleChange={handleFilterModalChange}
            handleReset={handleFilterModalReset}
          />
        </>
      );
    }
  };
  let columns = [
    {
      label: t("preferences.dateLabel"),
      name: "date"
    },
    {
      label: t("preferences.imEmployee"),
      name: "imEmployee"
    },
    {
      label: t("preferences.authorizedBy"),
      name: "authorizedBy"
    },
    {
      label: t("preferences.changedValueLabel"),
      name: "changedValues"
    }
  ];

  const handleFilterModalChange = (value) => {
    setFilterValue(value);
    filterDatas(value);
  };
  const filterDatas = (filterValue) => {
    if (filterValue.value === all) {
      setFilterData(parentData);
    } else {
      const filtered = parentData.filter((item) =>
        item.changedValues?.includes(filterValue.label)
      );
      setFilterData(filtered);
    }
  };
  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  const customerName = customers.find(
    (obj) => obj?.value === customer_id
  )?.number;

  const onAuthenticatedCallerCliked = () => {
    navigate(-1);
    dispatch(setIsRefreshRequired(true));
  };
  const topTextFieldReportsPayload = [
    {
      key: t("preferences.customerPreferenceTypes23"),
      value: serviceType ? serviceType.descr : ""
    },
    {
      key: t("preferences.customerPreferenceTypes25"),
      value: !thirdPartShipper ? "" : thirdPartShipper?.name
    },
    {
      key: t("preferences.customerPreferenceTypes26"),
      value: !serviceLevel ? "" : escapeXmlForReport(serviceLevel)
    },
    {
      key: t("preferences.customerPreferenceTypes27"),
      value: billToCustomer ? t("common.yes") : t("common.no")
    },
    {
      key: t("preferences.customerPreferenceTypes28").replace(
        t("common.hash"),
        t("preferences.payloadPersentageTwentyThree")
      ),
      value: !shipper ? "" : escapeXmlForReport(shipper)
    }
  ];
  const filteredPreferenceTypes = useMemo(() => {
    return getPreferenceTypesPayloadForReport().filter((type) =>
      preferencesServiceIndicatorTypeIds.includes(type.preference_type_id)
    );
  }, []);

  return (
    <PreferencesLayout showBypass={true}>
      <Stack
        direction="column"
        gap={spacing.verticalMargin20}
        mb={spacing.verticalMargin32}
        mt={spacing.verticalMargin16}
      >
        {messageSuccess && (
          <SuccessPopup message={messageSuccess} close={setMessageSuccess} />
        )}
        {loading && <FullCircularLoader />}
        {throwError && (
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                  setThrowErrorMessage(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        )}
        <Grid container spacing={spacing.horizontalMargin20}>
          <Grid item xs={12}>
            <Box mb={spacing.verticalMargin20}>
              <Typography variant="subtitle1" color="textSecondary">
                {t("common.customer")}
              </Typography>
              <Typography variant="subtitle1">
                {customerName || hyphen}
              </Typography>
            </Box>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={PreferencesStyles.titleStyle}
              mb={spacing.verticalMargin20}
            >
              {t("preferences.serviceIndicatorTitle")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <Autocomplete
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              clearIcon={<GridCloseIcon />}
              id="serviceIndicatorType"
              name="serviceIndicatorType"
              options={serviceTypeData}
              size="medium"
              fullWidth
              onChange={handleServiceType}
              value={serviceType}
              getOptionLabel={(option) => option.descr}
              disabled={!enableSave}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("preferences.serviceIndicator.serviceIndicatorType")}
                />
              )}
            />{" "}
          </Grid>
          <Grid item xs={12} sm={3}>
            {" "}
            <Autocomplete
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              clearIcon={<GridCloseIcon />}
              id="3rdPartyShipper"
              name="3rdPartyShipper"
              options={vendorResponseList}
              size="medium"
              fullWidth
              onChange={handleThirdPartyShipper}
              getOptionLabel={(option) => option.name}
              value={thirdPartShipper}
              disabled={enableThirdParty}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    !enableThirdParty
                      ? t("preferences.serviceIndicator.3rdPartyShipper*")
                      : t("preferences.serviceIndicator.3rdPartyShipper")
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            {" "}
            <TextField
              id="serviceLevel"
              name="serviceLevel"
              placeholder={t("preferences.serviceIndicator.serviceLevel")}
              label={
                !enableThirdParty
                  ? t("preferences.serviceIndicator.serviceLevel*")
                  : t("preferences.serviceIndicator.serviceLevel")
              }
              size="medium"
              fullWidth
              disabled={enableThirdParty}
              value={serviceLevel}
              inputProps={{
                maxLength: 70 // Enforce max length at the input level
              }}
              onChange={(e) => setServiceLevel(e?.target?.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            {" "}
            <TextField
              id="shipper"
              name="shipper"
              size="medium"
              placeholder={t("preferences.serviceIndicator.shipper")}
              label={
                !enableShipper
                  ? t("preferences.serviceIndicator.shipper*")
                  : t("preferences.serviceIndicator.shipper")
              }
              fullWidth
              disabled={enableShipper}
              value={shipper}
              inputProps={{
                maxLength: 70 // Enforce max length at the input level
              }}
              onChange={(e) => setShipper(e?.target?.value)}
            />
          </Grid>
        </Grid>
        <Box px={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={billToCustomer}
                onChange={() => {
                  if (billToCustomer) {
                    setBillToCustomer(false);
                    setShipper("");
                    setEnableShipper(true);
                  } else {
                    setBillToCustomer(true);
                    setEnableShipper(false);
                  }
                }}
              />
            }
            disabled={enableThirdParty}
            label={t("preferences.serviceIndicator.billToCustomer")}
          />
        </Box>
        <Accordion sx={PreferencesStyles.accordian}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography
              variant="subtitle1"
              style={PreferencesStyles.subTitleStyle}
            >
              {t("preferences.history")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={PreferencesStyles.accordiaDetail}>
            <TableContainer sx={PreferencesStyles.tableContainer}>
              <MUIDataTable
                columns={columns}
                data={filterData}
                options={options}
                title={
                  filterValue.value !== all && (
                    <Chip
                      label={filterValue.label}
                      sx={PreferencesStyles.chipColor}
                      variant="outlined"
                      onDelete={() =>
                        handleFilterModalChange(filterIndicatorOptions[0])
                      }
                      deleteIcon={
                        <HighlightOff sx={PreferencesStyles.chipDeleteIcon} />
                      }
                      size="small"
                    />
                  )
                }
              />
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Divider />
        <Stack mt={spacing.gap} gap={spacing.gap} direction="row">
          <Button
            variant="outlined"
            sx={PreferencesStyles.backButton}
            onClick={onAuthenticatedCallerCliked}
          >
            <ChevronLeft /> {t("common.back")}
          </Button>

          <Button variant="outlined" onClick={() => setOpenDownloadModal(true)}>
            {t("common.print")}
          </Button>

          <Button variant="contained" onClick={() => handleSave()}>
            {enableSave ? t("common.save") : t("common.edit")}
          </Button>
        </Stack>
      </Stack>

      <PreferencesReoprtDownloadModal
        title={t("common.downloadReport")}
        url={CF_URLS.printReports.customerInformation.serviceIndicatorReport}
        showPrintModal={openDownloadModal}
        setCloseModal={setOpenDownloadModal}
        setIsLoading={setLoading}
        customerId={customer_id}
        topTextFieldReportsPayload={topTextFieldReportsPayload}
        preferenceTypeId={preferencesServiceIndicatorTypeIds.reduce(
          (a, key) => (a += key.padStart(padStartLength, " ")),
          ""
        )}
        preferenceTypesPayloadForReport={filteredPreferenceTypes}
      />
    </PreferencesLayout>
  );
};
export default ServiceIndicator;
