import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { default as ErrorIcon } from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, SelectField } from "components/core";
import BranchField from "components/core/branch-field/BranchField";
import withLoading from "components/hoc/withLoading";
import { FormikProvider, useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import DownloadPopup from "components/core/modal-components/DownloadPopup";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import { ERROR_TYPES, Y_CHECKER, downloadOptionPdf } from "utils/constants";
import { billingCycle } from "utils/constants/biling-module";
import {
  billDateFormatByLocale,
  findErrorMessage,
  getUserName,
  printReport
} from "utils/helpers";
import { DefaultPricingUsageReport } from "./DefaultPricingUsageReport.style";
import { defaultPricingSchema } from "./schema";

function MediaRequestPickupDetail() {
  const initialValues = {
    selectedBranch: "",
    selectedBillDate: ""
  };
  const { auth, currentBranch, localeByBranch } = useSelector(selectAuth);
  let allLocations = useSelector(({ auth }) => auth?.allLocations);

  //current selected branch
  const selectedCurrentBranch = currentBranch?.district_id;

  //formik with inital values and validator
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: defaultPricingSchema
  });

  const branchPrevious = useRef();

  const { t } = useTranslation();
  const navigate = useNavigate();
  //branch
  const [branch, setBranch] = useState([]);
  //bill date
  const [billDates, setBillDate] = useState([]);
  //error alert
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  //disable the print button
  const [printDisable, setPrintDisable] = useState(true);
  //disable district field
  const [disableDistrict, setDisableDistrict] = useState(false);
  //handle print modal
  const [openPrintModal, setOpenPrintModal] = useState(false);
  //loading
  const [isLoading, setIsLoading] = useState(false);
  // print format type
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);

  const [reportCFUrl, setReportCFUrl] = useState();
  //print modal
  const handleOpenPrintModal = () => setOpenPrintModal(true);
  const handleClosePrintModal = () => {
    setAlert(false);
    setOpenPrintModal(false);
    setReportCFUrl("");
  };

  useEffect(() => {
    //checking the branch is changed, if so navigate to dashboard
    if (branchPrevious.current !== selectedCurrentBranch) {
      branchPrevious.current !== undefined && navigate("/");
    }
    branchPrevious.current = selectedCurrentBranch;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCurrentBranch]);

  useEffect(() => {
    //setting the branch values
    const allBranches = allLocations
      .map((branch) => ({
        value: branch.district_id,
        label: branch.database_name.toUpperCase(),
        id: branch.district_id
      }))
      ?.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
    setBranch(allBranches);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLocations]);

  const handleBillingInterfaceLogonCF = async () => {
    try {
      setIsLoading(true);
      const reqBody = JSON.stringify({
        main_district_id: selectedCurrentBranch,
        login: auth?.user?.login
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.defaultPricingUsageReport.billingInterfaceLogon
      );

      const res = await getDataFromFirestore(response, 1, response.data.docId);
      if (res.data) {
        //if the customer logon
        if (res.data[0]?.[0].valid === Y_CHECKER) {
          setPrintDisable(true);
        } else {
          setDisableDistrict(true);
          setPrintDisable(false);
          formik.setFieldValue("selectedBranch", selectedCurrentBranch);
          handleBillDateCF(selectedCurrentBranch);
        }
      } else if (res.error) {
        setAlertMessage(res.error);
        setAlert(true);
      }
    } catch (e) {
      setAlertMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (auth?.user?.login && selectedCurrentBranch) {
      handleBillingInterfaceLogonCF();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBillDateCF = async (district_id) => {
    setIsLoading(true);
    //getting the bill date
    try {
      const reqBody = JSON.stringify({
        main_district_id: selectedCurrentBranch,
        district_id: district_id,
        billing_cycle_code: billingCycle.eom
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.defaultPricingUsageReport.getPastSixMonthSendDates
      );
      const res = await getDataFromFirestore(response, 1, response.data.docId);
      if (res.data) {
        //if the customer logon
        if (res?.data[0]?.length > 0) {
          const sortedDates = res?.data[0]?.sort(function (a, b) {
            const dateA = new Date(a.billing_date);
            const dateB = new Date(b.billing_date);
            return dateA - dateB;
          });
          const formattedBillDate = await formattedBillDates(sortedDates);

          const mappedDates = formattedBillDate?.map((i) => ({
            label: i,
            value: i
          }));

          const billDates = mappedDates?.map((item, idx) => {
            return {
              ...item,
              billing_date: sortedDates[idx].billing_date
            };
          });

          formik.setFieldValue(
            "selectedBillDate",
            billDates[billDates.length - 1]
          );

          setBillDate(billDates);
          setPrintDisable(true);
        }
      } else if (res.error) {
        setAlertMessage(res.error);
        setAlert(true);
      }
    } catch (e) {
      setAlertMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  const formattedBillDates = async (dates) => {
    const processedDates = await Promise.all(
      dates?.map((date) =>
        billDateFormatByLocale(date.billing_date, localeByBranch)
      )
    );

    return processedDates;
  };

  //handle district on change
  const handleDistrictChange = (e) => {
    formik.setFieldValue("selectedBranch", e.target?.value);
    handleBillDateCF(e.target?.value);
  };
  //handle print
  const handlePrint = async () => {
    setIsLoading(true);

    //getting the details according to the billing date
    try {
      const reqBody = JSON.stringify({
        main_district_id: selectedCurrentBranch,
        district_id: formik.values.selectedBranch,
        billing_date:
          formik.values.selectedBillDate?.billing_date?.split("T")[0]
      });
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.defaultPricingUsageReport.reportDefaultCatalogBilling
      );
      const res = await getDataFromFirestore(response, 1, response.data.docId);

      if (res.error) {
        setAlertMessage(res.error);
        setAlert(true);
      } else {
        setReportCFUrl(CF_URLS.printReports.billing.defaultPricingReport);
        handleOpenPrintModal();
      }
      setIsLoading(false);
    } catch (e) {
      setAlertMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setAlert(true);
    }
  };

  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setAlert(false);
      const requestBody = JSON.stringify({
        main_district_id: selectedCurrentBranch,
        district_id: formik.values.selectedBranch,
        billing_date:
          formik.values.selectedBillDate?.billing_date?.split("T")[0],
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });
      let reportResponse = await printReport(
        requestBody,
        `${CF_URLS.printReports.billing.defaultPricingReport}`
      );
      if (!reportResponse.success) {
        setAlertMessage(reportResponse.error);
        setAlert(true);
      }
    } catch (e) {
      setAlertMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}
      <form>
        <>
          <Grid container gap={spacing.horizontalMargin20}>
            <Stack sx={DefaultPricingUsageReport.TitleStyle}>
              <Typography variant="h4">
                {t("defaultPricingUsageReport.pageTitle")}
              </Typography>
            </Stack>
            {/* Error Field */}
            {alert && (
              <Grid item xs={12}>
                <Alert
                  severity="error"
                  id="button-wraning"
                  icon={<img src={ErrorIcon} alt="Warning" />}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      id="button-wraning-close-btn"
                      onClick={() => {
                        setAlert(false);
                        setAlertMessage("");
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {alertMessage}
                </Alert>
              </Grid>
            )}

            <Grid container spacing={spacing.verticalMargin20}>
              <Grid item xs={3}>
                <BranchField
                  name="branch"
                  id="branch"
                  options={branch}
                  value={formik.values.selectedBranch}
                  error={Boolean(formik.errors.selectedBranch)}
                  onBlur={formik.handleBlur}
                  helperText={formik.errors.selectedBranch}
                  onChange={handleDistrictChange}
                  disabled={disableDistrict}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectField
                  error={formik.touched.billDate}
                  helperText={formik.touched.billDate}
                  label={t("defaultPricingUsageReport.billdate")}
                  id="bill-Date"
                  name="bill-Date"
                  value={
                    formik.values.selectedBillDate.value ||
                    formik.values.selectedBillDate
                  }
                  options={billDates}
                  onChange={(e, newValue) => {
                    formik.setFieldValue("selectedBillDate", newValue);
                  }}
                  objectSelection={true}
                  disabled={!formik.values.selectedBranch}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handlePrint}
                  disabled={
                    printDisable &&
                    !(
                      Boolean(formik.values.selectedBranch) &&
                      Boolean(
                        formik.values.selectedBillDate.value ||
                          formik.values.selectedBillDate
                      )
                    )
                  }
                  id="print-button"
                >
                  {t("pickup.print")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <DownloadPopup
            title={t("defaultPricingUsageReport.downloadTitle")}
            showPrintModal={openPrintModal}
            cfUrl={reportCFUrl}
            onCancleClick={handleClosePrintModal}
            onDownloadClick={handleDownload}
            setSelectedValue={setSelectedValue}
          />
        </>
      </form>
    </FormikProvider>
  );
}

export default withLoading(MediaRequestPickupDetail);
