import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack
} from "@mui/material";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import AuditAuthForm from "components/shared/auth/AuditAuthSection";
import AuthenticationCallerButton from "components/shared/auth/AuthenticationCallerButton";
import SuccessAuthSection from "components/shared/auth/SuccessAuthSection";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CONTAINER_INVENTORY_AUDIT_DETAIL,
  CONTAINER_INVENTORY_AUDIT_SEARCH,
  CONTAINER_INVENTORY_VIEW_CAR,
  DISCREPANCY_MANAGEMENT,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH
} from "routing/Paths";
import {
  useGetAllCustomersQuery,
  useGetPersonnelAuthQuery,
  useGetValidateChallengeResponseMutation
} from "services/api";
import { CF_URLS } from "services/api/endpoints";
import {
  getAudiType,
  getDistricts,
  getMessageFromCode,
  getRequestorTypeData
} from "services/api/query";
import { selectAuth, setAuthenticationState } from "store/slices";
import {
  clearWarning,
  setSearchResults,
  updateAuthFormValues,
  updateContainerAuthentication,
  updateSearchFormValues
} from "store/slices/container-processing";
import {
  clearError,
  resetForm,
  setIsContainerReload,
  setLoading,
  updateFormValues
} from "store/slices/container-processing/containerAuditDetails";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  DEFAULT_LOCALE_CODE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  SUPPORTED_LANGUAGES,
  SYSTEM_ID_SecureBaseWeb,
  VALUE_EMPTY_STRING,
  VALUE_N,
  Y_CHECKER,
  authSection,
  dateFormat,
  dateFormatWithTime,
  dayMonthYearTimeFormat,
  downloadOptionPdf,
  errorMsgs,
  numberConstants
} from "utils/constants";

import DownloadPopup from "components/core/modal-components/DownloadPopup";
import CommonModal from "components/shared/common-modal/CommonModal";
import { getAuthorizationPersonnelsAuthentications } from "services/common";
import {
  setError,
  setWarning
} from "store/slices/container-processing/containerAuditDetails";
import {
  EMPTY_SELECTION,
  checkFilterOptions,
  defaultStatus,
  emailRegex,
  globalAttributeTypeIdCalFuture,
  handleGetnameFormatting,
  programSecurityCode299,
  programSecurityCode306,
  statusList
} from "utils/constants/container-processing/ContainerAuditConstants";
import {
  findErrorMessage,
  generateSHA256Hash,
  getGlobalAttributeValue,
  getLanguageId,
  getResponseData,
  getSortedData,
  getUserName,
  printReport,
  sendEmail
} from "utils/helpers";
import {
  checkAuditStatus,
  containerAuditDetailConst,
  getTheEmailBody,
  getTheFirestoreErrorMessage,
  initialValues,
  maxCommentLength,
  severityError,
  severityWarning,
  specialCharRex,
  system_id
} from "../../../utils/constants/container-processing/ContainerAuditConstants";
import { DetailsStyles } from "./ContainerAuditDetailStyles";
import ContainerAuditForm from "./ContainerAuditForm";
import DetailTable from "./DetailTable";
import EmailPublish from "./EmailPublish";
import MediaCount from "./MediaCount";

const ContainerAuditDetailScreen = ({ setIsEmail, isEmail }) => {
  const {
    currentBranch,
    auth,
    timeZoneOffset,
    error,
    isError,
    localeByBranch,
    allLocations
  } = useSelector(selectAuth);
  const location = useLocation();
  const navigate = useNavigate();
  const containerAuditId = location?.state?.id;
  const currentPath = location?.pathname;
  const {
    formValues,
    isLoading,
    throwError,
    throwErrorMsg,
    isContainerReload
  } = useSelector((state) => state.containerAuditDetails);

  const { authFormValues, isAuthenticated } = useSelector(
    (state) => state.containerAuditSearch
  );

  const dispatch = useDispatch();
  const languageLocaleId = getLanguageId();
  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: authFormValues?.personnel?.value
  });
  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  // getting the Customers on branch change in authentication
  const { data: authenticatedCustomers = [] } = useGetAllCustomersQuery({
    mainDistrictId: authFormValues?.branch
  });
  const programSecurityList = auth?.user?.program_list;
  const programSecurityListArray = programSecurityList.split(",").map(Number);
  const isBranchReq = programSecurityList.includes(programSecurityCode306);
  const isButtonAccessible = programSecurityList.includes(
    programSecurityCode299
  );
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);
  const auditDetailForm = useFormik({
    initialValues: initialValues
  });

  const [requestedListBy, setRequestedListBy] = useState([]);
  const [auditTypeList, setAuditTypeList] = useState([]);
  const [openCancel, setOpenCancel] = useState(false);
  const [cancelText, setCancelText] = useState("");
  const [allBranches, setAllBranches] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [showAuthenticationSection, setShowAuthenticationSection] =
    useState(false);
  const [isAuthenticationError, setIsAuthenticationError] = useState(false);
  const [valuePersonnelAuth] = useState(authSection?.personnelAuth);
  const [viewData, setViewData] = useState("");
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});
  const [emailRecipientsList, setEmailRecipientsList] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [auditDetails, setAuditDetails] = useState("");
  const [authCustomers, setAuthCustomers] = useState([]);
  const [maxDate, setMaxDate] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [severity, setSeverity] = useState(severityError);
  const [viewMode, setViewMode] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [today, setToday] = useState(dayjs());
  const [reportCFUrl, setReportCFUrl] = useState("");

  let initialAuthValues = {
    branch: currentBranch?.district_id,
    customer: VALUE_EMPTY_STRING,
    personnel: VALUE_EMPTY_STRING,
    authNumber: VALUE_EMPTY_STRING,
    challengeQuestion: VALUE_EMPTY_STRING,
    challengeResponse: VALUE_EMPTY_STRING
  };
  const containerAuthForm = useFormik({
    initialValues: authFormValues
  });

  useEffect(() => {
    import(`dayjs/locale/${localeByBranch}.js`).then(() => {
      const todayDate = dayjs().utc().locale(localeByBranch);
      setToday(todayDate);
    });
  }, [localeByBranch]);

  useEffect(() => {
    handleGetRequestByList();
    getAllBarnchesFromFireStore();
    getMaxDate();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    throwError && window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, [throwError]);

  useEffect(() => {
    if (personnelAuth?.length) {
      const updates = {
        ...authFormValues,
        challengeQuestion: personnelAuth[0]?.challenge_question
      };
      dispatch(updateAuthFormValues(updates));
    }
    // eslint-disable-next-line
  }, [personnelAuth]);

  // set the default branch into the auth section
  useEffect(() => {
    if (allBranches && allBranches?.length) {
      dispatch(setLoading(true));
      const selectedLocationDetails = allBranches.find(
        (location) => location.district_id === currentBranch?.value
      );
      const updates = {
        branch: selectedLocationDetails?.district_id,
        customer: VALUE_EMPTY_STRING,
        personnel: VALUE_EMPTY_STRING,
        authNumber: VALUE_EMPTY_STRING,
        challengeQuestion: VALUE_EMPTY_STRING,
        challengeResponse: VALUE_EMPTY_STRING
      };
      dispatch(updateAuthFormValues(updates));

      dispatch(setLoading(false));
    }
    // eslint-disable-next-line
  }, [allBranches]);

  useEffect(() => {
    dispatch(setIsContainerReload(false));
    if (containerAuditId && currentBranch?.district_id) {
      setIsDataLoaded(false);
      handleGetAuditDetail(containerAuditId, currentBranch?.district_id);
    } else if (!containerAuditId) {
      handleNew();
      dispatch(resetForm());
    }
    //eslint-disable-next-line
  }, [containerAuditId, currentBranch?.district_id, isContainerReload]);

  const getMaxDate = async () => {
    const auditSearchMaxDate = await getGlobalAttributeValue(
      currentBranch?.district_id,
      SYSTEM_ID_SecureBaseWeb,
      globalAttributeTypeIdCalFuture
    );
    const maxDateNumber =
      parseInt(auditSearchMaxDate) / numberConstants.tweleve;
    const maxDateFormatted = today.add(maxDateNumber, "year");
    setMaxDate(maxDateFormatted);
  };

  const handleGetRequestByList = async () => {
    try {
      const result = await getRequestorTypeData(numberConstants?.six);
      let response = result.map((res) => ({
        value:
          res.dropdown_list_id === numberConstants?.twentyFour
            ? checkFilterOptions.Branch
            : res.dropdown_list_id === numberConstants?.twentyFive
              ? checkFilterOptions.Customer
              : res.dropdown_list_id,
        label: res.descr
      }));
      if (!isBranchReq) {
        response = response.filter(
          (item) => item.value !== checkFilterOptions?.Branch
        );
      }
      const responseWithAll = [EMPTY_SELECTION].concat(response);
      responseWithAll.sort((a, b) => a.label.localeCompare(b.label));
      setRequestedListBy(responseWithAll);
    } catch (err) {
      dispatch(setLoading(false));
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  const handleGetAuditType = async (code) => {
    try {
      const result = await getAudiType(checkFilterOptions.Customer);
      let response = [];
      if (code === checkFilterOptions.Customer) {
        response = result
          ?.filter((res) => res.customer_required_flag === Y_CHECKER)
          .map((res) => ({
            value: res.audit_type_id,
            label: res.descr,
            custReq: res.customer_required_flag,
            locReq: res.location_required_flag
          }));
      } else {
        response = result?.map((res) => ({
          value: res.audit_type_id,
          label: res.descr,
          custReq: res.customer_required_flag,
          locReq: res.location_required_flag
        }));
      }
      const responseWithAll = [EMPTY_SELECTION].concat(response);
      responseWithAll.sort((a, b) => a?.value?.localeCompare(b?.value));
      setAuditTypeList(responseWithAll);
    } catch (err) {
      dispatch(setLoading(false));
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  const handleReSetAudit = () => {
    const updates = { auditType: VALUE_EMPTY_STRING };
    dispatch(updateFormValues(updates));
  };

  const handleLocationValidation = async () => {
    const {
      row_from,
      row_to,
      shelf_from,
      shelf_to,
      vault_from,
      vault_to,
      rack_from,
      rack_to
    } = formValues;
    let isError = false;
    if (parseInt(vault_from) > parseInt(vault_to)) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode80165);
      let msg = errorMsg[0]?.descr.replace(
        "{0}",
        t("containerAuditDetails.the") +
          " " +
          t("workManagement.distribution.vault") +
          " "
      );
      dispatch(setError(msg));
      isError = true;
    } else if (parseInt(row_from) > parseInt(row_to)) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode80165);
      let msg = errorMsg[0]?.descr.replace(
        "{0}",
        t("containerAuditDetails.the") +
          " " +
          t("workManagement.distribution.row") +
          " "
      );
      dispatch(setError(msg));
      isError = true;
    } else if (parseInt(rack_from) > parseInt(rack_to)) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode80165);
      let msg = errorMsg[0]?.descr.replace(
        "{0}",
        t("containerAuditDetails.the") +
          " " +
          t("workManagement.distribution.rack") +
          " "
      );
      dispatch(setError(msg));
      isError = true;
    } else if (parseInt(shelf_from) > parseInt(shelf_to)) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode80165);
      let msg = errorMsg[0]?.descr.replace(
        "{0}",
        t("containerAuditDetails.the") +
          " " +
          t("workManagement.distribution.shelf") +
          " "
      );
      dispatch(setError(msg));
      isError = true;
    }
    return isError;
  };

  const handleSaveAction = async () => {
    setIsSaved(true);
    dispatch(setLoading(true));
    dispatch(clearError());
    const {
      auditType,
      dueDate,
      customer,
      vault_from,
      vault_to,
      row_from,
      row_to,
      rack_from,
      rack_to,
      shelf_from,
      shelf_to,
      comment,
      requestedBy
    } = formValues;
    if (!containerAuditId) {
      if (
        requestedBy.value === checkFilterOptions?.Customer &&
        !isAuthenticated
      ) {
        const errorMsg = await getMessageFromCode(errorMsgs?.errorCode80226);
        setSeverity(severityError);
        dispatch(setError(`${errorMsg[0]?.descr}`));
        dispatch(setLoading(false));
        return;
      }
    }
    if (!requestedBy) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode20354);
      let msg = errorMsg[0]?.descr.replace(
        "|",
        t("containerAuditDetails.requestedBy")
      );
      setSeverity(severityError);
      dispatch(setError(`${msg}`));
      dispatch(setLoading(false));
      return;
    }
    if (!auditType) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode20354);
      let msg = errorMsg[0]?.descr.replace(
        "|",
        t("containerAuditDetails.auditType")
      );
      setSeverity(severityError);
      dispatch(setError(`${msg}`));
      dispatch(setLoading(false));
      return;
    }
    if (!dueDate) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode20354);
      let msg = errorMsg[0]?.descr.replace(
        "|",
        t("containerAuditDetails.dueDate")
      );
      setSeverity(severityError);
      dispatch(setError(`${msg}`));
      dispatch(setLoading(false));
      return;
    }
    if (auditType.custReq === Y_CHECKER && !customer) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode20354);
      let msg = errorMsg[0]?.descr.replace("|", t("common.Customer"));
      setSeverity(severityError);
      dispatch(setError(`${msg}`));
      dispatch(setLoading(false));
      return;
    }

    if (auditType.locReq === Y_CHECKER) {
      const hasValidationError = await handleLocationValidation();
      if (hasValidationError) {
        dispatch(setLoading(false));
        return;
      }
    }

    if (comment && comment.length > maxCommentLength) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode80185);
      let msg = errorMsg[0]?.descr
        .replace("{0}", t("containerAuditDetails.comments"))
        .replace("{1}", maxCommentLength);
      dispatch(setError(`${t("containerAuditDetails.comments")} ${msg}`));
      dispatch(setLoading(false));
      return;
    }
    let reqBody = {
      main_district_id: currentBranch?.district_id,
      audit_type_id: auditType?.value,
      due_date: dayjs(dueDate).format(dateFormat.universalFormat),
      vault_from: vault_from === t("common.all") ? null : vault_from,
      vault_to: vault_to === t("common.all") ? null : vault_to,
      row_from: row_from === t("common.all") ? null : row_from,
      row_to: row_to === t("common.all") ? null : row_to,
      rack_from: rack_from === t("common.all") ? null : rack_from,
      rack_to: rack_to === t("common.all") ? null : rack_to,
      shelf_from: shelf_from === t("common.all") ? null : shelf_from,
      shelf_to: shelf_to === t("common.all") ? null : shelf_to,
      comment: comment,
      requestor_code: requestedBy?.value
    };
    if (containerAuditId) {
      reqBody = {
        ...reqBody,
        employee_id: auth?.user?.employee_id
      };
    } else {
      reqBody = {
        ...reqBody,
        logged_in_employee_id: auth?.user?.employee_id
      };
    }
    if (
      (requestedBy.value === checkFilterOptions?.Customer &&
        containerAuthForm?.values?.personnel?.value) ||
      (viewData && viewData[0][0]?.requested_by_personnel_id)
    ) {
      reqBody = {
        ...reqBody,
        requested_personnel_id:
          containerAuthForm?.values?.personnel?.value ||
          viewData[0][0]?.requested_by_personnel_id
      };
    }
    try {
      let dataSets = null;
      if (containerAuditId) {
        dataSets = await getResponseData(
          JSON.stringify({
            ...reqBody,
            container_audit_id: containerAuditId,
            location_changed_flag: VALUE_N,
            container_audit_timestamp: auditDetails?.container_audit_timestamp
          }),
          CF_URLS.containerAuditDetails?.updateContainerAuditDetail,
          1
        );
      } else {
        dataSets = await getResponseData(
          JSON.stringify({
            ...reqBody,
            customer_id: customer?.value
          }),
          CF_URLS.containerAuditDetails?.savecontainerAuditDetail,
          1
        );
      }
      dispatch(setLoading(false));
      const { data } = dataSets;
      if (data[0][0]?.error === numberConstants.zero && containerAuditId) {
        setViewMode(true);
        handleGetAuditDetail(containerAuditId, currentBranch?.district_id);
        await import(`dayjs/locale/${localeByBranch}.js`);
        const formattedDate = dayjs
          .utc()
          .add(timeZoneOffset, "hour")
          .locale(localeByBranch)
          .format(dateFormatWithTime);
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode80169);
        let errorMessage = errorMsg[0]?.descr?.replace("{0}", formattedDate);
        setToastMessage(errorMessage);
      } else if (data[0][0]?.error) {
        const errorMsg = await getMessageFromCode(data[0][0]?.error);
        dispatch(setError(errorMsg[0]?.descr));
      } else if (data[0][0]?.container_audit_id) {
        handleGetAuditDetail(
          data[0][0]?.container_audit_id,
          currentBranch?.district_id
        );
        setViewMode(true);
        await import(`dayjs/locale/${localeByBranch}.js`);
        const formattedDate = dayjs
          .utc()
          .add(timeZoneOffset, "hour")
          .locale(localeByBranch)
          .format(dateFormatWithTime);
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode80169);
        let errorMessage = errorMsg[0]?.descr?.replace("{0}", formattedDate);
        setToastMessage(errorMessage);
      } else {
        dispatch(setLoading(false));
        dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
      }
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  const handleGetAuditDetail = async (auditId, auditBranch) => {
    dispatch(clearError());
    dispatch(setLoading(true));
    if (!auditId) {
      dispatch(setLoading(false));
      return;
    }
    try {
      const reqBody = JSON.stringify({
        main_district_id: auditBranch || currentBranch?.district_id,
        container_audit_id: auditId,
        iso_locale_code: DEFAULT_LOCALE_CODE
      });

      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerAuditDetails?.getContainerAuditDetail,
        4
      );
      const { data } = dataSets;
      if (Number(data[1][0]?.error)) {
        const errorMsg = await getMessageFromCode(data[1][0]?.error);
        dispatch(
          setError(errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE))
        );
        dispatch(setLoading(false));
        return;
      } else {
        setViewData(data);
        setAuditDetails(data[0][0]);
        handleGetAuditType();
        const {
          requestor_localized,
          audit_type_id,
          due_date,
          audit_sample_method_id,
          vault_from,
          vault_to,
          row_from,
          row_to,
          shelf_from,
          shelf_to,
          rack_from,
          rack_to,
          requestor_code,
          audit_type,
          customer_number
        } = data[0][0];
        const selectedCustomer = customers.filter(
          (cust) => cust.number === customer_number
        );
        await import(`dayjs/locale/${localeByBranch}.js`);
        const formattedDueDate = dayjs.utc(due_date).locale(localeByBranch);
        const updates = {
          ...formValues,
          auditId: VALUE_EMPTY_STRING,
          auditType: { label: audit_type, value: audit_type_id },
          dueDate: dayjs(formattedDueDate),
          customer: selectedCustomer[0],
          vaultFrom: vault_from || ALL_SELECTION?.value,
          vaultTo: vault_to || ALL_SELECTION?.value,
          rowFrom: row_from || ALL_SELECTION?.value,
          rowTo: row_to || ALL_SELECTION?.value,
          rackFrom: rack_from || ALL_SELECTION?.value,
          rackTo: rack_to || ALL_SELECTION?.value,
          shelfFrom: shelf_from || ALL_SELECTION?.value,
          shelfTo: shelf_to || ALL_SELECTION?.value,
          requestedBy: { label: requestor_localized, value: requestor_code },
          auditSampleMethodId: audit_sample_method_id,
          comment: VALUE_EMPTY_STRING
        };
        dispatch(updateFormValues(updates));
        setIsDataLoaded(true);
      }
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    if (authFormValues?.branch) {
      if (isAuthenticated) {
        getAllocatedCustomers();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authFormValues?.branch, isAuthenticated]);

  const onAuthenticatedCallerCliked = () => {
    if (isAuthenticated) {
      dispatch(setAuthenticationState(null));
      dispatch(updateAuthFormValues(initialAuthValues));
      const updates = !isAuthenticated;
      dispatch(updateContainerAuthentication(updates));
      dispatch(resetForm());
      dispatch(clearWarning());
      handleNew();
    } else if (showAuthenticationSection) {
      setShowAuthenticationSection(false);
    } else {
      setShowAuthenticationSection(true);
    }
    dispatch(clearError());
  };

  const isShowAuthenticationSection = () => {
    if (showAuthenticationSection) {
      return true;
    }
    return false;
  };

  // get branches from firestore
  const getAllBarnchesFromFireStore = async () => {
    dispatch(setLoading(true));
    try {
      const branches = await getDistricts();
      let bran = branches?.map((b) => {
        return {
          ...b,
          label: b?.name,
          value: b?.district_id
        };
      });
      const branchData = getSortedData(bran, "name", "asc");
      setAllBranches(branchData);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.EXCEPTION)));
      dispatch(setLoading(false));
    }
  };

  const handleBranchChange = (e) => {
    const updatedValues = {
      branch: e.target.value,
      customer: VALUE_EMPTY_STRING,
      personnel: VALUE_EMPTY_STRING,
      authNumber: VALUE_EMPTY_STRING,
      challengeQuestion: VALUE_EMPTY_STRING,
      challengeResponse: VALUE_EMPTY_STRING
    };

    dispatch(updateAuthFormValues(updatedValues));
  };

  const handleCustomerChange = (event, newValue) => {
    const updatedValues = {
      ...authFormValues,
      customer: newValue,
      personnel: VALUE_EMPTY_STRING,
      authNumber: VALUE_EMPTY_STRING,
      challengeQuestion: VALUE_EMPTY_STRING,
      challengeResponse: VALUE_EMPTY_STRING
    };
    dispatch(updateAuthFormValues(updatedValues));
  };
  // handle Personnel selection in auth section
  const handlePersonnelChange = (selectedValue) => {
    const updatedValues = {
      ...authFormValues,
      personnel: selectedValue,
      authNumber: VALUE_EMPTY_STRING,
      challengeQuestion: VALUE_EMPTY_STRING,
      challengeResponse: VALUE_EMPTY_STRING
    };
    dispatch(updateAuthFormValues(updatedValues));
  };

  const onAuthenticateClick = async () => {
    dispatch(setLoading(true));
    setIsAuthenticationError(false);
    try {
      // Check if either authNumber or challengeResponse is provided
      if (
        !authFormValues?.authNumber?.length &&
        !authFormValues?.challengeResponse?.length
      ) {
        setIsAuthenticationError(true);
        dispatch(setLoading(false));
        return;
      }

      // Authenticate with authNumber
      if (authFormValues?.authNumber?.length) {
        if (authFormValues.authNumber === personnelAuth[0]?.auth_number) {
          dispatch(updateContainerAuthentication(true));
          setViewData("");
          setAuditDetails("");
        } else {
          setIsAuthenticationError(true);
        }
      }

      // Authenticate with challengeResponse
      if (authFormValues?.challengeResponse?.length) {
        const hashCode = await generateSHA256Hash(
          authFormValues.challengeResponse.trim().toUpperCase()
        );

        const validateChallengeResponse =
          await triggerValidateChallengeResponse({
            main_district_id: String(currentBranch?.district_id),
            language_locale_id: languageLocaleId,
            personnel_id: authFormValues?.personnel?.value,
            challenge_response: hashCode
          });

        if (validateChallengeResponse?.data[0]?.is_match_flag === Y_CHECKER) {
          dispatch(updateContainerAuthentication(true));
          setViewData("");
          setAuditDetails("");
        } else {
          setIsAuthenticationError(true);
        }
      }
      containerAuthForm.setValues(authFormValues);
      dispatch(resetForm());
      handleNew();
      dispatch(setSearchResults([]));
      let updates = {
        auditId: VALUE_EMPTY_STRING,
        reqBy: ALL_SELECTION,
        auditType: ALL_SELECTION,
        auditStatus: defaultStatus,
        auditDate: VALUE_EMPTY_STRING,
        auditTo: VALUE_EMPTY_STRING,
        dueDate: VALUE_EMPTY_STRING,
        dueDateTo: VALUE_EMPTY_STRING,
        exceptionsOnly: false,
        customer: authFormValues?.customer
      };
      dispatch(updateFormValues(updates));
      dispatch(updateSearchFormValues(updates));
      dispatch(setLoading(false));
      setIsSaved(false);
      setViewMode(false);
    } catch (error) {
      dispatch(setLoading(false));
      setIsAuthenticationError(true);
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  const handleAuthFieldsChange = (name, value) => {
    const updatedValues = {
      ...authFormValues,
      [name]: value
    };
    dispatch(updateAuthFormValues(updatedValues));
  };

  const onCancelAuthCliked = () => {
    dispatch(updateContainerAuthentication(false));
    setShowAuthenticationSection(false);
  };

  // if authenticated get the allocated customers
  const getAllocatedCustomers = async () => {
    dispatch(setLoading(true));
    try {
      if (authenticatedCustomers) {
        const data = await getAuthorizationPersonnelsAuthentications({
          personnelId: authFormValues?.personnel?.value
        });
        const personalCustomers = JSON.parse(data?.customer_list);
        const personalCustomersList = personalCustomers?.map(
          ({ number, name, customer_Id }) => ({
            label: `${number} - ${name.trim()}`,
            value: customer_Id,
            number: number
          })
        );
        personalCustomersList?.sort((a, b) =>
          String(a.value).localeCompare(String(b.value))
        );
        if (personalCustomersList.length) {
          setAuthCustomers(personalCustomersList);
          const errorObj = await getMessageFromCode(errorMsgs?.errorCode80251);
          const errorMsg = errorObj[0]?.descr;

          dispatch(setWarning(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE)));
        } else {
          const errorObj = await getMessageFromCode(errorMsgs?.errorCode80241);
          const errorMsg = errorObj[0]?.descr;

          dispatch(setWarning(errorMsg));
          setAuthCustomers([]);
        }
      } else {
        const errorObj = await getMessageFromCode(errorMsgs?.errorCode80241);
        const errorMsg = errorObj[0]?.descr;

        dispatch(setWarning(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE)));
        const updatedValues = {
          ...authFormValues,
          authNumber: VALUE_EMPTY_STRING,
          challengeQuestion: VALUE_EMPTY_STRING,
          challengeResponse: VALUE_EMPTY_STRING
        };
        dispatch(updateAuthFormValues(updatedValues));
        setShowAuthenticationSection(true);
        dispatch(updateContainerAuthentication(false));
      }
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  const handlePersonnelAuthChange = (selectedValue) => {
    const updatedValues = {
      ...authFormValues,
      personnel: selectedValue,
      authNumber: VALUE_EMPTY_STRING,
      challengeQuestion: VALUE_EMPTY_STRING,
      challengeResponse: VALUE_EMPTY_STRING
    };
    dispatch(updateAuthFormValues(updatedValues));
    dispatch(updateContainerAuthentication(false));
    setIsAuthenticationError(false);
  };

  const onCancelAudit = async () => {
    dispatch(setLoading(true));
    if (formValues?.requestedBy?.value === checkFilterOptions?.Customer) {
      if (isAuthenticated) {
        let errorMsg = "";
        if (viewData?.audit_status === checkAuditStatus?.scheduled) {
          errorMsg = await getMessageFromCode(errorMsgs.errorCode25004);
        } else {
          errorMsg = await getMessageFromCode(errorMsgs.errorCode25088);
        }
        const msg = errorMsg[0]?.descr;
        setCancelText(msg);
        dispatch(setLoading(false));
        setOpenCancel(true);
      } else {
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode80226);
        const msg = errorMsg[0]?.descr;
        dispatch(setError(msg));
        setOpenCancel(false);
        dispatch(setLoading(false));
        setShowAuthenticationSection(true);
      }
    } else {
      let errorMsg = "";
      if (viewData?.audit_status === checkAuditStatus?.scheduled) {
        errorMsg = await getMessageFromCode(errorMsgs.errorCode25004);
      } else {
        errorMsg = await getMessageFromCode(errorMsgs.errorCode25088);
      }
      const msg = errorMsg[0]?.descr;
      setCancelText(msg);
      dispatch(setLoading(false));
      setOpenCancel(true);
    }
  };
  const handleCancel = async () => {
    dispatch(setLoading(true));
    if (
      formValues?.requestedBy.value === checkFilterOptions?.Customer &&
      !isAuthenticated
    ) {
      const errorMsg = await getMessageFromCode(errorMsgs?.errorCode80226);
      setSeverity(severityError);
      dispatch(setError(`${errorMsg[0]?.descr}`));
      dispatch(setLoading(false));
      setOpenCancel(false);
      return;
    }
    let personnel_id =
      formValues?.requestedBy.value === checkFilterOptions.Customer
        ? authFormValues?.personnel?.value
        : VALUE_EMPTY_STRING;
    try {
      const dataSets = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.district_id,
          container_audit_id: auditDetails?.container_audit_id,
          cancel_employee_id: auth?.user?.employee_id,
          cancel_personnel_id: personnel_id,
          container_timestamp: auditDetails?.container_timestamp
        }),
        CF_URLS.containerAuditDetails?.cancelContainerAudit,
        2
      );
      const { data } = dataSets;
      dispatch(setLoading(false));
      if (data[0][0]?.error !== numberConstants.zero) {
        const errorMsg = await getMessageFromCode(data[0][0]?.error);
        dispatch(setError(errorMsg[0]?.descr));
        setOpenCancel(false);
        return;
      } else if (
        data[0][0]?.container_audit_id ||
        data[0][0]?.error === numberConstants.zero
      ) {
        const containerId =
          containerAuditId || auditDetails?.container_audit_id;
        handleGetAuditDetail(containerId, currentBranch?.district_id);
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode80170);
        let errorMessage = errorMsg[0]?.descr?.replace(
          "{0}",
          dayjs().format(dayMonthYearTimeFormat)
        );
        setToastMessage(errorMessage);
      } else {
        dispatch(setLoading(false));
        dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
      }
      setOpenCancel(false);
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  const handleNew = async () => {
    setViewMode(false);
    setViewData("");
    setAuditDetails("");
    dispatch(resetForm());
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${CONTAINER_INVENTORY_AUDIT_DETAIL}`
    );
  };
  const handlePublishComment = async (recipient) => {
    dispatch(setLoading(true));
    try {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode80180);
      let mail = recipient?.map((item) => item.emailAddress).join(",");
      let comment = errorMsg[0]?.descr?.replace(
        "{0}",
        dayjs().format(dayMonthYearTimeFormat)
      );
      comment = comment?.replace("{1}", mail);
      const dataSets = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.id,
          container_audit_id: auditDetails?.container_audit_id,
          publish_comment: comment,
          employee_id: auth?.user?.employee_id
        }),
        CF_URLS.containerAuditDetails?.publishcontaineraudit,
        1
      );
      const { data } = dataSets;
      if (data[0][0]?.error !== numberConstants.zero) {
        const errorMsg = await getMessageFromCode(data[0][0]?.error);
        let msg = errorMsg
          ? errorMsg[0]?.descr
          : findErrorMessage(ERROR_TYPES.ISSUE);
        dispatch(setError(msg));
      } else {
        handleGetAuditDetail(auditDetails?.container_audit_id, "");
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode80178);
        let successMessage = errorMsg[0]?.descr?.replace(
          "{0}",
          dayjs().format(dayMonthYearTimeFormat)
        );
        setToastMessage(successMessage);
      }
    } catch (err) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
      dispatch(setLoading(false));
    }
  };

  const handlePublish = async () => {
    dispatch(setLoading(true));
    dispatch(clearError());
    try {
      const dataSets = await getResponseData(
        JSON.stringify({
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          customer_id: auditDetails?.customer_id
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.getpersonnellist,
        2
      );
      const { data } = dataSets;
      if (Array.isArray(data[0])) {
        setIsEmail(true);
        let arr = [];
        data[0].forEach((item) => {
          let middleName = item?.middle_name && item?.middle_name + ".";
          let name = ` ${item?.last_name}, ${item?.first_name} ${middleName} `;
          if (emailRegex.test(item?.business_email_addr)) {
            arr.push({ ...item, isCheck: false, first_name: name });
          }
        });
        arr.sort((a, b) => a?.last_name?.localeCompare(b?.last_name));
        setEmailRecipientsList([...arr]);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  const emailSendingTask = async (
    recipients,
    emailSubject,
    mailBody,
    attechment
  ) => {
    dispatch(setLoading(true));
    try {
      const response = await sendEmail(
        recipients,
        mailBody,
        emailSubject,
        attechment
      );
      if (response?.data?.isSuccess) {
        // email sent
        dispatch(setLoading(false));
        return true;
      } else {
        dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));

        dispatch(setLoading(false));
        return false;
      }
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
      dispatch(setLoading(false));
    }
  };
  const handleGetLink = async () => {
    dispatch(setLoading(true));
    try {
      const dataSets2 = await getResponseData(
        JSON.stringify({
          district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          system_id: system_id
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.getcorpsettings,
        1
      );
      const cropList = dataSets2.data[0];
      const dataSets = await getResponseData(
        JSON.stringify({
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          system_id: system_id
        }),
        CF_URLS.openMediaProcessing?.auditdetail?.getsystemglobalattributes,
        1
      );
      const { data } = dataSets;
      let res = [...data[0], ...cropList]?.filter((item) => {
        let key = item?.setting_type_descr || item?.global_attribute_type_descr;
        return key === containerAuditDetailConst?.ironMountainLink;
      });
      return res;
    } catch (errorMsg) {
      dispatch(setError(errorMsg[0]?.descr));
    } finally {
      dispatch(setLoading(false));
    }
  };
  const handleSend = async () => {
    dispatch(setLoading(true));
    let data = emailRecipientsList?.filter((item) => item?.isCheck);
    if (!data?.length) {
      const errorMsg = await getMessageFromCode(errorMsgs.messageCodes80175);
      if (errorMsg[0]?.descr) {
        setIsEmail(false);
        dispatch(setError(errorMsg[0]?.descr));
        setSeverity(severityWarning);
      }
      dispatch(setLoading(false));
      return;
    }
    let recipient = [];
    data.forEach((item) => {
      recipient.push({
        emailAddress: item?.business_email_addr,
        name: item?.first_name.replace(specialCharRex, "")
      });
    });
    const attechment = await handlePrint(false);
    try {
      let emailSubjectAuth = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode80179
      );
      const link = await handleGetLink();
      const nameFormatting = handleGetnameFormatting(
        auditDetails?.requested_by_personnel_name,
        auditDetails?.started_datetime_ltz
      );

      const mailBodyAuth = await getTheEmailBody(nameFormatting, link);
      if (mailBodyAuth) {
        const isEmailSendAuth = await emailSendingTask(
          recipient,
          emailSubjectAuth?.errorMsg?.replace("#%1%", containerAuditId),
          mailBodyAuth,
          attechment
        );
        if (isEmailSendAuth) {
          setIsEmail(false);
          handlePublishComment(recipient);
        } else {
          dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));

          dispatch(setLoading(false));
        }
      }
    } catch (err) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
      dispatch(setLoading(false));
    }
  };
  const handleBackToEmail = async () => {
    const errorMsg = await getMessageFromCode(errorMsgs?.errorCode80177);
    dispatch(setError(errorMsg[0]?.descr));
    setSeverity(severityWarning);
    setIsEmail(false);
  };
  const handlePrint = async (isLink = true) => {
    dispatch(setLoading(true));
    try {
      const requestBody = JSON.stringify({
        main_district_id: currentBranch?.id,
        customer_id: auditDetails?.customer_id,
        district_id: currentBranch?.district_id,
        container_audit_id: auditDetails?.container_audit_id,
        iso_locale_code:
          allLocations[0]?.iso_locale_code ||
          SUPPORTED_LANGUAGES?.ENGLISH_US?.code,
        district_name: currentBranch?.name,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });

      const url =
        CF_URLS.printReports.containerProcessing?.containerAuditDetails;
      let reportResponse = await printReport(requestBody, url, isLink);
      if (!reportResponse.success) {
        dispatch(setError(reportResponse.error));
      }
      if (!isLink) {
        return reportResponse;
      } else {
        dispatch(setLoading(false));
      }
    } catch (err) {
      dispatch(setLoading(false));
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  const handleComment = async () => {
    dispatch(setLoading(true));
    try {
      const dataSets = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.id,
          container_audit_id: auditDetails?.container_audit_id,
          publish_comment: formValues?.comment,
          employee_id: auth?.user?.employee_id
        }),
        CF_URLS.containerAuditDetails?.publishcontaineraudit,
        1
      );
      const { data } = dataSets;
      dispatch(setLoading(false));
      if (data[0][0]?.error !== numberConstants.zero) {
        const errorMsg = await getMessageFromCode(data[0][0]?.error);
        dispatch(setError(errorMsg[0]?.descr));
      } else {
        handleGetAuditDetail(auditDetails?.container_audit_id);
      }
    } catch (err) {
      dispatch(setLoading(false));
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };
  const ButtonComponent = () => {
    return (
      <>
        <MediaCount viewData={auditDetails} />
        <Stack>
          <DetailTable viewData={viewData} />
        </Stack>
        <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
          <Divider />
        </Grid>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="start"
          gap={spacing.verticalMargin20}
        >
          <Button
            id="search-button"
            variant="outlined"
            type="button"
            onClick={() => {
              navigate(
                `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${CONTAINER_INVENTORY_AUDIT_SEARCH}`,
                { state: { from: containerAuditId ? currentPath : null } }
              );
            }}
          >
            {t("containerAuditDetails.search")}
          </Button>
          <Button
            disabled={
              !(
                auditDetails?.discrepancy_exist_flag === Y_CHECKER &&
                auditDetails?.discrepancy_exist_flag
              )
            }
            id="viewDiscrepancies-button"
            variant="outlined"
            type="button"
            onClick={() => {
              navigate(
                `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`,
                {
                  state: { containerAuditId: containerAuditId }
                }
              );
            }}
          >
            {t("containerAuditSearch.auditDetails.viewDiscrepancies")}
          </Button>
          <Button
            disabled={!auditDetails?.car_id}
            id="viewCar-button"
            variant="outlined"
            type="button"
            onClick={() => {
              navigate(
                `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${CONTAINER_INVENTORY_VIEW_CAR}`
              );
            }}
          >
            {t("containerAuditSearch.auditDetails.viewCar")}
          </Button>
          <Button
            disabled={!(auditDetails?.audit_type && viewData[3]?.length > 0)}
            id="clear-button"
            variant="outlined"
            type="button"
            onClick={() => {
              setIsDownload(true);
              setReportCFUrl(
                CF_URLS.printReports.containerProcessing?.containerAuditDetails
              );
            }}
          >
            {t("containerAuditDetails.print")}
          </Button>
          {isButtonAccessible && (
            <>
              <Button
                disabled={
                  !(
                    auditDetails?.audit_status ===
                      checkAuditStatus?.completed &&
                    auditDetails?.requestor_code ===
                      checkFilterOptions?.Customer
                  )
                }
                id="clear-button"
                variant="outlined"
                type="button"
                onClick={() => {
                  handlePublish();
                }}
              >
                {t("containerAuditDetails.publish")}
              </Button>

              <Button
                disabled={
                  auditDetails?.audit_status
                    ? statusList.includes(auditDetails?.audit_status)
                    : true
                }
                id="clear-button"
                variant="outlined"
                type="button"
                onClick={() => {
                  onCancelAudit();
                }}
              >
                {t("common.cancel")}
              </Button>
              <Button
                id="clear-button"
                variant="outlined"
                type="button"
                onClick={() => {
                  handleNew();
                }}
              >
                {t("containerAuditSearch.auditDetails.new")}
              </Button>
              <Button
                variant="contained"
                size="medium"
                type="button"
                onClick={() => handleSaveAction()}
                disabled={
                  (containerAuditId &&
                    !(
                      auditDetails?.audit_status ===
                        checkAuditStatus?.scheduled ||
                      auditDetails?.audit_status === checkAuditStatus?.started
                    )) ||
                  isSaved
                }
              >
                {t("common.save")}
              </Button>
            </>
          )}
        </Grid>
      </>
    );
  };
  const handleComponentRender = () => {
    if (isShowAuthenticationSection() && !isAuthenticated) {
      return (
        <AuditAuthForm
          allBranches={allBranches}
          handleBranchChange={handleBranchChange}
          valuePersonnelAuth={valuePersonnelAuth}
          allCustomers={customers}
          handleCustomerChange={handleCustomerChange}
          handlePersonnelChange={handlePersonnelChange}
          onAuthenticateClick={onAuthenticateClick}
          isAuthenticationError={isAuthenticationError}
          isAuthenticated={isAuthenticated}
          onCancelAuthCliked={onCancelAuthCliked}
          programSecurityListArray={programSecurityListArray}
          setError={setError}
          isLoading={isLoading}
          handleChange={handleAuthFieldsChange}
          setLoading={setLoading}
          authFormValues={authFormValues}
          searchText={searchText}
          setSearchText={setSearchText}
          authCustomers={authenticatedCustomers}
        />
      );
    } else if (isEmail) {
      return (
        <EmailPublish
          handleBack={() => {
            handleBackToEmail();
          }}
          emailRecipientsList={emailRecipientsList}
          handleSend={handleSend}
          setEmailRecipientsList={setEmailRecipientsList}
          setSeverity={setSeverity}
          throwError={throwError}
        />
      );
    } else {
      return (
        <>
          <ContainerAuditForm
            requestedListBy={requestedListBy}
            auditTypeList={auditTypeList}
            customers={isAuthenticated ? authCustomers : customers}
            setLoading={setLoading}
            currentBranch={currentBranch}
            setThrowError={setError}
            handleReSetAudit={handleReSetAudit}
            auditDetailForm={auditDetailForm}
            viewData={auditDetails}
            isViewMode={!!auditDetails?.audit_type}
            localeByBranch={localeByBranch}
            allAuditData={viewData}
            handleComment={handleComment}
            handleGetAuditType={handleGetAuditType}
            hasPermission={isButtonAccessible}
            maxDate={maxDate}
            containerAuditId={containerAuditId}
            setShowAuthenticationSection={setShowAuthenticationSection}
            viewMode={viewMode}
            setIsSaved={setIsSaved}
            initialAuthValues={initialAuthValues}
          />
          <ButtonComponent />
        </>
      );
    }
  };
  return (
    <>
      {containerAuditId
        ? (isLoading || !isDataLoaded) && (
            <FullCircularLoader loading={isLoading || !isDataLoaded} />
          )
        : isLoading && <FullCircularLoader loading={isLoading} />}
      <DownloadPopup
        title={t("containerAuditDetails.downloadReportTitle")}
        showPrintModal={isDownload}
        cfUrl={reportCFUrl}
        onCancleClick={() => {
          setIsDownload(false);
          setReportCFUrl("");
        }}
        onDownloadClick={() => {
          handlePrint();
        }}
        setSelectedValue={setSelectedValue}
      />
      <CommonModal
        open={openCancel}
        title={t("common.confirm")}
        body={
          <Stack gap={spacing.gap}>
            <p>{cancelText}</p>
            <p>{t("common.proceedMsg")}</p>
          </Stack>
        }
        buttons={
          <>
            <Button
              id="saveBtn-modal"
              variant="outlined"
              disabled={null}
              onClick={() => setOpenCancel(false)}
              sx={null}
            >
              {t("common.cancel")}
            </Button>
            <Button
              id="saveBtn-modal"
              variant="contained"
              disabled={null}
              onClick={handleCancel}
              sx={null}
            >
              {t("common.ok")}
            </Button>
          </>
        }
      />
      <form>
        <Stack gap={spacing.gap} sx={DetailsStyles.mainDivStyles}>
          <AuthenticationCallerButton
            isAuthenticated={isAuthenticated}
            onAuthenticatedCallerCliked={onAuthenticatedCallerCliked}
          />
          {toastMessage && !isLoading && (
            <SuccessPopup
              message={toastMessage}
              close={() => {
                setToastMessage("");
              }}
            />
          )}

          {(throwError || isError) && (
            <Collapse in={throwError || isError}>
              <Alert
                severity={severity}
                sx={DetailsStyles.errorStyle}
                icon={
                  <img
                    src={severity === severityError ? ErrorIcon : WarningIcon}
                    alt="error"
                    style={DetailsStyles.iconStyle}
                  />
                }
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      dispatch(clearError(false));
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {throwErrorMsg || error}
              </Alert>
            </Collapse>
          )}
          {isAuthenticated && (
            <>
              <SuccessAuthSection
                personalDetailForm={containerAuthForm}
                valuePersonnelAuth={valuePersonnelAuth}
                handlePersonnelOnChange={handlePersonnelAuthChange}
                disablePersonnel={true}
              />
              <Divider />
            </>
          )}
          <>{handleComponentRender()}</>
        </Stack>
      </form>
    </>
  );
};

export default ContainerAuditDetailScreen;
