import CloseIcon from "@mui/icons-material/Close";
import { Alert, Button, Grid, IconButton, Typography } from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_MODULE_BASE_PATH,
  CORRECTIVE_ACTION_REQUESTS_DETAIL,
  CORRECTIVE_ACTION_REQUESTS_SEARCH,
  CUSTOMER_MOVE_SEARCH,
  DISCREPANCY_MANAGEMENT,
  EVENT_SEARCH,
  GENERAL,
  MEDIA_DESTRUCTION_REQUEST_SEARCH,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH,
  OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH,
  OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH,
  OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH,
  OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH,
  REQUEST_MODULE_BASE_PATH,
  REQUEST_SEARCH,
  ROUTE_MANAGEMENT,
  RUN_MANAGEMENT,
  SCHEDULE,
  SERVICE_INFORMATION,
  SERVICE_WINDOWS
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import {
  carStatusId,
  carTypeId,
  detectedIn,
  downloadOptionPdf,
  ERROR_TYPES,
  maxRowCount,
  mediaType,
  N_CHECKER,
  system_id,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  ymdDateFormat
} from "utils/constants";
import { general } from "utils/constants/container-processing/ContainerProcessing";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData,
  getUserName,
  printReport
} from "utils/helpers";
import { SystemRequestStyles } from "../system-requests/SystemRequest.style";
import CarDiscrepency from "./CarDiscrepency";
import Containers from "./Containers";
import Customers from "./Customers";
import HighPriority from "./HighPriority";
import OpenMedia from "./OpenMedia";
import RequestRuns from "./RequestRuns";
import {
  drStatusId,
  incompleteStopsPath,
  limitRowCountFlag,
  path,
  requestStatusId,
  requestStatusId2,
  statusId
} from "./ToDoConstants";
import { ToDoStyles } from "./ToDoStyles";

const ToDo = () => {
  const { currentBranch, auth } = useSelector(selectAuth);
  const navigate = useNavigate();

  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorAlertMsg, setShowErrorAlertMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0); // selected tab
  const [showPrintReportModal, setShowPrintReportModal] = useState(false);
  const [reportName, setReportName] = useState("");

  // high priority
  const [openMediaMissingAtPicking, setOpenMediaMissingAtPicking] = useState(0);
  const [requestsNotCompleted, setRequestsNotCompleted] = useState(0);
  const [unassignedRequests, setUnassignedRequests] = useState(0);
  const [containersMissingatStaging, setContainersMissingatStaging] =
    useState(0);
  const [containersMissingatArrival, setContainersMissingatArrival] =
    useState(0);
  const [inventoryPickedError, setInventoryPickedError] = useState(0);
  const [carsSignOffCount, setCarsSignOffCount] = useState(0);
  const [carSignOffId, setCarSignOffId] = useState("");

  // customers
  const [requiringServiceInformation, setRequiringServiceInformation] =
    useState(0);
  const [withServiceInformation, setWithServiceInformation] = useState(0);
  const [
    withSchedulesRequiringServiceWindows,
    setWithSchedulesRequiringServiceWindows
  ] = useState(0);
  const [withSchedulesRequiringRoutes, setWithSchedulesRequiringRoutes] =
    useState(0);
  const [requiringPricingContracts, setRequiringPricingContracts] = useState(0);
  const [withCatalogItemsmissing, setWithCatalogItemsmissing] = useState(0);
  const [withExpiringSchedules, setWithExpiringSchedules] = useState(0);
  const [requiringAuthorizationPersonnel, setRequiringAuthorizationPersonnel] =
    useState(0);
  const [viewCustomerMoves, setViewCustomerMoves] = useState(0);

  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [throwError, setThrowError] = useState(false);

  //Request Runs
  const [allUnassignedRequests, setAllUnassignedRequests] = useState(0);
  const [requiringConfirmation, setRequiringConfirmation] = useState(0);
  const [drEvents, setDrEvents] = useState(0);
  const [customerToConfirm, setCustomerToConfirm] = useState(0);
  const [branchToSchedule, setBranchToSchedule] = useState(0);
  const [runsNotCompleted, setRunsNotCompleted] = useState(0);
  const [incompleteStops, setIncompleteStops] = useState(0);
  const highPriorityDataLoaded = useRef(false);
  const requestRunsDataLoaded = useRef(false);

  const userName = getUserName(auth?.user);
  const [reportCUrl, setReportCFUrl] = useState(VALUE_EMPTY_STRING);
  const [selectedReportType, setSelectedReportType] =
    useState(downloadOptionPdf);

  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );
  const colors = [
    ToDoStyles.highPriorityColor,
    ToDoStyles.requestRunsColor,
    ToDoStyles.customersColor,
    ToDoStyles.containersColor,
    ToDoStyles.openmediaColor,
    ToDoStyles.discrepacyColor
  ];

  const buttonLabels = [
    t("dashboard.highPriority"),
    t("dashboard.requestRuns"),
    t("dashboard.customers"),
    t("dashboard.containers"),
    t("dashboard.openMedia"),
    t("dashboard.CARDiscrepacy")
  ];

  const handleClick = (index) => {
    setActiveIndex(index);
    switch (index) {
      case 0:
        if (!highPriorityDataLoaded.current) {
          getHighPriorityData(currentBranch?.district_id);
          highPriorityDataLoaded.current = true;
        }
        break;
      case 1:
        if (!requestRunsDataLoaded.current) {
          getRequestRunsData(currentBranch?.district_id);
          requestRunsDataLoaded.current = true;
        }
        break;
      default:
        break;
      //ToDo
    }
  };

  const onCardClick = async (labelText) => {
    switch (labelText) {
      // high priority
      case t("dashboard.openMediaMissingAtPicking"):
        navigate(`${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT} `, {
          state: {
            todoProps: { detectedIn: detectedIn[0], mediaType: mediaType[0] }
          }
        });
        break;
      case t("dashboard.requestsNotCompleted"): {
        const serviceDates = await getServiceDates(currentBranch?.district_id);
        navigate(
          `${REQUEST_MODULE_BASE_PATH}/${REQUEST_SEARCH}/${serviceDates?.startDate}/${serviceDates?.endDate}`
        );
        break;
      }
      case t("dashboard.unassignedRequests"): {
        const currentDate = dayjs();
        const formattedDate = currentDate.format(ymdDateFormat);
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/${RUN_MANAGEMENT}/${formattedDate}`
        );
        break;
      }
      case t("dashboard.containersMissingatStaging"):
        navigate(`${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT} `, {
          state: {
            todoProps: { detectedIn: detectedIn[1], mediaType: mediaType[1] }
          }
        });
        break;
      case t("dashboard.containersMissingatArrival"):
        navigate(`${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT} `, {
          state: {
            todoProps: { detectedIn: detectedIn[2], mediaType: mediaType[1] }
          }
        });
        break;
      case t("dashboard.inventoryPickedError"):
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_SEARCH}/${carTypeId}/${carStatusId}/${maxRowCount}`
        );
        break;
      case t("dashboard.carsRequiringSignOff"):
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CORRECTIVE_ACTION_REQUESTS_BASE_PATH}/${CORRECTIVE_ACTION_REQUESTS_DETAIL}`,
          { state: { record: { car_id: carSignOffId } } }
        );
        break;

      // customer
      case t("dashboard.withSchedulesRequiringRoutes"):
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${ROUTE_MANAGEMENT}/${true}`
        );
        break;
      case t("dashboard.requiringPricingContracts"):
        setReportName(t("dashboard.customerRequiringPricingContractsInSFI"));
        setShowPrintReportModal(true);
        break;
      case t("dashboard.withCatalogItemsMissing"):
        setReportName(t("dashboard.withCatalogItemsMissingDownloadReport"));
        setShowPrintReportModal(true);
        break;
      case t("dashboard.requiringAuthorizationPersonnel"):
        setReportName(
          t("dashboard.requiringAuthorizationPersonnelDownloadReport")
        );
        setShowPrintReportModal(true);
        break;
      case t("dashboard.viewCustomerMoves"):
        navigate(
          `${ADMIN_MODULE_BASE_PATH}/${CUSTOMER_MOVE_SEARCH}/${Y_CHECKER}`
        );
        break;
      case t("dashboard.requiringServiceInformation"):
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SERVICE_INFORMATION}/${true}/${true}`
        );
        break;
      case t("dashboard.withSchedulesRequiringServiceWindows"):
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SERVICE_WINDOWS}/${true}/${true}`
        );
        break;
      case t("dashboard.withServiceInformation"):
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SCHEDULE}/${true}`
        );
        break;
      case t("dashboard.withExpiringSchedules"):
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CUSTOMER_INFO_BASE_PATH}/${SCHEDULE}/${true}`
        );
        break;
      default:
    }
  };
  const onCardClickRequestRuns = async (labeltext) => {
    switch (labeltext) {
      case t("dashboard.allUnassignedRequests"): {
        const navigateApiDataAllUnassigned =
          await getRequestRunsStartAndEndTime(currentBranch?.district_id);
        const naviagteDataAllUnassigned = {
          startDate: navigateApiDataAllUnassigned?.startDate,
          endDate: navigateApiDataAllUnassigned?.endDate,
          requestStatusId: requestStatusId,
          limitRowCountFlag: limitRowCountFlag
        };
        navigate(
          `${REQUEST_MODULE_BASE_PATH}/${REQUEST_SEARCH}/${naviagteDataAllUnassigned?.startDate}/${naviagteDataAllUnassigned?.endDate}/${naviagteDataAllUnassigned?.requestStatusId}/${naviagteDataAllUnassigned?.limitRowCountFlag}`
        );
        break;
      }
      case t("dashboard.requiringConfirmation"): {
        let navigateApiDataRequiringConfirmation =
          await todoRequestRunsGetRequestData(currentBranch?.district_id);
        let navigateDataRequiringConfirmation = {
          startDate: navigateApiDataRequiringConfirmation?.startDate,
          endDate: navigateApiDataRequiringConfirmation?.endDate,
          requestStatusId: requestStatusId2,
          limitRowCountFlag: limitRowCountFlag
        };
        navigate(
          `${REQUEST_MODULE_BASE_PATH}/${REQUEST_SEARCH}/${navigateDataRequiringConfirmation?.startDate}/${navigateDataRequiringConfirmation?.endDate}/${navigateDataRequiringConfirmation?.requestStatusId}/${navigateDataRequiringConfirmation?.limitRowCountFlag}`
        );
        break;
      }
      case t("dashboard.DREventsinProgress"): {
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${EVENT_SEARCH}/${path}`
        );

        break;
      }
      case t("dashboard.customerToConfirm"): {
        const navigateData = {
          destructRequestStatusId: statusId,
          systemId: system_id
        };
        navigate(
          `${REQUEST_MODULE_BASE_PATH}/${MEDIA_DESTRUCTION_REQUEST_SEARCH}/${navigateData.destructRequestStatusId}/${navigateData.systemId}`
        );
        break;
      }
      case t("dashboard.branchtoScheduleComplete"): {
        const navigateData1 = {
          destructRequestStatusId: drStatusId,
          systemId: system_id
        };
        navigate(
          `${REQUEST_MODULE_BASE_PATH}/${MEDIA_DESTRUCTION_REQUEST_SEARCH}/${navigateData1.destructRequestStatusId}/${navigateData1.systemId}`
        );
        break;
      }
      case t("dashboard.runsNotCompleted"): {
        const runsNotCompletedData = await todoRunsNotCompleted(
          currentBranch?.district_id
        );
        let runsNotCompleted = {
          runDate: runsNotCompletedData?.runDate
        };
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_TRANSPORTATION_BASE_PATH}/${RUN_MANAGEMENT}/${runsNotCompleted?.runDate}`
        );
        break;
      }
      case t("dashboard.incompleteStops"):
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${GENERAL.replace(
            GENERAL,
            `${general}/${incompleteStopsPath}`
          )}`
        );
        break;
      default:
        break;
    }
  };

  const getServiceDates = async (district_id) => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: district_id,
        district_id: district_id
      };

      const serviceDatesResponse = await getResponseData(
        reqBody,
        `${CF_URLS.dashboard.todo.getStartAndEndDate}`,
        1
      );

      const startDate =
        serviceDatesResponse?.data[0][0]["start date"].split("T")[0];
      const endDate =
        serviceDatesResponse?.data[0][0]["end date"].split("T")[0];
      setIsLoading(false);

      return { startDate: startDate, endDate: endDate };
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const getHighPriorityData = async (district_id) => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: district_id,
        spid: ""
      };

      const highPriorityResponse = await getResponseData(
        reqBody,
        `${CF_URLS.dashboard.todo.todoHighPriority}`,
        1
      );

      setOpenMediaMissingAtPicking(
        parseInt(highPriorityResponse?.data[0][0]?.om_missing_at_picking_count)
      );
      setRequestsNotCompleted(
        parseInt(highPriorityResponse?.data[0][0]?.request_not_completed_count)
      );
      setUnassignedRequests(
        parseInt(highPriorityResponse?.data[0][0]?.request_not_on_run_count)
      );
      setContainersMissingatStaging(
        parseInt(highPriorityResponse?.data[0][0]?.missing_at_run_prep_count)
      );
      setContainersMissingatArrival(
        parseInt(highPriorityResponse?.data[0][0]?.missing_at_arrival_count)
      );
      setInventoryPickedError(
        parseInt(
          highPriorityResponse?.data[0][0]?.inventory_scanned_in_error_count
        )
      );
      setIsLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const getCustomerData = async (district_id) => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: district_id,
        spid: "",
        employee_id: auth?.user?.id
      };

      const customerResponse = await getResponseData(
        reqBody,
        `${CF_URLS.dashboard.todo.todoCustomer}`,
        10
      );

      setRequiringServiceInformation(
        parseInt(customerResponse?.data[0][0]?.count)
      );
      setWithServiceInformation(parseInt(customerResponse?.data[1][0]?.count));
      setWithSchedulesRequiringServiceWindows(
        parseInt(customerResponse?.data[2][0]?.count)
      );
      setWithSchedulesRequiringRoutes(
        parseInt(customerResponse?.data[3][0]?.count)
      );
      setRequiringPricingContracts(
        parseInt(customerResponse?.data[4][0]?.count)
      );
      setWithCatalogItemsmissing(parseInt(customerResponse?.data[5][0]?.count));
      setWithExpiringSchedules(parseInt(customerResponse?.data[6][0]?.count));
      setRequiringAuthorizationPersonnel(
        parseInt(customerResponse?.data[7][0]?.count)
      );
      setViewCustomerMoves(parseInt(customerResponse?.data[9][0]?.count));
      setIsLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };
  //Data binding
  const getRequestRunsData = async (districtId) => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: districtId,
        spid: ""
      };

      const requestRunsResponse = await getResponseData(
        reqBody,
        CF_URLS.dashboard.todo.todoRequestRuns,
        7
      );
      setAllUnassignedRequests(
        parseInt(requestRunsResponse?.data[0][0]?.count)
      );
      setRequiringConfirmation(
        parseInt(requestRunsResponse?.data[1][0]?.count)
      );
      setDrEvents(parseInt(requestRunsResponse?.data[2][0]?.count));
      setCustomerToConfirm(parseInt(requestRunsResponse?.data[5][0]?.count));
      setBranchToSchedule(parseInt(requestRunsResponse?.data[6][0]?.count));
      setRunsNotCompleted(parseInt(requestRunsResponse?.data[3][0]?.count));
      setIncompleteStops(parseInt(requestRunsResponse?.data[4][0]?.count));
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setIsLoading(false);
    }
  };

  // All Unassigned Requests
  const getRequestRunsStartAndEndTime = async (districtId) => {
    try {
      setIsLoading(true);
      const reqBody = {
        main_district_id: districtId,
        district_id: districtId
      };

      const requestRunsStartAndEndTimeResponse = await getResponseData(
        reqBody,
        CF_URLS.dashboard.todo.todoRequestRunsGetStartAndEndDate,
        1
      );
      const startDate =
        requestRunsStartAndEndTimeResponse?.data[0][0]["start_date"].split(
          "T"
        )[0];
      const endDate =
        requestRunsStartAndEndTimeResponse?.data[0][0]["end_date"].split(
          "T"
        )[0];
      setIsLoading(false);

      return { startDate: startDate, endDate: endDate };
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  // Requiring confirmation
  const todoRequestRunsGetRequestData = async (districtId) => {
    try {
      setIsLoading(true);
      const reqBody = {
        main_district_id: districtId,
        district_id: districtId
      };

      const requestRunsStartAndEndTimeResponse = await getResponseData(
        reqBody,
        CF_URLS.dashboard.todo.todoRequestRunsGetRequestData,
        1
      );
      const startDate =
        requestRunsStartAndEndTimeResponse?.data[0][0][
          "min_service_date"
        ].split("T")[0];
      const endDate =
        requestRunsStartAndEndTimeResponse?.data[0][0][
          "max_service_date"
        ].split("T")[0];
      setIsLoading(false);

      return { startDate: startDate, endDate: endDate };
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  //Runs not completed
  const todoRunsNotCompleted = async (districtId) => {
    try {
      const reqBody = { main_district_id: districtId };
      const runsNotCompletedData = await getResponseData(
        reqBody,
        CF_URLS.dashboard.todo.todoRunsNotCompleted,
        1
      );

      const runDate =
        runsNotCompletedData?.data[0][0]["run_date"].split("T")[0];
      return { runDate: runDate };
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const onCancelClick = () => {
    setThrowErrorMessage("");
    setThrowError(false);
    setReportName((prev) => "");
    setShowPrintReportModal(false);
  };
  const requestBody = {
    main_district_id: mainDistrictId,
    user_name: userName,
    report_format: selectedReportType?.type?.toUpperCase()
  };

  useEffect(() => {
    if (showPrintReportModal) {
      if (
        reportName === t("dashboard.customerRequiringPricingContractsInSFI")
      ) {
        setReportCFUrl(
          CF_URLS.printReports.todoReports.customerWithOutPricingContact
        );
      }
      if (reportName === t("dashboard.withCatalogItemsMissingDownloadReport")) {
        setReportCFUrl(
          CF_URLS.printReports.todoReports.customersCatalogueItemsMissing
        );
      }
      if (
        reportName ===
        t("dashboard.requiringAuthorizationPersonnelDownloadReport")
      ) {
        setReportCFUrl(
          CF_URLS.printReports.todoReports.customersRequiringAuthPersonal
        );
      }
    } else {
      setReportCFUrl(VALUE_EMPTY_STRING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPrintReportModal]);

  const onDownloadClick = async () => {
    if (reportName === t("dashboard.customerRequiringPricingContractsInSFI")) {
      try {
        setIsLoading(true);
        const reqBody = JSON.stringify(requestBody);

        const reportResponse = await printReport(
          reqBody,
          CF_URLS.printReports.todoReports.customerWithOutPricingContact
        );
        if (!reportResponse.success) {
          setThrowErrorMessage((prevState) => reportResponse.error);
          setThrowError((prevState) => true);
        }
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage(
          (prevState) => e.message || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError((prevState) => true);
      } finally {
        setIsLoading((prevState) => false);
      }
      return;
    }

    if (reportName === t("dashboard.withCatalogItemsMissingDownloadReport")) {
      try {
        setIsLoading(true);
        const reqBody = JSON.stringify(requestBody);

        const reportResponse = await printReport(
          reqBody,
          CF_URLS.printReports.todoReports.customersCatalogueItemsMissing
        );
        if (!reportResponse.success) {
          setThrowErrorMessage((prevState) => reportResponse.error);
          setThrowError((prevState) => true);
        }
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage(
          (prevState) => e.message || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError((prevState) => true);
      } finally {
        setIsLoading((prevState) => false);
      }
      return;
    }

    if (
      reportName ===
      t("dashboard.requiringAuthorizationPersonnelDownloadReport")
    ) {
      const requestPayload = {
        ...requestBody,
        count_flag: N_CHECKER,
        list_flag: Y_CHECKER,
        customer_id: ""
      };

      try {
        setIsLoading(true);
        const reqBody = JSON.stringify(requestPayload);

        const reportResponse = await printReport(
          reqBody,
          CF_URLS.printReports.todoReports.customersRequiringAuthPersonal
        );
        if (!reportResponse.success) {
          setThrowErrorMessage((prevState) => reportResponse.error);
          setThrowError((prevState) => true);
        }
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage(
          (prevState) => e.message || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError((prevState) => true);
      } finally {
        setIsLoading((prevState) => false);
      }
      return;
    }
  };

  //cars signoff data

  const getCarsSignOffData = async (district_Id) => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: district_Id,
        employee_id: auth?.user?.id
      };

      const { data } = await getResponseData(
        reqBody,
        `${CF_URLS.dashboard.todo.getCarsSignOffUrl}`,
        1
      );
      const firstData = data?.[0] || [];
      setCarSignOffId(firstData.length ? firstData[0].car_Id : "");
      setCarsSignOffCount(firstData.length);

      setIsLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    highPriorityDataLoaded.current = false;
    requestRunsDataLoaded.current = false;
    if (currentBranch?.district_id) {
      getCarsSignOffData(currentBranch?.district_id);
      switch (activeIndex) {
        case 0:
          if (!highPriorityDataLoaded.current) {
            getHighPriorityData(currentBranch?.district_id);
            highPriorityDataLoaded.current = true;
          }
          break;
        case 1:
          if (!requestRunsDataLoaded.current) {
            getRequestRunsData(currentBranch?.district_id);
            requestRunsDataLoaded.current = true;
          }
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch?.district_id]);

  useEffect(() => {
    if (activeIndex === 2) {
      getCustomerData(currentBranch?.district_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, currentBranch?.district_id]);

  return (
    <>
      {isLoading && <FullCircularLoader loading={isLoading} />}
      <Grid container spacing={2}>
        <Grid container spacing={1} justifyContent="center" mt={2}>
          <Typography sx={SystemRequestStyles.fontStyles}>
            {t("dashboard.todo")}
          </Typography>
        </Grid>
        <Grid container justifyContent="center" mt={3}>
          {colors.map((color, index) => (
            <Button
              key={color}
              style={{
                border: `${ToDoStyles.border} ${color}`,
                color:
                  activeIndex === index
                    ? ToDoStyles.colorWhite
                    : ToDoStyles.colorBlack,
                backgroundColor:
                  activeIndex === index
                    ? color
                    : ToDoStyles.transparentBackground,
                fontWeight:
                  activeIndex === index
                    ? ToDoStyles.typoBold
                    : ToDoStyles.typoNormal,
                borderRadius: ToDoStyles.buttonBorderRadius,
                margin: ToDoStyles.buttonMargin,
                padding: ToDoStyles.buttonPadding
              }}
              onClick={() => handleClick(index)}
            >
              {buttonLabels[index]}
            </Button>
          ))}
        </Grid>

        {showErrorAlert && (
          <Grid container justifyContent="center" mt={3}>
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" width="20" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowErrorAlert(false);
                    setShowErrorAlertMsg("");
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              style={ToDoStyles?.margin}
            >
              {Array.isArray(showErrorAlertMsg)
                ? showErrorAlertMsg.map((message, index) => (
                    <>
                      {index === 0 ? <b>{message}</b> : `${index}) ${message}`}
                      <br />
                    </>
                  ))
                : showErrorAlertMsg}
            </Alert>
          </Grid>
        )}

        {activeIndex === 0 && (
          <HighPriority
            openMediaMissingAtPicking={openMediaMissingAtPicking}
            requestsNotCompleted={requestsNotCompleted}
            unassignedRequests={unassignedRequests}
            containersMissingatStaging={containersMissingatStaging}
            containersMissingatArrival={containersMissingatArrival}
            inventoryPickedError={inventoryPickedError}
            carsSignOffCount={carsSignOffCount}
            onCardClick={onCardClick}
          />
        )}

        {activeIndex === 1 && (
          <RequestRuns
            allUnassignedRequests={allUnassignedRequests}
            requiringConfirmation={requiringConfirmation}
            drEvents={drEvents}
            customerToConfirm={customerToConfirm}
            branchToSchedule={branchToSchedule}
            runsNotCompleted={runsNotCompleted}
            incompleteStops={incompleteStops}
            onCardClick={onCardClickRequestRuns}
          />
        )}

        {activeIndex === 2 && (
          <Customers
            requiringServiceInformation={requiringServiceInformation}
            withServiceInformation={withServiceInformation}
            withSchedulesRequiringServiceWindows={
              withSchedulesRequiringServiceWindows
            }
            withSchedulesRequiringRoutes={withSchedulesRequiringRoutes}
            requiringPricingContracts={requiringPricingContracts}
            withCatalogItemsmissing={withCatalogItemsmissing}
            withExpiringSchedules={withExpiringSchedules}
            requiringAuthorizationPersonnel={requiringAuthorizationPersonnel}
            viewCustomerMoves={viewCustomerMoves}
            onCardClick={onCardClick}
          />
        )}

        {activeIndex === 3 && (
          <Containers
            setShowErrorAlert={setShowErrorAlert}
            setShowErrorAlertMsg={setShowErrorAlertMsg}
            setIsLoading={setIsLoading}
            currentBranch={currentBranch}
          />
        )}
        {activeIndex === 4 && (
          <OpenMedia
            setShowErrorAlert={setShowErrorAlert}
            setShowErrorAlertMsg={setShowErrorAlertMsg}
            setIsLoading={setIsLoading}
          />
        )}
        {activeIndex === 5 && (
          <CarDiscrepency
            setIsLoading={setIsLoading}
            setShowErrorAlert={setShowErrorAlert}
            setShowErrorAlertMsg={setShowErrorAlertMsg}
          />
        )}
      </Grid>

      {/* Report download */}
      <DownloadPopup
        title={
          reportName === t("dashboard.withCatalogItemsMissingDownloadReport") ||
          reportName ===
            t("dashboard.requiringAuthorizationPersonnelDownloadReport")
            ? t("common.downloadReport")
            : reportName
        }
        showPrintModal={showPrintReportModal}
        onCancleClick={onCancelClick}
        onDownloadClick={onDownloadClick}
        throwErrorMessage={throwErrorMessage}
        throwError={throwError}
        setSelectedValue={setSelectedReportType}
        cfUrl={reportCUrl}
      />
    </>
  );
};

export default ToDo;
