import { ThemeProvider, useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography,
  createTheme
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { CircularLoaderFullPage, SelectField } from "components/core";
import { SearchTableStyles } from "components/features/request-module/media-request-search/search-table/SearchTable.Styles";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import {
  getClassificationQueryData,
  getCommissionTypeQueryData
} from "services/api/query";
import { selectAuth } from "store/slices";
import { commonStyles } from "styles/commonStyles";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS, ERROR_TYPES } from "utils/constants";
import { findErrorMessage } from "utils/helpers";
import DownloadReportModal from "./DownloadReportModal";
import { billingRulesInterfaceStyles } from "./MaintainBillingRules.Styles";

const MaintainBillingRules = () => {
  const globalTheme = useTheme();
  const commonDataTableTheme = createTheme(globalTheme, {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            height: "52px",
            lineHeight: "30px",
            padding: "16px 24px !important"
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            height: "56px",
            padding: "16px 24px !important"
          }
        }
      }
    }
  });
  const [classifications, setClassifications] = useState([]);
  const [commissionTypes, setCommissionTypes] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResetGrid, setIsResetGrid] = useState(true);
  const [isFindButtonDisabled, setIsFindButtonDisabled] = useState(true);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const { t } = useTranslation();
  const { currentBranch } = useSelector(selectAuth);
  //page status
  const [pageToZero, setPageToZero] = useState(false);
  const selectedBranchId = currentBranch?.district_id;
  const [reportCFUrl, setReportCFUrl] = useState("");

  const initialState = {
    classification: ""
  };

  const interfaceRulesForm = useFormik({
    initialValues: initialState
  });

  useEffect(() => {
    getQueryData(
      getClassificationQueryData,
      "billing_classification_code",
      "descr",
      setClassifications
    );
    getQueryData(
      getCommissionTypeQueryData,
      "commission_type_id",
      "descr",
      setCommissionTypes
    );
  }, []);

  const getQueryData = async (
    queryName,
    valueField,
    labelField,
    setStateName
  ) => {
    try {
      const result = await queryName();
      const response = result.map((res) => ({
        value: res[valueField],
        label: res[labelField]
      }));
      setStateName(response);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const getResponseData = async (url) => {
    let requestBody = JSON.stringify({
      main_district_id: selectedBranchId,
      billing_classification_code: interfaceRulesForm.values.classification
    });

    const response = await callToCloudFunction(requestBody, url);
    const data = await getDataFromFirestore(response, 1, response.data.docId);
    return data;
  };

  const handleFind = async (event) => {
    event.preventDefault();
    try {
      setPageToZero(false);
      setIsLoading(true);
      let itemData = await getResponseData(
        `${CF_URLS.billingRules.getCatalogItem}`
      );
      let catalogItemData = itemData?.data[0];

      let identifierData = await getResponseData(
        `${CF_URLS.billingRules.getIdentifierInfo}`
      );
      let identifierInfoData = identifierData?.data[0];

      if (catalogItemData && identifierInfoData) {
        const mappedData = catalogItemData.map((item) => {
          const correspondingIdentifierInfo = identifierInfoData.find(
            (identifierInfoItem) =>
              identifierInfoItem.billing_identifier_id ===
              item.billing_identifier_id
          );

          const commissionTypeInfo = commissionTypes.find(
            (commissionTypeItem) =>
              commissionTypeItem.value === item.commission_type_id
          );

          return {
            ...item,
            identifier_type: correspondingIdentifierInfo?.type || null,
            commission_description: commissionTypeInfo?.label || null
          };
        });
        setSearchResults(mappedData);
      } else if (itemData?.error || identifierData?.error) {
        setThrowErrorMessage(itemData.error || identifierData.error);
        setThrowError(true);
      }
      setIsResetGrid(false);
      setIsFindButtonDisabled(true);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const handleClear = () => {
    interfaceRulesForm.resetForm();
    setSearchResults([]);
    setIsResetGrid(true);
    setIsFindButtonDisabled(true);
    setThrowError(false);
    setThrowErrorMessage(null);
    setPageToZero(true);
  };

  const handlePrint = () => {
    setIsVisible(true);
    const url = CF_URLS.printReports.billing.interfaceRulesReport;
    setReportCFUrl(url);
  };

  const columns = [
    {
      name: "catalog_item_code",
      label: t("billingInterfaceRules.catalogItemHeader")
    },
    {
      name: "invoice_line_descr",
      label: t("billingInterfaceRules.catalogItemDescriptionHeader")
    },
    {
      name: "identifier_type",
      label: t("billingInterfaceRules.identifierValueHeader")
    },
    {
      name: "variance_check_flag",
      label: t("billingInterfaceRules.performVarianceCheckHeader")
    },
    {
      name: "variance_threshold_pct",
      label: t("billingInterfaceRules.varianceThresholdPercentHeader"),
      options: {
        customBodyRender: (value) => <>{value ? `${value}%` : ""}</>
      }
    },
    {
      name: "flat_fee_flag",
      label: t("billingInterfaceRules.flatFeeHeader")
    },
    {
      name: "commission_description",
      label: t("billingInterfaceRules.commTypeHeader")
    }
  ];

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    selectableRowsHideCheckboxes: true,
    page: pageToZero && 0,
    textLabels: {
      body: {
        noMatch:
          !isResetGrid && searchResults.length === 0
            ? `${t("common.noDataFound")}`
            : `${t("common.tableTextWithoutInputs")}`
      },
      pagination: {
        jumpToPage: (
          <span style={SearchTableStyles.jumpToPage}>
            {t("requestSearch.jumpToPage")}
          </span>
        )
      }
    },
    customToolbar: searchResults.length
      ? () => (
          <>
            <div></div>
            <Button
              variant="outlined"
              onClick={(event) => handlePrint(event)}
              sx={billingRulesInterfaceStyles.dataTableStyles.PrintButton}
              disabled={searchResults.length === 0}
            >
              {t("common.print")}
            </Button>
          </>
        )
      : null
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}
      <form>
        {throwError && !isVisible && (
          <Collapse in={throwError}>
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
              sx={{ marginBottom: spacing.margingTop20 }}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          </Collapse>
        )}
        <Grid
          display={"flex"}
          flexDirection={"column"}
          gap={spacing.verticalMargin20}
        >
          <Grid container>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <SelectField
                options={classifications}
                labelId="classification"
                label={t("billingInterfaceRules.classificationLabel")}
                value={interfaceRulesForm.values.classification}
                onChange={(event) => {
                  interfaceRulesForm.setFieldValue(
                    "classification",
                    event.target.value
                  );
                  setIsFindButtonDisabled(false);
                }}
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={spacing.verticalMargin20}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={(event) => handleClear(event)}
              >
                {t("common.clear")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                disabled={isFindButtonDisabled}
                variant="contained"
                onClick={(event) => handleFind(event)}
                sx={billingRulesInterfaceStyles.classificationStyles.findButton}
              >
                {t("common.find")}
              </Button>
            </Grid>
          </Stack>

          <ThemeProvider theme={commonDataTableTheme}>
            <MUIDataTable
              options={options}
              data={searchResults}
              columns={columns}
              title={
                !isResetGrid && (
                  <Typography style={commonStyles.tableRecordsFoundTitleStyle}>
                    {searchResults.length} {t("common.recordsFound")}
                  </Typography>
                )
              }
            />
          </ThemeProvider>
        </Grid>
      </form>
      {isVisible && (
        <DownloadReportModal
          {...{
            isVisible,
            setIsVisible,
            currentBranch,
            classification: interfaceRulesForm.values.classification,
            setThrowError,
            throwError,
            setThrowErrorMessage,
            throwErrorMessage,
            setIsLoading,
            reportCFUrl,
            setReportCFUrl
          }}
        />
      )}
    </>
  );
};

export default MaintainBillingRules;
