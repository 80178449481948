import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_LOCALE } from "utils/constants";

const user = {};
const googleUser = {};
const auth = {
  isAuthenticated: false, // firebase authentication is success or failes
  isLoading: false,
  error: null,
  user, // worker table user
  googleUser // firebase authenticated user
};

const initialState = {
  loading: false,
  user: {},
  error: null, // worker verification error
  auth,
  roleAccess: [],
  branches: [], // users branches
  currentBranch: null, // selected branch
  timeZoneOffset: null, //get timezone offset from sp
  localeGetDate: null, //get local_getdate from sp
  authInfo: {}, // initialize authInfo from local storage
  localeByBranch: DEFAULT_LOCALE,
  localeByBranchLoaded: false,
  allLocations: [], //all locations
  isError: null,
  sessionId: null,
  isTimeZoneOffsetLoaded: false
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInfo: (state, { payload }) => {
      state.authInfo = JSON.parse(payload);
      state.auth.isAuthenticated = true;
    },
    removeAuthInfo: (state) => {
      // removes authInfo from storage
      state.authInfo = null;
    },
    updateBranch: (state, { payload }) => {
      state.authInfo = {
        ...state.authInfo,
        branch: payload
      };
    },
    setIRMEmployeeInfo: (state, { payload }) => {
      state.auth.user = JSON.parse(payload);
    },
    setIRMEmployeeGetError: (state, { payload }) => {
      state.error = "Not an employee";
    },
    logoutSuccess: (state, action) => {
      state.auth = {
        loading: false,
        user: {},
        error: null,
        auth: {
          isAuthenticated: false,
          isLoading: false,
          error: null,
          user: {},
          googleUser: {}
        },
        roleAccess: [],
        branches: [],
        currentBranch: null
      };
    },
    setAssignedBranches: (state, { payload }) => {
      state.branches = payload;
    },
    setCurrentBranch: (state, { payload }) => {
      state.currentBranch = payload;
      localStorage.setItem("branch", payload?.district_id);
    },
    setRoleAccess: (state, { payload }) => {
      state.roleAccess = payload;
    },
    setLoggedUserData: (state, { payload }) => {
      state.error = null;
      state.isError = false;
      state.auth.user = payload.employee;
      state.roleAccess = payload.roleAccess;
      state.branches = payload.branches;
      state.auth.sessionId = payload.sessionId;
    },
    setTimeZoneOffset: (state, action) => {
      state.timeZoneOffset = action.payload?.time_zone_offset;
      state.localeGetDate = action.payload?.local_getdate;
      state.isTimeZoneOffsetLoaded = true;
    },
    setBranchLocale: (state, { payload }) => {
      state.localeByBranch = payload;
      state.localeByBranchLoaded = true;
    },
    setLocations: (state, { payload }) => {
      state.allLocations = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      state.isError = true;
    },
    updateLoggedUserData: (state, { payload }) => {
      state.auth.sessionId = payload.sessionId;
    }
  }
});

export default authSlice.reducer;
