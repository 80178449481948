import {
  Button,
  Grid,
  Paper,
  SvgIcon,
  TableContainer,
  Typography
} from "@mui/material";
import { ReactComponent as RemoveRedEyeFilled } from "assets/images/RemoveRedEyeFilled.svg";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import spacing from "styles/spacing";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { personalSearchStyles } from "./PersonnelSearchStyles";

const PersonnelSearchTable = ({
  personalSearchTableData,
  handleNewButtonClick,
  handlePrintButtonClick,
  onNavigate,
  personalSearchForm,
  isAuthenticated,
  hasPermissionToNavigateToDetail
}) => {
  //Table columns
  const columns = [
    {
      name: "district_id",
      label: t("enterpriseBillingSummary.emptyLabelHeader"),
      options: {
        filter: true,
        sort: false,
        download: false,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => {
          const rowData = personalSearchTableData[tableMeta?.rowIndex];
          return (
            <Button
              onClick={() => {
                onNavigate(value, tableMeta);
              }}
              sx={{
                opacity:
                  isAuthenticated &&
                  !(
                    personalSearchForm?.values?.personnel?.value ===
                      rowData?.personnel_id || hasPermissionToNavigateToDetail
                  )
                    ? 0.5
                    : 1
              }}
              disabled={
                isAuthenticated
                  ? !(
                      personalSearchForm?.values?.personnel?.value ===
                        rowData?.personnel_id || hasPermissionToNavigateToDetail
                    )
                  : false
              }
            >
              <SvgIcon component={RemoveRedEyeFilled} />
            </Button>
          );
        },
        customHeadLabelRender: (columnMeta) => <div>{columnMeta.label}</div>
      }
    },
    {
      name: "name",
      label: t("personalSearch.nameColumn"),
      options: {
        sort: true
      }
    },
    {
      name: "login",
      label: t("personalSearch.userIDColumn"),
      options: {
        sort: true
      }
    },
    {
      name: "customer_number",
      label: t("personalSearch.customerNumberColumn"),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = personalSearchTableData[dataIndex];
          const { customer_number } = rowData;
          //split the customer string and create a array
          let cusArray = customer_number
            ?.split(",")
            .map((cusNo) => cusNo.trim());
          //remove duplicate customers
          let cusArrayDupRemoved = [...new Set(cusArray)];
          //sort this array asc
          cusArrayDupRemoved.sort((a, b) => {
            const numA = Number(a);
            const numB = Number(b);
            if (!isNaN(numA) && !isNaN(numB)) {
              return numA - numB;
            }

            return a.localeCompare(b);
          });

          //join the array back to a string
          let sortedCustStr = cusArrayDupRemoved.join(",");

          // Truncate message to 75 characters
          const truncatedMessage =
            sortedCustStr.length > 5
              ? `${cusArrayDupRemoved.slice(0, 5).join(",")}...`
              : cusArrayDupRemoved.join(",");
          return truncatedMessage;
        }
      }
    }
  ];

  //Table Options
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    sort: true,
    pagination: personalSearchTableData?.length && true,
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={isAuthenticated && !hasPermissionToNavigateToDetail}
            onClick={() => handleNewButtonClick()}
          >
            {t("common.new")}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={personalSearchTableData?.length === 0}
            onClick={() => handlePrintButtonClick()}
          >
            {t("common.print")}
          </Button>
        </Grid>
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          personalSearchTableData?.length === 0 &&
          `${t("common.noRecordsFound")}`
      }
    }
  };

  return (
    <>
      <Paper sx={personalSearchStyles?.tablePaper}>
        <TableContainer>
          <MUIDataTable
            columns={columns}
            options={options}
            data={personalSearchTableData}
            title={
              <Typography style={personalSearchStyles.titleStyle}>
                {personalSearchTableData?.length} {t("common.recordsFound")}
              </Typography>
            }
          />
        </TableContainer>
      </Paper>
    </>
  );
};

export default PersonnelSearchTable;
