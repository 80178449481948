import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  OPEN_MEDIA_AUDIT_DETAIL,
  OPEN_MEDIA_AUDIT_SEARCH,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH,
  WORK_MANAGEMENT
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import {
  downloadOptionPdf,
  ERROR_TYPES,
  notApplicable,
  notAvailableValue,
  VALUE_EMPTY_STRING,
  VALUE_N,
  Y_CHECKER
} from "utils/constants";
import {
  distributeOptionReadyToDistribute,
  distributeOptionReadyToScan,
  distributePastDue,
  distributionTabId,
  pickingOptionnonCompletedTask,
  pickingTabId,
  verifyConstants,
  verifyFilterOptions,
  verifyTabId
} from "utils/constants/open-media-processing/WorkManagement";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getAuthenticatedUserName,
  getResponseData,
  getUserName,
  printReport
} from "utils/helpers";
import ToDoItem from "./ToDoItem";

const OpenMedia = ({
  setIsLoading,
  setShowErrorAlert,
  setShowErrorAlertMsg
}) => {
  const { t } = useTranslation();
  const { currentBranch } = useSelector(selectAuth);
  const navigate = useNavigate();
  const [reportName, setReportName] = useState("");
  const [openMediasToDos, setOpenMediasToDos] = useState({
    batchesReadyForDist: 0,
    distBatchesWaiting: 0,
    unprocessedDist: 0,
    nonCompletedPicks: 0,
    nonCompletedOpenMediaAudits: 0,
    batchesReadyToVerify: 0,
    openMediaRequiringDist: 0,
    missingInvetory: 0,
    openMediaRackingNearCap: 0
  }); // to-do open media counts state
  const [selectedReportType, setSelectedReportType] =
    useState(downloadOptionPdf);
  const [reportCUrl, setReportCFUrl] = useState(VALUE_EMPTY_STRING);
  const [downloadReport, setDownloadReport] = useState({
    open: false,
    reportUrl: null,
    requestBody: null
  });
  const { auth } = useSelector(selectAuth);
  const [throwError, setThrowError] = useState(false); // error message show/hide state
  const [throwErrorMessage, setThrowErrorMessage] = useState(""); // error message state
  const userName = getUserName(auth?.user);
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );
  // fetch open media to-do's
  const fetchTodoOpenMedia = async (districtId) => {
    try {
      setIsLoading(true);
      const reqBody = {
        main_district_id: districtId,
        spid: VALUE_EMPTY_STRING
      };

      const openMediaResponse = await getResponseData(
        reqBody,
        `${CF_URLS.dashboard.todo.todoOpenMedia}`,
        10
      );

      const openMediaData = openMediaResponse.data;
      setOpenMediasToDos((prevState) => ({
        batchesReadyForDist: openMediaData[0][0]?.count,
        distBatchesWaiting: openMediaData[6][0]?.count,
        unprocessedDist: openMediaData[2][0]?.count,
        nonCompletedPicks: openMediaData[1][0]?.count,
        nonCompletedOpenMediaAudits: openMediaData[8][0]?.count,
        batchesReadyToVerify: openMediaData[7][0]?.count,
        openMediaRequiringDist: openMediaData[5][0]?.count,
        missingInvetory: openMediaData[4][0]?.count,
        openMediaRackingNearCap: openMediaData[3][0]?.count,
        openMediaDistributionBatches: openMediaData[9][0]?.count
      }));
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setIsLoading(false);
    }
  };

  // card items click event handler
  const onItemClick = async (labelText) => {
    switch (labelText) {
      case t("dashboard.batchesReadyforDistribution"):
        // redirect to Work Management (Distribution)
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${WORK_MANAGEMENT}`,
          {
            state: {
              tab: distributionTabId,
              filter: distributeOptionReadyToDistribute,
              prior_to_today_flag: Y_CHECKER
            }
          }
        );
        break;
      case t("dashboard.distributionBatchesWaitingforLists"):
        // redirect to Work Management (Verify)
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${WORK_MANAGEMENT}`,
          {
            state: {
              tab: verifyTabId,
              filter: verifyFilterOptions.batchesWaitingForList
            }
          }
        );
        break;
      case t("dashboard.unprocessedDistributionLists"):
        // redirect to Work Management (Distribution)
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${WORK_MANAGEMENT}`,
          {
            state: {
              tab: distributionTabId,
              filter: distributeOptionReadyToScan,
              option: distributePastDue
            }
          }
        );
        break;
      case t("dashboard.nonCompletedPickTasks"):
        // redirect to Work Management (Picking)
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${WORK_MANAGEMENT}`,
          {
            state: { tab: pickingTabId, filter: pickingOptionnonCompletedTask }
          }
        );
        break;
      case t("dashboard.nonCompletedOpenMediaAudits"):
        // redirect to Open Media Audit Search
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${OPEN_MEDIA_AUDIT_SEARCH}`,
          { state: { auditStatus: verifyConstants.lvForListGrid } }
        );
        break;
      case t("dashboard.batchesReadytoVerify"):
        // redirect to Work Management (Verify)
        navigate(
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${WORK_MANAGEMENT}`,
          {
            state: {
              tab: verifyTabId,
              filter: verifyFilterOptions.readyToVerify
            }
          }
        );
        break;
      case t("dashboard.openMediaRequiringRedistribution"):
        setReportName(t("dashboard.openMediaRequiringRedistribution"));
        setDownloadReport((prevState) => ({
          open: true,
          reportUrl:
            CF_URLS.printReports.openMediaProcessing
              .openMediaRequiringDistribution,
          requestBody: {
            main_district_id: currentBranch?.district_id,
            district_id: currentBranch?.district_id,
            count_only_flag: VALUE_N,
            count: openMediasToDos.openMediaRequiringDist,
            user_name: getAuthenticatedUserName(),
            report_format: selectedReportType?.type?.toUpperCase()
          }
        }));
        break;
      case t("dashboard.missingInventoryFiles"):
        setReportName(t("dashboard.missingInventoryFiles"));
        setDownloadReport((prevState) => ({
          open: true,
          reportUrl: "REPORT_URL",
          requestBody: {
            main_district_id: currentBranch?.district_id,
            district_id: currentBranch?.district_id,
            district_name: currentBranch?.label,
            user_name: getAuthenticatedUserName(),
            report_format: selectedReportType?.type?.toUpperCase()
          }
        }));
        break;
      case t("dashboard.openMediaRackingNearCapacity"):
        setReportName(t("dashboard.openMediaRackingNearCapacity"));
        setDownloadReport((prevState) => ({
          open: true,
          reportUrl:
            CF_URLS.printReports.todoReports.openMediaRackingNearCapacity,
          requestBody: {
            main_district_id: currentBranch?.district_id,
            district_id: currentBranch?.district_id,
            district_name: currentBranch?.label,
            user_name: getAuthenticatedUserName(),
            report_format: selectedReportType?.type?.toUpperCase()
          }
        }));
        break;
      case t("dashboard.openMediaDistributionBatches"):
        {
          // redirect to Open Media Audit detail
          const todoProps = {
            requestedBy: t("dashboard.branch"),
            auditType: t("openMediaAuditDetail.distribution")
          };
          navigate(
            `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${OPEN_MEDIA_AUDIT_DETAIL}`,
            {
              state: { todoProps }
            }
          );
        }
        break;
      default:
        // default
        break;
    }
  };

  // donwload report cancel click event handler
  const handleDownloadReportCancelOnClick = () => {
    setDownloadReport((prevState) => ({
      open: false,
      reportUrl: null,
      requestBody: null
    }));
    setThrowErrorMessage((prevState) => "");
    setThrowError((prevState) => false);
  };
  // common payload
  const requestBody = {
    main_district_id: mainDistrictId,
    user_name: userName,
    report_format: selectedReportType?.type?.toUpperCase()
  };

  useEffect(() => {
    if (downloadReport.open) {
      if (reportName === t("dashboard.missingInventoryFiles")) {
        setReportCFUrl(
          CF_URLS.printReports.todoReports.openMediaMissingInventoryFiles
        );
      } else {
        setReportCFUrl(downloadReport.reportUrl);
      }
    } else {
      setReportCFUrl(VALUE_EMPTY_STRING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReport.open]);

  // download report click event handler
  const handleDownloadOnClick = async () => {
    if (reportName === t("dashboard.missingInventoryFiles")) {
      const requestPayload = {
        ...requestBody,
        criteria: notApplicable
      };

      try {
        setIsLoading(true);
        const reqBody = JSON.stringify(requestPayload);

        const reportResponse = await printReport(
          reqBody,
          CF_URLS.printReports.todoReports.openMediaMissingInventoryFiles
        );
        if (!reportResponse.success) {
          setThrowErrorMessage((prevState) => reportResponse.error);
          setThrowError((prevState) => true);
        }
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage(
          (prevState) => e.message || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError((prevState) => true);
      } finally {
        setIsLoading((prevState) => false);
      }
      return;
    }

    if (reportName === t("dashboard.openMediaRequiringRedistribution")) {
      try {
        setIsLoading(true);
        const requestPayload = {
          ...downloadReport.requestBody,
          criteria: notAvailableValue
        };
        const reqBody = JSON.stringify(requestPayload);

        const reportResponse = await printReport(
          reqBody,
          downloadReport.reportUrl
        );
        if (!reportResponse.success) {
          setThrowErrorMessage((prevState) => reportResponse.error);
          setThrowError((prevState) => true);
        }
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage(
          (prevState) => e.message || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError((prevState) => true);
      } finally {
        setIsLoading((prevState) => false);
      }
    }
    if (reportName === t("dashboard.openMediaRackingNearCapacity")) {
      try {
        setIsLoading(true);
        const requestPayload = {
          ...downloadReport.requestBody
        };
        const reqBody = JSON.stringify(requestPayload);

        const reportResponse = await printReport(
          reqBody,
          downloadReport.reportUrl
        );
        if (!reportResponse.success) {
          setThrowErrorMessage((prevState) => reportResponse.error);
          setThrowError((prevState) => true);
        }
        setIsLoading(false);
      } catch (e) {
        setThrowErrorMessage(
          (prevState) => e.message || findErrorMessage(ERROR_TYPES.ISSUE)
        );
        setThrowError((prevState) => true);
      } finally {
        setIsLoading((prevState) => false);
      }
    }
  };

  // init
  useEffect(() => {
    fetchTodoOpenMedia(currentBranch?.district_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={12} md={6}>
        <ToDoItem
          labelText={t("dashboard.batchesReadyforDistribution")}
          number={openMediasToDos.batchesReadyForDist}
          onCardClick={onItemClick}
        />
        <ToDoItem
          labelText={t("dashboard.distributionBatchesWaitingforLists")}
          number={openMediasToDos.distBatchesWaiting}
          onCardClick={onItemClick}
        />
        <ToDoItem
          labelText={t("dashboard.unprocessedDistributionLists")}
          number={openMediasToDos.unprocessedDist}
          onCardClick={onItemClick}
        />
        <ToDoItem
          labelText={t("dashboard.nonCompletedPickTasks")}
          number={openMediasToDos.nonCompletedPicks}
          onCardClick={onItemClick}
        />
        <ToDoItem
          labelText={t("dashboard.nonCompletedOpenMediaAudits")}
          number={openMediasToDos.nonCompletedOpenMediaAudits}
          onCardClick={onItemClick}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ToDoItem
          labelText={t("dashboard.batchesReadytoVerify")}
          number={openMediasToDos.batchesReadyToVerify}
          onCardClick={onItemClick}
        />
        <ToDoItem
          labelText={t("dashboard.openMediaRequiringRedistribution")}
          number={openMediasToDos.openMediaRequiringDist}
          onCardClick={onItemClick}
        />
        <ToDoItem
          labelText={t("dashboard.missingInventoryFiles")}
          number={openMediasToDos.missingInvetory}
          onCardClick={onItemClick}
        />
        <ToDoItem
          labelText={t("dashboard.openMediaRackingNearCapacity")}
          number={openMediasToDos.openMediaRackingNearCap}
          onCardClick={onItemClick}
        />
        <ToDoItem
          labelText={t("dashboard.openMediaDistributionBatches")}
          number={openMediasToDos.openMediaDistributionBatches}
          onCardClick={onItemClick}
        />
      </Grid>

      {downloadReport.open && (
        <DownloadPopup
          title={t("common.downloadReport")}
          showPrintModal={downloadReport.open}
          onCancleClick={handleDownloadReportCancelOnClick}
          onDownloadClick={handleDownloadOnClick}
          setSelectedValue={setSelectedReportType}
          setThrowError={setThrowError}
          throwError={throwError}
          throwErrorMessage={throwErrorMessage}
          cfUrl={reportCUrl}
        />
      )}
    </>
  );
};

OpenMedia.propTypes = {
  setIsLoading: PropTypes.func.isRequired, // loading indicator show/hide
  setShowErrorAlert: PropTypes.func.isRequired, // alert show/hide
  setShowErrorAlertMsg: PropTypes.func.isRequired // error message
};

export default OpenMedia;
