import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { DatePickerField, FullCircularLoader } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import CommonModal from "components/shared/common-modal/CommonModal";

import {
  getFormFactors,
  getPhysicalVaultsService,
  getRackUnderRowService,
  getRowUnderPhysicalVaultService
} from "components/vault-configuration/services";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllCustomersQuery } from "services/api";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  dateFormat,
  downloadOptionPdf,
  ERROR_TYPES,
  errorMsgs,
  unauthorized,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y
} from "utils/constants";
import {
  vaultConfigurationPayloadProp,
  zero
} from "utils/constants/vault-configuration/VaultConfiguration";
import {
  defaultCapacityPercentage,
  dropDownOptions,
  fields,
  globalAttributeId,
  minimumDate,
  na,
  numberOfPeriods,
  options,
  percentage,
  percentageMax,
  programTypes,
  reportTypes,
  systemId,
  vaults
} from "utils/constants/vault-management-reports/VaultManagementReports";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getGlobalAttributeValue,
  getResponseData,
  getTheFirestoreErrorMessage,
  getUserName,
  printReport
} from "utils/helpers";
import { VaultManagementReportsStyles } from "./vaultManagementReportsStyles";

let defaultOption = {
  label: t("common.all"),
  value: VALUE_EMPTY_STRING
};

const VaultManagementReports = ({ open, setIsReportsOpen, fromDashboard }) => {
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [reportType, setReportType] = useState(
    fromDashboard ? reportTypes.capacity : reportTypes.configuration
  );
  const [selectedReportOption, setSelectedReportOption] =
    useState(VALUE_EMPTY_STRING);
  const [programType, setProgramType] = useState(programTypes.all);
  const [includeDetails, setIncludeDetails] = useState(false);
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] =
    useState(VALUE_EMPTY_STRING);
  const [isDownLoadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);
  const { currentBranch, auth } = useSelector(selectAuth);
  const [selectedCustomer, setSelectedCustomer] = useState({
    ...defaultOption
  });
  const [disabledList, setDisabledList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [capacityPercentage, setCapacityPercentage] =
    useState(VALUE_EMPTY_STRING);
  const [physicalVaults, setPhysicalVaults] = useState([]);
  const [selectedPhysicalVault, setSelectedPhysicalVault] = useState({
    ...defaultOption
  });
  const [rows, setRows] = useState([]);
  const [logicalVaults, setLogicalVaults] = useState([]);
  const [selectedLogicalVault, setSelectedLogicalVault] = useState({
    ...defaultOption
  });

  const [selectedRow, setSelectedRow] = useState({ ...defaultOption });
  const [errorMessage, setErrorMessage] = useState(VALUE_EMPTY_STRING);
  const [vault, setVault] = useState(vaults.all);
  const [racks, setRacks] = useState([]);
  const [selectedRack, setSelectedRack] = useState({ ...defaultOption });
  const [formFactors, setFormFactors] = useState([]);
  const [selectedFormFactor, setSelectedFormFactor] = useState({
    ...defaultOption
  });
  const [loaders, setLoaders] = useState([]);
  const [dateFrom, setDateFrom] = useState(dayjs());
  const [dateTo, setDateTo] = useState(dayjs());
  const [disableReset, setDisableReset] = useState(true);
  const districtId = getAuthenticatedUserBranch();
  const { localeByBranch } = useSelector(selectAuth);
  const [reportCFUrl, setReportCFUrl] = useState("");
  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });

  const today = dayjs();
  const minDate = dayjs(minimumDate);
  const maxDate = dayjs(today);

  // handle session timeout and uncought error
  const handleCatchError = (error) => {
    if (error?.response?.data === unauthorized) {
      setErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
    } else {
      setErrorMessage(t("printTapeLabel.systemError"));
    }
  };

  const getLogicalVaultsOptions = () => {
    let list = [];
    if (programType === programTypes.openMedia) {
      list = logicalVaults.filter(
        (lv) => lv.type === programTypes.openMediaCode || !lv.type
      );
    } else if (programType === programTypes.container) {
      list = logicalVaults.filter(
        (lv) => lv.type !== programTypes.openMediaCode || !lv.type
      );
    } else {
      list = logicalVaults;
    }
    return [
      {
        label: t("common.all"),
        value: VALUE_EMPTY_STRING
      },
      ...list
    ];
  };
  const getPhysicalVaults = async () => {
    if (loaders.includes(fields.vault)) return;
    if (!physicalVaults.length) {
      setLoaders((prev) => [...prev, fields.vault]);
      try {
        const res = await getPhysicalVaultsService(districtId);
        if (res?.data?.[0]) {
          let vaults = res.data[0].map((pv) => ({
            physicalVaultId: pv.physical_vault_id,
            physicalVaultNumber: pv.physical_vault_number
          }));
          let sorted = vaults.sort(
            (a, b) => a.physicalVaultNumber - b.physicalVaultNumber
          );

          setPhysicalVaults(
            sorted.map((v) => ({
              label: v.physicalVaultNumber,
              value: v.physicalVaultId
            }))
          );
        }
      } catch (error) {
        handleCatchError(error);
      } finally {
        setLoaders((prev) => prev.filter((id) => id !== fields.vault));
      }
    }
  };

  useEffect(() => {
    if (selectedPhysicalVault.value)
      getRowUnderPhysicalVault(selectedPhysicalVault.value);
    //eslint-disable-next-line
  }, [selectedPhysicalVault.value]);

  const getRowUnderPhysicalVault = async (vaultId) => {
    setLoaders((prev) => [...prev, fields.row]);
    try {
      const res = await getRowUnderPhysicalVaultService(districtId, vaultId);
      if (res?.data?.[0]) {
        let rows = res.data[0].map((row) => ({
          rowId: row.row_id,
          rowNumber: row.row_number
        }));
        let sorted = rows.sort((a, b) => a.rowNumber - b.rowNumber);

        setRows(
          sorted.map((v) => ({
            label: v.rowNumber,
            value: v.rowId
          }))
        );
        setDisabledList((prev) => prev.filter((id) => id !== fields.row));
        setSelectedRow({ ...defaultOption });
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setLoaders((prev) => prev.filter((id) => id !== fields.row));
    }
  };

  const getRacksUnderRows = async (vaultId, rowId) => {
    setLoaders((prev) => [...prev, fields.rack]);
    try {
      const res = await getRackUnderRowService(districtId, vaultId, rowId);
      if (res?.data?.[0]) {
        let racks = res.data[0].map((rack) => ({
          rackId: rack.rack_id,
          rackNumber: rack.rack_number
        }));
        let sorted = racks.sort((a, b) => a.rackNumber - b.rackNumber);

        setRacks(
          sorted.map((v) => ({
            label: v.rackNumber,
            value: v.rackId
          }))
        );
        setSelectedRack({ ...defaultOption });
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setLoaders((prev) => prev.filter((id) => id !== fields.rack));
    }
  };
  useEffect(() => {
    if (
      (reportType === reportTypes.configuration &&
        selectedCustomer?.value &&
        programType === programTypes.openMedia) ||
      selectedReportOption === options.inboundOutboundInventory
    ) {
      getLogicalVaults();
    }
    //eslint-disable-next-line
  }, [selectedCustomer?.value, reportType, selectedReportOption]);

  const getLogicalVaults = async () => {
    if (vault === vaults.customer || !selectedCustomer?.value) return;
    setLoaders((prev) => [...prev, fields.logicalVaultField]);
    try {
      //eslint-disable-next-line
      let reqBody = {
        main_district_id: districtId,
        customer_id: selectedCustomer?.value,
        display_all: VALUE_Y,
        display_media_type: VALUE_N
      };
      let res = null;
      if (reportType === reportTypes.configuration) {
        let url = CF_URLS.vaultConfiguration.logicalvaultsForCustomers;
        res = await getResponseData(reqBody, url, 2);
      } else if (selectedReportOption === options.inboundOutboundInventory) {
        let url = CF_URLS.vaultConfiguration.activeLogicalVaults;
        res = await getResponseData(reqBody, url, 2);
      }

      let data = res.data;
      let logicalVaultOptions = data?.[0]
        .map((lv) => ({
          value: lv.logical_vault_id,
          label: lv.code,
          type: lv.media_program_type_id?.trim() || VALUE_EMPTY_STRING
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setLogicalVaults(logicalVaultOptions);
    } catch (error) {
      handleCatchError(error);
    } finally {
      setLoaders((prev) =>
        prev.filter((id) => id !== fields.logicalVaultField)
      );
    }
  };

  const getFormFactorsList = async () => {
    if (loaders.includes(fields.formFactor)) return;
    if (formFactors.length) return;
    setLoaders((prev) => [...prev, fields.formFactor]);

    try {
      let { all, c, m, container } = programTypes;
      let containerMediaFlag =
        programType === all
          ? VALUE_EMPTY_STRING
          : programType === container
            ? c
            : m;
      const res = await getFormFactors(districtId, containerMediaFlag);
      if (res?.data[0] && res.data[0].length) {
        let data = res.data[0];
        data = data.sort((a, b) => a.form_factor_id - b.form_factor_id);
        setFormFactors(
          data.map((f) => ({
            value: f.form_factor_id,
            label: f.short_descr,
            type: f.container_media_flag
          }))
        );
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setLoaders((prev) => prev.filter((id) => id !== fields.formFactor));
    }
  };

  useEffect(() => {
    if (
      reportType === reportTypes.configuration ||
      reportType === reportTypes.capacity
    )
      getPhysicalVaults();

    if (reportType === reportTypes.capacity)
      setProgramType(programTypes.openMedia);

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (reportType === reportTypes.configuration) {
      getFormFactorsList();
    }
    //eslint-disable-next-line
  }, [reportType, programType]);

  const onChangeRow = (val) => {
    setRacks([]);
    setSelectedRack({ ...defaultOption });
    if (val?.value) {
      setSelectedRow(val);
      if (reportType === reportTypes.configuration)
        getRacksUnderRows(selectedPhysicalVault.value, val.value);
    } else {
      setSelectedRow({ ...defaultOption });
    }
    setDisableReset(false);
  };

  const handleReportTypeChange = (event) => {
    reset();
    let value = event.target.value;
    if (value === reportTypes.capacity) {
      setDisabledList((prev) => prev.filter((id) => id !== fields.customer));
      setProgramType(programTypes.openMedia);
    }
    if (value === reportTypes.configuration) {
      setProgramType(programTypes.all);
      setVault(vaults.all);
    }

    setErrorMessage(VALUE_EMPTY_STRING);
    setReportType(value);
    setDisabledList([]);
    setSelectedCustomer({ ...defaultOption });
    setDisableReset(false);
  };

  const onChangeProgramType = (val) => {
    if (reportType === reportTypes.configuration)
      if (val === programTypes.openMedia) {
        setDisabledList((prev) =>
          prev
            .filter(
              (id) =>
                ![
                  fields.logicalVaultField,
                  fields.customer,
                  fields.formFactor
                ].includes(id)
            )
            .concat(fields.vault)
        );
        setVault(vaults.all);
      } else if (val === programTypes.container) {
        setDisabledList((prev) =>
          prev
            .filter((id) => ![fields.vault, fields.formFactor].includes(id))
            .concat([fields.logicalVaultField, fields.customer])
        );

        setSelectedCustomer({ ...defaultOption });
      } else {
        let addToDisabled = [fields.formFactor];
        if (vault === vaults.branch) {
          addToDisabled.push(fields.customer);
        }
        setDisabledList((prev) =>
          prev
            .filter((id) => ![fields.customer, fields.vault].includes(id))
            .concat(addToDisabled)
        );
      }
    setSelectedLogicalVault({ ...defaultOption });
    setSelectedFormFactor({ ...defaultOption });
    setSelectedCustomer({ ...defaultOption });
    setIncludeDetails(false);
    setProgramType(val);
    setDisableReset(false);
  };

  const handleCustomerChange = (e, newValue) => {
    setErrorMessage(VALUE_EMPTY_STRING);
    setLogicalVaults([]);
    setSelectedLogicalVault({ ...defaultOption });
    if (newValue?.value) {
      setSelectedCustomer(newValue);
    } else if (selectedReportOption === options.inboundOutboundInventory) {
      setSelectedCustomer(null);
    } else {
      setSelectedCustomer({ ...defaultOption });
    }
    setDisableReset(false);
  };

  const handleCheckboxChange = (event) => {
    setIncludeDetails(event.target.checked);
  };

  const handleModelOpen = async () => {
    if (
      selectedReportOption === options.capacityGreaterThan ||
      selectedReportOption === options.capacityLessThan
    ) {
      if (capacityPercentage > 99 || capacityPercentage <= 0) {
        let { errorMsg } = await getTheFirestoreErrorMessage(
          errorMsgs.errorCode20547
        );
        errorMsg = errorMsg
          .replace("|", t("vaultManagementReports.capacityPercentage"))
          .replace("|", zero)
          .replace("|", 100);

        setErrorMessage(errorMsg);
        return;
      }
    }

    if (selectedReportOption === options.inboundOutboundInventory) {
      if (!selectedCustomer?.value) {
        let { errorMsg } = await getTheFirestoreErrorMessage(
          errorMsgs.errorCode20259
        );
        setErrorMessage(errorMsg.replace("|", t("common.customer")));
        return;
      }
    }

    if (
      selectedReportOption === options.detail &&
      programType === programTypes.openMedia
    ) {
      const isCustomerLogicalVaultSelected =
        selectedCustomer?.value && selectedLogicalVault?.value;
      const isCustomerFormFactorSelected =
        selectedCustomer?.value && selectedFormFactor.value;
      const isPhysicalVaultRowSelected =
        selectedPhysicalVault?.value && selectedRow?.value;
      const isPhysicalVaultFormFactorSelected =
        selectedPhysicalVault?.value && selectedFormFactor.value;
      if (
        !(
          isCustomerLogicalVaultSelected ||
          isCustomerFormFactorSelected ||
          isPhysicalVaultRowSelected ||
          isPhysicalVaultFormFactorSelected
        )
      ) {
        let { errorMsg } = await getTheFirestoreErrorMessage(
          errorMsgs.errorCode25141
        );

        setErrorMessage(errorMsg);
        return;
      }
    }

    setIsDownloadModalOpen(true);
    let url = null;
    if (reportType === reportTypes.capacity) {
      if (
        selectedReportOption === options.showAllCapacities &&
        includeDetails
      ) {
        url = CF_URLS.printReports.vaultConfiguration.vaultCapacityDetail;
      }

      if (
        (selectedReportOption === options.showAllCapacities ||
          selectedReportOption === options.capacityGreaterThan ||
          selectedReportOption === options.capacityLessThan) &&
        !includeDetails
      ) {
        url = CF_URLS.printReports.vaultConfiguration.summeryReport;
      }

      if (selectedReportOption === options.unassignedShelves) {
        url = CF_URLS.printReports.vaultConfiguration.unassignShelvesReport;
      }

      if (selectedReportOption === options.capacityHistory) {
        url =
          CF_URLS.printReports.vaultConfiguration.vaultCapacityHistoryReport;
      }

      if (selectedReportOption === options.availableCapacity) {
        url = CF_URLS.printReports.vaultConfiguration.availableCapacityReport;
      }
      if (selectedReportOption === options.rackingNearCapacity) {
        if (programType === programTypes.openMedia) {
          url =
            CF_URLS.printReports.vaultConfiguration
              .openMediaRackingNearCapacity;
        } else {
          url =
            CF_URLS.printReports.vaultConfiguration.rackingNearCapacityReport;
        }
      }
    }

    if (reportType === reportTypes.configuration) {
      if (selectedReportOption === options.summary) {
        url =
          CF_URLS.printReports.vaultConfiguration
            .vaultConfigurationSummeryReport;
      }
      if (selectedReportOption === options.detail) {
        if (programType === programTypes.openMedia) {
          url =
            CF_URLS.printReports.vaultConfiguration
              .vaultConfigurationDetailReport;
        }
        if (programType === programTypes.container) {
          url =
            CF_URLS.printReports.vaultConfiguration.configDetailReportContainer;
        }
      }
    }

    if (reportType === reportTypes.management) {
      if (selectedReportOption === options.containersIncorrectShelfDuration) {
        url =
          CF_URLS.printReports.vaultConfiguration.containerIncorrectShelfReport;
      }

      if (
        selectedReportOption === options.logicalVaultsIncorrectShelfDuration
      ) {
        url =
          CF_URLS.printReports.vaultConfiguration
            .logicalVaultSwithinCorrectShelfDurationReport;
      }

      if (
        selectedReportOption ===
        options.logicalVaultsHourlyHandlingNoReviewMonthly
      ) {
        url =
          CF_URLS.printReports.vaultConfiguration
            .logicalVaultnoReviewHourlyReport;
      }

      if (
        selectedReportOption === options.containerSlotPhysicalVaultCustomerMap
      ) {
        url = CF_URLS.printReports.vaultConfiguration.customerMapReport;
      }

      if (selectedReportOption === options.nonContiguousLogicalVaults) {
        url =
          CF_URLS.printReports.vaultConfiguration
            .nonContiguousLogicalVaultsReport;
      }

      if (selectedReportOption === options.inboundOutboundInventory) {
        setIsLoading(true);
        const globalAttributeValue = await getGlobalAttributeValue(
          districtId,
          systemId,
          globalAttributeId
        );
        setIsLoading(false);
        if (!globalAttributeValue) {
          setErrorMessage(t("common.systemErrorMessage"));
          return;
        }

        if (includeDetails) {
          if (globalAttributeValue === VALUE_Y) {
            url =
              CF_URLS.printReports.vaultConfiguration.inboundOutboundDetailIme;
          } else if (globalAttributeValue === VALUE_N) {
            url =
              CF_URLS.printReports.vaultConfiguration
                .inboundOutboundInventoryDetailReport;
          }
        }

        if (!includeDetails) {
          if (globalAttributeValue === VALUE_Y) {
            url =
              CF_URLS.printReports.vaultConfiguration.inboundOutboundSummaryIme;
          } else if (globalAttributeValue === VALUE_N) {
            url =
              CF_URLS.printReports.vaultConfiguration.inboundOutboundSummary;
          }
        }
      }
    }
    setReportCFUrl(url);
  };
  const handleDownloadCancel = () => {
    setIsDownloadModalOpen(false);
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);
    setReportCFUrl("");
  };

  const handleTextFieldChange = (event) => {
    setErrorMessage(VALUE_EMPTY_STRING);
    let value = event.target.value;
    if (!isNaN(value) && value <= 99) {
      setCapacityPercentage(value);
    }
    setDisableReset(false);
  };

  const onChangePhysicalVault = (val) => {
    setSelectedLogicalVault({ ...defaultOption });
    setLogicalVaults([]);
    setSelectedCustomer({ ...defaultOption });
    setRacks([]);
    setSelectedRack({ ...defaultOption });
    setRows([]);
    setSelectedRow({ ...defaultOption });
    if (val?.value) {
      setSelectedPhysicalVault(val);
    } else {
      setSelectedPhysicalVault({ ...defaultOption });
    }
    setDisableReset(false);
  };

  const onClickPrintButtonClick = async () => {
    setThrowError(false);
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setErrorMessage(VALUE_EMPTY_STRING);

    let commonPayload = {
      main_district_id: districtId,
      customer_id: selectedCustomer?.value,
      user_name: getUserName(auth?.user),
      report_format: selectedValue?.type?.toUpperCase()
    };
    let customerNumber =
      selectedCustomer?.label?.split(" ")?.at(0) || VALUE_EMPTY_STRING;
    let reqBody = null;
    let URL = null;
    let { all, a, c, m, container } = programTypes;
    let containerMediaFlag =
      programType === all ? a : programType === container ? c : m;

    let programTypeText =
      containerMediaFlag === c
        ? t("vaultConfiguration.container")
        : t("vaultConfiguration.openMedia");

    if (reportType === reportTypes.capacity) {
      if (
        selectedReportOption === options.showAllCapacities &&
        includeDetails
      ) {
        reqBody = JSON.stringify({
          ...commonPayload,
          district_id: districtId,
          Customer: customerNumber,
          container_media_flag: containerMediaFlag,
          selection_criteria: na,
          Show: t("vaultManagementReports.allCapacity"),
          Program_Type: programTypeText,
          Include_Detail: includeDetails ? VALUE_Y : VALUE_N,
          report_type: reportType,
          report: selectedReportOption,
          district_name: currentBranch?.name?.replace("_", " ")
        });
        URL = CF_URLS.printReports.vaultConfiguration.vaultCapacityDetail;
      }

      if (
        (selectedReportOption === options.showAllCapacities ||
          selectedReportOption === options.capacityGreaterThan ||
          selectedReportOption === options.capacityLessThan) &&
        !includeDetails
      ) {
        reqBody = JSON.stringify({
          ...commonPayload,
          customer_number: customerNumber,
          capacity_less_than:
            selectedReportOption === options.capacityLessThan
              ? capacityPercentage
              : VALUE_EMPTY_STRING,
          capacity_greater_than:
            selectedReportOption === options.capacityGreaterThan
              ? capacityPercentage
              : VALUE_EMPTY_STRING,
          container_media_flag: containerMediaFlag,
          report_type: reportType,
          report: selectedReportOption,
          include_detail: includeDetails ? VALUE_Y : VALUE_N,
          district_name: currentBranch?.name?.replace("_", " ")
        });
        URL = CF_URLS.printReports.vaultConfiguration.summeryReport;
      }

      if (selectedReportOption === options.unassignedShelves) {
        URL = CF_URLS.printReports.vaultConfiguration.unassignShelvesReport;
        reqBody = JSON.stringify({
          ...commonPayload,
          district_id: districtId,
          physical_vault_id: VALUE_EMPTY_STRING,
          row_id: VALUE_EMPTY_STRING,
          container_media_flag: containerMediaFlag,
          display_container_slot:
            programType === programTypes.openMedia ? VALUE_N : VALUE_Y
        });
      }

      if (selectedReportOption === options.capacityHistory) {
        URL =
          CF_URLS.printReports.vaultConfiguration.vaultCapacityHistoryReport;
        reqBody = JSON.stringify({
          ...commonPayload,
          district_id: districtId,
          number_of_periods: numberOfPeriods,
          interval: programTypes.m,
          container_media_flag: containerMediaFlag,
          district_name: currentBranch?.name?.replace("_", " ")
        });
      }

      if (selectedReportOption === options.availableCapacity) {
        URL = CF_URLS.printReports.vaultConfiguration.availableCapacityReport;
        reqBody = JSON.stringify({
          ...commonPayload,
          district_id: districtId,
          container_media_flag: containerMediaFlag,
          district_name: currentBranch?.name?.replace("_", " ")
        });
      }
      if (selectedReportOption === options.rackingNearCapacity) {
        if (programType === programTypes.openMedia) {
          reqBody = {
            ...commonPayload,
            district_id: districtId,
            district_name: currentBranch?.name?.replace("_", " ")
          };
          delete reqBody.customer_id;

          URL =
            CF_URLS.printReports.vaultConfiguration
              .openMediaRackingNearCapacity;
        } else {
          URL =
            CF_URLS.printReports.vaultConfiguration.rackingNearCapacityReport;
          reqBody = JSON.stringify({
            ...commonPayload,
            district_id: districtId,
            container_media_flag: containerMediaFlag,
            thecount: zero,
            interface_from: vaultConfigurationPayloadProp,
            count_only_flag: VALUE_EMPTY_STRING,
            district_name: currentBranch?.name?.replace("_", " ")
          });
        }
      }
    }

    if (reportType === reportTypes.configuration) {
      if (selectedReportOption === options.summary) {
        URL =
          CF_URLS.printReports.vaultConfiguration
            .vaultConfigurationSummeryReport;
        reqBody = JSON.stringify({
          ...commonPayload,
          logical_vault_id: selectedLogicalVault.value,
          physical_vault_id: selectedPhysicalVault.value,
          row_id: selectedRow.value,
          rack_id: selectedRack.value,
          form_factor_id: selectedFormFactor.value,
          program_type: containerMediaFlag,
          vault_type: getVaultFlag(vault)
        });
      }
      if (selectedReportOption === options.detail) {
        let commonDetailPayload = {
          ...commonPayload,
          logical_vault_id: selectedLogicalVault.value,
          physical_vault_id: selectedPhysicalVault.value,
          row_id: selectedRow.value,
          rack_id: selectedRack.value,
          form_factor_id: selectedFormFactor.value,
          container_media_flag: containerMediaFlag,
          vault_type: getVaultFlag(vault),
          district_name: currentBranch?.name?.replace("_", " "),
          district_id: districtId
        };
        if (programType === programTypes.openMedia) {
          URL =
            CF_URLS.printReports.vaultConfiguration
              .vaultConfigurationDetailReport;

          reqBody = JSON.stringify({
            ...commonDetailPayload,
            vault_logical_vault: selectedLogicalVault.value
              ? selectedLogicalVault?.label?.trim()
              : VALUE_EMPTY_STRING,
            vault_physical_vault: selectedPhysicalVault.value
              ? selectedPhysicalVault?.label?.trim()
              : VALUE_EMPTY_STRING,
            vault_row: selectedRow.value
              ? selectedRow?.label?.trim()
              : VALUE_EMPTY_STRING,
            vault_rack: selectedRack
              ? selectedRack?.label?.trim()
              : VALUE_EMPTY_STRING,
            vault_form_factor: selectedFormFactor.value
              ? selectedLogicalVault?.label?.trim()
              : VALUE_EMPTY_STRING,
            customer_number: selectedCustomer.value
              ? customerNumber?.trim()
              : VALUE_EMPTY_STRING
          });
        }
        if (programType === programTypes.container) {
          URL =
            CF_URLS.printReports.vaultConfiguration.configDetailReportContainer;
          reqBody = JSON.stringify({
            ...commonDetailPayload
          });
        }
      }
    }

    if (reportType === reportTypes.management) {
      if (selectedReportOption === options.containersIncorrectShelfDuration) {
        reqBody = JSON.stringify({
          main_district_id: districtId,
          display_container_slot: VALUE_Y,
          user_name: getUserName(auth?.user),
          report_format: selectedValue?.type?.toUpperCase()
        });

        URL =
          CF_URLS.printReports.vaultConfiguration.containerIncorrectShelfReport;
      }

      if (
        selectedReportOption === options.logicalVaultsIncorrectShelfDuration
      ) {
        reqBody = JSON.stringify({
          selection_criteria: na,
          main_district_id: districtId,
          district_id: districtId,
          district_name: currentBranch?.name?.replace("_", " "),
          user_name: getUserName(auth?.user),
          report_format: selectedValue?.type?.toUpperCase()
        });

        URL =
          CF_URLS.printReports.vaultConfiguration
            .logicalVaultSwithinCorrectShelfDurationReport;
      }

      if (
        selectedReportOption ===
        options.logicalVaultsHourlyHandlingNoReviewMonthly
      ) {
        reqBody = JSON.stringify({
          main_district_id: districtId,
          district_id: districtId,
          district_name: currentBranch?.name?.replace("_", " "),
          user_name: getUserName(auth?.user),
          report_format: selectedValue?.type?.toUpperCase()
        });

        URL =
          CF_URLS.printReports.vaultConfiguration
            .logicalVaultnoReviewHourlyReport;
      }

      if (
        selectedReportOption === options.containerSlotPhysicalVaultCustomerMap
      ) {
        reqBody = {
          ...commonPayload,
          district_id: districtId,
          district_name: currentBranch?.name?.replace("_", " ")
        };
        delete reqBody.customer_id;

        URL = CF_URLS.printReports.vaultConfiguration.customerMapReport;
      }

      if (selectedReportOption === options.nonContiguousLogicalVaults) {
        reqBody = { ...commonPayload, display_container_slot: VALUE_Y };
        delete reqBody.customer_id;
        URL =
          CF_URLS.printReports.vaultConfiguration
            .nonContiguousLogicalVaultsReport;
      }

      if (selectedReportOption === options.inboundOutboundInventory) {
        try {
          setIsLoading(true);
          const globalAttributeValue = await getGlobalAttributeValue(
            districtId,
            systemId,
            globalAttributeId
          );
          if (!globalAttributeValue) {
            setIsLoading(false);
            setErrorMessage(t("common.systemErrorMessage"));
            return;
          }

          if (includeDetails) {
            reqBody = JSON.stringify({
              main_district_id: districtId,
              program_type: containerMediaFlag,
              customer_id: selectedCustomer?.value,
              logical_vault: selectedLogicalVault?.value || VALUE_EMPTY_STRING,
              date_from: dayjs(dateFrom).format(dateFormat.universalFormat),
              date_to: dayjs(dateTo).format(dateFormat.universalFormat),
              limit_rows: VALUE_Y,
              user_name: getUserName(auth?.user),
              report_format: selectedValue?.type?.toUpperCase()
            });

            if (globalAttributeValue === VALUE_Y) {
              URL =
                CF_URLS.printReports.vaultConfiguration
                  .inboundOutboundDetailIme;
            } else if (globalAttributeValue === VALUE_N) {
              URL =
                CF_URLS.printReports.vaultConfiguration
                  .inboundOutboundInventoryDetailReport;
            }
          }

          if (!includeDetails) {
            reqBody = JSON.stringify({
              main_district_id: districtId,
              program_type: containerMediaFlag,
              customer_id: selectedCustomer?.value,
              logical_vault: selectedLogicalVault?.value || VALUE_EMPTY_STRING,
              date_from: dayjs(dateFrom).format(dateFormat.universalFormat),
              date_to: dayjs(dateTo).format(dateFormat.universalFormat),
              limit_rows: VALUE_Y,
              user_name: getUserName(auth?.user),
              report_format: selectedValue?.type?.toUpperCase()
            });

            if (globalAttributeValue === VALUE_Y) {
              URL =
                CF_URLS.printReports.vaultConfiguration
                  .inboundOutboundSummaryIme;
            } else if (globalAttributeValue === VALUE_N) {
              URL =
                CF_URLS.printReports.vaultConfiguration.inboundOutboundSummary;
            }
          }
        } catch (error) {
          handleCatchError(error);
        }
      }
    }
    downloadReport(reqBody, URL);
  };

  const onChangeVaults = (val) => {
    if (val === vaults.customer || val === vaults.all) {
      setDisabledList((prev) => prev.filter((id) => id !== fields.customer));
    } else {
      setDisabledList((prev) => [...prev, fields.customer]);
    }

    setVault(val);
    setDisableReset(false);
  };

  const getVaultFlag = (v) => {
    let { all, a, b, c, branch } = vaults;
    return v === all ? a : v === branch ? b : c;
  };

  const reset = () => {
    setDisabledList([]);
    setRows([]);
    setSelectedPhysicalVault({ ...defaultOption });
    setSelectedRow({ ...defaultOption });
    setSelectedRack({ ...defaultOption });
    setSelectedLogicalVault({ ...defaultOption });
    setProgramType(programTypes.openMedia);
    setSelectedCustomer({ ...defaultOption });
    setIncludeDetails(false);
    setSelectedFormFactor({ ...defaultOption });
    setErrorMessage(VALUE_EMPTY_STRING);
  };
  const handleDropdownChange = (event, newValue) => {
    reset();
    if (reportType === reportTypes.capacity) {
      if (newValue === options.capacityGreaterThan) {
        setCapacityPercentage(defaultCapacityPercentage.high);
      } else if (newValue === options.capacityLessThan) {
        setCapacityPercentage(defaultCapacityPercentage.low);
      }
      if (newValue === options.availableCapacity) {
        setProgramType(programTypes.container);
      }
      if (newValue === options.unassignedShelves) {
        setProgramType(programTypes.openMedia);
      }
      if (newValue === options.capacityHistory) {
        setProgramType(programTypes.container);
        setDisabledList((prev) => [...prev, fields.programTypeOM]);
      }
    }
    if (reportType === reportTypes.configuration) {
      if (newValue === options.summary) {
        setProgramType(programTypes.all);
        setVault(vaults.all);
      }

      if (newValue === options.detail) {
        setProgramType(programTypes.openMedia);
        setVault(vaults.all);
        setDisabledList((prev) => [
          ...prev,
          fields.vault,
          fields.programTypeAll
        ]);
      }
    }

    if (reportType === reportTypes.management) {
      if (newValue === options.inboundOutboundInventory) {
        setProgramType(programTypes.all);
        setSelectedCustomer(null);
      }
    }
    setSelectedReportOption(newValue);
    setDisableReset(false);
  };

  const downloadReport = async (reqBody, URL) => {
    if (!URL) return;
    setIsLoading(true);
    try {
      let reportResponse = await printReport(reqBody, URL);
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse?.error);
        setThrowError(true);
        return;
      }
      setIsDownloadModalOpen(false);
    } catch (error) {
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const updateDropdownOptions = () => {
      switch (reportType) {
        case reportTypes.capacity:
          setDropdownOptions(dropDownOptions.capacity);
          setSelectedReportOption(options.showAllCapacities);
          break;
        case reportTypes.configuration:
          setDropdownOptions(dropDownOptions.configuration);
          setSelectedReportOption(options.summary);
          break;
        case reportTypes.management:
          setDropdownOptions(dropDownOptions.management);
          setSelectedReportOption(options.containersIncorrectShelfDuration);
          break;
        default:
          break;
      }
    };

    updateDropdownOptions();
  }, [reportType]);

  const onChangeLogicalVault = (val) => {
    if (val?.value) {
      setSelectedLogicalVault(val);
    } else {
      setSelectedLogicalVault({ ...defaultOption });
    }
    setDisableReset(false);
  };

  const onChangeRack = (val) => {
    if (val?.value) {
      setSelectedRack(val);
    } else {
      setSelectedRack({ ...defaultOption });
    }
    setDisableReset(false);
  };

  const onChangeFormFactor = (val) => {
    if (val?.value) {
      setSelectedFormFactor(val);
    } else {
      setSelectedFormFactor({ ...defaultOption });
    }
    setDisableReset(false);
  };

  const onChangeDateFrom = (newDateFrom) => {
    const fromDate = dayjs(newDateFrom);
    setDateFrom(fromDate);

    if (dateTo && fromDate && dayjs(dateTo).isBefore(fromDate)) {
      setDateTo(fromDate);
    }

    const oneMonthLater = fromDate.add(1, "month");
    if (dateTo && fromDate && dayjs(dateTo).isAfter(oneMonthLater)) {
      setErrorMessage(t("vaultManagementReports.maxDaysMsg"));

      setDateTo(fromDate);
    }
    setDisableReset(false);
  };

  const onChangeDateTo = (newDateTo) => {
    const toDate = dayjs(newDateTo);

    if (toDate && dateFrom && toDate.isBefore(dayjs(dateFrom))) {
      setDateTo(dayjs(dateFrom));
    } else {
      const oneMonthLater = dayjs(dateFrom).add(1, "month");
      if (toDate && dateFrom && toDate.isAfter(oneMonthLater)) {
        setErrorMessage(t("vaultManagementReports.maxDaysMsg"));

        setDateTo(dayjs(dateFrom));
      } else {
        setDateTo(toDate);
      }
    }
    setDisableReset(false);
  };

  const onCLickReset = () => {
    reset();
    if (fromDashboard) {
      handleReportTypeChange({ target: { value: reportTypes.capacity } });
      handleDropdownChange(null, options.showAllCapacities);
      setIncludeDetails(false);
    } else {
      handleReportTypeChange({ target: { value: reportTypes.configuration } });
      handleDropdownChange(null, options.summary);
      onChangeProgramType(programTypes.all);
      setVault(vaults.all);
    }

    setSelectedLogicalVault({ ...defaultOption });
    setSelectedRack({ ...defaultOption });
    setSelectedFormFactor({ ...defaultOption });
    setDisableReset(true);
  };

  const getFilteredFormFactors = () => {
    if (programType === programTypes.container) {
      return [...formFactors].filter((f) => f.type === programTypes.c);
    } else if (programType === programTypes.openMedia) {
      return [...formFactors].filter((f) => f.type === programTypes.m);
    } else {
      return formFactors;
    }
  };
  let title = t("vaultManagementReports.title");
  const getBody = () => {
    const isProgramTypeVisible =
      reportType === reportTypes.capacity ||
      reportType === reportTypes.configuration ||
      (reportType === reportTypes.management &&
        selectedReportOption === options.inboundOutboundInventory);

    const showCustomerField = [
      options.showAllCapacities,
      options.capacityGreaterThan,
      options.capacityLessThan,
      options.capacityHistory,
      options.configurationSummary,
      options.configurationDetail,
      options.inboundOutboundInventory,
      options.summary,
      options.detail
    ].includes(selectedReportOption);

    const managementInbounds =
      reportType === reportTypes.management &&
      selectedReportOption === options.inboundOutboundInventory;

    const showLogicalVaultField =
      reportType === reportTypes.configuration || managementInbounds;

    const showRackField = reportType === reportTypes.configuration;

    const isIncludeDetailEnabled =
      selectedReportOption === options.showAllCapacities ||
      selectedReportOption === options.inboundOutboundInventory;

    const isVaultsVisible = reportType === reportTypes.configuration;

    const showCapacityPercentage = [
      options.capacityGreaterThan,
      options.capacityLessThan
    ].includes(selectedReportOption);

    const showSelectionCriteria =
      reportType === reportTypes.management
        ? selectedReportOption === options.inboundOutboundInventory
        : true;

    const doNotShowFields = [
      options.rackingNearCapacity,
      options.availableCapacity
    ].includes(selectedReportOption);

    const showPhysicalVaultField =
      selectedReportOption === options.unassignedShelves ||
      reportType === reportTypes.configuration;
    const showRowField =
      selectedReportOption === options.unassignedShelves ||
      reportType === reportTypes.configuration;
    return (
      <Box sx={VaultManagementReportsStyles.wrapper}>
        {!!errorMessage && (
          <Collapse in={!!errorMessage}>
            <Alert
              severity="error"
              icon={
                <img
                  src={ErrorIcon}
                  alt="error"
                  style={VaultManagementReportsStyles.errorIcon}
                />
              }
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorMessage(VALUE_EMPTY_STRING);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorMessage}
            </Alert>
          </Collapse>
        )}
        <Grid
          container
          sx={VaultManagementReportsStyles.gridWrapper}
          p={spacing.gap10}
        >
          <Grid item xs={12} mb={1}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                sx={VaultManagementReportsStyles.labelFrom}
              >
                {t("vaultManagementReports.reportType")}
              </FormLabel>
              <RadioGroup
                sx={VaultManagementReportsStyles.reportRadioGroup}
                value={reportType}
                onChange={handleReportTypeChange}
              >
                <FormControlLabel
                  value={reportTypes.capacity}
                  control={<Radio />}
                  label={t("vaultManagementReports.capacity")}
                />
                <FormControlLabel
                  value={reportTypes.configuration}
                  control={<Radio />}
                  label={t("vaultManagementReports.configuration")}
                />
                <FormControlLabel
                  value={reportTypes.management}
                  control={<Radio />}
                  label={t("vaultManagementReports.management")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              componentsProps={{
                clearIndicator: ArrowDown
              }}
              popupIcon={<ArrowDown />}
              disableClearable
              id="report"
              disablePortal
              name={"report"}
              options={dropdownOptions}
              size="medium"
              value={selectedReportOption}
              onChange={handleDropdownChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("vaultManagementReports.report")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} mb={1} mt={spacing.horizontalMargin24}>
            <Typography sx={VaultManagementReportsStyles.labelFrom}>
              {t("vaultManagementReports.selectionCriteria")}
            </Typography>
          </Grid>
          {showSelectionCriteria && (
            <>
              {isProgramTypeVisible && (
                <Grid item xs={6} mb={1}>
                  <FormControl
                    component="fieldset"
                    disabled={disabledList.includes(fields.programType)}
                  >
                    <FormLabel
                      component="legend"
                      sx={VaultManagementReportsStyles.secLabelForm}
                    >
                      {t("vaultConfiguration.programType")}
                    </FormLabel>
                    <RadioGroup
                      sx={VaultManagementReportsStyles.reportRadioGroup}
                      value={programType}
                      onChange={(e) => onChangeProgramType(e.target.value)}
                    >
                      {(reportType === reportTypes.configuration ||
                        selectedReportOption ===
                          options.inboundOutboundInventory) && (
                        <FormControlLabel
                          value={programTypes.all}
                          control={
                            <Radio
                              disabled={disabledList.includes(
                                fields.programTypeAll
                              )}
                            />
                          }
                          label={t("vaultConfiguration.all")}
                        />
                      )}
                      <FormControlLabel
                        value={programTypes.openMedia}
                        control={
                          <Radio
                            disabled={disabledList.includes(
                              fields.programTypeOM
                            )}
                          />
                        }
                        label={t("vaultConfiguration.openMedia")}
                      />
                      <FormControlLabel
                        value={programTypes.container}
                        control={<Radio />}
                        label={t("vaultConfiguration.container")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
              {isVaultsVisible && (
                <Grid item xs={6} mb={1}>
                  <FormControl
                    component="fieldset"
                    disabled={disabledList.includes(fields.vault)}
                  >
                    <FormLabel
                      component="legend"
                      sx={VaultManagementReportsStyles.secLabelForm}
                    >
                      {t("vaultConfiguration.vaults")}
                    </FormLabel>
                    <RadioGroup
                      sx={VaultManagementReportsStyles.reportRadioGroup}
                      value={vault}
                      onChange={(e) => onChangeVaults(e.target.value)}
                    >
                      <FormControlLabel
                        value={vaults.all}
                        control={<Radio />}
                        label={t("vaultConfiguration.all")}
                      />
                      <FormControlLabel
                        value={vaults.branch}
                        control={<Radio />}
                        label={t("vaultConfiguration.branch")}
                      />
                      <FormControlLabel
                        value={vaults.customer}
                        control={<Radio />}
                        label={t("common.customer")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
            </>
          )}
        </Grid>
        {showSelectionCriteria && !doNotShowFields && (
          <>
            <Grid container p={spacing.gap10} gap={spacing.gap} wrap="nowrap">
              {showCustomerField && (
                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined">
                    <CustomersField
                      disabled={disabledList.includes(fields.customer)}
                      options={[
                        {
                          label: t("common.all"),
                          value: VALUE_EMPTY_STRING
                        },
                        ...customers
                      ]}
                      value={selectedCustomer || null}
                      handleOnChange={handleCustomerChange}
                    />
                  </FormControl>
                </Grid>
              )}
              {showLogicalVaultField && (
                <Grid item xs={4}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    id="logical-vault"
                    disablePortal
                    name={"logical-vault"}
                    disabled={
                      disabledList.includes(fields.logicalVaultField) ||
                      !logicalVaults.length ||
                      !selectedCustomer?.value
                    }
                    onChange={(e, val) => onChangeLogicalVault(val)}
                    options={getLogicalVaultsOptions()}
                    size="medium"
                    value={selectedLogicalVault}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("vaultConfiguration.logicalVault")}
                      />
                    )}
                  />
                </Grid>
              )}
              {!managementInbounds && showRackField && (
                <Grid item xs={4}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    id="rack"
                    disablePortal
                    name={"rack"}
                    disabled={!racks?.length || !selectedRow?.value}
                    options={[
                      {
                        label: t("common.all"),
                        value: VALUE_EMPTY_STRING
                      },
                      ...racks
                    ]}
                    size="medium"
                    value={selectedRack}
                    onChange={(e, val) => onChangeRack(val)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("vaultConfiguration.rack")}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              container
              p={spacing.gap10}
              pt={spacing.gap10}
              gap={spacing.gap}
              wrap="nowrap"
            >
              {showPhysicalVaultField && (
                <Grid item xs={4}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    id="physical-vault"
                    disablePortal
                    name={"physical-vault"}
                    value={selectedPhysicalVault}
                    options={[
                      {
                        label: t("common.all"),
                        value: VALUE_EMPTY_STRING
                      },
                      ...physicalVaults
                    ]}
                    onChange={(e, val) => onChangePhysicalVault(val)}
                    size="medium"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("vaultConfiguration.vault")}
                      />
                    )}
                  />
                </Grid>
              )}
              {showRowField && (
                <Grid item xs={4}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    disabled={!rows?.length || !selectedPhysicalVault?.value}
                    id="row"
                    disablePortal
                    name={"row"}
                    options={[
                      {
                        label: t("common.all"),
                        value: VALUE_EMPTY_STRING
                      },
                      ...rows
                    ]}
                    onChange={(e, val) => onChangeRow(val)}
                    size="medium"
                    value={selectedRow}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("vaultConfiguration.row")}
                      />
                    )}
                  />
                </Grid>
              )}
              {isVaultsVisible && (
                <Grid item xs={4}>
                  <Autocomplete
                    componentsProps={{
                      clearIndicator: ArrowDown
                    }}
                    popupIcon={<ArrowDown />}
                    clearIcon={<CloseIcon />}
                    id="form-factor"
                    disablePortal
                    disabled={disabledList.includes(fields.formFactor)}
                    name={"form-factor"}
                    onChange={(e, val) => onChangeFormFactor(val)}
                    options={[
                      {
                        label: t("common.all"),
                        value: VALUE_EMPTY_STRING
                      },
                      ...getFilteredFormFactors()
                    ]}
                    size="medium"
                    value={selectedFormFactor}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("vaultManagementReports.formFactor")}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>

            {(isIncludeDetailEnabled || showCapacityPercentage) && (
              <Grid
                container
                p={spacing.gap10}
                pt={spacing.gap10}
                gap={spacing.gap}
                wrap="nowrap"
              >
                {isIncludeDetailEnabled && (
                  <Grid
                    item
                    xs={4}
                    pl={spacing.gap10}
                    sx={VaultManagementReportsStyles.includeDetailsWrapper}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={VaultManagementReportsStyles.checkboxColor}
                          checked={includeDetails}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={t("vaultManagementReports.includeDetails")}
                    />
                  </Grid>
                )}
                {showCapacityPercentage && (
                  <Grid item xs={4}>
                    <Stack
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      direction={"row"}
                      gap={spacing.gap10}
                    >
                      <TextField
                        id="outlined-basic"
                        label={t("vaultManagementReports.capacityPercentage")}
                        variant="outlined"
                        fullWidth
                        value={capacityPercentage}
                        onChange={handleTextFieldChange}
                        inputProps={{ maxLength: percentageMax }}
                      />
                      <Typography sx={VaultManagementReportsStyles.perSymbol}>
                        {percentage}
                      </Typography>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            )}
            {managementInbounds && (
              <Grid
                container
                p={spacing.gap10}
                pt={spacing.gap10}
                gap={spacing.gap}
                wrap="nowrap"
              >
                <Grid item xs={4}>
                  <DatePickerField
                    id="date-from"
                    name="date-from"
                    value={dateFrom}
                    minDate={minDate}
                    maxDate={maxDate}
                    locale={localeByBranch}
                    onChange={(val) => onChangeDateFrom(val)}
                    label={t("vaultManagementReports.dateFrom")}
                  />
                </Grid>{" "}
                <Grid item xs={4}>
                  <DatePickerField
                    id="date-to"
                    name="date-to"
                    value={dateTo}
                    minDate={minDate}
                    maxDate={maxDate}
                    locale={localeByBranch}
                    onChange={(val) => onChangeDateTo(val)}
                    label={t("vaultManagementReports.dateTo")}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    );
  };
  const getButtons = () => {
    return (
      <Stack gap={spacing.gap} ml={spacing.gap10} direction="row">
        <Button variant="outlined" onClick={() => setIsReportsOpen(false)}>
          {t("common.close")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => onCLickReset()}
          disabled={disableReset}
        >
          {t("common.reset")}
        </Button>
        <Button variant="contained" onClick={handleModelOpen}>
          {t("common.print")}
        </Button>
      </Stack>
    );
  };
  return (
    <>
      {isLoading || loaders.length ? <FullCircularLoader /> : null}

      <CommonModal
        open={open}
        title={title}
        body={getBody()}
        buttons={getButtons()}
      ></CommonModal>
      <DownloadPopup
        title={t("common.downloadReport")}
        showPrintModal={isDownLoadModalOpen}
        cfUrl={reportCFUrl}
        onCancleClick={handleDownloadCancel}
        onDownloadClick={onClickPrintButtonClick}
        setSelectedValue={setSelectedValue}
        setThrowError={setThrowError}
        throwError={throwError}
        throwErrorMessage={throwErrorMessage}
      />
    </>
  );
};

export default VaultManagementReports;
