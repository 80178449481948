import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as ArrowLeft } from "assets/images/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "assets/images/ArrowRight.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllCustomersQuery } from "services/api";
import { selectAuth, selectRoute, setHaveChanges } from "store/slices";
import spacing from "styles/spacing";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  errorMsgs,
  hyphen,
  N_CHECKER,
  unauthorized,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y,
  Y_CHECKER
} from "utils/constants";
import {
  getAuthenticatedUserBranch,
  getTheFirestoreErrorMessage
} from "utils/helpers";

import SuccessPopup from "components/core/success-popup/SuccessPopup";
import CommonModal from "components/shared/common-modal/CommonModal";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import VaultManagementReports from "components/vault-management-reports/VaultManagementReports";
import { useCallbackPrompt } from "hooks";
import { uniqueId } from "lodash";
import {
  accessProperties,
  branch,
  capacityMax,
  dataProperties,
  durations,
  id,
  idCapacity,
  idCustomer,
  idProgramType,
  newTypes,
  nodeIdPrefix,
  nodeTypes,
  number,
  paddZero,
  programTypes,
  rowAndRackMax,
  rowAndRackMaxLength,
  typeDelete,
  typeInsert,
  unassigned,
  vaultAndShelfMax,
  vaultAndShelfMaxLenght,
  zero
} from "utils/constants/vault-configuration/VaultConfiguration";
import {
  checkAuditActiveService,
  createPhysicalVaultService,
  createRackService,
  createRowService,
  createShelfService,
  deleteRackService,
  deleteRowService,
  deleteShelfService,
  deleteVaultService,
  getFormFactors,
  getFormFactorsWithShelf,
  getPhysicalVaultsService,
  getRackUnderRowService,
  getRowUnderPhysicalVaultService,
  getShelfPropertiesService,
  getShelfUnderRackService,
  handleFactorMoveService,
  releaseRackService,
  releaseRowService,
  releaseShelfService,
  releaseVaultService,
  updatePhysicalVaultService,
  updateRackService,
  updateRowService,
  updateShelfService
} from "./services";
import { VaultConfigurationStyles } from "./vaultConfigurationStyles";
import VaultConfigurationTree from "./VaultConfigurationTree";

function getNextAvailableNumber(vaultNumbers, max) {
  vaultNumbers.sort((a, b) => a - b);
  for (let i = 1; i <= max; i++) {
    if (!vaultNumbers.includes(i)) {
      return i;
    }
  }
  return null;
}
const VaultConfiguration = () => {
  const { currentBranch } = useSelector(selectAuth);
  const { haveChanges } = useSelector(selectRoute);
  const dispatch = useDispatch();
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(haveChanges);
  const [errorMessage, setErrorMessage] = useState(VALUE_EMPTY_STRING);
  const [physicalVaults, setPhysicalVaults] = useState([]);
  const [rows, setRows] = useState([]);
  const [racks, setRacks] = useState([]);
  const [shelves, setShelves] = useState([]);
  const [expanded, setExpanded] = useState([nodeTypes.branch]);
  const [loaders, setLoaders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [propertiesHeading, setPropertiesHeading] = useState(
    t("vaultConfiguration.properties")
  );
  const [buttonsDisable, setButtonDisable] = useState({
    print: false,
    delete: true,
    releaseSlots: true,
    save: true,
    new: false
  });
  const [numberField, setNumberField] = useState(VALUE_EMPTY_STRING);
  const [initilaNumberField, setInitialNumberField] =
    useState(VALUE_EMPTY_STRING);
  const [selected, setSelected] = useState(nodeTypes.branch);
  const [selectedType, setSelectedType] = useState(nodeTypes.branch);
  const [programType, setProgramType] = useState(programTypes.openMedia);
  const [duration, setDuration] = useState(durations.active);
  const [isPopulated, setIsPopulated] = useState(false);
  const [capacity, setCapacity] = useState(VALUE_EMPTY_STRING);
  const [startingSlot, setStartingSlot] = useState(VALUE_EMPTY_STRING);
  const [endingSlot, setEndingSlot] = useState(VALUE_EMPTY_STRING);
  const [logicalVault, setLogicalVault] = useState(VALUE_EMPTY_STRING);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [leftSelected, setLeftSelected] = useState([]);
  const [rightSelected, setRightSelected] = useState([]);
  const [leftFormFactors, setLeftFormFactors] = useState([]);
  const [rightFormFactors, setRightFormFactors] = useState([]);
  const [awaitSelection, setAwaitSelection] = useState(VALUE_EMPTY_STRING);
  const [createMode, setCreateMode] = useState(false);
  const [releaseMode, setReleaseMode] = useState(VALUE_EMPTY_STRING);
  const [updateMode, setUpdateMode] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(VALUE_EMPTY_STRING);
  const districtId = getAuthenticatedUserBranch();
  const [initialProperties, setInitialProperties] = useState({});
  const [disabledFields, setDisabledFields] = useState([]);
  const [newlyAdded, setNewlyAadded] = useState([]);
  const [loadedVaults, setLoadedVaults] = useState([]);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    let properties = JSON.stringify({
      numberField: numberField?.toString(),
      programType,
      duration,
      capacity,
      logicalVault,
      customerId: selectedCustomer?.value || zero
    });

    if (
      properties !== JSON.stringify(initialProperties) &&
      Object.keys(initialProperties).length
    ) {
      setUpdateMode(true);
    } else {
      setUpdateMode(false);
    }
  }, [
    programType,
    selectedCustomer,
    capacity,
    duration,
    numberField,
    logicalVault,
    initialProperties
  ]);
  useEffect(() => {
    setButtonDisable((prev) => ({
      ...prev,
      save: !updateMode
    }));
  }, [updateMode]);

  useEffect(() => {
    if (errorMessage) window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [errorMessage]);

  useEffect(() => {
    let isDeleteDisabled = leftSelected.length || rightSelected.length;
    setButtonDisable((prev) => ({
      ...prev,
      delete: isDeleteDisabled
    }));
  }, [leftSelected, rightSelected]);

  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });

  // handle session timeout and uncought error
  const handleCatchError = (error) => {
    if (error?.response?.data === unauthorized) {
      setErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
    } else {
      setErrorMessage(t("printTapeLabel.systemError"));
    }
  };

  const getPhysicalVaults = async () => {
    if (!physicalVaults.length)
      setLoaders((prev) => [...prev, nodeTypes.branch]);
    try {
      const res = await getPhysicalVaultsService(districtId);
      if (res?.data?.[0]) {
        let vaults = res.data[0].map((pv) => ({
          physicalVaultId: pv.physical_vault_id,
          physicalVaultNumber: pv.physical_vault_number,
          timestamp: pv.timestamp
        }));
        setPhysicalVaults(
          vaults.sort((a, b) => a.physicalVaultNumber - b.physicalVaultNumber)
        );
      }
      if (awaitSelection) {
        setSelected(awaitSelection);
        setAwaitSelection(VALUE_EMPTY_STRING);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      stopLoaderForNode(nodeTypes.branch);
    }
  };

  const stopLoaderForNode = (nodeId) => {
    setLoaders((prev) => prev.filter((id) => id !== nodeId));
  };

  const onSelectedItemsChange = (e, selected) => {
    if (createMode && !!e) {
      setShowConfirmModal(true);
      return;
    }
    setSelected(selected);
    let selectedType = selected.split("_")[0];
    if (e) {
      let properetiesText = VALUE_EMPTY_STRING;
      if (selectedType === nodeTypes.branch) {
        properetiesText = t("vaultConfiguration.properties");
      } else {
        properetiesText = selected
          .split("_")
          ?.slice(1)
          ?.reduce(
            (a, n, i) => a.concat(i === 0 ? n : `-${n}`),
            `${t("vaultConfiguration.properties")} ${t(
              "vaultConfiguration.for"
            )} `
          );
      }
      setPropertiesHeading(properetiesText);
    }
    setSelectedType(selectedType);
  };

  useEffect(() => {
    switch (selectedType) {
      case nodeTypes.vault:
      case nodeTypes.row:
      case nodeTypes.rack:
        setButtonDisable({
          print: false,
          delete: false,
          releaseSlots: false,
          save: !createMode,
          new: createMode
        });
        break;
      case nodeTypes.shelf:
        setButtonDisable({
          print: false,
          delete: logicalVault !== unassigned && !!logicalVault,
          releaseSlots: programType === programTypes.container ? false : true,
          save: createMode ? false : !updateMode,
          new: true
        });
        break;
      case nodeTypes.branch:
        setButtonDisable({
          delete: true,
          releaseSlots: true,
          new: createMode,
          print: false,
          save: !createMode
        });
        break;
      default:
        break;
    }
    onSelectedItemsChange(null, selected);
    // eslint-disable-next-line
  }, [selectedType, selected, programType]);

  useEffect(() => {
    if (districtId) {
      getPhysicalVaults();
    }
    // eslint-disable-next-line
  }, [districtId]);

  const handleDataFetch = async (
    serviceFunction,
    params,
    setData,
    nodeId,
    keyMappings,
    sortKey
  ) => {
    try {
      setErrorMessage(VALUE_EMPTY_STRING);
      const data = await serviceFunction(...params);

      if (data?.data?.[0]) {
        setData((prevData) => {
          let updatedData = [...prevData].filter((item) => !!item.timestamp);
          data.data[0].forEach((node) => {
            const itemIndex = updatedData.findIndex(
              (item) =>
                item[sortKey.replace(number, id)] === node[keyMappings.uniqueId]
            );
            if (itemIndex !== -1) {
              updatedData[itemIndex] = {
                ...updatedData[itemIndex],
                timestamp: node.timestamp
              };
            } else {
              const newData = {
                physicalVaultId: node.physical_vault_id,
                timestamp: node.timestamp,
                physicalVaultNumber: node.physical_vault_number
              };
              Object.keys(keyMappings).forEach((key) => {
                if (key !== uniqueId) {
                  newData[key] = node[keyMappings[key]];
                }
              });
              updatedData.push(newData);
            }
          });
          return updatedData.sort((a, b) => a[sortKey] - b[sortKey]);
        });
        setExpanded((prev) => [...prev, nodeId]);
        setIsLoading(false);
      }
    } catch (error) {
      handleCatchError(error);
    }
  };

  let rowProps = {
    rowId: dataProperties.rowId,
    rowNumber: dataProperties.rowNumber
  };
  let rackProps = {
    rackId: dataProperties.rackId,
    rackNumber: dataProperties.rackNumber
  };

  const getRowUnderPhysicalVault = async (vaultId, nodeId) => {
    setLoadedVaults((prev) => [...prev, nodeId]);
    await handleDataFetch(
      getRowUnderPhysicalVaultService,
      [districtId, vaultId, nodeId, stopLoaderForNode],
      setRows,
      nodeId,
      {
        uniqueId: dataProperties.rowId,
        ...rowProps
      },
      accessProperties.rowNumber
    );
  };

  const getRackUnderRow = async (vaultId, rowId, nodeId) => {
    await handleDataFetch(
      getRackUnderRowService,
      [districtId, vaultId, rowId, nodeId, stopLoaderForNode],
      setRacks,
      nodeId,
      {
        uniqueId: dataProperties.rackId,
        ...rowProps,
        ...rackProps
      },
      accessProperties.rackNumber
    );
  };

  const getShelfUnderRack = async (vaultId, rowId, rackId, nodeId) => {
    await handleDataFetch(
      getShelfUnderRackService,
      [districtId, vaultId, rowId, rackId, nodeId, stopLoaderForNode],
      setShelves,
      nodeId,
      {
        uniqueId: dataProperties.shelfId,
        ...rowProps,
        ...rackProps,
        shelfId: dataProperties.shelfId,
        shelfNumber: dataProperties.shelfNumber
      },
      accessProperties.shelfNumber
    );
  };

  const handleCustomerChange = (e, newValue) => {
    dispatch(setHaveChanges(true));

    setSelectedCustomer(newValue);
  };

  const getShelfProperties = async (shelfId) => {
    setErrorMessage(VALUE_EMPTY_STRING);
    try {
      setIsLoading(true);
      const res = await getShelfPropertiesService(districtId, shelfId);
      let containerMediaFlag = VALUE_EMPTY_STRING;
      if (res.data[0] && res.data[0].length) {
        let [data] = res.data[0];
        if (Object.keys(data).length) {
          let {
            container_media_flag,
            long_term_flag: longTermFlag,
            capacity,
            first_slot_number: firstSlotNumber,
            actual_media_count: actualMediaCount,
            logical_vault_code: logicalVaultCode,
            customer_id: customerId,
            shelf_number: shelfNumber
          } = data;

          containerMediaFlag = container_media_flag;

          let programType = VALUE_EMPTY_STRING;
          let duration = VALUE_EMPTY_STRING;
          if (containerMediaFlag === programTypes.c) {
            programType = programTypes.container;
          } else if (containerMediaFlag === programTypes.m) {
            programType = programTypes.openMedia;
          }
          setProgramType(programType);

          if (longTermFlag === Y_CHECKER) {
            duration = durations.longTerm;
          } else if (longTermFlag === N_CHECKER) {
            duration = durations.active;
          }
          let logicalValutTextboxValue;
          let startingSlotLabelValue;
          let endSlotLabelValue;

          if (containerMediaFlag === programTypes.c) {
            logicalValutTextboxValue = VALUE_EMPTY_STRING;
          } else if (logicalVaultCode !== VALUE_EMPTY_STRING) {
            logicalValutTextboxValue = logicalVaultCode;
          } else {
            logicalValutTextboxValue = unassigned;
          }

          if (
            logicalValutTextboxValue !== unassigned &&
            !!logicalValutTextboxValue
          ) {
            setButtonDisable((prev) => ({ ...prev, delete: true }));
          }

          if (logicalValutTextboxValue === unassigned) {
            startingSlotLabelValue = VALUE_EMPTY_STRING;
            endSlotLabelValue = VALUE_EMPTY_STRING;
          } else {
            startingSlotLabelValue = firstSlotNumber;
            endSlotLabelValue =
              parseInt(capacity) + parseInt(firstSlotNumber) - 1;
          }

          if (
            actualMediaCount === zero &&
            containerMediaFlag === programTypes.c
          ) {
            setDisabledFields([]);
          } else {
            setDisabledFields([idCustomer]);

            if (logicalVaultCode.length > 0) {
              setDisabledFields((prev) => [...prev, idProgramType]);
            } else {
              setDisabledFields((prev) =>
                [...prev].filter((disabled) => disabled !== idProgramType)
              );
            }
          }

          setLeftSelected([]);
          setRightSelected([]);
          setDuration(duration);
          setInitialProperties({
            numberField: parseInt(shelfNumber, 10).toString(),
            programType,
            duration,
            capacity: capacity?.trim(),
            logicalVault: logicalValutTextboxValue?.trim(),
            customerId
          });
          setCapacity(capacity?.trim() || VALUE_EMPTY_STRING);
          setStartingSlot(startingSlotLabelValue?.trim() || VALUE_EMPTY_STRING);
          setEndingSlot(
            isNaN(endSlotLabelValue) ? VALUE_EMPTY_STRING : endSlotLabelValue
          );

          setLogicalVault(
            logicalValutTextboxValue?.trim() || VALUE_EMPTY_STRING
          );
          setSelectedCustomer(
            customers.find((cust) => cust.value === customerId)
          );
        }
      }
      const formFactorPromise = getFormFactors(
        DISTRICT_ID_GET_ALL_LOCATIONS,
        containerMediaFlag
      );

      const formFactorResWithPromise = getFormFactorsWithShelf(
        districtId,
        shelfId
      );
      const [formFactorRes, formFactorResWithShelf] = await Promise.all([
        formFactorPromise,
        formFactorResWithPromise
      ]);
      let existingFormFactors = [];

      if (Array.isArray(formFactorResWithShelf?.data[0])) {
        let data = formFactorResWithShelf.data[0];
        setRightFormFactors(data);
        existingFormFactors = data.map(
          (formFactor) => formFactor.form_factor_id
        );
      }

      if (formFactorRes?.data[0] && formFactorRes.data[0].length) {
        let data = formFactorRes.data[0];
        data = data.filter(
          (formFactor) =>
            !existingFormFactors.includes(formFactor.form_factor_id)
        );
        setLeftFormFactors(data);
      }

      setIsPopulated(true);
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeNumber = (e) => {
    dispatch(setHaveChanges(true));

    setButtonDisable((prev) => ({
      ...prev,
      save: false
    }));
    let value = e.target.value;
    if (!value || !isNaN(e.target.value)) setNumberField(e.target.value);
  };

  const getMaxLength = () => {
    if (selectedType === nodeTypes.vault || selectedType === nodeTypes.shelf) {
      return vaultAndShelfMaxLenght;
    } else {
      return rowAndRackMaxLength;
    }
  };

  const handleSelectAll = (list, setList, isSelected) => {
    dispatch(setHaveChanges(true));

    if (isSelected) {
      setList(list.map((item) => item.form_factor_id));
    } else {
      setList([]);
    }
  };

  const handleFactorMove = async (list, type, id) => {
    try {
      setIsLoading(true);
      let formFactorIdList = list.map((f) => f.form_factor_id).join(",");
      let shelf = getNodeData(selected);
      let shelfId = id || shelf.shelfId;
      const res = await handleFactorMoveService(
        districtId,
        shelfId,
        formFactorIdList,
        type
      );
      let data = res.data;
      if (data[1] && data[1][0]?.error !== zero) {
        let code = data[1][0]?.error;
        let replaceText = VALUE_EMPTY_STRING;
        if (code === errorMsgs.errorCode25387) {
          replaceText = rightFormFactors
            .map((formFactor) => formFactor.short_descr)
            .join(",");
        }
        handleError(code, replaceText);
        setIsLoading(false);
        if (selectedType === nodeTypes.shelf) {
          restoreFields();
        }
        return false;
      }

      const { vault, row, rack } = getParentNodes(selected);
      await getShelfUnderRack(
        vault.physicalVaultId,
        row.rowId,
        rack.rackId,
        selected
      );
      return true;
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMoveRight = async () => {
    setIsLoading(true);
    let isAllowedToMove = await checkIfActiveAudit();
    if (!isAllowedToMove) return;
    setErrorMessage("");
    const selectedItems = leftFormFactors.filter((item) =>
      leftSelected.includes(item.form_factor_id)
    );
    if (selected.split("_").at(-1) !== newTypes.shelf) {
      await handleFactorMove(selectedItems, typeInsert);
    }
    setRightFormFactors([...rightFormFactors, ...selectedItems]);
    setLeftFormFactors(
      leftFormFactors.filter(
        (item) => !leftSelected.includes(item.form_factor_id)
      )
    );
    setLeftSelected([]);
  };

  const handleMoveLeft = async () => {
    setIsLoading(true);
    let isAllowedToMove = await checkIfActiveAudit();
    if (!isAllowedToMove) return;
    setErrorMessage("");
    const selectedItems = rightFormFactors.filter((item) =>
      rightSelected.includes(item.form_factor_id)
    );
    if (selected.split("_").at(-1) !== newTypes.shelf) {
      isAllowedToMove = await handleFactorMove(selectedItems, typeDelete);
    }
    if (!isAllowedToMove) return;
    setLeftFormFactors([...leftFormFactors, ...selectedItems]);
    setRightFormFactors(
      rightFormFactors.filter(
        (item) => !rightSelected.includes(item.form_factor_id)
      )
    );
    setRightSelected([]);
  };

  const resetRightPanel = () => {
    setProgramType(programTypes.openMedia);
    setDuration(durations.active);
    setSelectedCustomer(null);
    setLogicalVault(VALUE_EMPTY_STRING);
    setRightFormFactors([]);
    setCapacity(VALUE_EMPTY_STRING);
    setStartingSlot(VALUE_EMPTY_STRING);
    setEndingSlot(VALUE_EMPTY_STRING);
  };

  const onChangeProgramType = async (e) => {
    dispatch(setHaveChanges(true));
    setIsLoading(true);
    let isAllowedToChange = await checkIfActiveAudit();
    if (!isAllowedToChange) return;
    let value = e.target.value;
    setProgramType(value);
    let type =
      value === programTypes.container ? programTypes.c : programTypes.m;
    if (type === programTypes.c) {
      setDisabledFields((prev) => prev.filter((id) => id !== idCustomer));
    } else {
      setDisabledFields((prev) => [...prev, idCustomer]);
      setLogicalVault(unassigned);
      setSelectedCustomer(null);
    }
    setRightFormFactors([]);
    loadFormFactors(type);

    if (selected.split("_").at(-1) !== newTypes.shelf) {
      await handleFactorMove(rightFormFactors, typeDelete);
    }
    setLeftSelected([]);
    setRightSelected([]);
  };

  const handleClickNew = async () => {
    setIsLoading(true);
    setErrorMessage(VALUE_EMPTY_STRING);
    let isAllowedNew = await checkIfActiveAudit();
    if (!isAllowedNew) return;
    setCreateMode(true);
    switch (selectedType) {
      case nodeTypes.branch: {
        setExpanded([nodeTypes.branch]);
        let newVault = {
          physicalVaultId: VALUE_EMPTY_STRING,
          physicalVaultNumber: newTypes.vault
        };
        setPhysicalVaults((prev) => [...prev, newVault]);
        setSelectedType(nodeTypes.vault);
        setSelected(`${nodeIdPrefix.vault}${newTypes.vault}`);
        setPropertiesHeading(
          `${t("vaultConfiguration.properties")} ${t(
            "vaultConfiguration.for"
          )}  ${t("vaultConfiguration.new")}`
        );
        setNumberField(
          getNextAvailableNumber(
            physicalVaults.map((vault) =>
              parseInt(vault.physicalVaultNumber, 10)
            ),
            vaultAndShelfMax
          )
        );
        break;
      }

      case nodeTypes.vault: {
        if (loaders.includes(selected)) return;
        setExpanded((prev) => [...prev, selected]);
        let { vault } = getParentNodes(selected);
        let newRow = {
          physicalVaultId: vault.physicalVaultId,
          rowId: VALUE_EMPTY_STRING,
          rowNumber: newTypes.row,
          physicalVaultNumber: vault.physicalVaultNumber
        };
        setRows((prev) => [...prev, newRow]);
        setSelectedType(nodeTypes.row);
        setSelected(
          `${nodeIdPrefix.row}${vault.physicalVaultNumber}_${newTypes.row}`
        );
        setPropertiesHeading(
          `${t("vaultConfiguration.properties")} ${t(
            "vaultConfiguration.for"
          )} ${vault.physicalVaultNumber}-${t("vaultConfiguration.new")}`
        );
        setNumberField(
          getNextAvailableNumber(
            rows
              .filter((row) => row.physicalVaultId === vault.physicalVaultId)
              .map((row) => parseInt(row.rowNumber, 10)),
            rowAndRackMax
          )
        );

        break;
      }

      case nodeTypes.row: {
        if (loaders.includes(selected)) return;
        setExpanded((prev) => [...prev, selected]);
        let { vault, row } = getParentNodes(selected);
        let newRack = {
          physicalVaultId: vault.physicalVaultId,
          rowId: row.rowId,
          rowNumber: row.rowNumber,
          physicalVaultNumber: vault.physicalVaultNumber,
          rackId: VALUE_EMPTY_STRING,
          rackNumber: newTypes.rack
        };
        setRacks((prev) => [...prev, newRack]);
        setSelectedType(nodeTypes.rack);
        setSelected(
          `${nodeIdPrefix.rack}${vault.physicalVaultNumber}_${row.rowNumber}_${newTypes.rack}`
        );
        setPropertiesHeading(
          `${t("vaultConfiguration.properties")} ${t(
            "vaultConfiguration.for"
          )} ${vault.physicalVaultNumber}-${row.rowNumber}-${t(
            "vaultConfiguration.new"
          )}`
        );
        setNumberField(
          getNextAvailableNumber(
            racks
              .filter(
                (rack) =>
                  rack.physicalVaultId === vault.physicalVaultId &&
                  rack.rowId === row.rowId
              )
              .map((rack) => parseInt(rack.rackNumber, 10)),
            rowAndRackMax
          )
        );
        break;
      }

      case nodeTypes.rack: {
        if (loaders.includes(selected)) return;
        setExpanded((prev) => [...prev, selected]);
        setDisabledFields([]);
        let { vault, row, rack } = getParentNodes(selected);
        let newShelf = {
          physicalVaultId: vault.physicalVaultId,
          rowId: row.rowId,
          rowNumber: row.rowNumber,
          physicalVaultNumber: vault.physicalVaultNumber,
          rackId: rack.rackId,
          rackNumber: rack.rackNumber,
          shelfId: VALUE_EMPTY_STRING,
          shelfNumber: newTypes.shelf
        };
        resetRightPanel();
        setShelves((prev) => [...prev, newShelf]);
        setSelectedType(nodeTypes.shelf);
        setSelected(
          `${nodeIdPrefix.shelf}${vault.physicalVaultNumber}_${row.rowNumber}_${rack.rackNumber}_${newTypes.shelf}`
        );
        setPropertiesHeading(
          `${t("vaultConfiguration.properties")} ${t(
            "vaultConfiguration.for"
          )} ${vault.physicalVaultNumber}-${row.rowNumber}-${
            rack.rackNumber
          }-${t("vaultConfiguration.new")}`
        );
        setNumberField(
          getNextAvailableNumber(
            shelves
              .filter(
                (shelf) =>
                  shelf.physicalVaultId === vault.physicalVaultId &&
                  shelf.rowId === row.rowId &&
                  shelf.rackId === rack.rackId
              )
              .map((shelf) => parseInt(shelf.shelfNumber, 10)),
            vaultAndShelfMax
          )
        );
        await loadFormFactors(programTypes.m);
        setLogicalVault(unassigned);
        setIsPopulated(true);
        setDisabledFields((prev) => [...prev, idCustomer]);
        setLogicalVault(unassigned);
        break;
      }

      default:
        setCreateMode(false);
        break;
    }
  };

  const loadFormFactors = async (type) => {
    try {
      setIsLoading(true);
      const formFactorRes = await getFormFactors(
        DISTRICT_ID_GET_ALL_LOCATIONS,
        type
      );

      if (formFactorRes?.data[0] && formFactorRes.data[0].length) {
        let data = formFactorRes.data[0];

        setLeftFormFactors(data);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getParentNodeNumbers = (selectedNode) => {
    return selectedNode.split("_")?.slice(1);
  };

  const getNodeData = (node) => {
    const [vaultNumber, rowNumber, rackNumber, shelfNumber] =
      getParentNodeNumbers(node);
    const type = node.split("_").length;

    const searchParams = {
      2: {
        list: physicalVaults,
        key: accessProperties.physicalVaultNumber,
        value: vaultNumber
      },
      3: {
        list: rows,
        key: [accessProperties.physicalVaultNumber, accessProperties.rowNumber],
        value: [vaultNumber, rowNumber]
      },
      4: {
        list: racks,
        key: [
          accessProperties.physicalVaultNumber,
          accessProperties.rowNumber,
          accessProperties.rackNumber
        ],
        value: [vaultNumber, rowNumber, rackNumber]
      },
      5: {
        list: shelves,
        key: [
          accessProperties.physicalVaultNumber,
          accessProperties.rowNumber,
          accessProperties.rackNumber,
          accessProperties.shelfNumber
        ],
        value: [vaultNumber, rowNumber, rackNumber, shelfNumber]
      }
    };

    if (!searchParams[type]) return null;

    const { list, key, value } = searchParams[type];

    return list.find((item) => {
      if (Array.isArray(key)) {
        return key.every((k, i) => item[k] === value[i]);
      }
      return item[key] === value;
    });
  };

  const getParentNodes = (selectedNode) => {
    const [vaultNumber, rowNumber, rackNumber] =
      getParentNodeNumbers(selectedNode);

    const vault = physicalVaults.find(
      (v) => v.physicalVaultNumber === vaultNumber
    );
    const row = rowNumber
      ? rows.find(
          (r) =>
            r.rowNumber === rowNumber && r.physicalVaultNumber === vaultNumber
        )
      : null;
    const rack = rackNumber
      ? racks.find(
          (r) =>
            r.rackNumber === rackNumber &&
            r.rowNumber === rowNumber &&
            r.physicalVaultNumber === vaultNumber
        )
      : null;

    return { vault, row, rack };
  };
  const handleError = async (errorCode, replaceText) => {
    let { errorMsg } = await getTheFirestoreErrorMessage(errorCode);
    if (errorMsg) {
      if (replaceText) {
        if (typeof replaceText === "string") {
          errorMsg = errorMsg.replaceAll("|", replaceText);
        } else if (Array.isArray(replaceText)) {
          replaceText.forEach((text) => {
            errorMsg = errorMsg.replace("|", text);
          });
        }
      }
      setErrorMessage(errorMsg);
    }
    setIsLoading(false);
  };

  const updateShelfTimeStamp = (rec) => {
    const { timestamp, shelf_id } = rec[0];
    let updatedShelves = [...shelves];
    updatedShelves.forEach((item) => {
      if (item.shelfId === shelf_id) {
        item.timestamp = timestamp;
      }
    });
    setShelves(updatedShelves);
  };

  const updateRackTimeStamp = (rec) => {
    const { timestamp, rack_id, rack_number } = rec[0];
    let updatedRacks = [...racks];
    updatedRacks.forEach((item) => {
      if (item.rackId === rack_id) {
        item.timestamp = timestamp;
        item.rackNumber = rack_number;
      }
    });
    setRacks(updatedRacks);
  };

  const updateRowTimeStamp = (rec) => {
    const { timestamp, row_id, row_number } = rec[0];
    let updatedRows = [...rows];
    updatedRows.forEach((item) => {
      if (item.rowId === row_id) {
        item.timestamp = timestamp;
        item.rowNumber = row_number;
      }
    });
    setRows(updatedRows);
  };

  const updatePhysicalVaultTimeStamp = (rec) => {
    const { timestamp, physical_vault_id, physical_vault_number } = rec[0];
    let updatedPhysicalVaults = [...physicalVaults];
    updatedPhysicalVaults.forEach((item) => {
      if (item.physicalVaultId === physical_vault_id) {
        item.timestamp = timestamp;
        item.physicalVaultNumber = physical_vault_number;
      }
    });
    setPhysicalVaults(updatedPhysicalVaults);
  };

  const restoreFields = () => {
    let {
      numberField,
      programType,
      duration,
      capacity,
      logicalVault,
      customerId
    } = initialProperties;

    setNumberField(parseInt(numberField, 10).toString());
    setProgramType(programType);
    setDuration(duration);
    setCapacity(capacity);
    setLogicalVault(logicalVault);
    let customer =
      customerId === "" || customerId === zero
        ? null
        : customers.find((c) => c.value === customerId);
    setSelectedCustomer(customer);
  };

  const onClickSave = async () => {
    setErrorMessage(VALUE_EMPTY_STRING);
    setShowConfirmModal(false);
    let isAllowedToUpdate = await checkIfActiveAudit();
    if (!isAllowedToUpdate) {
      setNumberField(initilaNumberField);
      return;
    }
    switch (selectedType) {
      case nodeTypes.vault: {
        let newVaultNumber = numberField.toString().padStart(2, paddZero);
        if (
          physicalVaults
            .map((v) => v.physicalVaultNumber)
            .includes(newVaultNumber)
        ) {
          handleError(errorMsgs.errorCode62503, [
            t("vaultConfiguration.vault"),
            t("vaultConfiguration.vault"),
            t("vaultConfiguration.branch")
          ]);
          return;
        }

        let isUpdating = selected.split("_").at(-1) !== newTypes.vault;
        let vault = getNodeData(selected);
        setIsLoading(true);
        if (isUpdating) {
          const res = await updatePhysicalVaultService(
            districtId,
            vault.physicalVaultId,
            newVaultNumber,
            vault.timestamp
          );
          let data = res.data;
          if (data[1] && data[1][0]?.error !== zero) {
            handleError(data[1][0]?.error, t("vaultConfiguration.vault"));
            return;
          }
          updatePhysicalVaultTimeStamp(data[0]);
        } else {
          const res = await createPhysicalVaultService(
            districtId,
            newVaultNumber
          );
          let data = res.data;
          if (data[0]?.length && data[0][0]?.error) {
            handleError(data[0][0]?.error, t("vaultConfiguration.vault"));
            return;
          }
          setCreateMode(false);
          await getPhysicalVaults();
          setNewlyAadded((prev) => [
            ...prev,
            `${nodeIdPrefix.vault}${newVaultNumber}`
          ]);
        }
        setIsLoading(false);
        setSelected(`${nodeIdPrefix.vault}${newVaultNumber}`);
        setSelectedType(nodeTypes.vault);
        break;
      }

      case nodeTypes.row: {
        let { vault, row } = getParentNodes(selected);
        let newRowNumber = numberField.toString().padStart(3, paddZero);
        let newNodeId = `${nodeIdPrefix.row}${vault.physicalVaultNumber}_${newRowNumber}`;

        if (
          rows
            .filter((r) => r.physicalVaultNumber === vault.physicalVaultNumber)
            .map((r) => r.rowNumber)
            .includes(newRowNumber)
        ) {
          handleError(errorMsgs.errorCode62503, [
            t("vaultConfiguration.row"),
            t("vaultConfiguration.row"),
            t("vaultConfiguration.vault")
          ]);
          return;
        }
        setIsLoading(true);
        let isUpdating = selected.split("_").at(-1) !== newTypes.row;

        if (isUpdating) {
          const res = await updateRowService(
            districtId,
            row.rowId,
            newRowNumber,
            row.timestamp
          );
          let data = res.data;
          if (data[1] && data[1][0]?.error !== zero) {
            handleError(data[1][0]?.error);
            return;
          }
          updateRowTimeStamp(data[0]);
        } else {
          let physicalVaultId = vault.physicalVaultId;
          const res = await createRowService(
            districtId,
            physicalVaultId,
            newRowNumber
          );
          let data = res.data;
          if (data[0]?.length && data[0][0]?.error) {
            handleError(data[0][0]?.error, t("vaultConfiguration.row"));
            return;
          }
          setCreateMode(false);
          await getRowUnderPhysicalVault(physicalVaultId, selected);
          setNewlyAadded((prev) => [...prev, newNodeId]);
        }
        setIsLoading(false);
        setSelected(newNodeId);
        setSelectedType(nodeTypes.row);
        break;
      }
      case nodeTypes.rack: {
        let { vault, row, rack } = getParentNodes(selected);
        let newRackNumber = numberField.toString().padStart(3, paddZero);
        let newNodeId = `${nodeIdPrefix.rack}${vault.physicalVaultNumber}_${row.rowNumber}_${newRackNumber}`;

        if (
          racks
            .filter((r) => r.rowId === row.rowId)
            .map((r) => r.rackNumber)
            .includes(newRackNumber)
        ) {
          handleError(errorMsgs.errorCode62503, [
            t("vaultConfiguration.rack"),
            t("vaultConfiguration.rack"),
            t("vaultConfiguration.row")
          ]);
          return;
        }
        let rowId = row.rowId;
        setIsLoading(true);
        let isUpdating = selected.split("_").at(-1) !== newTypes.rack;

        if (isUpdating) {
          const res = await updateRackService(
            districtId,
            rack.rackId,
            newRackNumber,
            rack.timestamp
          );
          let data = res.data;
          if (data[1] && data[1][0]?.error !== zero) {
            handleError(data[1][0]?.error);
            return;
          }
          updateRackTimeStamp(data[0]);
        } else {
          const res = await createRackService(districtId, rowId, newRackNumber);
          let data = res.data;
          if (data[0]?.length && data[0][0]?.error) {
            handleError(data[0][0]?.error, t("vaultConfiguration.rack"));
            return;
          }
          setCreateMode(false);
          await getRackUnderRow(vault.physicalVaultId, rowId, selected);
          setNewlyAadded((prev) => [...prev, newNodeId]);
        }
        setIsLoading(false);
        setSelected(newNodeId);
        setSelectedType(nodeTypes.rack);
        break;
      }

      case nodeTypes.shelf: {
        let { vault, row, rack } = getParentNodes(selected);
        let newShelfNumber = numberField.toString().padStart(2, paddZero);
        let isUpdating = selected.split("_").at(-1) !== newTypes.shelf;
        let inputCapacity = capacity !== zero ? capacity : VALUE_EMPTY_STRING;
        if (!inputCapacity ^ !rightFormFactors.length) {
          let code = !capacity
            ? errorMsgs.errorCode62511
            : errorMsgs.errorCode62510;

          handleError(code);
          setCapacity(initialProperties?.capacity);
          return;
        }

        if (
          !isUpdating &&
          shelves
            .filter((s) => s.rackId === rack.rackId)
            .map((r) => r.shelfNumber)
            .includes(newShelfNumber)
        ) {
          handleError(errorMsgs.errorCode62503, [
            t("vaultConfiguration.shelf"),
            t("vaultConfiguration.shelf"),
            t("vaultConfiguration.rack")
          ]);
          return;
        }

        let { rowId } = row;
        let { rackId } = rack;
        let customerId = selectedCustomer?.value || VALUE_EMPTY_STRING;
        let longTermFlag = duration === durations.longTerm ? VALUE_Y : VALUE_N;
        let containerMediaFlag =
          programType === programTypes.container
            ? programTypes.c
            : programTypes.m;
        try {
          setIsLoading(true);
          const newNodeId = `${nodeIdPrefix.shelf}${vault.physicalVaultNumber}_${row.rowNumber}_${rack.rackNumber}_${newShelfNumber}`;

          if (isUpdating) {
            let shelf = getNodeData(selected);

            const res = await updateShelfService(
              districtId,
              shelf.shelfId,
              newShelfNumber,
              customerId,
              capacity,
              longTermFlag,
              containerMediaFlag,
              shelf.timestamp
            );
            let data = res.data;
            if (data[1] && data[1][0]?.error !== zero) {
              handleError(data[1][0]?.error, t("vaultConfiguration.shelf"));
              setIsLoading(false);
              restoreFields();
              return;
            }
            setUpdateMode(false);
            getShelfProperties(shelf.shelfId);
            updateShelfTimeStamp(data[0]);
            if (newShelfNumber !== shelf.shelfNumber) {
              await getShelfUnderRack(
                vault.physicalVaultId,
                rowId,
                rackId,
                selected
              );
            }
          } else {
            const res = await createShelfService(
              districtId,
              rackId,
              customerId,
              capacity,
              longTermFlag,
              containerMediaFlag,
              newShelfNumber
            );
            let data = res.data;
            if (data[0]?.length && data[0][0]?.error) {
              handleError(data[0][0]?.error, t("vaultConfiguration.shelf"));
              setIsLoading(false);
              return;
            }

            let createdShelfId = data[0]?.[0]?.shelf_id;
            if (createdShelfId) {
              await handleFactorMove(
                rightFormFactors,
                typeInsert,
                createdShelfId
              );
              setLeftSelected([]);
              setRightSelected([]);
            }

            setCreateMode(false);
            setIsLoading(true);
            await getShelfUnderRack(
              vault.physicalVaultId,
              rowId,
              rackId,
              selected
            );
            setNewlyAadded((prev) => [...prev, newNodeId]);
          }

          setSelected(newNodeId);

          setSelectedType(nodeTypes.shelf);
        } catch (error) {
          handleCatchError(error);
        }

        break;
      }

      default:
        break;
    }
    if (showPrompt) {
      confirmNavigation();
    }
    dispatch(setHaveChanges(false));
  };

  const resetSelection = () => {
    setSelected(nodeTypes.branch);
    setSelectedType(nodeTypes.branch);
  };

  const onClickDelete = async () => {
    if (createMode) {
      onRefuseSave();
      return;
    }
    setIsLoading(true);
    let { errorMsg } = await getTheFirestoreErrorMessage(
      errorMsgs.errorCode62518
    );
    showThisConfirmMessage(errorMsg);
  };

  const checkIfActiveAudit = async () => {
    try {
      setIsLoading(true);
      const res = await checkAuditActiveService(districtId);
      let data = res?.data[0];
      let comapreWith = data?.[0]?.om_audit_active_flag;
      if (programType === programTypes.container) {
        comapreWith = data?.[0]?.container_audit_active_flag;
      }
      if (data) {
        let hasActiveAudit = comapreWith === VALUE_Y;
        if (hasActiveAudit) {
          let { errorMsg } = await getTheFirestoreErrorMessage(
            errorMsgs.errorCode80149
          );
          setErrorMessage(
            errorMsg.replace("|", t("vaultConfiguration.containerSmall"))
          );
          if (selectedType === nodeTypes.shelf) {
            restoreFields();
          }
          return false;
        } else {
          return true;
        }
      }
    } catch (error) {
      handleCatchError(error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const onClickReleaseSlots = async () => {
    try {
      setIsLoading(true);
      let isAllowedToRelease = await checkIfActiveAudit();
      if (!isAllowedToRelease) return;
      setReleaseMode(errorMsgs.errorCode62519);

      let { errorMsg } = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode62519
      );
      showThisConfirmMessage(errorMsg);
    } catch (error) {
      handleCatchError(error);
    }
  };

  const showThisConfirmMessage = (msg) => {
    switch (selectedType) {
      case nodeTypes.vault: {
        let vault = getNodeData(selected);
        msg = msg.replace(
          "|",
          `${t("vaultConfiguration.vault")} ${vault.physicalVaultNumber}`
        );
        break;
      }
      case nodeTypes.row: {
        let row = getNodeData(selected);
        msg = msg.replace(
          "|",
          `${t("vaultConfiguration.row")} ${row.rowNumber}`
        );
        break;
      }
      case nodeTypes.rack: {
        let rack = getNodeData(selected);
        msg = msg.replace(
          "|",
          `${t("vaultConfiguration.rack")} ${rack.rackNumber}`
        );
        break;
      }
      case nodeTypes.shelf: {
        let shelf = getNodeData(selected);
        msg = msg.replace(
          "|",
          `${t("vaultConfiguration.shelf")} ${shelf.shelfNumber}`
        );
        break;
      }
      default:
        break;
    }
    setIsLoading(false);
    setConfirmMessage(msg);
    setShowConfirmModal(true);
  };

  const onConfirmDelete = async () => {
    setIsLoading(true);
    let isAllowedToDelete = await checkIfActiveAudit();
    if (!isAllowedToDelete) return;
    let reqId = VALUE_EMPTY_STRING;
    let refrehFunction = null;
    let deleteFunction = null;
    let updateDataFunction = null;
    let number = VALUE_EMPTY_STRING;
    setIsLoading(true);
    setErrorMessage(VALUE_EMPTY_STRING);

    let { vault, row, rack } = getParentNodes(selected);
    let parts = selected.split("_");
    let parentParts = parts.slice(1, parts.length - 1);
    let parentId = nodeTypes.branch;
    switch (selectedType) {
      case nodeTypes.vault: {
        reqId = vault.physicalVaultId;
        number = vault.physicalVaultNumber;
        refrehFunction = getPhysicalVaults;
        deleteFunction = deleteVaultService;
        resetSelection();
        break;
      }
      case nodeTypes.row: {
        reqId = row.rowId;
        number = row.rowNumber;
        refrehFunction = () =>
          getRowUnderPhysicalVault(vault.physicalVaultId, selected);
        deleteFunction = deleteRowService;
        updateDataFunction = () =>
          setRows((prev) => prev.filter((item) => item.rowId !== reqId));
        parentId = `${nodeIdPrefix.vault}${parentParts?.join("_")}`;

        break;
      }
      case nodeTypes.rack: {
        reqId = rack.rackId;
        number = rack.rackNumber;
        refrehFunction = () =>
          getRackUnderRow(vault.physicalVaultId, row.rowId, selected);
        deleteFunction = deleteRackService;
        updateDataFunction = () =>
          setRacks((prev) => prev.filter((item) => item.rackId !== reqId));
        parentId = `${nodeIdPrefix.row}${parentParts?.join("_")}`;

        break;
      }
      case nodeTypes.shelf: {
        let shelf = getNodeData(selected);
        reqId = shelf.shelfId;
        number = shelf.shelfNumber;
        refrehFunction = () =>
          getShelfUnderRack(
            vault.physicalVaultId,
            row.rowId,
            rack.rackId,
            selected
          );
        deleteFunction = deleteShelfService;
        updateDataFunction = () =>
          setShelves((prev) => prev.filter((item) => item.shelfId !== reqId));
        parentId = `${nodeIdPrefix.rack}${parentParts?.join("_")}`;

        break;
      }
      default:
        break;
    }

    try {
      const res = await deleteFunction(districtId, reqId);
      let data = res.data;
      if (data[0]?.length && data[0][0]?.error) {
        handleError(
          data[0][0]?.error,
          `${getNameText(selectedType)} ${number}`
        );

        return;
      }
      if (updateDataFunction) {
        updateDataFunction();
      }
      refrehFunction();
      setSelected(parentId);
      setSelectedType(parentId.split("_")[0]);
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
      setConfirmMessage(VALUE_EMPTY_STRING);
      setShowConfirmModal(false);
    }
  };

  const onConfirmRelease = async (releaseFormFactorFlag) => {
    let reqId = VALUE_EMPTY_STRING;
    let releaseFunction = null;
    let number = VALUE_EMPTY_STRING;
    let type = VALUE_EMPTY_STRING;
    setIsLoading(true);
    setErrorMessage(VALUE_EMPTY_STRING);

    let { vault, row, rack } = getParentNodes(selected);

    switch (selectedType) {
      case nodeTypes.vault: {
        reqId = vault.physicalVaultId;
        number = vault.physicalVaultNumber;
        releaseFunction = releaseVaultService;
        type = t("vaultConfiguration.vault");
        break;
      }
      case nodeTypes.row: {
        reqId = row.rowId;
        number = row.rowNumber;
        releaseFunction = releaseRowService;
        type = t("vaultConfiguration.row");
        break;
      }
      case nodeTypes.rack: {
        reqId = rack.rackId;
        number = rack.rackNumber;
        releaseFunction = releaseRackService;
        type = t("vaultConfiguration.rack");
        break;
      }
      case nodeTypes.shelf: {
        let shelf = getNodeData(selected);
        reqId = shelf.shelfId;
        number = shelf.shelfNumber;
        releaseFunction = releaseShelfService;
        type = t("vaultConfiguration.shelf");
        break;
      }
      default:
        break;
    }
    try {
      const res = await releaseFunction(
        districtId,
        reqId,
        releaseFormFactorFlag
      );
      let data = res.data;
      if (data[0]?.length && data[0][0]?.error !== zero) {
        handleError(
          data[0][0]?.error,
          `${getNameText(selectedType)} ${number}`
        );
        setReleaseMode(VALUE_EMPTY_STRING);
        return;
      }
      let { errorMsg } = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode62512
      );
      setSuccessMessage(errorMsg.replace("|", `${type} ${number}`));
      setReleaseMode(VALUE_EMPTY_STRING);
      if (selectedType === nodeTypes.shelf) {
        getShelfProperties(reqId);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
      setConfirmMessage(VALUE_EMPTY_STRING);
      setShowConfirmModal(false);
      setReleaseMode(VALUE_EMPTY_STRING);
    }
  };
  const onRefuseSave = () => {
    let removeFrom = null;
    switch (selectedType) {
      case nodeTypes.vault:
        removeFrom = setPhysicalVaults;
        break;
      case nodeTypes.row:
        removeFrom = setRows;
        break;
      case nodeTypes.rack:
        removeFrom = setRacks;
        break;
      case nodeTypes.shelf:
        removeFrom = setShelves;
        break;
      default:
        break;
    }
    if (removeFrom) {
      removeFrom((prev) => prev.filter((item) => !!item.timestamp));
    }
    if (selectedType === nodeTypes.shelf) {
      resetRightPanel();
      setIsPopulated(false);
    }
    resetSelection();
    setCreateMode(false);
    setShowConfirmModal(false);
  };

  const onCLickYesFromConfirm = async () => {
    if (createMode) {
      onClickSave();
    } else if (releaseMode === errorMsgs.errorCode62519) {
      setReleaseMode(errorMsgs.errorCode62520);
      let { errorMsg } = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode62520
      );
      showThisConfirmMessage(errorMsg);
    } else if (releaseMode === errorMsgs.errorCode62520) {
      onConfirmRelease(VALUE_Y);
    } else {
      onConfirmDelete();
    }
  };

  const onClickNoFromConfirm = () => {
    if (createMode) {
      onRefuseSave();
    } else if (releaseMode === errorMsgs.errorCode62520) {
      onConfirmRelease(VALUE_N);
    } else {
      setShowConfirmModal(false);
    }
  };

  const getNameText = (type) => {
    switch (type) {
      case nodeTypes.vault:
        return t("vaultConfiguration.vault");
      case nodeTypes.row:
        return t("vaultConfiguration.row");
      case nodeTypes.rack:
        return t("vaultConfiguration.rack");
      case nodeTypes.shelf:
        return t("vaultConfiguration.shelf");
      default:
        return VALUE_EMPTY_STRING;
    }
  };
  const handleConfirmationOnNo = () => {
    dispatch(setHaveChanges(false));
    confirmNavigation();
  };
  const getModalButtons = () => {
    return (
      <>
        {createMode ? (
          <Button
            variant="outlined"
            onClick={() => {
              setShowConfirmModal(false);
            }}
          >
            {t("common.cancel")}
          </Button>
        ) : null}
        <Button variant="outlined" onClick={onCLickYesFromConfirm}>
          {t("common.yes")}
        </Button>
        <Button variant="contained" onClick={onClickNoFromConfirm}>
          {t("common.no")}
        </Button>
      </>
    );
  };

  const onChangeCapacity = (e) => {
    dispatch(setHaveChanges(true));
    setErrorMessage("");
    let value = e.target.value;
    if (value === VALUE_EMPTY_STRING) {
      setCapacity(zero);
      return;
    }
    if (!value || !isNaN(value)) setCapacity(value);
  };

  const getMaxCapacity = () => {
    return programType === programTypes.openMedia
      ? capacityMax.four
      : capacityMax.three;
  };
  const handleConfirmationOnCancel = () => cancelNavigation();

  return (
    <>
      {isLoading && <FullCircularLoader />}
      {!!errorMessage && (
        <Collapse in={!!errorMessage}>
          <Alert
            severity="error"
            icon={
              <img
                src={ErrorIcon}
                alt="error"
                style={VaultConfigurationStyles.errorIcon}
              />
            }
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage(VALUE_EMPTY_STRING);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMessage}
          </Alert>
        </Collapse>
      )}

      {successMessage && (
        <SuccessPopup message={successMessage} close={setSuccessMessage} />
      )}

      <CommonModal
        open={showConfirmModal}
        buttons={getModalButtons()}
        title={t("common.confirm")}
        body={
          <Typography>
            {createMode
              ? t("vaultConfiguration.saveChangesMsg")
              : confirmMessage}
          </Typography>
        }
      />

      {showPrompt && (
        <ConfirmPopup
          modalPopupOpen={showPrompt}
          handleYes={() => onClickSave()}
          handleNo={handleConfirmationOnNo}
          showNo={true}
          handleCancel={handleConfirmationOnCancel}
          showCancel={true}
        />
      )}

      <Grid container>
        <Grid item xs={3}>
          <Stack gap={spacing.gap} sx={VaultConfigurationStyles.treeWrapper}>
            <Typography sx={VaultConfigurationStyles.subHeading}>
              {t("vaultConfiguration.vaultMap")}
            </Typography>
            <Paper sx={VaultConfigurationStyles.treePaper} mr={3} mb={3}>
              <VaultConfigurationTree
                physicalVaults={physicalVaults}
                rows={rows}
                racks={racks}
                shelves={shelves}
                expanded={expanded}
                loaders={loaders}
                setPropertiesHeading={setPropertiesHeading}
                setLoaders={setLoaders}
                setExpanded={setExpanded}
                setSelectedType={setSelectedType}
                getRowUnderPhysicalVault={getRowUnderPhysicalVault}
                getRackUnderRow={getRackUnderRow}
                getShelfUnderRack={getShelfUnderRack}
                setNumberField={setNumberField}
                setInitialNumberField={setInitialNumberField}
                getShelfProperties={getShelfProperties}
                setIsPopulated={setIsPopulated}
                setSelected={setSelected}
                selected={selected}
                onSelectedItemsChange={onSelectedItemsChange}
                createMode={createMode}
                newlyAdded={newlyAdded}
                loadedVaults={loadedVaults}
              />
            </Paper>
          </Stack>
        </Grid>
        <Grid item xs={9} mb={2}>
          <Stack
            gap={spacing.gap10}
            ml={spacing.gap10}
            sx={VaultConfigurationStyles.rightPanelWrapper}
          >
            <Typography
              sx={VaultConfigurationStyles.subHeading}
              mb={spacing.gap10}
            >
              {propertiesHeading}
            </Typography>

            {selectedType && selectedType !== nodeTypes.branch ? (
              <Grid container mb={spacing.gap10}>
                <Grid item xs={12}>
                  <Grid item xs={4} pr={spacing.gap}>
                    <TextField
                      label={t("vaultConfiguration.number")}
                      variant="outlined"
                      fullWidth
                      value={numberField}
                      onChange={(e) => onChangeNumber(e)}
                      inputProps={{ maxLength: getMaxLength() }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {selectedType === nodeTypes.shelf && isPopulated ? (
              <>
                <Grid container>
                  <Grid item xs={4} mb={1} pr={spacing.gap}>
                    <FormControl
                      component="fieldset"
                      disabled={disabledFields.includes(idProgramType)}
                    >
                      <FormLabel component="legend">
                        {t("vaultConfiguration.programType")}
                      </FormLabel>
                      <RadioGroup
                        value={programType}
                        onChange={(e) => onChangeProgramType(e)}
                        sx={VaultConfigurationStyles.radioButtonGroup}
                      >
                        <FormControlLabel
                          value="container"
                          control={<Radio />}
                          label={t("vaultConfiguration.container")}
                        />
                        <FormControlLabel
                          value="openMedia"
                          control={<Radio />}
                          label={t("vaultConfiguration.openMedia")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} mb={1} pr={spacing.gap}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        {t("vaultConfiguration.duration")}
                      </FormLabel>
                      <RadioGroup
                        value={duration}
                        onChange={(e) => {
                          dispatch(setHaveChanges(true));

                          setDuration(e.target.value);
                        }}
                        sx={VaultConfigurationStyles.radioButtonGroup}
                      >
                        <FormControlLabel
                          value={durations.active}
                          control={<Radio />}
                          label={t("vaultConfiguration.active")}
                        />
                        <FormControlLabel
                          value={durations.longTerm}
                          control={<Radio />}
                          label={t("vaultConfiguration.longTerm")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} pr={spacing.gap}>
                    <Autocomplete
                      componentsProps={{
                        clearIndicator: ArrowDown
                      }}
                      popupIcon={<ArrowDown />}
                      clearIcon={<CloseIcon />}
                      disabled={disabledFields.includes(idCustomer)}
                      id="assigned-customer"
                      name={"assigned-customer"}
                      options={customers}
                      value={selectedCustomer || branch}
                      onChange={handleCustomerChange}
                      size="medium"
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("vaultConfiguration.assignedCustomer")}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4} pr={spacing.gap}>
                    <Box mb={spacing.verticalMargin20}>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("vaultConfiguration.logicalVault")}
                      </Typography>
                      <Typography variant="subtitle1">
                        {logicalVault || hyphen}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container pt={spacing.gap10}>
                  <Grid item md={5.5}>
                    <Paper sx={VaultConfigurationStyles.formFactorPaper}>
                      <Stack
                        pb={spacing.gap10}
                        sx={VaultConfigurationStyles.formFactorHeader}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={VaultConfigurationStyles.checkboxColor}
                              checked={
                                leftSelected.length > 0 &&
                                leftSelected.length === leftFormFactors.length
                              }
                              onChange={(e) =>
                                handleSelectAll(
                                  leftFormFactors,
                                  setLeftSelected,
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label={
                            <Stack ml={1}>
                              <Typography variant="span">
                                {t("vaultConfiguration.formFactors")}
                              </Typography>
                              <Typography variant="caption">
                                {leftSelected.length}/{leftFormFactors.length}
                                selected
                              </Typography>
                            </Stack>
                          }
                        />
                      </Stack>
                      <Divider />

                      <Stack sx={VaultConfigurationStyles.formFactorBody}>
                        {leftFormFactors
                          .sort((a, b) =>
                            a.short_descr.localeCompare(b.short_descr)
                          )
                          .map((formFactor) => (
                            <FormControlLabel
                              key={formFactor.form_factor_id}
                              control={
                                <Checkbox
                                  sx={VaultConfigurationStyles.checkboxColor}
                                  checked={leftSelected.includes(
                                    formFactor.form_factor_id
                                  )}
                                  onChange={(e) => {
                                    dispatch(setHaveChanges(true));

                                    const selected = [...leftSelected];
                                    if (e.target.checked) {
                                      selected.push(formFactor.form_factor_id);
                                    } else {
                                      const index = selected.indexOf(
                                        formFactor.form_factor_id
                                      );
                                      if (index > -1) {
                                        selected.splice(index, 1);
                                      }
                                    }
                                    setLeftSelected(selected);
                                  }}
                                />
                              }
                              label={
                                <Typography ml={1}>
                                  {formFactor.short_descr}
                                </Typography>
                              }
                            />
                          ))}
                      </Stack>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    md={1}
                    sx={VaultConfigurationStyles.arrowButtonsGrid}
                  >
                    <Stack justifyContent="center" gap={spacing.gap} px={2}>
                      <Button variant="outlined" onClick={handleMoveRight}>
                        <ArrowRight />
                      </Button>
                      <Button variant="outlined" onClick={handleMoveLeft}>
                        <ArrowLeft />
                      </Button>
                    </Stack>
                  </Grid>

                  <Grid item md={5.5}>
                    <Paper sx={VaultConfigurationStyles.formFactorPaper}>
                      <Stack
                        pb={spacing.gap10}
                        sx={VaultConfigurationStyles.formFactorHeader}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={VaultConfigurationStyles.checkboxColor}
                              checked={
                                rightSelected.length > 0 &&
                                rightSelected.length === rightFormFactors.length
                              }
                              onChange={(e) =>
                                handleSelectAll(
                                  rightFormFactors,
                                  setRightSelected,
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label={
                            <Stack ml={1}>
                              <Typography variant="span">
                                {t("vaultConfiguration.formFactorsSelected")}
                              </Typography>
                              <Typography variant="caption">
                                {rightSelected.length}/{rightFormFactors.length}
                                selected
                              </Typography>
                            </Stack>
                          }
                        />
                      </Stack>
                      <Divider />

                      <Stack sx={VaultConfigurationStyles.formFactorBody}>
                        {rightFormFactors
                          .sort((a, b) =>
                            a.short_descr.localeCompare(b.short_descr)
                          )
                          .map((formFactor) => (
                            <FormControlLabel
                              key={formFactor.form_factor_id}
                              control={
                                <Checkbox
                                  sx={VaultConfigurationStyles.checkboxColor}
                                  checked={rightSelected.includes(
                                    formFactor.form_factor_id
                                  )}
                                  onChange={(e) => {
                                    dispatch(setHaveChanges(true));

                                    const selected = [...rightSelected];
                                    if (e.target.checked) {
                                      selected.push(formFactor.form_factor_id);
                                    } else {
                                      const index = selected.indexOf(
                                        formFactor.form_factor_id
                                      );
                                      if (index > -1) {
                                        selected.splice(index, 1);
                                      }
                                    }
                                    setRightSelected(selected);
                                  }}
                                />
                              }
                              label={
                                <Typography ml={1}>
                                  {formFactor.short_descr}
                                </Typography>
                              }
                            />
                          ))}
                      </Stack>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container pt={spacing.gap10}>
                  <Grid item xs={4} pr={spacing.gap10}>
                    <TextField
                      label={t("vaultConfiguration.capacity")}
                      variant="outlined"
                      fullWidth
                      disabled={disabledFields.includes(idCapacity)}
                      value={capacity === zero ? "" : capacity}
                      onChange={(e) => onChangeCapacity(e)}
                      inputProps={{ maxLength: getMaxCapacity() }}
                    />
                  </Grid>
                  <Grid item xs={4} px={spacing.gap10}>
                    <Box mb={spacing.verticalMargin20}>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("vaultConfiguration.startingSlot")}
                      </Typography>
                      <Typography variant="subtitle1">
                        {startingSlot || VALUE_EMPTY_STRING}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} pl={spacing.gap10}>
                    <Box mb={spacing.verticalMargin20}>
                      <Typography variant="subtitle1" color="textSecondary">
                        {t("vaultConfiguration.endingSlot")}
                      </Typography>
                      <Typography variant="subtitle1">
                        {endingSlot || VALUE_EMPTY_STRING}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Divider />
          <Stack
            mt={spacing.gap}
            gap={spacing.gap}
            ml={spacing.gap10}
            direction="row"
          >
            <Button
              variant="outlined"
              disabled={buttonsDisable.print}
              onClick={() => setIsReportsOpen(true)}
            >
              {t("common.print")}
            </Button>
            <Button
              variant="outlined"
              disabled={buttonsDisable.delete}
              onClick={onClickDelete}
            >
              {t("common.delete")}
            </Button>
            <Button
              variant="outlined"
              disabled={buttonsDisable.releaseSlots}
              onClick={onClickReleaseSlots}
            >
              {t("vaultConfiguration.buttonReleaseSlots")}
            </Button>

            <Button
              variant="outlined"
              disabled={buttonsDisable.save}
              onClick={onClickSave}
            >
              {t("common.save")}
            </Button>
            <Button
              variant="contained"
              disabled={buttonsDisable.new}
              onClick={handleClickNew}
            >
              {t("common.new")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {isReportsOpen ? (
        <VaultManagementReports
          open={isReportsOpen}
          setIsReportsOpen={setIsReportsOpen}
          fromDashboard={false}
        />
      ) : null}
    </>
  );
};

export default VaultConfiguration;
