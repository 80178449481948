import CloseIcon from "@mui/icons-material/Close";
import { Alert, Divider, IconButton, Stack } from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import AuthenticationCallerButton from "components/shared/auth/AuthenticationCallerButton";
import SuccessAuthSection from "components/shared/auth/SuccessAuthSection";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CONTAINER_INVENTORY_AUDIT_DETAIL,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH
} from "routing/Paths";
import {
  useGetAllCustomersQuery,
  useGetPersonnelAuthQuery,
  useGetValidateChallengeResponseMutation
} from "services/api";
import { CF_URLS } from "services/api/endpoints";
import {
  getAudiType,
  getAuditStatusData,
  getDistricts,
  getMessageFromCode,
  getRequestorTypeData
} from "services/api/query";
import { selectAuth, setAuthenticationState } from "store/slices";
import {
  clearError,
  clearWarning,
  fetchDataFailure,
  fetchDataRequest,
  fetchDataSuccess,
  resetForm,
  setAuthCustomers,
  setError,
  setLoading,
  setSearchResults,
  setWarning,
  updateAuthFormValues,
  updateContainerAuthentication,
  updateSearchFormValues
} from "store/slices/container-processing";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  DEFAULT_LOCALE_CODE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  SYSTEM_ID_SecureBaseWeb,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y,
  Y_CHECKER,
  authSection,
  dateFormat,
  downloadOptionPdf,
  errorMsgs,
  numberConstants
} from "utils/constants";
import {
  checkFilterOptions,
  defaultStatus,
  globalAttributeTypeIdCalFuture,
  globalAttributeTypeIdCalPast,
  globalAttributeTypeIdMaxRowLimit,
  pendingResolution,
  programSecurityCode299
} from "utils/constants/container-processing/ContainerAuditConstants";
import {
  findErrorMessage,
  generateSHA256Hash,
  getAuthenticatedUserBranch,
  getGlobalAttributeValue,
  getLanguageId,
  getResponseData,
  getSortedData,
  getUserName,
  printReport
} from "utils/helpers";

import DownloadPopup from "components/core/modal-components/DownloadPopup";
import AuditAuthForm from "components/shared/auth/AuditAuthSection";
import { getAuthorizationPersonnelsAuthentications } from "services/common";
import AuditSearch from "./AuditSearch";
import AuditTable from "./AuditTable";
import { AuditStyles } from "./auditStyles";
const AuditLayout = () => {
  const {
    searchFormValues,
    searchResults,
    isLoading,
    throwError,
    throwErrorMsg,
    authFormValues,
    isAuthenticated,
    throwWarning,
    throwWarningMsg,
    authCustomers
  } = useSelector((state) => state.containerAuditSearch);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const prevLocation = location?.state?.from;
  const { currentBranch, auth } = useSelector(selectAuth);
  const today = dayjs();

  const programSecurityList = auth?.user?.program_list;
  const programSecurityListArray = programSecurityList.split(",").map(Number);
  const isNewVisible = programSecurityList.includes(programSecurityCode299);

  const dispatch = useDispatch();
  const mainDistrictId = String(
    getAuthenticatedUserBranch() ||
      currentBranch?.district_id ||
      VALUE_EMPTY_STRING
  );

  const [showAuthenticationSection, setShowAuthenticationSection] =
    useState(false);
  const [allBranches, setAllBranches] = useState([]);
  const [valuePersonnelAuth] = useState(authSection?.personnelAuth);
  const [isAuthenticationError, setIsAuthenticationError] = useState(false);
  const languageLocaleId = getLanguageId();
  const [auditTypes, setAuditTypes] = useState([]);
  const [auditStatusList, setAuditStatusList] = useState([]);
  const [reqList, setReqList] = useState([ALL_SELECTION]);
  const [isResetGrid, setIsResetGrid] = useState(true);
  const [isFindButtonDisabled, setIsFindButtonDisabled] = useState(false);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedId, setSelectedId] = useState("");
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [maxRecordMsg, setMaxRecordMsg] = useState("");
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);
  const [reportCFUrl, setReportCFUrl] = useState("");

  let initialAuthValues = {
    customer: "",
    personnel: "",
    authNumber: "",
    challengeQuestion: "",
    challengeResponse: ""
  };

  const auditForm = useFormik({
    initialValues: searchFormValues,
    onSubmit: (values) => {
      onFindClick();
    }
  });
  const resetValues = () => {
    const updates = {
      reqBy: ALL_SELECTION,
      auditType: ALL_SELECTION,
      auditStatus: ALL_SELECTION,
      dueDate: null,
      dueDateTo: null,
      exceptionsOnly: false,
      auditDate: null,
      auditTo: null
    };
    dispatch(updateSearchFormValues(updates));
  };

  const containerAuthForm = useFormik({
    initialValues: authFormValues
  });

  // selected personnel auth details
  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: authFormValues?.personnel?.value
  });

  // getting the Data from useGetAllCustomersQuery
  const { data: allCustomers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  // getting the Customers on branch change in authentication
  const { data: authenticatedCustomers = [] } = useGetAllCustomersQuery({
    mainDistrictId: authFormValues?.branch
  });
  const handlePrintButtonClick = () => {
    setShowPrintModal(true);
    setReportCFUrl(
      CF_URLS.printReports.containerProcessing.containerAuditSummeryReport
    );
  };

  const getOMauditMaxDispaly = async () => {
    const auditSearchMaxDisplay = await getGlobalAttributeValue(
      currentBranch?.value,
      SYSTEM_ID_SecureBaseWeb,
      globalAttributeTypeIdMaxRowLimit
    );
    return auditSearchMaxDisplay;
  };

  const getMinDate = async () => {
    const auditSearchMinDate = await getGlobalAttributeValue(
      currentBranch?.value,
      SYSTEM_ID_SecureBaseWeb,
      globalAttributeTypeIdCalPast
    );
    const minDateNumber =
      parseInt(auditSearchMinDate) / numberConstants.tweleve;
    const minDateFormatted = today.subtract(minDateNumber, "year");
    setMinDate(minDateFormatted);
  };

  const getMaxDate = async () => {
    const auditSearchMaxDate = await getGlobalAttributeValue(
      currentBranch?.value,
      SYSTEM_ID_SecureBaseWeb,
      globalAttributeTypeIdCalFuture
    );
    const maxDateNumber =
      parseInt(auditSearchMaxDate) / numberConstants.tweleve;
    const maxDateFormatted = today.add(maxDateNumber, "year");
    setMaxDate(maxDateFormatted);
  };

  useEffect(() => {
    if (throwError) {
      window.scrollTo(0, 0);
    }
  }, [throwError]);

  useEffect(() => {
    if (authFormValues?.branch && isAuthenticated) {
      getAllocatedCustomers();
    }
    // eslint-disable-next-line
  }, [authFormValues?.branch, isAuthenticated]);
  useEffect(() => {
    getAllBarnchesFromFireStore();
    getMaxDate();
    getMinDate();
    getAuditStatusList();
    getReqTypeList();
    if (!prevLocation) {
      handleReset();
      dispatch(clearError());
      dispatch(clearWarning());
      dispatch(setSearchResults([]));
    } else {
      setIsDataLoaded(false);
      onFindClick();
    }
    // eslint-disable-next-line
  }, []);

  // set the default branch into the auth section
  useEffect(() => {
    if (allBranches && allBranches?.length) {
      dispatch(setLoading(true));
      const selectedLocationDetails = allBranches.find(
        (location) => location.district_id === currentBranch?.value
      );
      const updates = {
        branch: selectedLocationDetails?.district_id,
        customer: VALUE_EMPTY_STRING,
        personnel: VALUE_EMPTY_STRING,
        authNumber: VALUE_EMPTY_STRING,
        challengeQuestion: VALUE_EMPTY_STRING,
        challengeResponse: VALUE_EMPTY_STRING
      };
      dispatch(updateAuthFormValues(updates));
      dispatch(setLoading(false));
    }
    // eslint-disable-next-line
  }, [allBranches]);

  // get challange question
  useEffect(() => {
    if (personnelAuth?.length) {
      const updates = {
        ...authFormValues,
        challengeQuestion: personnelAuth[0]?.challenge_question
      };
      dispatch(updateAuthFormValues(updates));
    }
    // eslint-disable-next-line
  }, [personnelAuth]);

  const getAuditTypeList = async (code) => {
    try {
      const result = await getAudiType(checkFilterOptions.Customer);
      let response = [];
      if (code === checkFilterOptions.Customer) {
        response = result
          ?.filter((res) => res.customer_required_flag === Y_CHECKER)
          .map((res) => ({
            value: res.audit_type_id,
            label: res.descr
          }));
      } else {
        response = result?.map((res) => ({
          value: res.audit_type_id,
          label: res.descr
        }));
      }
      const responseWithAll = [ALL_SELECTION].concat(response);
      responseWithAll.sort((a, b) => a?.value?.localeCompare(b?.value));
      setAuditTypes(responseWithAll);
    } catch (err) {
      dispatch(setError(ERROR_TYPES.ISSUE));
    }
  };

  const getAuditStatusList = async () => {
    try {
      const result = await getAuditStatusData();
      const response = result.filter((res) => res.descr !== pendingResolution);
      const statuses = response.map((res) => ({
        value: res.audit_status_id,
        label: res.descr
      }));

      statuses.sort((a, b) => a.value.localeCompare(b.value));
      const responseWithAll = [ALL_SELECTION].concat(statuses);
      setAuditStatusList(responseWithAll);
    } catch (err) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  const getReqTypeList = async () => {
    try {
      const result = await getRequestorTypeData(numberConstants?.six);
      const response = result.map((res) => ({
        value:
          res.dropdown_list_id === numberConstants?.twentyFour
            ? checkFilterOptions.Branch
            : res.dropdown_list_id === numberConstants?.twentyFive
              ? checkFilterOptions.Customer
              : res.dropdown_list_id,
        label: res.descr
      }));
      const responseWithAll = [ALL_SELECTION].concat(response);
      responseWithAll.sort((a, b) => a.label?.localeCompare(b?.label));
      setReqList(responseWithAll);
    } catch (err) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  const onFindClick = async () => {
    dispatch(fetchDataSuccess([]));
    dispatch(clearError());
    dispatch(fetchDataRequest());
    setMaxRecordMsg("");
    const {
      auditId,
      auditType,
      auditStatus,
      auditDate,
      auditTo,
      customer,
      reqBy,
      dueDate,
      dueDateTo,
      exceptionsOnly
    } = searchFormValues;
    const maxRowLimit = await getOMauditMaxDispaly();
    try {
      const auditToDate = auditTo ? auditTo : auditDate;
      const dueToDate = dueDateTo ? dueDateTo : dueDate;
      if (auditToDate < auditDate) {
        const errorObj = await getMessageFromCode(errorMsgs?.errorCode80165);
        const errorMsg = errorObj[0]?.descr;
        const errorString = errorMsg?.replace(
          "{0}",
          t("containerAuditSearch.auditData")
        );
        dispatch(
          fetchDataFailure(errorString || findErrorMessage(ERROR_TYPES.ISSUE))
        );
        return;
      }
      if (dueToDate < dueDate) {
        const errorObj = await getMessageFromCode(errorMsgs?.errorCode80165);
        const errorMsg = errorObj[0]?.descr;
        const errorString = errorMsg?.replace(
          "{0}",
          t("containerAuditSearch.dueDate")
        );
        dispatch(
          fetchDataFailure(errorString || findErrorMessage(ERROR_TYPES.ISSUE))
        );
        return;
      }
      let customersList = "";
      if (isAuthenticated) {
        const ids = authCustomers
          .map((cust) => cust.value)
          .filter((value) => value !== t("common.all"));
        customersList = customer
          ? customer?.value === t("common.all")
            ? ids.join(",")
            : customer.value
          : "";
      } else {
        customersList = customer
          ? customer?.value === t("common.all")
            ? ""
            : customer.value
          : "";
      }
      let reqBody = null;
      if (auditId && auditId > 0) {
        reqBody = JSON.stringify({
          main_district_id:
            authFormValues?.branch?.district_id || mainDistrictId,
          container_audit_id: auditId,
          customer_id_list: customersList,
          iso_locale_code: DEFAULT_LOCALE_CODE,
          with_exception_only_flag: VALUE_N,
          row_limit: maxRowLimit
        });
      } else {
        reqBody = JSON.stringify({
          main_district_id:
            authFormValues?.branch?.district_id || mainDistrictId,
          container_audit_id: null,
          audit_type_id:
            auditType?.value === t("common.all") ? "" : auditType?.value,

          customer_id_list: customersList,

          audit_date_from:
            auditDate && dayjs(auditDate).format(dateFormat.universalFormat),
          audit_date_to:
            auditToDate &&
            dayjs(auditToDate).format(dateFormat.universalFormat),
          requestor_code: reqBy?.value === t("common.all") ? "" : reqBy?.value,
          audit_status_id:
            auditStatus?.value === t("common.all") ? "" : auditStatus?.value,
          due_date_from:
            dueDate && dayjs(dueDate).format(dateFormat.universalFormat),
          due_date_to:
            dueToDate && dayjs(dueToDate).format(dateFormat.universalFormat),
          iso_locale_code: DEFAULT_LOCALE_CODE,
          with_exception_only_flag: exceptionsOnly ? VALUE_Y : VALUE_N,
          row_limit: maxRowLimit
        });
      }
      let res = await getResponseData(
        reqBody,
        `${CF_URLS.containerProcessing.returncontaineraudits}`,
        4
      );

      setIsResetGrid(false);
      dispatch(setLoading(false));
      if (res) {
        const data0 = res?.data[0];

        data0.sort((a, b) => {
          const dueDateComparison = a.due_date.localeCompare(b.due_date);
          if (dueDateComparison !== 0) {
            return dueDateComparison;
          }
          return a.container_audit_id - b.container_audit_id;
        });
        setIsDataLoaded(true);
        if (data0?.length > maxRowLimit) {
          const errorObj = await getMessageFromCode(errorMsgs?.errorCode80168);
          const errorMsg = errorObj[0]?.descr;
          const errorString = errorMsg?.replace("{0}", maxRowLimit);
          dispatch(fetchDataFailure(errorString));
          const msg =
            t("containerAuditSearch.matchRecordCount") + " > " + maxRowLimit;
          setMaxRecordMsg(msg);
          return;
        }
        dispatch(fetchDataSuccess(data0));
        if (res?.data[0][0]?.error) {
          const errorObj = await getMessageFromCode(res?.data[0][0]?.error);
          const errorMsg = errorObj[0]?.descr;
          dispatch(
            fetchDataFailure(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE))
          );
        } else if (res?.error) {
          dispatch(fetchDataFailure(res?.error));
        }
      }
      if (auditId && auditId > 0) {
        resetValues();
      }
    } catch (e) {
      dispatch(fetchDataFailure(findErrorMessage(ERROR_TYPES.ISSUE)));
    }
  };

  // get branches from firestore
  const getAllBarnchesFromFireStore = async () => {
    dispatch(setLoading(true));
    try {
      const branches = await getDistricts();
      let branchList = branches?.map((branch) => {
        return {
          ...branch,
          label: branch?.name,
          value: branch?.district_id
        };
      });
      const branchData = getSortedData(branchList, "name", "asc");
      setAllBranches(branchData);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.EXCEPTION)));
      dispatch(setLoading(false));
    }
  };

  // if authenticated get the allocated customers
  const getAllocatedCustomers = async () => {
    dispatch(setLoading(true));
    try {
      if (authenticatedCustomers) {
        const data = await getAuthorizationPersonnelsAuthentications({
          personnelId: authFormValues?.personnel?.value
        });
        const personalCustomers = JSON.parse(data?.customer_list);
        const personalCustomersList = personalCustomers?.map(
          ({ number, name, customer_Id }) => ({
            label: `${number} - ${name.trim()}`,
            value: customer_Id,
            number: number
          })
        );
        personalCustomersList?.sort((a, b) =>
          String(a.value).localeCompare(String(b.value))
        );
        if (personalCustomersList.length) {
          const allCux = [ALL_SELECTION, ...personalCustomersList];
          dispatch(setAuthCustomers(allCux));
          const errorObj = await getMessageFromCode(errorMsgs?.errorCode80251);
          const errorMsg = errorObj[0]?.descr;

          dispatch(setWarning(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE)));
        } else {
          const errorObj = await getMessageFromCode(errorMsgs?.errorCode80241);
          const errorMsg = errorObj[0]?.descr;

          dispatch(setWarning(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE)));
          dispatch(setAuthCustomers([]));
        }
      } else {
        const errorObj = await getMessageFromCode(errorMsgs?.errorCode80241);
        const errorMsg = errorObj[0]?.descr;

        dispatch(setWarning(errorMsg || findErrorMessage(ERROR_TYPES.ISSUE)));
        const updatedValues = {
          ...authFormValues,
          authNumber: "",
          challengeQuestion: "",
          challengeResponse: ""
        };
        dispatch(updateAuthFormValues(updatedValues));
        setShowAuthenticationSection(true);
        dispatch(updateContainerAuthentication(false));
      }
      dispatch(setLoading(false));
    } catch (e) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
      dispatch(setLoading(false));
    }
  };

  const handlePersonnelAuthChange = (selectedValue) => {
    const updatedValues = {
      ...authFormValues,
      personnel: selectedValue,
      authNumber: "",
      challengeQuestion: "",
      challengeResponse: ""
    };
    dispatch(updateAuthFormValues(updatedValues));
    dispatch(updateContainerAuthentication(false));
    setIsAuthenticationError(false);
    dispatch(clearError());
  };
  const isShowAuthenticationSection = () => {
    if (showAuthenticationSection) {
      return true;
    }
    return false;
  };

  const onAuthenticatedCallerCliked = () => {
    if (isAuthenticated) {
      dispatch(setAuthenticationState(null));
      dispatch(updateAuthFormValues(initialAuthValues));
      const updates = !isAuthenticated;

      dispatch(updateContainerAuthentication(updates));
      dispatch(resetForm());
      dispatch(clearWarning());
    } else if (showAuthenticationSection) {
      setShowAuthenticationSection(false);
    } else {
      setShowAuthenticationSection(true);
    }
    dispatch(clearError());
  };

  const onCancelAuthCliked = () => {
    setShowAuthenticationSection(false);
  };
  // handle branch change
  const handleBranchChange = (e) => {
    const updatedValues = {
      branch: e.target.value,
      customer: "",
      personnel: ""
    };

    dispatch(updateAuthFormValues(updatedValues));
  };
  // customer selection in auth section
  const handleCustomerChange = (event, newValue) => {
    dispatch(clearError());
    const updatedValues = {
      ...authFormValues,
      customer: newValue,
      personnel: ""
    };
    dispatch(updateAuthFormValues(updatedValues));
  };
  // handle Personnel selection in auth section
  const handlePersonnelChange = (selectedValue) => {
    const updatedValues = {
      ...authFormValues,
      personnel: selectedValue
    };
    dispatch(updateAuthFormValues(updatedValues));
  };

  //checking the challenge response
  const [triggerValidateChallengeResponse] =
    useGetValidateChallengeResponseMutation({});

  // authentication click
  const onAuthenticateClick = async () => {
    dispatch(setLoading(true));
    setIsAuthenticationError(false);
    try {
      // Check if either authNumber or challengeResponse is provided
      if (
        !authFormValues?.authNumber?.length &&
        !authFormValues?.challengeResponse?.length
      ) {
        setIsAuthenticationError(true);
        return;
      }

      // Authenticate with authNumber
      if (authFormValues?.authNumber?.length) {
        if (authFormValues.authNumber === personnelAuth[0]?.auth_number) {
          dispatch(updateContainerAuthentication(true));
          dispatch(setSearchResults([]));
        } else {
          setIsAuthenticationError(true);
        }
      }

      // Authenticate with challengeResponse
      if (authFormValues?.challengeResponse?.length) {
        const hashCode = await generateSHA256Hash(
          authFormValues.challengeResponse.trim().toUpperCase()
        );

        const validateChallengeResponse =
          await triggerValidateChallengeResponse({
            main_district_id: String(currentBranch?.district_id),
            language_locale_id: languageLocaleId,
            personnel_id: authFormValues?.personnel?.value,
            challenge_response: hashCode
          });

        if (validateChallengeResponse?.data[0]?.is_match_flag === Y_CHECKER) {
          dispatch(updateContainerAuthentication(true));
          dispatch(setSearchResults([]));
        } else {
          setIsAuthenticationError(true);
        }
      }
      containerAuthForm.setValues(authFormValues);
      let updates = {
        customer: authFormValues?.customer,
        reqBy: ALL_SELECTION,
        auditType: ALL_SELECTION,
        auditStatus: defaultStatus,
        dueDate: null,
        dueDateTo: null,
        exceptionsOnly: false,
        auditDate: null,
        auditTo: null,
        auditId: null
      };
      dispatch(updateSearchFormValues(updates));
    } catch (error) {
      setIsAuthenticationError(true);
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const isShowSearchSection = () => {
    if (isAuthenticated) {
      return true;
    }
    if (showAuthenticationSection) {
      return false;
    }
    return true;
  };

  const handleReset = () => {
    if (isAuthenticated) {
      const updates = {
        reqBy: ALL_SELECTION,
        auditType: ALL_SELECTION,
        auditStatus: defaultStatus,
        dueDate: null,
        dueDateTo: null,
        exceptionsOnly: false,
        auditDate: null,
        auditTo: null,
        auditId: null,
        customer: authFormValues?.customer
      };
      dispatch(updateSearchFormValues(updates));
      dispatch(setSearchResults([]));
      dispatch(setLoading(false));
      dispatch(clearError());
    } else {
      dispatch(resetForm());
    }
    setIsResetGrid(true);
  };

  const handleNavigationClick = (id) => {
    let path = `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${CONTAINER_INVENTORY_AUDIT_DETAIL}`;
    const propsData = {
      authUser: authFormValues,
      id
    };
    if (isAuthenticated) {
      navigate(`${path}/${id}`, { state: propsData });
    } else navigate(`${path}/${id}`, { state: propsData });
  };

  const handleAuthFieldsChange = (name, value) => {
    const updatedValues = {
      ...authFormValues,
      [name]: value
    };
    dispatch(updateAuthFormValues(updatedValues));
  };

  //handle download
  const handleDownload = async () => {
    const {
      auditId,
      auditType,
      auditStatus,
      auditDate,
      auditTo,
      customer,
      reqBy,
      dueDate,
      dueDateTo,
      exceptionsOnly
    } = searchFormValues;
    const maxRowLimit = await getOMauditMaxDispaly();
    try {
      dispatch(setLoading(true));
      dispatch(clearError());

      const auditToDate = auditTo ? auditTo : auditDate;
      const dueToDate = dueDateTo ? dueDateTo : dueDate;
      let customersList = "";
      if (isAuthenticated) {
        const ids = authCustomers
          .map((cust) => cust.value)
          .filter((value) => value !== t("common.all"));
        customersList = customer
          ? customer?.value === t("common.all")
            ? ids.join(",")
            : customer.value
          : "";
      } else {
        customersList = customer
          ? customer?.value === t("common.all")
            ? ""
            : customer.value
          : "";
      }
      const requestBody = JSON.stringify({
        container_audit_id: auditId,
        audit_type_id:
          auditType?.value === t("common.all") ? "" : auditType?.value,
        customer_id_list: customersList,

        audit_date_from: auditDate
          ? dayjs(auditDate).format(dateFormat.universalFormat)
          : "",
        audit_date_to: auditToDate
          ? dayjs(auditToDate).format(dateFormat.universalFormat)
          : "",
        requestor_code: reqBy?.value === t("common.all") ? "" : reqBy?.value,
        audit_status_id:
          auditStatus?.value === t("common.all") ? "" : auditStatus?.value,
        due_date_from: dueDate
          ? dayjs(dueDate).format(dateFormat.universalFormat)
          : "",
        due_date_to: dueToDate
          ? dayjs(dueToDate).format(dateFormat.universalFormat)
          : "",

        with_exception_only_flag: exceptionsOnly ? VALUE_Y : VALUE_N,
        main_district_id: currentBranch?.district_id,
        district_id:
          mainDistrictId === DISTRICT_ID_GET_ALL_LOCATIONS
            ? ""
            : mainDistrictId,
        district_name: currentBranch?.name,
        iso_locale_code: DEFAULT_LOCALE_CODE,
        row_limit: maxRowLimit,
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      });

      let url =
        CF_URLS.printReports.containerProcessing.containerAuditSummeryReport;

      let reportResponse = await printReport(requestBody, url);
      if (!reportResponse.success) {
        dispatch(setError(reportResponse?.error));
      }
    } catch (e) {
      dispatch(setError(findErrorMessage(ERROR_TYPES.ISSUE)));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const authFormProps = {
    allBranches,
    handleBranchChange,
    valuePersonnelAuth,
    allCustomers,
    handleCustomerChange,
    handlePersonnelChange,
    onAuthenticateClick,
    isAuthenticationError,
    isAuthenticated,
    onCancelAuthCliked,
    programSecurityListArray,
    setError,
    isLoading,
    setLoading,
    authFormValues,
    handleChange: handleAuthFieldsChange,
    selectedBranch: currentBranch,
    authCustomers: authenticatedCustomers
  };

  const searchProps = {
    auditForm,
    auditTypes,
    auditStatusList,
    reqList,
    allCustomers,
    minDate,
    maxDate,
    today,
    onFindClick,
    authCustomers,
    handleReset,
    isFindButtonDisabled,
    setIsFindButtonDisabled,
    isAuthenticated,
    getAuditTypeList,
    initialAuthValues
  };

  const tableProps = {
    searchResults,
    isResetGrid,
    isNewVisible,
    setSelectedRow,
    selectedRow,
    setSelectedId,
    selectedId,
    handleNavigationClick,
    handlePrintButtonClick,
    maxRecordMsg
  };
  return (
    <>
      {prevLocation
        ? (isLoading || !isDataLoaded) && (
            <FullCircularLoader loading={isLoading || !isDataLoaded} />
          )
        : isLoading && <FullCircularLoader loading={isLoading} />}
      <form>
        <Stack gap={spacing.gap} sx={AuditStyles.mainDivStyles}>
          <AuthenticationCallerButton
            isAuthenticated={isAuthenticated}
            onAuthenticatedCallerCliked={onAuthenticatedCallerCliked}
          />
          {throwWarning && (
            <Alert
              severity="warning"
              icon={<img src={ErrorIcon} alt="error" width="20" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(clearWarning());
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwWarningMsg}
            </Alert>
          )}

          {throwError && (
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" width="20" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(clearError());
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMsg}
            </Alert>
          )}

          {isShowAuthenticationSection() && (
            <>
              {/* Personnel Authentication Section */}
              {!isAuthenticated && <AuditAuthForm {...authFormProps} />}
            </>
          )}

          {isAuthenticated && (
            <>
              <SuccessAuthSection
                personalDetailForm={containerAuthForm}
                valuePersonnelAuth={valuePersonnelAuth}
                handlePersonnelOnChange={handlePersonnelAuthChange}
                disablePersonnel={true}
              />
              <Divider />
            </>
          )}
          {/* Table Section */}
          {isShowSearchSection() && (
            <>
              <AuditSearch {...searchProps} />
              <AuditTable {...tableProps} />
            </>
          )}

          {/* Download popup */}
          <DownloadPopup
            title={t("containerAuditSearch.reportTitle")}
            showPrintModal={showPrintModal}
            cfUrl={reportCFUrl}
            onCancleClick={() => {
              setShowPrintModal(false);
              setReportCFUrl(VALUE_EMPTY_STRING);
            }}
            onDownloadClick={handleDownload}
            setSelectedValue={setSelectedValue}
          />
        </Stack>
      </form>
    </>
  );
};
export default AuditLayout;
