import { Card, Grid, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { CF_URLS } from "services/api/endpoints";
import spacing from "styles/spacing";
import { ERROR_TYPES } from "utils/constants";
import { modalMinWidth } from "utils/constants/transportation/RunManagementConstants";
import { findErrorMessage, getDateFromUTCFormat } from "utils/helpers";
import { runManagementStyles } from "./RunManagementStyle";
import { getResponseData } from "./helper";

const RequestSummaryModal = (props) => {
  const {
    setIsShowRequestModal,
    mainDistrictId,
    requestId,
    customerData,
    setIsLoading,
    setThrowError,
    setThrowErrorMessage
  } = props;

  const [requestData, setRequestData] = useState(null);
  const [mediaData, setMediaData] = useState([]);

  useEffect(() => {
    requestId && getRequestDetails(requestId);
    //eslint-disable-next-line
  }, [requestId]);

  const getRequestDetails = async (requestId) => {
    try {
      setIsLoading(true);
      let requestBody = JSON.stringify({
        main_district_id: mainDistrictId,
        request_id: requestId
      });
      let data = await getResponseData(
        requestBody,
        `${CF_URLS.runManagement.getRequestSummary}`,
        2
      );
      let request = data?.data[0][0];
      let media = data?.data[1];
      let mediaData = [];
      media.forEach(async (item, index) =>
        mediaData.push({
          id: index,
          descr: item.descr,
          quantity: item.quantity
        })
      );
      if (data?.error) {
        setThrowErrorMessage(data.error);
        setThrowError(true);
      } else {
        let customer = customerData.filter(
          (item) => item.customer_id === request.customer_id
        );
        request.customer = customer[0]?.number;
        request.service_date = getDateFromUTCFormat(request.service_date);
        request.logged_datetime = getDateFromUTCFormat(
          request.logged_datetime,
          { withTime: true }
        );
        setRequestData(request);
        setMediaData(mediaData);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  // Table columns
  const columns = [
    {
      field: "descr",
      headerName: t("runManagement.mediaType"),
      headerClassName: "gridHeader",
      flex: 1,
      sortable: false
    },
    {
      field: "quantity",
      headerName: t("runManagement.numberOfItems"),
      headerClassName: "gridHeader",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center"
    }
  ];

  const handleCancelClick = () => {
    setIsShowRequestModal(false);
    setRequestData(null);
    setMediaData([]);
  };

  return (
    <>
      <CommonModal
        open={requestData}
        title={t("runManagement.requestSummary")}
        minWidth={modalMinWidth}
        body={
          <>
            <Stack direction="column">
              <Grid container spacing={spacing.gap}>
                <Grid item xs={3}>
                  <Typography variant="caption" component="small">
                    {t("common.customer")}
                  </Typography>
                  <Typography variant="body1">
                    {requestData?.customer}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption" component="small">
                    {t("runManagement.serviceDate")}
                  </Typography>
                  <Typography variant="body1">
                    {requestData?.service_date}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption" component="small">
                    {t("runManagement.requestType")}
                  </Typography>
                  <Typography variant="body1">
                    {requestData?.request_type}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="caption" component="small">
                    {t("runManagement.action")}
                  </Typography>
                  <Typography variant="body1">{requestData?.action}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption" component="small">
                    {t("runManagement.status")}
                  </Typography>
                  <Typography variant="body1">
                    {requestData?.status_code}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption" component="small">
                    {t("runManagement.loggedDate")}
                  </Typography>
                  <Typography variant="body1">
                    {requestData?.logged_datetime}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption" component="small">
                    {t("runManagement.loggedBy")}
                  </Typography>
                  <Typography variant="body1">
                    {requestData?.logged_full_name}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
            <Stack direction="column">
              <Grid
                container
                spacing={spacing.gap}
                sx={runManagementStyles.mediaCardStyle}
              >
                <Grid item xs={5}>
                  <Card>
                    {mediaData?.length > 0 && (
                      <Typography
                        variant="subtitle1"
                        sx={runManagementStyles.recordStyle}
                      >
                        {mediaData?.length}{" "}
                        {t("billingRecurringCharge.recordsFound")}
                      </Typography>
                    )}
                    <Stack sx={runManagementStyles.mediaGridStyle}>
                      <DataGrid
                        rows={mediaData}
                        columns={columns}
                        disableColumnMenu={true}
                        sx={{
                          ".MuiDataGrid-columnSeparator": {
                            display: "none"
                          },
                          "&.MuiDataGrid-columnHeaders": {
                            heught: "36px",
                            backgroundColor: "#F5F5F5"
                          }
                        }}
                        localeText={{
                          noRowsLabel: t("runManagement.noMediaDetail")
                        }}
                        hideFooter={mediaData.length === 0}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 }
                          }
                        }}
                        pageSizeOptions={[5, 10]}
                      />
                    </Stack>
                  </Card>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={runManagementStyles.commentTitleStyle}>
                    {t("runManagement.comments")}
                  </Typography>
                  <Grid item xs={12}>
                    <Typography variant="caption" component="small">
                      {t("runManagement.ironMountainComments")}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={runManagementStyles.commentStyle}
                    >
                      {requestData?.employee_comment}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" component="small">
                      {t("runManagement.customerComments")}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={runManagementStyles.commentStyle}
                    >
                      {requestData?.personnel_comment}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" component="small">
                      {t("runManagement.otherComments")}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={runManagementStyles.commentStyle}
                    >
                      {requestData?.comment}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </>
        }
        buttons={
          <>
            <Button variant="outlined" onClick={handleCancelClick}>
              {t("common.close")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default RequestSummaryModal;
