import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CF_URLS } from "services/api/endpoints";
import {
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  downloadOptionPdf
} from "utils/constants";
import { findErrorMessage, printReport } from "utils/helpers";

export default function DownloadReportModal({
  isVisible,
  setIsVisible,
  printRequestBody,
  currentBranch,
  setIsLoading,
  throwError,
  setThrowError,
  throwErrorMessage,
  setThrowErrorMessage
}) {
  const { t } = useTranslation();
  const [reportCUrl, setReportCFUrl] = useState(VALUE_EMPTY_STRING);
  const [selectedValue, setSelectedValue] = useState(downloadOptionPdf);

  useEffect(() => {
    if (isVisible) {
      setReportCFUrl(CF_URLS.printReports.disasterRecovery.getEventSearchPrint);
    } else {
      setReportCFUrl(VALUE_EMPTY_STRING);
    }
  }, [isVisible]);

  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);
      const reqBody = JSON.stringify({
        main_district_id: printRequestBody.main_district_id,
        customer_id: printRequestBody.customer_id,
        dr_event_status_id: printRequestBody.dr_event_status_id,
        dr_event_type_code: printRequestBody.dr_event_type_code,
        from_date: printRequestBody.from_date,
        to_date: printRequestBody.to_date,
        dr_event_id: printRequestBody.dr_event_id,
        user_name: printRequestBody.user_name,
        report_format: selectedValue?.type?.toUpperCase()
      });
      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.disasterRecovery.getEventSearchPrint}`
      );
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setThrowError(false);
    setIsVisible(false);
    setReportCFUrl(VALUE_EMPTY_STRING);
  };

  return (
    <DownloadPopup
      title={t("common.downloadReport")}
      showPrintModal={isVisible}
      onCancleClick={handleCancel}
      onDownloadClick={handleDownload}
      setSelectedValue={setSelectedValue}
      setThrowError={setThrowError}
      throwError={throwError}
      throwErrorMessage={throwErrorMessage}
      cfUrl={reportCUrl}
    />
  );
}
